/* jshint bitwise: false */
import UAParser from 'ua-parser-js';

(function() {
  'use strict';

  function UAParserService() {
    var service = {};
    service.Parser = UAParser;

    service.getName = function(ua) {
      if (_.isEmpty(ua)) {
        return '';
      }

      var parsed = new service.Parser(ua);
      return parsed.getBrowser().name + ' on ' + parsed.getOS().name;
    };

    return service;
  }

  UAParser.$inject = [
  ];

  angular.module('blocks.utils')
    .service('UAParser', UAParserService);
})();
