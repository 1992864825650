(function() {
  'use strict';

  function State() {
    return {
      restrict: 'AE',
      scope: {
        map: '=',
        state: '='
      },
      template: '<span class="label label-{{map[state].labelStyle || \'info\'}}">' +
      '{{map[state].name || map[state].title || state}}</span>'
    };
  }

  State.$inject = [];

  angular.module('blocks.widgets')
    .directive('kzState', State);
})();
