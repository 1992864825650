(function() {
  'use strict';

  function TodosController($q, EventsStore, EventSearch, List, Utils) {
    var ctrl = this;
    ctrl.loaded = true;
    ctrl.options = {
      typesToUpdate: ['event'],
      trackBy: 'id',
      borderClass: function(item) {
        return item.main.getBorderClass();
      }
    };

    ctrl.draftOptions = angular.copy(ctrl.options);

    Utils.setPageTitle('To do list');

    function loadList() {
      return $q.all([
        EventsStore.loadGrouped(),
        EventSearch.getSearch('todosearch')
      ])
        .then(function(result) {
          var items = result[0];
          var search = result[1];

          ctrl.list = new List({
            search: search,
            idField: 'id',
            pagination: 'links',
            findOptions: {
              limit: 10
            },
            defaultFilter: {
              state: 'todo'
            }
          });
          ctrl.draftlist = new List({
            search: search,
            idField: 'id',
            pagination: 'links',
            findOptions: {
              limit: 10
            },
            defaultFilter: {
              state: 'draft'
            }
          });
          return $q.all([
            ctrl.list.doLoadItems(items),
            ctrl.draftlist.doLoadItems(items)
          ]);
        });
    }

    loadList();
  }

  TodosController.$inject = [
    '$q',
    'EventsStore',
    'EventSearch',
    'ListFactory',
    'UtilsService'
  ];

  angular.module('component.todos')
    .controller('TodosController', TodosController);
})();
