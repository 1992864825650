(function() {
  'use strict';

  function BaseVersionService(ConfService, Store, Utils, Auth) {
    var Service = function(docType) {
      ConfService.call(this, docType);
      this.store = Store;
    };

    Utils.extends(Service, ConfService);

    /**
     * Creates the structure for a version
     * @return {object}
     */
    Service.prototype.getDefaultGroup = function() {
      var groupid = Utils.guid(),
          ddoc = _.assignIn(
            {},
            {
              _id: Utils.guid(),
              versionGroupId: groupid,
              version: 1,
              type: this.docType,
              state: 'draft',
              organisation: Auth.currentOrganisation()
            },
            this.getDefaultDoc()
          );

      return {
        doc: ddoc,
        linkedVersions: [ddoc],
        _id: groupid
      };
    };

    /**
     * Return the initial structure of the event type
     * @return {object}
     */
    Service.prototype.getDefaultDoc = function() {
      return {};
    };

    Service.prototype.findAllGrouped = function(kzOpts) {
      return this.store.findAllGrouped(this.docType, kzOpts);
    };

    Service.prototype.findLatestPublishedGrouped = function() {
      return this.store.findLatestPublishedGrouped(this.docType);
    };

    Service.prototype.findLatestAvailableGrouped = function() {
      return this.store.findLatestAvailableGrouped(this.docType);
    };

    Service.prototype.findLatestAvailable = function() {
      return this.store.findLatestAvailable(this.docType);
    };

    Service.prototype.findLatestPublished = function() {
      return this.store.findLatestPublished(this.docType);
    };

    Service.prototype.findLatest = function() {
      return this.store.findLatestPublished(this.docType);
    };

    Service.prototype.getGroupByItemId = function(itemId) {
      return this.store.getGroupByItemId(this.docType, itemId);
    };

    Service.prototype.getGroupById = function(groupId) {
      return this.store.getGroupById(this.docType, groupId);
    };

    Service.prototype.getGroupsByIds = function(groupIds) {
      return this.store.getGroupsByIds(this.docType, groupIds);
    };

    Service.prototype.getGroupsByItemIds = function(groupIds) {
      return this.store.getGroupsByItemIds(this.docType, groupIds);
    };

    Service.prototype.clearGroupById = function(groupId) {
      return this.store.clearGroupById(this.docType, groupId);
    };

    return Service;
  }

  BaseVersionService.$inject = [
    'ConfService',
    'BaseVersionStoreService',
    'UtilsService',
    'AuthService'
  ];

  angular.module('blocks.services')
    .factory('BaseVersionService', BaseVersionService);
})();
