/* eslint-disable */
(function() {
  'use strict';

  angular.module('kz.tree', []);
})();

/* eslint-disable */
angular.module('kz.tree').run(['$templateCache', function($templateCache) {$templateCache.put('node-title.html','<span class="kzt-node-title" ng-class="{\'is-active\': showChildren}">\n  <i class="fa" ng-class="{\'{{icons.arrowRight}}\': !showChildren && hasChildren, \'{{icons.arrowDown}}\': showChildren, \'{{icons.empty}}\': !hasChildren}"></i>\n  <a ng-if="hasChildren" href="" ng-click="nodeTitleCtrl.toggleSubNodes()">{{title}}</a>\n  <span ng-if="!hasChildren">{{title}}</span>\n</span>\n');
$templateCache.put('node.html','<div>\n  <div class="kzt-checkbox" ng-if="options.selectable">\n    <label>\n      <input type="checkbox" value="node._id" ng-click="toggleSelected(node._id)" ng-checked="isSelected(node._id)">\n    </label>\n  </div>\n\n  <kz-tree-node-title key="node._id" title="node.name" has-children="node.categories.length" show-children="!showSelected && isExpanded(node._id)" icons="options.icons" on-expand="expand(node._id)"></kz-tree-node-title>\n\n  <span ng-show="(isSearching || showSelected) && node.path" class="kzt-node-path">\n    {{ node.path }}\n  </span>\n\n  <ul ng-if="node.categories.length && (isExpanded(node._id) || options.expanded)">\n    <li ng-repeat="node in node.categories track by node._id" ng-include="\'node.html\'"></li>\n  </ul>\n</div>\n');
$templateCache.put('search.html','<div class="kzt-search">\n  <input type="text" name="kz-search-query" id="kzt-search-query" value="" placeholder="Search..." ng-model="search" ng-readonly="readonly" />\n</div>\n');
$templateCache.put('toolbar.html','<div class="kzt-toolbar">\n  <div class="pull-left" ng-if="options.searchable">\n    <kz-tree-search nodes="nodes" on-search="doSearch(query)" readonly="showSelected"></kz-tree-search>\n  </div>\n  <div ng-class="{\'pull-right\': options.searchable}">\n    <ul class="kzt-toolbar-list" ng-if="total.length && options.selectable">\n      <li ng-if="options.allowMassSelect">\n        <a href="" ng-click="selectAll()" ng-class="{\'disabled\': total.length === selected.length}">Select all</a>\n      </li>\n      <li ng-if="options.allowMassSelect">\n        <a href="" ng-click="deselectAll()" ng-class="{\'disabled\': !selected.length}">Deselect all</a>\n      </li>\n      <li ng-if="selected.length">\n        <a href="" ng-click="toggleSelected()">{{ showSelected ? \'Hide\' : \'Show\' }} selected</a>\n      </li>\n      <li ng-if="options.multiple">\n        <span ng-if="selected.length > 0">{{ selected.length }} out of </span>{{ total.length }} items<span ng-if="selected.length > 0"> selected</span>\n      </li>\n      <li ng-if="!options.multiple">\n        {{ total.length }} items<span ng-if="selected.length > 0"></span>\n      </li>\n    </ul>\n  </div>\n</div>\n');
$templateCache.put('tree.html','<div class="kz-tree">\n\n  <kz-tree-toolbar\n    nodes="nodes"\n    selected="selected"\n    options="options"\n    on-change-selection="doChangeSelection(selected)"\n    on-toggle-selected="doToggleSelected(show)"\n    on-search="doSearch(query)">\n  </kz-tree-toolbar>\n\n  <ul class="kzt-list">\n    <li ng-if="!isSearching && !showSelected" ng-repeat="node in nodes track by node._id" ng-include="\'node.html\'"></li>\n    <li ng-if="isSearching && !showSelected" ng-repeat="node in searchResults track by node._id" ng-include="\'node.html\'"></li>\n    <li ng-if="showSelected" ng-repeat="node in selectedNodes track by node._id" ng-include="\'node.html\'"></li>\n  </ul>\n\n</div>');}]);
/* eslint-disable */
(function() {
  'use strict';

  function TreeUtils() {
    var service = {};

    service.contains = function(nodes, query, path, results) {
      results = results || [];
      path = path ? path + ' » ' : '';

      if (service.isArray(nodes)) {
        for (var i=0; i<nodes.length; i++) {
          var node = nodes[i];

          if (node.name) {
            var found = node.name.toLowerCase().indexOf(query) > -1,
                newPath = found ? path.replace(/»([^»]*)$/, '$1').trim() : path + node.name;

            if (found) {
              // Attach a path property to it and push it to the results
              node.path = newPath;
              results.push(node);
            }

            if(node.categories) {
              service.contains(node.categories, query, newPath, results);
            }
          }
        }
      }

      return results;
    };

    service.toggleNodeChildrenVisibility = function(id, expanded) {
      var idx = expanded.indexOf(id);

      if (idx !== -1) {
        expanded.splice(idx, 1);
      } else {
        expanded.push(id);
      }

      return expanded;
    };

    service.collectAll = function(nodes, key, results) {
      results = service.isArray(results) ? results : [];

      if (service.isArray(nodes)) {
        for (var i=0; i<nodes.length; i++) {
          var node = nodes[i];

          if (node[key]) {
            results.push(node[key]);
          }

          if(node.categories) {
            service.collectAll(node.categories, key, results);
          }
        }
      }

      return results;
    };

    service.createSelectedNodesFromIds = function(ids, nodes, selected) {
      selected = service.isArray(selected) ? selected : [];

      if (service.isArray(ids) && service.isArray(nodes)) {
        for (var i=0; i<ids.length; i++) {
          var node = service.findBy(ids[i], '_id', nodes);

          if (node) {
            selected.push({ _id: node._id, name: node.name, path: node.path });
          }
        }
      }

      return selected;
    };

    service.findBy = function(value, key, nodes, path) {
      path = path ? path + ' » ' : '';

      if (service.isArray(nodes)) {
        for (var i=0; i<nodes.length; i++) {
          var match = nodes[i][key] === value,
              newPath = match ? path.replace(/»([^»]*)$/, '$1').trim() : path + nodes[i].name;

          if (match) {
            nodes[i].path = newPath;
            return nodes[i];
          }

          var found = service.isArray(nodes[i].categories) && service.findBy(value, key, nodes[i].categories, newPath);

          if (found) {
            return found;
          }
        }
      }
    };

    service.isExpanded = function(id, nodes) {
      return nodes.indexOf(id) !== -1;
    };

    service.isArray = function(value) {
      return typeof value !== 'undefined' && value.constructor === Array;
    };

    service.search = function(nodes, query) {
      return service.contains(nodes, query.toLowerCase());
    };

    return service;
  }

  angular.module('kz.tree')
    .service('TreeUtils', TreeUtils);
})();

/* eslint-disable */
(function() {
  'use strict';

  function TreeDirective(Utils, DEFAULT_OPTIONS) {
    return {
      restrict: 'AE',
      replace: true,
      templateUrl: 'tree.html',
      scope: {
        nodes: '=',
        model: '=?ngModel',
        options: '=?'
      },
      link: function(scope) {
        scope.options = angular.extend({}, DEFAULT_OPTIONS, scope.options);

        // Watch on model and always turn into an array as it is simpler to work with an array
        scope.$watch('model', function(model) {
          if (model === undefined) {
            return;
          }

          if (!Utils.isArray(model)) {
            scope.selected = [model];
          } else {
            scope.selected = model;
          }
        }, true);

        // Watch selected and convert to model if necessary
        scope.$watch('selected', function(selected) {
          if (scope.options.multiple) {
            scope.model = selected;
          } else {
            scope.model = Utils.isArray(selected) && selected.length > 0 ? selected[0] : undefined;
          }
        }, true);

        scope.expanded = [];
        scope.isSearching = false;


        scope.expand = function(id) {
          scope.expanded = Utils.toggleNodeChildrenVisibility(id, scope.expanded);
        };

        if (scope.nodes.length === 1 && scope.nodes[0].categories.length) {
          scope.expand(scope.nodes[0]._id);
        }

        scope.toggleSelected = function(id) {
          scope.selected = Utils.isArray(scope.selected) ? scope.selected : [];
          var idx = scope.selected.indexOf(id);
          if (scope.options.multiple) {
            if (idx !== -1) { // remove from selection
              scope.selected.splice(idx, 1);
            } else { // add to selection
              scope.selected.push(id);
            }
          } else {
            if (idx !== -1) {
              scope.selected = [];
            } else {
              scope.selected = [id];
            }
          }
        };

        scope.isSelected = function(id) {
          return Utils.isArray(scope.selected) ? scope.selected.indexOf(id) !== -1 : false;
        };

        scope.isExpanded = function(id) {
          return Utils.isExpanded(id, scope.expanded);
        };

        scope.doSearch = function(query) {
          if (query.length) {
            scope.isSearching = true;
            scope.searchResults = Utils.search(scope.nodes, query);
          } else {
            scope.isSearching = false;
            scope.searchResults = null;
          }
        };

        scope.doChangeSelection = function(selected) {
          scope.selected = selected;
        };

        scope.doToggleSelected = function(show) {
          scope.showSelected = show;
          if (show) {
            scope.selectedNodes = Utils.createSelectedNodesFromIds(scope.selected, scope.nodes);
          }
        };
      }
    };
  }

  TreeDirective.$inject = ['TreeUtils', 'DEFAULT_OPTIONS'];

  angular.module('kz.tree')
    .directive('kzTree', TreeDirective);
})();

/* eslint-disable */
(function() {
  'use strict';

  function ToolbarDirective(Utils) {
    return {
      restrict: 'AE',
      replace: true,
      templateUrl: 'toolbar.html',
      scope: {
        nodes: '=',
        options: '=',
        selected: '=?',
        onSearch: '&',
        onChangeSelection: '&',
        onToggleSelected: '&'
      },
      link: function(scope) {
        var MAX = 100,
            WARNING = 'You have selected quite a few items and your browser might ' +
                      'become unresponsive while rendering all your items. ' +
                      'Are you sure you want to continue?';

        scope.showSelected = false;
        scope.total = Utils.collectAll(scope.nodes, '_id');

        scope.doSearch = function(query) {
          scope.onSearch({ query: query });
        };

        scope.selectAll = function() {
          scope.onChangeSelection({ selected: scope.total.slice() });
        };

        scope.deselectAll = function() {
          scope.onChangeSelection({ selected: [] });
        };

        scope.$watch('nodes', function(newValue, oldValue) {
          if(newValue !== oldValue) {
            scope.total = Utils.collectAll(scope.nodes, '_id');
          }
        }, true);

        scope.toggleSelected = function() {
          scope.showSelected = !scope.showSelected;

          if (scope.total.length > scope.selected.length) {
            if (scope.selected.length > MAX) {
              if (confirm(WARNING)) {
                scope.onToggleSelected({ show: scope.showSelected });
              } else {
                scope.showSelected = !scope.showSelected;
              }
            } else {
              scope.onToggleSelected({ show: scope.showSelected });
            }
          }
        };

      }
    };
  }

  ToolbarDirective.$inject = ['TreeUtils'];

  angular.module('kz.tree')
    .directive('kzTreeToolbar', ToolbarDirective);
})();

/* eslint-disable */
(function() {
  'use strict';

  function TreeSearchDirective(Utils) {
    return {
      restrict: 'AE',
      replace: true,
      templateUrl: 'search.html',
      scope: {
        nodes: '=',
        readonly: '=',
        onSearch: '&'
      },
      link: function(scope) {
        scope.$watch('search', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            if (newValue.length > 2) {
              scope.onSearch({ query: newValue });
            }

            if (newValue.length === 0) {
              scope.onSearch({ query: newValue });
            }
          }
        });
      }
    };
  }

  TreeSearchDirective.$inject = ['TreeUtils', 'DEFAULT_OPTIONS'];

  angular.module('kz.tree')
    .directive('kzTreeSearch', TreeSearchDirective);
})();

/* eslint-disable */
(function() {
  'use strict';

  function NodeTitleController($scope) {
    var ctrl = this;

    ctrl.toggleSubNodes = function() {
      $scope.onExpand({ id: $scope.key });
    };
  }

  function NodeTitleDirective() {
    return {
      restrict: 'AE',
      replace: true,
      templateUrl: 'node-title.html',
      scope: {
        key: '=',
        title: '=',
        hasChildren: '=',
        showChildren: '=?',
        icons: '=',
        onExpand: '&?'
      },
      controller: NodeTitleController,
      controllerAs: 'nodeTitleCtrl'
    };
  }

  NodeTitleController.$inject = ['$scope'];

  angular.module('kz.tree')
    .directive('kzTreeNodeTitle', NodeTitleDirective);
})();

/* eslint-disable */
(function() {
  'use strict';

  var defaultOptions = {
    multiple: true,
    selectable: false,
    allowMassSelect: false,
    expanded: false,
    searchable: false
  };

  angular.module('kz.tree')
    .constant('DEFAULT_OPTIONS', defaultOptions);
})();
