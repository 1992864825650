(function() {
  'use strict';

  function Progress(Utils) {
    return {
      restrict: 'AE',
      scope: {
        numerator: '=',
        denominator: '=',
        rate: '=',
        objName: '@',
        success: '=?',
        description: '@?'
      },
      transclude: true,
      templateUrl: function(_elem, attr) {
        if (attr.inline === 'true') {
          return 'app/blocks/widgets/progress/progress-inline.html';
        }
        return 'app/blocks/widgets/progress/progress.html';
      },
      replace: true,
      link: function($scope, element) {
        if (element.find('ng-transclude').children().length) {
          $scope.useTransclude = true;
        }

        $scope.description = $scope.description || 'Progress';

        var update = function() {
          $scope.completionRate = $scope.rate ||
            Utils.getRate($scope.numerator, $scope.denominator);
          if ($scope.completionRate >= 100) {
            $scope.labelStyle = $scope.success === false ? 'danger' : 'success';
          } else if ($scope.completionRate >= 66) {
            $scope.labelStyle = 'info';
          } else if ($scope.completionRate >= 33) {
            $scope.labelStyle = 'warning';
          } else if ($scope.completionRate >= 0) {
            $scope.labelStyle = 'danger';
          }
        };

        $scope.$watch('rate', update);
        $scope.$watchCollection('numerator', update);
        $scope.$watchCollection('denominator', update);
      }
    };
  }

  Progress.$inject = ['UtilsService'];

  angular.module('blocks.widgets')
    .directive('kzProgressBar', Progress);
})();
