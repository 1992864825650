(function() {
  'use strict';

  function Search(Utils) {
    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'name',
              type: 'text',
              label: 'Start typing to search'
            }
          ],
          options: {
            defaultFacet: 'name',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'name',
            matchFunc: function(item, _key, value) {
              if (!value) { return true; }

              var val = value.toLowerCase(),
                  name = Utils.safeLowerStr(item.doc.name);
              return name.indexOf(val) > -1;
            }
          }
        ],
        orders: {
          name: function(item) {
            return Utils.safeLowerStr(item.doc.name);
          }
        },
        orderGroups: {
          name: {
            title: 'name',
            orders: ['name']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'name'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = ['UtilsService'];

  angular.module('component.organisations')
    .service('OrganisationSearch', Search);
})();
