(function() {
  'use strict';

  function PaginationDirective($rootScope) {
    return {
      scope: {
        total: '=',
        startkey: '=',
        perPage: '=',
        to: '&'
      },
      restrict: 'E',
      templateUrl: 'app/blocks/list/list.pagination.html',
      link: function(scope) {
        scope.totalItems = +scope.total;
        scope.perPage = +scope.perPage;

        /**
         * We need to adjust the numbers for the totalItems and last item displayed
         * when we remove one or more items.
         * @param  {Number} count How many items we've removed.
         * @return {void}
         */
        var adjustPaginationLinks = function(count) {
          scope.totalItems -= count;
          scope.endAt -= count;
        };

        var calculatePosition = function(startkey) {
          // APIList will always give us a startkey that is a multiple of perPage,
          // e.g. when total = 11, perPage = 10 and we're on page 2 :: startkey = 20
          // List will give us a startkey equal to the index of the last element in the list
          // e.g. when total = 11, perPage = 10 and we're on page 2 :: startkey = 11
          if (startkey % scope.perPage) {
            // If the modulus is anything other than 0,
            // it means the startkey needs to be normalised
            // startkey need to be the next multiple of perPage
            // e.g. perPage = 5, startkey = 13 then startkey need to be 15
            // startkey = Math.ceil((startkey / scope.perPage)) * scope.perPage
            // page = Math.ceil(14 / 5) = 3
            // startkey = page * 5 = 15
            startkey = Math.ceil((startkey / scope.perPage)) * scope.perPage;
          }

          scope.endAt = startkey > scope.totalItems ? scope.totalItems : startkey;
          scope.currentPage = Math.ceil(((startkey - scope.perPage) + 1) / scope.perPage);
          scope.startAt = ((scope.perPage * scope.currentPage) - scope.perPage) + 1;
          scope.totalPages = Math.ceil(scope.totalItems / scope.perPage);
        };

        calculatePosition(+scope.startkey);

        scope.$watch('total', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            scope.totalItems = +scope.total;
            var fromStart = 1;
            calculatePosition(fromStart);
          }
        });

        scope.$watch('startkey', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            calculatePosition(+newValue);
          }
        });

        scope.goToPage = function(page) {
          scope.to({ page: page });
        };

        $rootScope.$on('KZPagination:ItemRemoved', function(_evt, args) {
          adjustPaginationLinks(args.count);
        });
      }
    };
  }

  PaginationDirective.$inject = ['$rootScope'];

  angular.module('blocks.list')
    .directive('kzPagination', PaginationDirective);
})();
