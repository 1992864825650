(function() {
  'use strict';

  function HelpEditorController(Form, HELPTEXTS) {
    var ctrl = this;

    ctrl.help = window.help || HELPTEXTS;

    window.help = ctrl.help;

    ctrl.model = {};
    ctrl.hform = new Form([
      { id: 'route', type: 'string', label: 'Route', required: true },
      { id: 'text', type: 'text', label: 'Text', required: true }
    ]);

    ctrl.add = function(form) {
      ctrl.help[ctrl.model.route] = ctrl.model.text;
      ctrl.model = {};

      form.$setPristine();
      form.$setUntouched();
    };

    ctrl.load = function(key, value) {
      ctrl.model.route = key;
      ctrl.model.text = value;
    };
  }

  HelpEditorController.$inject = ['FormsService', 'HELPTEXTS'];

  angular.module('epf.core')
    .controller('HelpEditorController', HelpEditorController);
})();
