(function() {
  'use strict';

  function kzLoading($rootScope, $timeout) {
    return {
      scope: {
        loaded: '='
      },
      transclude: true,
      template: '<div>' +
      '<div class="col-xs-12 text-center indicator" ng-if="!loaded">' +
      '<i class="icon-spin icon-small animate-spin"></i></div>' +
      '<ng-transclude ng-if="loaded"></ng-transclude>' +
      '</div>',
      link: function(scope) {
        var unbind = scope.$watch('loaded', function(value) {
          if (value) {
            // Notify.success('Page loaded');
            $timeout(function() {
              $rootScope.$broadcast('KzPageLoaded');
            }, 100);
            unbind();
          }
        });
      }
    };
  }

  kzLoading.$inject = ['$rootScope', '$timeout'];

  function kzLoadingInline() {
    return {
      scope: {
        loaded: '='
      },
      transclude: true,
      template: '<span>' +
      '<span ng-if="!loaded">' +
      '<i class="icon-spin icon-small animate-spin"></i></span>' +
      '<ng-transclude ng-if="loaded"></ng-transclude>' +
      '</span>'
    };
  }

  angular.module('blocks.utils')
    .directive('kzLoading', kzLoading)
    .directive('kzLoadingInline', kzLoadingInline);
})();
