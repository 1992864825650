(function() {
  'use strict';

  function EmailTypesService(RestEasApi, EasApi, DISABLED_TEMPLATE_ID) {
    var service = new RestEasApi('email-templates/types');

    service.disable = function(emailTypeId) {
      return service.setCurrent(emailTypeId, DISABLED_TEMPLATE_ID);
    };

    service.setCurrent = function(emailTypeId, emailTemplateId) {
      var url = 'email-templates/types/' + emailTypeId + '/set-current';
      return EasApi.post(url, { email_template_id: emailTemplateId });
    };

    service.isCurrent = function(templateId, emailType) {
      return templateId === emailType.doc.defaultTemplate.id;
    };

    service.sendTestEmail = function(emailTypeId, preferredTemplate) {
      var url = 'email-templates/types/' + emailTypeId + '/test-email';
      var data = {};
      if (preferredTemplate) {
        data.preferredTemplate = preferredTemplate;
      }
      return EasApi.post(url, data);
    };

    service.preview = function(emailTypeId, preferredTemplate, partials) {
      var url = 'email-templates/types/' + emailTypeId + '/preview';
      var data = {};
      if (preferredTemplate) {
        data.preferredTemplate = preferredTemplate;
      }
      if (partials) {
        data.preferredPartials = partials;
      }

      return EasApi.post(url, data);
    };

    service.getByType = function(emailTypeId) {
      var url = 'email-templates/types/' + emailTypeId + '/templates';
      return EasApi.get(url);
    };

    return service;
  }

  EmailTypesService.$inject = ['RestEasApi', 'EasApiService', 'DISABLED_TEMPLATE_ID'];

  angular.module('component.emailTemplates')
    .factory('EmailTypesService', EmailTypesService);
})();
