(function() {
  'use strict';

  function OrganisationControlPanelComponent(Organisations, Utils) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.setup();
    };


    ctrl.setup = function() {
      return Organisations.find()
        .then(function(org) {
          ctrl.org = org;
          Utils.setPageTitle('Organisation control panel: ' + org.name);
        })
        .finally(function() {
          ctrl.loaded = true;
        });
    };

    ctrl.$onDestroy = function() {
    };
  }

  OrganisationControlPanelComponent.$inject = ['OrganisationsService', 'UtilsService'];

  angular.module('component.organisations')
    .component('organisationControlPanel', {
      templateUrl: 'app/components/organisations/organisation.controlpanel.component.html',
      controller: OrganisationControlPanelComponent
    });
})();
