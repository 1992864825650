(function() {
  'use strict';

  function CookiesService(ipCookie) {
    var service = {};

    service.put = function(key, val, opts) {
      return ipCookie(key, val, opts);
    };

    service.get = function(key) {
      return ipCookie(key);
    };

    service.all = function() {
      return ipCookie();
    };

    service.remove = function(key, opts) {
      return ipCookie.remove(key, opts);
    };

    return service;
  }

  CookiesService.$inject = ['ipCookie'];

  angular.module('blocks.cookies')
    .service('CookiesService', CookiesService);
})();
