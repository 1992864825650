(function() {
  'use strict';

  function Cards(Sidebar) {
    var service = {};

    service.register = function() {
      var cards = [
        {
          id: 'goals-list-card',
          title: 'Create a new goal event',
          directive: 'goals-list-card',
          options: {
            routes: ['epf.goals.index']
          }
        },
        {
          id: 'goal-set',
          title: 'All goals within this set',
          directive: 'goalset-card',
          options: {
            routes: [
              'epf.goals.view',
              'epf.goals.work',
              'epf.users.goals-view',
              'epf.users.goals-work'
            ]
          }
        },
        {
          id: 'target-linking-card',
          title: 'Linking status',
          directive: 'target-linking-card',
          options: {
            routes: ['epf.goals.link']
          }
        }
      ];

      Sidebar.registerCards(cards);
    };

    return service;
  }

  Cards.$inject = ['SidebarService'];

  angular.module('component.goals')
    .service('GoalsCards', Cards);
})();
