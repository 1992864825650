(function() {
  'use strict';

  function ReportViewDirective(
    $log,
    $q,
    Report,
    Reports,
    Utils
  ) {
    return {
      scope: {
        reportEx: '=report',
        reportDoc: '=',
        reportId: '@',
        model: '=',
        field: '=',
        generated: '=?',
        resultLoaded: '&',
        storedResult: '=',
        extra: '=',
        itemsToDisplay: '=',
        mode: '@?',
        size: '=?',
        needPreview: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-view.html',
      replace: true,
      link: function($scope) {
        $scope.domID = 'report-content-' + Utils.guid();
        function runReport() {
          function loadBaseReport() {
            if ($scope.reportEx !== undefined) {
              return $q.when($scope.reportEx);
            } else if ($scope.reportDoc !== undefined) {
              return $q.when(new Report($scope.reportDoc));
            }

            return Reports.getGroupByItemId($scope.reportId, true)
              .then(function(doc) {
                return $q.when(new Report(doc));
              })
              .catch(function(err) {
                $log.warn('Report not found', err);
              });
          }


          $scope.loaded = false;
          loadBaseReport()
            .then(function(baseReport) {
              $scope.baseReport = baseReport;
              $scope.showPDFDownload = baseReport.doc.enablePDF !== undefined ?
                baseReport.doc.enablePDF : true;
              if (($scope.extra || {}).hidePDF) {
                $scope.showPDFDownload = false;
              }
              $scope.report = baseReport.getSingleOrMultiReportByDoc();
              // $scope.resolvedModel = $scope.resolveModel($scope.model);
              $scope.loaded = true;
            });
        }
        runReport();

        $scope.$on('RegenerateReport', function() {
          $scope.generated = false;
          runReport();
        });

        $scope.resultLoaded2 = function(result) {
          $scope.resultLoaded({ result: result });
        };
      }
    };
  }

  ReportViewDirective.$inject = [
    '$log',
    '$q',
    'ReportFactory',
    'ReportTemplatesService',
    'UtilsService'
  ];

  angular.module('component.reports')
    .directive('kzReportView', ReportViewDirective);
})();
