(function() {
  'use strict';

  function config($compileProvider, $logProvider, $provide,
                  KZ_ENVIRONMENT, _PACKAGE_VERSIONS) {
    if (KZ_ENVIRONMENT !== 'local') {
      $compileProvider.debugInfoEnabled(false);
      $compileProvider.commentDirectivesEnabled(false);
      $compileProvider.cssClassDirectivesEnabled(false);
    }
    // This may be added only once we are on >1.6.0
    // $logProvider.debugEnabled(false);
    $logProvider.debugEnabled(KZ_ENVIRONMENT === 'dev' || KZ_ENVIRONMENT === 'local');
    // $ravenProvider.development(true);
    // Raven.setExtraContext({
    //   versions: PACKAGE_VERSIONS
    // });

    $provide.decorator('$log', ['$delegate', function($delegate) {
      // Keep track of the original debug method, we'll need it later.
      var origDebug = $delegate.debug;
      var origInfo = $delegate.info;
      var origWarn = $delegate.warn;
      /*
       * Intercept the call to $log.debug() so we can add on
       * our enhancement. We're going to add on a date and
       * time stamp to the message that will be logged.
       */
      $delegate.debug = function() {
        var args = [].slice.call(arguments);
        args[0] = [new Date().toString(), ': ', args[0]].join('');

        window.kzLog = window.kzLog || [];
        window.kzLog.push('Debug: ' + args.join(', '));

        // We want to have only last 3
        var sz = Math.max(0, window.kzLog.length - 300);
        window.kzLog.splice(0, sz);

        // Send on our enhanced message to the original debug method.
        origDebug.apply(null, args);
      };

      $delegate.info = function() {
        var args = [].slice.call(arguments);
        args[0] = [new Date().toString(), ': ', args[0]].join('');

        window.kzLog = window.kzLog || [];
        window.kzLog.push('Info: ' + args.join(', '));

        // We want to have only last 3
        var sz = Math.max(0, window.kzLog.length - 300);
        window.kzLog.splice(0, sz);

        // Send on our enhanced message to the original debug method.
        origInfo.apply(null, args);
      };

      $delegate.warn = function() {
        var args = [].slice.call(arguments);
        args[0] = [new Date().toString(), ': ', args[0]].join('');

        window.kzLog = window.kzLog || [];
        window.kzLog.push('Warning: ' + args.join(', '));

        // We want to have only last 3
        var sz = Math.max(0, window.kzLog.length - 300);
        window.kzLog.splice(0, sz);

        // Send on our enhanced message to the original debug method.
        origWarn.apply(null, args);
      };

      return $delegate;
    }]);
  }

  config.$inject = [
    '$compileProvider',
    '$logProvider',
    '$provide',
    // '$ravenProvider',
    'KZ_ENVIRONMENT',
    'PACKAGE_VERSIONS'
  ];

  angular.module('blocks.utils')
    .config(config);
})();
