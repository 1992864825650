(function() {
  'use strict';

  function Cards(Sidebar) {
    var service = {};

    service.register = function() {
      var cards = [
        {
          id: 'columnsMappingInfo',
          title: 'Column Matching',
          directive: 'columns-mapping-info-card',
          options: {
            routes: ['epf.importer.new', 'epf.importer.edit']
          }
        },
        {
          id: 'resultsOverview',
          title: 'Results overview',
          directive: 'results-overview-card',
          options: {
            routes: ['epf.importer.view']
          }
        },
        {
          id: 'optionsUsed',
          title: 'Options',
          directive: 'options-used-card',
          options: {
            routes: ['epf.importer.view']
          }
        }
      ];

      Sidebar.registerCards(cards);
    };

    return service;
  }

  Cards.$inject = ['SidebarService'];

  angular.module('component.importer')
    .service('ImporterCards', Cards);
})();
