export const GoalsTypesTemplates = {
  'app/components/goals/partials/define-target.html': require(
    './partials/define-target.html'
  ).default,
  // 'app/components/goals/partials/define-filters.html': require(
  // './partials/define-filters.html').default,
  'app/components/goals/partials/define-conditions.html': require(
    './partials/define-conditions.html'
  ).default,
  'app/components/goals/directives/goal.wrapper.html': require(
    './directives/goal.wrapper.html'
  ).default,
  'app/components/goals/listview.html': require('./listview.html').default,
  'app/components/goals/listview-no-featured.html': require('./listview-no-featured.html').default,
  'app/components/goals/directives/goal.wrapper.no-featured.html': require(
    './directives/goal.wrapper.no-featured.html'
  ).default
};
