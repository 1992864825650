(function() {
  'use strict';

  function FAQController($state, $stateParams, FAQs, Auth, Utils,
                          Notify) {
    var ctrl = this;

    ctrl.faq = {};
    if ($stateParams.id) {
      FAQs.find($stateParams.id)
        .then(function(data) {
          ctrl.faq = data;
          Utils.setPageTitle('Edit frequently asked question: ' + ctrl.faq.question);
          ctrl.showDelete = true;
          ctrl.loaded = true;
        });
    } else {
      Utils.setPageTitle('New frequently asked question');
    }

    // this is a new faq Type, let's make sure it gets an ID
    if (!$stateParams.id) {
      ctrl.faq._id = Utils.guid();
      ctrl.faq.organisation = Auth.currentOrganisation();
      ctrl.loaded = true;
    }

    ctrl.save = function(isValid) {
      ctrl.formIsSubmitted = true;
      if (isValid) {
        var actionName = 'modified';
        if (ctrl.faq._rev === undefined) {
          actionName = 'created';
        }

        Utils.recordLog(ctrl.faq, actionName, Auth.currentUser());
        ctrl.faq.type = 'faq'; // Make sure this is faq and not something else
        FAQs.save(ctrl.faq)
          .then(function(data) {
            ctrl.faq._rev = data._rev;
            ctrl.formIsSaved = true;
            Notify.success('FAQ successfully saved!', 'Success');
            $state.go('epf.faqs.index');
          })
          .catch(Utils.showError);
      }
    };

    ctrl.remove = function() {
      Utils.swal({
        title: 'Are you sure you want to remove this faq?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
        function(isConfirm) {
          if (isConfirm) {
            FAQs.remove($stateParams.id)
              .then(function() {
                Notify.success('FAQ successfully removed!', 'Success');
                $state.go('epf.faqs.index');
              });
          }
        });
    };
  }

  FAQController.$inject = [
    '$state',
    '$stateParams',
    'FAQsService',
    'AuthService',
    'UtilsService',
    'NotifyService'
  ];

  angular.module('component.faqs')
    .controller('FAQController', FAQController);
})();
