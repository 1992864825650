(function() {
  'use strict';

  function ConfService($log, $q, $rootScope, Store, Cache) {
    var Service = function(docType) {
      var _this = this;
      this.docType = docType;
      this.store = Store;

      $log.debug('Store: Setting up invalidating listener', this.docType);
      $rootScope.$on('KZClearCache', function() {
        $log.debug('Invalidating conf service');
        _this._cached_flatten = undefined;
      });
    };

    Service.prototype.findAll = function(kzOpts) {
      return this.store.findAll(this.docType, kzOpts);
    };

    Service.prototype.save = function(doc) {
      return this.store.save(this.docType, doc);
    };

    Service.prototype.find = function(id, kzOpts) {
      return this.store.get(this.docType, id, kzOpts);
    };

    Service.prototype.getOneOf = function(kzOpts) {
      return this.store.getOneOf(this.docType, kzOpts);
    };

    Service.prototype.findByIds = function(ids, kzOpts) {
      return this.store.findKeys(this.docType, ids, kzOpts);
    };

    Service.prototype.remove = function(id) {
      return this.store.remove(this.docType, id);
    };

    Service.prototype.storeItems = function(docs) {
      return this.store.storeItems(this.docType, docs);
    };

    Service.prototype.findByCategoryId = function(category) {
      return this.flatten()
        .then(function(flat) {
          return flat[category];
        });
    };

    // Compatibility
    Service.prototype.findSubCategory = function(categoryId, options) {
      var _this = this;
      return this.findCategoryPath(categoryId)
        .then(function(path) {
          if (path.length === 0) {
            // Should we rather fail?
            return {};
          }

          return $q.all([$q.when(path), _this.find(path[0].rootId, options)]);
        })
        .then(function(result) {
          var row = result[0];
          var category = result[1];

          if (row.length === 1) {
            category.meta = row[0];
            return category;
          }

          var breadcrumb;
          for (var i = 1; i < row.length; i++) {
            breadcrumb = row[i];
            if (category === undefined) {
              return $q.reject({
                status: 404,
                message: 'Category ' + categoryId + 'not found in ' + result[1]._id
              });
            }
            category = _.find(category.categories, { _id: row[i].key });
          }

          if (breadcrumb) {
            category.meta = breadcrumb;
          }

          return category;
        });
    };

    Service.prototype.findChildCategories = function(categoryIds) {
      var _this = this;
      var ids = _.isArray(categoryIds) ? categoryIds : [categoryIds];
      return $q.all(_.map(ids, function(id) {
        return _this.findChildCategory(id);
      }))
      .then(function(result) {
        return _.flatten(result);
      });
    };

    /**
     * Returns all categories below the given one
     * @param  {uuid} categoryId  CategoryId
     * @return {Array}            Array of children
     */
    Service.prototype.findChildCategory = function(categoryId) {
      return this.findSubCategory(categoryId)
        .then(function(subCategory) {
          function emitCategories(category) {
            var categories = [category._id];
            if (category.categories) {
              // Get all categories from sub categories
              category.categories.forEach(function(item) {
                categories = categories.concat(emitCategories(item));
              });
            }

            return categories;
          }

          return emitCategories(subCategory);
        });
    };


    Service.prototype.findByCategory = function(category) {
      var _this = this;
      return this.findByCategoryId(category)
        .then(function(data) {
          return _this.get(data.rootId);
        });
    };

    Service.prototype.findCategoryPath = function(categoryId) {
      return this.flatten()
        .then(function(flat) {
          var category = flat[categoryId];
          if (category === undefined) {
            return $q.reject(
              { status: 404, message: 'Category ' + categoryId + ' not found' }
            );
          }

          var path = [];
          var parent = category;
          path.push(category);

          while (parent !== undefined) {
            parent = flat[parent.parent];
            if (parent !== undefined) {
              path.unshift(parent);
            }
          }

          return path;
        });
    };

    Service.prototype.flatten = function(kzOpts) {
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: this.docType + '-flatten',
        maxAge: 0,
        cached: true
      }, kzOpts);
      var func = this._flatten.bind(this, kzOpts);

      return Cache.cachedPromise(func, ext);
    };

    Service.prototype._flatten = function() {
      if (this._cached_flatten) {
        return $q.when(this._cached_flatten);
      }

      $log.debug('ConfService: Calling flatten on', this.docType);
      var _this = this;

      return _this.findAll()
        .then(function(data) {
          var flatten = {};

          var flat = function(categories, root, parent) {
            if (categories === undefined) {
              return;
            }

            categories.forEach(function(category, idx) {
              flatten[category._id] = {
                root: root.name,
                value: category.name,
                key: category._id,
                rootId: root._id,
                parent: parent._id,
                order: idx
              };

              flat(category.categories, root, category);
            });
          };

          data.forEach(function(item, idx) {
            flatten[item.doc._id] = {
              root: item.doc.name,
              value: item.doc.name,
              key: item.doc._id,
              rootId: item.doc._id,
              order: idx
            };

            flat(item.doc.categories, item.doc, item.doc);
          });

          _this._cached_flatten = flatten;
          return flatten;
        });
    };

    return Service;
  }

  ConfService.$inject = ['$log', '$q', '$rootScope', 'BaseConfStoreService', 'CacheService'];

  angular.module('blocks.services')
    .factory('ConfService', ConfService);
})();
