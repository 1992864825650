(function() {
  'use strict';

  function EmailTemplateComponentController(
    $q,
    $state,
    $uibModal,
    EmailTypes,
    EmailType,
    EmailTemplate,
    EmailTemplates,
    DEFAULT_TEMPLATE_ID,
    Form,
    Notify,
    Utils
  ) {
    var ctrl = this;

    ctrl.$onInit = function() {
      EmailTypes.find(ctrl.emailTypeId)
        .then(function(emailType) {
          ctrl.emailType = new EmailType(emailType);
          var templatePromise;
          if (ctrl.emailTemplateId === 'new') {
            Utils.setPageTitle('New custom email type');
            var duplicateFrom = ctrl.duplicateFrom || DEFAULT_TEMPLATE_ID;
            ctrl.isNew = true;
            if (duplicateFrom === DEFAULT_TEMPLATE_ID) {
              var tpl = _.assign({
                name: 'Custom',
                email_type: ctrl.emailTypeId,
                partials: ctrl.emailType.doc.defaults
              });
              templatePromise = $q.when(tpl);
            } else {
              templatePromise = EmailTemplates.duplicate(duplicateFrom)
                .then(function(obj) {
                  return obj;
                });
            }
          } else {
            ctrl.isNew = false;
            templatePromise = EmailTemplates.find(ctrl.emailTemplateId);
          }
          return templatePromise;
        })
        .then(function(emailTemplate) {
          ctrl.emailTemplate = new EmailTemplate(emailTemplate, ctrl.emailType);
          ctrl.templateModel = {
            name: ctrl.emailTemplate.doc.name
          };
          Utils.setPageTitle('New email type based on template: ' + ctrl.emailTemplate.doc.name);
          _.forEach(ctrl.emailTemplate.doc.partials || [], function(partial) {
            ctrl.templateModel[partial.id] = partial.value;
          });

          ctrl.setup();
        });
    };

    ctrl.setup = function() {
      var fields = [
        // {
        //   id: 'name',
        //   type: 'string',
        //   label: 'Name',
        //   required: true
        // }
      ];

      _.forEach(ctrl.emailType.doc.partials || [], function(partial) {
        fields.push({
          id: partial.id,
          type: partial.type === 'string' ? 'string' : 'wysiwyg',
          label: partial.label,
          required: true
        });
      });
      ctrl.templateForm = new Form(fields);
    };

    ctrl.modelToDoc = function(model) {
      var partials = [];
      _.forEach(ctrl.emailType.doc.partials || [], function(partial) {
        var value = model[partial.id];
        if (value) {
          partials.push({
            id: partial.id,
            value: model[partial.id]
          });
        }
      });
      return {
        partials: partials,
        name: model.name
      };
    };

    ctrl.submit = function() {
      if (ctrl.submitted) {
        return;
      }

      if (ctrl.form.$invalid) {
        ctrl.submitted = false;
        return;
      }

      ctrl.submitted = true;

      var data = ctrl.modelToDoc(ctrl.templateModel);

      return ctrl.confirmSave(data)
        .then(function() {
          _.assign(ctrl.emailTemplate.doc, data);

          var promise = ctrl.isNew
            ? EmailTemplates.create(ctrl.emailTemplate.doc)
            : EmailTemplates.save(ctrl.emailTemplate.doc);

          return promise;
        })
        .then(function(res) {
          ctrl.emailTemplate.doc._id = res.id;
          ctrl.emailTemplate.doc._rev = res.rev;
          if (ctrl.emailTemplate.doc._id === ctrl.emailType.currentTemplate().id) {
            return res;
          }
          return EmailTypes.setCurrent(ctrl.emailType.doc.id, ctrl.emailTemplate.doc._id)
            .then(function() {
              return EmailTypes.find(ctrl.emailTypeId);
            })
            .then(function(emailType) {
              ctrl.emailType = new EmailType(emailType);
              return res;
            });
        })
        .then(function(res) {
          Notify.success('Email template has been saved.', 'Success');
          if (ctrl.isNew) {
            return $state.go('epf.email-templates.email-template', {
              emailTypeId: ctrl.emailTypeId,
              emailTemplateId: res.id,
              duplicateFrom: null
            });
          }
          ctrl.isNew = false;
        })
        .catch(function(err) {
          if (err === 'dismissed') {
            Notify.error('Save cancelled', 'Warning');
            return;
          }

          Notify.error('Could not save: ' + err, 'Success');
        })
        .finally(function() {
          ctrl.submitted = false;
        });
    };

    ctrl.confirmSave = function(data) {
      var def = $q.defer();
      $uibModal.open({
        animation: true,
        template: '<email-type-preview email-type-id="ctrl.emailTypeId" ' +
                  'partials="ctrl.partials" on-close="$close(false)"></email-type-preview>' +
                  '<div class="modal-footer"><div class="row"><div class="col-sm-12">' +
                  '<button type="button" class="btn btn-success" ' +
                  'ng-click="$close(true)">Apply changes</button>' +
                  '<button type="button" class="btn btn-danger" ' +
                  'ng-click="$close(false)">Cancel</button></div></div></div>',
        controller: ['emailTypeId', 'partials', '$uibModalInstance',
          function(emailTypeId, partials, $uibModalInstance) {
            this.emailTypeId = emailTypeId;
            this.partials = partials;

            this.confirm = function() {
              $uibModalInstance.close(true);
            };
          }
        ],
        controllerAs: 'ctrl',
        resolve: {
          emailTypeId: function() {
            return ctrl.emailTypeId;
          },
          partials: function() {
            return data.partials;
          }
        },
        size: 'lg'
      }).result
      .then(function(result) {
        if (result) {
          def.resolve(result);
        } else {
          def.reject('dismissed');
        }
      })
      .catch(function(err) {
        console.log('Dismissed', err);
        def.reject('dismissed');
      });

      return def.promise;
    };

    ctrl.preview = function() {
      var data = ctrl.modelToDoc(ctrl.templateModel);
      $uibModal.open({
        animation: true,
        template: '<email-type-preview email-type-id="ctrl.emailTypeId" ' +
                  'partials="ctrl.partials" on-close="$close()"></email-type-preview>',
        controller: ['emailTypeId', 'partials',
          function(emailTypeId, partials) {
            this.emailTypeId = emailTypeId;
            this.partials = partials;
          }
        ],
        controllerAs: 'ctrl',
        resolve: {
          emailTypeId: function() {
            return ctrl.emailTypeId;
          },
          partials: function() {
            return data.partials;
          }
        },
        size: 'lg'
      }).result.catch(function(err) {
        console.log('Dismissed', err);
      });
    };

    ctrl.restore = function() {
      return Utils.confirm({
        title: 'This will override the form with the default values. ' +
               'Would you like to restore them?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Restore'
      }).then(function() {
        _.forEach(ctrl.emailType.doc.defaults || [], function(partial) {
          ctrl.templateModel[partial.id] = partial.value;
        });
      });
    };
  }

  EmailTemplateComponentController.$inject = [
    '$q',
    '$state',
    '$uibModal',
    'EmailTypesService',
    'EmailTypeFactory',
    'EmailTemplateFactory',
    'EmailTemplatesService',
    'DEFAULT_TEMPLATE_ID',
    'FormsService',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.emailTemplates')
    .component('emailTemplateComponent', {
      templateUrl: 'app/components/email-templates/email-template.html',
      controller: EmailTemplateComponentController,
      bindings: {
        emailTypeId: '<',
        emailTemplateId: '<',
        duplicateFrom: '<?'
      }
    });
})();
