(function() {
  'use strict';

  function DatabaseService(
    pouchDB,
    kzPouchDB,
    $q,
    $log,
    $rootScope,
    $timeout,
    $location,
    // eslint-disable-next-line no-unused-vars
    $document,
    md5,
    loadingBar,
    Auth,
    OidcService,
    Network,
    Notify,
    DetectBrowser,
    ClusterService,
    DATABASES,
    COUCHDB_URLS,
    DB_MODES,
    DB_ERRORS,
    AUTH_ERRORS,
    SERVER_CONFIG
  ) {
    var resetChangesDiff = 24 * 60 * 60 * 1000;
    var service = {};
    service._replications = {};
    service._changes = {};
    service._changesSeq = {};
    service._databases = {};
    service._userInfo = {};
    service._cachedDbs = {};
    service._cachedRawDbs = {};
    var _dbs = {};

    var listenToChanges = SERVER_CONFIG.listenToChanges;

    var getLocalAdapter = function() {
      if (window.cordova) {
        return 'cordova-sqlite';
      }

      if (!service.browser) {
        service.browser = DetectBrowser.detect();
        $log.debug(service.browser);
      }
      var browser = service.browser;
      if (browser && ['ios', 'safari'].indexOf(browser.name) !== -1 &&
          browser.version.split('.')[0] < 10) {
        return 'websql';
      }

      return undefined;
    };

    /**
     * Create pouchdb wrapper for given name
     * @param  {string} name Name of the database
     * @param  {boolean} raw Whether to force it to use vendor's pouchdb
     * @return {object}      Returns database
     */
    var createPouchDb = function(name, raw, options) {
      if (name === undefined) {
        $log.error('Trying to get undefined');
        return;
      }

      options = options || {};
      options.name = name;
      options.size = 50;
      options.ajax = {
        timeout: 60 * 1000,
        auto_compation: true
      };

      options.fetch = function(url, opts) {
        opts.credentials = 'include';
        opts.headers.set('accept', 'application/json');
        if (OidcService.organisation) {
          opts.headers.set('EAS-Organisation', OidcService.organisation);
        }
        if (OidcService.usernameHeader) {
          opts.headers.set('EAS-Username', OidcService.usernameHeader);
        }
        if (OidcService.bearer) {
          opts.headers.set('Authorization', OidcService.bearer);
        }

        return PouchDB.fetch(url, opts) // eslint-disable-line no-undef
          .then(function(response) {
            var ct = response.headers.get('content-type');
            if (ct && ct.indexOf('application/json') === -1) {
              return $q.reject({
                status: response.status,
                message: 'We couldn’t reach the server, please try that again'
              });
            }

            return response;
          });
      };

      // If name is not local, check for cordova use
      if (name.indexOf('http') !== 0) {
        options.adapter = getLocalAdapter();
        raw = true;
      }

      var db = _dbs[name + raw];
      if (db !== undefined && !db._destroyed) {
        return db;
      }

      // if (name.indexOf('undefined') !== -1) {
      //   debugger
      // }

      // if (name.indexOf('master') === -1) {
      //   debugger
      // }

      $log.debug('Creating a new db', name);
      if (raw) {
        try {
          db = pouchDB(options);
          _dbs[name + raw] = db;
          return db;
        } catch (err) {
          $log.error(err);
          Notify.error(err);
          return;
        }
      }


      // if (name.indexOf('undefined') > -1) {
      //   debugger;
      // }

      $log.debug('connecting to ' + name);

      try {
        db = kzPouchDB(options);
      } catch (err) {
        $log.error(err);
        Notify.error(err);
        db = undefined;
      }

      _dbs[name + raw] = db;
      return db;
    };

    var checkedDbs = [];

    var openDb = function(name) {
      var def = $q.defer();
      console.log('IDB: Checking db', name);
      if (!window.indexedDB) {
        Auth.noIDBSupport = true;
        def.reject(new Error('IndexedDB not found'));
        return def.promise;
      }

      var req = window.indexedDB.open('_pouch_' + name);
      req.onerror = function(err) {
        console.log('IDB: Error opening', err, name);
        return def.reject(err);
      };
      req.onsuccess = function(ev) {
        console.log('IDB: Success opening', name);
        def.resolve(ev.target.result);
      };
      return def.promise;
    };

    var deleteDb = function(name) {
      var def = $q.defer();
      if (!window.indexedDB) {
        Auth.noIDBSupport = true;
        def.reject(new Error('IndexedDB not found'));
        return def.promise;
      }
      var req = window.indexedDB.deleteDatabase('_pouch_' + name);
      req.onerror = function(err) {
        console.log('IDB: Error deleting', err);
        return def.reject(err);
      };
      req.onsuccess = function(ev) {
        console.log('IDB: Success deleting', ev.result);
        def.resolve(ev.result);
      };
      return def.promise;
    };

    var checkDb = function(name) {
      if (checkedDbs.indexOf(name) !== -1) {
        // console.log('IDB: Skipping check');
        return $q.when();
      }
      checkedDbs.push(name);

      try {
        return openDb(name)
          .then(function(db) {
            var stores = db.objectStoreNames.length;
            db.close();
            console.log('IDB: Found stores', stores);
            if (stores !== 7) {
              return deleteDb(name);
            }
          })
          .catch(function(err) {
            console.log('IDB: ERROR checking', err);
          })
          .then(function(res) {
            return res;
          });
      } catch (err) {
        console.log(err);
      }
    };

    /**
     * Public interface
     */

    /**
     * Return a database - use everywhere you need to access a db
     * @param  {string} name Specify whether user or organisation
     * @param  {string} type Specify sub type - shared, private, conf, main
     * @return {object}      Database object
     */
    service.get = function(name, type, options) {
      options = options || {};
      var dbName = service.getName(name, type),
          prefix = service._getDbPrefix('remote'),
          fullName = prefix + dbName;

      var db = this._cachedRawDbs[fullName];
      if (db === undefined) {
        this._cachedRawDbs[fullName] = createPouchDb(fullName, options.raw);
      }
      return this._cachedRawDbs[fullName];
    };

    /**
     * Return raw name of the database
     * @param  {string} name Specify whether user or organisation
     * @param  {string} type Specify sub type - shared, private, conf, main
     * @return {string}      Name of the database
     */
    service.getName = function(name, type) {
      var dbs = service._getDatabases();
      return type ? dbs[name][type] : dbs[name];
    };

    /**
     * Sets up long running processes such as continuous
     * replication or listening to changes
     */
    service.finalize = function() {
      // if (Network.isOffline()) {
      //   return;
      // }

      if (service.requiredUser !== undefined && service.requiredUser !== Auth.currentUser()) {
        return $q.reject(AUTH_ERRORS.differentUser);
      }

      // if (service._getDbLocation() === 'local') {
      //   service.replicate();
      // } else {
      // }
      service.changes();

      if (service._watched === undefined) {
        $rootScope.$on('SettingsChanged', service._settingsChanged);

        $rootScope.$on('BecomeOnline', function() {
          $log.debug('Became online, starting');
          $rootScope.$broadcast('ResetChangesTimeout');
        });

        service._watched = true;
      }
    };

    /**
     * Cancels all long running processes
     */
    service.cancel = function(options) {
      return $q.all([
        service.cancelReplication(),
        service.cancelChanges(options)
      ]);
    };

    /**
     * Public method to retrieve user info object
     */
    service.userInfo = function() {
      return service._userInfo;
    };

    /**
     * Return information whether an item has been synced to backen or not
     * @param  {id} id      Document ID
     * @return {Promise}    Promise returning state
     */
    service.getSyncState = function(id) {
      // This should be used only if using local
      $log.warn('DB.getSyncState: deprecated');
      if (service._getDbLocation() === 'remote') {
        return $q.when('synced');
      }

      var dbs = service._getDatabases();
      var db = createPouchDb(dbs.user.syncdb, true);

      return db.get(id)
        .then(function(doc) {
          return doc.state;
        })
        .catch(function(error) {
          if (error.status === 404) {
            return 'synced';
          }

          console.log(error);
          return 'error';
        });
    };

    /**
     * Set state of an item, usually upon an event from replication
     * @param {id} id         Document ID
     * @param {string} state  New state to set
     */
    service.setSyncState = function(id, state) {
      // This should be used only if using local
      $log.warn('DB.setSyncState: deprecated');
      if (service._getDbLocation() === 'remote') {
        return $q.when();
      }

      var dbs = service._getDatabases();
      var db = createPouchDb(dbs.user.syncdb, true);

      return db.get(id)
        .then(function(doc) {
          if (state === 'synced') {
            doc._deleted = true;
          }

          doc.state = state;
          return db.put(doc);
        })
        .catch(function(error) {
          $log.error(id + ' ' + error);
        });
    };

    /**
     * Private functions
     */

    /**
     * Return whether it is allowed to be in offline mode,
     * used only if network is down
     * @return {object} promise
     */
    service._isOfflineAllowed = function() {
      if (!Network.isOffline()) {
        return $q.resolve();
      }
      if (!service.isOfflineCapable()) {
        return $q.reject(DB_ERRORS.offlineNotAllowed);
      }

      return $q.resolve();
    };

    service._checkStorageMode = function() {
      if (Auth.noIDBSupport !== undefined) {
        return $q.when();
      }
      var def = $q.defer();

      var testDb = window.indexedDB.open('test');
      testDb.onerror = function(err) {
        console.log('IDB: check failed. indexedDB not available', err);
        Auth.noIDBSupport = true;
        return def.resolve();
      };

      testDb.onsuccess = function(ok) {
        console.log('IDB: check ok', ok);
        return def.resolve();
      };

      return def.promise;

      // $log.debug('Checking storage mode');
      // Auth.noIDBSupport = 'checking';
      //
      // return checkDb('check')
      //   .then(function() {
      //     var db;
      //     try {
      //       db = createPouchDb('check', true);
      //     } catch (err) {
      //       Auth.noIDBSupport = true;
      //       return $q.when();
      //     }
      //
      //     if (db === undefined) {
      //       Auth.noIDBSupport = true;
      //       return $q.when();
      //     }
      //
      //     return db.info()
      //       .catch(function() {
      //         // Lets try it again in case they hit allow
      //         return db.info();
      //       })
      //       .then(function(info) {
      //         Auth.DBAdapter = info.adapter;
      //         Auth.noIDBSupport = false;
      //         return db.destroy();
      //       })
      //       .catch(function(err) {
      //         if (err && err.status === 500) {
      //           Auth.noIDBSupport = true;
      //         }
      //       });
      //   })
      //   .catch(function(err) {
      //     $log.warn(err);
      //   });
    };

    /**
     * Set actual mode
     *
     * This should take in account that we can be in a pending state.
     * The point is that it should always reach the preferredMode
     */
    service._setActualMode = function() {
      $log.debug('Setting actual mode');
      Auth.processSettings();
      service.actualMode = service.actualMode || Auth.preferredMode || 'online';

      if (!service.isOfflineCapable()) {
        service.actualMode = 'online';
      }

      return $q.when();
    };

    /**
     * Check whether user has private database
     *
     * If offline it will reject sync request
     * If online it will trigger registering
     *
     * @return {Promise} Returns a promise
     */
    service._hasPrivateDatabase = function() {
      $log.warn('DB._hasPrivateDatabase: deprecated');
      $log.debug('Checking private database');
      var privateDatabase = service._getPrivateName();

      return service._checkDatabase(privateDatabase)
        .catch(function(error) {
          if (error.status >= 500) {
            return $q.reject(error);
          }

          // This is returned if private database is missing, we should handle registering
          if (error.status === 404) {
            return $q.reject(DB_ERRORS.privateDbNotOnBackend);
          }

          return $q.reject(DB_ERRORS.privateDbDoNotExists);
        });
    };

    /**
     * Check for user and org in the query string
     */
    service._checkCredsInQuery = function() {
      var search = $location.search();
      if (search.org !== undefined) {
        Auth.setOrganisation(search.org);
      }

      service.requiredUser = search.user;
    };

    /**
     * Check whether user has an organisation set
     *
     * If not it will try to set it if there is only one
     * @return {Promise} Returns a promise
     */
    service._hasOrganisationSet = function() {
      $log.debug('Checking organisation');
      if (Auth.currentOrganisation() === undefined) {
        return $q.reject(DB_ERRORS.organisationNotSet);
      }
    };

    /**
     * Check whether user has shared and conf database for chosen organisation
     *
     * @return {Promise} Returns a promise
     */
    service._hasSharedAndOrgConf = function() {
      $log.warn('DB._hasSharedAndOrgConf: deprecated');
      $log.debug('Checking presense of shared and org db');
      var databases = service._getDatabases();
      var sharedDatabase = databases.user.shared;
      var orgConfDatabase = databases.organisation.conf;

      return $q.all([
        service._checkDatabase(sharedDatabase),
        service._checkDatabase(orgConfDatabase)
      ])
        .catch(function(err) {
          if (err.status === 404) {
            return $q.reject(DB_ERRORS.sharedDbDoNotExists);
          }

          return $q.reject(err);
        });
    };

    service._setStarted = function() {
      $log.debug('Setting app as ready');
      service._started = true;
    };

    /**
     * Original methods
     */

    /**
     * Build databases object
     * @param  {string} username     Username
     * @param  {string} organisation Organisation ID
     * @return {object}              Databases object
     */
    function buildDatabasesToSync(username, organisation) {
      var databases = {
        user: {},
        organisation: {},
        master: DATABASES.master,
        queue: DATABASES.queue
      };

      if (username) {
        var userHash = md5.createHash(username);
        databases.user.private = DATABASES.user.private.replace('<USERID>', userHash);
        databases.user.syncdb = DATABASES.user.syncdb.replace('<USERID>', userHash);
      }

      if (organisation && service._userInfo) {
        var organisationDBs = service.getDatabasesFromInfo(organisation);
        if (organisationDBs) {
          databases.user.shared = organisationDBs.user.shared;
          databases.organisation.conf = organisationDBs.organisation.conf;
          databases.organisation.main = organisationDBs.organisation.main;
        }
      }

      return databases;
    }

    service.getDBUrl = function() {
      var url = ClusterService.getCurrentUrls().ldb;
      return _.trimEnd(url, '/');
    };

    function replicateDatabase(database) {
      $log.warn('DB.replicateDatabase: deprecated');
      var remote = service.getDBUrl();
      $log.debug('Replicating database');
      var localDb = createPouchDb(COUCHDB_URLS.local + database, true);
      var emitter = localDb.sync(remote + database)
        .on('error', function(error) {
          console.log('Sync error: ' + error);
        });

      return emitter.$promise;
    }

    service.destroyDbs = function() {
      $log.warn('DB.destroyDbs: deprecated');
      return service.cancelReplication()
        .then(function() {
          var databases = service._getDatabases();
          var databasesToSync = [
            databases.user.shared,
            databases.user.private,
            databases.organisation.conf
          ];
          return $q.all(databasesToSync.map(function(dbName) {
            return createPouchDb(COUCHDB_URLS.local + dbName, true).destroy();
          }));
        });
    };

    service.IsPendingOffline = function() {
      if (service.currentConnectivityMode() === DB_MODES.pendingOffline) {
        service.switchToOffline();
      }
    };

    service.switchToOnline = function(destroy) {
      if (!service._started) {
        return;
      }

      if (service.actualMode === 'online') {
        return $q.when();
      }

      service.actualMode = DB_MODES.pendingOnline;

      return service.cancelReplication()
        .then(function() {
          if (destroy) {
            return service.destroyDbs();
          }
        })
        .then(function() {
          service.actualMode = DB_MODES.online;
          service._reset();
          service.finalize();
          $log.debug('Switched to online');
        });
    };

    service.switchToOffline = function() {
      if (!service._started) {
        return;
      }

      if (service.actualMode === 'offline') {
        return $q.when();
      }

      service.actualMode = DB_MODES.pendingOffline;
      return service.cancelChanges()
        .then(service.replicateOnce)
        .then(service.isDbReady)
        .then(function() {
          service.actualMode = DB_MODES.offline;
          service.finalize();
          $log.debug('Switched to offline');
        });
    };

    service.replicateUserPrivateDatabase = function() {
      $log.warn('DB.replicateUserPrivateDatabase: deprecated');
      $log.debug('Replicating private database');
      var databases = service._getDatabases();

      if (databases.user.private === undefined) {
        return $q.reject({ status: 401, message: 'Unknown user' });
      }

      loadingBar.start();
      var def = $q.defer();

      replicateDatabase(databases.user.private).then(function() {
        return service._loadUserInfo();
      }).then(function() {
        def.resolve();
        loadingBar.complete();
        $log.debug('Finished private');
      });
      return def.promise;
    };

    service.setupUserDatabases = function(username, organisation) {
      $log.warn('DB.setupUserDatabases: deprecated');
      $log.debug('Loading databasesyy');
      if (organisation === undefined) {
        return $q.reject(DB_ERRORS.organisationNotSet);
      }

      var databases = buildDatabasesToSync(username, organisation);
      service._databases = databases;

      // sync the user databases
      var promises = [],
          databasesToSync = [databases.user.shared, databases.organisation.conf],
          replicate = function(dbs) {
            var remote = service.getDBUrl();
            var localDb = createPouchDb(COUCHDB_URLS.local + dbs, true);
            var emitter = localDb.sync(remote + dbs)
              .on('error', function(err) {
                console.log('Error: ' + err);
              })
              .on('denied', function(err) {
                console.log('Error: ' + err);
              });
            return emitter.$promise;
          };

      loadingBar.start();

      _.forEach(databasesToSync, function(db) {
        promises.push(replicate(db));
      });

      return $q.all(promises)
        .then(function() {
          loadingBar.complete();
          $log.debug('Finished conf and shared');
        })
        .catch(function(errors) {
          return $q.reject(errors);
        });
    };

    service.replicateOnce = function() {
      $log.warn('DB.replicateOnce: deprecated');
      $log.debug('Starting once replication');

      var databases = service._getDatabases();

      if (_.isEmpty(databases)) {
        return;
      }

      var databasesToSync = [
        databases.user.shared,
        databases.user.private,
        databases.organisation.conf
      ];

      var replicate = function(dbs) {
        var localDb = createPouchDb(COUCHDB_URLS.local + dbs, true);
        var remote = service.getDBUrl();
        return localDb.sync(remote + dbs).$promise;
      };

      return $q.all(databasesToSync.map(replicate));
    };

    service.replicate = function() {
      $log.warn('DB.replicate: deprecated');
      if (service.actualMode !== DB_MODES.offline) {
        return;
      }

      $log.debug('Starting live replication');

      var databases = service._getDatabases();

      if (_.isEmpty(databases)) {
        return;
      }

      var databasesToSync = [
        databases.user.shared,
        databases.user.private,
        databases.organisation.conf
      ];

      var replicate = function(dbs) {
        var remote = service.getDBUrl();
        var localDb = createPouchDb(COUCHDB_URLS.local + dbs, true);
        var emitter = localDb.sync(remote + dbs,
          {
            live: true,
            retry: true
          })
          .on('change', function(info) {
            if (info.direction === 'push') {
              info.change.docs.forEach(function(item) {
                service.setSyncState(item._id + item._rev, 'synced')
                  .finally(function() {
                    $rootScope.$broadcast('DBPushChange', { id: item._id });
                  });
              });
            } else {
              info.change.docs.forEach(function(item) {
                $log.debug('Received', item._id, item._rev);
                $rootScope.$broadcast('DBPullChange', { id: item._id, doc: item });
              });
            }
          })
          .on('error', function(error) {
            // This should be called and is not
            // Notify.warning("Couch sync error");
            // $rootScope.$broadcast('NetworkOffline');
            console.log(error);
          })
          .on('paused', function() {
            $log.debug('paused');

            // replication paused (e.g. user went offline)
          })
          .on('active', function() {
            $log.debug('active');

            // replicate resumed (e.g. user went back online)
          })
          .on('denied', function(info) {
            console.log(info);
            Notify.error('Failed replicating ' + info.doc.id + ': ' + info.doc.reason, false);

            // a document failed to replicate, e.g. due to permissions
          })
          .on('complete', function() {
            // console.log(info);
            // console.log('complete');
            // handle complete

          });

        return emitter;
      };

      _.forEach(databasesToSync, function(db) {
        if (db !== undefined) {
          if (!(db in service._replications)) {
            $log.debug('Setting replication for ' + db);
            service._replications[db] = replicate(db);
          }
        }
      });
    };

    // var chcount = 0;
    service.changes = function() {
      // if (service.actualMode !== DB_MODES.online) {
      //   return;
      // }
      if (!listenToChanges) {
        return;
      }

      $log.debug('Starting listening to changes');
      var databases = service._getDatabases();

      // only do the replications if there are databases setup
      if (_.isEmpty(databases)) {
        return;
      }

      var databasesToSync = [
        databases.master
      ];
      var listen = function(dbs) {
        var remote = service.getDBUrl();
        var localDb = createPouchDb(remote + dbs, true),
            timeout = 5000,
            ticks = 0,
            startTimer,
            emitter;

        startTimer = function() {
          service._changesTimeouts = service._changesTimeouts || {};
          service._changesTimeouts[dbs] = $timeout(emitter, timeout);
        };

        emitter = function() {
          timeout = Math.round(Math.min(timeout * 1.5, 60000));
          ticks += 1;

          if (service.changesPaused) {
            startTimer();
            return;
          }

          if (Network.isNetworkOffline()) {
            startTimer();
            return;
          }

          if (_.isEmpty(Auth.currentUser())) {
            $log.warn('Trying to get changes even without user');
            startTimer();
            return;
          }

          // chcount += 1;
          localDb.changes({
            filter: 'public/by_user',
            query_params: {
              organisation: Auth.currentOrganisation()
            },
            since: service._changesSeq[dbs] || 'now',
            include_docs: true
          })
            .then(function(info) {
              // $log.debug('Asking for changes', chcount, timeout);
              service._changesSeq[dbs] = info.last_seq;
              _.forEach(info.results, function(info) {
                $log.debug('Broadcasting', info.doc._id, info.doc._rev);
                // $rootScope.$broadcast('ResetChangesTimeout');
                $rootScope.$broadcast('DBPullChange', { id: info.id, doc: info.doc });
              });

              if (ticks < 10) {
                $log.debug('Changes: Starting timer', ticks, timeout);
                startTimer();
              } else {
                $log.debug('Changes: Skip changes', ticks, timeout);
              }
            })
            .catch(function(error) {
              console.log(error);
              service._changesSeq = {};
              startTimer();
            });
        };

        $rootScope.$on('ResetChangesTimeout', function() {
          $log.debug('Changes: Resetting timeout');
          service._changesSeq = {};
          timeout = 1000;
          ticks = 0;
          if (service._changesTimeouts[dbs]) {
            $timeout.cancel(service._changesTimeouts[dbs]);
          }

          startTimer();
        });

        emitter();
        return emitter;
      };

      service.changesPaused = false;
      _.forEach(databasesToSync, function(db) {
        if (db !== undefined) {
          if (!(db in service._changes)) {
            $log.debug('Setting changes for ' + db);
            service._changes[db] = listen(db);
          }
        }
      });
    };


    $log.warn('Adding listener for db visibility');
    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'hidden') {
        $log.debug('Pausing changes');
        service.changesPaused = true;
        service.pausedAt = new Date();
      } else {
        $log.debug('Restarting changes');
        var diff = new Date() - service.pausedAt;
        if (service.pausedAt && diff > resetChangesDiff) {
          $log.debug('Resetting changes ' + diff + ' is more than ' + resetChangesDiff);
          service._changesSeq = {};
        }
        service.changesPaused = false;
      }
    });


    service.cancelReplication = function() {
      $log.warn('DB.cancelReplication: deprecated');
      return $q.all(_.values(service._replications)
        .map(function(repl) {
          return repl.cancel();
        }))
        .then(function() {
          service._databases = {};
          service._replications = {};
          service._dbs = {};
          $log.debug('Stopping replication');
        });
    };

    service.cancelChanges = function(options) {
      if (options && options.pause) {
        service.changesPaused = true;
        return $q.when();
      }

      _.forOwn(service._changesTimeouts, function(value) {
        $timeout.cancel(value);
      });

      service._changesTimeouts = {};
      service._changes = {};
      return $q.when();
    };

    service.isDbReady = function() {
      $log.warn('DB.isDbReady: deprecated');
      var databases = service._getDatabases();
      var databasesToSync = [
        databases.user.shared,
        databases.user.private,
        databases.organisation.conf
      ];

      // only do the replications if there are databases setup
      if (_.isEmpty(databases)) {
        return;
      }
      var remote = service.getDBUrl();

      return $q.all(databasesToSync.map(function(dbName) {
        var db = createPouchDb(remote + dbName, true);

        return db.info()
          .then(function() {
            return db.query('epf/by_type');
          })
          .then(function() {
            return db.query('epf/by_org_and_type');
          })
          .then(function() {
            $log.debug(dbName + ' ready');
          });
      }));
    };

    // This is to become persistent
    service.allowLocalDb = function(kzOpts) {
      kzOpts = kzOpts || {};
      if (kzOpts.alwaysAllow) {
        return $q.when();
      }

      return Auth.getStorageMode()
        .then(function(mode) {
          if (['session', 'persistent'].indexOf(mode) === -1) {
            return $q.reject();
          }
        });
    };

    service.getDb = function(name, options, kzOpts) {
      options = options || {};
      options.name = 'kz_' + name;

      var res = this._cachedDbs[name];
      if (res !== undefined) {
        return res;
      }

      var prom = service.allowLocalDb(kzOpts)
        .catch(function() {
          return $q.reject({ status: 501, message: 'Access to local data not allowed' });
        })
        .then(function() {
          try {
            return checkDb(options.name);
          } catch (err) {
            console.log(err);
          }
        })
        .then(function() {
          try {
            return createPouchDb(options.name, true, options);
          } catch (err) {
            window.noPouch = true;
            return $q.reject({ status: 500, message: 'Could not create local db' });
          }
        });

      this._cachedDbs[name] = prom;
      return this._cachedDbs[name];
    };

    service.getLocalDbName = function(name, username, org) {
      if (username === undefined) {
        username = Auth.currentUser();
      }

      if (org === undefined) {
        org = Auth.currentOrganisation();
      }

      if (_.isEmpty(username) || _.isEmpty(org)) {
        return $q.reject({ status: 403, message: 'Undefined user' });
      }

      var userHash = md5.createHash(org + ':' + username);
      return $q.when(userHash + '_' + name);
    };

    service.getLocalDb = function(name, options, kzOpts) {
      kzOpts = kzOpts || {};
      var username = Auth.currentUser(),
          org = kzOpts.organisation || Auth.currentOrganisation();

      return service.getLocalDbName(name, username, org)
        .then(function(dbName) {
          return service.getDb(dbName, options, kzOpts);
        });
    };

    service.destroyLocalDb = function(name, options, kzOpts) {
      var dbName;
      return service.getLocalDb(name, options, kzOpts)
        .then(function(db) {
          dbName = db.name;
          $log.warn('Destroying local db: ' + name + ': ' + dbName);
          return db.destroy();
        })
        .catch(function(err) {
          $log.warn('Could not remove ' + dbName, err);
          var websqlName = '_pouch__websqldb__pouch_' + dbName;
          window.localStorage.removeItem(websqlName);
        });
    };

    /**
     * Reviewed private
     */

    /**
     * Return name of the private database, this is called before
     * we have a user info. This is the guessing that has to match the one on backend
     * @return {string} Name of private database
     */
    service._getPrivateName = function() {
      var username = Auth.currentUser(),
          userHash = md5.createHash(username);
      return DATABASES.user.private.replace('<USERID>', userHash);
    };

    /**
     * Returns a private database, this is called before
     * we have a user info.
     * @return {object} Database
     */
    service._getPrivate = function() {
      var prefix = service._getDbPrefix();
      var dbName = service._getPrivateName();
      return createPouchDb(prefix + dbName);
    };

    /**
     * Return database location based on actual network and preference status
     * @return {string} Location to be used to retrieve the correct dbName
     */
    service._getDbLocation = function() {
      var actualMode = service.actualMode || Auth.preferredMode;

      // If we are offline, always return local, we have
      // already passed the check whether we can be
      if (Network.isOffline()) {
        return 'local';
      }

      // If we have offline enabled or we are still changing to online, use local
      if ([DB_MODES.offline, DB_MODES.pendingOnline].indexOf(actualMode) > -1) {
        return 'local';
      }

      return 'remote';
    };

    /**
     * Returns prefix used when getting the actual database
     * @param  {string} [location] Location specified if force is needed
     * @return {string}            Prefix for couch
     */
    service._getDbPrefix = function(location) {
      location = location || service._getDbLocation();
      if (location === 'remote') {
        return service.getDBUrl() + '/';
      }
      return COUCHDB_URLS[location];
    };

    /**
     * Check whether there is a database present.
     *
     * Either used to indicate that we should do initial sync or that a user does
     * not have an acount
     * @param  {string} dbName Name of the database to check
     * @return {Promise}       Returns a promise
     */
    service._checkDatabase = function(dbName) {
      var prefix = service._getDbPrefix();
      var dbLocation = service._getDbLocation();
      var options = {};

      // This is needed otherwise it fails badly and does not end in catch
      if (dbLocation === 'remote') {
        options.skipSetup = true;
      }

      var db = createPouchDb(prefix + dbName, true, options);
      if (_.isUndefined(db)) {
        return $q.reject({ status: 500, message: 'No idb support' });
      }

      return db.info()
        .catch(function(err) {
          if (service._getDbLocation() === 'local') {
            return $q.reject(
              { status: 500, message: 'Could not get db info - possibly corrupted' }
            );
          }

          if (err.status === 404) {
            $log.warn('FIXME - Does this mean we should start registering?');
            return $q.reject({ status: 404, message: 'Database does not exist' });
          }

          if (err.status === 401) {
            $log.warn('User is logged in but does not have a permission to talk to the db');
            return $q.reject(
              { status: 500, message: 'You do not have required permissions to log in' }
            );
          }

          return $q.reject(err);
        })
        .then(function(info) {
          if (service._getDbLocation() === 'local' && info.doc_count === 0) {
            return $q.reject({ status: 489, message: 'Database does not exist' });
          }
        });
    };

    /**
     * Load user information
     *
     * It should contain information about profiles and organisations
     * @return {Promise} returns a promise
     */
    service._loadUserInfo = function() {
      delete service._userInfo;
      var userPrivateDb = service._getPrivate();
      return userPrivateDb.query('epf/by_type',
        {
          key: 'user_info',
          include_docs: true
        })
        .then(function(data) {
          if (data.rows.length === 1) {
            service._userInfo = data.rows[0].doc;
          } else if (data.rows.length === 0) {
            return $q.reject(DB_ERRORS.UserInfoDoNotExists);
          } else {
            $log.warn('Multiple infos found - using first one');
            service._userInfo = data.rows[0].doc;
          }

          return service._userInfo;
        })
        .catch(function(error) {
          if (error.status === 404) {
            return $q.reject(DB_ERRORS.UserInfoDoNotExists);
          }

          return $q.reject(error);
        });
    };

    /**
     * Return or load and return databases settings
     * @return {[type]} [description]
     */
    service._getDatabases = function() {
      if (
        service._databases.organisation === undefined ||
        service._databases.organisation.conf === undefined
      ) {
        service._loadDatabases();
      }

      if (service._loadedFor !== Auth.currentUser() + '-' + Auth.currentOrganisation()) {
        service._loadDatabases();
      }

      return service._databases;
    };

    /**
     * Build databases based on username and organisation
     * @return {object} Databases object
     */
    service._loadDatabases = function() {
      var username = Auth.currentUser(),
          organisation = Auth.currentOrganisation(),
          databases = buildDatabasesToSync(username, organisation);

      service._loadedFor = username + '-' + organisation;
      service._databases = databases;
      service._dbs = {};
    };

    service._settingsChanged = function(_event, settings) {
      if (settings.preferredMode === Auth.MODES.online && service.actualMode === DB_MODES.offline) {
        service.switchToOnline(true);
      }

      if (settings.preferredMode === Auth.MODES.offline && service.actualMode === DB_MODES.online) {
        service.switchToOffline();
      }
    };

    service._reset = function() {
      service._databases = {};
      service._dbs = {};
    };

    /**
     * Public used and known
     */

    /**
     * Retrieves databases from user info object
     * @param  {string} [organisation] Organisation ID
     * @return {object}                Databases object
     */
    service.getDatabasesFromInfo = function(organisation) {
      var org = organisation || Auth.currentOrganisation(),
          info = service.userInfo();

      if (info.databases === undefined) {
        return;
      }

      if (org && 'databases' in info && org in info.databases) {
        return info.databases[org];
      }

      $log.debug(info);
      $log.debug('Unknown database: ' + org);
    };

    /**
     * Retrieve organisation list from user unfi object
     * @param  {string} [organisation] Organisation ID
     * @return {object}              Object organisation: title
     */
    service.getOrganisationFromInfo = function(organisation) {
      var org = organisation || Auth.currentOrganisation(),
          info = service.userInfo();

      if (org && info.databases && org in info.databases) {
        return info.organisations[org];
      }

      $log.warn('Unknown database: ' + org);
    };

    /**
     * Return the size of all the databases that need to sync
     */
    service.checkSyncSize = function() {
      var databases = service._getDatabases();

      if (_.isEmpty(databases)) {
        return;
      }

      var databasesToSync = [
        databases.user.shared,
        databases.user.private,
        databases.organisation.conf
      ];

      var promises = [];
      var remote = service.getDBUrl();

      _.forEach(databasesToSync, function(database) {
        if (database !== undefined) {
          var db = pouchDB(remote + database),
              promise = db.info()
                .then(function(result) {
                  return result;
                });

          promises.push(promise);
        }
      });

      return $q.all(promises)
        .then(function(data) {
          var result = 0;
          for (var i = 0; i < data.length; i++) {
            if (data[i] && data[i].disk_size) {
              result += data[i].disk_size;
            }
          }

          return result;
        });
    };

    /**
     * Detect whether we can use offline mode
     * @return {Boolean} [description]
     */
    service.isOfflineCapable = function() {
      if (window.cordova || window.indexedDB) {
        return true;
      }

      return false;
    };

    return service;
  }

  DatabaseService.$inject = [
    'pouchDB',
    'kzPouchDB',
    '$q',
    '$log',
    '$rootScope',
    '$timeout',
    '$location',
    '$document',
    'md5',
    'cfpLoadingBar',
    'AuthService',
    'OidcService',
    'NetworkService',
    'NotifyService',
    'DetectBrowser',
    'ClusterService',
    'DATABASES',
    'COUCHDB_URLS',
    'DB_MODES',
    'DB_ERRORS',
    'AUTH_ERRORS',
    'SERVER_CONFIG'
  ];

  angular.module('blocks.db')
    .service('DatabaseService', DatabaseService);
})();
