(function() {
  'use strict';

  function Slot($state, $transitions, HELPTEXTS) {
    return {
      restrict: 'A',
      template: '<div class="row row-bordered">' +
        '<div class="col-sm-12" ng-bind-html="helpText"></div>' +
      '</div>',
      link: function(scope) {
        scope.helpText = HELPTEXTS[$state.current.name];
        scope.hidden = _.isUndefined(scope.helpText);

        // scope.$on('$stateChangeSuccess', function() {
        $transitions.onSuccess({}, function() {
          scope.helpText = HELPTEXTS[$state.current.name];
          scope.hidden = _.isUndefined(scope.helpText);
        });
      }
    };
  }

  Slot.$inject = ['$state', '$transitions', 'HELPTEXTS'];

  angular.module('layout.sidebar')
    .directive('sidebarHelp', Slot);
})();
