(function() {
  'use strict';

  function NetworkLink() {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'app/blocks/network/network.link.html'
    };
  }

  angular.module('blocks.network')
    .directive('kzNetworkLink', NetworkLink);
})();
