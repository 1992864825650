(function() {
  'use strict';

  function SingleButton($q) {
    return {
      restrict: 'AE',
      scope: {
        action: '=',
        klass: '@'
      },
      templateUrl: 'app/blocks/widgets/buttons/singlebutton.html',
      link: function(scope) {
        /**
         * Construct the class name from options.
         * The options are:
         * type - a button or a link
         * color - what color the buttons should have
         * scopeClass - if the scope passes some class as a string
         * additionalClasses - if the button has any additional classes as a string
         * @param  {Object} opts The object with the options.
         * @return {String}     The final class name as a string.
         */
        var constructClassName = function(opts) {
          var defaults = {
            type: opts.type || 'button',
            color: opts.color || 'success',
            scopeClass: opts.scopeClass || 'btn',
            typeClasses: opts.type === 'button' ? 'btn btn-' : 'text-',
            additionalClasses: opts.additionalClasses
          };

          var className = _.isUndefined(opts.type)
            ? defaults.scopeClass
            : defaults.typeClasses + defaults.color;

          return defaults.additionalClasses
            ? className + ' ' + defaults.additionalClasses
            : className;
        };

        scope.className = constructClassName({
          type: scope.action.type,
          color: scope.action.color,
          scopeClass: scope.klass,
          additionalClasses: scope.action.klass
        });

        scope.mouseUp = function() {
          if (_.isFunction(scope.action.mouseUp)) {
            scope.action.mouseUp();
          }
        };

        scope.doAction = function() {
          if (scope.modal) {
            scope.modal.dismiss('cancel');
          }

          scope.loading = true;
          $q.when(scope.action.onClick())
            .finally(function() {
              scope.loading = false;
            });
        };
      }
    };
  }

  SingleButton.$inject = ['$q'];

  angular.module('blocks.widgets')
    .directive('kzSingleButton', SingleButton);
})();
