(function() {
  'use strict';

  function kzRefineFilter($timeout) {
    return {
      restrict: 'AE',
      scope: {
        list: '=',
        refineBy: '@',
        orderByTitle: '@',
        clearRefineTitle: '@'
      },

      templateUrl: 'app/blocks/widgets/search/kz-refine-filter.html',
      link: function(scope) {
        scope.$watch('list', function() {
          if (_.isUndefined(scope.list)) {
            return;
          }

          scope.facet = _.find(scope.list.search.facetly.facets, function(item) {
            return item.id === scope.refineBy;
          });

          scope.orderGroups = _.pickBy(scope.list.search.orderGroups, function(orderGroupValue) {
            return !orderGroupValue.hideFromSelection;
          });

          scope.orderGroupsCount = _.keys(scope.orderGroups).length;
        });

        scope.clearAll = function() {
          scope.list.searchModel.filteredBy = scope.list.searchModel.filteredBy || {};
          delete scope.list.searchModel.filteredBy[scope.refineBy];
          $timeout(function() {
            scope.list.doSearch();
          });
        };

        scope.selectOption = function(option) {
          scope.list.searchModel.filteredBy = scope.list.searchModel.filteredBy || {};

          if (scope.list.searchModel.filteredBy[scope.refineBy] === option) {
            delete scope.list.searchModel.filteredBy[scope.refineBy];
          } else {
            scope.list.searchModel.filteredBy[scope.refineBy] = option;
          }

          $timeout(function() {
            scope.list.doSearch();
          });
        };

        scope.selectOrder = function(option) {
          scope.list.searchModel.orderBy = option;

          $timeout(function() {
            scope.list.doSearch();
          });
        };
      }
    };
  }

  kzRefineFilter.$inject = ['$timeout'];

  angular.module('blocks.widgets')
    .directive('kzRefineFilter', kzRefineFilter);
})();
