import pouchdb from 'pouchdb';
window.PouchDB = pouchdb;

// import 'frylytics-client-browser';

import 'angular-cache';
import 'angular-sanitize';
import 'angular-ui-router';
import 'angular-cookie';
import 'angular-ui-bootstrap';
import 'angular-pouchdb';
import 'angular-ui-tree';
import 'angular-sweetalert';
import 'angular-base64';
import 'angular-md5';
import 'angular-loading-bar';
import 'angular-legacy-sortablejs-maintained';
import 'ui-select';
import 'ng-infinite-scroll';
import 'angular-slugify';
import 'angular-formly';
// import 'angular-dashboard-framework';
import 'angular-dashboard-framework/dist/angular-dashboard-framework-tpls';
import 'chart.js';
import 'tc-angular-chartjs';
// import 'angular-lodash';
import 'angular-sequoia';
import 'angular-animate';
import 'angularjs-color-picker';
import 'angular-moment';
// import 'angular-facetly';
import './angular-dropzone';
import 'ng-csv';
import 'angular-wizard';
import 'ng-quill';
import 'angular-signature';
import 'angular-lz-string';
import 'corejs-typeahead';
import 'angular-typeahead';
import 'angular-aside';
import 'checklist-model';
import 'angular-ui-validate';


(function() {
  'use strict';

  // FIXME - this should be using something else
  // angular.module("epf", [])
  //   .constant("KZ_ENVIRONMENT", "local")
  //   .constant("KZ_CONFIG", {})
  //   .constant("PACKAGE_VERSIONS", {
  //     "frontend": "2.13.29"
  //   });

  angular
    .module('epf.core',
    [
      'angular-cache',
      // 'angular-lodash',
      'ui.validate',
      'ngSanitize',
      'ui.router',
      'ipCookie',
      'ui.bootstrap',
      'pouchdb',
      'ui.tree',
      'oitozero.ngSweetAlert',
      'base64',
      'angular-md5',
      'cfp.loadingBar',
      'ng-sortable',
      'ui.select',
      'infinite-scroll',
      'slugifier',
      'formly',
      'ngDropzone',
      'adf',
      'tc.chartjs',
      'ngSequoia',
      'color.picker',
      'angularMoment',
      // 'ngFacetly',
      // 'ngRaven',
      'ngCsv',
      'mgo-angular-wizard',
      'kz.tree',
      'ngQuill',
      'signature',
      'lz-string',
      'siyfion.sfTypeahead',
      'ngAside',
      'checklist-model'
    ]
    );
})();
