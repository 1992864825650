(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.events', {
        url: 'events/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          resolvedEvent: [function() {}],
          resolvedEventSection: [function() {}]
        }
      })

      .state('epf.events.index', {
        url: 'list/:filterId',
        views: {
          content: {
            templateUrl: 'app/components/events/list.html',
            controller: 'EventsController',
            controllerAs: 'eventsCtrl'
          }
        },
        params: {
          filterId: ''
        },
        data: {
          showActionButtons: true
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_db, Security) {
            return Security.hasPermission('events.view.own');
          }],
          resolvedFilter: ['setup', '$stateParams', 'OrganisationsService',
            function(_setup, $stateParams, Organisations) {
              if (_.isEmpty($stateParams.filterId)) {
                return;
              }

              return Organisations.getFilter('epf.events.index', $stateParams.filterId)
                .then(function(data) {
                  return data;
                });
            }]
        }
      })

      .state('epf.events.edit', {
        url: 'edit/:id?token',
        views: {
          content: {
          }
        },
        data: {
          action: 'edit'
        },
        resolve: {
          resolvedEvent: [
            'setup', '$q', 'EventSectionsService', '$stateParams',
            function(_db, $q, EventSections, $stateParams) {
              return EventSections.findAllByEvent($stateParams.id, 'draft')
                .then(function(sections) {
                  if (sections.length === 1) {
                    return $q.reject({
                      status: 302,
                      state: 'epf.events.section-fillin',
                      stateParams: { id: sections[0].doc._id }
                    });
                  }

                  return $q.reject({
                    status: 404,
                    message: 'Event not found'
                  });
                });
            }]
        }
      })

      // This is just so that we keep can have view without slash
      .state('epf.events.viewslash', {
        url: 'view/:id',
        views: {
          content: {
            templateUrl: 'app/components/events/view.html',
            controller: 'EventViewController',
            controllerAs: 'eventViewCtrl'
          }
        },
        params: {
          event: null
        }
      })

      .state('epf.events.view', {
        url: 'view/:id/:scrollTo',
        views: {
          content: {
            templateUrl: 'app/components/events/view.html',
            controller: 'EventViewController',
            controllerAs: 'eventViewCtrl'
          }
        },
        params: {
          event: null,
          scrollTo: ''
        },
        resolve: {
          resolvedEvent: [
            'setup', 'EventFactory', '$stateParams',
            function(_db, Event, $stateParams) {
              var event = new Event({});
              return event.load($stateParams.id);
            }]
        }
      })

      .state('epf.events.tearoff', {
        url: 'tearoff/:id/',
        views: {
          content: {
            templateUrl: 'app/components/events/tearoff.html',
            controller: 'EventViewController',
            controllerAs: 'eventViewCtrl'
          }
        },
        params: {
          event: null
        },
        data: {
          tearoff: true
        },
        resolve: {
          resolvedEvent: [
            'setup', '$q', 'EventFactory', 'EventsService', '$stateParams',
            function(_db, $q, Event, Events, $stateParams) {
              if ($stateParams.event) {
                return $stateParams.event;
              }

              console.log('reloading');
              var event = new Event({});
              return event.load($stateParams.id)
                .catch(function(error) {
                  if (error && error.status === 404) {
                    return Events.findFor($stateParams.id)
                      .then(function(doc) {
                        event = new Event();
                        return event.init(doc);
                      });
                  }

                  return $q.reject(error);
                });
            }]
        }
      })

      .state('epf.events.selecttags', {
        url: 'select',
        views: {
          content: {
            templateUrl: 'app/components/events/selecttags.html',
            controller: 'SelectTagsController',
            controllerAs: 'eventsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.events.applytags', {
        url: 'apply',
        views: {
          content: {
            templateUrl: 'app/components/events/applytags.html',
            controller: 'ApplyTagsController',
            controllerAs: 'eventsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.events.manage-timelines', {
        url: 'manage',
        views: {
          content: {
            templateUrl: 'app/components/events/manage-timelines.html',
            controller: 'ManageTimelinesController',
            controllerAs: 'ctrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_db, Security) {
            return Security.hasPermission('organisation.manage-timelines');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.allevents', {
        url: 'allevents/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_db, Security) {
            return Security.hasPermission('events.view');
          }]
        }
      })

      .state('epf.allevents.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/events/allevents.html',
            controller: 'AllEventsController',
            controllerAs: 'eventsCtrl'
          }
        },
        data: {
          showActionButtons: true
        }
      })

      .state('epf.events.new', {
        url: 'new/:user',
        views: {
          content: {
            templateUrl: 'app/components/events/new.html',
            controller: 'NewEventController',
            controllerAs: 'newEventCtrl'
          }
        },
        resolve: {
          auth: ['setup', '$stateParams', 'SecurityService', function(_db, $stateParams, Security) {
            if ($stateParams.user) {
              return Security.hasPermission('events.create');
            }

            return Security.hasPermission('events.create.own');
          }]
        },
        params: {
          defaults: null,
          user: '',
          route: 'epf.events.section-new'
        }
      })

      .state('epf.events.section-new', {
        url: 'new-section/:eventTypeId/:user',
        views: {
          content: {
            templateUrl: 'app/components/events/eventsection.edit.html',
            controller: 'SectionController',
            controllerAs: 'eventCtrl'
          }
        },
        data: {
          action: 'new'
        },
        params: {
          defaults: null,
          previousRoute: null,
          autosave: null,
          doc: null,
          user: ''
        },
        resolve: {
          redirect: ['$q', '$location', '$stateParams', function($q, $location, $stateParams) {
            var search = $location.search();
            var docId = search.doc;
            if (docId !== undefined) {
              return $q.reject({
                status: 302,
                state: 'epf.events.section-fillin',
                stateParams: { id: docId, user: $stateParams.user },
                location: { autosave: search.autosave }
              });
            }
          }]
        }
      })

      .state('epf.events.section-fillin', {
        url: 'fillin/:id/:user',
        views: {
          content: {
            templateUrl: 'app/components/events/eventsection.edit.html',
            controller: 'SectionController',
            controllerAs: 'eventCtrl'
          }
        },
        data: {
          action: 'edit'
        },
        params: {
          defaults: null,
          eventTypeId: null,
          previousRoute: null,
          user: ''
        },
        resolve: {
          resolvedEventSection: [
            'setup', 'EventSectionFactory', 'EventSectionsService', '$stateParams',
            function(_db, EventSection, EventSections, $stateParams) {
              return EventSections.findFor($stateParams.id)
                .then(function(doc) {
                  var event = new EventSection(doc.doc);
                  if ($stateParams.eventTypeId) {
                    delete event.doc.meta.quickDraft;
                    event.doc.meta.eventType = $stateParams.eventTypeId;
                    event.changeEventType();
                  }
                  return event;
                });
            }]
        }
      })

      .state('epf.events.section-view', {
        url: 'view-section/:id/:user',
        views: {
          content: {
            templateUrl: 'app/components/events/eventsection.view.html',
            controller: 'EventSectionViewController',
            controllerAs: 'eventViewCtrl'
          }
        },
        params: {
          event: null,
          user: ''
        },
        resolve: {
          resolvedEventSection: [
            'setup', 'EventSectionFactory', 'EventSectionsService', '$stateParams',
            function(_db, EventSection, EventSections, $stateParams) {
              return EventSections.find($stateParams.id)
                .then(function(doc) {
                  var event = new EventSection(doc);
                  return event;
                });
            }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.events')
    .config(config);
})();
