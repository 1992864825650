(function() {
  'use strict';

  function PendingSectionSearch($q, $filter) {
    var STATES = {
      accepted: 'Accepted',
      rejected: 'Rejected'
    };

    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              label: 'Start typing to search'
            },
            {
              id: 'state',
              type: 'select',
              label: 'State',
              options: _.map(STATES, function(value, key) {
                return { id: key, title: value };
              }),
              facetly: false,
              advanced: false
            }
          ],
          options: {
            defaultFacet: 'text',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        orderGroups: {
          eventType: {
            title: 'event type',
            orders: ['eventType', 'createdDate'],
            groupBy: {
              title: 'Event type is',
              notitle: 'No event type',
              templateUrl: 'app/components/admin/eventType.wrapper.html',
              getter: function(item) {
                return item.doc.eventTypeName;
              }
            }
          },
          submittedDate: {
            title: 'date submitted',
            orders: ['submittedDate'],
            groupBy: {
              title: 'Submitted in',
              notitle: 'Submittion unknown',
              getter: function(item) {
                return $filter('date')(item.doc.submittedDate, 'MMMM y');
              }
            }
          },
          username: {
            title: 'response from',
            groupBy: {
              title: 'Response from',
              notitle: 'No user',
              getter: function(item) {
                return item.doc.userFullname;
              }
            }
          },
          owner: {
            title: 'event owner',
            groupBy: {
              title: 'Event owner',
              notitle: 'No owner',
              getter: function(item) {
                return item.doc.eventOwnerFullname;
              }
            }
          }
        },
        defaultOrder: 'eventType'
      };

      return $q.when(search);
    };

    return {
      search: getSearch()
    };
  }

  PendingSectionSearch.$inject = ['$q', '$filter'];

  angular.module('component.admin')
    .service('PendingSectionSearch', PendingSectionSearch);
})();
