(function() {
  'use strict';

  function ProfileTemplateSlotController(
    $rootScope,
    $state,
    $scope,
    ProfileTemplates,
    Security,
    ProfileTemplatesUtils,
    Auth,
    Notify,
    Utils,
    LocalizationService
  ) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.showLimit = 2;

    ctrl.editUrl = $state.href('epf.profile-templates.edit', { id: $scope.item._id });

    /**
     * We need this bound to the controller so that we can
     * either display the edit URL or just the announcement
     * title in the view.
     * @type {Boolean}
     */
    ctrl.canEditTemplate = false;

    Security.hasPermission('profileTemplates.edit')
      .then(function(res) {
        ctrl.canEditTemplate = res === undefined ? true : res;
      });

    ctrl.actionButtons = [{
      label: 'Edit',
      ariaLabel: 'Edit ' + $scope.item.title,
      icon: 'icon-edit',
      href: (function() {
        return $state.href('epf.profile-templates.edit', { id: $scope.item._id });
      })(),
      showCondition: Security.hasPermission.bind(this, 'profileTemplates.edit'),
      klass: 'text-primary'
    }];

    if (!$scope.item.isDefault) {
      ctrl.actionButtons.push({
        label: 'Delete',
        ariaLabel: 'Delete ' + $scope.item.title,
        icon: 'icon-trash',
        onClick: function() {
          Utils.swal({
            title: 'Are you sure you want to remove this profile template?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
          },
          function(isConfirm) {
            if (isConfirm) {
              var id = $scope.item._id;
              ProfileTemplates.remove(id)
                .then(function() {
                  $rootScope.$broadcast('KZItemRemoved', { item: $scope.item });
                  Notify.success('Profile template successfully removed!', 'Success');
                  $state.go('epf.profile-templates.index');
                });
            }
          });
        },
        showCondition: Security.hasPermission.bind(this, 'profileTemplates.edit'),
        klass: 'text-danger'
      });
    }

    ctrl.secondaryActionButtons = [{
      label: 'Duplicate',
      ariaLabel: 'Duplicate ' + $scope.item.title,
      icon: 'icon-duplicate',
      onClick: function() {
        var title = 'Copy of ' + $scope.item.title,
            newTemplate = ProfileTemplatesUtils.extendTemplate(
              {
                title: title,
                showTitle: $scope.item.showTitle,
                template: $scope.item.template
              },
              Auth.currentOrganisation()
            );

        // We need to make sure that the dashboard framework picks up the correct title as well
        newTemplate.template.title = title;

        Notify.success(
          'Your copy of the profile template is ready!',
          'Success'
        );

        $state.go('epf.profile-templates.new', { template: newTemplate });
      },
      showCondition: Security.hasPermission.bind(this, 'profileTemplates.edit'),
      klass: 'text-info'
    }];

    ctrl.actionButtons.push({
      label: 'Show audit log',
      ariaLabel: 'Show audit log for ' + $scope.item.title,
      icon: 'icon-history',
      onClick: function() {
        ctrl.shownDescription = !ctrl.shownDescription;
        this.label = ctrl.shownDescription ? 'Hide audit log' : 'Show audit log';
      },
      showCondition: function() {
        return !_.isUndefined($scope.item.dates);
      },
      klass: 'text-info'
    });
  }

  function ProfileTemplateSlotDirective() {
    return {
      scope: {
        item: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/dashboard-templates/directives/template.slot.html',
      controller: ProfileTemplateSlotController,
      controllerAs: 'ctrl'
    };
  }

  ProfileTemplateSlotController.$inject = [
    '$rootScope',
    '$state',
    '$scope',
    'ProfileTemplatesService',
    'SecurityService',
    'ProfileTemplatesUtilsService',
    'AuthService',
    'NotifyService',
    'UtilsService',
    'LocalizationService'
  ];

  angular.module('component.dashboardTemplates')
    .directive('profileTemplateSlot', ProfileTemplateSlotDirective);
})();
