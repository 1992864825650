(function() {
  'use strict';

  function kzFiltersBanner() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        filters: '=',
        search: '=',
        initialFilters: '=',
        doSearch: '&',
        omit: '='
      },
      templateUrl: 'app/blocks/widgets/search/kz.filters-banner.html',
      link: function(scope) {
        var omit = ['hideBySystemEventType', 'user'],
            _initialFilters = _.assign({}, scope.initialFilters),
            initialFilters = {};

        _.forOwn(_initialFilters, function(item, key) {
          if (!_.isEmpty(item)) {
            initialFilters[key] = item;
          }
        });

        var updateVisibility = function() {
          var appliedFilters = {};

          _.forOwn(scope.appliedFilters, function(item, key) {
            if (!_.isEmpty(item)) {
              appliedFilters[key] = item;
            }
          });

          // console.log('applied', scope.appliedFilters);
          // console.log('applied', appliedFilters);
          // console.log('initial', _.omit(initialFilters, [omit]));
          scope.show = !_.isEqual(appliedFilters, _.omit(initialFilters, [omit]));
        };

        var updateAppliedFilters = function(filters) {
          return _.chain(filters)
                    .omit(omit)
                    .value();
        };

        scope.appliedFilters = updateAppliedFilters(scope.filters);

        scope.$watchCollection('filters',
          function(newVal) {
            scope.appliedFilters = updateAppliedFilters(newVal);
            updateVisibility();
          });

        scope.removeAppliedFilter = function(filter) {
          if (filter === 'blueprint') {
            scope.filters[filter] = '';
          } else {
            scope.filters = _.omit(scope.filters, [filter]);
          }

          scope.search.filteredBy = scope.filters;
          updateVisibility();
        };

        scope.removeAllAppliedFilters = function() {
          scope.filters = _.assign({}, _initialFilters);
          scope.search.filteredBy = scope.filters;
          updateVisibility();
          scope.doSearch();
        };

        scope.applyAllFilters = function() {
          scope.doSearch();
        };
      }
    };
  }

  angular.module('blocks.widgets')
    .directive('kzFiltersBanner', kzFiltersBanner);
})();
