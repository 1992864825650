(function() {
  'use strict';

  function EmailTypeFactory(DISABLED_TEMPLATE_ID) {
    var EmailType = function(doc) {
      this.doc = doc;
    };

    EmailType.prototype.deserialize = function(doc) {
      return angular.copy(doc);
    };

    EmailType.prototype.serialize = function(data) {
      return angular.copy(data);
    };

    EmailType.prototype.isDisabled = function() {
      return this.doc.defaultTemplate.id === DISABLED_TEMPLATE_ID;
    };

    EmailType.prototype.currentTemplate = function() {
      return this.doc.defaultTemplate;
    };

    EmailType.prototype.getBorderClass = function() {
      return this.isDisabled() ? 'progress-border-pending' : 'progress-border-complete';
    };

    return EmailType;
  }

  EmailTypeFactory.$inject = ['DISABLED_TEMPLATE_ID'];

  angular.module('component.emailTemplates')
    .factory('EmailTypeFactory', EmailTypeFactory);
})();
