(function() {
  'use strict';

  function ReportViewFiltersController($rootScope, Form, Utils, Security, ReportViewer) {
    var ctrl = this;

    Security.hasPermission('reports.edit.own')
      .then(function(data) {
        ctrl.canEditReport = data;
      });

    ctrl.reportViewer = ReportViewer;
    ctrl.report = {
      title: ReportViewer.title,
      subtitle: ReportViewer.subtitle
    };

    ctrl.reportTemplate = 'blueprint-coverage';
    ReportViewer.init()
      .then(function() {
        ctrl.list = ReportViewer.list;
        ctrl.list.doLoadItems();
        ctrl.loaded = true;
      })
      .catch(function(error) {
        Utils.showError(error);
        ctrl.loaded = true;
      });

    ReportViewer.overview = '';

    ctrl.showDatesForm = function() {
      ctrl.dates = ctrl.dates || {};
      var leftForm = new Form();

      leftForm.addField({
        id: 'after',
        type: 'date',
        label: 'Events after',
        required: true
      });

      var rightForm = new Form();
      rightForm.addField({
        id: 'before',
        type: 'date',
        label: 'Events before',
        required: true
      });

      ctrl.leftForm = leftForm;
      ctrl.rightForm = rightForm;
      ctrl.showDates = true;
    };

    ctrl.updateDates = function() {
      ctrl.reportViewer.list.searchModel.filteredBy.before = ctrl.dates.before;
      ctrl.reportViewer.list.searchModel.filteredBy.after = ctrl.dates.after;
      ctrl.reportViewer.list.defaultFilter.before = ctrl.dates.before;
      ctrl.reportViewer.list.defaultFilter.after = ctrl.dates.after;
      ctrl.showDates = false;
      ctrl.reportViewer.list.doLoadItems()
        .then(function() {
          $rootScope.$broadcast('reportsviewer.listUpdated');
        });
    };

    ctrl.applyDates = function() {
      $rootScope.$broadcast('reportsviewer.listUpdated');
    };

    ctrl.resetForn = function() {
      $rootScope.$broadcast('reportsviewer.listUpdated');
    };
  }

  ReportViewFiltersController.$inject = [
    '$rootScope',
    'FormsService',
    'UtilsService',
    'SecurityService',
    'reportViewer'
  ];

  angular.module('component.reports')
    .controller('ReportViewFiltersController', ReportViewFiltersController);
})();
