(function() {
  'use strict';

  function kzSearch($compile, Form) {
    return {
      restrict: 'AE',
      scope: {
        search: '=',
        searchModel: '=',
        doSearch: '&',
        filteredByInit: '=',
        displaySearchFilters: '='
      },

      // controller: controller,
      // controllerAs: 'ctrl',
      // bindToController: true,
      templateUrl: 'app/blocks/widgets/search/kz-search.html',
      link: function($scope, $el) {
        var ctrl = $scope;

        ctrl.form = new Form();
        if (ctrl.search === undefined) {
          return;
        }

        function buildFilters() {
          $el.find('div.forms').empty();
          _.forEach(ctrl.search.filters, function(filter, idx) {
            if (filter.directive === 'hidden-filter') {
              return;
            }

            var elString = '<div ' + filter.directive + ' search="search" ' +
              'search-model="searchModel" filter="search.filters[' + idx + ']"></div>';
            var el = $compile(elString)($scope);
            $el.find('div.forms').append(el);
          });
        }

        buildFilters();

        $scope.$on('FilterChanged', function() {
          buildFilters();
        });

        ctrl.applyFilters = function() {
          ctrl.displaySearchFilters = true;
          ctrl.doSearch();
        };

        ctrl.removeAllFilters = function() {
          var filteredByInit = angular.copy(ctrl.filteredByInit);
          ctrl.search.filteredBy = filteredByInit || {};
          buildFilters();
          ctrl.doSearch();
        };
      }

    };
  }

  kzSearch.$inject = ['$compile', 'FormsService'];

  angular.module('blocks.widgets')
    .directive('kzSearch', kzSearch);
})();
