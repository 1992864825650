(function() {
  'use strict';

  function EasApiService(
    $http, $q, $log, $timeout, Network, OidcService, ClusterService, DB_ERRORS
  ) {
    var service = {};

    var VERSION = 'v2';

    service.getAPI_URL = function() {
      var urls = ClusterService.getCurrentUrls();
      return _.trimEnd(urls.api, '/');
    };

    service.absUrl = function(path, options) {
      var opts = options || {};
      var url = service.getAPI_URL();
      url += url.endsWith('/') ? '' : '/';
      url += opts.version !== undefined ? opts.version : VERSION;
      url += url.endsWith('/') ? '' : '/';
      url += path;
      return url;
    };

    service.call = function(method, path, data, urlParams, options) {
      options = options || {};

      if (!options.force && Network.isOffline()) {
        return $q.reject(DB_ERRORS.notAvailableOffline);
      }

      var url = service.absUrl(path, options);
      var ldata = urlParams;

      $log.debug(url);
      function handleError(error) {
        if (error && error.status === 0) {
          $log.warn('Rest API: No response from server');
          return $q.reject(
            { status: 0, message: 'Cannot reach the server. Your connection is down.' }
          );
        }

        var status = error.status || 0;
        var errText;
        var errDetail;

        if (_.isEmpty(error.data)) {
          errText = 'Cannot reach the server';
        } else if (_.isString(error.data)) {
          errText = error.data;
          errDetail = error.data.details;
        } else {
          errText = error.data.message;
          errDetail = error.data.details;
        }

        return $q.reject({ status: status, message: errText, details: errDetail });
      }

      if (method === 'BLOB') {
        method = 'GET';
        return $http({
          method: method,
          url: url,
          params: (method === 'GET') ? ldata : {},
          data: (method !== 'GET') ? data : {},
          withCredentials: true,
          headers: {
            Authorization: OidcService.bearer,
            'EAS-Organisation': OidcService.organisation,
            'EAS-Username': OidcService.usernameHeader
          },
          responseType: 'blob' // very important, otherwise zip file won't work
        })
          .then(function(result) {
            var headers = result.headers();
            var blobName = 'noname';
            if (headers['content-disposition']) {
              blobName = headers['content-disposition']
                .split('filename=')[1]
                .split(';')[0];
            }

            var blobContent = result.data,
                blobType = result.headers('content-type').split(';')[0];

            var blob = new Blob([blobContent], { type: blobType });
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = blobName;
            a.style.display = 'none';
            // a.srcObject = blob;

            document.body.appendChild(a);
            a.click();

            $timeout(function() { window.URL.revokeObjectURL(url); }, 10 * 60 * 1000);
          });
      } else if (method === 'MULTIPART') {
        const fd = new FormData();
        fd.append('data', JSON.stringify(data));

        _.forEach(options.files || {}, function(fi, name) {
          const blob = new Blob([fi], { type: fi.type });
          fd.append(name, blob);
        });

        return $http.post(url, fd, {
          // transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
            Authorization: OidcService.bearer,
            'EAS-Organisation': OidcService.organisation,
            'EAS-Username': OidcService.usernameHeader
          }
        })
          .then(function(resp) { return resp.data; })
          .catch(handleError);
      }

      return $http({
        method: method,
        url: url,
        params: (method === 'GET') ? ldata : {},
        data: (method !== 'GET') ? data : {},
        withCredentials: true,
        headers: {
          Authorization: OidcService.bearer,
          'EAS-Organisation': OidcService.organisation,
          'EAS-Username': OidcService.usernameHeader
        }
      }).then(function(resp) {
        return resp.data;
      }).catch(function(error) {
        if (error && error.status === 0) {
          $log.warn('Rest API: No response from server');
          return $q.reject(
            { status: 0, message: 'Cannot reach the server. Your connection is down.' }
          );
        }

        var status = error.status || 0;
        var errText;
        var errDetail;

        if (_.isEmpty(error.data)) {
          errText = 'Cannot reach the server';
        } else if (_.isString(error.data)) {
          errText = error.data;
          errDetail = error.data.details;
        } else {
          errText = error.data.message;
          errDetail = error.data.details;
        }

        return $q.reject({ status: status, message: errText, details: errDetail });
      });
    };

    service.get = function(path, data, urlParams, options) {
      return service.call('GET', path, data, urlParams, options);
    };

    service.post = function(path, data, urlParams, options) {
      return service.call('POST', path, data, urlParams, options);
    };

    service.downloadBlob = function(path, data, urlParams, options) {
      return service.call('BLOB', path, data, urlParams, options);
    };

    service.postMultiPart = function(path, data, urlParams, files, options) {
      options = options || {};
      options.files = files;
      return service.call('MULTIPART', path, data, urlParams, options);
    };

    service.put = function(path, data, urlParams, options) {
      return service.call('PUT', path, data, urlParams, options);
    };

    service.delete = function(path, data, urlParams, options) {
      return service.call('DELETE', path, data, urlParams, options);
    };

    return service;
  }

  EasApiService.$inject = [
    '$http',
    '$q',
    '$log',
    '$timeout',
    'NetworkService',
    'OidcService',
    'ClusterService',
    'DB_ERRORS'
  ];

  angular.module('blocks.easapi')
    .service('EasApiService', EasApiService);
})();
