(function() {
  'use strict';

  function SideBarEventFiles($stateParams, EventsApi) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/events/cards/files.card.html',
      link: function(scope) {
        scope.eventId = $stateParams.id;
        EventsApi.getEventFiles(scope.eventId)
        .then(function(files) {
          scope.model = files.map(file => file.fileinfo);
        });
      }
    };
  }

  SideBarEventFiles.$inject = ['$stateParams', 'EventsApiService'];

  angular.module('component.events')
    .directive('sidebarEventFiles', SideBarEventFiles);
})();
