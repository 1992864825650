(function() {
  'use strict';

  function ResponseInvitationController($scope, EventSections, Notify) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.setup();
    };

    ctrl.edit = function() {
      if (!ctrl.allowActions) {
        return;
      }

      ctrl.helpers.readOnly = false;
      ctrl.model = angular.copy(ctrl.invitation);
      $scope.$broadcast('kzReloadActions');
      $scope.$emit('kzEnterEditMode');
    };

    ctrl.save = function() {
      ctrl.invitation.responsible = [];
      ctrl.invitation.invites = ctrl.model.invites;
      EventSections.nextResponsiblePersonUpdate(
        ctrl.eventSection.doc._id, ctrl.invitation, ctrl.nextIdx
      )
        .then(function(res) {
          ctrl.eventSection.doc._rev = res.rev;
          ctrl.helpers.readOnly = true;
          ctrl.parsedInvitation = ctrl.eventSection.parseInvitation(ctrl.invitation);
          $scope.$broadcast('kzReloadActions');
          $scope.$emit('kzLeaveEditMode');
        })
        .catch(function(err) {
          console.log(err);
          Notify.error((err && err.message) || 'Failed to update next responsible');
        });
    };

    ctrl.cancel = function() {
      ctrl.helpers.readOnly = true;
      $scope.$broadcast('kzReloadActions');
      $scope.$emit('kzLeaveEditMode');
    };


    ctrl.loadActionButtons = function() {
      return [
        {
          label: 'Edit',
          icon: 'icon-edit',
          onClick: function() {
            ctrl.edit();
          },
          klass: 'text-primary',
          btnClass: 'btn-primary',
          showCondition: function() {
            return ctrl.helpers.readOnly;
          }
        },
        {
          label: 'Save',
          icon: 'icon-save',
          onClick: ctrl.save.bind(ctrl),
          klass: 'text-success',
          btnClass: 'btn-success',
          showCondition: function() {
            return !ctrl.helpers.readOnly;
          }
        },
        {
          label: 'Cancel',
          icon: 'icon-cancel',
          onClick: ctrl.cancel.bind(ctrl),
          klass: 'text-danger',
          btnClass: 'btn-danger',
          showCondition: function() {
            return !ctrl.helpers.readOnly;
          }
        }
      ];
    };

    ctrl.setup = function() {
      ctrl.helpers = {
        readOnly: ctrl.allowActions ? true : ctrl.readOnly
      };

      if (ctrl.allowActions) {
        ctrl.actionButtons = ctrl.loadActionButtons();
      }

      ctrl.parsedInvitation = ctrl.eventSection.parseInvitation(ctrl.invitation);
    };
  }

  ResponseInvitationController.$inject = [
    '$scope',
    'EventSectionsService',
    'NotifyService'
  ];

  angular.module('events.directives')
    .component('sectionResponseInvitation', {
      templateUrl: 'app/components/events/directives/response.invitation.form.html',
      controller: ResponseInvitationController,
      bindings: {
        eventSection: '=',
        invitation: '<',
        nextDefSection: '<',
        readOnly: '<',
        allowActions: '<',
        nextIdx: '='
      }
    });
})();
