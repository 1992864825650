(function() {
  'use strict';

  function SectionVisibilityDirective($q, Blueprints, Relations, Roles, Form, EventUtils) {
    return {
      scope: {
        model: '=',
        readOnly: '=',
        eventType: '=',
        currentSection: '@'
      },
      restrict: 'EA',
      templateUrl: 'app/components/event-types/directives/section-visibility.html',
      replace: true,
      link: function(scope) {
        var initForm;

        function getFields(section) {
          if (!section) {
            return [];
          }

          var fields = _.filter((section || {}).fields, function(item) {
            return ['customField', 'blueprint', 'relation', 'role'].indexOf(item.type) !== -1;
          });

          var promises = _.map(fields, function(item) {
            var promise;
            if (item.type === 'blueprint') {
              promise = Blueprints.find(item.blueprint, { cached: true })
                .then(function(doc) {
                  return { _id: doc._id, key: doc._id, name: doc.name };
                });
            } else if (item.type === 'relation') {
              promise = Relations.find(item.relation)
                .then(function(doc) {
                  return { _id: doc._id, key: doc._id, name: doc.name };
                });
            } else if (item.type === 'role') {
              promise = Roles.find(item.role)
                .then(function(doc) {
                  return { _id: doc._id, key: doc._id, name: doc.title };
                });
            } else {
              promise = $q.when({ _id: item._id, key: item._id, name: item.name });
            }

            return promise.catch(function(err) {
              console.log(err);
            });
          });

          return $q.all(promises)
            .then(function(res) {
              return _.filter(res, function(item) {
                return !!item;
              });
            });
        }

        function getDefaultValue(fieldType) {
          var defaultValueByType = { boolean: false };
          var defaultValue = defaultValueByType[fieldType];
          if (_.isUndefined(defaultValue)) {
            defaultValue = null;
          }
          return defaultValue;
        }

        function getForm(cond, options) {
          var idx = options.idx;
          var section = _.find(scope.eventType.sections, { _id: cond.section });

          var fld,
              blueprintFieldId;
          if (section && cond.field) {
            var field = _.find(section.fields, function(item) {
              var _id = item.blueprint || item.relation || item.role || item._id;
              return _id === cond.field;
            });
            if (!field) {
              cond.field = null;
              cond.value = null;
            } else {
              if (field.blueprint) {
                blueprintFieldId = field.blueprint;
              }
              fld = EventUtils.createFormField(
                field,
                {},
                { forceId: 'value', ignoreRestricted: true, showAllBlueprints: true }
              );
              fld.getTitle = function(val) {
                if (_.isArray(this.options)) {
                  var item = _.find(this.options, { key: val });
                  if (item) {
                    return item.name;
                  }
                }
                return val;
              };
            }
          }

          if (options.changed) {
            cond.value = null;
            if (blueprintFieldId) {
              Blueprints.find(blueprintFieldId, { cached: true })
                .then(function(blueprint) {
                  cond.value = getDefaultValue(blueprint.blueprintType);
                });
            } else if (fld) {
              cond.value = getDefaultValue(fld.type);
            }
          }

          var curIdx = _.findIndex(scope.eventType.sections, { _id: scope.currentSection });
          var secOpts = _.chain(scope.eventType.sections)
            .map(function(sec, idx) {
              return {
                _id: sec._id,
                key: sec._id,
                name: 'Section ' + (idx + 1),
                multiSource: sec.multiSource
              };
            })
            .filter(function(_sec, idx) {
              return idx < curIdx;
            })
            .value();

          scope.hasMultiSource = _.find(secOpts, { multiSource: true });

          var fldOpts = getFields(section);
          var form = new Form([
            {
              id: 'section',
              type: 'discrete',
              label: 'Section',
              required: true,
              disabled: scope.readOnly,
              onChange: function() {
                initForm({ changed: 'section', idx: idx });
              },
              options: secOpts,
              getTitle: function(val) {
                var item = _.find(secOpts, { _id: val });
                if (item) {
                  return item.name;
                }

                return val;
              }
            },
            {
              id: 'field',
              type: 'discrete',
              label: 'Field',
              required: true,
              disabled: scope.readOnly,
              onChange: function() {
                initForm({ changed: 'field', idx: idx });
              },
              options: fldOpts,
              getTitle: function(val) {
                return fldOpts.then(function(flds) {
                  var item = _.find(flds, { _id: val });
                  if (item) {
                    return item.name;
                  }

                  return val;
                });
              }
            }
          ]);
          if (fld) {
            form.addField(fld);
          }
          return form;
        }

        initForm = function(options) {
          options = options || {};
          if (options.idx !== undefined) {
            var cond = scope.model.conds[options.idx];
            scope.forms[options.idx] = { model: cond, form: getForm(cond, options) };
          } else {
            scope.forms = _.map(scope.model.conds, function(cond, idx) {
              var opts = _.assignIn({}, options, { idx: idx });
              return { model: cond, form: getForm(cond, opts) };
            });
          }
        };

        // scope.$watch('model.visibilityType', function() {
        //   initForm();
        // });

        scope.addCondition = function() {
          scope.model.conds.push({});
          initForm();
        };

        scope.removeCondition = function(condIdx) {
          scope.model.conds.splice(condIdx, 1);
          initForm();
        };

        initForm();
      }
    };
  }

  SectionVisibilityDirective.$inject = ['$q', 'BlueprintsService',
    'RelationsService', 'RolesService', 'FormsService', 'EventUtils'];

  angular.module('component.eventTypes')
    .directive('sectionVisibility', SectionVisibilityDirective);
})();
