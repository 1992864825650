(function() {
  'use strict';

  function EventSectionInviteController($scope, Form, Utils) {
    var ctrl = this;

    ctrl.show = false;
    ctrl.form = new Form();
    ctrl.invites = {};

    $scope.source = $scope.event.getResponsibles.bind($scope.event);
    ctrl.options = {
      formData: {
        label: 'Request'
      }
    };

    // If anonymous external we do not want to invite people
    if ($scope.def.filledBy.indexOf('system:anonymous-external') === -1) {
      $scope.event.checkPermission('canInvite')
        .then(function() {
          ctrl.show = true;
        });
    }

    ctrl.invite = function() {
      var responsible = ctrl.invites.responsible || [];
      var invites = ctrl.invites.invites || [];

      if (_.isEmpty(responsible) && _.isEmpty(invites)) {
        Utils.swal({
          title: 'Please specify either a user or an e-mail address',
          type: 'error'
        });
        return;
      }

      $scope.event.invite(ctrl.invites);
    };
  }

  EventSectionInviteController.$inject = ['$scope', 'FormsService', 'UtilsService'];

  function EventSectionInviteDirective() {
    return {
      scope: {
        section: '=',
        event: '=',
        def: '=',
        options: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-invite.html',
      replace: true,
      controller: EventSectionInviteController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventSectionInvite', EventSectionInviteDirective)
    .controller('EventSectionInviteController', EventSectionInviteController);
})();
