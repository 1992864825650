(function() {
  'use strict';

  function ImportViewController($interval, $rootScope, $scope, $state, $stateParams, AsyncTasks,
                                Importer, Utils, Notify, TASK_NAMES) {
    var ctrl = this;
    var importId = $stateParams.id;

    function resetPagination() {
      ctrl.outputLimit = 5;
      ctrl.outputPage = 1;
      ctrl.outputStartKey = 1;
      ctrl.outputTotal = 0;
    }
    resetPagination();

    ctrl.filterState = '__all__';
    function getImport() {
      var outputOptions = {
        size: ctrl.outputLimit,
        page: ctrl.outputPage,
        filterState: ctrl.filterState || '__all__'
      };
      return AsyncTasks.findWithOutput($stateParams.id, outputOptions)
        .then(function(importDoc) {
          ctrl.importDoc = importDoc;

          var taskName = '';
          if (!_.isUndefined(importDoc.data)) {
            taskName = importDoc.data.name;
          } else {
            taskName = TASK_NAMES[importDoc.action];
          }

          Utils.setPageTitle('View import task: ' + taskName);

          if (_.isUndefined(ctrl.filterState) || ctrl.filterState === '__all__') {
            if (_.isUndefined(importDoc.meta)) {
              ctrl.outputLimit = 0;
            } else {
              ctrl.outputTotal = importDoc.meta.processed;
            }
          } else if (!_.isUndefined(importDoc.outputSummary)) {
            ctrl.outputTotal = importDoc.outputSummary[ctrl.filterState];
          }

          var dryrun;
          if (ctrl.importDoc) {
            if (!_.isUndefined(importDoc.dryrun)) {
              dryrun = importDoc.dryrun;
            } else {
              // retro-compatibility
              dryrun = importDoc.data.options.dryrun;
            }
          }

          var meta = importDoc.meta || {},
              data = importDoc.data || {},
              options = data.options || {};
          $rootScope.$broadcast(
            'ImportDetailsLoaded',
            { meta: meta, options: options, dryrun: dryrun }
          );

          return importDoc;
        });
    }

    $scope.filterState = '__all__';
    $scope.$watchCollection('filterState', function(newFilterState) {
      resetPagination();
      if (!_.isUndefined(newFilterState)) {
        ctrl.filterState = newFilterState;
      }
      getImport();
    });

    var refreshTaskInterval;
    var stopTaskRefresh = function() {
      if (angular.isDefined(refreshTaskInterval)) {
        $interval.cancel(refreshTaskInterval);
        refreshTaskInterval = undefined;
      }
    };

    function startInterval() {
      ctrl.importDoc = undefined;
      refreshTaskInterval = $interval(function() {
        getImport(importId)
          .then(function(importDoc) {
            if (_.indexOf(['new', 'processing'], importDoc.state) === -1) {
              stopTaskRefresh();
            }
          });
      }, 5000);
    }
    startInterval();

    $scope.$on('$destroy', function() {
      stopTaskRefresh();
    });

    ctrl.downloadOutput = function(importId) {
      AsyncTasks.importerDownloadOutput(importId);
    };

    ctrl.abortImport = function(importId) {
      AsyncTasks.abort(importId)
        .then(getImport)
        .then(function() {
          Notify.success(
            'Abortion imminent.',
            'The system is checking every 5 iterations if the import can be aborted.'
          );
        })
        .catch(function(err) {
          Notify.error('Error while aborting import', err);
        });
    };

    ctrl.importForReal = function(importData, importId) {
      Utils.swal({
        title: 'Are you sure you would like to run this import for real?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          var dryrun = false;

          var prom;
          if (_.isEmpty(importData)) {
            prom = Importer.reImportFileWithoutChanges(importId, dryrun);
          } else {
            // in the case it was a dryrun and user want to directly run it for real.
            var name = importData.name,
                docType = importData.docType,
                extra = { eventTypeId: importData.eventTypeId },
                bulkFileName = importData.bulkFileName,
                mapping = importData.mapping,
                files = {},
                eUniqueness = importData.entry_uniqueness,
                options = importData.options;

            if (importData.extra && importData.extra.event_type_id) {
              extra = { eventTypeId: importData.extra.event_type_id };
            }

            prom = Importer.importFile(
              name,
              docType,
              extra,
              bulkFileName,
              files,
              mapping,
              eUniqueness,
              options,
              dryrun,
              importId
            );
          }

          prom.then(function() {
            Notify.success('Import executed for real');
            startInterval(); // restart interval as the previous task was finished.
          });
        }
      });
    };

    ctrl.retryImport = function(importId) {
      $state.go('epf.importer.edit', { id: importId });
    };

    ctrl.goToPage = function(page) {
      ctrl.outputPage = page;
      ctrl.outputStartKey = page * ctrl.outputLimit;
      getImport();
    };
  }

  ImportViewController.$inject = [
    '$interval',
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'AsyncTasksService',
    'ImporterService',
    'UtilsService',
    'NotifyService',
    'TASK_NAMES'
  ];

  angular.module('component.importer')
    .controller('ImportViewController', ImportViewController);
})();
