import * as toastr from 'toastr';

(function() {
  'use strict';

  function NotifyService($log) {
    var getToastrConfig = function(timeout) {
      return {
        closeButton: timeout === 0,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: _.isNumber(timeout) ? timeout : '3000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        target: '#toast-status-messages'
      };
    };

    var service = {};

    service.log = function(message) {
      $log.log(message);
    };

    service.success = function(message, title, timeout) {
      toastr.options = getToastrConfig(timeout);
      toastr.success(message, title);
    };

    service.error = function(message, title, timeout) {
      toastr.options = getToastrConfig(timeout);
      toastr.error(message, title);
    };

    service.warning = function(message, title, timeout) {
      toastr.options = getToastrConfig(timeout);
      toastr.warning(message, title);
    };

    service.info = function(message, title, timeout) {
      toastr.options = getToastrConfig(timeout);
      toastr.info(message, title);
    };

    return service;
  }

  NotifyService.$inject = ['$log'];

  angular.module('blocks.notify')
    .service('NotifyService', NotifyService);
})();
