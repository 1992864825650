(function() {
  'use strict';

  function UserGoalsController(
    $scope, $rootScope, $stateParams, GoalsStore, Goals, GoalLight, GoalSearch,
    APIList, Profile, Utils, UsersStub, resolvedFilter
  ) {
    var ctrl = this;

    ctrl.username = $stateParams.user;
    if (_.isEmpty(ctrl.username)) {
      Utils.unauthorized(
        { message: 'You are not authorised to access this page. User is not defined.' }
      );
      Utils.setPageTitle('User goals');
    } else {
      UsersStub.find(ctrl.username)
        .then(function(user) {
          var fullName = _.trim(
            (user.firstName || '') + ' ' + (user.lastName || '')
          );
          Utils.setPageTitle(fullName + '\'s goals');
        })
        .catch(function() {
          Utils.setPageTitle('User goals');
        });
    }

    ctrl.options = {
      highlightField: 'text',
      trackBy: 'doc._id',
      src: 'es'
    };

    var listName = resolvedFilter ? 'goals-' + resolvedFilter.id : 'goals';

    ctrl.searchModel = Profile.getListPreferences(listName).current;
    ctrl.options.search = ctrl.searchModel;
    ctrl.resolvedFilter = resolvedFilter || {};

    var defaultFilter = _.assignIn(
      (resolvedFilter || {}).filter || {},
      { user: ctrl.username }
    );

    var makeDocs = function(item) {
      return new GoalLight(item);
    };

    ctrl.list = new APIList({
      search: GoalSearch.dbsearch(defaultFilter),
      model: ctrl.searchModel,
      defaultFilter: defaultFilter,
      findOptions: { limit: 20 },
      makeDocs: makeDocs,
      searchFn: GoalsStore.searchIds.bind(GoalsStore),
      fetchFn: GoalsStore.fetchIds.bind(GoalsStore)
    });
    $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: ctrl.list.search });

    ctrl.list.doSearch();

    $scope.$on('GoalPeriodSelected', function(_evt, periodFilter) {
      console.log('Some period selected');
      $scope.$broadcast('GoalPeriodUpdated', periodFilter);
    });

    $scope.$on('$destroy', function() {
      Goals.cachedEvents = {};
    });
  }

  UserGoalsController.$inject = [
    '$scope',
    '$rootScope',
    '$stateParams',
    'GoalsStore',
    'GoalsService',
    'GoalLightFactory',
    'GoalSearch',
    'APIIDListFactory',
    'ProfileService',
    'UtilsService',
    'UsersStubService',
    'resolvedFilter'
  ];

  angular.module('component.goals')
    .controller('UserGoalsController', UserGoalsController);
})();
