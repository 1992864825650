(function() {
  'use strict';

  function BlueprintCoverageWidgetViewController($log, $q, $state, config, Auth, Blueprints,
    BlueprintUtils, ReportViewer, numberFilter, Users, LocalizationService) {
    var ctrl = this;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.config = config;

    function loadGraph(options) {
      var reportData = {
        blueprint: options.blueprintId,
        reportType: 'reportType',
        restrictionType: 'none',
        location: 'organisation'
      };

      if (options.blueprintCatId) {
        reportData.blueprintCatId = options.blueprintCatId;
      }

      ctrl.currentBlueprint = options.blueprintCatId || options.blueprintId;

      var opts = {};
      if (ctrl.isRemoteUser) {
        opts.forUser = ctrl.user;
      }

      var reportViewer = new ReportViewer({ reportData: reportData }, opts);
      return reportViewer.init()
        .then(function() {
          return reportViewer.getCoverage();
        })
        .then(function(coverage) {
          ctrl.widgetCircleClass = 'col-sm-' + (12 / numberFilter(coverage.overview.length, 0));
          ctrl.report = {
            data: coverage
          };
        });
    }

    ctrl.loadingCoverages = true;
    ctrl.loadingGraph = true;
    ctrl.user = Users.remoteUser || Auth.currentUser();
    ctrl.isRemoteUser = ctrl.user !== Auth.currentUser();
    BlueprintUtils.getHistoricalCoverages(ctrl.user, { cached: true })
      .then(function(historicalCoverages) {
        if (_.isUndefined(ctrl.config.blueprint)) {
          return $q.reject({ status: 404, message: 'Please select a blueprint to display' });
        }

        // identify deleted blueprints, Ugly isn't it?
        var proms = [];
        _.forEach(historicalCoverages, function(relations) {
          _.forEach(relations, function(relation) {
            _.forEach(relation.blueprint, function(categoriesIds) {
              _.forEach(categoriesIds, function(categoryId) {
                proms.push(
                  Blueprints.findSubCategory(categoryId, {})
                    .then(function(category) { return category._id; })
                    .catch(function() { return undefined; })
                );
              });
            });
          });
        });

        return $q.all(proms)
          .then(function(nonDeletedBlueprints) {
            return [historicalCoverages, nonDeletedBlueprints];
          });
      })
      .then(function(result) {
        var historicalCoverages = result[0];
        var nonDeletedBlueprints = result[1];

        if (ctrl.config.blueprint.id === 'all') {
          return historicalCoverages;
        }

        // filterBy BlueprintId
        var coverages = {};
        _.forEach(historicalCoverages, function(relations, period) {
          coverages[period] = _.filter(relations, function(relation) {
            var isPresent = false;
            _.forEach(relation.blueprint, function(blueprints, blueprintId) {
              if (blueprintId === ctrl.config.blueprint.id) {
                relation.blueprint[blueprintId] = _.filter(blueprints, function(blpId) {
                  return _.indexOf(nonDeletedBlueprints, blpId) > -1;
                });

                isPresent = true;
                return false; // break
              }
            });

            return isPresent;
          });

          // remove the object if it's empty
          if (_.isEmpty(coverages[period])) {
            delete coverages[period];
          }
        });
        return coverages;
      })
      .then(function(blueprintHistoricalCoverages) {
        if (!_.isEmpty(blueprintHistoricalCoverages)) {
          ctrl.coverages = blueprintHistoricalCoverages;

          if (ctrl.config.displayGraph) {
            // show the graph to the first element present
            var options = {};
            _.forEach(['current', 'previous', 'future'], function(periodName) {
              if (!_.isUndefined(blueprintHistoricalCoverages[periodName])) {
                var firstBlueprint = blueprintHistoricalCoverages[periodName][0].blueprint;

                if (_.isEmpty(options)) {
                  _.forEach(firstBlueprint, function(blueprints, blpId) {
                    options = { blueprintId: blpId };
                    if (!_.isEmpty(blueprints)) {
                      options.blueprintCatId = blueprints[0];
                    }
                    return false; // break
                  });
                } else {
                  return false; // break
                }
              }
            });

            if (!_.isEmpty(options)) {
              return loadGraph(options);
            }
          }
        }

        // Find category titles and sort
        var categories = [];
        _.forOwn(ctrl.coverages, function(period) {
          _.forEach(period, function(blueprintSet) {
            _.forOwn(blueprintSet.blueprint, function(blueprint) {
              if (!blueprint.length) {
                return;
              }

              _.forEach(blueprint, function(category) {
                categories.push(category);
              });
            });
          });
        });
        var qs = _.map(categories, function(cat) {
          return Blueprints.findByCategoryId(cat);
        });
        return $q.all(qs)
          .then(function(res) {
            var mapping = {};
            _.forEach(res, function(item) {
              mapping[item.key] = item.value;
            });
            _.forOwn(ctrl.coverages, function(period) {
              _.forEach(period, function(blueprintSet) {
                _.forOwn(blueprintSet.blueprint, function(blueprint, key) {
                  if (!blueprint.length) {
                    return;
                  }

                  var newCats = [];
                  _.forEach(blueprint, function(category) {
                    newCats.push({
                      id: category,
                      title: mapping[category] || category
                    });
                  });
                  blueprintSet.blueprint[key] = _.sortBy(newCats, 'title');
                });
              });
            });
          });
      })
      .then(function() {
        ctrl.loadingCoverages = false;
        ctrl.loadingGraph = false;
      })
      .catch(function(error) {
        if (error.status === 403) {
          ctrl.error = 'You don\'t have enough privilege to load the coverage report';
        } else if (error.status === 404) {
          ctrl.error = error.message;
        } else {
          $log.error('An error occured, we could not load widget: ');
          $log.error(error);
          ctrl.error = 'An error occured, we could not load widget';
        }

        ctrl.loadingCoverages = false;
        ctrl.loadingGraph = false;
      });

    ctrl.updateGraph = function(options) {
      ctrl.loadingGraph = true;
      loadGraph(options)
        .then(function() {
          ctrl.loadingGraph = false;
        });
    };

    ctrl.goToCoverage = function(blueprintId, blueprintCatId) {
      var args = {
        location: 'organisation',
        blueprintId: blueprintId,
        id: 'coverage'
      };
      if (blueprintCatId) {
        args.blueprintCatId = blueprintCatId;
      }

      if (ctrl.isRemoteUser) {
        args.user = ctrl.user;
      }

      $state.go('epf.reports.view', args);
    };
  }

  BlueprintCoverageWidgetViewController.$inject = ['$log', '$q', '$state', 'config', 'AuthService',
    'BlueprintsService', 'BlueprintUtils', 'ReportViewerFactory', 'numberFilter', 'UsersService',
    'LocalizationService'];

  function BlueprintCoverageWidgetEditController($q, config, Auth, Blueprints, BlueprintUtils,
    Users, Security) {
    var ctrl = this;

    ctrl.user = Users.remoteUser || Auth.currentUser();
    ctrl.config = config;

    $q.all([
      BlueprintUtils.getHistoricalCoverages(ctrl.user, { cached: true }),
      Blueprints.findAll(),
      Security.hasPermission('dashboardTemplates.edit')
        .catch(function() {
          return false;
        })
    ])
      .then(function(result) {
        var historicalCoverages = result[0];
        var blueprints = result[1];
        var canSeeAll = result[2];

        // get settings options
        var blueprintOptions = [{ id: 'all', name: 'All' }];
        var blueprintIds = _.chain(historicalCoverages)
          .map(function(relations) {
            return _.map(relations, function(relation) {
              return _.keysIn(relation.blueprint);
            });
          })
          .reduce(function(pre, cur) {
            return pre.concat(cur);
          })
          .reduce(function(pre, cur) {
            return pre.concat(cur);
          })
          .uniq()
          .value();

        var blOptions = _.chain(blueprints);

        if (!canSeeAll) {
          blOptions = blOptions
            .filter(function(item) {
              return _.indexOf(blueprintIds, item.id) > -1;
            });
        }

        blOptions = blOptions
          .map(function(item) {
            return { id: item.doc._id, name: item.doc.name };
          })
          .sortBy('name')
          .value();
        ctrl.blueprints = blueprintOptions.concat(blOptions);
      });
  }

  BlueprintCoverageWidgetEditController.$inject = ['$q', 'config', 'AuthService',
    'BlueprintsService', 'BlueprintUtils', 'UsersService', 'SecurityService'];

  function config(dashboardProvider) {
    dashboardProvider.widget('BlueprintCoverageWidget', {
      title: 'Blueprint Coverage',
      description: 'Displays a blueprint coverage',
      controller: 'BlueprintCoverageWidgetViewController',
      controllerAs: 'BlueprintCoverageWidgetCtrl',
      templateUrl: 'app/components/reports/widgets/blueprint-coverage/view.html',
      reload: true,
      edit: {
        templateUrl: 'app/components/reports/widgets/blueprint-coverage/edit.html',
        controller: 'BlueprintCoverageWidgetEditController',
        controllerAs: 'BlueprintCoverageWidgetCtrl'
      },
      category: 'Reports'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.reports')
    .config(config)
    .controller('BlueprintCoverageWidgetViewController', BlueprintCoverageWidgetViewController)
    .controller('BlueprintCoverageWidgetEditController', BlueprintCoverageWidgetEditController);
})();
