(function() {
  'use strict';

  function EventDraftListController($scope, config, EventUtils, FrylyticsService) {
    var ctrl = this;

    config = _.defaults(config, { show: 'todo', sort: 'addedDate' });
    var frylyt = FrylyticsService.captureStart('todo-list');

    ctrl.options = {
      typesToUpdate: ['event'],
      trackBy: 'id',
      borderClass: function(item) {
        return item.main.getBorderClass();
      }
    };

    function loadList() {
      return EventUtils.getList('todosearch', ctrl.searchModel, {
        defaultFilter: {
          state: [config.show]
        },
        listOptions: {
          findOptions: {
            limit: 5
          },
          pagination: 'links'
        },
        own: true
      }).then(function(list) {
        ctrl.list = list;
        ctrl.list.doLoadItems();
        FrylyticsService.captureEnd('todo-list', frylyt);
      });
    }

    ctrl.searchModel = {};
    // ctrl.options.loadList = loadList;
    $scope.$on('KZStoreInvalidated', function(_evt, args) {
      if (args.type === 'event' || args.type === 'eventSection') {
        loadList();
      }
    });
    loadList();
  }

  EventDraftListController.$inject = [
    '$scope',
    'config',
    'EventUtils',
    'FrylyticsService'
  ];

  function EventDraftListWidgetController(config, Form) {
    var ctrl = this;

    _.assignIn(config, _.defaults(config, { show: 'todo', sort: 'added' }));

    var showOptions = [
      {
        _id: 'todo',
        name: 'To do list'
      },
      {
        _id: 'draft',
        name: 'Saved drafts'
      },
      {
        _id: 'alldrafts',
        name: 'Both'
      }
    ];

    var sortOptions = [
      {
        _id: 'addedDate',
        name: 'Date created'
      },
      {
        _id: 'recent',
        name: 'Most recently saved first'
      },
      {
        _id: 'notrecent',
        name: 'Most recently saved last'
      }
    ];

    ctrl.form = new Form([
      {
        id: 'show',
        type: 'discrete',
        label: 'What to show',
        options: showOptions,
        required: true
      },
      {
        id: 'sort',
        type: 'discrete',
        label: 'Ordered by',
        options: sortOptions,
        required: true
      }
    ]);
  }

  EventDraftListWidgetController.$inject = ['config', 'FormsService'];

  function config(dashboardProvider) {
    dashboardProvider.widget('eventTodo', {
      title: 'To do list',
      description: 'Your to do list and saved drafts',
      controller: 'EventDraftListController',
      controllerAs: 'eventDraftsCtrl',
      templateUrl: 'app/components/events/widgets/draft-list/view.html',
      edit: {
        templateUrl: 'app/components/events/widgets/draft-list/edit.html',
        controller: 'EventDraftListWidgetController',
        controllerAs: 'eventDraftsEditCtrl'
      },
      category: 'Events'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.drafts')
    .config(config)
    .controller('EventDraftListController', EventDraftListController)
    .controller('EventDraftListWidgetController', EventDraftListWidgetController);
})();
