
(function() {
  'use strict';

  function ReportBuilderController(
    $q,
    $scope,
    $timeout,
    Blueprints,
    EventTypes,
    Form,
    SingleReport,
    Utils,
    Features
  ) {
    var ctrl = this;

    ctrl.singleReport = $scope.singleReport;
    ctrl.model = $scope.singleReport.conf;
    ctrl.editMode = $scope.editMode;

    if (ctrl.model.filter === undefined) {
      ctrl.model.filter = SingleReport.getInitialFilter();
    }
    ctrl.model.outputType = ctrl.model.outputType || 'simple';

    $q.all([Blueprints.findAll(), EventTypes.findAll(), Features.isOn('turnitin')])
      .then(function(results) {
        var blueprints = results[0],
            eventTypes = results[1],
            turnitinEnabled = results[2];

        var likertBlueprints = _.chain(blueprints)
          .filter(function(blueprint) {
            return blueprint.doc.blueprintType === 'likert';
          })
          .map(function(blueprint) {
            return blueprint.doc._id;
          })
          .value();

        var turnitinBlueprints = _.chain(blueprints)
          .filter(function(blueprint) {
            return ['text', 'file'].indexOf(blueprint.doc.blueprintType) !== -1;
          })
          .map(function(blueprint) {
            return blueprint.doc._id;
          })
          .value();

        var likertFields = [];
        var turnitinFields = [];
        _.forEach(eventTypes, function(eventType) {
          _.forEach(eventType.doc.sections, function(section) {
            _.forEach(section.fields, function(field) {
              if (field.blueprintType === 'likert') {
                likertFields.push('__et__' + eventType.doc._id + '_' + field._id);
              }
              if (
                field.type === 'customField' &&
                (field.blueprintType === 'text' || field.blueprintType === 'file') &&
                field.enableAntiPlagiarism
              ) {
                turnitinFields.push('__et__' + eventType.doc._id + '_' + field._id);
              }
            });
          });
        });

        ctrl.preCalculatedData = {
          likertBlueprints: likertBlueprints,
          likertFields: likertFields,
          turnitinEnabled: turnitinEnabled,
          turnitinFields: turnitinFields,
          turnitinBlueprints: turnitinBlueprints
        };
      });

    var opts = { disableAll: !$scope.editMode };
    ctrl.singleReportBasicForm = new Form(ctrl.singleReport.getSingleReportBasicFormFields(opts));

    ctrl.outputTypeForm = new Form([{
      id: 'outputType',
      type: 'discrete',
      label: 'Select output type',
      required: true,
      disabled: !ctrl.editMode,
      options: [
        { _id: 'simple', key: 'simple', name: 'Tabular' },
        { _id: 'detailed', key: 'detailed', name: 'Detailed' },
        { _id: 'none', key: 'none', name: 'Hidden' }
      ]
    }]);

    ctrl.addSortField = function() {
      if (ctrl.singleReport.conf.sortBy === undefined) {
        ctrl.singleReport.conf.sortBy = [];
      }

      ctrl.singleReport.conf.sortBy.push(ctrl.singleReport.getInitialSort());
    };

    ctrl.addOutputField = function() {
      if (ctrl.singleReport.conf.outputFields === undefined) {
        ctrl.singleReport.conf.outputFields = [];
      }

      ctrl.singleReport.conf.outputFields.push(ctrl.singleReport.getInitialOutputField());
    };

    ctrl.addChart = function() {
      if (ctrl.singleReport.conf.charts === undefined) {
        ctrl.singleReport.conf.charts = [];
      }

      ctrl.singleReport.conf.charts.push(ctrl.singleReport.getInitialChart());
    };

    ctrl.removeAllCharts = function() {
      ctrl.singleReport.conf.charts = [];
    };

    $scope.$watch('singleReport.conf.presource', function(value) {
      console.log(value);
      if (ctrl.singleReport.conf.source === value) {
        console.log('Not doing anything');
        return;
      }

      // Determine we need to clean
      var arrsToCheck = ['outputFields', 'charts', 'sortBy'];
      var changed = _.some(_.map(arrsToCheck, function(item) {
        return (ctrl.singleReport.conf[item] || []).length > 0;
      }));

      if ((ctrl.singleReport.conf.filter.filters || []).length > 0 || changed) {
        Utils.swal({
          title: 'Changing the source will clear all filter, output fields and charts',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK'
        },
        function(isConfirm) {
          if (!isConfirm) {
            $timeout(function() {
              ctrl.singleReport.conf.presource = ctrl.singleReport.conf.source;
            });
          } else {
            ctrl.singleReport.conf.filter = SingleReport.getInitialFilter();
            delete ctrl.singleReport.conf.sortBy;
            delete ctrl.singleReport.conf.charts;
            delete ctrl.singleReport.conf.outputFields;
            ctrl.singleReport.conf.source = value;
          }
        });
      } else {
        ctrl.singleReport.conf.source = value;
      }
    });
  }

  ReportBuilderController.$inject = [
    '$q',
    '$scope',
    '$timeout',
    'BlueprintsService',
    'EventTypesService',
    'FormsService',
    'SingleReportFactory',
    'UtilsService',
    'FeaturesService'
  ];

  function ReportBuilderDirective() {
    return {
      scope: {
        singleReport: '=',
        editMode: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-builder.html',
      replace: true,
      controller: ReportBuilderController,
      controllerAs: 'reportBuilderCtrl'
    };
  }

  ReportBuilderDirective.$inject = [];

  angular.module('component.reports')
    .directive('reportBuilder', ReportBuilderDirective);
})();
