
(function() {
  'use strict';

  function ReportEventTypesDirective($q, $log, Reports, Report) {
    return {
      scope: {
        reportEx: '=report',
        reportDoc: '=',
        reportId: '@'
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-eventtypes.html',
      replace: true,
      link: function($scope) {
        function loadReport() {
          var promise;
          if ($scope.reportEx !== undefined) {
            $scope.report = $scope.reportEx;
            promise = $q.when();
          } else if ($scope.reportDoc !== undefined) {
            $scope.report = new Report($scope.reportDoc);
            promise = $q.when();
          } else {
            promise = Reports.find($scope.reportId, true)
              .then(function(doc) {
                $scope.report = new Report(doc);
              })
              .catch(function(err) {
                $log.warn('Report not found', err);
              });
          }

          return promise;
        }

        $scope.loading = true;
        loadReport()
          .then(function() {
            $scope.report.getEventTypeUsage()
              .then(function(res) {
                if (res.rows && res.rows.length > 0) {
                  res.rows = _.chain(res.rows)
                    .sortBy('value')
                    .groupBy('value')
                    .value();
                }
                $scope.eventTypes = res;
              })
              .catch(function(err) {
                $scope.error = err;
              });
          });

        $scope.isObjectEmpty = function(obj) {
          return _.isEmpty(obj);
        };
      }
    };
  }

  ReportEventTypesDirective.$inject = ['$q', '$log', 'ReportTemplatesService', 'ReportFactory'];

  angular.module('component.reports')
    .directive('kzReportEventTypes', ReportEventTypesDirective);
})();
