(function() {
  'use strict';

  function EmailTypeComponentController(
    $state,
    $scope,
    $rootScope,
    EmailTypes,
    EmailType,
    DEFAULT_TEMPLATE_ID,
    Security,
    Notify,
    Utils
  ) {
    var ctrl = this;

    var load = function() {
      ctrl.loadType()
        .then(function() {
          ctrl.loadActions();
        });
    };

    ctrl.$onInit = function() {
      load();
    };

    ctrl.loadType = function() {
      return EmailTypes.find(ctrl.emailTypeId)
        .then(function(emailType) {
          ctrl.emailType = new EmailType(emailType);
          Utils.setPageTitle('Edit email type: ' + ctrl.emailType.doc.name);
        });
    };

    $scope.$on('EmailTypeChanged', function() {
      load();
    });

    ctrl.reload = function() {
      $rootScope.$broadcast('EmailTypeChanged');
    };

    ctrl.isDefault = function() {
      return ctrl.emailType.doc.defaultTemplate.id === DEFAULT_TEMPLATE_ID;
    };

    ctrl.loadActions = function() {
      // These are the currently viewed email template actions
      // They relate only to the current email template
      ctrl.emailTemplateActions = [
        // {
        //   label: 'Send test email',
        //   onClick: function() {
        //     EmailTypes.sendTestEmail(ctrl.emailType.doc.id,
        //     ctrl.emailType.doc.defaultTemplate.id)
        //       .then(function() {
        //         Notify.success('A test email has been sent to your email address');
        //       })
        //       .catch(function(err) {
        //         var msg = 'Unknown error';
        //         if (err && err.message) {
        //           msg = err.message;
        //         }
        //         Notify.error(msg);
        //       });
        //   },
        //   showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
        //   klass: 'btn-info btn-sm'
        // }
      ];

      // These are the email type actions that affect all the templates
      ctrl.emailTypeActions = [
        {
          label: 'Edit',
          href: (function() {
            return $state.href('epf.email-templates.email-template', {
              emailTypeId: ctrl.emailTypeId,
              emailTemplateId: ctrl.isDefault() ? 'new' : ctrl.emailType.doc.defaultTemplate.id
            });
          })(),
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.edit'),
          klass: 'btn-primary'
        },
        // {
        //   label: 'Disable',
        //   type: 'link',
        //   color: 'warning',
        //   position: 1,
        //   onClick: function() {
        //     return EmailTypes.disable(ctrl.emailType.doc.id)
        //       .then(function() {
        //         Notify.success('Template has been disabled');
        //         return ctrl.reload();
        //       })
        //       .catch(Notify.error);
        //   },
        //   showCondition: function() {
        //     if (ctrl.emailType.isDisabled()) {
        //       return false;
        //     }
        //     return Security.hasPermission('emailTemplates.edit');
        //   },
        //   klass: 'text-warning'
        // },
        {
          label: 'Send test email',
          onClick: function() {
            EmailTypes.sendTestEmail(ctrl.emailType.doc.id, ctrl.emailType.doc.defaultTemplate.id)
              .then(function() {
                Notify.success('A test email has been sent to your email address');
              })
              .catch(function(err) {
                var msg = 'Unknown error';
                if (err && err.message) {
                  msg = err.message;
                }
                Notify.error(msg);
              });
          },
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          klass: 'text-info',
          color: 'info',
          type: 'link',
          position: 1
        },
        {
          label: 'View sent emails',
          color: 'info',
          type: 'link',
          position: 1,
          href: (function() {
            return $state.href('epf.email-templates.email-type-log', {
              emailTypeId: ctrl.emailTypeId
            });
          })(),
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view')
        }
      ];
    };
  }

  EmailTypeComponentController.$inject = [
    '$state',
    '$scope',
    '$rootScope',
    'EmailTypesService',
    'EmailTypeFactory',
    'DEFAULT_TEMPLATE_ID',
    'SecurityService',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.emailTemplates')
    .component('emailTypeComponent', {
      templateUrl: 'app/components/email-templates/email-type.html',
      controller: EmailTypeComponentController,
      bindings: {
        emailTypeId: '<'
      }
    });
})();
