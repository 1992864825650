(function() {
  'use strict';

  function EmailTypesComponentController($state, $rootScope, Profile, EmailTypeSearch,
      EmailTypes, EmailType, List, Security, Utils) {
    var ctrl = this;
    ctrl.options = {
      stateField: 'doc.id',
      trackBy: 'doc.id'
    };

    Utils.setPageTitle('Email types');

    ctrl.searchModel = Profile.getListPreferences('emailTypes').current;
    var makeDocs = function(item) {
      return new EmailType(item);
    };

    ctrl.list = new List({
      search: EmailTypeSearch.search,
      model: ctrl.searchModel,
      idField: 'doc.id'
    });
    $rootScope.$broadcast('KZCurrentListChange', { list: 'emailTypes', search: ctrl.list.search });

    EmailTypes.findAll({}, {}, { noCache: true })
      .then(function(data) {
        if (data.length > 0) {
          ctrl.list.doLoadItems(data.map(makeDocs));
        }
      }).catch(function(err) {
        console.log(err);
      });

    ctrl.actions = [
      {
        label: 'View sent emails',
        color: 'info',
        type: 'button',
        position: 1,
        href: (function() {
          return $state.href('epf.email-templates.email-logs');
        })(),
        showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
        klass: 'btn-primary'
      }
    ];

    // Defined here so that I don't have to create a special slot that does nothing
    ctrl.options.actionButtons = function(item) {
      return [
        {
          label: 'Edit',
          icon: 'icon-edit',
          href: (function() {
            return $state.href('epf.email-templates.email-type', { emailTypeId: item.doc.id });
          })(),
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          klass: 'text-info'
        }
      ];
    };
  }

  EmailTypesComponentController.$inject = [
    '$state',
    '$rootScope',
    'ProfileService',
    'EmailTypeSearch',
    'EmailTypesService',
    'EmailTypeFactory',
    'ListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.emailTemplates')
    .component('emailTypesComponent', {
      templateUrl: 'app/components/email-templates/email-types.html',
      controller: EmailTypesComponentController
    });
})();
