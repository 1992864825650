(function() {
  'use strict';

  function EventSectionActionsHeaderDirective(
    $q,
    Auth,
    EventUtils,
    Organisations,
    Security,
    Users
  ) {
    return {
      scope: {
        eventSection: '=',
        eventOwner: '=',
        eventExtras: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/events/directives/event-actions-header.html',
      link: function(scope) {
        scope.sectionStates = scope.eventSection.getProgress();

        if (scope.options.allowLinking) {
          scope.$watchCollection('eventSection.doc.meta.linkedTargets', function(newLinkedTargets) {
            scope.goalsCategoriesLoaded = false;
            Organisations.getListOptions()
              .then(function(listOptions) {
                if (
                  !_.has(listOptions, 'epf.goals.index') ||
                  _.isEmpty(listOptions['epf.goals.index'].sections)
                ) {
                  return [[], []];
                }

                var goalCategories = listOptions['epf.goals.index'].sections;
                var hasRoleForCat = _.map(goalCategories, function(cat) {
                  if (_.isUndefined(cat.allowedRoles) || _.isEmpty(cat.allowedRoles)) {
                    return $q.when(true);
                  }

                  if (Users.remoteUser) {
                    return Security.hasRoleFor(Users.remoteUser, cat.allowedRoles);
                  }

                  return Security.hasRole(cat.allowedRoles);
                });

                return $q.all(hasRoleForCat)
                  .then(function(hasRoles) {
                    return [goalCategories, hasRoles];
                  });
              })
              .then(function(result) {
                var goalCategories = result[0];
                var hasRoles = result[1];

                var categoryCount = {};
                if (!_.isUndefined(scope.eventSection.doc.meta)) {
                  _.forEach(newLinkedTargets || [], function(linkedTarget) {
                    if (_.isUndefined(linkedTarget.eventTypeVersionGroupId)) {
                      return;
                    }

                    if (_.isUndefined(categoryCount[linkedTarget.eventTypeVersionGroupId])) {
                      categoryCount[linkedTarget.eventTypeVersionGroupId] = 0;
                    }

                    categoryCount[linkedTarget.eventTypeVersionGroupId]++;
                  });
                }

                // get the total count
                scope.totalCount = _.chain(categoryCount)
                  .map(function(count) {
                    return count;
                  })
                  .sum()
                  .value();

                // filter categories and set count for each categories
                scope.goalCategories = _.chain(goalCategories)
                  .filter(function(_cat, index) {
                    return hasRoles[index];
                  })
                  .map(function(cat) {
                    if (_.isUndefined(cat.filter)) {
                      cat.count = scope.totalCount;
                      return cat;
                    }

                    if (
                      _.isUndefined(cat.filter.eventType_versionGroupId) ||
                      _.isEmpty(cat.filter.eventType_versionGroupId)
                    ) {
                      cat.count = scope.totalCount;
                      return cat;
                    }

                    cat.count = 0;
                    var filter = cat.filter.eventType_versionGroupId || [];
                    _.forEach(filter, function(versionGroupId) {
                      if (!_.isUndefined(categoryCount[versionGroupId])) {
                        cat.count += categoryCount[versionGroupId];
                      }
                    });

                    return cat;
                  })
                  .value();
              })
              .then(function() {
                scope.goalsCategoriesLoaded = true;
              });
          });
        }

        scope.openLinkingTargetsPopup = function(goalCatId) {
          var remoteUser = scope.eventOwner !== Auth.currentUser() ? scope.eventOwner : undefined;

          var prom = $q.when();
          if (!_.isUndefined(goalCatId)) {
            prom = Organisations.getFilter('epf.goals.index', goalCatId);
          }

          return prom
            .then(function(goalCat) {
              EventUtils.openLinkingEventToTargetsPopup(scope.eventSection, remoteUser, goalCat);
            });
        };
      }
    };
  }

  EventSectionActionsHeaderDirective.$inject = [
    '$q',
    'AuthService',
    'EventUtils',
    'OrganisationsService',
    'SecurityService',
    'UsersService'
  ];

  angular.module('events.directives')
    .directive('eventActionsHeader', EventSectionActionsHeaderDirective);
})();
