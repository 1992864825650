(function() {
  'use strict';

  function DraftsListDirective($rootScope, List, AUTOSAVE_EVENTS) {
    return {
      scope: {
        drafts: '=',
        info: '=',
        messages: '=?',
        canDeleteDraft: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/drafts/drafts-list.html',
      link: function link(scope) {
        scope.canDeleteDraft = scope.canDeleteDraft || false;

        var setupList = function() {
          return new List({
            search: {
              filters: [],
              orders: {
                name: function(item) {
                  return item.doc.lastSaved;
                }
              },
              orderGroups: {
                name: {
                  title: 'Last worked on',
                  orders: ['lastSaved']
                }
              },
              defaultFilter: {},
              defaultOrder: 'lastSaved'
            },
            model: { current: { filteredBy: {} } },
            idField: '_id',
            pagination: 'links'
          });
        };

        scope.list = setupList();
        scope.list.doLoadItems(scope.drafts);

        scope.$watch('drafts', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            scope.list = setupList();
            scope.list.doLoadItems(newValue);
          }
        });

        $rootScope.$on(AUTOSAVE_EVENTS.removed, function(_evt, args) {
          _.remove(scope.list.items, args.draft);
          if (scope.list.allItems !== undefined) {
            _.remove(scope.list.allItems, args.draft);
          }

          if (scope.list.filtered !== undefined) {
            _.remove(scope.list.filtered, args.draft);
          }

          // We need to tell the pagination to self-update as well
          // We can tell it how many items have been removed from the list.
          $rootScope.$broadcast('KZPagination:ItemRemoved', { count: 1 });
        });

        /**
         * On click, we load the provided page.
         * @param  {number} page The page number to navigate to.
         * @return {void}
         */
        scope.goToPage = function(page) {
          scope.list.goToPage(+page);
        };
      }
    };
  }

  DraftsListDirective.$inject = ['$rootScope', 'ListFactory', 'AUTOSAVE_EVENTS'];

  angular.module('component.drafts')
    .directive('kzDraftsList', DraftsListDirective);
})();
