(function() {
  'use strict';

  function EventTypePredefinedFieldsController($state, $stateParams, PredefinedFields,
                                                EventTypes, FORM_TEXT_TYPES, Utils) {
    var ctrl = this;

    ctrl.fields = [];
    ctrl.eventType = {};
    ctrl.section = {};
    ctrl.textTypes = FORM_TEXT_TYPES;

    Utils.setPageTitle('Choose a predefined field');

    EventTypes.getForEdit($stateParams.eventTypeId)
      .then(function(data) {
        ctrl.eventType = data;
        ctrl.section = _.find(data.sections, { _id: $stateParams.sectionId });
      });

    PredefinedFields.findAll()
      .then(function(data) {
        ctrl.fields = data;
      });

    function newCustomField(field) {
      field._id = Utils.guid();
      ctrl.section.fields.push(field);

      return EventTypes.save(ctrl.eventType)
        .then(function() {
          $state.go('epf.event-types.field', {
            eventTypeId: $stateParams.eventTypeId,
            sectionId: $stateParams.sectionId,
            fieldId: field._id }
          );
        });
    }

    ctrl.actionSelected = false;
    ctrl.new = function() {
      ctrl.actionSelected = true;
      var field = {
        type: 'customField',
        saved: false,
        blueprintType: '',
        name: ''
      };

      newCustomField(field);
    };

    ctrl.select = function(id) {
      ctrl.actionSelected = true;

      PredefinedFields.find(id)
        .then(function(data) {
          delete data.fieldType;
          delete data._rev;
          delete data._id;
          delete data.restricted;
          delete data.organisation;

          var field = {
                type: 'customField',
                saved: false
              },
              merged = _.merge(data, field);

          newCustomField(merged);
        });
    };

    ctrl.remove = function(id) {
      ctrl.actionSelected = true;

      Utils.swal({
        title: 'Are you sure you want to remove this predefined field?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          PredefinedFields.remove(id)
            .then(function(res) {
              _.remove(ctrl.fields, function(field) {
                return field._id === res.result.id;
              });
            })
            .then(function() {
              ctrl.actionSelected = false;
            });
        } else {
          ctrl.actionSelected = false;
        }
      });
    };
  }

  EventTypePredefinedFieldsController.$inject = [
    '$state',
    '$stateParams',
    'EventTypePredefinedFieldsService',
    'EventTypesService',
    'FORM_TEXT_TYPES',
    'UtilsService'
  ];

  angular.module('component.eventTypes')
    .controller('EventTypePredefinedFieldsController', EventTypePredefinedFieldsController);
})();
