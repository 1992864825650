(function() {
  'use strict';

  var types = [
    {
      label: 'Hierarchical tree',
      name: 'tree'
    },
    {
      label: 'Discrete',
      name: 'discrete'
    },
    {
      label: 'Discrete (multiple)',
      name: 'discrete_multiple'
    },
    {
      label: 'Likert',
      name: 'likert'
    },
    {
      label: 'Free text (short)',
      name: 'string'
    },
    {
      label: 'Free text (long)',
      name: 'text'
    },
    {
      label: 'Date',
      name: 'date'
    },
    {
      label: 'DateTime',
      name: 'datetime'
    },
    {
      label: 'Numeric',
      name: 'numeric'
    },
    {
      label: 'Boolean',
      name: 'boolean'
    },
    {
      label: 'File',
      name: 'file'
    }
  ];

  var treeOptions = {
    limit: 1,
    allowSearch: true,
    allowSelect: true,
    inline: false,
    canEdit: false,
    buttons: {
      select: '<i class="icon-check"></i>',
      deselect: '<i class="icon-minus"></i>',
      goToSubitems: '<i class="icon-right-open-big"></i>',
      addSubitems: '<i class="icon-plus"></i>',
      addNode: '<i class="icon-plus"></i>',
      remove: '<i class="icon-trash"></i>',
      done: 'Done',
      showSelected: 'Show selected',
      hideSelected: 'Hide selected',
      backToList: 'Back to list',
      move: '<i class="icon-move"></i>',
      modalSelect: 'Select'
    }
  };

  angular.module('component.blueprints')
    .constant('BLUEPRINT_TYPES', types)
    .constant('TREE_OPTIONS', treeOptions);
})();
