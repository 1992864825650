(function() {
  'use strict';

  function SectionVisibilityDirective(Form) {
    return {
      scope: {
        model: '=',
        readOnly: '=',
        eventType: '=',
        currentSection: '@'
      },
      restrict: 'EA',
      templateUrl: 'app/components/event-types/directives/dependent-sections-permissions.html',
      replace: true,
      link: function(scope) {
        if (_.isUndefined(scope.model.dependentSectionsPerms)) {
          scope.model.dependentSectionsPerms = [];
        }

        function getForm() {
          var curIdx = _.findIndex(scope.eventType.sections, { _id: scope.currentSection });
          var secOpts = _.chain(scope.eventType.sections)
            .map(function(sec, idx) {
              return { _id: sec._id, key: sec._id, name: 'Section ' + (idx + 1) };
            })
            .filter(function(_sec, idx) {
              return idx < curIdx;
            })
            .value();

          var form = new Form([
            {
              id: 'section',
              type: 'discrete',
              label: 'Section',
              required: true,
              disabled: scope.readOnly,
              options: secOpts,
              getTitle: function(val) {
                var item = _.find(secOpts, { _id: val });
                if (item) {
                  return item.name;
                }

                return val;
              }
            },
            {
              id: 'permission',
              type: 'discrete',
              label: 'Permission',
              required: true,
              disabled: scope.readOnly,
              options: [
                {
                  _id: 'canEdit',
                  key: 'canEdit',
                  name: 'Can edit section responses'
                },
                {
                  _id: 'canRemoveMS',
                  key: 'canRemoveMS',
                  name: 'Can remove multi source responses'
                }
              ]
            }
          ]);
          return form;
        }

        function initForm() {
          scope.forms = _.map(scope.model.dependentSectionsPerms, function() {
            return getForm();
          });
        }

        // function canAddMoreDependencies() {
        //   // check that they are some permissions left for each section.
        //   var mappedSections = {};
        //   _.forEach(scope.model.dependentSectionsPerms, function(sectionPerm) {
        //     if (_.isUndefined(mappedSections[sectionPerm.section])) {
        //       mappedSections[sectionPerm.section] = [];
        //     }
        //
        //     mappedSections[sectionPerm.section].push(sectionPerm.permission);
        //   });
        //
        //   // difference between mappedSections.keys() and maxSections
        //   // for each sections check diff between perms and maxPerm
        //
        //   scope.canAddMoreDependencies = false;
        // }

        scope.addDependency = function() {
          scope.model.dependentSectionsPerms.push({});
          initForm();
        };

        scope.removeDependency = function(idx) {
          scope.model.dependentSectionsPerms.splice(idx, 1);
          initForm();
        };

        // check if can add more dependencies?
        initForm();
      }
    };
  }

  SectionVisibilityDirective.$inject = ['FormsService'];

  angular.module('component.eventTypes')
    .directive('dependentSectionsPermissions', SectionVisibilityDirective);
})();
