(function() {
  'use strict';

  function ListView($rootScope, $timeout, $log) {
    return {
      restrict: 'AE',
      scope: {
        list: '=',
        options: '=?'
      },
      transclude: true,
      templateUrl: function(_elem, attr) {
        return attr.template ? attr.template : 'app/blocks/list/listview.html';
      },
      link: function(scope) {
        scope.options = scope.options || {};
        scope.options.trackBy = scope.options.trackBy || 'doc._id';
        scope.currentGroup = {
          value: 'unused' // Something weird so that even undefined is shown as a change
        };

        scope.getBorderClass = function(item) {
          if (scope.options.borderClass) {
            return scope.options.borderClass(item);
          }

          return item.getBorderClass ? item.getBorderClass() : 'progress-border-complete';
        };

        scope.$on('KZListResetted', function() {
          scope.currentGroup.value = 'unused';
        });

        scope.$on('KZApplySearch', function(_evt, options) {
          options = options || {};
          $log.debug('Searching from broadcast');
          if (options.fullReload && _.isFunction(scope.list.loadList)) {
            scope.list.loadList();
          } else {
            scope.list.doSearch();
          }
        });

        scope.$on('KZItemRemoved', function(_evt, args) {
          _.remove(scope.list.items, args.item);
          if (scope.list.allItems !== undefined) {
            _.remove(scope.list.allItems, args.item);
          }

          if (scope.list.filtered !== undefined) {
            _.remove(scope.list.filtered, args.item);
          }
        });

        var identifier = function(id) {
          return function(obj) {
            return obj.id === id;
          };
        };

        var removeItem = function(gid) {
          _.remove(scope.list.items, identifier(gid));

          if (scope.list.allItems !== undefined) {
            _.remove(scope.list.allItems, identifier(gid));
          }

          if (scope.list.filtered !== undefined) {
            _.remove(scope.list.filtered, identifier(gid));
          }
        };

        var addItem = function(item) {
          // Run through filters
          scope.list.verifyOne(item)
            .then(function(matched) {
              if (matched === undefined) {
                return;
              }

              scope.list.items.splice(0, 0, matched);
              scope.list.allItems.splice(0, 0, matched);
              scope.list.filtered.splice(0, 0, matched);
              matched.recentlyUpdated = true;
              $timeout(function() {
                matched.recentlyUpdated = false;
              }, 1000);
            });
        };

        var updateItem = function(item) {
          scope.list.verifyOne(item)
            .then(function(matched) {
              if (matched === undefined) {
                _.remove(scope.list.items, identifier(item.id));
              }

              item.recentlyUpdated = true;
              $timeout(function() {
                item.recentlyUpdated = false;
              }, 1000);
            });
        };

        var typesToUpdate = (scope.options || {}).typesToUpdate;
        if (typesToUpdate !== undefined) {
          $log.debug('ListView: set updating');
          scope.$on('SpecialStoreUpdated', function(_evt, args) {
            $log.debug('ListView: got updating');
            if (typesToUpdate.indexOf(args.type) === -1) {
              return;
            }

            if (args.action === 'remove') {
              removeItem(args.id);
              // todo;
            } else if (args.action === 'add') {
              addItem(args.item);
            } else if (args.action === 'update') {
              updateItem(args.item);
            }
          });

          scope.$on('ItemReloadRequested', function(_evt, args) {
            if (typesToUpdate.indexOf(args.type) === -1) {
              return;
            }

            scope.list.reloadItem(args);
          });
        }

        scope.$watch('list.searchModel', function(value, oldVal) {
          if (value === undefined) {
            return;
          }
          if (_.isEqual(value, oldVal)) {
            return;
          }

          $rootScope.$broadcast('KZPreferencesChange');
          scope.options.orderedBy = scope.list.orderedBy();
          scope.list.doSearch();
        }, true);

        /**
         * On click, we load the provided page.
         * @param  {number} page The page number to navigate to.
         * @return {void}
         */
        scope.goToPage = function(page) {
          scope.list.goToPage(+page);
        };
      }
    };
  }

  ListView.$inject = ['$rootScope', '$timeout', '$log'];

  angular.module('blocks.list')
    .directive('kzListView', ListView);
})();
