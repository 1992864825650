(function() {
  'use strict';

  function Card($stateParams, Auth, Drafts) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/drafts/cards/drafts.list.card.html',
      link: function(scope) {
        var user = $stateParams.user || Auth.currentUser();

        // Get the drafts for the provided document type
        var matchFunc = function(doc) {
          return (
            doc.isFor === 'eventSection' &&
            doc.data.eventOwner === user &&
            doc.data.eventType === $stateParams.eventTypeId
          );
        };

        /* eslint-disable max-len */
        scope.messages = {
          default: 'You\'ve previously worked on these events and you can continue working on them from here.',
          empty: 'You have no drafts yet. Every time you create or edit a form, we will automatically save your changes so you can continue working on them. These as well as forms you manually save as draft will show up here.'
        };
        /* eslint-enable */

        Drafts.findAllBy(matchFunc)
          .then(function(drafts) {
            scope.drafts = drafts;
          });
      }
    };
  }

  Card.$inject = [
    '$stateParams',
    'AuthService',
    'DraftsService'
  ];

  angular.module('component.drafts')
    .directive('eventEditDraftsListCard', Card);
})();
