(function() {
  'use strict';

  function LunrFactory($q, $window, $log, CacheService) {
    var lunr = $window.lunr;

    var factory = function(id, config, options) {
      options = options || {};
      this.id = id;
      this.state = 'new';
      this.dirty = false;
      this._indexed = {};
      this._source = {};
      this._version = options.version;
      if (config !== undefined) {
        this.createIndex(config, options);
      }
    };

    factory.prototype.serialize = function() {
      var ser = {
        version: this._version,
        idx: JSON.stringify(this._idx.toJSON()),
        indexed: this._indexed,
        source: this._source
      };
      return ser;
    };

    factory.prototype.loadIndex = function(index) {
      if (index.version !== this._version) {
        return false;
      }

      this._idx = lunr.Index.load(JSON.parse(index.idx));
      this._indexed = index.indexed;
      this._source = index.source || {};
      this.dirty = false;
      return true;
    };

    factory.prototype.createIndex = function(config, options) {
      options = options || {};
      this._version = options.version;
      this._idx = lunr(config);
      this._indexed = {};
      this._source = {};
      this.dirty = true;
      return this;
    };

    factory.prototype.add = function(item, options) {
      var _this = this;

      var func = options.preprocess || function(item) {
        return $q.when(item);
      };

      var _ident = options.ident || function(item) {
        return [item.doc._id, item.doc._rev];
      };

      var ident;
      return $q.when(_ident(item))
        .then(function(idt) {
          ident = idt;
          if (_this._indexed[ident[0]] === ident[1]) {
            return $q.reject({ status: 200, message: 'Not adding, the same' });
          }

          return $q.when(func(item));
        })
        .then(function(data) {
          _this._source[ident[0]] = data;

          var transformed = {};

          // Transform data
          _.forOwn(data, function(value, key) {
            if (value === undefined) {
              return;
            }

            if (_.isArray(value)) {
              value = _.map(value, function(item) {
                if (item === undefined) {
                  return '';
                }

                return item.value !== undefined ? item.value : item;
              }).join(' ');
            }

            transformed[key] = value.value !== undefined ? value.value : value;
          });
          _this._idx.update(transformed);
          _this._indexed[ident[0]] = ident[1];
          _this.dirty = true;
        })
        .catch(function(err) {
          if (err && err.status && err.status === 200) {
            return;
          }

          return $q.reject(err);
        });
    };

    factory.prototype.update = function(item) {
      this._idx.update(item);
    };

    factory.prototype.remove = function(itemId) {
      delete this._indexed[itemId];
      this.dirty = true;
    };

    factory.prototype.index = function(items, options) {
      options = options || {};
      $log.debug('Started indexing:', items.length);
      var _this = this;
      this.state = 'indexing';

      return $q.all(_.map(items, function(item) {
        return _this.add(item, options);
      }))
      .then(function() {
        _this.state = 'ready';
        $log.debug('Done indexing');
        return _this;
      });
    };

    factory.prototype.search = function(token) {
      if (this._idx === undefined) {
        $log.error('Please, setup the index first');
        return;
      }

      return this._idx.search(token);
    };

    factory.prototype.tokenize = function(query) {
      return this._idx.pipeline.run(this._idx.tokenizerFn(query));
    };

    factory.prototype.cache = function() {
      var _this = this;
      if (!this.dirty) {
        return $q.when(this);
      }

      return CacheService.persistentPut(this.id, this.serialize())
        .then(function() {
          _this.dirty = false;
          return _this;
        })
        .catch(function(err) {
          console.log('Cannot cache', err);
          return _this;
        });
    };

    return factory;
  }

  LunrFactory.$inject = ['$q', '$window', '$log', 'CacheService'];

  angular.module('blocks.lunr')
    .factory('KZLunrIndex', LunrFactory);
})();
