(function() {
  'use strict';

  function EventResponseViewDirective($q, $state, Utils, Network, LocalizationService) {
    return {
      scope: {
        section: '=',
        event: '=',
        item: '=',
        def: '=',
        parent: '=?',
        fields: '=',
        form: '=?',
        displayOptions: '=?',
        allowActions: '=?',
        options: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-response-view.html',
      link: function(scope) {
        scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
        scope.displayOptions = scope.displayOptions || { collapsible: false, isExpanded: true };
        scope.helpers = {
          isExpanded: scope.displayOptions.isExpanded,
          readOnly: true
        };
        scope.edited = { fields: {} };
        scope.options = scope.options || { readOnly: true };

        scope.fileOptions = {
          accept: ['all'],
          multiple: true,
          sizeLimit: 100,
          fileLimit: 0,
          storeSingle: false
        };

        var original;
        var edit = function() {
          if (!scope.allowActions) {
            return;
          }

          original = angular.copy(scope.item);
          scope.helpers.readOnly = false;
          scope.options.readOnly = false;
          scope.$broadcast('kzReloadActions');
        };

        var save = function() {
          var prom = $q.when(),
              item = _.assignIn({}, scope.item, scope.edited);
          if (scope.options.postponeActions) {
            var data = {
              action_name: 'edit',
              section_id: scope.def._id,
              event_section_id: item.eventSectionId,
              fields: item.fields
            };
            scope.$emit('PostponedAction', data);
          } else {
            prom = scope.event.saveResponse(scope.def._id, item);
          }

          prom
            .then(function() {
              scope.helpers.readOnly = true;
              scope.options.readOnly = true;
              scope.$broadcast('kzReloadActions');
            });
        };

        var cancel = function() {
          if (!_.isUndefined(original.fields)) {
            scope.item.fields = original.fields;
          }
          scope.edited = { fields: {} };
          scope.helpers.readOnly = true;
          scope.options.readOnly = true;
          scope.$broadcast('kzReloadActions');
        };

        var remove = function() {
          if (!scope.allowActions) {
            return;
          }

          if (scope.options.postponeActions) {
            var data = {
              action_name: 'remove',
              section_id: scope.def._id,
              event_section_id: scope.item.eventSectionId
            };
            scope.$emit('PostponedAction', data);
          } else {
            var def = $q.defer();
            Utils.swal({
              title: 'Are you sure you want to remove this response?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK'
            },
            function(isConfirm) {
              if (isConfirm) {
                scope.event.removeResponse(scope.def._id, scope.item)
                  .then(function() {
                    def.resolve();

                    // reload page
                    var route;
                    var args = { id: scope.event.doc._id };
                    if (scope.event.isMine()) {
                      route = 'epf.events.view';
                    } else {
                      route = 'epf.users.events-view';
                      args.user = scope.event.doc.user;
                    }
                    $state.go(route, args, { reload: true });
                  })
                  .catch(function(error) {
                    def.reject(error);
                  });
              } else {
                def.reject();
              }
            });
            return def.promise;
          }
        };

        var loadActionButtons = function() {
          var buttons = [
            {
              label: 'Edit',
              icon: 'icon-edit',
              onClick: edit,
              klass: 'btn btn-sm btn-primary',
              showCondition: function() {
                if (Network.isOffline()) {
                  return false;
                }

                if (!scope.helpers.readOnly) {
                  return false;
                }

                return scope.event.canEditSection(scope.item, scope.def);
              }
            },
            {
              label: 'Save',
              icon: 'icon-save',
              onClick: save,
              klass: 'btn btn-sm btn-success',
              showCondition: function() {
                if (Network.isOffline()) {
                  return false;
                }

                return !scope.helpers.readOnly;
              }
            },
            {
              label: 'Cancel',
              icon: 'icon-cancel',
              onClick: cancel,
              klass: 'btn btn-sm btn-danger',
              showCondition: function() {
                return !scope.helpers.readOnly;
              }
            },
            {
              label: 'Remove',
              icon: 'icon-remove',
              onClick: remove,
              klass: 'btn btn-sm btn-danger',
              showCondition: function() {
                if (Network.isOffline()) {
                  return false;
                }

                if (!scope.helpers.readOnly) {
                  return false;
                }

                return scope.event.canDeleteMultiSourceResponse(scope.item, scope.def);
              }
            }
          ];

          // We need to hide the buttons box when there are no visible buttons
          Utils.filterButtons(buttons)
            .then(function(visible) {
              scope.showActionButtons = visible.length && !Network.isOffline();
            });

          // Set the buttons
          scope.actionButtons = buttons;
        };

        loadActionButtons();
      }
    };
  }

  EventResponseViewDirective.$inject = [
    '$q',
    '$state',
    'UtilsService',
    'NetworkService',
    'LocalizationService'
  ];

  angular.module('events.directives')
    .directive('eventResponseView', EventResponseViewDirective);
})();
