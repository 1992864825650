(function() {
  'use strict';

  function Header() {
    return {
      restrict: 'AE',
      scope: {
        actions: '='
      },
      transclude: true,
      templateUrl: 'app/blocks/widgets/header/header.html'
    };
  }

  Header.$inject = [];

  angular.module('blocks.widgets')
    .directive('kzHeader', Header);
})();
