(function() {
  'use strict';

  var CHART_COLORS = [
    { r: 244, g: 67, b: 54 },
    { r: 233, g: 30, b: 9 },
    { r: 156, g: 39, b: 176 },
    { r: 103, g: 58, b: 183 },
    { r: 63, g: 81, b: 181 },
    { r: 33, g: 150, b: 243 },
    { r: 3, g: 169, b: 244 },
    { r: 0, g: 188, b: 212 },
    { r: 0, g: 150, b: 136 },
    { r: 76, g: 175, b: 80 },
    { r: 139, g: 195, b: 74 },
    { r: 205, g: 220, b: 57 },
    { r: 255, g: 193, b: 7 },
    { r: 255, g: 152, b: 0 },
    { r: 121, g: 85, b: 72 },
    { r: 3, g: 73, b: 244 },
    { r: 244, g: 54, b: 79 },
    { r: 0, g: 188, b: 212 },
    { r: 220, g: 170, b: 57 },
    { r: 255, g: 50, b: 0 },
    { r: 69, g: 63, b: 181 },
    { r: 0, g: 150, b: 136 },
    { r: 121, g: 72, b: 79 },
    { r: 139, g: 195, b: 74 },
    { r: 0, g: 188, b: 212 },
    { r: 63, g: 128, b: 181 },
    { r: 0, g: 212, b: 194 },
    { r: 0, g: 150, b: 106 },
    { r: 233, g: 30, b: 180 },
    { r: 121, g: 75, b: 72 },
    { r: 121, g: 95, b: 72 },
    { r: 3, g: 169, b: 244 },
    { r: 244, g: 67, b: 54 },
    { r: 92, g: 175, b: 76 },
    { r: 78, g: 58, b: 183 },
    { r: 0, g: 104, b: 150 },
    { r: 63, g: 81, b: 181 },
    { r: 244, g: 105, b: 54 },
    { r: 218, g: 255, b: 7 },
    { r: 187, g: 195, b: 74 },
    { r: 103, g: 58, b: 183 },
    { r: 233, g: 30, b: 140 },
    { r: 3, g: 244, b: 223 },
    { r: 255, g: 101, b: 0 },
    { r: 0, g: 134, b: 150 },
    { r: 255, g: 193, b: 7 },
    { r: 163, g: 195, b: 74 },
    { r: 255, g: 152, b: 0 },
    { r: 33, g: 108, b: 243 },
    { r: 176, g: 39, b: 141 },
    { r: 129, g: 39, b: 176 },
    { r: 176, g: 39, b: 169 },
    { r: 233, g: 30, b: 58 },
    { r: 121, g: 85, b: 72 },
    { r: 205, g: 220, b: 57 },
    { r: 255, g: 193, b: 7 },
    { r: 101, g: 39, b: 176 },
    { r: 156, g: 39, b: 176 },
    { r: 0, g: 212, b: 151 },
    { r: 255, g: 143, b: 7 },
    { r: 0, g: 150, b: 136 },
    { r: 0, g: 103, b: 212 },
    { r: 139, g: 195, b: 74 },
    { r: 244, g: 54, b: 117 },
    { r: 76, g: 175, b: 100 },
    { r: 3, g: 169, b: 244 },
    { r: 220, g: 202, b: 57 },
    { r: 121, g: 105, b: 72 },
    { r: 233, g: 30, b: 99 },
    { r: 91, g: 195, b: 74 },
    { r: 33, g: 66, b: 243 },
    { r: 233, g: 30, b: 99 },
    { r: 103, g: 58, b: 183 },
    { r: 58, g: 63, b: 183 },
    { r: 112, g: 175, b: 76 },
    { r: 76, g: 175, b: 80 },
    { r: 25, g: 15, b: 0 },
    { r: 14, g: 22, b: 57 },
    { r: 6, g: 8, b: 181 },
    { r: 24, g: 6, b: 54 },
    { r: 25, g: 9, b: 7 },
    { r: 9, g: 6, b: 181 },
    { r: 3, g: 15, b: 243 },
    { r: 3, g: 23, b: 243 }
  ];

  angular.module('component.reports')
    .constant('CHART_COLORS', CHART_COLORS);
})();
