(function() {
  'use strict';

  function Sidebar($rootScope, $transitions, SidebarService) {
    return {
      restrict: 'A',
      templateUrl: 'app/layout/sidebar/sidebar.html',
      link: function(scope) {
        var ctrl = scope;
        ctrl.cards = scope.cards || [];
        // Notify.success('Loading page');
        ctrl.cards = SidebarService.getCardsForRoute();
        ctrl.loaded = true;
        // scope.$on('$stateChangeSuccess', function() {
        $transitions.onSuccess({}, function() {
          ctrl.cards = [];
          // Notify.success('Loading page');
          ctrl.cards = SidebarService.getCardsForRoute();
        });

        scope.$on('KzPageLoaded', function() {
          // Notify.success('Page loaded');
          ctrl.cards = SidebarService.getCardsForRoute();
        });


        $rootScope.$on('sidebarCard:toggleSticky', function(_event, isSticky, cardId) {
          // if the toggle resulted in a card becoming sticky, make that card visible
          ctrl.cards = _.map(ctrl.cards, function(card) {
            if (isSticky) {
              card.isHidden = false;

              if (card.id !== cardId) {
                card.isHidden = true;
              }
            } else {
              card.isHidden = false;
            }

            return card;
          });
        });
      }
    };
  }

  Sidebar.$inject = ['$rootScope', '$transitions', 'SidebarService'];

  angular.module('layout.sidebar')
    .directive('kzSidebar', Sidebar);
})();
