(function() {
  'use strict';

  function EmailTemplateFactory() {
    var EmailTemplate = function(doc, emailType) {
      this.doc = doc;
      this.currentTemplate = emailType.doc.defaultTemplate;
    };

    EmailTemplate.prototype.deserialize = function(doc) {
      return angular.copy(doc);
    };

    EmailTemplate.prototype.serialize = function(data) {
      return angular.copy(data);
    };

    EmailTemplate.prototype.getBorderClass = function() {
      if (this.currentTemplate.id === this.doc._id) {
        return 'progress-border-complete';
      }

      return '';
    };

    return EmailTemplate;
  }

  EmailTemplateFactory.$inject = [
  ];

  angular.module('component.emailTemplates')
    .factory('EmailTemplateFactory', EmailTemplateFactory);
})();
