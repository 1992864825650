(function() {
  'use strict';

  function ReportViewerFactory($q, $rootScope, $filter, Utils, Auth, APIList, List,
                               Blueprints, Events, Event, EventsStore, Calculator,
                               separateByUppercaseFilter, capitalizeFilter) {
    var ReportViewer = function(data, options) {
      this.options = options || {};
      this.forUser = this.options.forUser || undefined;
      this.report = data.reportData;
      this.search = {};
      this.overview = '';
      this.tree = [];
    };

    var getReportSubTitle = function(blueprint) {
      var subtitle = 'This shows the tags available for <strong>' +
          blueprint.value + '</strong>. Events tagged ' +
          'with this information are shown on the right hand side.';

      return subtitle;
    };

    ReportViewer.prototype.setTitles = function() {
      var _this = this;
      var categoryId = this.report.blueprintCatId || this.report.blueprint;
      return Blueprints.findByCategoryId(categoryId)
        .then(function(blueprint) {
          _this.title = capitalizeFilter(separateByUppercaseFilter(blueprint.value));
          _this.subtitle = getReportSubTitle(blueprint);
        });
    };

    ReportViewer.prototype.init = function() {
      return this.initApiList();
      // var promise;
      // if (_.isUndefined(this.forUser)) {
      //   var settings = Auth.getSettings();
      //   promise = settings.dataOptimized ? this.initCouchList() : this.initList();
      // } else {
      //   promise = this.initApiList();
      // }

      // return promise
      //   .catch(function(error) {
      //     console.log(error);
      //     return $q.reject(error);
      //   });
    };

    ReportViewer.prototype.getDefaultFilter = function() {
      var blueprintCatId = this.report.blueprintCatId || this.report.blueprint;
      var flt = { blueprint: [blueprintCatId] };
      flt.own = !this.forUser;
      flt.user = this.forUser || Auth.currentUser();

      if (this.report.restrictionType === 'none') {
        return $q.when(flt);
      } else if (this.report.restrictionType === 'date') {
        _.assign(flt, {
          after: this.report.startDate,
          before: this.report.endDate
        });
        return $q.when(flt);
      }

      return $q.reject({ status: 500, message: 'Invalid restriction type' });
    };

    function sortBlueprintTags(tags) {
      return _.chain(tags)
        .map(function(tag) {
          if (!_.isEmpty(tag.categories)) {
            tag.categories = sortBlueprintTags(tag.categories);
          }

          return tag;
        })
        .sortBy('name')
        .value();
    }

    ReportViewer.prototype.getSearchObject = function() {
      var _this = this;
      return {
        options: {
          noButtons: true
        },
        facetly: {
          facets: [
            {
              id: 'after',
              label: 'Events after',
              type: 'date'
            },
            {
              id: 'before',
              label: 'Events before',
              type: 'date'
            }
          ]
        },
        filters: [
          {
            id: 'blueprint',
            directive: 'kz-field-filter',
            controller: ['$scope', function($scope) {
              $scope.tree = _this.tree;
              $scope.updateCoverage = function() {
                return _this.getCoverage()
                  .then(function(data) {
                    if (_.isEmpty(data.blueprintTags)) {
                      _this.noBlueprints = true;
                    } else {
                      data.blueprintTags = sortBlueprintTags(data.blueprintTags);
                    }
                    _.assign(_this.tree, data.blueprintTags);
                  })
                  .catch(function(error) {
                    console.log(error);
                  });
              };

              $scope.updateCoverage();
              $scope.$on('reportsviewer.listUpdated', function() {
                $scope.updateCoverage();
              });

              $scope.selected = [];
              $scope.kzTree = '';
              $scope.treeOptions = {
                selectable: true,
                searchable: true,
                icons: {
                  arrowRight: 'icon-right-open-big',
                  arrowDown: 'icon-down-open-big',
                  empty: 'icon-minus'
                }
              };
            }],
            type: 'tree',
            label: 'Select items from the below to see which events have been tagged with them ' +
            'on the right hand side',
            required: false,
            preMatchFunc: function(value) {
              return Blueprints.findChildCategories(value);
            },
            matchFunc: function(event, _key, value, preMatch) {
              if (!value || value.length === 0) {
                return true;
              }

              return event.init()
               .then(function() {
                 return _.intersection(event.categories, preMatch).length;
               })
              .catch(function() {
                return false;
              });
            },
            ngModelAttrs: {
              kzTree: {
                bound: 'kzTree',
                attribute: 'kz-tree'
              },
              selected: {
                bound: 'selected',
                attribute: 'selected'
              },
              nodes: {
                bound: 'nodes',
                attribute: 'nodes'
              },
              options: {
                bound: 'treeOptions',
                attribute: 'options'
              }
            },
            ngModelAttrsValues: [
              {
                name: 'kzTree',
                value: 'kzTree'
              },
              {
                name: 'selected',
                value: 'selected'
              },
              {
                name: 'nodes',
                value: 'tree'
              },
              {
                name: 'options',
                value: 'treeOptions'
              }
            ]
          },
          {
            id: 'after',
            type: 'date',
            directive: 'hidden-filter',
            label: 'Show only events that have started after',
            matchFunc: function(event, _key, value) {
              if (!value || !event.doc.startDate) { return false; }

              var when = Utils.fromDate(value);
              when.setHours(0);
              var start = Utils.fromDate(event.doc.startDate);
              start.setHours(0);
              return start >= when;
            }
          },
          {
            id: 'before',
            type: 'date',
            directive: 'hidden-filter',
            label: 'Show only events that have completed before',
            matchFunc: function(event, _key, value) {
              if (!value || !event.doc.endDate) { return false; }

              var when = Utils.fromDate(value);
              when.setDate(when.getDate() + 1);
              when.setHours(0);
              var end = Utils.fromDate(event.doc.endDate);
              end.setHours(0);
              return end <= when;
            }
          }
        ],
        orders: {
          startDate: function(event) {
            if (event.doc.startDate) {
              return -(new Date(event.doc.startDate).getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          },
          addedDate: function(event) {
            var date = event.doc.createdDate || (event.doc.date && event.doc.date.added);
            if (date) {
              return -(new Date(date).getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          },
          state: function(event) {
            var orderedStates = ['draft', 'published', 'archived'];
            return orderedStates.indexOf(event.doc.state);
          }
        },
        orderGroups: {
          addedDate: {
            title: 'date created',
            orders: ['addedDate', 'startDate'],
            groupBy: {
              title: 'Events created in',
              notitle: 'Events without a date',
              getter: function(item) {
                var event = item.main || item;
                var date = event.doc.createdDate || (event.doc.date && event.doc.date.added);
                if (date) {
                  return $filter('date')(date, 'MMMM y');
                }

                return;
              }
            }
          }
        },
        defaultOrder: 'addedDate'
      };
    };

    ReportViewer.prototype.getCoverage = function() {
      // var _this = this;
      // // if (!_.isUndefined(this._coverage)) {
      //   return $q.when(this._coverage);
      // }
      var options = { user: this.forUser, period: 'all' };
      if (this.report.blueprintCatId) {
        options.blueprintCatId = this.report.blueprintCatId;
      }

      var blueprint = this.report.blueprint;
      return Calculator.calculateBlueprintCoverage(blueprint, this.list, options)
        .then(function(data) {
          // _this._coverage = data;
          $rootScope.$broadcast('reportsviewer.CoverageUpdated');
          return data;
        });
    };

    ReportViewer.prototype.initList = function() {
      var _this = this;
      var list;

      return this.getDefaultFilter()
        .then(function(defaultFilter) {
          _this.defaultFilter = defaultFilter;
          return _this.setTitles();
        })
        .then(function() {
          return EventsStore.load();
        })
        .then(function(events) {
          _this.search = _this.getSearchObject();
          _this.searchModel = { filteredBy: {} };
          list = new List({
            search: _this.search,
            model: _this.searchModel,
            defaultFilter: _this.defaultFilter,
            idField: 'doc._id' }
          );
          return list.set(events);
        })
        .then(function() {
          list.listType = 'dblist';
          _this.list = list;
          _this.list.defaultFilter = _this.defaultFilter;
          _this.list.initialized = true;
          $rootScope.$broadcast('reportsviewer.listUpdated');
          return _this.list;
        });
    };

    ReportViewer.prototype.initCouchList = function() {
      var list,
          makeDocs;
      var _this = this;

      makeDocs = function(item) {
        return new Event(item);
      };

      return this.getDefaultFilter()
        .then(function(defaultFilter) {
          _this.defaultFilter = defaultFilter;
          return _this.setTitles();
        })
        .then(function() {
          _this.searchModel = {};
          list = new APIList(Events, {
            search: _this.getSearchObject(),
            model: _this.searchModel,
            defaultFilter: _this.defaultFilter,
            makeDocs: makeDocs,
            endpoint: 'eventsearch'
          });

          list.listType = 'couchlist';
          _this.list = list;
          $rootScope.$broadcast('reportsviewer.listUpdated');
          list.initialized = true;
          return list;
        });
    };

    ReportViewer.prototype.initApiList = function() {
      var list,
          makeDocs;
      var _this = this;

      makeDocs = function(item) {
        var isLocal = Auth.currentUser() === item.user;
        return new Event(item, { noExtra: true, isLocal: isLocal });
      };

      return this.getDefaultFilter()
        .then(function(defaultFilter) {
          _this.defaultFilter = defaultFilter;
          return _this.setTitles();
        })
        .then(function() {
          _this.search = _this.getSearchObject();
          _this.searchModel = { filteredBy: {} };
          list = new APIList(Events, {
            search: _this.search,
            model: _this.searchModel,
            defaultFilter: _this.defaultFilter,
            makeDocs: makeDocs
          });

          list.listType = 'apilist';
          _this.list = list;
          $rootScope.$broadcast('reportsviewer.listUpdated');
          list.initialized = true;
          return list;
        });
    };

    return ReportViewer;
  }

  ReportViewerFactory.$inject = [
    '$q',
    '$rootScope',
    '$filter',
    'UtilsService',
    'AuthService',
    'APIListFactory',
    'ListFactory',
    'BlueprintsService',
    'EventsService',
    'EventFactory',
    'EventsStore',
    'ReportCalculator',
    'separateByUppercaseFilter',
    'capitalizeFilter'
  ];

  angular.module('component.reports')
    .factory('ReportViewerFactory', ReportViewerFactory);
})();
