export const EventsTemplates = {
  'app/components/events/partials/audit-log.include.html': require(
    './partials/audit-log.include.html'
  ).default,
  'app/components/events/directives/eventsection-slot.html': require(
    './directives/eventsection-slot.html'
  ).default,
  'app/components/events/directives/eventsection-slot-compact.html': require(
    './directives/eventsection-slot-compact.html'
  ).default
};

