(function() {
  'use strict';

  function Slot($stateParams, Announcements, LocalizationService) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/announcements/inbox/inbox-message-details.card.html',
      link: function($scope) {
        $scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
        $scope.message = {};
        $scope.loaded = false;

        if ($stateParams.id) {
          Announcements.findAllExtrasFor($stateParams.id)
            .then(function(extras) {
              $scope.message = extras;
              $scope.loaded = true;
            })
            .catch(function(err) {
              console.error(err);
              $scope.loaded = true;
            });
        }
      }
    };
  }

  Slot.$inject = ['$stateParams', 'AnnouncementsService', 'LocalizationService'];

  angular.module('component.announcements')
    .directive('inboxMessageDetails', Slot);
})();
