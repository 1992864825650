(function() {
  'use strict';

  function capitalize(input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  function filter() {
    return function(file) {
      let status;
      if (!file) {
        status = 'N/A';
      } else if (file.status === 'failed') {
        status = file.error;
      } else if (file.status === 'uploaded' &&
        file.currentSubmission !== undefined &&
        file.currentHash === file.currentSubmission.uploaded_hash
      ) {
        if (file.currentSubmission.error_message) {
          status = file.currentSubmission.error_message;
        } else if (file.currentSubmission.state.state === 'complete') {
          status = 'Similarity score: ' + file.currentSubmission.score + '%';
        } else {
          status = file.currentSubmission.state.message;
        }
      } else {
        status = capitalize(file.status);
      }
      return status;
    };
  }

  angular.module('blocks.utils')
      .filter('fileStatus', filter);
})();
