(function() {
  'use strict';

  function kzSearchString() {
    return {
      restrict: 'AE',
      scope: {
        list: '='
      },
      templateUrl: 'app/blocks/widgets/search/kz-search-string.html'
    };
  }

  angular.module('blocks.widgets')
    .directive('kzSearchString', kzSearchString);
})();
