(function() {
  'use strict';

  function offlineLimit(Network) {
    return {
      templateUrl: 'app/blocks/utils/offline.limit.html',
      link: function(scope) {
        scope.offline = Network.isOffline();
      }
    };
  }

  offlineLimit.$inject = ['NetworkService'];

  angular.module('blocks.utils')
    .directive('offlineLimit', offlineLimit);
})();
