(function() {
  'use strict';

  function EventUsersController($scope, $q, Auth, Security) {
    var ctrl = this;

    ctrl.username = Auth.currentUser();
    ctrl.event = $scope.event;

    // if the event has linked events
    if (ctrl.event && _.isArray(ctrl.event.linkedEvents) && ctrl.event.linkedEvents.length > 0) {
      ctrl.users = [];

      var eventUser = [],
          users = [],
          linkedEventsUsers = _.map(ctrl.event.linkedEvents, function(evt) {
            if (evt.user !== ctrl.username) {
              return evt.user;
            }
          }),
          checkPermissions = function(users) {
            return $q.all(
                users.map(function(user) {
                  return Security.hasPermissionFor('events.view', user)
                    .catch(function() {
                      return false;
                    })
                    .then(function(hasPermission) {
                      var canView = false;
                      if (hasPermission) {
                        canView = true;
                      }

                      return { user: user, canView: canView };
                    });
                }))
              .then(function(results) {
                ctrl.users = results;
              });
          };

      users = _.union(linkedEventsUsers, eventUser);

      checkPermissions(users);
    }
  }

  EventUsersController.$inject = ['$scope', '$q', 'AuthService', 'SecurityService'];

  function EventUsers() {
    return {
      scope: {
        event: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/events/directives/event-users.html',
      replace: true,
      controller: EventUsersController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventUsers', EventUsers)
    .controller('EventUsersController', EventUsersController);
})();
