(function() {
  'use strict';

  function Search(Utils, moment, PERMISSIONS) {
    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'name',
              type: 'text',
              label: 'Start typing to search'
            },
            {
              id: 'permissions',
              type: 'select',
              label: 'By permissions',
              multiselect: true,
              options: _.map(PERMISSIONS, function(perm) {
                return { id: perm.name, title: perm.description };
              }),
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'name',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'name',
            matchFunc: function(item, _key, value) {
              if (!value) { return true; }

              var val = value.toLowerCase(),
                  name = Utils.safeLowerStr(item.doc.title) + ' ' +
                    Utils.safeLowerStr(item.doc.description);
              return name.indexOf(val) > -1;
            }
          },
          {
            id: 'permissions',
            matchFunc: function(item, _key, value) {
              if (!value || value.length === 0) { return true; }
              var usage = item.doc.permissions || [];
              return _.intersection(usage, value).length > 0;
            }
          }
        ],
        orders: {
          name: function(item) {
            return Utils.safeLowerStr(item.doc.title);
          },
          added: function(item) {
            var dt = item.doc.createdDate;
            if (dt === undefined && item.doc.dates && item.doc.dates.length) {
              dt = item.doc.dates[0].date;
            }
            if (dt === undefined) {
              return Number.MAX_SAFE_INTEGER;
            }
            return -(moment(dt).toDate().getTime());
          },
          modified: function(item) {
            var dt = item.doc.modifiedDate;
            if (dt === undefined && item.doc.dates && item.doc.dates.length) {
              dt = _.last(item.doc.dates).date;
            }
            if (dt === undefined) {
              return Number.MAX_SAFE_INTEGER;
            }
            return -(moment(dt).toDate().getTime());
          }
        },
        orderGroups: {
          name: {
            title: 'name',
            orders: ['name']
          },
          added: {
            title: 'added',
            orders: ['added', 'name']
          },
          modified: {
            title: 'most recent',
            orders: ['modified', 'name']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'name'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = ['UtilsService', 'moment', 'PERMISSIONS'];

  angular.module('component.roles')
    .service('RoleSearch', Search);
})();
