(function() {
  'use strict';

  function RelationsReorderController($state, Relations, Notify, Utils, BLUEPRINT_TYPES) {
    var ctrl = this;
    ctrl.loaded = false;

    Utils.setPageTitle('Reorder relations');

    ctrl.options = {
      trackBy: 'doc._id',
      stateMapping: _.keyBy(_.map(BLUEPRINT_TYPES, function(item) {
        return { id: item.name, name: item.label };
      }), 'id'),
      stateField: 'doc.fieldType'
    };

    var getPrevious;
    var getNext;

    Relations.findAll({ cached: false })
      .then(function(items) {
        ctrl.relations = _.chain(items)
                              .sortBy(function(item) {
                                return item.doc.order;
                              })
                              .value();

        ctrl.loaded = true;
      });

    ctrl.actions = [
      {
        label: 'Done',
        icon: 'icon-save',
        href: (function() { return $state.href('epf.relations.index'); })(),
        klass: 'btn-success'
      }
    ];

    getPrevious = function(c, l) {
      return c === 0 ? c : l[c - 1].doc.order;
    };

    getNext = function(c, l) {
      return c === l.length - 1 ? l[c].doc.order + 1 : l[c + 1].doc.order;
    };

    ctrl.reorder = function(evt) {
      var current = _.findIndex(evt.models,
        function(m) { return m.doc._id === evt.model.doc._id; }),
          prev = getPrevious(current, evt.models),
          next = getNext(current, evt.models),
          order = Utils.getNewOrderIndex(prev, next);

      Relations.updateOrder(evt.model.doc._id, order)
        .then(function(data) {
          evt.model.doc._rev = data.rev;
          evt.model.doc.order = order;
          Notify.success('Order successfully updated!', 'Success');
        })
        .catch(Utils.showError);
    };
  }

  RelationsReorderController.$inject = [
    '$state',
    'RelationsService',
    'NotifyService',
    'UtilsService',
    'BLUEPRINT_TYPES'
  ];

  angular.module('component.relations')
    .controller('RelationsReorderController', RelationsReorderController);
})();
