(function() {
  'use strict';

  function HamburgerMenuController($rootScope, $scope) {
    var ctrl = this;

    ctrl.show = false;
    $rootScope.burgerMenuShown = ctrl.show;

    ctrl.onClickLink = function() {
      delete ctrl.categoryTitle;
      ctrl.links = ctrl.rootLinks;
      ctrl.show = !ctrl.show;
      $rootScope.burgerMenuShown = ctrl.show;
    };

    ctrl.goTo = function(dest, categoryTitle) {
      if (dest === 'back') {
        ctrl.links = ctrl.rootLinks;
      } else {
        ctrl.links = dest;
      }
      ctrl.categoryTitle = categoryTitle;
    };

    function updateLinks(links) {
      ctrl.rootLinks = _.map(links, function(link) {
        if (!_.isUndefined(link.links) && link.links.length === 1) {
          link = link.links[0];
        }

        return link;
      });
      ctrl.links = angular.copy(ctrl.rootLinks);
    }
    updateLinks($scope.links);

    $rootScope.$on('KZMenuUpdated', function(_evt, menu) {
      updateLinks(menu);
    });
  }

  HamburgerMenuController.$inject = ['$rootScope', '$scope'];

  function HamburgerMenuDirective() {
    return {
      restrict: 'EA',
      templateUrl: 'app/layout/header/kz-hamburger-menu.html',
      transclude: true,
      scope: {
        links: '='
      },
      controller: 'HamburgerMenuController',
      controllerAs: 'hamburgerMenuCtrl'
    };
  }

  angular.module('kzHeaderCard')
    .directive('kzHamburgerMenu', HamburgerMenuDirective)
    .controller('HamburgerMenuController', HamburgerMenuController);
})();
