(function() {
  'use strict';

  function RelationsController($state, Profile, Relations, RelationSearch, List, Security, Utils) {
    var ctrl = this;
    ctrl.options = {
      trackBy: 'doc._id'
    };

    Utils.setPageTitle('Relations');

    ctrl.searchModel = Profile.getListPreferences('relations').current;

    ctrl.list = new List({
      search: RelationSearch.search,
      model: ctrl.searchModel,
      idField: 'doc._id'
    }
    );

    Relations.findAll({ cached: false })
      .then(function(data) {
        ctrl.list.doLoadItems(data);
      });

    ctrl.actions = [
      {
        label: 'Reorder',
        icon: 'icon-move',
        href: (function() { return $state.href('epf.relations.reorder'); })(),
        showCondition: Security.hasPermission.bind(this, 'relations.edit'),
        klass: 'btn-border btn-info'
      },
      {
        label: 'New relation',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.relations.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'relations.edit'),
        klass: 'btn-success'
      }
    ];

    // Defined here so that I don't have to create a special slot that does nothing
    ctrl.options.actionButtons = function(item) {
      return [
        {
          label: 'Edit',
          icon: 'icon-edit',
          href: (function() { return $state.href('epf.relations.edit', { id: item.doc._id }); })(),
          showCondition: Security.hasPermission.bind(this, 'relations.edit'),
          klass: 'text-info'
        },
        {
          label: 'View',
          icon: 'icon-edit',
          href: (function() { return $state.href('epf.relations.edit', { id: item.doc._id }); })(),
          showCondition: function() {
            return Security.hasPermission('relations.edit')
              .then(function(has) {
                return !has;
              })
              .catch(function() {
                return true;
              });
          },
          klass: 'text-info'
        }
      ];
    };

    ctrl.options.secondaryActionButtons = function(item) {
      return [
        {
          label: 'Duplicate',
          icon: 'icon-duplicate',
          onClick: function() {
            Relations.getNewCopy(item.doc._id)
              .then(function(newCopy) {
                $state.go('epf.relations.new', { defaults: newCopy });
              });
          },
          showCondition: Security.hasPermission.bind(this, 'relations.edit'),
          klass: 'text-info'
        }
      ];
    };
  }

  RelationsController.$inject = [
    '$state',
    'ProfileService',
    'RelationsService',
    'RelationSearch',
    'ListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.relations')
    .controller('RelationsController', RelationsController);
})();
