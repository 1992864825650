(function() {
  'use strict';

  function InboxMessageController(
    $stateParams,
    Announcements,
    Auth,
    LocalizationService,
    Utils
  ) {
    var ctrl = this;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.message = {};

    if ($stateParams.id) {
      Announcements.find($stateParams.id).then(function(data) {
        ctrl.message = data;
        Utils.setPageTitle('Inbox: ' + ctrl.message.title);
        // We need to mark this announcement as read
        if (!ctrl.message.isRead) {
          Announcements.addUserExtra({
            _id: Utils.guid(),
            type: 'announcementExtra',
            announcement: ctrl.message._id,
            organisation: Auth.currentOrganisation(),
            user: Auth.currentUser(),
            readDate: Utils.now()
          })
            .then(function() {
              ctrl.message.isRead = true;
            })
            .catch(Utils.showError);
        }
        ctrl.loaded = true;
      });
    }
  }

  InboxMessageController.$inject = [
    '$stateParams',
    'AnnouncementsService',
    'AuthService',
    'LocalizationService',
    'UtilsService'
  ];

  angular.module('component.announcements')
    .controller('InboxMessageController', InboxMessageController);
})();
