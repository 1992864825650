(function() {
  'use strict';

  function kzLinkedFieldsFilter(Form) {
    var controller = function($scope) {
      var ctrl = this;

      ctrl.formData = {};

      ctrl.form = new Form();

      ctrl.idx = 1;

      var label = ctrl.filter.label,
          id = ctrl.filter.id;

      // FIXME Use the approach used in kz-relations-fields-filter.directive.js

      ctrl.addPair = function() {
        var curr = ctrl.idx;

        ctrl.form.addField({
          id: id + '_id' + curr,
          type: 'string',
          required: false,
          label: label + ' id ' + curr
        });

        ctrl.form.addField({
          id: id + '_value' + curr,
          type: 'string',
          required: false,
          label: label + ' value ' + curr,
          expressionProperties: {
            hide: function(_$viewValue, _$modelValue, scope) {
              return _.isEmpty(scope.model[id + '_id' + curr]);
            }
          }
        });

        ctrl.idx++;
      };

      $scope.$watchCollection(
        function() {
          return (ctrl.formData);
        },
        function(newValue) {
          if (newValue) {
            ctrl.search.filteredBy[id] = [];

            var keys = _.keys(ctrl.formData),
                ids = _.sortBy(_.filter(keys, function(key) {
                  return _.startsWith(key, id + '_id');
                })),
                values = _.sortBy(_.filter(keys, function(key) {
                  return _.startsWith(key, id + '_value');
                })),
                isEmpty = function(obj) {
                  return _.isUndefined(obj) || _.isEmpty(obj);
                };

            _.zipWith(ids, values, function(a, b) {
              if (!isEmpty(ctrl.formData[a]) && !isEmpty(ctrl.formData[b])) {
                ctrl.search.filteredBy[id].push(ctrl.formData[a] + ':' + ctrl.formData[b]);
              }
            });
          }
        }
      );

      ctrl.addPair();
    };

    controller.$inject = ['$scope'];

    return {
      restrict: 'AEC',
      scope: {
        search: '=',
        filter: '=',
        getOptions: '='
      },
      controller: controller,
      controllerAs: 'ctrl',
      bindToController: true,
      templateUrl: 'app/blocks/widgets/filters/kz-linked-fields.html'
    };
  }

  kzLinkedFieldsFilter.$inject = ['FormsService'];

  angular.module('blocks.widgets')
    .directive('kzLinkedFieldsFilter', kzLinkedFieldsFilter);
})();
