(function() {
  'use strict';

  function kzRelationsFieldsFilter(Form) {
    var controller = function($scope, Utils, Relations) {
      var ctrl = this;

      ctrl.formData = {};

      ctrl.form = new Form();

      ctrl.idx = 1;

      var label = 'Relation',
          id = 'relation';

      var addValueField = function(curr, options, itemLabel, itemValue, group) {
        ctrl.form.removeField(id + '_value' + curr, group);
        ctrl.formData[id + '_value' + curr] = '';

        ctrl.form.addField({
          id: id + '_value' + curr,
          type: 'select',
          required: false,
          label: label + ' value ' + curr,
          itemLabel: itemLabel,
          itemValue: itemValue,
          options: options
        }, group);
      };

      ctrl.addPair = function() {
        var curr = ctrl.idx;
        var group = ctrl.form.addGroup();

        ctrl.form.addField({
          id: id + '_id' + curr,
          type: 'select',
          required: false,
          label: label + ' id ' + curr,
          itemLabel: 'name',
          itemValue: 'id',
          options: Relations.findAll()
            .then(function(data) {
              var relations = [];
              relations.push({ id: '', name: '---' });
              _.forEach(data, function(relation) {
                relations.push({ id: relation.id, name: relation.doc.name });
              });
              return relations;
            }),
          onChange: function($viewValue) {
            if ($viewValue) {
              Relations.findByCategoryId($viewValue, { include_docs: true })
                .then(function(data) {
                  var options = [];
                  _.forEach(data.doc.categories, function(category) {
                    _.forEach(Utils.flatten(category, {}), function(v, k) {
                      // Hack, please fix me - it is undefined for first level only
                      if (k === 'undefined') {
                        options.push({ name: category.name, id: v._id });
                      } else {
                        options.push({ name: category.name + ' - ' + k, id: v._id });
                      }
                    });
                  });

                  addValueField(curr, options, 'name', 'id', group);
                });
            } else {
              ctrl.form.removeField(id + '_value' + curr, group);
            }
          }
        }, group);

        ctrl.idx++;
      };

      $scope.$watchCollection(
        function() {
          return (ctrl.formData);
        },
        function(newValue) {
          if (newValue) {
            ctrl.search.filteredBy[id] = [];

            var keys = _.keys(ctrl.formData),
                ids = _.sortBy(_.filter(keys, function(key) {
                  return _.startsWith(key, id + '_id');
                })),
                values = _.sortBy(_.filter(keys, function(key) {
                  return _.startsWith(key, id + '_value');
                })),
                isEmpty = function(obj) {
                  return _.isUndefined(obj) || _.isEmpty(obj);
                };

            _.zipWith(ids, values, function(a, b) {
              if (!isEmpty(ctrl.formData[a]) && !isEmpty(ctrl.formData[b])) {
                ctrl.search.filteredBy[id].push(ctrl.formData[a] + ':' + ctrl.formData[b]);
              }
            });
          }
        }
      );

      ctrl.addPair();
    };

    controller.$inject = ['$scope', 'UtilsService', 'RelationsService'];

    return {
      restrict: 'AEC',
      scope: {
        search: '=',
        filter: '='
      },
      controller: controller,
      controllerAs: 'ctrl',
      bindToController: true,
      templateUrl: 'app/components/relations/filters/kz-relations-fields-filter.html'
    };
  }

  kzRelationsFieldsFilter.$inject = ['FormsService'];

  angular.module('blocks.widgets')
    .directive('kzRelationsFieldsFilter', kzRelationsFieldsFilter);
})();
