
(function() {
  'use strict';

  function ReportFormDirective($q, $log, Reports, Report, Utils) {
    return {
      scope: {
        reportEx: '=report',
        reportDoc: '=',
        reportId: '@',
        model: '=',
        defaultModel: '=?',
        required: '@?',
        disabled: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-form.html',
      replace: true,
      link: function($scope) {
        function loadReport() {
          var promise;
          if ($scope.reportEx !== undefined) {
            $scope.baseReport = $scope.reportEx;
            promise = $q.when();
          } else if ($scope.reportDoc !== undefined) {
            $scope.baseReport = new Report($scope.reportDoc);
            promise = $q.when();
          } else if (!_.isEmpty($scope.reportId)) {
            promise = Reports.getGroupByItemId($scope.reportId, true)
              .then(function(doc) {
                $scope.baseReport = new Report(doc);
              })
              .catch(function(err) {
                $log.warn('Report not found', err);
              });
          } else {
            promise = $q.reject();
          }

          return promise;
        }

        function setupReport() {
          loadReport()
            .then(function() {
              $scope.report = $scope.baseReport.getSingleOrMultiReportByDoc();
              $log.debug('Loaded report');
              return $scope.report.getForm({ ignoreUser: true });
            })
            .then(function(form) {
              $log.debug('Loaded report form');
              if ($scope.required) {
                form.fields.forEach(function(item) {
                  item.templateOptions.required = true;
                });
              }

              if ($scope.disabled) {
                form.fields.forEach(function(item) {
                  item.templateOptions.disabled = true;
                });
              }

              $scope.reportForm = form;
              $scope.requiredFields = _.map(form.fields, 'id');

              return $q.all([
                $q.when($scope.defaultModel || $scope.report.getDefaultModel()),
                $scope.report.clearModel($scope.model)
              ]);
            })
            .then(function(result) {
              // Pick the default and update with the data from cleared model
              $scope.model = _.assignIn({}, result[0], result[1]);
              $scope.loaded = true;
            });
        }

        $scope.$watch('reportEx', function(report) {
          if (_.isUndefined(report)) {
            return;
          }

          setupReport();
        });

        $scope.$watch('reportDoc', function(reportDoc) {
          if (_.isUndefined(reportDoc)) {
            return;
          }
          setupReport();
        });

        $scope.$watch('reportId', function(reportId) {
          if (_.isUndefined(reportId)) {
            return;
          }

          setupReport();
        });

        $scope.resetModel = function() {
          $q.when($scope.defaultModel || $scope.report.getDefaultModel())
            .then(function(model) {
              $scope.model = model;
            });
        };

        $scope.hasChanged = function() {
          return !_.isEqual($scope.defaultModel, $scope.model);
        };

        $scope.$on('ReportChanged', function() {
          setupReport();
        });

        $scope.modelWatcher = $scope.$watch('model', function(newVal) {
          console.log('in watcher');
          var validity = false;
          if (newVal === undefined) {
            validity = false;
          } else {
            validity = _.every($scope.requiredFields, function(fldId) {
              return !Utils.isBlank(newVal[fldId]);
            });
          }

          $scope.$emit('kzReportFormValidity', { validity: validity });
        }, true);
      }
    };
  }

  ReportFormDirective.$inject = [
    '$q',
    '$log',
    'ReportTemplatesService',
    'ReportFactory',
    'UtilsService'
  ];

  angular.module('component.reports')
    .directive('kzReportForm', ReportFormDirective);
})();
