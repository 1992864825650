(function() {
  'use strict';

  var pdfUrlEndpoints = {
    url: 'urlToPdf',
    html: 'htmlToPdf'
  };

  var MAX_PAYLOAD_SIZE = 4194304; // 4MB
  var BASE_URL = 'https://pkg7axv4xl.execute-api.eu-west-2.amazonaws.com/dev/pdf';
  // var BASE_URL = 'http://localhost:3000/pdf';

  var apiUrls = {
    urlToPdf: BASE_URL + '/url',
    htmlToPdf: BASE_URL + '/html'
  };

  var defaults = {
    filename: 'Document.pdf',
    buttonTitle: 'Print PDF'
  };

  angular.module('blocks.pdf')
    .constant('PDF_MAX_PAYLOAD_SIZE', MAX_PAYLOAD_SIZE)
    .constant('PDF_DEFAULTS', defaults)
    .constant('PDF_URL_ENDPOINTS', pdfUrlEndpoints)
    .constant('PDF_API_URLS', apiUrls);
})();
