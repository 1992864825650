(function() {
  'use strict';

  function GoalsFieldController($scope, Users, Form, LocalizationService) {
    var ctrl = this;

    ctrl.remoteUser = Users.remoteUser;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    // separate predefinedgoals from custom goals
    // set predefined as readOnly and don't show them in definition
    if (!$scope.model) {
      $scope.model = {
        definitions: []
      };
    }

    // include default
    if (!_.isUndefined($scope.def.dueDate)) {
      $scope.model.dueDate = $scope.def.dueDate;
    }

    if (!_.isUndefined($scope.def.periods)) {
      $scope.model.periods = $scope.def.periods;
    }

    // predefined predefined goals
    var alreadyIncluded = _.map($scope.model.definitions, function(goal) {
      return goal._id;
    });

    ctrl.predefinedGoalsIds = [];
    _.forEach($scope.def.predefinedGoals, function(goal) {
      ctrl.predefinedGoalsIds.push(goal._id);

      if (alreadyIncluded.indexOf(goal._id) === -1) {
        var newGoal = angular.copy(goal);
        $scope.model.definitions.push(newGoal);
      }
    });

    ctrl.customGoalsIds = _.filter($scope.model.definitions, function(goal) {
      return _.indexOf(ctrl.predefinedGoalsIds, goal._id) === -1;
    });

    ctrl.dueDateForm = new Form([
      {
        id: 'dueDate',
        type: 'date',
        label: 'Due date for all the goals in this section',
        required: true
      }
    ]);
  }

  GoalsFieldController.$inject = ['$scope', 'UsersService', 'FormsService', 'LocalizationService'];

  function GoalsField() {
    return {
      scope: {
        def: '=',
        model: '=',
        endDate: '=',
        readOnly: '=',
        form: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/goals.field.html',
      replace: false,
      controller: GoalsFieldController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('goalsField', GoalsField)
    .controller('GoalsFieldController', GoalsFieldController);
})();
