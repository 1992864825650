(function() {
  'use strict';

  function EventTypesController($state, $rootScope, Profile, EventTypeLight, EventTypes,
                                EventTypeSearch, List, Security, Utils) {
    var ctrl = this;
    // var loadList = function() {
    //   return EventTypes.findAllGrouped({ cached: false })
    //     .then(function(data) {
    //       return ctrl.list.doLoadItems(_.map(data, function(item) {
    //         return new EventType(item);
    //       }));
    //     });


    //   // Version.findAllGrouped('eventType')
    //   //   .then(function(data) {
    //   //     debugger
    //   //     ctrl.list.doLoadItems(data);
    //   //   });
    // };

    Utils.setPageTitle('Event types');

    ctrl.options = {
      // loadList: loadList
    };

    ctrl.searchModel = Profile.getListPreferences('eventtypes').current;

    var makeDocs = function(item) {
      return new EventTypeLight(item);
    };

    ctrl.list = new List({
      search: EventTypeSearch.search,
      model: ctrl.searchModel,
      idField: 'id',
      makeDocs: makeDocs,
      searchFn: EventTypes.searchIds.bind(EventTypes),
      fetchFn: function(ids) {
        return EventTypes.fetchIds(ids, { transformType: 'light' });
      },
      defaultFilter: {
        purpose: 'latestDrafts'
      }
    });
    $rootScope.$broadcast('KZCurrentListChange', { list: 'eventtypes', search: ctrl.list.search });

    ctrl.list.doLoadItems();

    // loadList();

    ctrl.actions = [
      {
        label: 'New event type',
        icon: 'icon-edit',
        href: (function() {
          return $state.href('epf.event-types.new');
        })(),
        showCondition: Security.hasPermission.bind(this, 'eventTypes.edit'),
        klass: 'btn-success'
      }
    ];
  }

  EventTypesController.$inject = [
    '$state',
    '$rootScope',
    'ProfileService',
    'EventTypeLightFactory',
    'EventTypesService',
    'EventTypeSearch',
    'APIIDListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.eventTypes')
    .controller('EventTypesController', EventTypesController);
})();
