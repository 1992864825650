(function() {
  'use strict';

  function UsersService(
    $q,
    md5,
    RestApi,
    Api,
    kzLocalStorage,
    Utils,
    Profile,
    Queue,
    Auth,
    Relations
  ) {
    var service = new RestApi('users');

    service.es_endpoint = 'es_users';

    service.findAllWithRole = function(role) {
      return service.findAll({ field: 'role', value: role });
    };

    service.findAll = function(data) {
      // get all users from Elastic
      return Api.post(this.es_endpoint, data);
    };

    service.find = function(id, kzOpts) {
      kzOpts = kzOpts || {};
      if (!_.isString(id)) {
        return $q.reject({ status: 404, message: 'Unknown user' });
      }

      if (!kzOpts.forceRemote && id === Auth.currentUser()) {
        return Profile.find(kzOpts);
      }

      // todo: dirty, random fake IE to get the up to date doc
      var opts = { _id: id };
      if (kzOpts.noCache) {
        opts.random = Math.random();
      }

      return Api.get(this.rest_endpoint, opts);
    };

    service.updatePartiallyProfile = function(partTypeToUpdate, data) {
      return Api.put('user_update', { data: data, type: partTypeToUpdate });
    };

    service.updatePin = function(doc, pin) {
      var actionData = {
        profile_id: doc._id,
        pin: pin !== undefined ? md5.createHash(pin) : ''
      };
      return Queue.submitRequest('user_pin_update', actionData, doc._id);
    };


    service.updateState = function(user, action, currentUser, reason) {
      var apiData = {
        userId: user._id,
        action: action,
        who: currentUser
      };
      if (action === 'reject') {
        apiData.reason = reason;
      }

      return Api.put('usersupdatestate', apiData);
    };

    service.getRoles = function(options) {
      options = options || {};
      var prom;
      if (options.username) {
        prom = service.find(options.username);
      } else if (options.userDoc) {
        prom = $q.when(options.userDoc);
      } else {
        return $q.reject({ status: 404, message: 'Please choose either username or userDoc' });
      }

      return prom
        .then(function(doc) {
          var roles = angular.copy(doc.roles || []);
          if (!options.justVisible) {
            roles = roles.concat(['system:timeline-owner', 'system:anyone']);
          }

          if (doc.isSuperUser) {
            roles.push('system:superadmin');
            roles.push('system:admin');
          } else if (doc.isOrgSuperUser) {
            roles.push('system:admin');
          }

          if (doc.state === 'waiting_for_approval') {
            roles.push('system:pending-user');
          }

          return roles;
        });
    };

    service.getHistory = function(options) {
      var opts = options || {};

      var getCache;
      if (opts.user && opts.user !== Auth.currentUser()) {
        getCache = service.find(opts.user)
          .then(function(user) {
            return user._tmp_cache || {};
          });
      } else {
        getCache = Profile.userCache({ cached: true });
      }

      return getCache
        .then(function(cache) {
          var profile = cache.profile || {};
          var history = profile.history;

          if (opts.byType) {
            history = _.filter(history, function(item) {
              return item.type === opts.byType;
            });
          }

          // group by period, type, item id, item value, dates
          var today = Utils.now({ dateOnly: true });
          var result = {};
          _.forEach(history, function(item) {
            var startDate = item.start;
            var endDate = item.end;

            var period;
            if (startDate <= today && endDate >= today) {
              period = 'current';
            } else if (endDate < today) {
              period = 'previous';
            } else if (today < startDate) {
              period = 'future';
            }

            result[period] = result[period] || {};
            result[period][item.id] = result[period][item.id] || {};
            var itemId = result[period][item.id];

            // Ignore empty relations
            if (_.isEmpty(item.value)) {
              return;
            }

            itemId.type = item.type;
            itemId.values = itemId.values || {};

            var values = item.value;
            if (typeof item.value === 'string') {
              values = [item.value];
            }

            _.forEach(values, function(val) {
              if (_.isUndefined(itemId.values[val])) {
                itemId.values[val] = [];
              }

              itemId.values[val].push({ start: item.start, end: item.end, event: item.event });
            });
          });

          return result;
        });
    };

    service.getHistorySorted = function(options) {
      return $q.all([service.getHistory(options), Relations.findAll({ sortedByOrder: true })])
        .then(function(results) {
          var history = results[0];
          var orderedRelationsIds = _.map(results[1], function(relation) {
            return relation.doc._id;
          });

          var result = {};
          _.forOwn(history, function(data, period) {
            var rels = [];
            _.forOwn(data, function(relationValue, relationId) {
              if (options.includes) {
                if (
                  (
                    relationValue.type === 'relation' &&
                    (
                      _.indexOf(options.includes.relations, '__all__') === -1 &&
                      _.indexOf(options.includes.relations, relationId) === -1
                    )
                  ) ||
                  (
                    relationValue.type === 'user_dependent' &&
                    (
                      _.indexOf(options.includes.roles, '__all__') === -1 &&
                      _.indexOf(options.includes.roles, relationId) === -1
                    )
                  )
                ) {
                  return;
                }
              }

              var relation = {
                id: relationId,
                type: relationValue.type,
                categories: []
              };

              _.forOwn(relationValue.values || {}, function(events, categoryId) {
                var sorted = _(events).sortBy('start');
                if (period !== 'future') {
                  sorted = sorted.reverse();
                }
                sorted = sorted.value();

                var first = _.head(sorted) || {};
                relation.categories.push({
                  id: categoryId,
                  values: sorted,
                  sortBy: first.start
                });
              });

              var sorted = _(relation.categories).sortBy('sortBy');
              if (period !== 'future') {
                sorted = sorted.reverse();
              }
              sorted = sorted.value();
              relation.categories = sorted;

              rels.push(relation);
            });

            result[period] = [];
            _.forEach(orderedRelationsIds, function(relationId) {
              // add relation in the order of the relations list
              var relation = _.find(rels, { id: relationId });
              if (relation) {
                result[period].push(relation);
              }
            });

            // don't forget the relations that are not in the relations list
            _.forEach(rels, function(relation) {
              if (!_.find(result[period], { id: relation.id })) {
                result[period].push(relation);
              }
            });
          });
          return result;
        });
    };

    service.isUnique = function(field, value, profile) {
      return Api.post('unique', { field: field, value: value, profile: profile })
        .then(function(data) {
          if (!data.unique) {
            return $q.reject();
          }
        });
    };

    // Bulk Change
    var source = [];
    service.saveSource = function(users) {
      source = users;
    };

    service.getSource = function() {
      return source;
    };

    service.bulkSave = function(data) {
      return Api.post('usersbulk', data)
        .then(function(result) {
          console.log(result);
          var task = {};
          task.original = angular.copy(data);
          task.result = result;

          task.id = result.task_id;
          task.state = result.task_state;
          task.startDate = Utils.now();
          task.actorName = Auth.currentUser();

          service.storeTask(task);
        });
    };

    var tasksItemName = 'tasks';
    service.storeTask = function(task) {
      var tasks = kzLocalStorage.getItem(tasksItemName);
      tasks[task.id] = task;

      kzLocalStorage.setItem(tasksItemName, tasks);
    };

    service.getTasks = function() {
      var tasks = kzLocalStorage.getItem(tasksItemName);
      return tasks;
    };

    service.clearTasks = function() {
      kzLocalStorage.setItem(tasksItemName, {});
    };

    service.countByRole = function(roleId) {
      return Api.get('users_count_by_role', {}, { role_id: roleId });
    };

    return service;
  }

  UsersService.$inject = ['$q', 'md5', 'RestApi', 'ApiService', 'kzLocalStorage', 'UtilsService',
    'ProfileService', 'QueueService', 'AuthService', 'RelationsService'];

  angular.module('component.users')
    .factory('UsersService', UsersService);
})();
