(function() {
  'use strict';

  function BulkActionController(
    $scope,
    $state,
    AsyncTasks,
    Form,
    Notify,
    Utils,
    BULK_ACTIONS_DEFINITIONS_BY_ID
  ) {
    var ctrl = this;

    $scope.readFile = function(event) {
      var file = event.target.files[0];
      if (_.isUndefined(file)) {
        return;
      }

      if (_.indexOf(['text/csv', 'application/vnd.ms-excel'], file.type) === -1) {
        ctrl.fileError = 'File with type ' + file.type + ' is not allowed to be uploaded, only ' +
          'csv is.';
      }

      // no more than 10 Mo
      if (file.size > 10 * 1000 * 1000) {
        ctrl.fileError = 'The file should not exceed 10Mb';
      }

      if (ctrl.fileError) {
        return;
      }

      var reader = new FileReader();

      reader.readAsText(file);
      reader.onload = function() {
        ctrl.bulkChangesParams.csv = {
          name: file.name,
          type: file.type,
          size: file.size,
          lastModifiedDate: file.lastModifiedDate,
          content: btoa(unescape(encodeURIComponent(reader.result))),
          columns: reader.result.split(/\r?\n/)[0].split(',')
        };
      };
      reader.onerror = function() {
        console.log(reader.error);
      };
    };

    this.$onChanges = function(changes) {
      if (changes.actionName) {
        ctrl.bulkChangesParams = {
          taskAction: changes.actionName.currentValue,
          dryrun: false
        };
      }

      ctrl.bulkActionConstants = BULK_ACTIONS_DEFINITIONS_BY_ID[ctrl.actionName];
      ctrl.forms = [];
      _.forEach(ctrl.bulkActionConstants.formElements, function(element) {
        if (element.type === 'formly-form') {
          ctrl.forms.push(new Form(element.fields));
        }
      });

      ctrl.loaded = true;
    };

    this.downloadTemplate = function() {
      var actionTemplate = ctrl.bulkActionConstants.CSVTemplate;
      Utils.downloadCSV(actionTemplate.headers, actionTemplate.name);
    };

    this.startBulkAction = function(bulkChangesParams) {
      AsyncTasks.start(ctrl.actionName, bulkChangesParams)
        .then(function(output) {
          Notify.success(ctrl.bulkActionConstants.successNotification, 'Success!');
          var taskId = output.id;
          $state.go('epf.importer.view', { id: taskId });
        });
    };
  }

  BulkActionController.$inject = [
    '$scope',
    '$state',
    'AsyncTasksService',
    'FormsService',
    'NotifyService',
    'UtilsService',
    'BULK_ACTIONS_DEFINITIONS_BY_ID'
  ];

  angular.module('component.importer')
    .component('bulkAction', {
      templateUrl: 'app/components/importer/components/bulkAction.html',
      controller: BulkActionController,
      bindings: { bulkChangesParams: '<', actionName: '<' }
    });
})();
