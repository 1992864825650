(function() {
  'use strict';

  function LocalStoreService(
    $q,
    $log,
    $rootScope,
    Database,
    Network,
    Utils
  ) {
    var service = {};

    service._keep = {};
    service._errors = {};
    var locErrors = [];
    var pushing;

    service._get = function(id) {
      return service.idbStore()
        .then(function(db) {
          return db.get(id);
        })
        .then(function(item) {
          service._keep[item._id] = item;
          return item;
        })
        .catch(function(err) {
          if (err && _.indexOf([404, 501], err.status) > -1) {
            return;
          }

          return $q.reject(err);
        });
    };

    service.get = function(id) {
      return service._get(id);
        // .then(function(item) {
        //   if (item === undefined) {
        //     return;
        //   }

        //   return item;
        // });
    };

    /**
     * Put item in a local store
     *
     * This handles own hash for comparing data - e.g. if a different tab
     * pushes changes to couchdb it changes both revisions but it's still
     * effectively the same
     *
     * @param  {[type]} doc [description]
     * @return {[type]}     [description]
     */
    service.put = function(doc) {
      var item = service._keep[doc._id];

      doc.kzPrevHash = doc.kzHash;
      doc.kzHash = Utils.hashDoc(doc);

      if (item === undefined) {
        item = {
          _id: doc._id,
          doc: doc,
          source: 'idb'
        };
      } else {
        item.doc = doc;
        item.pushed = false;
      }

      item.modifiedDate = Utils.now();

      var db;
      return service.idbStore()
        .then(function(ldb) {
          db = ldb;
          return db.put(item);
        })
        .catch(function(err) {
          // If this is a conflict, allow if it is no longer present in db
          if (err && err.status === 409) {
            return db.get(item._id)
              .then(function(data) {
                if (data.doc.kzHash === item.doc.kzPrevHash) {
                  item._rev = data._rev;
                  item.doc._rev = data.doc._rev;
                  return db.put(item);
                }

                // If the item is present but with different hash we persist the error
                // as this likely mean it really has been modified elsewhere
                return $q.reject(err);
              })
              .catch(function(err) {
                // If not there but we expect something we can try and save it
                // but it's likely the doc._rev change too but we don't know it
                if (err && err.status === 404) {
                  delete item._rev;
                  return db.put(item);
                }

                return $q.reject(err);
              });
          }
          return $q.reject(err);
        })
        .then(function(res) {
          item._rev = res.rev;
          $rootScope.$broadcast('KZLocalStoreUpdated');
          return doc;
        })
        .catch(function(err) {
          $log.warn('LocalStore: Failed saving', doc._id, item._rev, err);
          return $q.reject(err);
        });
    };

    service.safeRemove = function(doc, options) {
      options = options || {};
      return $q.all([service.idbStore(), this._get(doc._id)])
        .then(function(result) {
          var db = result[0];
          var item = result[1];
          if (item === undefined) {
            return;
          }

          if (options.pushed) {
            item.pushed = true;
            item.pushedDate = Utils.now();
            item.doc._rev = doc.doc._rev;
          } else {
            item._deleted = true;
          }
          return db.put(item);
        })
        .then(function(result) {
          if (result !== undefined) {
            delete service._keep[result.id];
            delete service._errors[result.id];
          }
          $rootScope.$broadcast('KZLocalStoreUpdated');
        })
        .catch(function(err) {
          service._errors[doc._id] = err;
        });
    };

    service.verify = function(doc) {
      var locItem = service._keep[doc._id];
      if (locItem === undefined) {
        return $q.when(true);
      }

      return this._get(doc._id)
        .then(function(item) {
          if (item === undefined) {
            return $q.when(true);
          }
          return item._rev === locItem._rev;
        })
        .then(function(verified) {
          if (!verified) {
            return $q.reject({ status: 409, message: 'Document update conflict' });
          }
        });
    };

    service.findAll = function(type) {
      return service.idbStore()
        .then(function(db) {
          return db.allDocs({ include_docs: true });
        })
        .then(function(data) {
          var items = _.chain(data.rows)
            .map(function(item) {
              return item.doc;
            })
            .filter(function(item) {
              if (type !== undefined) {
                return item.doc.type === type;
              }

              return true;
            })
            .value();

          _.forEach(items, function(item) {
            service._keep[item._id] = item;
          });

          $rootScope.$broadcast('KZLocalStoreUpdated');
          return items;
        })
        .catch(function(err) {
          if (err && err.status === 501) {
            return [];
          }
          if (err && err.status === 500) {
            return [];
          }
          return $q.reject(err);
        });
    };

    service.findToPush = function(type) {
      return service.findAll(type)
        .then(function(data) {
          return _.filter(data, function(item) {
            return !item.pushed;
          });
        });
    };

    function compare(lc, rm) {
      return Utils.hashDoc(lc) === Utils.hashDoc(rm);
    }

    service.forcePush = function(item) {
      var mdb = Database.get('master', undefined, { raw: true });
      return service.pushDoc(mdb, item, { force: true });
    };

    service.pushDoc = function(mdb, item, options) {
      options = options || {};
      // If there already is a conflict we do not need to try
      // it's not going to disappear on its own
      if (!options.force) {
        var err = service._errors[item.doc._id];
        if (err && err.status === 409) {
          return $q.reject(err);
        }
      }

      return mdb.put(item.doc)
        .catch(function(err) {
          if (err && err.status === 409) {
            return mdb.get(item.doc._id)
              .then(function(rm) {
                // If the item is queue, always ignore conflict
                // as browser can only create new ones
                if (item.doc.type === 'queue') {
                  item.doc = rm;
                  return { _id: rm._id, rev: rm._rev };
                }

                var rmRev = rm._rev;
                if (options.force) {
                  item.doc._rev = rmRev;
                  return mdb.put(item.doc);
                }
                // If the original hash is valid (e.i. not changed)
                // and equals previous hash we can override
                // as it means it is the version we were editing
                if (rm.kzHash === Utils.hashDoc(rm) && rm.kzHash === item.doc.kzPrevHash) {
                  item.doc._rev = rmRev;
                  return mdb.put(item.doc);
                }

                if (!compare(item.doc, rm)) {
                  return $q.reject(err);
                }

                item.doc._rev = rmRev;
                return mdb.put(item.doc);
              });
          }

          return $q.reject(err);
        })
        .then(function(res) {
          item.doc._rev = res.rev;
          var doc = angular.copy(item.doc);

          $rootScope.$broadcast('DBPullChange', { doc: doc });
          return service.safeRemove(item, { pushed: true });
        })
        .catch(function(err) {
          $log.warn('LocalStore: Failed bulk saving saving', item._id, err);
          return $q.reject(err);
        });
    };

    service.pushList = function(data, queueList) {
      queueList = queueList || [];
      var mdb = Database.get('master', undefined, { raw: true });
      var promises = [];
      _.forEach(data, function(item) {
        promises.push(service.pushDoc(mdb, item.doc)
          .then(function() {
            delete service._errors[item.doc._id];
            var queue = _.find(queueList, function(qitm) {
              return qitm.doc.doc.related === item.doc._id;
            });
            if (queue !== undefined) {
              return service.pushDoc(mdb, queue.doc);
            }
          })
          .catch(function(err) {
            if (err && err.status) {
              err.warn = [403, 409].indexOf(err.status) !== -1;
            }

            service._errors[item.doc._id] = err;
            locErrors.push(err);
            // return $q.reject(err);
          })
        );
      });

      return $q.all(promises);
    };

    service.pushQueue = function() {
      return service.idbStore()
        .then(function(db) {
          return db.allDocs({ include_docs: true });
        })
        .then(function(data) {
          // Separate queue from the others
          var rest = _.filter(data.rows, function(item) {
            return item.doc.doc.type !== 'queue' && !item.doc.pushed;
          });
          var restIds = _.map(rest, function(item) {
            return item.doc.doc._id;
          });

          var queue = _.filter(data.rows, function(item) {
            return item.doc.doc.type === 'queue' && !item.doc.pushed
              && restIds.indexOf(item.doc.doc.related) === -1;
          });
          queue = _.sortBy(queue, 'doc.modifiedDate');

          return service.pushList(queue);
        });
    };

    /**
     * Push all local documents to the server
     *
     * The process should be as follow:
     *   1. Get all local docs
     *   2. Attempt to submit non-queue docs
     *   3. If successful submit related queue object
     *   4. When main list is processed, attempt to submit queue that are not
     *      related to any local objects (such as event retracting etc)

     * @return {Promise} No actual output
     */
    service.pushAll = function() {
      if (pushing) {
        return $q.when();
      }

      pushing = true;
      locErrors = [];
      return Network.forceNetworkOnline()
        .then(function() {
          return service.idbStore();
        })
        .then(function(db) {
          return db.allDocs({ include_docs: true });
        })
        .then(function(data) {
          // Separate queue from the others
          var queue = _.filter(data.rows, function(item) {
            return item.doc.doc.type === 'queue' && !item.doc.pushed;
          });
          var rest = _.filter(data.rows, function(item) {
            return item.doc.doc.type !== 'queue' && !item.doc.pushed;
          });

          // Sort by modified date so that we process older one first
          rest = _.sortBy(rest, 'doc.modifiedDate');
          queue = _.sortBy(queue, 'doc.modifiedDate');

          return service.pushList(rest, queue);
        })
        .then(function() {
          return service.pushQueue();
        })
        .then(function() {
          if (locErrors.length > 0) {
            return $q.reject(locErrors[0]);
          }
        })
        .catch(function(err) {
          // Don't fail if it is offline
          if (err && err.status === 509) {
            return;
          }
          if (err && err.status === 401) {
            $rootScope.needRelogin = true;
          }

          return $q.reject(err);
        })
        .finally(function() {
          pushing = false;
        });
    };

    service.count = function(options) {
      options = options || {};
      if (options.all) {
        return service.idbStore({ organisation: options.organisation })
          .then(function(db) {
            return db.info();
          })
          .then(function(info) {
            return info.doc_count;
          });
      }

      return service.idbStore({ organisation: options.organisation })
        .then(function(db) {
          return db.allDocs({ include_docs: true });
        })
        .then(function(data) {
          return _.filter(data.rows, function(item) {
            return !item.doc.pushed;
          }).length;
        });
    };

    service.idbStore = function(kzOpts) {
      return Database.getLocalDb('idbStore', undefined, kzOpts)
        .then(function(idbStore) {
          if (_.isUndefined(idbStore)) {
            return $q.reject({ status: 500, message: 'No idb store support' });
          }
          return idbStore;
        });
    };

    service.getMeta = function(id) {
      var mdb = Database.get('master');
      var data = {};
      return service.get(id)
        .then(function(item) {
          data.item = item;
          data.error = service._errors[id];
          return mdb.get(id);
        })
        .then(function(item) {
          data.couchdb = item;
          return data;
        })
        .catch(function(err) {
          data.error = err;
          return data;
        });
    };

    service.removeData = function() {
      return Database.destroyLocalDb('idbStore');
    };

    return service;
  }

  LocalStoreService.$inject = [
    '$q',
    '$log',
    '$rootScope',
    'DatabaseService',
    'NetworkService',
    'UtilsService'
  ];

  angular.module('blocks.stores')
    .service('LocalStoreService', LocalStoreService);
})();
