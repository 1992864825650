(function() {
  'use strict';

  function GoalsController($scope, $rootScope, Auth, GoalsStore, Utils, Profile, resolvedFilter) {
    var ctrl = this;

    var listName = resolvedFilter ? 'goals-' + resolvedFilter.id : 'goals';

    ctrl.searchModel = Profile.getListPreferences(listName).current;
    ctrl.options = { search: ctrl.searchModel };
    ctrl.resolvedFilter = resolvedFilter;

    var defaultFilter = (resolvedFilter || {}).filter || {};
    defaultFilter.user = Auth.currentUser();

    Utils.setPageTitle('Goals');

    GoalsStore.getList(defaultFilter, ctrl.searchModel)
      .then(function(list) {
        ctrl.list = list;
        ctrl.loaded = true;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: ctrl.list.search });
      })
      .catch(function(error) {
        Utils.showError(error);
      });

    // get all current period and show only needed goals + targets.

    ctrl.listOptions = {
      noItemsText: 'There have been no goals created yet.'
    };

    $scope.$on('GoalPeriodSelected', function(_evt, periodFilter) {
      console.log('Some period selected');
      $scope.$broadcast('GoalPeriodUpdated', periodFilter);
    });
  }

  GoalsController.$inject = ['$scope', '$rootScope', 'AuthService', 'GoalsStore',
    'UtilsService', 'ProfileService', 'resolvedFilter'];

  angular.module('component.goals')
    .controller('GoalsController', GoalsController);
})();
