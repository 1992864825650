(function() {
  'use strict';

  function GoalSlotController($scope, GOALS_STATES) {
    var ctrl = this;
    ctrl.GOALS_STATES = GOALS_STATES;


    ctrl.showTargets = $scope.options && $scope.options.showTargets;
    $scope.goal.getViewLink()
      .then(function(viewLink) {
        ctrl.viewLink = viewLink;
      });
    ctrl.state = $scope.goal.getState();

    // function setPeriod(period) {
    //   $scope.goal.setPeriod(period);
    //   ctrl.targets = $scope.goal.targets;
    // }

    function loadTargets(selectedPeriod) {
      console.log('GoalSlot: Fetching targets for', selectedPeriod);
      ctrl.targets = $scope.goal.getTargetsForPeriod(selectedPeriod);
      ctrl.totals = $scope.goal.getProgress(selectedPeriod);
      ctrl.progresses = {};
      ctrl.completedTargets = 0;
      _.forEach(ctrl.targets, function(target) {
        var progress = target.calculateProgress(selectedPeriod);
        ctrl.progresses[target.id] = progress;
        if (progress.ratio >= 1) {
          ctrl.completedTargets += 1;
        }
      });
    }

    function loadWatch() {
      $scope.$on('GoalPeriodUpdated', function(_evt, periodFilter) {
        var splitted = periodFilter.split('|');
        if (splitted[0] === $scope.goal.doc.eventId) {
          var selectedPeriod = splitted[1];
          loadTargets(selectedPeriod);
        }
      });
    }

    // if (!$scope.goal.loaded) {
    //   $scope.goal.initialise({ loadEvent: true })
    //     .then(function(goal) {
    //       // $scope.goal = goal;
    //       loadWatch();
    //       loadTargets();
    //     });
    // } else {
    //   loadWatch();
    //   setPeriod();
    // }
    loadTargets($scope.goal.getCurrentPeriodId());
    loadWatch();
  }

  GoalSlotController.$inject = ['$scope', 'GOALS_STATES'];

  function GoalSlotDirective() {
    return {
      scope: {
        goal: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/goal.slot.html',
      replace: true,
      controller: GoalSlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('goalSlot', GoalSlotDirective)
    .controller('GoalSlotController', GoalSlotController);
})();
