(function() {
  'use strict';

  function ErrorController($log, $window, $state, $stateParams, Auth, Setup) {
    var ctrl = this;
    if ($stateParams.error === null) {
      $state.go('dashboard.index');
      return;
    }

    ctrl.show = true;
    ctrl.error = $stateParams.error;
    ctrl.toState = $stateParams.toState;
    ctrl.toParams = $stateParams.toParams;

    $log.error($stateParams.error);
    ctrl.setOnline = function() {
      var settings = Auth.getSettings();
      settings.preferredOffline = false;
      Auth.setSettings(settings);
      $window.location.reload();
    };

    ctrl.retry = function() {
      var options;
      if (ctrl.error && ctrl.error.name === 'web_sql_went_bad') {
        options = {};
      } else {
        options = { onlyCache: true };
      }
      Setup.cleanUpData(options)
        .finally(function() {
          $window.location.reload();
        });
    };

    ctrl.logout = function() {
      return Setup.logout();
    };
  }

  ErrorController.$inject = ['$log', '$window', '$state', '$stateParams', 'AuthService',
    'SetupService'];

  angular.module('epf.core')
    .controller('ErrorController', ErrorController);
})();
