(function() {
  'use strict';

  function TagsController($scope, Blueprints, Form, Utils) {
    var ctrl = this;
    ctrl.forms = [];
    ctrl.tags = [];

    /* Get blueprints with name, key and categories */
    Blueprints.findAllDiscrete()
      .then(function(data) {
        if (data.total_rows > 0) {
          _.assign(ctrl.tags, data.rows.map(function(doc) {
            return {
              _id: doc.key,
              key: doc.key,
              name: doc.value
            };
          }));
        }
      })
      .then(function() {
        ctrl.tags = _.sortBy(ctrl.tags, 'name');
      });

    function buildForm() {
      if (!$scope.doc || !$scope.doc.tags) {
        return;
      }

      ctrl.forms = [];
      $scope.doc.tags.forEach(function(tag) {
        var form = new Form();
        form.addField({
          id: 'tag',
          type: 'discrete',
          label: 'Tag',
          required: true,
          options: ctrl.tags
        });

        ctrl.forms.push({ form: form, tag: tag });
      });
    }

    buildForm();

    $scope.$watch(function() {
      if ($scope.doc.tags) {
        return $scope.doc.tags.length;
      }
    }, function() {
      buildForm();
    });

    /* Handle event tags */
    ctrl.addEventTypeTag = function() {
      var tag = { _id: Utils.guid(), tag: '' };
      $scope.doc.tags = $scope.doc.tags || [];
      $scope.doc.tags.push(tag);
    };

    ctrl.removeEventTypeTag = function(id) {
      _.remove($scope.doc.tags, function(tag) { return tag._id === id; });
    };
  }

  TagsController.$inject = ['$scope', 'BlueprintsService', 'FormsService', 'UtilsService'];

  function TagsDirective() {
    return {
      scope: {
        klass: '@',
        doc: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/event-types/partials/event-type-tag.html',
      replace: true,
      controller: TagsController,
      controllerAs: 'ctrl'
    };
  }

  function EventTypeTitleDirective(EventTypes) {
    return {
      scope: {
        eventType: '=',
        appendVersionInformation: '=?'
      },
      restrict: 'EA',
      template: '<i class="icon-spin animate-spin" ng-if="!title"></i>{{ title }}',
      link: function(scope) {
        var appendVersionInformation = _.isUndefined(scope.appendVersionInformation)
          ? true
          : scope.appendVersionInformation;

        function loadTitle() {
          if (!scope.eventType) {
            scope.title = 'Quick draft';
            return;
          }

          EventTypes.fetchIds([scope.eventType], { transformType: 'stub' })
            .then(function(data) {
              if (data.length !== 1) {
                return;
              }
              scope.title = data[0].name;
              if (appendVersionInformation) {
                scope.title += ' version ' + data[0].version;
              }
            });
        }

        scope.$watch(
          function() {
            return (scope.eventType);
          },
          function() {
            loadTitle();
          }
        );
      }
    };
  }

  EventTypeTitleDirective.$inject = ['EventTypesService'];

  function EventTypeGroupTitleDirective($state, EventTypesStore) {
    return {
      scope: {
        eventTypeGroup: '=',
        showHref: '=?'
      },
      restrict: 'EA',
      template: '<span data-ng-repeat="item in items">' +
        '<a target="_blank" ng-href="{{item.href}}" href="" ng-if="showHref">{{item.title}}</a>' +
        '<span ng-if="!showHref">{{item.title}}</span>' +
        '<span ng-if="!$last">, </span>' +
        '</span>',
      link: function(scope) {
        function loadTitle() {
          // is this really working?
          if (!scope.eventTypeGroup) {
            scope.title = 'Quick draft';
            return;
          }
          var gids = scope.eventTypeGroup;
          if (!_.isArray(gids)) {
            gids = [gids];
          }

          if (_.indexOf(gids, '__all__') > -1) {
            scope.items = [{ title: 'Any' }];
            return;
          }

          var data = { versionGroupId: gids, purpose: 'latestPublished' },
              options = { transformType: 'stub' };
          return EventTypesStore.fetch(data, options)
            .then(function(results) {
              scope.items = _.map(results, function(item) {
                return {
                  title: item.name,
                  href: $state.href('epf.event-types.edit', { id: item.id })
                };
              });
            })
            .catch(function(error) {
              console.log(error);
            });
        }

        scope.$watch(
          function() {
            return (scope.eventTypeGroup);
          },
          function() {
            loadTitle();
          }
        );
      }
    };
  }

  EventTypeGroupTitleDirective.$inject = ['$state', 'EventTypesStore'];

  function EventTypeUsageVersionedDirective() {
    return {
      scope: {
        eventTypes: '='
      },
      restrict: 'EA',
      template: '<a ng-if="versions" ui-sref="epf.event-types.edit({ id: versions[0].id })">' +
                  '{{ versions[0].name }} version {{ versions[0].version }}' +
                '</a>' +
                '<span ng-if="versions" data-ng-repeat="version in versions">' +
                '<a ng-if="!$first" ui-sref="epf.event-types.edit({ id: version.id })">' +
                  '{{ version.version }}' +
                '</a>' +
                '<span ng-if="!$last">, </span>' +
                '</span>',
      link: function(scope) {
        scope.versions = scope.eventTypes;
      }
    };
  }

  EventTypeUsageVersionedDirective.$inject = [];

  angular.module('component.eventTypes')
    .directive('tagFields', TagsDirective)
    .controller('TagsController', TagsController)
    .directive('kzEventTypeTitle', EventTypeTitleDirective)
    .directive('kzEventTypeUsage', EventTypeUsageVersionedDirective)
    .directive('kzEventTypeGroupTitle', EventTypeGroupTitleDirective);
})();
