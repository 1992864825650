(function() {
  'use strict';

  function UserFactory(
    $q,
    $state,
    $rootScope,
    $uibModal,
    Users,
    UsersStub,
    Auth,
    OidcService,
    Notify,
    Security,
    SweetAlert,
    USER_STATES,
    SERVER_CONFIG
  ) {
    var User = function(doc, options) {
      this.doc = doc;
      this.options = options || {};
    };

    User.prototype.reload = function() {
      var _this = this;
      return Users.find(this.doc.user)
        .then(function(doc) {
          _this.doc = doc;
          $rootScope.$broadcast('KZUserReloaded', { doc: doc });
        });
    };

    User.getAllOptions = function(forUser, search) {
      var options = { sort_on: 'firstname', isForEventCreation: true };
      if (search) {
        options.autocomplete_fullname = search;
      }

      return Users.findAll(options)
        .then(function(users) {
          return _.chain(users.hits)
            .filter(function(user) {
              return user.username !== forUser;
            })
            .map(function(user) {
              return {
                _id: user.username,
                key: user.username,
                name: _.trim(
                  (user.firstName || '') + ' ' +
                  (user.lastName || '') + ' - ' +
                  (user.email || '')
                ) || user.username
              };
            })
            .value();
        });
    };

    User.getFullName = function(username) {
      return UsersStub.find(username)
        .then(function(user) {
          var fullname = _.trim((user.firstName || '') + ' ' + (user.lastName || ''));
          if (!fullname) {
            fullname = user.username;
          }

          return fullname;
        })
        .catch(function(error) {
          console.log(error);
          return ' ';
        });
    };

    User.getHistory = function(options) {
      var opts = options || {};
      return Users.getHistory(opts);
    };

    User.getHistorySorted = function(options) {
      var opts = options || {};
      return Users.getHistorySorted(opts);
    };

    User.getRelations = function(options) {
      // get already assigned relation's categories grouped by relation id
      var opts = options || {};
      opts.byType = 'relation';

      return Users.getHistory(opts);
    };

    User.getUserDependents = function(options) {
      var opts = options || {};
      opts.byType = 'user_dependent';

      return Users.getHistory(opts);
    };

    User.partiallySaveProfile = function(partToSave, formFields, profileDoc) {
      var data = { _id: profileDoc._id, _rev: profileDoc._rev };
      _.forEach(formFields, function(field) {
        data[field.id] = profileDoc[field.id];
      });
      return Users.updatePartiallyProfile(partToSave, data);
    };

    User.prototype.isActive = function() {
      return this.doc.state === 'active';
    };

    User.prototype.isDisabled = function() {
      return this.doc.state === 'disabled';
    };

    User.prototype.actionButtons = function() {
      var _this = this;
      return [
        {
          label: 'Summary',
          icon: 'icon-userFields',
          href: (function() {
            return $state.href('dashboard.summary', { user: _this.doc.username });
          })(),
          klass: 'text-info'
        },
        {
          label: 'Timeline',
          icon: 'icon-list-numbered',
          href: (function() {
            return $state.href('epf.users.events', { user: _this.doc.username });
          })(),
          showCondition: this.isActive.bind(this),
          klass: 'text-info'
        },
        {
          label: 'Goals',
          icon: 'icon-goal',
          href: (function() {
            return $state.href('epf.users.goals', { user: _this.doc.username });
          })(),
          showCondition: this.isActive.bind(this),
          klass: 'text-info'
        },
        {
          label: 'Files',
          icon: 'icon-folder',
          href: (function() {
            return $state.href('epf.users.files', { user: _this.doc.username });
          })(),
          showCondition: this.isActive.bind(this),
          klass: 'text-info'
        }
      ];
    };

    User.prototype.secondaryActionButtons = function() {
      var _this = this;
      return [
        {
          label: 'Create new',
          icon: 'icon-plus',
          href: (function() {
            return $state.href('epf.events.new', { user: _this.doc.username });
          })(),
          showCondition: this.isActive.bind(this),
          klass: 'text-info'
        }
      ];
    };

    User.prototype.profileActionButtons = function() {
      var _this = this;
      return [
        {
          label: 'Create new',
          icon: 'icon-plus',
          type: 'button',
          color: 'primary',
          href: (function() {
            var user = (Auth.currentUser() === _this.doc.username) ? '' : _this.doc.username;
            return $state.href('epf.events.new', { user: user });
          })(),
          showCondition: this.canCreateEvent.bind(_this),
          klass: 'btn-xs btn-border'
        },
        {
          label: 'Disable this user',
          icon: 'icon-toggle-off',
          type: 'button',
          color: 'danger',
          onClick: _this.updateState.bind(this, 'user_disable'),
          showCondition: function() {
            if (_this.isDisabled(_this)) {
              return false;
            }

            return _this.canReview();
          },
          klass: 'btn-xs btn-border'
        },
        {
          label: 'Enable this user',
          icon: 'icon-toggle-on',
          type: 'button',
          color: 'success',
          onClick: _this.updateState.bind(this, 'user_enable'),
          showCondition: function() {
            if (!_this.isDisabled(_this)) {
              return false;
            }

            return _this.canReview();
          },
          klass: 'btn-xs'
        },
        {
          label: 'Login as this user',
          icon: 'icon-toggle-on',
          type: 'button',
          color: 'primary',
          onClick: function() {
            OidcService.loginAs(_this.doc.user);
          },
          showCondition: function() {
            if (SERVER_CONFIG.authType !== 'oidc') {
              return $q.reject({ status: 403, message: 'This is available only with OIDC' });
            }
            return $q.all(
              [
                Security.hasPermissionFor('users.lase', _this.doc.user),
                Security.userHasRole(_this.doc.user, ['system:admin', 'system:superadmin'])
              ]
            ).then(function(results) {
              var hasUsersLasePerm = results[0],
                  hasAdminRole = results[1];
              if (!hasUsersLasePerm || hasAdminRole) {
                var msg = 'You don\'t have enough privilege to login as this person';
                return $q.reject({ status: 403, message: msg });
              }
            });
          },
          klass: 'btn-xs btn-border'
        }
      ];
    };

    User.prototype.canCreateEvent = function() {
      if (Auth.currentUser() === this.doc.user) {
        return Security.hasPermission('events.create');
      }

      return Security.hasPermissionFor('events.create', this.doc.user);
    };

    User.prototype.canReview = function() {
      if (Auth.currentUser() === this.doc.user) {
        return $q.reject({ status: 403, message: 'Current account cannot be disabled' });
      }

      return Security.hasPermissionFor('users.review', this.doc.user);
    };

    var stateMapping = {
      user_enable: 'enable',
      user_disable: 'disable',
      user_approve: 'approve',
      user_reject: 'reject'
    };

    var stateMappingPostAction = {
      user_enable: 'enabled',
      user_disable: 'disabled',
      user_approve: 'approved',
      user_reject: 'rejected'
    };

    User.prototype.updateState = function(action) {
      var def = $q.defer();
      var _this = this;
      var options = {
        title: 'Are you sure you want to ' + (stateMapping[action] || action) + ' this user?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      };

      if (action === 'reject') {
        options.type = 'input';
        options.inputPlaceholder = 'Tell us the reason of that rejection';
      }

      SweetAlert.swal(options, function(data) {
        var reason = '';
        if (action === 'reject') {
          if (data === '') {
            SweetAlert.swal.showInputError('Please give a reason.');
            return false;
          }

          reason = data;
        }

        if (data) {
          Users.updateState(_this.doc, action, Auth.currentUser(), reason)
            .then(function() {
              return _this.reload();
            })
            .then(function() {
              Notify.success(
                'The user has been ' + (stateMappingPostAction[action] || action) +
                ' successfully!',
                'Nice!'
              );
              def.resolve();
            })
            .catch(function(error) {
              def.reject(error);
            });
        }
      });

      return def.promise;
    };

    User.prototype.getBorderClass = function() {
      var state = USER_STATES[this.doc.state];
      return 'progress-border-' + (state ? state.borderStyle || 'danger' : 'danger');
    };

    User.prototype.openPreview = function(meta) {
      var _this = this;

      $uibModal.open({
        animation: true,
        size: 'lg',
        templateUrl: 'app/components/users/partials/preview.html',
        controller: ['$scope', '$uibModalInstance', 'profileDoc', 'meta', function(
          $scope, $uibModalInstance, profileDoc, meta
        ) {
          $scope.profileDoc = profileDoc;
          $scope.meta = meta;

          $scope.dismiss = function() {
            $uibModalInstance.dismiss('cancel');
          };
        }],
        resolve: {
          profileDoc: function() {
            return _this.doc;
          },
          meta: function() {
            return meta;
          }
        }
      });
    };

    return User;
  }

  UserFactory.$inject = [
    '$q',
    '$state',
    '$rootScope',
    '$uibModal',
    'UsersService',
    'UsersStubService',
    'AuthService',
    'OidcService',
    'NotifyService',
    'SecurityService',
    'SweetAlert',
    'USER_STATES',
    'SERVER_CONFIG'
  ];

  angular.module('component.users')
    .factory('UserFactory', UserFactory);
})();
