(function() {
  'use strict';

  function ReportController(
    $q,
    $scope,
    $state,
    $stateParams,
    Form,
    Notify,
    Security,
    Reports,
    Report,
    Utils,
    Auth
  ) {
    var ctrl = this;

    ctrl.action = $state.current.data.action;
    if (ctrl.action === 'edit') {
      ctrl.reportId = $stateParams.id;

      // This should not happen as 'edit' has only one route
      if (ctrl.reportId === undefined) {
        Utils.showError({ message: 'Report ID is not set' });
        return;
      }
    }

    ctrl.extra = {
      user: Auth.currentUser()
    };

    function loadReport() {
      return Reports.fetch(ctrl.reportId, 'full')
        .then(function(data) {
          var report = new Report(data);
          Utils.setPageTitle('Edit report ' + report.doc.title);
          var editPerm = report.location === 'personal' ? 'reports.edit.own' : 'reports.edit';
          return Security.hasPermission(editPerm)
            .then(function(hasEditPerm) {
              ctrl.hasEditPerm = hasEditPerm;
              return report;
            })
            .catch(function(error) {
              if (error.status === 403) {
                $state.go('epf.reportTemplates.index');
              }

              return $q.reject(error);
            });
        })
        .then(function(version) {
          ctrl.baseReport = version;
          ctrl.origDoc = angular.copy(version.doc);
          ctrl.version = version;
          ctrl.editMode = ctrl.baseReport.doc.state === 'draft' && ctrl.hasEditPerm;
          if (ctrl.baseReport.doc.enablePDF === undefined) {
            ctrl.baseReport.doc.enablePDF = true;
          }
          if (ctrl.baseReport.doc.enableCSV === undefined) {
            ctrl.baseReport.doc.enableCSV = true;
          }
        })
        .catch(Utils.showError);
    }

    function newReport() {
      ctrl.baseReport = new Report($stateParams.doc || Reports.getDefaultGroup());
      ctrl.baseReport.doc.presource = ctrl.baseReport.doc.source; // todo: should I be here?
      ctrl.baseReport.doc.enablePDF = true;
      ctrl.baseReport.doc.enableCSV = true;
      ctrl.editMode = true;
      Utils.setPageTitle('New report');
      return $q.when();
    }

    function setup() {
      if (ctrl.action === 'edit') {
        return loadReport();
      }

      ctrl.hasEditPerm = true;
      return newReport();
    }

    ctrl.show = false;
    setup()
      .then(function() {
        var disableAll = !ctrl.editMode;
        ctrl.baseForm = new Form(Report.getBaseFormFields(disableAll));
        ctrl.report = ctrl.baseReport.getSingleOrMultiReportByDoc();
        ctrl.loaded = true;
      });

    ctrl.switchToMultiReport = function() {
      if (ctrl.report.type !== 'singleReport') {
        return;
      }

      Utils.swal({
        title: 'This will convert this single report into a multi report template. Would you ' +
          'like to continue?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          ctrl.baseReport.switchToMultiReport(ctrl.report)
            .then(function(multiReport) {
              ctrl.report = multiReport;
              $scope.form.$setPristine();
            });
        }
      });
    };

    function saveAndRedirect(stateParams, options) {
      options = options || {};
      if (ctrl.baseReport.doc.state !== 'draft' && ctrl.hasEditPerm) {
        return Utils.showError({ message: 'You cannot perform that action' });
      }

      if (_.isEmpty(ctrl.baseReport.doc.title)) {
        Utils.swal(
          'Please fill in the mandatory report template name.',
          '',
          'warning'
        );
        return;
      }

      var def;
      if (!options.force && ctrl.origDoc && _.isEqual(ctrl.origDoc, ctrl.baseReport.doc)) {
        def = $q.when();
      } else {
        def = Reports.save(ctrl.baseReport.doc, true)
          .then(function(data) {
            ctrl.baseReport.doc._rev = data.rev;
            $scope.form.$setPristine();
            Notify.success('The report has been saved', 'Success');
          });
      }

      return def
        .then(function() {
          if (!_.isUndefined(stateParams) && !_.isEmpty(stateParams)) {
            $state.go(stateParams.url, stateParams.args);
          }
        })
        .catch(Utils.showError);
    }

    ctrl.save = function() {
      var isNew = !ctrl.baseReport.doc._rev,
          stateParams = {};
      if (isNew) {
        stateParams.url = 'epf.reportTemplates.edit';
        stateParams.args = { id: ctrl.baseReport.doc._id };
      }
      saveAndRedirect(stateParams, { force: true });
    };

    ctrl.defaultModel = {};
    ctrl.reportData = {};
    ctrl.generate = function() {
      ctrl.showResult = false;
      $scope.$broadcast('ReportChanged');
      ctrl.report.getDefaultModel()
        .then(function(defaultModel) {
          ctrl.model = _.assignIn(defaultModel, ctrl.reportData);
          ctrl.hasBeenModified = false;
          ctrl.generated = false;
          ctrl.showResult = true;
          $scope.$broadcast('RegenerateReport');
        });
    };

    ctrl.loadAuditlog = function() {
      Reports.getAuditlog($stateParams.id)
        .then(function(data) {
          ctrl.auditlog = data;
        });
    };

    $scope.$watch(function() {
      return ctrl.baseReport && ctrl.baseReport.doc;
    }, function(val) {
      if (val) {
        ctrl.hasBeenModified = ctrl.showResult;
      }
    }, true);

    $scope.$on('SaveAndEditMultiReportElement', function(_event, args) {
      var stateParams = {
        url: 'epf.reportTemplates.editMultiReportElement',
        args: { reportId: args.reportId, elementId: args.elementId }
      };

      saveAndRedirect(stateParams);
    });
  }

  ReportController.$inject = [
    '$q',
    '$scope',
    '$state',
    '$stateParams',
    'FormsService',
    'NotifyService',
    'SecurityService',
    'ReportTemplatesService',
    'ReportFactory',
    'UtilsService',
    'AuthService'
  ];

  angular.module('component.reports')
    .controller('ReportTemplateController', ReportController);
})();
