(function() {
  'use strict';

  function LogOutController(
    Setup
  ) {
    Setup.logout();
  }

  LogOutController.$inject = [
    'SetupService'
  ];

  angular.module('component.accounts')
    .controller('LogOutController', LogOutController);
})();
