(function() {
  'use strict';

  function ListButtons($q, $window, Utils) {
    return {
      restrict: 'AE',
      scope: {
        actions: '=',
        secondaryActions: '=',
        featuredActions: '=',
        item: '=?',
        version: '=?',
        options: '=?',
        leftSlotSize: '@?',
        useContextualMenu: '=?'
      },
      templateUrl: function(_elem, attrs) {
        return attrs.dots ? 'app/blocks/list/dotbuttons.html' : 'app/blocks/list/listbuttons.html';
      },
      link: function(scope) {
        /**
         * useContextualMenu will determine if the page buttons will
         * behave differently on a mobile device or not.
         * i.e. with a true value, a mobile-sized screen width will show a plus
         * button and a popup when it is clicked. The popup will display
         * the list of actionButtons passed in via actions.
         * with a false value or undefined, all screen widths will simply show the list of
         * actionButtons.
         * Here we are setting the default to be true, when useContextualMenu is undefined.
         * @type {boolean}
         */
        scope.useContextualMenu = _.isUndefined(scope.useContextualMenu)
          ? true
          : scope.useContextualMenu;

        scope.leftSize = scope.leftSlotSize || 6;
        scope.actionButtons = [];
        scope.secondaryActionButtons = [];
        scope.featuredActionButtons = [];
        scope.containerId = _.uniqueId('list-buttons-');

        function loadButtons(buttons, name) {
          scope[name] = [];

          return $q.when(buttons)
            .then(function(buttons) {
              return Utils.filterButtons(buttons);
            })
            .then(function(data) {
              scope[name] = data;
              scope.loaded = true;
            });
        }

        scope.$watch('actions', function(value) {
          if (_.isUndefined(value)) {
            return;
          }

          if (_.isFunction(value)) {
            value = value(scope.item);
          }

          loadButtons(value, 'actionButtons');
        });

        scope.$watch('secondaryActions', function(value) {
          if (_.isUndefined(value)) {
            return;
          }

          if (_.isFunction(value)) {
            value = value(scope.item);
          }

          loadButtons(value, 'secondaryActionButtons');
        }, true);

        scope.$watch('featuredActions', function(value) {
          if (_.isUndefined(value)) {
            return;
          }

          if (_.isFunction(value)) {
            value = value(scope.item);
          }

          loadButtons(value, 'featuredActionButtons');
        }, true);

        scope.$on('kzReloadActions', function() {
          loadButtons(scope.actions, 'actionButtons');
          loadButtons(scope.secondaryActions, 'secondaryActionButtons');
          loadButtons(scope.featuredActions, 'featuredActionButtons');
        });

        scope.doAction = function(action) {
          if (scope.modal) {
            scope.modal.dismiss('cancel');
          }

          action.onClick();
        };

        var shouldShowButtons = function(width) {
          return width > 768;
        };

        // If the window resizes, check whether to make the buttons visible anyway
        var w = angular.element($window);
        scope.shouldShowButtons = shouldShowButtons($window.innerWidth);
        w.bind('resize', function() {
          scope.shouldShowButtons = shouldShowButtons($window.innerWidth);
        });

        // Destroy
        scope.$on('$destory', function() {
          w.off('resize');
        });
      }
    };
  }

  ListButtons.$inject = ['$q', '$window', 'UtilsService'];

  angular.module('blocks.list')
    .directive('kzListButtons', ListButtons);
})();
