(function() {
  'use strict';

  function GoalsStore(
    $q,
    Goals,
    GoalLightFactory,
    Goal,
    GoalSearch,
    List,
    APIIDList,
    Events,
    EventsStore,
    Network
  ) {
    var service = {};

    var makeDocs = function(item) {
      return new GoalLightFactory(item);
    };

    service.searchIds = function(data) {
      return Goals.searchIds(data);
    };

    service.searchOwnIds = function(data) {
      return Goals.searchOwnIds(data);
    };

    service.fetchIds = function(ids) {
      return Goals.fetchIds(ids);
      // return Goals.findKeys(ids)
      //   .then(function(data) {
      //     return $q.all(_.map(data, function(goal) {
      //       return service.transform(goal);
      //     }));
      //   });
      // return Api.post('goals_ids', { ids: ids })
      //   .then(function(data) {
      //     return data.hits;
      //   });
    };

    service.search = function(data, options) {
      options = options || {};
      var prom = options.own ? service.searchOwnIds(data) : service.searchIds(data);
      return prom.then(function(ids) {
        return _.map(ids.hits, 'id');
      });
    };

    service.fetch = function(data, options) {
      return service.search(data, options)
        .then(function(ids) {
          return service.fetchIds(ids);
        });
    };

    service.fetchLight = function(data, options) {
      return service.fetch(data, options)
        .then(function(data) {
          return _.map(data, function(item) {
            return new GoalLightFactory(item);
          });
        });
    };

    service.getList = function(defaultFilter, searchModel, options) {
      if (Network.isOffline()) {
        return service.getOfflineList(defaultFilter, searchModel, options);
      }

      return service.getOnlineList(defaultFilter, searchModel, options);
    };

    service.getOnlineList = function(defaultFilter, searchModel, _options) {
      var list = new APIIDList({
        search: GoalSearch.dbsearch(defaultFilter),
        model: searchModel,
        idField: 'doc._id',
        defaultFilter: defaultFilter,
        findOptions: { limit: 20 },
        makeDocs: makeDocs,
        searchFn: service.searchOwnIds.bind(service),
        fetchFn: service.fetchIds.bind(service)
      });
      list.doLoadItems();
      return $q.when(list);
    };

    service.getOfflineList = function(defaultFilter, searchModel, _options) {
      var list = new List({
        search: GoalSearch.dbsearch(defaultFilter),
        model: searchModel,
        idField: 'doc._id',
        defaultFilter: defaultFilter,
        findOptions: { limit: 20 }
      });

      return Goal.findAll({ cached: false })
        .then(function(goals) {
          // get the goalset resolved
          return Goal.getGoalsByEventIdAndFieldId(goals)
            .then(function(goalsByEventIdAndFieldId) {
              return _.map(goals, function(goal) {
                if (goal.doc.eventId && goal.doc.eventFieldId) {
                  var data = goalsByEventIdAndFieldId[goal.doc.eventId][goal.doc.eventFieldId];
                  goal.isTheSetIAmInResolved = data.isTheSetIAmInResolved;
                  goal.lastGoalId = data.lastGoalId;
                }

                return goal;
              });
            });
        })
        .then(function(goals) {
          return $q.all(_.map(goals, function(goal) {
            return service.transform(goal)
              .then(function(gl) {
                return new GoalLightFactory(gl);
              });
          }));
        })
        .then(function(goals) {
          list.doLoadItems(goals);
          return list;
        });
    };

    service.getSimpleList = function(goals) {
      var opts = {
        idField: 'doc._id',
        search: GoalSearch.dbsearch(undefined, { simpleSearch: true }),
        pagination: 'links',
        findOptions: { limit: 10 }
      };

      // opts = _.assign(opts, listOpt || {});
      var list = new List(opts);

      return list.doLoadItems(goals)
        .then(function() {
          var goToPage = function(page) {
            list.goToPage(+page);
          };
          return { list: list, goToPageFunc: goToPage };
        });
    };

    service.transform = function(goal) {
      var transformed = {
        type: 'light',
        doc: goal.doc
      };
      return Events.find(goal.doc.eventId)
        .then(function(event) {
          return EventsStore.transform({ doc: event });
        })
        .then(function(event) {
          // FIXME - this should be a transformed event
          if (event) {
            transformed.event = event;
            transformed.eventType = event.eventType;
            transformed.eventType_name = event.eventType.name;
          }
          return transformed;
        })
        .catch(function(error) {
          console.log(error);
          return transformed;
        });
    };

    return service;
  }

  GoalsStore.$inject = [
    '$q',
    'GoalsService',
    'GoalLightFactory',
    'GoalFactory',
    'GoalSearch',
    'ListFactory',
    'APIIDListFactory',
    'EventsService',
    'EventsStore',
    'NetworkService'
  ];

  angular.module('component.goals')
    .factory('GoalsStore', GoalsStore);
})();
