(function() {
  'use strict';

  function Slot(Events) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/events/cards/eventfillin.card.html',
      link: function(scope) {
        scope.event = Events.currentEvent;

        scope.$watch(function() {
          return Events.currentEvent;
        }, function(event) {
          scope.event = event;
        });

        // scope.$on('KZCurrentEventChange', function(evt, args) {
        //   scope.event = args.event;
        // });
      }
    };
  }

  Slot.$inject = ['EventsService'];

  angular.module('component.events')
    .directive('sidebarEventFillIn', Slot);
})();
