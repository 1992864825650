(function() {
  'use strict';

  function kzGroupTitleDirective($templateRequest, $compile) {
    function link(scope, element) {
      if (!scope.groupBy) {
        return;
      }

      function getValue(item) {
        if (_.isEmpty(item)) {
          return '';
        }

        return scope.groupBy.getter(item);
      }

      function setup() {
        var prevValue = getValue(scope.prevItem);
        var value = getValue(scope.item);

        if (value !== prevValue) {
          scope.value = value;
          if (_.isUndefined(value)) {
            scope.title = scope.groupBy.notitle;
          } else {
            scope.title = scope.groupBy.title;
          }

          if (scope.templateUrl) {
            // Load the html through $templateRequest
            $templateRequest(scope.templateUrl).then(function(html) {
              // Convert the html to an actual DOM node
              var template = angular.element(html);
              // Append it to the directive element
              element.append(template);
              // And let Angular $compile it
              $compile(template)(scope);
            });
          }
        }
      }

      setup();
    }

    return {
      scope: {
        item: '=groupItem',
        groupBy: '=',
        prevItem: '=',
        nextItem: '=',
        templateUrl: '@'
      },
      restrict: 'AE',
      replace: true,
      templateUrl: 'app/components/events/directives/kz-group-title.html',
      link: link
    };
  }

  kzGroupTitleDirective.$inject = ['$templateRequest', '$compile'];

  angular.module('events.directives')
    .directive('kzGroupTitle', kzGroupTitleDirective);
})();
