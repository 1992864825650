(function() {
  'use strict';

  var RAW_KEYS = ['display', 'sources', 'filterForm', 'output'];

  function SQLReportBuilderComponent($scope, Security, Form, Notify) {
    var ctrl = this;
    ctrl.$onInit = function() {
      ctrl.setup();
    };

    ctrl.rawModel = {};

    ctrl.setup = function() {
      console.log('Setting up');
      ctrl.model = ctrl.report.conf;
      var opts = { disableAll: !ctrl.editMode };
      ctrl.basicForm = new Form(ctrl.report.getSingleReportBasicFormFields(opts));
      ctrl.loadDisplays();
      Security.hasRole('system:superadmin', { promise: true })
        .then(function() {
          ctrl.canEditRaw = true;
          ctrl.rawForm = new Form([
            {
              id: 'raw',
              type: 'text',
              label: 'Raw JSON'
            }
          ]);
          ctrl.rawModel = {
            raw: JSON.stringify(_.pick(ctrl.model, RAW_KEYS), null, 2)
          };
          $scope.$watch(function() {
            return ctrl.rawModel;
          }, function(data) {
            console.log('parsing');
            var parsed;
            try {
              parsed = JSON.parse(data.raw);
              _.forEach(RAW_KEYS, function(key) {
                var val = parsed[key];
                if (val) {
                  ctrl.report.conf[key] = val;
                }
              });
            } catch (e) {
              console.log(e);
              Notify.error('Invalid json');
            }
          }, true);
        })
        .catch(function() {
          ctrl.canEditRaw = false;
        });
    };

    ctrl.loadDisplays = function() {
      var display = ctrl.report.getDisplay();
      ctrl.display = _.map(display, function(dis) {
        var dp = angular.copy(dis);
        if (dp.type === 'table') {
          dp.source = _.find(ctrl.report.conf.sources, { id: dis.output });
        }
        return dp;
      });
    };
  }

  SQLReportBuilderComponent.$inject = [
    '$scope',
    'SecurityService',
    'FormsService',
    'NotifyService'
  ];
  angular.module('component.reports')
    .component('sqlReportBuilder', {
      templateUrl: 'app/components/reports/directives/sql-report-builder.html',
      controller: SQLReportBuilderComponent,
      bindings: {
        report: '<',
        editMode: '<'
      }
    });
})();
