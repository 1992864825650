(function() {
  'use strict';

  function RestEasApi(EasApi) {
    var service = function(endpoint, options) {
      this.rest_endpoint = endpoint;
      this.options = options || {};
    };

    service.prototype.getOptions = function(options) {
      var newOptions = {};
      _.assign(newOptions, this.options, options || {});
      return newOptions;
    };

    service.prototype.listUrl = function() {
      return this.rest_endpoint + '/';
    };

    service.prototype.specificUrl = function(oid) {
      return this.rest_endpoint + '/' + oid;
    };

    service.prototype.findAll = function(data, urlOptions, options) {
      var url = this.listUrl();
      var opts = this.getOptions(options);
      return EasApi.get(url, data, urlOptions, opts);
    };

    service.prototype.save = function(data) {
      var url = this.specificUrl(data._id);
      return EasApi.put(url, data, {}, this.getOptions());
    };

    service.prototype.create = function(data) {
      var url = this.listUrl();
      return EasApi.post(url, data, {}, this.getOptions());
    };

    service.prototype.find = function(oid, urlParams, options) {
      var url = this.specificUrl(oid);
      var opts = this.getOptions(options);
      return EasApi.get(url, undefined, urlParams, opts);
    };

    service.prototype.remove = function(oid) {
      var url = this.specificUrl(oid);
      return EasApi.delete(url, {}, {}, this.getOptions());
    };

    service.prototype.searchIds = function(data, options) {
      var url = this.listUrl() + 'search';
      var opts = this.getOptions(options);
      return EasApi.post(url, data, undefined, opts)
        .then(function(data) {
          // Lets make it compatible
          return {
            total: data.total,
            size: data.size,
            start: data.start,
            hits: _.map(data.ids, function(itm) {
              return { id: itm };
            })
          };
        });
    };

    service.prototype.fetchIds = function(ids, options) {
      var url = this.listUrl() + 'fetch';
      var opts = this.getOptions(options);
      return EasApi.post(url, { ids: ids }, undefined, opts)
        .then(function(data) {
          return _.map(data.docs, function(itm) {
            return {
              doc: itm
            };
          });
        });
    };


    return service;
  }

  RestEasApi.$inject = ['EasApiService'];

  angular.module('blocks.easapi')
    .factory('RestEasApi', RestEasApi);
})();
