(function() {
  'use strict';

  function envDirective($log, env) {
    return {
      template: '<div data-ng-if="show" class="fixed-badge">' +
      '<span class="{{ klass[env] }}">{{ text }}</span>' +
      '</div>',
      replace: true,
      link: function($scope) {
        $log.info('Running in', env, 'environment');
        $scope.show = env !== 'production';
        $scope.env = env;
        $scope.klass = {
          test: 'text-warning',
          dev: 'text-success'
        };
        $scope.text = 'This is a ' + env + ' server';

        // if (['local'].indexOf(env) !== -1) {
        //   // eslint-disable-next-line
        //   var intercom = function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/w859fqqz';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}};
        //   intercom();
        // }
      }
    };
  }

  envDirective.$inject = ['$log', 'KZ_ENVIRONMENT'];

  angular.module('blocks.utils')
    .directive('kzEnv', envDirective);
})();
