(function() {
  'use strict';

  function BasicFormController($rootScope, Form) {
    var ctrl = this;

    ctrl.form = new Form();

    ctrl.form.addField({
      id: 'title',
      type: 'string',
      label: 'Template title',
      required: true,
      onChange: function($viewValue) {
        $rootScope.$broadcast('KZTemplate:FormChanged', { title: $viewValue });
      }
    });
  }

  function BasicFormDirective() {
    return {
      scope: {
        template: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/dashboard-templates/directives/form.html',
      controller: BasicFormController,
      controllerAs: 'ctrl'
    };
  }

  BasicFormController.$inject = [
    '$rootScope',
    'FormsService'
  ];

  angular.module('component.dashboardTemplates')
    .directive('dashboardTemplateBasicForm', BasicFormDirective);
})();
