(function() {
  'use strict';

  function EventTypePreviewController($q, $stateParams, EventType, EventTypes, Utils) {
    var ctrl = this;

    ctrl.id = $stateParams.id;

    if ($stateParams.id) {
      ctrl.displayOptions = {
        showAllBlueprints: true
      };
      EventTypes.getForEdit($stateParams.id)
      .then(function(data) {
        try {
          ctrl.eventType = new EventType(data);
          ctrl.version = ctrl.eventType;
          Utils.setPageTitle(
            'Preview event type ' +
            ctrl.eventType.doc.name +
            ' version '
            + ctrl.eventType.doc.version
          );
          ctrl.loaded = true;
        } catch (err) {
          return $q.reject({ status: 500, message: err });
        }
      });
    } else {
      ctrl.loaded = true;
    }
  }

  EventTypePreviewController.$inject = [
    '$q',
    '$stateParams',
    'EventTypeFactory',
    'EventTypesService',
    'UtilsService'
  ];

  angular.module('component.eventTypes')
    .controller('EventTypePreviewController', EventTypePreviewController);
})();
