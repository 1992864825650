(function() {
  'use strict';

  function APIIDListFactory($q, $timeout, $rootScope, $log, Notify, DB_SETTINGS) {
    var APIList = function(options) {
      options = options || {};
      options.pagination = options.pagination || 'infinite';
      this.searchModel = options.model || {};
      this.appliedFilters = {};
      this.options = options;
      this.startkey = 0;
      this.idSize = 50;

      this.service = options.service;
      this.searchFn = options.searchFn;
      this.fetchFn = options.fetchFn;

      if (this.searchFn === undefined) {
        if (this.service && this.service.searchIds) {
          this.searchFn = this.service.searchIds.bind(this.service);
        } else {
          throw new Error('Please provide at either searchFn or a service with searchIds function');
        }
      }
      if (this.fetchFn === undefined) {
        if (this.service && this.service.fetchIds) {
          this.fetchFn = this.service.fetchIds.bind(this.service);
        } else {
          throw new Error('Please provide at either searchFn or a service with fetchIds function');
        }
      }
      this.defaultFilter = _.assign(
        {},
        _.isUndefined(options.search) ? {} : options.search.defaultFilter,
        options.defaultFilter || {}
      );

      // makeDocs is a simple wrapper for function that will be called lazily
      // on loaded item. This is so that we can tweak objects coming from ES
      this.makeDocs = options.makeDocs || function(item) {
        return item;
      };

      this.findOptions = options.findOptions || { limit: DB_SETTINGS.pagination.limit };
      this.search = options.search || {};
      if (_.isUndefined(this.search.filteredBy)) {
        this.search.filteredBy = {};
      }

      if (options.maxSize) {
        this.idSize = Math.min(this.idSize, options.maxSize);
        this.findOptions.limit = Math.min(this.findOptions.limit, options.maxSize);
      }

      // For infinite scroll
      this.items = [];
      this.isLoading = false;
      this.finished = false;

      this.itemsIds = [];

      // Request counter so we can always use only the last one
      // This appears if the trigger to requests at the same time using filters
      this.requests = 0;
      this.ready = true;
    };

    APIList.prototype.setSearch = function(searchModel) {
      this.searchModel = searchModel || {};
      if (_.isUndefined(this.searchModel.filteredBy)) {
        this.searchModel.filteredBy = {};
      }
    };

    // Resets the start key
    APIList.prototype.resetFilters = function() {
      this._setStartKey();
      $rootScope.$broadcast('KZListResetted');
      return this;
    };

    /*
      External methods to be used in controllers
     */

    APIList.prototype.doPaginate = function(options) {
      var _this = this;
      options = options || this.findOptions;
      _this.isLoading = true;

      return this._paginate(options)
        .then(function(data) {
          if (_this.requests > 1) {
            _this.requests -= 1;
            return [];
          }

          var hits = data.map(_this.makeDocs);

          // if (!_.isUndefined(data.aggs)) {
          //   _this.aggs = data.aggs;
          // }
          //
          _this.items = _this.options.pagination === 'infinite' ? _.union(_this.items, hits) : hits;
          var limit = options.limit || data.total;
          _this._setStartKey(_this.startkey + limit); // set the new startkey

          _this.isLoading = false;
          if (hits.length === 0 || (_this.items.length === data.total)) {
            _this.finished = true;
          }

          if (_this.options.maxSize && _this.items.length > _this.options.maxSize) {
            _this.items = _this.items.slice(0, _this.options.maxSize);
            _this.finished = true;
          }

          _this.requests -= 1;
          _this.hideList = false;
          return _this.items;
        })
        .catch(function(error) {
          _this.requests -= 1;
          _this.isLoading = false;
          _this.finished = true;
          _this.hideList = false;
          return $q.reject(error);
        });
    };

    APIList.prototype._prefilter = function(filteredBy) {
      var filters = this.search.filters;
      var promises = [];
      var result = {};
      _.forOwn(filteredBy, function(value, key) {
        var filter = _.find(filters, function(item) {
          return item.id === key;
        });

        if (filter === undefined || filter.preFilter === undefined) {
          result[key] = value;
          return;
        }

        promises.push($q.when(filter.preFilter(value)));
      });

      return $q.all(promises)
        .then(function(data) {
          _.forEach(data, function(obj) {
            _.assignIn(result, obj);
          });
          return result;
        });
    };

    APIList.prototype._paginate = function(options) {
      // Load next page according to startkey
      var _this = this;
      var def;

      var paginated = [];
      if (_.isObject(options) && options.limit && this.itemsIds.length) {
        $log.warn('APILIST: Getting slice with ', _this.startkey);
        var end = _this.startkey + options.limit;
        if (_this.itemsIds.length < this.total_count && _this.itemsIds.length < end) {
          _this.idSize *= 10;
          def = this.loadIds()
            .then(function() {
              paginated = _this.itemsIds.slice(_this.startkey, _this.startkey + options.limit);
              return paginated;
            });
        } else {
          def = $q.when(_this.itemsIds.slice(_this.startkey, _this.startkey + options.limit));
        }
      } else {
        def = $q.when(_this.itemsIds);
      }

      return def
        .then(function(paginated) {
          _this.requests += 1;
          return _this.fetchFn(_.map(paginated, 'id'));
        });
    };

    APIList.prototype.doLoadItems = function() {
      this.resetFilters();
      this.items = [];
      this.itemsIds = [];
      this._setStartKey(0);

      // Load next page according to startkey
      var _this = this;

      _this.isLoading = true;
      _this.finished = false;
      _this.hideList = true;

      return this.loadIds()
        .then(function() {
          return _this.doPaginate();
        })
        .catch(function(error) {
          Notify.error('Could not load data');
          return $q.reject(error);
        });
    };

    APIList.prototype.loadIds = function(idSize) {
      // Load next page according to startkey
      var data = {},
          _this = this;

      if (idSize === undefined) {
        idSize = this.idSize;
      }

      var findOptions = _this.findOptions;

      data.sort_on = _this.searchModel.orderBy || _this.search.defaultOrder;
      data.includeAggs = false;

      var filteredBy = _.assign(
        {},
        this.defaultFilter,
        findOptions.filteredBy || this.searchModel.filteredBy
      );

      return this._prefilter(filteredBy)
        .then(function(filteredBy) {
          _.assignIn(
            data,
            filteredBy,
            { size: idSize }
          );
          return _this.searchFn(data)
            .then(function(data) {
              _this.itemsIds = data.hits;
              _this.total_count = data.total;
              _this.found_count = data.total;
              return data;
            })
            .catch(function(error) {
              // Notify.error('Could not load data');
              return $q.reject(error);
            });
        });
    };

    APIList.prototype.reloadItem = function(args) {
      var _this = this;
      // Playground
      return this.searchFn(args.data)
        .then(function(data) {
          if (data.hits.length === 1) {
            return _this.fetchFn([data.hits[0].id])
              .then(function(res) {
                var idx = _.findIndex(_this.items, { id: args.id });
                if (res.length === 1) {
                  var obj = _this.makeDocs(res[0]);
                  if (idx !== -1) {
                    _this.items[idx] = obj;
                  }
                } else if (idx !== -1) {
                  _this.items.splice(idx, 1);
                }
              });
          }
          // Removing as it was not found
          var idx = _.findIndex(_this.items, { id: args.id });
          if (idx !== -1) {
            _this.items.splice(idx, 1);
          }
        });
    };

    APIList.prototype.getTotalCount = function() {
      var _this = this;

      if (!_.isUndefined(this.total_count)) {
        return $q.when(this.total_count);
      }

      return this._paginate({ limit: 0 })
        .then(function(data) {
          _this.total_count = data.total;
          _this.found_count = data.total;
          return data.total;
        });
    };

    // APIList.prototype.getAggregates = function(options) {
    //   options = angular.copy(options) || {};
    //   options.size = 0;
    //   options.start = 0;
    //   options.includeAggs = true;

    //   var _this = this;

    //   return Api.post(_this.options.endpoint, options)
    //     .then(function(data) {
    //       _this.aggs = data.aggs;
    //       _this.aggs.total = data.total;
    //       return _this.aggs;
    //     });
    // };

    APIList.prototype.doSearch = function() {
      var _this = this;
      var def = $q.defer();
      this.resetFilters();
      this.items = [];
      this.itemsIds = [];
      this._setStartKey(0);
      this.showFilters = true;
      this.isLoading = true;
      this.hideList = true;
      $timeout(function() {
        return _this.doLoadItems()
          .then(function() {
            def.resolve();
          })
          .catch(function(error) {
            def.reject(error);
          });
      });
      return def.promise;
    };

    // set the startkey
    APIList.prototype._setStartKey = function(startkey) {
      $log.warn('APILIST: Setting start key to', startkey);
      this.startkey = startkey || 0;
    };

    APIList.prototype.orderedBy = function() {
      return this.searchModel.orderBy || this.search.defaultOrder;
    };

    /**
     * Given a page number, we set the new startkey to be that page number's
     * first item. This means simply multiplying the page number with the
     * limit per page.
     * @param  {number} page  The page number to go to.
     * @return {Promise}      The same promise returned by calling doPaginate().
     */
    APIList.prototype.goToPage = function(page) {
      page = page || 0;
      this._setStartKey(page * this.findOptions.limit);
      return this.doPaginate();
    };

    return APIList;
  }

  APIIDListFactory.$inject = [
    '$q',
    '$timeout',
    '$rootScope',
    '$log',
    'NotifyService',
    'DB_SETTINGS'
  ];

  angular.module('blocks.api')
    .factory('APIIDListFactory', APIIDListFactory);
})();
