(function() {
  'use strict';

  function GoalWrapperController(
    $scope,
    $rootScope,
    $state,
    EventSection,
    EventSections,
    Notify,
    GOALS_STATES,
    LocalizationService
  ) {
    var ctrl = this;

    ctrl.GOALS_STATES = GOALS_STATES;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    ctrl.overdueAndNotClosed = $scope.goal.goalSet &&
                               $scope.goal.isOverdue() &&
                               !$scope.goal.goalSet.isClosed;

    if ($scope.goal.event) {
      _.forEach($scope.goal.event.doc.sections, function(section, sectionId) {
        if (sectionId !== $scope.goal.doc.sectionId) {
          _.forEach(section.items, function(item) {
            EventSections.find(item.eventSectionId)
              .then(function(eventSection) {
                ctrl.eventSection = new EventSection(
                  eventSection,
                  { eventType: $scope.goal.event.eventType }
                );
              });
          });
        }
      });
    }

    ctrl.getBorderClass = function() {
      ctrl.borderClass = $scope.goal.getBorderClass ?
        $scope.goal.getBorderClass() : 'progress-border-complete';
    };

    function loadButtons() {
      ctrl.actionButtons = [
        {
          label: 'Add more goals',
          ariaLabel: 'Add more goals to ' + $scope.goal.doc.title,
          icon: 'icon-plus',
          klass: 'text-success',
          href: (function() {
            var route = 'epf.goals.add';
            var args = {
              eventId: $scope.goal.doc.eventId,
              sectionId: $scope.goal.doc.eventSectionId,
              fieldId: $scope.goal.doc.eventFieldId
            };

            if (!$scope.goal.isMine()) {
              route = 'epf.users.goals-add';
              args.user = $scope.goal.doc.user;
            }

            return $state.href(route, args);
          })(),
          showCondition: function() {
            return $scope.goal.checkPermission('canAdd');
          }
        },
        {
          label: 'Close this goal set',
          ariaLabel: 'Close the ' + $scope.goal.doc.title + ' goal set',
          icon: 'icon-check',
          klass: 'text-success',
          onClick: function() {
            return $scope.goal.getFull()
              .then(function(goal) {
                return goal.closeEventSection();
              })
              .then(function() {
                $rootScope.$broadcast('KZApplySearch');
                // remove this when doAction is more general
                // var route = 'epf.goals.index';
                // var args = {};

                // if (!$scope.goal.isMine()) {
                //   route = 'epf.users.goals';
                //   args.user = $scope.goal.doc.user;
                // }
                // $state.go(route, args, { reload: true });
              });
          },
          showCondition: function() {
            return $scope.goal.checkPermission('canClose');
          }
        },
        {
          label: 'Re-open goal set',
          ariaLabel: 'Reopen the ' + $scope.goal.doc.title + ' goal set',
          icon: 'icon-check',
          klass: 'text-success',
          onClick: function() {
            return $scope.goal.event.getFull()
              .then(function(event) {
                return event.reOpen();
              })
              .then(function() {
                $rootScope.$broadcast('KZApplySearch');
                // var route = 'epf.goals.index';
                // var args = {};

                // if (!$scope.goal.isMine()) {
                //   route = 'epf.users.goals';
                //   args.user = $scope.goal.doc.user;
                // }
                // $state.go(route, args, { reload: true });
              });
          },
          showCondition: function() {
            return $scope.goal.checkPermission('canReOpen');
          }
        }
      ];

      ctrl.secondaryActionButtons = [
        {
          label: 'Change due date',
          ariaLabel: 'Change due date for ' + $scope.goal.doc.title,
          icon: 'icon-calendar',
          klass: 'text-success',
          onClick: function() {
            return $scope.goal.getFull()
              .then(function(goal) {
                return goal.openUpdateDueDateModal();
              })
              .then(function() {
                Notify.success('The due date has been updated successfully!');
                $rootScope.$broadcast('KZApplySearch');
              })
              // .then(function() {
              //   // remove this when doAction is more general
              //   var route = 'epf.goals.index';
              //   var args = {};

              //   if (!$scope.goal.isMine()) {
              //     route = 'epf.users.goals';
              //     args.user = $scope.goal.doc.user;
              //   }
              //   $state.go(route, args, { reload: true });
              // })
              .catch(function(err) {
                if (err === 'cancel' || err === 'escape key press') {
                  return;
                }

                var msg = 'Something wrong just happened while updating ' +
                  'the due date: ' + err.message;
                Notify.error(msg);
              });
          },
          showCondition: function() {
            if (_.isEmpty($scope.goal.event) || $scope.goal.isSetClosed()) {
              return false;
            }

            return $scope.goal.checkPermission('canUpdateDueDate');
          }
        },
        {
          label: 'Change periods',
          ariaLabel: 'Change periods for ' + $scope.goal.doc.title,
          icon: 'icon-history',
          klass: 'text-success',
          onClick: function() {
            return $scope.goal.getFull()
              .then(function(goal) {
                return goal.openUpdatePeriodsModal();
              })
              .then(function() {
                Notify.success('The due date has been updated successfully!');
              })
              .catch(function(err) {
                var msg = 'Something wrong just happened while updating ' +
                  'the periods: ' + err.message;
                Notify.error(msg);
              });
          },
          showCondition: function() {
            var goal = $scope.goal;
            if (_.isEmpty(goal.event) || goal.isSetClosed()) {
              return false;
            }

            return $scope.goal.checkPermission('canUpdatePeriods');
          }
        }
      ];
    }

    function getTooltipHtml() {
      function searchLogsByAction(actionName, sectionId) {
        if (!$scope.goal.event) {
          return [];
        }
        return _.filter($scope.goal.event.doc.dates, function(date) {
          if (date.sectionId) {
            return date.action === actionName && date.sectionId === sectionId;
          }

          return date.action === actionName;
        });
      }

      var autoCloseDatesFound = searchLogsByAction(
        'section_autoclosegoal',
        $scope.goal.doc.eventSectionId
      );
      var hasBeenAlreadyAutoClosed = autoCloseDatesFound.length > 0;

      var toolTipHTML = '';

      if (!$scope.goal.goalSet) {
        toolTipHTML = 'There is no goal set attached';
      } else if ($scope.goal.goalSet.isClosed) {
        var actionName = 'section_closegoal';
        if ($scope.goal.goalSet.autoCloseSettings) {
          actionName = 'section_autoclosegoal';
        }

        var datesFound = searchLogsByAction(actionName, $scope.goal.doc.eventSectionId);
        if (datesFound.length > 0) {
          var closedDate = datesFound[datesFound.length - 1];
          toolTipHTML = 'This goal set has been ';
          if ($scope.goal.goalSet.autoCloseSettings) {
            toolTipHTML += 'auto-closed at ' + closedDate.date;
          } else {
            toolTipHTML += 'closed at ' + closedDate.date + ' by ' + closedDate.actor;
          }
        }
      } else if ($scope.goal.goalSet.autoCloseSettings) {
        if (hasBeenAlreadyAutoClosed) {
          _.remove($scope.goal.goalSet.autoCloseConditions, function(condition) {
            return condition === 'allAchievementsMet';
          });
        }

        if ($scope.goal.goalSet.autoCloseConditions.length > 0) {
          toolTipHTML += 'This goal set will automatically close based upon ' +
                              'the following criteria:<ul>';
          var _map = {
            overdue: 'The goal set is overdue',
            allAchievementsMet: 'All goals within the goal set is achieved'
          };
          _.forEach($scope.goal.goalSet.autoCloseConditions, function(condition) {
            if (!_.isUndefined(_map[condition])) {
              toolTipHTML += '<li>' + _map[condition] + '</li>';
            }
          });

          toolTipHTML += '</ul>';
        } else {
          toolTipHTML = 'This goal set has been re-opened and must be manually ' +
                             'closed when appropriate';
        }
      } else if ($scope.goal.goalSet.markStep === 'mandatory') {
        toolTipHTML = 'This goal set requires each goal to be manually marked ' +
                           'before it can be closed';
      } else {
        toolTipHTML = 'This goal set can be manually closed when appropriate';
      }

      return toolTipHTML;
    }

    ctrl.tooltipHtml = getTooltipHtml();

    loadButtons();
    ctrl.getBorderClass();
    ctrl.loaded = true;
  }

  GoalWrapperController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'EventSectionFactory',
    'EventSectionsService',
    'NotifyService',
    'GOALS_STATES',
    'LocalizationService'
  ];

  function GoalWrapperDirective() {
    return {
      scope: {
        goal: '=',
        templateType: '@',
        options: '='
      },
      restrict: 'AE',
      templateUrl: function(_elem, attr) {
        return attr.template ? attr.template : 'app/components/goals/directives/goal.wrapper.html';
      },
      replace: true,
      controller: GoalWrapperController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('goalWrapper', GoalWrapperDirective)
    .controller('GoalWrapperController', GoalWrapperController);
})();
