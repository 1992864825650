/* eslint-disable */
/* jshint ignore: start */

// PouchDB websql plugin 7.0.0
// Since PouchDB 7.0.0, shipped as a separate plugin.
// 
// (c) 2012-2018 Dale Harvey and the PouchDB team
// PouchDB may be freely distributed under the Apache license, version 2.0.
// For all details and documentation:
// http://pouchdb.com
(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(_dereq_,module,exports){
    'use strict';
    
    module.exports = argsArray;
    
    function argsArray(fun) {
      return function () {
        var len = arguments.length;
        if (len) {
          var args = [];
          var i = -1;
          while (++i < len) {
            args[i] = arguments[i];
          }
          return fun.call(this, args);
        } else {
          return fun.call(this, []);
        }
      };
    }
    },{}],2:[function(_dereq_,module,exports){
    // Copyright Joyent, Inc. and other Node contributors.
    //
    // Permission is hereby granted, free of charge, to any person obtaining a
    // copy of this software and associated documentation files (the
    // "Software"), to deal in the Software without restriction, including
    // without limitation the rights to use, copy, modify, merge, publish,
    // distribute, sublicense, and/or sell copies of the Software, and to permit
    // persons to whom the Software is furnished to do so, subject to the
    // following conditions:
    //
    // The above copyright notice and this permission notice shall be included
    // in all copies or substantial portions of the Software.
    //
    // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
    // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
    // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
    // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
    // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
    // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
    // USE OR OTHER DEALINGS IN THE SOFTWARE.
    
    var objectCreate = Object.create || objectCreatePolyfill
    var objectKeys = Object.keys || objectKeysPolyfill
    var bind = Function.prototype.bind || functionBindPolyfill
    
    function EventEmitter() {
      if (!this._events || !Object.prototype.hasOwnProperty.call(this, '_events')) {
        this._events = objectCreate(null);
        this._eventsCount = 0;
      }
    
      this._maxListeners = this._maxListeners || undefined;
    }
    module.exports = EventEmitter;
    
    // Backwards-compat with node 0.10.x
    EventEmitter.EventEmitter = EventEmitter;
    
    EventEmitter.prototype._events = undefined;
    EventEmitter.prototype._maxListeners = undefined;
    
    // By default EventEmitters will print a warning if more than 10 listeners are
    // added to it. This is a useful default which helps finding memory leaks.
    var defaultMaxListeners = 10;
    
    var hasDefineProperty;
    try {
      var o = {};
      if (Object.defineProperty) Object.defineProperty(o, 'x', { value: 0 });
      hasDefineProperty = o.x === 0;
    } catch (err) { hasDefineProperty = false }
    if (hasDefineProperty) {
      Object.defineProperty(EventEmitter, 'defaultMaxListeners', {
        enumerable: true,
        get: function() {
          return defaultMaxListeners;
        },
        set: function(arg) {
          // check whether the input is a positive number (whose value is zero or
          // greater and not a NaN).
          if (typeof arg !== 'number' || arg < 0 || arg !== arg)
            throw new TypeError('"defaultMaxListeners" must be a positive number');
          defaultMaxListeners = arg;
        }
      });
    } else {
      EventEmitter.defaultMaxListeners = defaultMaxListeners;
    }
    
    // Obviously not all Emitters should be limited to 10. This function allows
    // that to be increased. Set to zero for unlimited.
    EventEmitter.prototype.setMaxListeners = function setMaxListeners(n) {
      if (typeof n !== 'number' || n < 0 || isNaN(n))
        throw new TypeError('"n" argument must be a positive number');
      this._maxListeners = n;
      return this;
    };
    
    function $getMaxListeners(that) {
      if (that._maxListeners === undefined)
        return EventEmitter.defaultMaxListeners;
      return that._maxListeners;
    }
    
    EventEmitter.prototype.getMaxListeners = function getMaxListeners() {
      return $getMaxListeners(this);
    };
    
    // These standalone emit* functions are used to optimize calling of event
    // handlers for fast cases because emit() itself often has a variable number of
    // arguments and can be deoptimized because of that. These functions always have
    // the same number of arguments and thus do not get deoptimized, so the code
    // inside them can execute faster.
    function emitNone(handler, isFn, self) {
      if (isFn)
        handler.call(self);
      else {
        var len = handler.length;
        var listeners = arrayClone(handler, len);
        for (var i = 0; i < len; ++i)
          listeners[i].call(self);
      }
    }
    function emitOne(handler, isFn, self, arg1) {
      if (isFn)
        handler.call(self, arg1);
      else {
        var len = handler.length;
        var listeners = arrayClone(handler, len);
        for (var i = 0; i < len; ++i)
          listeners[i].call(self, arg1);
      }
    }
    function emitTwo(handler, isFn, self, arg1, arg2) {
      if (isFn)
        handler.call(self, arg1, arg2);
      else {
        var len = handler.length;
        var listeners = arrayClone(handler, len);
        for (var i = 0; i < len; ++i)
          listeners[i].call(self, arg1, arg2);
      }
    }
    function emitThree(handler, isFn, self, arg1, arg2, arg3) {
      if (isFn)
        handler.call(self, arg1, arg2, arg3);
      else {
        var len = handler.length;
        var listeners = arrayClone(handler, len);
        for (var i = 0; i < len; ++i)
          listeners[i].call(self, arg1, arg2, arg3);
      }
    }
    
    function emitMany(handler, isFn, self, args) {
      if (isFn)
        handler.apply(self, args);
      else {
        var len = handler.length;
        var listeners = arrayClone(handler, len);
        for (var i = 0; i < len; ++i)
          listeners[i].apply(self, args);
      }
    }
    
    EventEmitter.prototype.emit = function emit(type) {
      var er, handler, len, args, i, events;
      var doError = (type === 'error');
    
      events = this._events;
      if (events)
        doError = (doError && events.error == null);
      else if (!doError)
        return false;
    
      // If there is no 'error' event listener then throw.
      if (doError) {
        if (arguments.length > 1)
          er = arguments[1];
        if (er instanceof Error) {
          throw er; // Unhandled 'error' event
        } else {
          // At least give some kind of context to the user
          var err = new Error('Unhandled "error" event. (' + er + ')');
          err.context = er;
          throw err;
        }
        return false;
      }
    
      handler = events[type];
    
      if (!handler)
        return false;
    
      var isFn = typeof handler === 'function';
      len = arguments.length;
      switch (len) {
          // fast cases
        case 1:
          emitNone(handler, isFn, this);
          break;
        case 2:
          emitOne(handler, isFn, this, arguments[1]);
          break;
        case 3:
          emitTwo(handler, isFn, this, arguments[1], arguments[2]);
          break;
        case 4:
          emitThree(handler, isFn, this, arguments[1], arguments[2], arguments[3]);
          break;
          // slower
        default:
          args = new Array(len - 1);
          for (i = 1; i < len; i++)
            args[i - 1] = arguments[i];
          emitMany(handler, isFn, this, args);
      }
    
      return true;
    };
    
    function _addListener(target, type, listener, prepend) {
      var m;
      var events;
      var existing;
    
      if (typeof listener !== 'function')
        throw new TypeError('"listener" argument must be a function');
    
      events = target._events;
      if (!events) {
        events = target._events = objectCreate(null);
        target._eventsCount = 0;
      } else {
        // To avoid recursion in the case that type === "newListener"! Before
        // adding it to the listeners, first emit "newListener".
        if (events.newListener) {
          target.emit('newListener', type,
              listener.listener ? listener.listener : listener);
    
          // Re-assign `events` because a newListener handler could have caused the
          // this._events to be assigned to a new object
          events = target._events;
        }
        existing = events[type];
      }
    
      if (!existing) {
        // Optimize the case of one listener. Don't need the extra array object.
        existing = events[type] = listener;
        ++target._eventsCount;
      } else {
        if (typeof existing === 'function') {
          // Adding the second element, need to change to array.
          existing = events[type] =
              prepend ? [listener, existing] : [existing, listener];
        } else {
          // If we've already got an array, just append.
          if (prepend) {
            existing.unshift(listener);
          } else {
            existing.push(listener);
          }
        }
    
        // Check for listener leak
        if (!existing.warned) {
          m = $getMaxListeners(target);
          if (m && m > 0 && existing.length > m) {
            existing.warned = true;
            var w = new Error('Possible EventEmitter memory leak detected. ' +
                existing.length + ' "' + String(type) + '" listeners ' +
                'added. Use emitter.setMaxListeners() to ' +
                'increase limit.');
            w.name = 'MaxListenersExceededWarning';
            w.emitter = target;
            w.type = type;
            w.count = existing.length;
            if (typeof console === 'object' && console.warn) {
              console.warn('%s: %s', w.name, w.message);
            }
          }
        }
      }
    
      return target;
    }
    
    EventEmitter.prototype.addListener = function addListener(type, listener) {
      return _addListener(this, type, listener, false);
    };
    
    EventEmitter.prototype.on = EventEmitter.prototype.addListener;
    
    EventEmitter.prototype.prependListener =
        function prependListener(type, listener) {
          return _addListener(this, type, listener, true);
        };
    
    function onceWrapper() {
      if (!this.fired) {
        this.target.removeListener(this.type, this.wrapFn);
        this.fired = true;
        switch (arguments.length) {
          case 0:
            return this.listener.call(this.target);
          case 1:
            return this.listener.call(this.target, arguments[0]);
          case 2:
            return this.listener.call(this.target, arguments[0], arguments[1]);
          case 3:
            return this.listener.call(this.target, arguments[0], arguments[1],
                arguments[2]);
          default:
            var args = new Array(arguments.length);
            for (var i = 0; i < args.length; ++i)
              args[i] = arguments[i];
            this.listener.apply(this.target, args);
        }
      }
    }
    
    function _onceWrap(target, type, listener) {
      var state = { fired: false, wrapFn: undefined, target: target, type: type, listener: listener };
      var wrapped = bind.call(onceWrapper, state);
      wrapped.listener = listener;
      state.wrapFn = wrapped;
      return wrapped;
    }
    
    EventEmitter.prototype.once = function once(type, listener) {
      if (typeof listener !== 'function')
        throw new TypeError('"listener" argument must be a function');
      this.on(type, _onceWrap(this, type, listener));
      return this;
    };
    
    EventEmitter.prototype.prependOnceListener =
        function prependOnceListener(type, listener) {
          if (typeof listener !== 'function')
            throw new TypeError('"listener" argument must be a function');
          this.prependListener(type, _onceWrap(this, type, listener));
          return this;
        };
    
    // Emits a 'removeListener' event if and only if the listener was removed.
    EventEmitter.prototype.removeListener =
        function removeListener(type, listener) {
          var list, events, position, i, originalListener;
    
          if (typeof listener !== 'function')
            throw new TypeError('"listener" argument must be a function');
    
          events = this._events;
          if (!events)
            return this;
    
          list = events[type];
          if (!list)
            return this;
    
          if (list === listener || list.listener === listener) {
            if (--this._eventsCount === 0)
              this._events = objectCreate(null);
            else {
              delete events[type];
              if (events.removeListener)
                this.emit('removeListener', type, list.listener || listener);
            }
          } else if (typeof list !== 'function') {
            position = -1;
    
            for (i = list.length - 1; i >= 0; i--) {
              if (list[i] === listener || list[i].listener === listener) {
                originalListener = list[i].listener;
                position = i;
                break;
              }
            }
    
            if (position < 0)
              return this;
    
            if (position === 0)
              list.shift();
            else
              spliceOne(list, position);
    
            if (list.length === 1)
              events[type] = list[0];
    
            if (events.removeListener)
              this.emit('removeListener', type, originalListener || listener);
          }
    
          return this;
        };
    
    EventEmitter.prototype.removeAllListeners =
        function removeAllListeners(type) {
          var listeners, events, i;
    
          events = this._events;
          if (!events)
            return this;
    
          // not listening for removeListener, no need to emit
          if (!events.removeListener) {
            if (arguments.length === 0) {
              this._events = objectCreate(null);
              this._eventsCount = 0;
            } else if (events[type]) {
              if (--this._eventsCount === 0)
                this._events = objectCreate(null);
              else
                delete events[type];
            }
            return this;
          }
    
          // emit removeListener for all listeners on all events
          if (arguments.length === 0) {
            var keys = objectKeys(events);
            var key;
            for (i = 0; i < keys.length; ++i) {
              key = keys[i];
              if (key === 'removeListener') continue;
              this.removeAllListeners(key);
            }
            this.removeAllListeners('removeListener');
            this._events = objectCreate(null);
            this._eventsCount = 0;
            return this;
          }
    
          listeners = events[type];
    
          if (typeof listeners === 'function') {
            this.removeListener(type, listeners);
          } else if (listeners) {
            // LIFO order
            for (i = listeners.length - 1; i >= 0; i--) {
              this.removeListener(type, listeners[i]);
            }
          }
    
          return this;
        };
    
    function _listeners(target, type, unwrap) {
      var events = target._events;
    
      if (!events)
        return [];
    
      var evlistener = events[type];
      if (!evlistener)
        return [];
    
      if (typeof evlistener === 'function')
        return unwrap ? [evlistener.listener || evlistener] : [evlistener];
    
      return unwrap ? unwrapListeners(evlistener) : arrayClone(evlistener, evlistener.length);
    }
    
    EventEmitter.prototype.listeners = function listeners(type) {
      return _listeners(this, type, true);
    };
    
    EventEmitter.prototype.rawListeners = function rawListeners(type) {
      return _listeners(this, type, false);
    };
    
    EventEmitter.listenerCount = function(emitter, type) {
      if (typeof emitter.listenerCount === 'function') {
        return emitter.listenerCount(type);
      } else {
        return listenerCount.call(emitter, type);
      }
    };
    
    EventEmitter.prototype.listenerCount = listenerCount;
    function listenerCount(type) {
      var events = this._events;
    
      if (events) {
        var evlistener = events[type];
    
        if (typeof evlistener === 'function') {
          return 1;
        } else if (evlistener) {
          return evlistener.length;
        }
      }
    
      return 0;
    }
    
    EventEmitter.prototype.eventNames = function eventNames() {
      return this._eventsCount > 0 ? Reflect.ownKeys(this._events) : [];
    };
    
    // About 1.5x faster than the two-arg version of Array#splice().
    function spliceOne(list, index) {
      for (var i = index, k = i + 1, n = list.length; k < n; i += 1, k += 1)
        list[i] = list[k];
      list.pop();
    }
    
    function arrayClone(arr, n) {
      var copy = new Array(n);
      for (var i = 0; i < n; ++i)
        copy[i] = arr[i];
      return copy;
    }
    
    function unwrapListeners(arr) {
      var ret = new Array(arr.length);
      for (var i = 0; i < ret.length; ++i) {
        ret[i] = arr[i].listener || arr[i];
      }
      return ret;
    }
    
    function objectCreatePolyfill(proto) {
      var F = function() {};
      F.prototype = proto;
      return new F;
    }
    function objectKeysPolyfill(obj) {
      var keys = [];
      for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k)) {
        keys.push(k);
      }
      return k;
    }
    function functionBindPolyfill(context) {
      var fn = this;
      return function () {
        return fn.apply(context, arguments);
      };
    }
    
    },{}],3:[function(_dereq_,module,exports){
    (function (global){
    'use strict';
    var Mutation = global.MutationObserver || global.WebKitMutationObserver;
    
    var scheduleDrain;
    
    {
      if (Mutation) {
        var called = 0;
        var observer = new Mutation(nextTick);
        var element = global.document.createTextNode('');
        observer.observe(element, {
          characterData: true
        });
        scheduleDrain = function () {
          element.data = (called = ++called % 2);
        };
      } else if (!global.setImmediate && typeof global.MessageChannel !== 'undefined') {
        var channel = new global.MessageChannel();
        channel.port1.onmessage = nextTick;
        scheduleDrain = function () {
          channel.port2.postMessage(0);
        };
      } else if ('document' in global && 'onreadystatechange' in global.document.createElement('script')) {
        scheduleDrain = function () {
    
          // Create a <script> element; its readystatechange event will be fired asynchronously once it is inserted
          // into the document. Do so, thus queuing up the task. Remember to clean up once it's been called.
          var scriptEl = global.document.createElement('script');
          scriptEl.onreadystatechange = function () {
            nextTick();
    
            scriptEl.onreadystatechange = null;
            scriptEl.parentNode.removeChild(scriptEl);
            scriptEl = null;
          };
          global.document.documentElement.appendChild(scriptEl);
        };
      } else {
        scheduleDrain = function () {
          setTimeout(nextTick, 0);
        };
      }
    }
    
    var draining;
    var queue = [];
    //named nextTick for less confusing stack traces
    function nextTick() {
      draining = true;
      var i, oldQueue;
      var len = queue.length;
      while (len) {
        oldQueue = queue;
        queue = [];
        i = -1;
        while (++i < len) {
          oldQueue[i]();
        }
        len = queue.length;
      }
      draining = false;
    }
    
    module.exports = immediate;
    function immediate(task) {
      if (queue.push(task) === 1 && !draining) {
        scheduleDrain();
      }
    }
    
    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
    },{}],4:[function(_dereq_,module,exports){
    if (typeof Object.create === 'function') {
      // implementation from standard node.js 'util' module
      module.exports = function inherits(ctor, superCtor) {
        ctor.super_ = superCtor
        ctor.prototype = Object.create(superCtor.prototype, {
          constructor: {
            value: ctor,
            enumerable: false,
            writable: true,
            configurable: true
          }
        });
      };
    } else {
      // old school shim for old browsers
      module.exports = function inherits(ctor, superCtor) {
        ctor.super_ = superCtor
        var TempCtor = function () {}
        TempCtor.prototype = superCtor.prototype
        ctor.prototype = new TempCtor()
        ctor.prototype.constructor = ctor
      }
    }
    
    },{}],5:[function(_dereq_,module,exports){
    (function (factory) {
        if (typeof exports === 'object') {
            // Node/CommonJS
            module.exports = factory();
        } else if (typeof define === 'function' && define.amd) {
            // AMD
            define(factory);
        } else {
            // Browser globals (with support for web workers)
            var glob;
    
            try {
                glob = window;
            } catch (e) {
                glob = self;
            }
    
            glob.SparkMD5 = factory();
        }
    }(function (undefined) {
    
        'use strict';
    
        /*
         * Fastest md5 implementation around (JKM md5).
         * Credits: Joseph Myers
         *
         * @see http://www.myersdaily.org/joseph/javascript/md5-text.html
         * @see http://jsperf.com/md5-shootout/7
         */
    
        /* this function is much faster,
          so if possible we use it. Some IEs
          are the only ones I know of that
          need the idiotic second function,
          generated by an if clause.  */
        var add32 = function (a, b) {
            return (a + b) & 0xFFFFFFFF;
        },
            hex_chr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
    
    
        function cmn(q, a, b, x, s, t) {
            a = add32(add32(a, q), add32(x, t));
            return add32((a << s) | (a >>> (32 - s)), b);
        }
    
        function md5cycle(x, k) {
            var a = x[0],
                b = x[1],
                c = x[2],
                d = x[3];
    
            a += (b & c | ~b & d) + k[0] - 680876936 | 0;
            a  = (a << 7 | a >>> 25) + b | 0;
            d += (a & b | ~a & c) + k[1] - 389564586 | 0;
            d  = (d << 12 | d >>> 20) + a | 0;
            c += (d & a | ~d & b) + k[2] + 606105819 | 0;
            c  = (c << 17 | c >>> 15) + d | 0;
            b += (c & d | ~c & a) + k[3] - 1044525330 | 0;
            b  = (b << 22 | b >>> 10) + c | 0;
            a += (b & c | ~b & d) + k[4] - 176418897 | 0;
            a  = (a << 7 | a >>> 25) + b | 0;
            d += (a & b | ~a & c) + k[5] + 1200080426 | 0;
            d  = (d << 12 | d >>> 20) + a | 0;
            c += (d & a | ~d & b) + k[6] - 1473231341 | 0;
            c  = (c << 17 | c >>> 15) + d | 0;
            b += (c & d | ~c & a) + k[7] - 45705983 | 0;
            b  = (b << 22 | b >>> 10) + c | 0;
            a += (b & c | ~b & d) + k[8] + 1770035416 | 0;
            a  = (a << 7 | a >>> 25) + b | 0;
            d += (a & b | ~a & c) + k[9] - 1958414417 | 0;
            d  = (d << 12 | d >>> 20) + a | 0;
            c += (d & a | ~d & b) + k[10] - 42063 | 0;
            c  = (c << 17 | c >>> 15) + d | 0;
            b += (c & d | ~c & a) + k[11] - 1990404162 | 0;
            b  = (b << 22 | b >>> 10) + c | 0;
            a += (b & c | ~b & d) + k[12] + 1804603682 | 0;
            a  = (a << 7 | a >>> 25) + b | 0;
            d += (a & b | ~a & c) + k[13] - 40341101 | 0;
            d  = (d << 12 | d >>> 20) + a | 0;
            c += (d & a | ~d & b) + k[14] - 1502002290 | 0;
            c  = (c << 17 | c >>> 15) + d | 0;
            b += (c & d | ~c & a) + k[15] + 1236535329 | 0;
            b  = (b << 22 | b >>> 10) + c | 0;
    
            a += (b & d | c & ~d) + k[1] - 165796510 | 0;
            a  = (a << 5 | a >>> 27) + b | 0;
            d += (a & c | b & ~c) + k[6] - 1069501632 | 0;
            d  = (d << 9 | d >>> 23) + a | 0;
            c += (d & b | a & ~b) + k[11] + 643717713 | 0;
            c  = (c << 14 | c >>> 18) + d | 0;
            b += (c & a | d & ~a) + k[0] - 373897302 | 0;
            b  = (b << 20 | b >>> 12) + c | 0;
            a += (b & d | c & ~d) + k[5] - 701558691 | 0;
            a  = (a << 5 | a >>> 27) + b | 0;
            d += (a & c | b & ~c) + k[10] + 38016083 | 0;
            d  = (d << 9 | d >>> 23) + a | 0;
            c += (d & b | a & ~b) + k[15] - 660478335 | 0;
            c  = (c << 14 | c >>> 18) + d | 0;
            b += (c & a | d & ~a) + k[4] - 405537848 | 0;
            b  = (b << 20 | b >>> 12) + c | 0;
            a += (b & d | c & ~d) + k[9] + 568446438 | 0;
            a  = (a << 5 | a >>> 27) + b | 0;
            d += (a & c | b & ~c) + k[14] - 1019803690 | 0;
            d  = (d << 9 | d >>> 23) + a | 0;
            c += (d & b | a & ~b) + k[3] - 187363961 | 0;
            c  = (c << 14 | c >>> 18) + d | 0;
            b += (c & a | d & ~a) + k[8] + 1163531501 | 0;
            b  = (b << 20 | b >>> 12) + c | 0;
            a += (b & d | c & ~d) + k[13] - 1444681467 | 0;
            a  = (a << 5 | a >>> 27) + b | 0;
            d += (a & c | b & ~c) + k[2] - 51403784 | 0;
            d  = (d << 9 | d >>> 23) + a | 0;
            c += (d & b | a & ~b) + k[7] + 1735328473 | 0;
            c  = (c << 14 | c >>> 18) + d | 0;
            b += (c & a | d & ~a) + k[12] - 1926607734 | 0;
            b  = (b << 20 | b >>> 12) + c | 0;
    
            a += (b ^ c ^ d) + k[5] - 378558 | 0;
            a  = (a << 4 | a >>> 28) + b | 0;
            d += (a ^ b ^ c) + k[8] - 2022574463 | 0;
            d  = (d << 11 | d >>> 21) + a | 0;
            c += (d ^ a ^ b) + k[11] + 1839030562 | 0;
            c  = (c << 16 | c >>> 16) + d | 0;
            b += (c ^ d ^ a) + k[14] - 35309556 | 0;
            b  = (b << 23 | b >>> 9) + c | 0;
            a += (b ^ c ^ d) + k[1] - 1530992060 | 0;
            a  = (a << 4 | a >>> 28) + b | 0;
            d += (a ^ b ^ c) + k[4] + 1272893353 | 0;
            d  = (d << 11 | d >>> 21) + a | 0;
            c += (d ^ a ^ b) + k[7] - 155497632 | 0;
            c  = (c << 16 | c >>> 16) + d | 0;
            b += (c ^ d ^ a) + k[10] - 1094730640 | 0;
            b  = (b << 23 | b >>> 9) + c | 0;
            a += (b ^ c ^ d) + k[13] + 681279174 | 0;
            a  = (a << 4 | a >>> 28) + b | 0;
            d += (a ^ b ^ c) + k[0] - 358537222 | 0;
            d  = (d << 11 | d >>> 21) + a | 0;
            c += (d ^ a ^ b) + k[3] - 722521979 | 0;
            c  = (c << 16 | c >>> 16) + d | 0;
            b += (c ^ d ^ a) + k[6] + 76029189 | 0;
            b  = (b << 23 | b >>> 9) + c | 0;
            a += (b ^ c ^ d) + k[9] - 640364487 | 0;
            a  = (a << 4 | a >>> 28) + b | 0;
            d += (a ^ b ^ c) + k[12] - 421815835 | 0;
            d  = (d << 11 | d >>> 21) + a | 0;
            c += (d ^ a ^ b) + k[15] + 530742520 | 0;
            c  = (c << 16 | c >>> 16) + d | 0;
            b += (c ^ d ^ a) + k[2] - 995338651 | 0;
            b  = (b << 23 | b >>> 9) + c | 0;
    
            a += (c ^ (b | ~d)) + k[0] - 198630844 | 0;
            a  = (a << 6 | a >>> 26) + b | 0;
            d += (b ^ (a | ~c)) + k[7] + 1126891415 | 0;
            d  = (d << 10 | d >>> 22) + a | 0;
            c += (a ^ (d | ~b)) + k[14] - 1416354905 | 0;
            c  = (c << 15 | c >>> 17) + d | 0;
            b += (d ^ (c | ~a)) + k[5] - 57434055 | 0;
            b  = (b << 21 |b >>> 11) + c | 0;
            a += (c ^ (b | ~d)) + k[12] + 1700485571 | 0;
            a  = (a << 6 | a >>> 26) + b | 0;
            d += (b ^ (a | ~c)) + k[3] - 1894986606 | 0;
            d  = (d << 10 | d >>> 22) + a | 0;
            c += (a ^ (d | ~b)) + k[10] - 1051523 | 0;
            c  = (c << 15 | c >>> 17) + d | 0;
            b += (d ^ (c | ~a)) + k[1] - 2054922799 | 0;
            b  = (b << 21 |b >>> 11) + c | 0;
            a += (c ^ (b | ~d)) + k[8] + 1873313359 | 0;
            a  = (a << 6 | a >>> 26) + b | 0;
            d += (b ^ (a | ~c)) + k[15] - 30611744 | 0;
            d  = (d << 10 | d >>> 22) + a | 0;
            c += (a ^ (d | ~b)) + k[6] - 1560198380 | 0;
            c  = (c << 15 | c >>> 17) + d | 0;
            b += (d ^ (c | ~a)) + k[13] + 1309151649 | 0;
            b  = (b << 21 |b >>> 11) + c | 0;
            a += (c ^ (b | ~d)) + k[4] - 145523070 | 0;
            a  = (a << 6 | a >>> 26) + b | 0;
            d += (b ^ (a | ~c)) + k[11] - 1120210379 | 0;
            d  = (d << 10 | d >>> 22) + a | 0;
            c += (a ^ (d | ~b)) + k[2] + 718787259 | 0;
            c  = (c << 15 | c >>> 17) + d | 0;
            b += (d ^ (c | ~a)) + k[9] - 343485551 | 0;
            b  = (b << 21 | b >>> 11) + c | 0;
    
            x[0] = a + x[0] | 0;
            x[1] = b + x[1] | 0;
            x[2] = c + x[2] | 0;
            x[3] = d + x[3] | 0;
        }
    
        function md5blk(s) {
            var md5blks = [],
                i; /* Andy King said do it this way. */
    
            for (i = 0; i < 64; i += 4) {
                md5blks[i >> 2] = s.charCodeAt(i) + (s.charCodeAt(i + 1) << 8) + (s.charCodeAt(i + 2) << 16) + (s.charCodeAt(i + 3) << 24);
            }
            return md5blks;
        }
    
        function md5blk_array(a) {
            var md5blks = [],
                i; /* Andy King said do it this way. */
    
            for (i = 0; i < 64; i += 4) {
                md5blks[i >> 2] = a[i] + (a[i + 1] << 8) + (a[i + 2] << 16) + (a[i + 3] << 24);
            }
            return md5blks;
        }
    
        function md51(s) {
            var n = s.length,
                state = [1732584193, -271733879, -1732584194, 271733878],
                i,
                length,
                tail,
                tmp,
                lo,
                hi;
    
            for (i = 64; i <= n; i += 64) {
                md5cycle(state, md5blk(s.substring(i - 64, i)));
            }
            s = s.substring(i - 64);
            length = s.length;
            tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            for (i = 0; i < length; i += 1) {
                tail[i >> 2] |= s.charCodeAt(i) << ((i % 4) << 3);
            }
            tail[i >> 2] |= 0x80 << ((i % 4) << 3);
            if (i > 55) {
                md5cycle(state, tail);
                for (i = 0; i < 16; i += 1) {
                    tail[i] = 0;
                }
            }
    
            // Beware that the final length might not fit in 32 bits so we take care of that
            tmp = n * 8;
            tmp = tmp.toString(16).match(/(.*?)(.{0,8})$/);
            lo = parseInt(tmp[2], 16);
            hi = parseInt(tmp[1], 16) || 0;
    
            tail[14] = lo;
            tail[15] = hi;
    
            md5cycle(state, tail);
            return state;
        }
    
        function md51_array(a) {
            var n = a.length,
                state = [1732584193, -271733879, -1732584194, 271733878],
                i,
                length,
                tail,
                tmp,
                lo,
                hi;
    
            for (i = 64; i <= n; i += 64) {
                md5cycle(state, md5blk_array(a.subarray(i - 64, i)));
            }
    
            // Not sure if it is a bug, however IE10 will always produce a sub array of length 1
            // containing the last element of the parent array if the sub array specified starts
            // beyond the length of the parent array - weird.
            // https://connect.microsoft.com/IE/feedback/details/771452/typed-array-subarray-issue
            a = (i - 64) < n ? a.subarray(i - 64) : new Uint8Array(0);
    
            length = a.length;
            tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            for (i = 0; i < length; i += 1) {
                tail[i >> 2] |= a[i] << ((i % 4) << 3);
            }
    
            tail[i >> 2] |= 0x80 << ((i % 4) << 3);
            if (i > 55) {
                md5cycle(state, tail);
                for (i = 0; i < 16; i += 1) {
                    tail[i] = 0;
                }
            }
    
            // Beware that the final length might not fit in 32 bits so we take care of that
            tmp = n * 8;
            tmp = tmp.toString(16).match(/(.*?)(.{0,8})$/);
            lo = parseInt(tmp[2], 16);
            hi = parseInt(tmp[1], 16) || 0;
    
            tail[14] = lo;
            tail[15] = hi;
    
            md5cycle(state, tail);
    
            return state;
        }
    
        function rhex(n) {
            var s = '',
                j;
            for (j = 0; j < 4; j += 1) {
                s += hex_chr[(n >> (j * 8 + 4)) & 0x0F] + hex_chr[(n >> (j * 8)) & 0x0F];
            }
            return s;
        }
    
        function hex(x) {
            var i;
            for (i = 0; i < x.length; i += 1) {
                x[i] = rhex(x[i]);
            }
            return x.join('');
        }
    
        // In some cases the fast add32 function cannot be used..
        if (hex(md51('hello')) !== '5d41402abc4b2a76b9719d911017c592') {
            add32 = function (x, y) {
                var lsw = (x & 0xFFFF) + (y & 0xFFFF),
                    msw = (x >> 16) + (y >> 16) + (lsw >> 16);
                return (msw << 16) | (lsw & 0xFFFF);
            };
        }
    
        // ---------------------------------------------------
    
        /**
         * ArrayBuffer slice polyfill.
         *
         * @see https://github.com/ttaubert/node-arraybuffer-slice
         */
    
        if (typeof ArrayBuffer !== 'undefined' && !ArrayBuffer.prototype.slice) {
            (function () {
                function clamp(val, length) {
                    val = (val | 0) || 0;
    
                    if (val < 0) {
                        return Math.max(val + length, 0);
                    }
    
                    return Math.min(val, length);
                }
    
                ArrayBuffer.prototype.slice = function (from, to) {
                    var length = this.byteLength,
                        begin = clamp(from, length),
                        end = length,
                        num,
                        target,
                        targetArray,
                        sourceArray;
    
                    if (to !== undefined) {
                        end = clamp(to, length);
                    }
    
                    if (begin > end) {
                        return new ArrayBuffer(0);
                    }
    
                    num = end - begin;
                    target = new ArrayBuffer(num);
                    targetArray = new Uint8Array(target);
    
                    sourceArray = new Uint8Array(this, begin, num);
                    targetArray.set(sourceArray);
    
                    return target;
                };
            })();
        }
    
        // ---------------------------------------------------
    
        /**
         * Helpers.
         */
    
        function toUtf8(str) {
            if (/[\u0080-\uFFFF]/.test(str)) {
                str = unescape(encodeURIComponent(str));
            }
    
            return str;
        }
    
        function utf8Str2ArrayBuffer(str, returnUInt8Array) {
            var length = str.length,
               buff = new ArrayBuffer(length),
               arr = new Uint8Array(buff),
               i;
    
            for (i = 0; i < length; i += 1) {
                arr[i] = str.charCodeAt(i);
            }
    
            return returnUInt8Array ? arr : buff;
        }
    
        function arrayBuffer2Utf8Str(buff) {
            return String.fromCharCode.apply(null, new Uint8Array(buff));
        }
    
        function concatenateArrayBuffers(first, second, returnUInt8Array) {
            var result = new Uint8Array(first.byteLength + second.byteLength);
    
            result.set(new Uint8Array(first));
            result.set(new Uint8Array(second), first.byteLength);
    
            return returnUInt8Array ? result : result.buffer;
        }
    
        function hexToBinaryString(hex) {
            var bytes = [],
                length = hex.length,
                x;
    
            for (x = 0; x < length - 1; x += 2) {
                bytes.push(parseInt(hex.substr(x, 2), 16));
            }
    
            return String.fromCharCode.apply(String, bytes);
        }
    
        // ---------------------------------------------------
    
        /**
         * SparkMD5 OOP implementation.
         *
         * Use this class to perform an incremental md5, otherwise use the
         * static methods instead.
         */
    
        function SparkMD5() {
            // call reset to init the instance
            this.reset();
        }
    
        /**
         * Appends a string.
         * A conversion will be applied if an utf8 string is detected.
         *
         * @param {String} str The string to be appended
         *
         * @return {SparkMD5} The instance itself
         */
        SparkMD5.prototype.append = function (str) {
            // Converts the string to utf8 bytes if necessary
            // Then append as binary
            this.appendBinary(toUtf8(str));
    
            return this;
        };
    
        /**
         * Appends a binary string.
         *
         * @param {String} contents The binary string to be appended
         *
         * @return {SparkMD5} The instance itself
         */
        SparkMD5.prototype.appendBinary = function (contents) {
            this._buff += contents;
            this._length += contents.length;
    
            var length = this._buff.length,
                i;
    
            for (i = 64; i <= length; i += 64) {
                md5cycle(this._hash, md5blk(this._buff.substring(i - 64, i)));
            }
    
            this._buff = this._buff.substring(i - 64);
    
            return this;
        };
    
        /**
         * Finishes the incremental computation, reseting the internal state and
         * returning the result.
         *
         * @param {Boolean} raw True to get the raw string, false to get the hex string
         *
         * @return {String} The result
         */
        SparkMD5.prototype.end = function (raw) {
            var buff = this._buff,
                length = buff.length,
                i,
                tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                ret;
    
            for (i = 0; i < length; i += 1) {
                tail[i >> 2] |= buff.charCodeAt(i) << ((i % 4) << 3);
            }
    
            this._finish(tail, length);
            ret = hex(this._hash);
    
            if (raw) {
                ret = hexToBinaryString(ret);
            }
    
            this.reset();
    
            return ret;
        };
    
        /**
         * Resets the internal state of the computation.
         *
         * @return {SparkMD5} The instance itself
         */
        SparkMD5.prototype.reset = function () {
            this._buff = '';
            this._length = 0;
            this._hash = [1732584193, -271733879, -1732584194, 271733878];
    
            return this;
        };
    
        /**
         * Gets the internal state of the computation.
         *
         * @return {Object} The state
         */
        SparkMD5.prototype.getState = function () {
            return {
                buff: this._buff,
                length: this._length,
                hash: this._hash
            };
        };
    
        /**
         * Gets the internal state of the computation.
         *
         * @param {Object} state The state
         *
         * @return {SparkMD5} The instance itself
         */
        SparkMD5.prototype.setState = function (state) {
            this._buff = state.buff;
            this._length = state.length;
            this._hash = state.hash;
    
            return this;
        };
    
        /**
         * Releases memory used by the incremental buffer and other additional
         * resources. If you plan to use the instance again, use reset instead.
         */
        SparkMD5.prototype.destroy = function () {
            delete this._hash;
            delete this._buff;
            delete this._length;
        };
    
        /**
         * Finish the final calculation based on the tail.
         *
         * @param {Array}  tail   The tail (will be modified)
         * @param {Number} length The length of the remaining buffer
         */
        SparkMD5.prototype._finish = function (tail, length) {
            var i = length,
                tmp,
                lo,
                hi;
    
            tail[i >> 2] |= 0x80 << ((i % 4) << 3);
            if (i > 55) {
                md5cycle(this._hash, tail);
                for (i = 0; i < 16; i += 1) {
                    tail[i] = 0;
                }
            }
    
            // Do the final computation based on the tail and length
            // Beware that the final length may not fit in 32 bits so we take care of that
            tmp = this._length * 8;
            tmp = tmp.toString(16).match(/(.*?)(.{0,8})$/);
            lo = parseInt(tmp[2], 16);
            hi = parseInt(tmp[1], 16) || 0;
    
            tail[14] = lo;
            tail[15] = hi;
            md5cycle(this._hash, tail);
        };
    
        /**
         * Performs the md5 hash on a string.
         * A conversion will be applied if utf8 string is detected.
         *
         * @param {String}  str The string
         * @param {Boolean} raw True to get the raw string, false to get the hex string
         *
         * @return {String} The result
         */
        SparkMD5.hash = function (str, raw) {
            // Converts the string to utf8 bytes if necessary
            // Then compute it using the binary function
            return SparkMD5.hashBinary(toUtf8(str), raw);
        };
    
        /**
         * Performs the md5 hash on a binary string.
         *
         * @param {String}  content The binary string
         * @param {Boolean} raw     True to get the raw string, false to get the hex string
         *
         * @return {String} The result
         */
        SparkMD5.hashBinary = function (content, raw) {
            var hash = md51(content),
                ret = hex(hash);
    
            return raw ? hexToBinaryString(ret) : ret;
        };
    
        // ---------------------------------------------------
    
        /**
         * SparkMD5 OOP implementation for array buffers.
         *
         * Use this class to perform an incremental md5 ONLY for array buffers.
         */
        SparkMD5.ArrayBuffer = function () {
            // call reset to init the instance
            this.reset();
        };
    
        /**
         * Appends an array buffer.
         *
         * @param {ArrayBuffer} arr The array to be appended
         *
         * @return {SparkMD5.ArrayBuffer} The instance itself
         */
        SparkMD5.ArrayBuffer.prototype.append = function (arr) {
            var buff = concatenateArrayBuffers(this._buff.buffer, arr, true),
                length = buff.length,
                i;
    
            this._length += arr.byteLength;
    
            for (i = 64; i <= length; i += 64) {
                md5cycle(this._hash, md5blk_array(buff.subarray(i - 64, i)));
            }
    
            this._buff = (i - 64) < length ? new Uint8Array(buff.buffer.slice(i - 64)) : new Uint8Array(0);
    
            return this;
        };
    
        /**
         * Finishes the incremental computation, reseting the internal state and
         * returning the result.
         *
         * @param {Boolean} raw True to get the raw string, false to get the hex string
         *
         * @return {String} The result
         */
        SparkMD5.ArrayBuffer.prototype.end = function (raw) {
            var buff = this._buff,
                length = buff.length,
                tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                i,
                ret;
    
            for (i = 0; i < length; i += 1) {
                tail[i >> 2] |= buff[i] << ((i % 4) << 3);
            }
    
            this._finish(tail, length);
            ret = hex(this._hash);
    
            if (raw) {
                ret = hexToBinaryString(ret);
            }
    
            this.reset();
    
            return ret;
        };
    
        /**
         * Resets the internal state of the computation.
         *
         * @return {SparkMD5.ArrayBuffer} The instance itself
         */
        SparkMD5.ArrayBuffer.prototype.reset = function () {
            this._buff = new Uint8Array(0);
            this._length = 0;
            this._hash = [1732584193, -271733879, -1732584194, 271733878];
    
            return this;
        };
    
        /**
         * Gets the internal state of the computation.
         *
         * @return {Object} The state
         */
        SparkMD5.ArrayBuffer.prototype.getState = function () {
            var state = SparkMD5.prototype.getState.call(this);
    
            // Convert buffer to a string
            state.buff = arrayBuffer2Utf8Str(state.buff);
    
            return state;
        };
    
        /**
         * Gets the internal state of the computation.
         *
         * @param {Object} state The state
         *
         * @return {SparkMD5.ArrayBuffer} The instance itself
         */
        SparkMD5.ArrayBuffer.prototype.setState = function (state) {
            // Convert string to buffer
            state.buff = utf8Str2ArrayBuffer(state.buff, true);
    
            return SparkMD5.prototype.setState.call(this, state);
        };
    
        SparkMD5.ArrayBuffer.prototype.destroy = SparkMD5.prototype.destroy;
    
        SparkMD5.ArrayBuffer.prototype._finish = SparkMD5.prototype._finish;
    
        /**
         * Performs the md5 hash on an array buffer.
         *
         * @param {ArrayBuffer} arr The array buffer
         * @param {Boolean}     raw True to get the raw string, false to get the hex one
         *
         * @return {String} The result
         */
        SparkMD5.ArrayBuffer.hash = function (arr, raw) {
            var hash = md51_array(new Uint8Array(arr)),
                ret = hex(hash);
    
            return raw ? hexToBinaryString(ret) : ret;
        };
    
        return SparkMD5;
    }));
    
    },{}],6:[function(_dereq_,module,exports){
    var v1 = _dereq_(9);
    var v4 = _dereq_(10);
    
    var uuid = v4;
    uuid.v1 = v1;
    uuid.v4 = v4;
    
    module.exports = uuid;
    
    },{"10":10,"9":9}],7:[function(_dereq_,module,exports){
    /**
     * Convert array of 16 byte values to UUID string format of the form:
     * XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
     */
    var byteToHex = [];
    for (var i = 0; i < 256; ++i) {
      byteToHex[i] = (i + 0x100).toString(16).substr(1);
    }
    
    function bytesToUuid(buf, offset) {
      var i = offset || 0;
      var bth = byteToHex;
      return bth[buf[i++]] + bth[buf[i++]] +
              bth[buf[i++]] + bth[buf[i++]] + '-' +
              bth[buf[i++]] + bth[buf[i++]] + '-' +
              bth[buf[i++]] + bth[buf[i++]] + '-' +
              bth[buf[i++]] + bth[buf[i++]] + '-' +
              bth[buf[i++]] + bth[buf[i++]] +
              bth[buf[i++]] + bth[buf[i++]] +
              bth[buf[i++]] + bth[buf[i++]];
    }
    
    module.exports = bytesToUuid;
    
    },{}],8:[function(_dereq_,module,exports){
    // Unique ID creation requires a high quality random # generator.  In the
    // browser this is a little complicated due to unknown quality of Math.random()
    // and inconsistent support for the `crypto` API.  We do the best we can via
    // feature-detection
    
    // getRandomValues needs to be invoked in a context where "this" is a Crypto implementation.
    var getRandomValues = (typeof(crypto) != 'undefined' && crypto.getRandomValues.bind(crypto)) ||
                          (typeof(msCrypto) != 'undefined' && msCrypto.getRandomValues.bind(msCrypto));
    if (getRandomValues) {
      // WHATWG crypto RNG - http://wiki.whatwg.org/wiki/Crypto
      var rnds8 = new Uint8Array(16); // eslint-disable-line no-undef
    
      module.exports = function whatwgRNG() {
        getRandomValues(rnds8);
        return rnds8;
      };
    } else {
      // Math.random()-based (RNG)
      //
      // If all else fails, use Math.random().  It's fast, but is of unspecified
      // quality.
      var rnds = new Array(16);
    
      module.exports = function mathRNG() {
        for (var i = 0, r; i < 16; i++) {
          if ((i & 0x03) === 0) r = Math.random() * 0x100000000;
          rnds[i] = r >>> ((i & 0x03) << 3) & 0xff;
        }
    
        return rnds;
      };
    }
    
    },{}],9:[function(_dereq_,module,exports){
    var rng = _dereq_(8);
    var bytesToUuid = _dereq_(7);
    
    // **`v1()` - Generate time-based UUID**
    //
    // Inspired by https://github.com/LiosK/UUID.js
    // and http://docs.python.org/library/uuid.html
    
    var _nodeId;
    var _clockseq;
    
    // Previous uuid creation time
    var _lastMSecs = 0;
    var _lastNSecs = 0;
    
    // See https://github.com/broofa/node-uuid for API details
    function v1(options, buf, offset) {
      var i = buf && offset || 0;
      var b = buf || [];
    
      options = options || {};
      var node = options.node || _nodeId;
      var clockseq = options.clockseq !== undefined ? options.clockseq : _clockseq;
    
      // node and clockseq need to be initialized to random values if they're not
      // specified.  We do this lazily to minimize issues related to insufficient
      // system entropy.  See #189
      if (node == null || clockseq == null) {
        var seedBytes = rng();
        if (node == null) {
          // Per 4.5, create and 48-bit node id, (47 random bits + multicast bit = 1)
          node = _nodeId = [
            seedBytes[0] | 0x01,
            seedBytes[1], seedBytes[2], seedBytes[3], seedBytes[4], seedBytes[5]
          ];
        }
        if (clockseq == null) {
          // Per 4.2.2, randomize (14 bit) clockseq
          clockseq = _clockseq = (seedBytes[6] << 8 | seedBytes[7]) & 0x3fff;
        }
      }
    
      // UUID timestamps are 100 nano-second units since the Gregorian epoch,
      // (1582-10-15 00:00).  JSNumbers aren't precise enough for this, so
      // time is handled internally as 'msecs' (integer milliseconds) and 'nsecs'
      // (100-nanoseconds offset from msecs) since unix epoch, 1970-01-01 00:00.
      var msecs = options.msecs !== undefined ? options.msecs : new Date().getTime();
    
      // Per 4.2.1.2, use count of uuid's generated during the current clock
      // cycle to simulate higher resolution clock
      var nsecs = options.nsecs !== undefined ? options.nsecs : _lastNSecs + 1;
    
      // Time since last uuid creation (in msecs)
      var dt = (msecs - _lastMSecs) + (nsecs - _lastNSecs)/10000;
    
      // Per 4.2.1.2, Bump clockseq on clock regression
      if (dt < 0 && options.clockseq === undefined) {
        clockseq = clockseq + 1 & 0x3fff;
      }
    
      // Reset nsecs if clock regresses (new clockseq) or we've moved onto a new
      // time interval
      if ((dt < 0 || msecs > _lastMSecs) && options.nsecs === undefined) {
        nsecs = 0;
      }
    
      // Per 4.2.1.2 Throw error if too many uuids are requested
      if (nsecs >= 10000) {
        throw new Error('uuid.v1(): Can\'t create more than 10M uuids/sec');
      }
    
      _lastMSecs = msecs;
      _lastNSecs = nsecs;
      _clockseq = clockseq;
    
      // Per 4.1.4 - Convert from unix epoch to Gregorian epoch
      msecs += 12219292800000;
    
      // `time_low`
      var tl = ((msecs & 0xfffffff) * 10000 + nsecs) % 0x100000000;
      b[i++] = tl >>> 24 & 0xff;
      b[i++] = tl >>> 16 & 0xff;
      b[i++] = tl >>> 8 & 0xff;
      b[i++] = tl & 0xff;
    
      // `time_mid`
      var tmh = (msecs / 0x100000000 * 10000) & 0xfffffff;
      b[i++] = tmh >>> 8 & 0xff;
      b[i++] = tmh & 0xff;
    
      // `time_high_and_version`
      b[i++] = tmh >>> 24 & 0xf | 0x10; // include version
      b[i++] = tmh >>> 16 & 0xff;
    
      // `clock_seq_hi_and_reserved` (Per 4.2.2 - include variant)
      b[i++] = clockseq >>> 8 | 0x80;
    
      // `clock_seq_low`
      b[i++] = clockseq & 0xff;
    
      // `node`
      for (var n = 0; n < 6; ++n) {
        b[i + n] = node[n];
      }
    
      return buf ? buf : bytesToUuid(b);
    }
    
    module.exports = v1;
    
    },{"7":7,"8":8}],10:[function(_dereq_,module,exports){
    var rng = _dereq_(8);
    var bytesToUuid = _dereq_(7);
    
    function v4(options, buf, offset) {
      var i = buf && offset || 0;
    
      if (typeof(options) == 'string') {
        buf = options === 'binary' ? new Array(16) : null;
        options = null;
      }
      options = options || {};
    
      var rnds = options.random || (options.rng || rng)();
    
      // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
      rnds[6] = (rnds[6] & 0x0f) | 0x40;
      rnds[8] = (rnds[8] & 0x3f) | 0x80;
    
      // Copy bytes to buffer, if provided
      if (buf) {
        for (var ii = 0; ii < 16; ++ii) {
          buf[i + ii] = rnds[ii];
        }
      }
    
      return buf || bytesToUuid(rnds);
    }
    
    module.exports = v4;
    
    },{"7":7,"8":8}],11:[function(_dereq_,module,exports){
    'use strict';
    
    /**
     * Stringify/parse functions that don't operate
     * recursively, so they avoid call stack exceeded
     * errors.
     */
    exports.stringify = function stringify(input) {
      var queue = [];
      queue.push({obj: input});
    
      var res = '';
      var next, obj, prefix, val, i, arrayPrefix, keys, k, key, value, objPrefix;
      while ((next = queue.pop())) {
        obj = next.obj;
        prefix = next.prefix || '';
        val = next.val || '';
        res += prefix;
        if (val) {
          res += val;
        } else if (typeof obj !== 'object') {
          res += typeof obj === 'undefined' ? null : JSON.stringify(obj);
        } else if (obj === null) {
          res += 'null';
        } else if (Array.isArray(obj)) {
          queue.push({val: ']'});
          for (i = obj.length - 1; i >= 0; i--) {
            arrayPrefix = i === 0 ? '' : ',';
            queue.push({obj: obj[i], prefix: arrayPrefix});
          }
          queue.push({val: '['});
        } else { // object
          keys = [];
          for (k in obj) {
            if (obj.hasOwnProperty(k)) {
              keys.push(k);
            }
          }
          queue.push({val: '}'});
          for (i = keys.length - 1; i >= 0; i--) {
            key = keys[i];
            value = obj[key];
            objPrefix = (i > 0 ? ',' : '');
            objPrefix += JSON.stringify(key) + ':';
            queue.push({obj: value, prefix: objPrefix});
          }
          queue.push({val: '{'});
        }
      }
      return res;
    };
    
    // Convenience function for the parse function.
    // This pop function is basically copied from
    // pouchCollate.parseIndexableString
    function pop(obj, stack, metaStack) {
      var lastMetaElement = metaStack[metaStack.length - 1];
      if (obj === lastMetaElement.element) {
        // popping a meta-element, e.g. an object whose value is another object
        metaStack.pop();
        lastMetaElement = metaStack[metaStack.length - 1];
      }
      var element = lastMetaElement.element;
      var lastElementIndex = lastMetaElement.index;
      if (Array.isArray(element)) {
        element.push(obj);
      } else if (lastElementIndex === stack.length - 2) { // obj with key+value
        var key = stack.pop();
        element[key] = obj;
      } else {
        stack.push(obj); // obj with key only
      }
    }
    
    exports.parse = function (str) {
      var stack = [];
      var metaStack = []; // stack for arrays and objects
      var i = 0;
      var collationIndex,parsedNum,numChar;
      var parsedString,lastCh,numConsecutiveSlashes,ch;
      var arrayElement, objElement;
      while (true) {
        collationIndex = str[i++];
        if (collationIndex === '}' ||
            collationIndex === ']' ||
            typeof collationIndex === 'undefined') {
          if (stack.length === 1) {
            return stack.pop();
          } else {
            pop(stack.pop(), stack, metaStack);
            continue;
          }
        }
        switch (collationIndex) {
          case ' ':
          case '\t':
          case '\n':
          case ':':
          case ',':
            break;
          case 'n':
            i += 3; // 'ull'
            pop(null, stack, metaStack);
            break;
          case 't':
            i += 3; // 'rue'
            pop(true, stack, metaStack);
            break;
          case 'f':
            i += 4; // 'alse'
            pop(false, stack, metaStack);
            break;
          case '0':
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
          case '7':
          case '8':
          case '9':
          case '-':
            parsedNum = '';
            i--;
            while (true) {
              numChar = str[i++];
              if (/[\d\.\-e\+]/.test(numChar)) {
                parsedNum += numChar;
              } else {
                i--;
                break;
              }
            }
            pop(parseFloat(parsedNum), stack, metaStack);
            break;
          case '"':
            parsedString = '';
            lastCh = void 0;
            numConsecutiveSlashes = 0;
            while (true) {
              ch = str[i++];
              if (ch !== '"' || (lastCh === '\\' &&
                  numConsecutiveSlashes % 2 === 1)) {
                parsedString += ch;
                lastCh = ch;
                if (lastCh === '\\') {
                  numConsecutiveSlashes++;
                } else {
                  numConsecutiveSlashes = 0;
                }
              } else {
                break;
              }
            }
            pop(JSON.parse('"' + parsedString + '"'), stack, metaStack);
            break;
          case '[':
            arrayElement = { element: [], index: stack.length };
            stack.push(arrayElement.element);
            metaStack.push(arrayElement);
            break;
          case '{':
            objElement = { element: {}, index: stack.length };
            stack.push(objElement.element);
            metaStack.push(objElement);
            break;
          default:
            throw new Error(
              'unexpectedly reached end of input: ' + collationIndex);
        }
      }
    };
    
    },{}],12:[function(_dereq_,module,exports){
    (function (global){
    'use strict';
    
    function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }
    
    var getArguments = _interopDefault(_dereq_(1));
    var immediate = _interopDefault(_dereq_(3));
    var events = _dereq_(2);
    var inherits = _interopDefault(_dereq_(4));
    var Md5 = _interopDefault(_dereq_(5));
    var uuidV4 = _interopDefault(_dereq_(6));
    var vuvuzela = _interopDefault(_dereq_(11));
    
    function isBinaryObject(object) {
      return (typeof ArrayBuffer !== 'undefined' && object instanceof ArrayBuffer) ||
        (typeof Blob !== 'undefined' && object instanceof Blob);
    }
    
    function cloneArrayBuffer(buff) {
      if (typeof buff.slice === 'function') {
        return buff.slice(0);
      }
      // IE10-11 slice() polyfill
      var target = new ArrayBuffer(buff.byteLength);
      var targetArray = new Uint8Array(target);
      var sourceArray = new Uint8Array(buff);
      targetArray.set(sourceArray);
      return target;
    }
    
    function cloneBinaryObject(object) {
      if (object instanceof ArrayBuffer) {
        return cloneArrayBuffer(object);
      }
      var size = object.size;
      var type = object.type;
      // Blob
      if (typeof object.slice === 'function') {
        return object.slice(0, size, type);
      }
      // PhantomJS slice() replacement
      return object.webkitSlice(0, size, type);
    }
    
    // most of this is borrowed from lodash.isPlainObject:
    // https://github.com/fis-components/lodash.isplainobject/
    // blob/29c358140a74f252aeb08c9eb28bef86f2217d4a/index.js
    
    var funcToString = Function.prototype.toString;
    var objectCtorString = funcToString.call(Object);
    
    function isPlainObject(value) {
      var proto = Object.getPrototypeOf(value);
      /* istanbul ignore if */
      if (proto === null) { // not sure when this happens, but I guess it can
        return true;
      }
      var Ctor = proto.constructor;
      return (typeof Ctor == 'function' &&
        Ctor instanceof Ctor && funcToString.call(Ctor) == objectCtorString);
    }
    
    function clone(object) {
      var newObject;
      var i;
      var len;
    
      if (!object || typeof object !== 'object') {
        return object;
      }
    
      if (Array.isArray(object)) {
        newObject = [];
        for (i = 0, len = object.length; i < len; i++) {
          newObject[i] = clone(object[i]);
        }
        return newObject;
      }
    
      // special case: to avoid inconsistencies between IndexedDB
      // and other backends, we automatically stringify Dates
      if (object instanceof Date) {
        return object.toISOString();
      }
    
      if (isBinaryObject(object)) {
        return cloneBinaryObject(object);
      }
    
      if (!isPlainObject(object)) {
        return object; // don't clone objects like Workers
      }
    
      newObject = {};
      for (i in object) {
        /* istanbul ignore else */
        if (Object.prototype.hasOwnProperty.call(object, i)) {
          var value = clone(object[i]);
          if (typeof value !== 'undefined') {
            newObject[i] = value;
          }
        }
      }
      return newObject;
    }
    
    function once(fun) {
      var called = false;
      return getArguments(function (args) {
        /* istanbul ignore if */
        if (called) {
          // this is a smoke test and should never actually happen
          throw new Error('once called more than once');
        } else {
          called = true;
          fun.apply(this, args);
        }
      });
    }
    
    function toPromise(func) {
      //create the function we will be returning
      return getArguments(function (args) {
        // Clone arguments
        args = clone(args);
        var self = this;
        // if the last argument is a function, assume its a callback
        var usedCB = (typeof args[args.length - 1] === 'function') ? args.pop() : false;
        var promise = new Promise(function (fulfill, reject) {
          var resp;
          try {
            var callback = once(function (err, mesg) {
              if (err) {
                reject(err);
              } else {
                fulfill(mesg);
              }
            });
            // create a callback for this invocation
            // apply the function in the orig context
            args.push(callback);
            resp = func.apply(self, args);
            if (resp && typeof resp.then === 'function') {
              fulfill(resp);
            }
          } catch (e) {
            reject(e);
          }
        });
        // if there is a callback, call it back
        if (usedCB) {
          promise.then(function (result) {
            usedCB(null, result);
          }, usedCB);
        }
        return promise;
      });
    }
    
    function mangle(key) {
      return '$' + key;
    }
    function unmangle(key) {
      return key.substring(1);
    }
    function Map$1() {
      this._store = {};
    }
    Map$1.prototype.get = function (key) {
      var mangled = mangle(key);
      return this._store[mangled];
    };
    Map$1.prototype.set = function (key, value) {
      var mangled = mangle(key);
      this._store[mangled] = value;
      return true;
    };
    Map$1.prototype.has = function (key) {
      var mangled = mangle(key);
      return mangled in this._store;
    };
    Map$1.prototype["delete"] = function (key) {
      var mangled = mangle(key);
      var res = mangled in this._store;
      delete this._store[mangled];
      return res;
    };
    Map$1.prototype.forEach = function (cb) {
      var keys = Object.keys(this._store);
      for (var i = 0, len = keys.length; i < len; i++) {
        var key = keys[i];
        var value = this._store[key];
        key = unmangle(key);
        cb(value, key);
      }
    };
    Object.defineProperty(Map$1.prototype, 'size', {
      get: function () {
        return Object.keys(this._store).length;
      }
    });
    
    function Set$1(array) {
      this._store = new Map$1();
    
      // init with an array
      if (array && Array.isArray(array)) {
        for (var i = 0, len = array.length; i < len; i++) {
          this.add(array[i]);
        }
      }
    }
    Set$1.prototype.add = function (key) {
      return this._store.set(key, true);
    };
    Set$1.prototype.has = function (key) {
      return this._store.has(key);
    };
    Set$1.prototype.forEach = function (cb) {
      this._store.forEach(function (value, key) {
        cb(key);
      });
    };
    Object.defineProperty(Set$1.prototype, 'size', {
      get: function () {
        return this._store.size;
      }
    });
    
    /* global Map,Set,Symbol */
    // Based on https://kangax.github.io/compat-table/es6/ we can sniff out
    // incomplete Map/Set implementations which would otherwise cause our tests to fail.
    // Notably they fail in IE11 and iOS 8.4, which this prevents.
    function supportsMapAndSet() {
      if (typeof Symbol === 'undefined' || typeof Map === 'undefined' || typeof Set === 'undefined') {
        return false;
      }
      var prop = Object.getOwnPropertyDescriptor(Map, Symbol.species);
      return prop && 'get' in prop && Map[Symbol.species] === Map;
    }
    
    // based on https://github.com/montagejs/collections
    
    var ExportedSet;
    var ExportedMap;
    
    {
      if (supportsMapAndSet()) { // prefer built-in Map/Set
        ExportedSet = Set;
        ExportedMap = Map;
      } else { // fall back to our polyfill
        ExportedSet = Set$1;
        ExportedMap = Map$1;
      }
    }
    
    // like underscore/lodash _.pick()
    function pick(obj, arr) {
      var res = {};
      for (var i = 0, len = arr.length; i < len; i++) {
        var prop = arr[i];
        if (prop in obj) {
          res[prop] = obj[prop];
        }
      }
      return res;
    }
    
    var hasLocal;
    
    try {
      localStorage.setItem('_pouch_check_localstorage', 1);
      hasLocal = !!localStorage.getItem('_pouch_check_localstorage');
    } catch (e) {
      hasLocal = false;
    }
    
    function hasLocalStorage() {
      return hasLocal;
    }
    
    // Custom nextTick() shim for browsers. In node, this will just be process.nextTick(). We
    
    inherits(Changes, events.EventEmitter);
    
    /* istanbul ignore next */
    function attachBrowserEvents(self) {
      if (hasLocalStorage()) {
        addEventListener("storage", function (e) {
          self.emit(e.key);
        });
      }
    }
    
    function Changes() {
      events.EventEmitter.call(this);
      this._listeners = {};
    
      attachBrowserEvents(this);
    }
    Changes.prototype.addListener = function (dbName, id, db, opts) {
      /* istanbul ignore if */
      if (this._listeners[id]) {
        return;
      }
      var self = this;
      var inprogress = false;
      function eventFunction() {
        /* istanbul ignore if */
        if (!self._listeners[id]) {
          return;
        }
        if (inprogress) {
          inprogress = 'waiting';
          return;
        }
        inprogress = true;
        var changesOpts = pick(opts, [
          'style', 'include_docs', 'attachments', 'conflicts', 'filter',
          'doc_ids', 'view', 'since', 'query_params', 'binary', 'return_docs'
        ]);
    
        /* istanbul ignore next */
        function onError() {
          inprogress = false;
        }
    
        db.changes(changesOpts).on('change', function (c) {
          if (c.seq > opts.since && !opts.cancelled) {
            opts.since = c.seq;
            opts.onChange(c);
          }
        }).on('complete', function () {
          if (inprogress === 'waiting') {
            immediate(eventFunction);
          }
          inprogress = false;
        }).on('error', onError);
      }
      this._listeners[id] = eventFunction;
      this.on(dbName, eventFunction);
    };
    
    Changes.prototype.removeListener = function (dbName, id) {
      /* istanbul ignore if */
      if (!(id in this._listeners)) {
        return;
      }
      events.EventEmitter.prototype.removeListener.call(this, dbName,
        this._listeners[id]);
      delete this._listeners[id];
    };
    
    
    /* istanbul ignore next */
    Changes.prototype.notifyLocalWindows = function (dbName) {
      //do a useless change on a storage thing
      //in order to get other windows's listeners to activate
      if (hasLocalStorage()) {
        localStorage[dbName] = (localStorage[dbName] === "a") ? "b" : "a";
      }
    };
    
    Changes.prototype.notify = function (dbName) {
      this.emit(dbName);
      this.notifyLocalWindows(dbName);
    };
    
    function guardedConsole(method) {
      /* istanbul ignore else */
      if (typeof console !== 'undefined' && typeof console[method] === 'function') {
        var args = Array.prototype.slice.call(arguments, 1);
        console[method].apply(console, args);
      }
    }
    
    var assign;
    {
      if (typeof Object.assign === 'function') {
        assign = Object.assign;
      } else {
        // lite Object.assign polyfill based on
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
        assign = function (target) {
          var to = Object(target);
    
          for (var index = 1; index < arguments.length; index++) {
            var nextSource = arguments[index];
    
            if (nextSource != null) { // Skip over if undefined or null
              for (var nextKey in nextSource) {
                // Avoid bugs when hasOwnProperty is shadowed
                if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                  to[nextKey] = nextSource[nextKey];
                }
              }
            }
          }
          return to;
        };
      }
    }
    
    var $inject_Object_assign = assign;
    
    inherits(PouchError, Error);
    
    function PouchError(status, error, reason) {
      Error.call(this, reason);
      this.status = status;
      this.name = error;
      this.message = reason;
      this.error = true;
    }
    
    PouchError.prototype.toString = function () {
      return JSON.stringify({
        status: this.status,
        name: this.name,
        message: this.message,
        reason: this.reason
      });
    };
    
    var UNAUTHORIZED = new PouchError(401, 'unauthorized', "Name or password is incorrect.");
    var MISSING_BULK_DOCS = new PouchError(400, 'bad_request', "Missing JSON list of 'docs'");
    var MISSING_DOC = new PouchError(404, 'not_found', 'missing');
    var REV_CONFLICT = new PouchError(409, 'conflict', 'Document update conflict');
    var INVALID_ID = new PouchError(400, 'bad_request', '_id field must contain a string');
    var MISSING_ID = new PouchError(412, 'missing_id', '_id is required for puts');
    var RESERVED_ID = new PouchError(400, 'bad_request', 'Only reserved document ids may start with underscore.');
    var NOT_OPEN = new PouchError(412, 'precondition_failed', 'Database not open');
    var UNKNOWN_ERROR = new PouchError(500, 'unknown_error', 'Database encountered an unknown error');
    var BAD_ARG = new PouchError(500, 'badarg', 'Some query argument is invalid');
    var INVALID_REQUEST = new PouchError(400, 'invalid_request', 'Request was invalid');
    var QUERY_PARSE_ERROR = new PouchError(400, 'query_parse_error', 'Some query parameter is invalid');
    var DOC_VALIDATION = new PouchError(500, 'doc_validation', 'Bad special document member');
    var BAD_REQUEST = new PouchError(400, 'bad_request', 'Something wrong with the request');
    var NOT_AN_OBJECT = new PouchError(400, 'bad_request', 'Document must be a JSON object');
    var DB_MISSING = new PouchError(404, 'not_found', 'Database not found');
    var IDB_ERROR = new PouchError(500, 'indexed_db_went_bad', 'unknown');
    var WSQ_ERROR = new PouchError(500, 'web_sql_went_bad', 'unknown');
    var LDB_ERROR = new PouchError(500, 'levelDB_went_went_bad', 'unknown');
    var FORBIDDEN = new PouchError(403, 'forbidden', 'Forbidden by design doc validate_doc_update function');
    var INVALID_REV = new PouchError(400, 'bad_request', 'Invalid rev format');
    var FILE_EXISTS = new PouchError(412, 'file_exists', 'The database could not be created, the file already exists.');
    var MISSING_STUB = new PouchError(412, 'missing_stub', 'A pre-existing attachment stub wasn\'t found');
    var INVALID_URL = new PouchError(413, 'invalid_url', 'Provided URL is invalid');
    
    function createError(error, reason) {
      function CustomPouchError(reason) {
        // inherit error properties from our parent error manually
        // so as to allow proper JSON parsing.
        /* jshint ignore:start */
        for (var p in error) {
          if (typeof error[p] !== 'function') {
            this[p] = error[p];
          }
        }
        /* jshint ignore:end */
        if (reason !== undefined) {
          this.reason = reason;
        }
      }
      CustomPouchError.prototype = PouchError.prototype;
      return new CustomPouchError(reason);
    }
    
    function tryFilter(filter, doc, req) {
      try {
        return !filter(doc, req);
      } catch (err) {
        var msg = 'Filter function threw: ' + err.toString();
        return createError(BAD_REQUEST, msg);
      }
    }
    
    function filterChange(opts) {
      var req = {};
      var hasFilter = opts.filter && typeof opts.filter === 'function';
      req.query = opts.query_params;
    
      return function filter(change) {
        if (!change.doc) {
          // CSG sends events on the changes feed that don't have documents,
          // this hack makes a whole lot of existing code robust.
          change.doc = {};
        }
    
        var filterReturn = hasFilter && tryFilter(opts.filter, change.doc, req);
    
        if (typeof filterReturn === 'object') {
          return filterReturn;
        }
    
        if (filterReturn) {
          return false;
        }
    
        if (!opts.include_docs) {
          delete change.doc;
        } else if (!opts.attachments) {
          for (var att in change.doc._attachments) {
            /* istanbul ignore else */
            if (change.doc._attachments.hasOwnProperty(att)) {
              change.doc._attachments[att].stub = true;
            }
          }
        }
        return true;
      };
    }
    
    // shim for Function.prototype.name,
    
    // Determine id an ID is valid
    //   - invalid IDs begin with an underescore that does not begin '_design' or
    //     '_local'
    //   - any other string value is a valid id
    // Returns the specific error object for each case
    function invalidIdError(id) {
      var err;
      if (!id) {
        err = createError(MISSING_ID);
      } else if (typeof id !== 'string') {
        err = createError(INVALID_ID);
      } else if (/^_/.test(id) && !(/^_(design|local)/).test(id)) {
        err = createError(RESERVED_ID);
      }
      if (err) {
        throw err;
      }
    }
    
    // Checks if a PouchDB object is "remote" or not. This is
    
    // originally parseUri 1.2.2, now patched by us
    
    // Based on https://github.com/alexdavid/scope-eval v0.0.3
    
    var thisAtob = function (str) {
      return atob(str);
    };
    
    var thisBtoa = function (str) {
      return btoa(str);
    };
    
    // Abstracts constructing a Blob object, so it also works in older
    // browsers that don't support the native Blob constructor (e.g.
    // old QtWebKit versions, Android < 4.4).
    function createBlob(parts, properties) {
      /* global BlobBuilder,MSBlobBuilder,MozBlobBuilder,WebKitBlobBuilder */
      parts = parts || [];
      properties = properties || {};
      try {
        return new Blob(parts, properties);
      } catch (e) {
        if (e.name !== "TypeError") {
          throw e;
        }
        var Builder = typeof BlobBuilder !== 'undefined' ? BlobBuilder :
                      typeof MSBlobBuilder !== 'undefined' ? MSBlobBuilder :
                      typeof MozBlobBuilder !== 'undefined' ? MozBlobBuilder :
                      WebKitBlobBuilder;
        var builder = new Builder();
        for (var i = 0; i < parts.length; i += 1) {
          builder.append(parts[i]);
        }
        return builder.getBlob(properties.type);
      }
    }
    
    // From http://stackoverflow.com/questions/14967647/ (continues on next line)
    // encode-decode-image-with-base64-breaks-image (2013-04-21)
    function binaryStringToArrayBuffer(bin) {
      var length = bin.length;
      var buf = new ArrayBuffer(length);
      var arr = new Uint8Array(buf);
      for (var i = 0; i < length; i++) {
        arr[i] = bin.charCodeAt(i);
      }
      return buf;
    }
    
    function binStringToBluffer(binString, type) {
      return createBlob([binaryStringToArrayBuffer(binString)], {type: type});
    }
    
    //Can't find original post, but this is close
    //http://stackoverflow.com/questions/6965107/ (continues on next line)
    //converting-between-strings-and-arraybuffers
    function arrayBufferToBinaryString(buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var length = bytes.byteLength;
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return binary;
    }
    
    // shim for browsers that don't support it
    function readAsBinaryString(blob, callback) {
      var reader = new FileReader();
      var hasBinaryString = typeof reader.readAsBinaryString === 'function';
      reader.onloadend = function (e) {
        var result = e.target.result || '';
        if (hasBinaryString) {
          return callback(result);
        }
        callback(arrayBufferToBinaryString(result));
      };
      if (hasBinaryString) {
        reader.readAsBinaryString(blob);
      } else {
        reader.readAsArrayBuffer(blob);
      }
    }
    
    function blobToBinaryString(blobOrBuffer, callback) {
      readAsBinaryString(blobOrBuffer, function (bin) {
        callback(bin);
      });
    }
    
    function blobToBase64(blobOrBuffer, callback) {
      blobToBinaryString(blobOrBuffer, function (base64) {
        callback(thisBtoa(base64));
      });
    }
    
    // simplified API. universal browser support is assumed
    function readAsArrayBuffer(blob, callback) {
      var reader = new FileReader();
      reader.onloadend = function (e) {
        var result = e.target.result || new ArrayBuffer(0);
        callback(result);
      };
      reader.readAsArrayBuffer(blob);
    }
    
    // this is not used in the browser
    
    var setImmediateShim = global.setImmediate || global.setTimeout;
    var MD5_CHUNK_SIZE = 32768;
    
    function rawToBase64(raw) {
      return thisBtoa(raw);
    }
    
    function sliceBlob(blob, start, end) {
      if (blob.webkitSlice) {
        return blob.webkitSlice(start, end);
      }
      return blob.slice(start, end);
    }
    
    function appendBlob(buffer, blob, start, end, callback) {
      if (start > 0 || end < blob.size) {
        // only slice blob if we really need to
        blob = sliceBlob(blob, start, end);
      }
      readAsArrayBuffer(blob, function (arrayBuffer) {
        buffer.append(arrayBuffer);
        callback();
      });
    }
    
    function appendString(buffer, string, start, end, callback) {
      if (start > 0 || end < string.length) {
        // only create a substring if we really need to
        string = string.substring(start, end);
      }
      buffer.appendBinary(string);
      callback();
    }
    
    function binaryMd5(data, callback) {
      var inputIsString = typeof data === 'string';
      var len = inputIsString ? data.length : data.size;
      var chunkSize = Math.min(MD5_CHUNK_SIZE, len);
      var chunks = Math.ceil(len / chunkSize);
      var currentChunk = 0;
      var buffer = inputIsString ? new Md5() : new Md5.ArrayBuffer();
    
      var append = inputIsString ? appendString : appendBlob;
    
      function next() {
        setImmediateShim(loadNextChunk);
      }
    
      function done() {
        var raw = buffer.end(true);
        var base64 = rawToBase64(raw);
        callback(base64);
        buffer.destroy();
      }
    
      function loadNextChunk() {
        var start = currentChunk * chunkSize;
        var end = start + chunkSize;
        currentChunk++;
        if (currentChunk < chunks) {
          append(buffer, data, start, end, next);
        } else {
          append(buffer, data, start, end, done);
        }
      }
      loadNextChunk();
    }
    
    function stringMd5(string) {
      return Md5.hash(string);
    }
    
    function rev$$1(doc, deterministic_revs) {
      var clonedDoc = clone(doc);
      if (!deterministic_revs) {
        return uuidV4.v4().replace(/-/g, '').toLowerCase();
      }
    
      delete clonedDoc._rev_tree;
      return stringMd5(JSON.stringify(clonedDoc));
    }
    
    var uuid = uuidV4.v4;
    
    function toObject(array) {
      return array.reduce(function (obj, item) {
        obj[item] = true;
        return obj;
      }, {});
    }
    // List of top level reserved words for doc
    var reservedWords = toObject([
      '_id',
      '_rev',
      '_attachments',
      '_deleted',
      '_revisions',
      '_revs_info',
      '_conflicts',
      '_deleted_conflicts',
      '_local_seq',
      '_rev_tree',
      //replication documents
      '_replication_id',
      '_replication_state',
      '_replication_state_time',
      '_replication_state_reason',
      '_replication_stats',
      // Specific to Couchbase Sync Gateway
      '_removed'
    ]);
    
    // List of reserved words that should end up the document
    var dataWords = toObject([
      '_attachments',
      //replication documents
      '_replication_id',
      '_replication_state',
      '_replication_state_time',
      '_replication_state_reason',
      '_replication_stats'
    ]);
    
    function parseRevisionInfo(rev) {
      if (!/^\d+-./.test(rev)) {
        return createError(INVALID_REV);
      }
      var idx = rev.indexOf('-');
      var left = rev.substring(0, idx);
      var right = rev.substring(idx + 1);
      return {
        prefix: parseInt(left, 10),
        id: right
      };
    }
    
    function makeRevTreeFromRevisions(revisions, opts) {
      var pos = revisions.start - revisions.ids.length + 1;
    
      var revisionIds = revisions.ids;
      var ids = [revisionIds[0], opts, []];
    
      for (var i = 1, len = revisionIds.length; i < len; i++) {
        ids = [revisionIds[i], {status: 'missing'}, [ids]];
      }
    
      return [{
        pos: pos,
        ids: ids
      }];
    }
    
    // Preprocess documents, parse their revisions, assign an id and a
    // revision for new writes that are missing them, etc
    function parseDoc(doc, newEdits, dbOpts) {
      if (!dbOpts) {
        dbOpts = {
          deterministic_revs: true
        };
      }
    
      var nRevNum;
      var newRevId;
      var revInfo;
      var opts = {status: 'available'};
      if (doc._deleted) {
        opts.deleted = true;
      }
    
      if (newEdits) {
        if (!doc._id) {
          doc._id = uuid();
        }
        newRevId = rev$$1(doc, dbOpts.deterministic_revs);
        if (doc._rev) {
          revInfo = parseRevisionInfo(doc._rev);
          if (revInfo.error) {
            return revInfo;
          }
          doc._rev_tree = [{
            pos: revInfo.prefix,
            ids: [revInfo.id, {status: 'missing'}, [[newRevId, opts, []]]]
          }];
          nRevNum = revInfo.prefix + 1;
        } else {
          doc._rev_tree = [{
            pos: 1,
            ids : [newRevId, opts, []]
          }];
          nRevNum = 1;
        }
      } else {
        if (doc._revisions) {
          doc._rev_tree = makeRevTreeFromRevisions(doc._revisions, opts);
          nRevNum = doc._revisions.start;
          newRevId = doc._revisions.ids[0];
        }
        if (!doc._rev_tree) {
          revInfo = parseRevisionInfo(doc._rev);
          if (revInfo.error) {
            return revInfo;
          }
          nRevNum = revInfo.prefix;
          newRevId = revInfo.id;
          doc._rev_tree = [{
            pos: nRevNum,
            ids: [newRevId, opts, []]
          }];
        }
      }
    
      invalidIdError(doc._id);
    
      doc._rev = nRevNum + '-' + newRevId;
    
      var result = {metadata : {}, data : {}};
      for (var key in doc) {
        /* istanbul ignore else */
        if (Object.prototype.hasOwnProperty.call(doc, key)) {
          var specialKey = key[0] === '_';
          if (specialKey && !reservedWords[key]) {
            var error = createError(DOC_VALIDATION, key);
            error.message = DOC_VALIDATION.message + ': ' + key;
            throw error;
          } else if (specialKey && !dataWords[key]) {
            result.metadata[key.slice(1)] = doc[key];
          } else {
            result.data[key] = doc[key];
          }
        }
      }
      return result;
    }
    
    // We fetch all leafs of the revision tree, and sort them based on tree length
    // and whether they were deleted, undeleted documents with the longest revision
    // tree (most edits) win
    // The final sort algorithm is slightly documented in a sidebar here:
    // http://guide.couchdb.org/draft/conflicts.html
    function winningRev(metadata) {
      var winningId;
      var winningPos;
      var winningDeleted;
      var toVisit = metadata.rev_tree.slice();
      var node;
      while ((node = toVisit.pop())) {
        var tree = node.ids;
        var branches = tree[2];
        var pos = node.pos;
        if (branches.length) { // non-leaf
          for (var i = 0, len = branches.length; i < len; i++) {
            toVisit.push({pos: pos + 1, ids: branches[i]});
          }
          continue;
        }
        var deleted = !!tree[1].deleted;
        var id = tree[0];
        // sort by deleted, then pos, then id
        if (!winningId || (winningDeleted !== deleted ? winningDeleted :
            winningPos !== pos ? winningPos < pos : winningId < id)) {
          winningId = id;
          winningPos = pos;
          winningDeleted = deleted;
        }
      }
    
      return winningPos + '-' + winningId;
    }
    
    // Pretty much all below can be combined into a higher order function to
    // traverse revisions
    // The return value from the callback will be passed as context to all
    // children of that node
    function traverseRevTree(revs, callback) {
      var toVisit = revs.slice();
    
      var node;
      while ((node = toVisit.pop())) {
        var pos = node.pos;
        var tree = node.ids;
        var branches = tree[2];
        var newCtx =
          callback(branches.length === 0, pos, tree[0], node.ctx, tree[1]);
        for (var i = 0, len = branches.length; i < len; i++) {
          toVisit.push({pos: pos + 1, ids: branches[i], ctx: newCtx});
        }
      }
    }
    
    function sortByPos(a, b) {
      return a.pos - b.pos;
    }
    
    function collectLeaves(revs) {
      var leaves = [];
      traverseRevTree(revs, function (isLeaf, pos, id, acc, opts) {
        if (isLeaf) {
          leaves.push({rev: pos + "-" + id, pos: pos, opts: opts});
        }
      });
      leaves.sort(sortByPos).reverse();
      for (var i = 0, len = leaves.length; i < len; i++) {
        delete leaves[i].pos;
      }
      return leaves;
    }
    
    // returns revs of all conflicts that is leaves such that
    // 1. are not deleted and
    // 2. are different than winning revision
    function collectConflicts(metadata) {
      var win = winningRev(metadata);
      var leaves = collectLeaves(metadata.rev_tree);
      var conflicts = [];
      for (var i = 0, len = leaves.length; i < len; i++) {
        var leaf = leaves[i];
        if (leaf.rev !== win && !leaf.opts.deleted) {
          conflicts.push(leaf.rev);
        }
      }
      return conflicts;
    }
    
    // compact a tree by marking its non-leafs as missing,
    // and return a list of revs to delete
    function compactTree(metadata) {
      var revs = [];
      traverseRevTree(metadata.rev_tree, function (isLeaf, pos,
                                                   revHash, ctx, opts) {
        if (opts.status === 'available' && !isLeaf) {
          revs.push(pos + '-' + revHash);
          opts.status = 'missing';
        }
      });
      return revs;
    }
    
    // build up a list of all the paths to the leafs in this revision tree
    function rootToLeaf(revs) {
      var paths = [];
      var toVisit = revs.slice();
      var node;
      while ((node = toVisit.pop())) {
        var pos = node.pos;
        var tree = node.ids;
        var id = tree[0];
        var opts = tree[1];
        var branches = tree[2];
        var isLeaf = branches.length === 0;
    
        var history = node.history ? node.history.slice() : [];
        history.push({id: id, opts: opts});
        if (isLeaf) {
          paths.push({pos: (pos + 1 - history.length), ids: history});
        }
        for (var i = 0, len = branches.length; i < len; i++) {
          toVisit.push({pos: pos + 1, ids: branches[i], history: history});
        }
      }
      return paths.reverse();
    }
    
    // for a better overview of what this is doing, read:
    
    function sortByPos$1(a, b) {
      return a.pos - b.pos;
    }
    
    // classic binary search
    function binarySearch(arr, item, comparator) {
      var low = 0;
      var high = arr.length;
      var mid;
      while (low < high) {
        mid = (low + high) >>> 1;
        if (comparator(arr[mid], item) < 0) {
          low = mid + 1;
        } else {
          high = mid;
        }
      }
      return low;
    }
    
    // assuming the arr is sorted, insert the item in the proper place
    function insertSorted(arr, item, comparator) {
      var idx = binarySearch(arr, item, comparator);
      arr.splice(idx, 0, item);
    }
    
    // Turn a path as a flat array into a tree with a single branch.
    // If any should be stemmed from the beginning of the array, that's passed
    // in as the second argument
    function pathToTree(path, numStemmed) {
      var root;
      var leaf;
      for (var i = numStemmed, len = path.length; i < len; i++) {
        var node = path[i];
        var currentLeaf = [node.id, node.opts, []];
        if (leaf) {
          leaf[2].push(currentLeaf);
          leaf = currentLeaf;
        } else {
          root = leaf = currentLeaf;
        }
      }
      return root;
    }
    
    // compare the IDs of two trees
    function compareTree(a, b) {
      return a[0] < b[0] ? -1 : 1;
    }
    
    // Merge two trees together
    // The roots of tree1 and tree2 must be the same revision
    function mergeTree(in_tree1, in_tree2) {
      var queue = [{tree1: in_tree1, tree2: in_tree2}];
      var conflicts = false;
      while (queue.length > 0) {
        var item = queue.pop();
        var tree1 = item.tree1;
        var tree2 = item.tree2;
    
        if (tree1[1].status || tree2[1].status) {
          tree1[1].status =
            (tree1[1].status ===  'available' ||
            tree2[1].status === 'available') ? 'available' : 'missing';
        }
    
        for (var i = 0; i < tree2[2].length; i++) {
          if (!tree1[2][0]) {
            conflicts = 'new_leaf';
            tree1[2][0] = tree2[2][i];
            continue;
          }
    
          var merged = false;
          for (var j = 0; j < tree1[2].length; j++) {
            if (tree1[2][j][0] === tree2[2][i][0]) {
              queue.push({tree1: tree1[2][j], tree2: tree2[2][i]});
              merged = true;
            }
          }
          if (!merged) {
            conflicts = 'new_branch';
            insertSorted(tree1[2], tree2[2][i], compareTree);
          }
        }
      }
      return {conflicts: conflicts, tree: in_tree1};
    }
    
    function doMerge(tree, path, dontExpand) {
      var restree = [];
      var conflicts = false;
      var merged = false;
      var res;
    
      if (!tree.length) {
        return {tree: [path], conflicts: 'new_leaf'};
      }
    
      for (var i = 0, len = tree.length; i < len; i++) {
        var branch = tree[i];
        if (branch.pos === path.pos && branch.ids[0] === path.ids[0]) {
          // Paths start at the same position and have the same root, so they need
          // merged
          res = mergeTree(branch.ids, path.ids);
          restree.push({pos: branch.pos, ids: res.tree});
          conflicts = conflicts || res.conflicts;
          merged = true;
        } else if (dontExpand !== true) {
          // The paths start at a different position, take the earliest path and
          // traverse up until it as at the same point from root as the path we
          // want to merge.  If the keys match we return the longer path with the
          // other merged After stemming we dont want to expand the trees
    
          var t1 = branch.pos < path.pos ? branch : path;
          var t2 = branch.pos < path.pos ? path : branch;
          var diff = t2.pos - t1.pos;
    
          var candidateParents = [];
    
          var trees = [];
          trees.push({ids: t1.ids, diff: diff, parent: null, parentIdx: null});
          while (trees.length > 0) {
            var item = trees.pop();
            if (item.diff === 0) {
              if (item.ids[0] === t2.ids[0]) {
                candidateParents.push(item);
              }
              continue;
            }
            var elements = item.ids[2];
            for (var j = 0, elementsLen = elements.length; j < elementsLen; j++) {
              trees.push({
                ids: elements[j],
                diff: item.diff - 1,
                parent: item.ids,
                parentIdx: j
              });
            }
          }
    
          var el = candidateParents[0];
    
          if (!el) {
            restree.push(branch);
          } else {
            res = mergeTree(el.ids, t2.ids);
            el.parent[2][el.parentIdx] = res.tree;
            restree.push({pos: t1.pos, ids: t1.ids});
            conflicts = conflicts || res.conflicts;
            merged = true;
          }
        } else {
          restree.push(branch);
        }
      }
    
      // We didnt find
      if (!merged) {
        restree.push(path);
      }
    
      restree.sort(sortByPos$1);
    
      return {
        tree: restree,
        conflicts: conflicts || 'internal_node'
      };
    }
    
    // To ensure we dont grow the revision tree infinitely, we stem old revisions
    function stem(tree, depth) {
      // First we break out the tree into a complete list of root to leaf paths
      var paths = rootToLeaf(tree);
      var stemmedRevs;
    
      var result;
      for (var i = 0, len = paths.length; i < len; i++) {
        // Then for each path, we cut off the start of the path based on the
        // `depth` to stem to, and generate a new set of flat trees
        var path = paths[i];
        var stemmed = path.ids;
        var node;
        if (stemmed.length > depth) {
          // only do the stemming work if we actually need to stem
          if (!stemmedRevs) {
            stemmedRevs = {}; // avoid allocating this object unnecessarily
          }
          var numStemmed = stemmed.length - depth;
          node = {
            pos: path.pos + numStemmed,
            ids: pathToTree(stemmed, numStemmed)
          };
    
          for (var s = 0; s < numStemmed; s++) {
            var rev = (path.pos + s) + '-' + stemmed[s].id;
            stemmedRevs[rev] = true;
          }
        } else { // no need to actually stem
          node = {
            pos: path.pos,
            ids: pathToTree(stemmed, 0)
          };
        }
    
        // Then we remerge all those flat trees together, ensuring that we dont
        // connect trees that would go beyond the depth limit
        if (result) {
          result = doMerge(result, node, true).tree;
        } else {
          result = [node];
        }
      }
    
      // this is memory-heavy per Chrome profiler, avoid unless we actually stemmed
      if (stemmedRevs) {
        traverseRevTree(result, function (isLeaf, pos, revHash) {
          // some revisions may have been removed in a branch but not in another
          delete stemmedRevs[pos + '-' + revHash];
        });
      }
    
      return {
        tree: result,
        revs: stemmedRevs ? Object.keys(stemmedRevs) : []
      };
    }
    
    function merge(tree, path, depth) {
      var newTree = doMerge(tree, path);
      var stemmed = stem(newTree.tree, depth);
      return {
        tree: stemmed.tree,
        stemmedRevs: stemmed.revs,
        conflicts: newTree.conflicts
      };
    }
    
    // return true if a rev exists in the rev tree, false otherwise
    function revExists(revs, rev) {
      var toVisit = revs.slice();
      var splitRev = rev.split('-');
      var targetPos = parseInt(splitRev[0], 10);
      var targetId = splitRev[1];
    
      var node;
      while ((node = toVisit.pop())) {
        if (node.pos === targetPos && node.ids[0] === targetId) {
          return true;
        }
        var branches = node.ids[2];
        for (var i = 0, len = branches.length; i < len; i++) {
          toVisit.push({pos: node.pos + 1, ids: branches[i]});
        }
      }
      return false;
    }
    
    function getTrees(node) {
      return node.ids;
    }
    
    // check if a specific revision of a doc has been deleted
    //  - metadata: the metadata object from the doc store
    //  - rev: (optional) the revision to check. defaults to winning revision
    function isDeleted(metadata, rev) {
      if (!rev) {
        rev = winningRev(metadata);
      }
      var id = rev.substring(rev.indexOf('-') + 1);
      var toVisit = metadata.rev_tree.map(getTrees);
    
      var tree;
      while ((tree = toVisit.pop())) {
        if (tree[0] === id) {
          return !!tree[1].deleted;
        }
        toVisit = toVisit.concat(tree[2]);
      }
    }
    
    function isLocalId(id) {
      return (/^_local/).test(id);
    }
    
    // returns the current leaf node for a given revision
    function latest(rev, metadata) {
      var toVisit = metadata.rev_tree.slice();
      var node;
      while ((node = toVisit.pop())) {
        var pos = node.pos;
        var tree = node.ids;
        var id = tree[0];
        var opts = tree[1];
        var branches = tree[2];
        var isLeaf = branches.length === 0;
    
        var history = node.history ? node.history.slice() : [];
        history.push({id: id, pos: pos, opts: opts});
    
        if (isLeaf) {
          for (var i = 0, len = history.length; i < len; i++) {
            var historyNode = history[i];
            var historyRev = historyNode.pos + '-' + historyNode.id;
    
            if (historyRev === rev) {
              // return the rev of this leaf
              return pos + '-' + id;
            }
          }
        }
    
        for (var j = 0, l = branches.length; j < l; j++) {
          toVisit.push({pos: pos + 1, ids: branches[j], history: history});
        }
      }
    
      /* istanbul ignore next */
      throw new Error('Unable to resolve latest revision for id ' + metadata.id + ', rev ' + rev);
    }
    
    function parseBase64(data) {
      try {
        return thisAtob(data);
      } catch (e) {
        var err = createError(BAD_ARG,
          'Attachment is not a valid base64 string');
        return {error: err};
      }
    }
    
    function preprocessString(att, blobType, callback) {
      var asBinary = parseBase64(att.data);
      if (asBinary.error) {
        return callback(asBinary.error);
      }
    
      att.length = asBinary.length;
      if (blobType === 'blob') {
        att.data = binStringToBluffer(asBinary, att.content_type);
      } else if (blobType === 'base64') {
        att.data = thisBtoa(asBinary);
      } else { // binary
        att.data = asBinary;
      }
      binaryMd5(asBinary, function (result) {
        att.digest = 'md5-' + result;
        callback();
      });
    }
    
    function preprocessBlob(att, blobType, callback) {
      binaryMd5(att.data, function (md5) {
        att.digest = 'md5-' + md5;
        // size is for blobs (browser), length is for buffers (node)
        att.length = att.data.size || att.data.length || 0;
        if (blobType === 'binary') {
          blobToBinaryString(att.data, function (binString) {
            att.data = binString;
            callback();
          });
        } else if (blobType === 'base64') {
          blobToBase64(att.data, function (b64) {
            att.data = b64;
            callback();
          });
        } else {
          callback();
        }
      });
    }
    
    function preprocessAttachment(att, blobType, callback) {
      if (att.stub) {
        return callback();
      }
      if (typeof att.data === 'string') { // input is a base64 string
        preprocessString(att, blobType, callback);
      } else { // input is a blob
        preprocessBlob(att, blobType, callback);
      }
    }
    
    function preprocessAttachments(docInfos, blobType, callback) {
    
      if (!docInfos.length) {
        return callback();
      }
    
      var docv = 0;
      var overallErr;
    
      docInfos.forEach(function (docInfo) {
        var attachments = docInfo.data && docInfo.data._attachments ?
          Object.keys(docInfo.data._attachments) : [];
        var recv = 0;
    
        if (!attachments.length) {
          return done();
        }
    
        function processedAttachment(err) {
          overallErr = err;
          recv++;
          if (recv === attachments.length) {
            done();
          }
        }
    
        for (var key in docInfo.data._attachments) {
          if (docInfo.data._attachments.hasOwnProperty(key)) {
            preprocessAttachment(docInfo.data._attachments[key],
              blobType, processedAttachment);
          }
        }
      });
    
      function done() {
        docv++;
        if (docInfos.length === docv) {
          if (overallErr) {
            callback(overallErr);
          } else {
            callback();
          }
        }
      }
    }
    
    function updateDoc(revLimit, prev, docInfo, results,
                       i, cb, writeDoc, newEdits) {
    
      if (revExists(prev.rev_tree, docInfo.metadata.rev) && !newEdits) {
        results[i] = docInfo;
        return cb();
      }
    
      // sometimes this is pre-calculated. historically not always
      var previousWinningRev = prev.winningRev || winningRev(prev);
      var previouslyDeleted = 'deleted' in prev ? prev.deleted :
        isDeleted(prev, previousWinningRev);
      var deleted = 'deleted' in docInfo.metadata ? docInfo.metadata.deleted :
        isDeleted(docInfo.metadata);
      var isRoot = /^1-/.test(docInfo.metadata.rev);
    
      if (previouslyDeleted && !deleted && newEdits && isRoot) {
        var newDoc = docInfo.data;
        newDoc._rev = previousWinningRev;
        newDoc._id = docInfo.metadata.id;
        docInfo = parseDoc(newDoc, newEdits);
      }
    
      var merged = merge(prev.rev_tree, docInfo.metadata.rev_tree[0], revLimit);
    
      var inConflict = newEdits && ((
        (previouslyDeleted && deleted && merged.conflicts !== 'new_leaf') ||
        (!previouslyDeleted && merged.conflicts !== 'new_leaf') ||
        (previouslyDeleted && !deleted && merged.conflicts === 'new_branch')));
    
      if (inConflict) {
        var err = createError(REV_CONFLICT);
        results[i] = err;
        return cb();
      }
    
      var newRev = docInfo.metadata.rev;
      docInfo.metadata.rev_tree = merged.tree;
      docInfo.stemmedRevs = merged.stemmedRevs || [];
      /* istanbul ignore else */
      if (prev.rev_map) {
        docInfo.metadata.rev_map = prev.rev_map; // used only by leveldb
      }
    
      // recalculate
      var winningRev$$1 = winningRev(docInfo.metadata);
      var winningRevIsDeleted = isDeleted(docInfo.metadata, winningRev$$1);
    
      // calculate the total number of documents that were added/removed,
      // from the perspective of total_rows/doc_count
      var delta = (previouslyDeleted === winningRevIsDeleted) ? 0 :
        previouslyDeleted < winningRevIsDeleted ? -1 : 1;
    
      var newRevIsDeleted;
      if (newRev === winningRev$$1) {
        // if the new rev is the same as the winning rev, we can reuse that value
        newRevIsDeleted = winningRevIsDeleted;
      } else {
        // if they're not the same, then we need to recalculate
        newRevIsDeleted = isDeleted(docInfo.metadata, newRev);
      }
    
      writeDoc(docInfo, winningRev$$1, winningRevIsDeleted, newRevIsDeleted,
        true, delta, i, cb);
    }
    
    function rootIsMissing(docInfo) {
      return docInfo.metadata.rev_tree[0].ids[1].status === 'missing';
    }
    
    function processDocs(revLimit, docInfos, api, fetchedDocs, tx, results,
                         writeDoc, opts, overallCallback) {
    
      // Default to 1000 locally
      revLimit = revLimit || 1000;
    
      function insertDoc(docInfo, resultsIdx, callback) {
        // Cant insert new deleted documents
        var winningRev$$1 = winningRev(docInfo.metadata);
        var deleted = isDeleted(docInfo.metadata, winningRev$$1);
        if ('was_delete' in opts && deleted) {
          results[resultsIdx] = createError(MISSING_DOC, 'deleted');
          return callback();
        }
    
        // 4712 - detect whether a new document was inserted with a _rev
        var inConflict = newEdits && rootIsMissing(docInfo);
    
        if (inConflict) {
          var err = createError(REV_CONFLICT);
          results[resultsIdx] = err;
          return callback();
        }
    
        var delta = deleted ? 0 : 1;
    
        writeDoc(docInfo, winningRev$$1, deleted, deleted, false,
          delta, resultsIdx, callback);
      }
    
      var newEdits = opts.new_edits;
      var idsToDocs = new ExportedMap();
    
      var docsDone = 0;
      var docsToDo = docInfos.length;
    
      function checkAllDocsDone() {
        if (++docsDone === docsToDo && overallCallback) {
          overallCallback();
        }
      }
    
      docInfos.forEach(function (currentDoc, resultsIdx) {
    
        if (currentDoc._id && isLocalId(currentDoc._id)) {
          var fun = currentDoc._deleted ? '_removeLocal' : '_putLocal';
          api[fun](currentDoc, {ctx: tx}, function (err, res) {
            results[resultsIdx] = err || res;
            checkAllDocsDone();
          });
          return;
        }
    
        var id = currentDoc.metadata.id;
        if (idsToDocs.has(id)) {
          docsToDo--; // duplicate
          idsToDocs.get(id).push([currentDoc, resultsIdx]);
        } else {
          idsToDocs.set(id, [[currentDoc, resultsIdx]]);
        }
      });
    
      // in the case of new_edits, the user can provide multiple docs
      // with the same id. these need to be processed sequentially
      idsToDocs.forEach(function (docs, id) {
        var numDone = 0;
    
        function docWritten() {
          if (++numDone < docs.length) {
            nextDoc();
          } else {
            checkAllDocsDone();
          }
        }
        function nextDoc() {
          var value = docs[numDone];
          var currentDoc = value[0];
          var resultsIdx = value[1];
    
          if (fetchedDocs.has(id)) {
            updateDoc(revLimit, fetchedDocs.get(id), currentDoc, results,
              resultsIdx, docWritten, writeDoc, newEdits);
          } else {
            // Ensure stemming applies to new writes as well
            var merged = merge([], currentDoc.metadata.rev_tree[0], revLimit);
            currentDoc.metadata.rev_tree = merged.tree;
            currentDoc.stemmedRevs = merged.stemmedRevs || [];
            insertDoc(currentDoc, resultsIdx, docWritten);
          }
        }
        nextDoc();
      });
    }
    
    function safeJsonParse(str) {
      // This try/catch guards against stack overflow errors.
      // JSON.parse() is faster than vuvuzela.parse() but vuvuzela
      // cannot overflow.
      try {
        return JSON.parse(str);
      } catch (e) {
        /* istanbul ignore next */
        return vuvuzela.parse(str);
      }
    }
    
    function safeJsonStringify(json) {
      try {
        return JSON.stringify(json);
      } catch (e) {
        /* istanbul ignore next */
        return vuvuzela.stringify(json);
      }
    }
    
    //
    // Parsing hex strings. Yeah.
    //
    // So basically we need this because of a bug in WebSQL:
    // https://code.google.com/p/chromium/issues/detail?id=422690
    // https://bugs.webkit.org/show_bug.cgi?id=137637
    //
    // UTF-8 and UTF-16 are provided as separate functions
    // for meager performance improvements
    //
    
    function decodeUtf8(str) {
      return decodeURIComponent(escape(str));
    }
    
    function hexToInt(charCode) {
      // '0'-'9' is 48-57
      // 'A'-'F' is 65-70
      // SQLite will only give us uppercase hex
      return charCode < 65 ? (charCode - 48) : (charCode - 55);
    }
    
    
    // Example:
    // pragma encoding=utf8;
    // select hex('A');
    // returns '41'
    function parseHexUtf8(str, start, end) {
      var result = '';
      while (start < end) {
        result += String.fromCharCode(
          (hexToInt(str.charCodeAt(start++)) << 4) |
            hexToInt(str.charCodeAt(start++)));
      }
      return result;
    }
    
    // Example:
    // pragma encoding=utf16;
    // select hex('A');
    // returns '4100'
    // notice that the 00 comes after the 41 (i.e. it's swizzled)
    function parseHexUtf16(str, start, end) {
      var result = '';
      while (start < end) {
        // UTF-16, so swizzle the bytes
        result += String.fromCharCode(
          (hexToInt(str.charCodeAt(start + 2)) << 12) |
            (hexToInt(str.charCodeAt(start + 3)) << 8) |
            (hexToInt(str.charCodeAt(start)) << 4) |
            hexToInt(str.charCodeAt(start + 1)));
        start += 4;
      }
      return result;
    }
    
    function parseHexString(str, encoding) {
      if (encoding === 'UTF-8') {
        return decodeUtf8(parseHexUtf8(str, 0, str.length));
      } else {
        return parseHexUtf16(str, 0, str.length);
      }
    }
    
    function quote(str) {
      return "'" + str + "'";
    }
    
    var ADAPTER_VERSION = 7; // used to manage migrations
    
    // The object stores created for each database
    // DOC_STORE stores the document meta data, its revision history and state
    var DOC_STORE = quote('document-store');
    // BY_SEQ_STORE stores a particular version of a document, keyed by its
    // sequence id
    var BY_SEQ_STORE = quote('by-sequence');
    // Where we store attachments
    var ATTACH_STORE = quote('attach-store');
    var LOCAL_STORE = quote('local-store');
    var META_STORE = quote('metadata-store');
    // where we store many-to-many relations between attachment
    // digests and seqs
    var ATTACH_AND_SEQ_STORE = quote('attach-seq-store');
    
    // escapeBlob and unescapeBlob are workarounds for a websql bug:
    // https://code.google.com/p/chromium/issues/detail?id=422690
    // https://bugs.webkit.org/show_bug.cgi?id=137637
    // The goal is to never actually insert the \u0000 character
    // in the database.
    function escapeBlob(str) {
      /* eslint-disable no-control-regex */
      return str
        .replace(/\u0002/g, '\u0002\u0002')
        .replace(/\u0001/g, '\u0001\u0002')
        .replace(/\u0000/g, '\u0001\u0001');
      /* eslint-enable no-control-regex */
    }
    
    function unescapeBlob(str) {
      /* eslint-disable no-control-regex */
      return str
        .replace(/\u0001\u0001/g, '\u0000')
        .replace(/\u0001\u0002/g, '\u0001')
        .replace(/\u0002\u0002/g, '\u0002');
      /* eslint-enable no-control-regex */
    }
    
    function stringifyDoc(doc) {
      // don't bother storing the id/rev. it uses lots of space,
      // in persistent map/reduce especially
      delete doc._id;
      delete doc._rev;
      return JSON.stringify(doc);
    }
    
    function unstringifyDoc(doc, id, rev) {
      doc = JSON.parse(doc);
      doc._id = id;
      doc._rev = rev;
      return doc;
    }
    
    // question mark groups IN queries, e.g. 3 -> '(?,?,?)'
    function qMarks(num) {
      var s = '(';
      while (num--) {
        s += '?';
        if (num) {
          s += ',';
        }
      }
      return s + ')';
    }
    
    function select(selector, table, joiner, where, orderBy) {
      return 'SELECT ' + selector + ' FROM ' +
        (typeof table === 'string' ? table : table.join(' JOIN ')) +
        (joiner ? (' ON ' + joiner) : '') +
        (where ? (' WHERE ' +
        (typeof where === 'string' ? where : where.join(' AND '))) : '') +
        (orderBy ? (' ORDER BY ' + orderBy) : '');
    }
    
    function compactRevs(revs, docId, tx) {
    
      if (!revs.length) {
        return;
      }
    
      var numDone = 0;
      var seqs = [];
    
      function checkDone() {
        if (++numDone === revs.length) { // done
          deleteOrphans();
        }
      }
    
      function deleteOrphans() {
        // find orphaned attachment digests
    
        if (!seqs.length) {
          return;
        }
    
        var sql = 'SELECT DISTINCT digest AS digest FROM ' +
          ATTACH_AND_SEQ_STORE + ' WHERE seq IN ' + qMarks(seqs.length);
    
        tx.executeSql(sql, seqs, function (tx, res) {
    
          var digestsToCheck = [];
          for (var i = 0; i < res.rows.length; i++) {
            digestsToCheck.push(res.rows.item(i).digest);
          }
          if (!digestsToCheck.length) {
            return;
          }
    
          var sql = 'DELETE FROM ' + ATTACH_AND_SEQ_STORE +
            ' WHERE seq IN (' +
            seqs.map(function () { return '?'; }).join(',') +
            ')';
          tx.executeSql(sql, seqs, function (tx) {
    
            var sql = 'SELECT digest FROM ' + ATTACH_AND_SEQ_STORE +
              ' WHERE digest IN (' +
              digestsToCheck.map(function () { return '?'; }).join(',') +
              ')';
            tx.executeSql(sql, digestsToCheck, function (tx, res) {
              var nonOrphanedDigests = new ExportedSet();
              for (var i = 0; i < res.rows.length; i++) {
                nonOrphanedDigests.add(res.rows.item(i).digest);
              }
              digestsToCheck.forEach(function (digest) {
                if (nonOrphanedDigests.has(digest)) {
                  return;
                }
                tx.executeSql(
                  'DELETE FROM ' + ATTACH_AND_SEQ_STORE + ' WHERE digest=?',
                  [digest]);
                tx.executeSql(
                  'DELETE FROM ' + ATTACH_STORE + ' WHERE digest=?', [digest]);
              });
            });
          });
        });
      }
    
      // update by-seq and attach stores in parallel
      revs.forEach(function (rev) {
        var sql = 'SELECT seq FROM ' + BY_SEQ_STORE +
          ' WHERE doc_id=? AND rev=?';
    
        tx.executeSql(sql, [docId, rev], function (tx, res) {
          if (!res.rows.length) { // already deleted
            return checkDone();
          }
          var seq = res.rows.item(0).seq;
          seqs.push(seq);
    
          tx.executeSql(
            'DELETE FROM ' + BY_SEQ_STORE + ' WHERE seq=?', [seq], checkDone);
        });
      });
    }
    
    function websqlError(callback) {
      return function (event) {
        guardedConsole('error', 'WebSQL threw an error', event);
        // event may actually be a SQLError object, so report is as such
        var errorNameMatch = event && event.constructor.toString()
            .match(/function ([^(]+)/);
        var errorName = (errorNameMatch && errorNameMatch[1]) || event.type;
        var errorReason = event.target || event.message;
        callback(createError(WSQ_ERROR, errorReason, errorName));
      };
    }
    
    function getSize(opts) {
      if ('size' in opts) {
        // triggers immediate popup in iOS, fixes #2347
        // e.g. 5000001 asks for 5 MB, 10000001 asks for 10 MB,
        return opts.size * 1000000;
      }
      // In iOS, doesn't matter as long as it's <= 5000000.
      // Except that if you request too much, our tests fail
      // because of the native "do you accept?" popup.
      // In Android <=4.3, this value is actually used as an
      // honest-to-god ceiling for data, so we need to
      // set it to a decently high number.
      var isAndroid = typeof navigator !== 'undefined' &&
        /Android/.test(navigator.userAgent);
      return isAndroid ? 5000000 : 1; // in PhantomJS, if you use 0 it will crash
    }
    
    function websqlBulkDocs(dbOpts, req, opts, api, db, websqlChanges, callback) {
      var newEdits = opts.new_edits;
      var userDocs = req.docs;
    
      // Parse the docs, give them a sequence number for the result
      var docInfos = userDocs.map(function (doc) {
        if (doc._id && isLocalId(doc._id)) {
          return doc;
        }
        var newDoc = parseDoc(doc, newEdits, dbOpts);
        return newDoc;
      });
    
      var docInfoErrors = docInfos.filter(function (docInfo) {
        return docInfo.error;
      });
      if (docInfoErrors.length) {
        return callback(docInfoErrors[0]);
      }
    
      var tx;
      var results = new Array(docInfos.length);
      var fetchedDocs = new ExportedMap();
    
      var preconditionErrored;
      function complete() {
        if (preconditionErrored) {
          return callback(preconditionErrored);
        }
        websqlChanges.notify(api._name);
        callback(null, results);
      }
    
      function verifyAttachment(digest, callback) {
        var sql = 'SELECT count(*) as cnt FROM ' + ATTACH_STORE +
          ' WHERE digest=?';
        tx.executeSql(sql, [digest], function (tx, result) {
          if (result.rows.item(0).cnt === 0) {
            var err = createError(MISSING_STUB,
              'unknown stub attachment with digest ' +
              digest);
            callback(err);
          } else {
            callback();
          }
        });
      }
    
      function verifyAttachments(finish) {
        var digests = [];
        docInfos.forEach(function (docInfo) {
          if (docInfo.data && docInfo.data._attachments) {
            Object.keys(docInfo.data._attachments).forEach(function (filename) {
              var att = docInfo.data._attachments[filename];
              if (att.stub) {
                digests.push(att.digest);
              }
            });
          }
        });
        if (!digests.length) {
          return finish();
        }
        var numDone = 0;
        var err;
    
        function checkDone() {
          if (++numDone === digests.length) {
            finish(err);
          }
        }
        digests.forEach(function (digest) {
          verifyAttachment(digest, function (attErr) {
            if (attErr && !err) {
              err = attErr;
            }
            checkDone();
          });
        });
      }
    
      function writeDoc(docInfo, winningRev$$1, winningRevIsDeleted, newRevIsDeleted,
                        isUpdate, delta, resultsIdx, callback) {
    
        function finish() {
          var data = docInfo.data;
          var deletedInt = newRevIsDeleted ? 1 : 0;
    
          var id = data._id;
          var rev = data._rev;
          var json = stringifyDoc(data);
          var sql = 'INSERT INTO ' + BY_SEQ_STORE +
            ' (doc_id, rev, json, deleted) VALUES (?, ?, ?, ?);';
          var sqlArgs = [id, rev, json, deletedInt];
    
          // map seqs to attachment digests, which
          // we will need later during compaction
          function insertAttachmentMappings(seq, callback) {
            var attsAdded = 0;
            var attsToAdd = Object.keys(data._attachments || {});
    
            if (!attsToAdd.length) {
              return callback();
            }
            function checkDone() {
              if (++attsAdded === attsToAdd.length) {
                callback();
              }
              return false; // ack handling a constraint error
            }
            function add(att) {
              var sql = 'INSERT INTO ' + ATTACH_AND_SEQ_STORE +
                ' (digest, seq) VALUES (?,?)';
              var sqlArgs = [data._attachments[att].digest, seq];
              tx.executeSql(sql, sqlArgs, checkDone, checkDone);
              // second callback is for a constaint error, which we ignore
              // because this docid/rev has already been associated with
              // the digest (e.g. when new_edits == false)
            }
            for (var i = 0; i < attsToAdd.length; i++) {
              add(attsToAdd[i]); // do in parallel
            }
          }
    
          tx.executeSql(sql, sqlArgs, function (tx, result) {
            var seq = result.insertId;
            insertAttachmentMappings(seq, function () {
              dataWritten(tx, seq);
            });
          }, function () {
            // constraint error, recover by updating instead (see #1638)
            var fetchSql = select('seq', BY_SEQ_STORE, null,
              'doc_id=? AND rev=?');
            tx.executeSql(fetchSql, [id, rev], function (tx, res) {
              var seq = res.rows.item(0).seq;
              var sql = 'UPDATE ' + BY_SEQ_STORE +
                ' SET json=?, deleted=? WHERE doc_id=? AND rev=?;';
              var sqlArgs = [json, deletedInt, id, rev];
              tx.executeSql(sql, sqlArgs, function (tx) {
                insertAttachmentMappings(seq, function () {
                  dataWritten(tx, seq);
                });
              });
            });
            return false; // ack that we've handled the error
          });
        }
    
        function collectResults(attachmentErr) {
          if (!err) {
            if (attachmentErr) {
              err = attachmentErr;
              callback(err);
            } else if (recv === attachments.length) {
              finish();
            }
          }
        }
    
        var err = null;
        var recv = 0;
    
        docInfo.data._id = docInfo.metadata.id;
        docInfo.data._rev = docInfo.metadata.rev;
        var attachments = Object.keys(docInfo.data._attachments || {});
    
    
        if (newRevIsDeleted) {
          docInfo.data._deleted = true;
        }
    
        function attachmentSaved(err) {
          recv++;
          collectResults(err);
        }
    
        attachments.forEach(function (key) {
          var att = docInfo.data._attachments[key];
          if (!att.stub) {
            var data = att.data;
            delete att.data;
            att.revpos = parseInt(winningRev$$1, 10);
            var digest = att.digest;
            saveAttachment(digest, data, attachmentSaved);
          } else {
            recv++;
            collectResults();
          }
        });
    
        if (!attachments.length) {
          finish();
        }
    
        function dataWritten(tx, seq) {
          var id = docInfo.metadata.id;
    
          var revsToCompact = docInfo.stemmedRevs || [];
          if (isUpdate && api.auto_compaction) {
            revsToCompact = compactTree(docInfo.metadata).concat(revsToCompact);
          }
          if (revsToCompact.length) {
            compactRevs(revsToCompact, id, tx);
          }
    
          docInfo.metadata.seq = seq;
          var rev = docInfo.metadata.rev;
          delete docInfo.metadata.rev;
    
          var sql = isUpdate ?
          'UPDATE ' + DOC_STORE +
          ' SET json=?, max_seq=?, winningseq=' +
          '(SELECT seq FROM ' + BY_SEQ_STORE +
          ' WHERE doc_id=' + DOC_STORE + '.id AND rev=?) WHERE id=?'
            : 'INSERT INTO ' + DOC_STORE +
          ' (id, winningseq, max_seq, json) VALUES (?,?,?,?);';
          var metadataStr = safeJsonStringify(docInfo.metadata);
          var params = isUpdate ?
            [metadataStr, seq, winningRev$$1, id] :
            [id, seq, seq, metadataStr];
          tx.executeSql(sql, params, function () {
            results[resultsIdx] = {
              ok: true,
              id: docInfo.metadata.id,
              rev: rev
            };
            fetchedDocs.set(id, docInfo.metadata);
            callback();
          });
        }
      }
    
      function websqlProcessDocs() {
        processDocs(dbOpts.revs_limit, docInfos, api, fetchedDocs, tx,
                    results, writeDoc, opts);
      }
    
      function fetchExistingDocs(callback) {
        if (!docInfos.length) {
          return callback();
        }
    
        var numFetched = 0;
    
        function checkDone() {
          if (++numFetched === docInfos.length) {
            callback();
          }
        }
    
        docInfos.forEach(function (docInfo) {
          if (docInfo._id && isLocalId(docInfo._id)) {
            return checkDone(); // skip local docs
          }
          var id = docInfo.metadata.id;
          tx.executeSql('SELECT json FROM ' + DOC_STORE +
          ' WHERE id = ?', [id], function (tx, result) {
            if (result.rows.length) {
              var metadata = safeJsonParse(result.rows.item(0).json);
              fetchedDocs.set(id, metadata);
            }
            checkDone();
          });
        });
      }
    
      function saveAttachment(digest, data, callback) {
        var sql = 'SELECT digest FROM ' + ATTACH_STORE + ' WHERE digest=?';
        tx.executeSql(sql, [digest], function (tx, result) {
          if (result.rows.length) { // attachment already exists
            return callback();
          }
          // we could just insert before selecting and catch the error,
          // but my hunch is that it's cheaper not to serialize the blob
          // from JS to C if we don't have to (TODO: confirm this)
          sql = 'INSERT INTO ' + ATTACH_STORE +
          ' (digest, body, escaped) VALUES (?,?,1)';
          tx.executeSql(sql, [digest, escapeBlob(data)], function () {
            callback();
          }, function () {
            // ignore constaint errors, means it already exists
            callback();
            return false; // ack we handled the error
          });
        });
      }
    
      preprocessAttachments(docInfos, 'binary', function (err) {
        if (err) {
          return callback(err);
        }
        db.transaction(function (txn) {
          tx = txn;
          verifyAttachments(function (err) {
            if (err) {
              preconditionErrored = err;
            } else {
              fetchExistingDocs(websqlProcessDocs);
            }
          });
        }, websqlError(callback), complete);
      });
    }
    
    var cachedDatabases = new ExportedMap();
    
    // openDatabase passed in through opts (e.g. for node-websql)
    function openDatabaseWithOpts(opts) {
      return opts.websql(opts.name, opts.version, opts.description, opts.size);
    }
    
    function openDBSafely(opts) {
      try {
        return {
          db: openDatabaseWithOpts(opts)
        };
      } catch (err) {
        return {
          error: err
        };
      }
    }
    
    function openDB(opts) {
      var cachedResult = cachedDatabases.get(opts.name);
      if (!cachedResult) {
        cachedResult = openDBSafely(opts);
        cachedDatabases.set(opts.name, cachedResult);
      }
      return cachedResult;
    }
    
    var websqlChanges = new Changes();
    
    function fetchAttachmentsIfNecessary(doc, opts, api, txn, cb) {
      var attachments = Object.keys(doc._attachments || {});
      if (!attachments.length) {
        return cb && cb();
      }
      var numDone = 0;
    
      function checkDone() {
        if (++numDone === attachments.length && cb) {
          cb();
        }
      }
    
      function fetchAttachment(doc, att) {
        var attObj = doc._attachments[att];
        var attOpts = {binary: opts.binary, ctx: txn};
        api._getAttachment(doc._id, att, attObj, attOpts, function (_, data) {
          doc._attachments[att] = $inject_Object_assign(
            pick(attObj, ['digest', 'content_type']),
            { data: data }
          );
          checkDone();
        });
      }
    
      attachments.forEach(function (att) {
        if (opts.attachments && opts.include_docs) {
          fetchAttachment(doc, att);
        } else {
          doc._attachments[att].stub = true;
          checkDone();
        }
      });
    }
    
    var POUCH_VERSION = 1;
    
    // these indexes cover the ground for most allDocs queries
    var BY_SEQ_STORE_DELETED_INDEX_SQL =
      'CREATE INDEX IF NOT EXISTS \'by-seq-deleted-idx\' ON ' +
      BY_SEQ_STORE + ' (seq, deleted)';
    var BY_SEQ_STORE_DOC_ID_REV_INDEX_SQL =
      'CREATE UNIQUE INDEX IF NOT EXISTS \'by-seq-doc-id-rev\' ON ' +
        BY_SEQ_STORE + ' (doc_id, rev)';
    var DOC_STORE_WINNINGSEQ_INDEX_SQL =
      'CREATE INDEX IF NOT EXISTS \'doc-winningseq-idx\' ON ' +
      DOC_STORE + ' (winningseq)';
    var ATTACH_AND_SEQ_STORE_SEQ_INDEX_SQL =
      'CREATE INDEX IF NOT EXISTS \'attach-seq-seq-idx\' ON ' +
        ATTACH_AND_SEQ_STORE + ' (seq)';
    var ATTACH_AND_SEQ_STORE_ATTACH_INDEX_SQL =
      'CREATE UNIQUE INDEX IF NOT EXISTS \'attach-seq-digest-idx\' ON ' +
        ATTACH_AND_SEQ_STORE + ' (digest, seq)';
    
    var DOC_STORE_AND_BY_SEQ_JOINER = BY_SEQ_STORE +
      '.seq = ' + DOC_STORE + '.winningseq';
    
    var SELECT_DOCS = BY_SEQ_STORE + '.seq AS seq, ' +
      BY_SEQ_STORE + '.deleted AS deleted, ' +
      BY_SEQ_STORE + '.json AS data, ' +
      BY_SEQ_STORE + '.rev AS rev, ' +
      DOC_STORE + '.json AS metadata';
    
    function WebSqlPouch(opts, callback) {
      var api = this;
      var instanceId = null;
      var size = getSize(opts);
      var idRequests = [];
      var encoding;
    
      api._name = opts.name;
    
      // extend the options here, because sqlite plugin has a ton of options
      // and they are constantly changing, so it's more prudent to allow anything
      var websqlOpts = $inject_Object_assign({}, opts, {
        version: POUCH_VERSION,
        description: opts.name,
        size: size
      });
      var openDBResult = openDB(websqlOpts);
      if (openDBResult.error) {
        return websqlError(callback)(openDBResult.error);
      }
      var db = openDBResult.db;
      if (typeof db.readTransaction !== 'function') {
        // doesn't exist in sqlite plugin
        db.readTransaction = db.transaction;
      }
    
      function dbCreated() {
        // note the db name in case the browser upgrades to idb
        if (hasLocalStorage()) {
          window.localStorage['_pouch__websqldb_' + api._name] = true;
        }
        callback(null, api);
      }
    
      // In this migration, we added the 'deleted' and 'local' columns to the
      // by-seq and doc store tables.
      // To preserve existing user data, we re-process all the existing JSON
      // and add these values.
      // Called migration2 because it corresponds to adapter version (db_version) #2
      function runMigration2(tx, callback) {
        // index used for the join in the allDocs query
        tx.executeSql(DOC_STORE_WINNINGSEQ_INDEX_SQL);
    
        tx.executeSql('ALTER TABLE ' + BY_SEQ_STORE +
          ' ADD COLUMN deleted TINYINT(1) DEFAULT 0', [], function () {
          tx.executeSql(BY_SEQ_STORE_DELETED_INDEX_SQL);
          tx.executeSql('ALTER TABLE ' + DOC_STORE +
            ' ADD COLUMN local TINYINT(1) DEFAULT 0', [], function () {
            tx.executeSql('CREATE INDEX IF NOT EXISTS \'doc-store-local-idx\' ON ' +
              DOC_STORE + ' (local, id)');
    
            var sql = 'SELECT ' + DOC_STORE + '.winningseq AS seq, ' + DOC_STORE +
              '.json AS metadata FROM ' + BY_SEQ_STORE + ' JOIN ' + DOC_STORE +
              ' ON ' + BY_SEQ_STORE + '.seq = ' + DOC_STORE + '.winningseq';
    
            tx.executeSql(sql, [], function (tx, result) {
    
              var deleted = [];
              var local = [];
    
              for (var i = 0; i < result.rows.length; i++) {
                var item = result.rows.item(i);
                var seq = item.seq;
                var metadata = JSON.parse(item.metadata);
                if (isDeleted(metadata)) {
                  deleted.push(seq);
                }
                if (isLocalId(metadata.id)) {
                  local.push(metadata.id);
                }
              }
              tx.executeSql('UPDATE ' + DOC_STORE + 'SET local = 1 WHERE id IN ' +
                qMarks(local.length), local, function () {
                tx.executeSql('UPDATE ' + BY_SEQ_STORE +
                  ' SET deleted = 1 WHERE seq IN ' +
                  qMarks(deleted.length), deleted, callback);
              });
            });
          });
        });
      }
    
      // in this migration, we make all the local docs unversioned
      function runMigration3(tx, callback) {
        var local = 'CREATE TABLE IF NOT EXISTS ' + LOCAL_STORE +
          ' (id UNIQUE, rev, json)';
        tx.executeSql(local, [], function () {
          var sql = 'SELECT ' + DOC_STORE + '.id AS id, ' +
            BY_SEQ_STORE + '.json AS data ' +
            'FROM ' + BY_SEQ_STORE + ' JOIN ' +
            DOC_STORE + ' ON ' + BY_SEQ_STORE + '.seq = ' +
            DOC_STORE + '.winningseq WHERE local = 1';
          tx.executeSql(sql, [], function (tx, res) {
            var rows = [];
            for (var i = 0; i < res.rows.length; i++) {
              rows.push(res.rows.item(i));
            }
            function doNext() {
              if (!rows.length) {
                return callback(tx);
              }
              var row = rows.shift();
              var rev = JSON.parse(row.data)._rev;
              tx.executeSql('INSERT INTO ' + LOCAL_STORE +
                  ' (id, rev, json) VALUES (?,?,?)',
                  [row.id, rev, row.data], function (tx) {
                tx.executeSql('DELETE FROM ' + DOC_STORE + ' WHERE id=?',
                    [row.id], function (tx) {
                  tx.executeSql('DELETE FROM ' + BY_SEQ_STORE + ' WHERE seq=?',
                      [row.seq], function () {
                    doNext();
                  });
                });
              });
            }
            doNext();
          });
        });
      }
    
      // in this migration, we remove doc_id_rev and just use rev
      function runMigration4(tx, callback) {
    
        function updateRows(rows) {
          function doNext() {
            if (!rows.length) {
              return callback(tx);
            }
            var row = rows.shift();
            var doc_id_rev = parseHexString(row.hex, encoding);
            var idx = doc_id_rev.lastIndexOf('::');
            var doc_id = doc_id_rev.substring(0, idx);
            var rev = doc_id_rev.substring(idx + 2);
            var sql = 'UPDATE ' + BY_SEQ_STORE +
              ' SET doc_id=?, rev=? WHERE doc_id_rev=?';
            tx.executeSql(sql, [doc_id, rev, doc_id_rev], function () {
              doNext();
            });
          }
          doNext();
        }
    
        var sql = 'ALTER TABLE ' + BY_SEQ_STORE + ' ADD COLUMN doc_id';
        tx.executeSql(sql, [], function (tx) {
          var sql = 'ALTER TABLE ' + BY_SEQ_STORE + ' ADD COLUMN rev';
          tx.executeSql(sql, [], function (tx) {
            tx.executeSql(BY_SEQ_STORE_DOC_ID_REV_INDEX_SQL, [], function (tx) {
              var sql = 'SELECT hex(doc_id_rev) as hex FROM ' + BY_SEQ_STORE;
              tx.executeSql(sql, [], function (tx, res) {
                var rows = [];
                for (var i = 0; i < res.rows.length; i++) {
                  rows.push(res.rows.item(i));
                }
                updateRows(rows);
              });
            });
          });
        });
      }
    
      // in this migration, we add the attach_and_seq table
      // for issue #2818
      function runMigration5(tx, callback) {
    
        function migrateAttsAndSeqs(tx) {
          // need to actually populate the table. this is the expensive part,
          // so as an optimization, check first that this database even
          // contains attachments
          var sql = 'SELECT COUNT(*) AS cnt FROM ' + ATTACH_STORE;
          tx.executeSql(sql, [], function (tx, res) {
            var count = res.rows.item(0).cnt;
            if (!count) {
              return callback(tx);
            }
    
            var offset = 0;
            var pageSize = 10;
            function nextPage() {
              var sql = select(
                SELECT_DOCS + ', ' + DOC_STORE + '.id AS id',
                [DOC_STORE, BY_SEQ_STORE],
                DOC_STORE_AND_BY_SEQ_JOINER,
                null,
                DOC_STORE + '.id '
              );
              sql += ' LIMIT ' + pageSize + ' OFFSET ' + offset;
              offset += pageSize;
              tx.executeSql(sql, [], function (tx, res) {
                if (!res.rows.length) {
                  return callback(tx);
                }
                var digestSeqs = {};
                function addDigestSeq(digest, seq) {
                  // uniq digest/seq pairs, just in case there are dups
                  var seqs = digestSeqs[digest] = (digestSeqs[digest] || []);
                  if (seqs.indexOf(seq) === -1) {
                    seqs.push(seq);
                  }
                }
                for (var i = 0; i < res.rows.length; i++) {
                  var row = res.rows.item(i);
                  var doc = unstringifyDoc(row.data, row.id, row.rev);
                  var atts = Object.keys(doc._attachments || {});
                  for (var j = 0; j < atts.length; j++) {
                    var att = doc._attachments[atts[j]];
                    addDigestSeq(att.digest, row.seq);
                  }
                }
                var digestSeqPairs = [];
                Object.keys(digestSeqs).forEach(function (digest) {
                  var seqs = digestSeqs[digest];
                  seqs.forEach(function (seq) {
                    digestSeqPairs.push([digest, seq]);
                  });
                });
                if (!digestSeqPairs.length) {
                  return nextPage();
                }
                var numDone = 0;
                digestSeqPairs.forEach(function (pair) {
                  var sql = 'INSERT INTO ' + ATTACH_AND_SEQ_STORE +
                    ' (digest, seq) VALUES (?,?)';
                  tx.executeSql(sql, pair, function () {
                    if (++numDone === digestSeqPairs.length) {
                      nextPage();
                    }
                  });
                });
              });
            }
            nextPage();
          });
        }
    
        var attachAndRev = 'CREATE TABLE IF NOT EXISTS ' +
          ATTACH_AND_SEQ_STORE + ' (digest, seq INTEGER)';
        tx.executeSql(attachAndRev, [], function (tx) {
          tx.executeSql(
            ATTACH_AND_SEQ_STORE_ATTACH_INDEX_SQL, [], function (tx) {
              tx.executeSql(
                ATTACH_AND_SEQ_STORE_SEQ_INDEX_SQL, [],
                migrateAttsAndSeqs);
            });
        });
      }
    
      // in this migration, we use escapeBlob() and unescapeBlob()
      // instead of reading out the binary as HEX, which is slow
      function runMigration6(tx, callback) {
        var sql = 'ALTER TABLE ' + ATTACH_STORE +
          ' ADD COLUMN escaped TINYINT(1) DEFAULT 0';
        tx.executeSql(sql, [], callback);
      }
    
      // issue #3136, in this migration we need a "latest seq" as well
      // as the "winning seq" in the doc store
      function runMigration7(tx, callback) {
        var sql = 'ALTER TABLE ' + DOC_STORE +
          ' ADD COLUMN max_seq INTEGER';
        tx.executeSql(sql, [], function (tx) {
          var sql = 'UPDATE ' + DOC_STORE + ' SET max_seq=(SELECT MAX(seq) FROM ' +
            BY_SEQ_STORE + ' WHERE doc_id=id)';
          tx.executeSql(sql, [], function (tx) {
            // add unique index after filling, else we'll get a constraint
            // error when we do the ALTER TABLE
            var sql =
              'CREATE UNIQUE INDEX IF NOT EXISTS \'doc-max-seq-idx\' ON ' +
              DOC_STORE + ' (max_seq)';
            tx.executeSql(sql, [], callback);
          });
        });
      }
    
      function checkEncoding(tx, cb) {
        // UTF-8 on chrome/android, UTF-16 on safari < 7.1
        tx.executeSql('SELECT HEX("a") AS hex', [], function (tx, res) {
            var hex = res.rows.item(0).hex;
            encoding = hex.length === 2 ? 'UTF-8' : 'UTF-16';
            cb();
          }
        );
      }
    
      function onGetInstanceId() {
        while (idRequests.length > 0) {
          var idCallback = idRequests.pop();
          idCallback(null, instanceId);
        }
      }
    
      function onGetVersion(tx, dbVersion) {
        if (dbVersion === 0) {
          // initial schema
    
          var meta = 'CREATE TABLE IF NOT EXISTS ' + META_STORE +
            ' (dbid, db_version INTEGER)';
          var attach = 'CREATE TABLE IF NOT EXISTS ' + ATTACH_STORE +
            ' (digest UNIQUE, escaped TINYINT(1), body BLOB)';
          var attachAndRev = 'CREATE TABLE IF NOT EXISTS ' +
            ATTACH_AND_SEQ_STORE + ' (digest, seq INTEGER)';
          // TODO: migrate winningseq to INTEGER
          var doc = 'CREATE TABLE IF NOT EXISTS ' + DOC_STORE +
            ' (id unique, json, winningseq, max_seq INTEGER UNIQUE)';
          var seq = 'CREATE TABLE IF NOT EXISTS ' + BY_SEQ_STORE +
            ' (seq INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT, ' +
            'json, deleted TINYINT(1), doc_id, rev)';
          var local = 'CREATE TABLE IF NOT EXISTS ' + LOCAL_STORE +
            ' (id UNIQUE, rev, json)';
    
          // creates
          tx.executeSql(attach);
          tx.executeSql(local);
          tx.executeSql(attachAndRev, [], function () {
            tx.executeSql(ATTACH_AND_SEQ_STORE_SEQ_INDEX_SQL);
            tx.executeSql(ATTACH_AND_SEQ_STORE_ATTACH_INDEX_SQL);
          });
          tx.executeSql(doc, [], function () {
            tx.executeSql(DOC_STORE_WINNINGSEQ_INDEX_SQL);
            tx.executeSql(seq, [], function () {
              tx.executeSql(BY_SEQ_STORE_DELETED_INDEX_SQL);
              tx.executeSql(BY_SEQ_STORE_DOC_ID_REV_INDEX_SQL);
              tx.executeSql(meta, [], function () {
                // mark the db version, and new dbid
                var initSeq = 'INSERT INTO ' + META_STORE +
                  ' (db_version, dbid) VALUES (?,?)';
                instanceId = uuid();
                var initSeqArgs = [ADAPTER_VERSION, instanceId];
                tx.executeSql(initSeq, initSeqArgs, function () {
                  onGetInstanceId();
                });
              });
            });
          });
        } else { // version > 0
    
          var setupDone = function () {
            var migrated = dbVersion < ADAPTER_VERSION;
            if (migrated) {
              // update the db version within this transaction
              tx.executeSql('UPDATE ' + META_STORE + ' SET db_version = ' +
                ADAPTER_VERSION);
            }
            // notify db.id() callers
            var sql = 'SELECT dbid FROM ' + META_STORE;
            tx.executeSql(sql, [], function (tx, result) {
              instanceId = result.rows.item(0).dbid;
              onGetInstanceId();
            });
          };
    
          // would love to use promises here, but then websql
          // ends the transaction early
          var tasks = [
            runMigration2,
            runMigration3,
            runMigration4,
            runMigration5,
            runMigration6,
            runMigration7,
            setupDone
          ];
    
          // run each migration sequentially
          var i = dbVersion;
          var nextMigration = function (tx) {
            tasks[i - 1](tx, nextMigration);
            i++;
          };
          nextMigration(tx);
        }
      }
    
      function setup() {
        db.transaction(function (tx) {
          // first check the encoding
          checkEncoding(tx, function () {
            // then get the version
            fetchVersion(tx);
          });
        }, websqlError(callback), dbCreated);
      }
    
      function fetchVersion(tx) {
        var sql = 'SELECT sql FROM sqlite_master WHERE tbl_name = ' + META_STORE;
        tx.executeSql(sql, [], function (tx, result) {
          if (!result.rows.length) {
            // database hasn't even been created yet (version 0)
            onGetVersion(tx, 0);
          } else if (!/db_version/.test(result.rows.item(0).sql)) {
            // table was created, but without the new db_version column,
            // so add it.
            tx.executeSql('ALTER TABLE ' + META_STORE +
              ' ADD COLUMN db_version INTEGER', [], function () {
              // before version 2, this column didn't even exist
              onGetVersion(tx, 1);
            });
          } else { // column exists, we can safely get it
            tx.executeSql('SELECT db_version FROM ' + META_STORE,
              [], function (tx, result) {
              var dbVersion = result.rows.item(0).db_version;
              onGetVersion(tx, dbVersion);
            });
          }
        });
      }
    
      setup();
    
      function getMaxSeq(tx, callback) {
        var sql = 'SELECT MAX(seq) AS seq FROM ' + BY_SEQ_STORE;
        tx.executeSql(sql, [], function (tx, res) {
          var updateSeq = res.rows.item(0).seq || 0;
          callback(updateSeq);
        });
      }
    
      function countDocs(tx, callback) {
        // count the total rows
        var sql = select(
          'COUNT(' + DOC_STORE + '.id) AS \'num\'',
          [DOC_STORE, BY_SEQ_STORE],
          DOC_STORE_AND_BY_SEQ_JOINER,
          BY_SEQ_STORE + '.deleted=0');
    
        tx.executeSql(sql, [], function (tx, result) {
          callback(result.rows.item(0).num);
        });
      }
    
      api._remote = false;
      api.type = function () {
        return 'websql';
      };
    
      api._id = toPromise(function (callback) {
        callback(null, instanceId);
      });
    
      api._info = function (callback) {
        var seq;
        var docCount;
        db.readTransaction(function (tx) {
          getMaxSeq(tx, function (theSeq) {
            seq = theSeq;
          });
          countDocs(tx, function (theDocCount) {
            docCount = theDocCount;
          });
        }, websqlError(callback), function () {
          callback(null, {
            doc_count: docCount,
            update_seq: seq,
            websql_encoding: encoding
          });
        });
      };
    
      api._bulkDocs = function (req, reqOpts, callback) {
        websqlBulkDocs(opts, req, reqOpts, api, db, websqlChanges, callback);
      };
    
      function latest$$1(tx, id, rev, callback, finish) {
        var sql = select(
            SELECT_DOCS,
            [DOC_STORE, BY_SEQ_STORE],
            DOC_STORE_AND_BY_SEQ_JOINER,
            DOC_STORE + '.id=?');
        var sqlArgs = [id];
    
        tx.executeSql(sql, sqlArgs, function (a, results) {
          if (!results.rows.length) {
            var err = createError(MISSING_DOC, 'missing');
            return finish(err);
          }
          var item = results.rows.item(0);
          var metadata = safeJsonParse(item.metadata);
          callback(latest(rev, metadata));
        });
      }
    
      api._get = function (id, opts, callback) {
        var doc;
        var metadata;
        var tx = opts.ctx;
        if (!tx) {
          return db.readTransaction(function (txn) {
            api._get(id, $inject_Object_assign({ctx: txn}, opts), callback);
          });
        }
    
        function finish(err) {
          callback(err, {doc: doc, metadata: metadata, ctx: tx});
        }
    
        var sql;
        var sqlArgs;
    
        if (!opts.rev) {
          sql = select(
            SELECT_DOCS,
            [DOC_STORE, BY_SEQ_STORE],
            DOC_STORE_AND_BY_SEQ_JOINER,
            DOC_STORE + '.id=?');
          sqlArgs = [id];
        } else if (opts.latest) {
          latest$$1(tx, id, opts.rev, function (latestRev) {
            opts.latest = false;
            opts.rev = latestRev;
            api._get(id, opts, callback);
          }, finish);
          return;
        } else {
          sql = select(
            SELECT_DOCS,
            [DOC_STORE, BY_SEQ_STORE],
            DOC_STORE + '.id=' + BY_SEQ_STORE + '.doc_id',
            [BY_SEQ_STORE + '.doc_id=?', BY_SEQ_STORE + '.rev=?']);
          sqlArgs = [id, opts.rev];
        }
    
        tx.executeSql(sql, sqlArgs, function (a, results) {
          if (!results.rows.length) {
            var missingErr = createError(MISSING_DOC, 'missing');
            return finish(missingErr);
          }
          var item = results.rows.item(0);
          metadata = safeJsonParse(item.metadata);
          if (item.deleted && !opts.rev) {
            var deletedErr = createError(MISSING_DOC, 'deleted');
            return finish(deletedErr);
          }
          doc = unstringifyDoc(item.data, metadata.id, item.rev);
          finish();
        });
      };
    
      api._allDocs = function (opts, callback) {
        var results = [];
        var totalRows;
        var updateSeq;
    
        var start = 'startkey' in opts ? opts.startkey : false;
        var end = 'endkey' in opts ? opts.endkey : false;
        var key = 'key' in opts ? opts.key : false;
        var keys = 'keys' in opts ? opts.keys : false;
        var descending = 'descending' in opts ? opts.descending : false;
        var limit = 'limit' in opts ? opts.limit : -1;
        var offset = 'skip' in opts ? opts.skip : 0;
        var inclusiveEnd = opts.inclusive_end !== false;
        
        var sqlArgs = [];
        var criteria = [];
    
        if (keys) {
          var destinctKeys = [];
          var bindingStr = "";
          keys.forEach(function (key) {
            if (destinctKeys.indexOf(key) === -1) {
              destinctKeys.push(key);
              bindingStr += '?,';
            }
          });
          bindingStr = bindingStr.substring(0, bindingStr.length - 1); // keys is never empty
          criteria.push(DOC_STORE + '.id IN (' + bindingStr + ')');
          sqlArgs = sqlArgs.concat(destinctKeys);
        } else if (key !== false) {
          criteria.push(DOC_STORE + '.id = ?');
          sqlArgs.push(key);
        } else if (start !== false || end !== false) {
          if (start !== false) {
            criteria.push(DOC_STORE + '.id ' + (descending ? '<=' : '>=') + ' ?');
            sqlArgs.push(start);
          }
          if (end !== false) {
            var comparator = descending ? '>' : '<';
            if (inclusiveEnd) {
              comparator += '=';
            }
            criteria.push(DOC_STORE + '.id ' + comparator + ' ?');
            sqlArgs.push(end);
          }
          if (key !== false) {
            criteria.push(DOC_STORE + '.id = ?');
            sqlArgs.push(key);
          }
        }
    
        if (!keys) {
          // report deleted if keys are specified
          criteria.push(BY_SEQ_STORE + '.deleted = 0');
        }
    
        db.readTransaction(function (tx) {
          // count the docs in parallel to other operations
          countDocs(tx, function (docCount) {
            totalRows = docCount;
          });
    
          /* istanbul ignore if */
          if (opts.update_seq) {
            // get max sequence in parallel to other operations
            getMaxSeq(tx, function (theSeq) {
              updateSeq = theSeq;
            });
          }
    
          if (limit === 0) {
            return;
          }
    
          // do a single query to fetch the documents
          var sql = select(
            SELECT_DOCS,
            [DOC_STORE, BY_SEQ_STORE],
            DOC_STORE_AND_BY_SEQ_JOINER,
            criteria,
            DOC_STORE + '.id ' + (descending ? 'DESC' : 'ASC')
            );
          sql += ' LIMIT ' + limit + ' OFFSET ' + offset;
    
          tx.executeSql(sql, sqlArgs, function (tx, result) {
            for (var i = 0, l = result.rows.length; i < l; i++) {
              var item = result.rows.item(i);
              var metadata = safeJsonParse(item.metadata);
              var id = metadata.id;
              var data = unstringifyDoc(item.data, id, item.rev);
              var winningRev$$1 = data._rev;
              var doc = {
                id: id,
                key: id,
                value: {rev: winningRev$$1}
              };
              if (opts.include_docs) {
                doc.doc = data;
                doc.doc._rev = winningRev$$1;
                if (opts.conflicts) {
                  var conflicts = collectConflicts(metadata);
                  if (conflicts.length) {
                    doc.doc._conflicts = conflicts;
                  }
                }
                fetchAttachmentsIfNecessary(doc.doc, opts, api, tx);
              }
              if (item.deleted) {
                if (keys) {
                  doc.value.deleted = true;
                  doc.doc = null;
                } else {
                  // propably should not happen
                  continue;
                }
              }
              if (!keys) {
                results.push(doc);
              } else {
                var index = keys.indexOf(id, index);
                do {
                  results[index] = doc;
                  index = keys.indexOf(id, index + 1);
                } while (index > -1 && index < keys.length);
              }
            }
            if (keys) {
              keys.forEach(function (key, index) {
                if (!results[index]) {
                  results[index] = {key: key, error: 'not_found'};
                }
              });
            }
          });
        }, websqlError(callback), function () {
          var returnVal = {
            total_rows: totalRows,
            offset: opts.skip,
            rows: results
          };
    
          /* istanbul ignore if */
          if (opts.update_seq) {
            returnVal.update_seq = updateSeq;
          }
          callback(null, returnVal);
        });
      };
    
      api._changes = function (opts) {
        opts = clone(opts);
    
        if (opts.continuous) {
          var id = api._name + ':' + uuid();
          websqlChanges.addListener(api._name, id, api, opts);
          websqlChanges.notify(api._name);
          return {
            cancel: function () {
              websqlChanges.removeListener(api._name, id);
            }
          };
        }
    
        var descending = opts.descending;
    
        // Ignore the `since` parameter when `descending` is true
        opts.since = opts.since && !descending ? opts.since : 0;
    
        var limit = 'limit' in opts ? opts.limit : -1;
        if (limit === 0) {
          limit = 1; // per CouchDB _changes spec
        }
    
        var results = [];
        var numResults = 0;
    
        function fetchChanges() {
    
          var selectStmt =
            DOC_STORE + '.json AS metadata, ' +
            DOC_STORE + '.max_seq AS maxSeq, ' +
            BY_SEQ_STORE + '.json AS winningDoc, ' +
            BY_SEQ_STORE + '.rev AS winningRev ';
    
          var from = DOC_STORE + ' JOIN ' + BY_SEQ_STORE;
    
          var joiner = DOC_STORE + '.id=' + BY_SEQ_STORE + '.doc_id' +
            ' AND ' + DOC_STORE + '.winningseq=' + BY_SEQ_STORE + '.seq';
    
          var criteria = ['maxSeq > ?'];
          var sqlArgs = [opts.since];
    
          if (opts.doc_ids) {
            criteria.push(DOC_STORE + '.id IN ' + qMarks(opts.doc_ids.length));
            sqlArgs = sqlArgs.concat(opts.doc_ids);
          }
    
          var orderBy = 'maxSeq ' + (descending ? 'DESC' : 'ASC');
    
          var sql = select(selectStmt, from, joiner, criteria, orderBy);
    
          var filter = filterChange(opts);
          if (!opts.view && !opts.filter) {
            // we can just limit in the query
            sql += ' LIMIT ' + limit;
          }
    
          var lastSeq = opts.since || 0;
          db.readTransaction(function (tx) {
            tx.executeSql(sql, sqlArgs, function (tx, result) {
              function reportChange(change) {
                return function () {
                  opts.onChange(change);
                };
              }
              for (var i = 0, l = result.rows.length; i < l; i++) {
                var item = result.rows.item(i);
                var metadata = safeJsonParse(item.metadata);
                lastSeq = item.maxSeq;
    
                var doc = unstringifyDoc(item.winningDoc, metadata.id,
                  item.winningRev);
                var change = opts.processChange(doc, metadata, opts);
                change.seq = item.maxSeq;
    
                var filtered = filter(change);
                if (typeof filtered === 'object') {
                  return opts.complete(filtered);
                }
    
                if (filtered) {
                  numResults++;
                  if (opts.return_docs) {
                    results.push(change);
                  }
                  // process the attachment immediately
                  // for the benefit of live listeners
                  if (opts.attachments && opts.include_docs) {
                    fetchAttachmentsIfNecessary(doc, opts, api, tx,
                      reportChange(change));
                  } else {
                    reportChange(change)();
                  }
                }
                if (numResults === limit) {
                  break;
                }
              }
            });
          }, websqlError(opts.complete), function () {
            if (!opts.continuous) {
              opts.complete(null, {
                results: results,
                last_seq: lastSeq
              });
            }
          });
        }
    
        fetchChanges();
      };
    
      api._close = function (callback) {
        //WebSQL databases do not need to be closed
        callback();
      };
    
      api._getAttachment = function (docId, attachId, attachment, opts, callback) {
        var res;
        var tx = opts.ctx;
        var digest = attachment.digest;
        var type = attachment.content_type;
        var sql = 'SELECT escaped, ' +
          'CASE WHEN escaped = 1 THEN body ELSE HEX(body) END AS body FROM ' +
          ATTACH_STORE + ' WHERE digest=?';
        tx.executeSql(sql, [digest], function (tx, result) {
          // websql has a bug where \u0000 causes early truncation in strings
          // and blobs. to work around this, we used to use the hex() function,
          // but that's not performant. after migration 6, we remove \u0000
          // and add it back in afterwards
          var item = result.rows.item(0);
          var data = item.escaped ? unescapeBlob(item.body) :
            parseHexString(item.body, encoding);
          if (opts.binary) {
            res = binStringToBluffer(data, type);
          } else {
            res = thisBtoa(data);
          }
          callback(null, res);
        });
      };
    
      api._getRevisionTree = function (docId, callback) {
        db.readTransaction(function (tx) {
          var sql = 'SELECT json AS metadata FROM ' + DOC_STORE + ' WHERE id = ?';
          tx.executeSql(sql, [docId], function (tx, result) {
            if (!result.rows.length) {
              callback(createError(MISSING_DOC));
            } else {
              var data = safeJsonParse(result.rows.item(0).metadata);
              callback(null, data.rev_tree);
            }
          });
        });
      };
    
      api._doCompaction = function (docId, revs, callback) {
        if (!revs.length) {
          return callback();
        }
        db.transaction(function (tx) {
    
          // update doc store
          var sql = 'SELECT json AS metadata FROM ' + DOC_STORE + ' WHERE id = ?';
          tx.executeSql(sql, [docId], function (tx, result) {
            var metadata = safeJsonParse(result.rows.item(0).metadata);
            traverseRevTree(metadata.rev_tree, function (isLeaf, pos,
                                                               revHash, ctx, opts) {
              var rev = pos + '-' + revHash;
              if (revs.indexOf(rev) !== -1) {
                opts.status = 'missing';
              }
            });
    
            var sql = 'UPDATE ' + DOC_STORE + ' SET json = ? WHERE id = ?';
            tx.executeSql(sql, [safeJsonStringify(metadata), docId]);
          });
    
          compactRevs(revs, docId, tx);
        }, websqlError(callback), function () {
          callback();
        });
      };
    
      api._getLocal = function (id, callback) {
        db.readTransaction(function (tx) {
          var sql = 'SELECT json, rev FROM ' + LOCAL_STORE + ' WHERE id=?';
          tx.executeSql(sql, [id], function (tx, res) {
            if (res.rows.length) {
              var item = res.rows.item(0);
              var doc = unstringifyDoc(item.json, id, item.rev);
              callback(null, doc);
            } else {
              callback(createError(MISSING_DOC));
            }
          });
        });
      };
    
      api._putLocal = function (doc, opts, callback) {
        if (typeof opts === 'function') {
          callback = opts;
          opts = {};
        }
        delete doc._revisions; // ignore this, trust the rev
        var oldRev = doc._rev;
        var id = doc._id;
        var newRev;
        if (!oldRev) {
          newRev = doc._rev = '0-1';
        } else {
          newRev = doc._rev = '0-' + (parseInt(oldRev.split('-')[1], 10) + 1);
        }
        var json = stringifyDoc(doc);
    
        var ret;
        function putLocal(tx) {
          var sql;
          var values;
          if (oldRev) {
            sql = 'UPDATE ' + LOCAL_STORE + ' SET rev=?, json=? ' +
              'WHERE id=? AND rev=?';
            values = [newRev, json, id, oldRev];
          } else {
            sql = 'INSERT INTO ' + LOCAL_STORE + ' (id, rev, json) VALUES (?,?,?)';
            values = [id, newRev, json];
          }
          tx.executeSql(sql, values, function (tx, res) {
            if (res.rowsAffected) {
              ret = {ok: true, id: id, rev: newRev};
              if (opts.ctx) { // return immediately
                callback(null, ret);
              }
            } else {
              callback(createError(REV_CONFLICT));
            }
          }, function () {
            callback(createError(REV_CONFLICT));
            return false; // ack that we handled the error
          });
        }
    
        if (opts.ctx) {
          putLocal(opts.ctx);
        } else {
          db.transaction(putLocal, websqlError(callback), function () {
            if (ret) {
              callback(null, ret);
            }
          });
        }
      };
    
      api._removeLocal = function (doc, opts, callback) {
        if (typeof opts === 'function') {
          callback = opts;
          opts = {};
        }
        var ret;
    
        function removeLocal(tx) {
          var sql = 'DELETE FROM ' + LOCAL_STORE + ' WHERE id=? AND rev=?';
          var params = [doc._id, doc._rev];
          tx.executeSql(sql, params, function (tx, res) {
            if (!res.rowsAffected) {
              return callback(createError(MISSING_DOC));
            }
            ret = {ok: true, id: doc._id, rev: '0-0'};
            if (opts.ctx) { // return immediately
              callback(null, ret);
            }
          });
        }
    
        if (opts.ctx) {
          removeLocal(opts.ctx);
        } else {
          db.transaction(removeLocal, websqlError(callback), function () {
            if (ret) {
              callback(null, ret);
            }
          });
        }
      };
    
      api._destroy = function (opts, callback) {
        websqlChanges.removeAllListeners(api._name);
        db.transaction(function (tx) {
          var stores = [DOC_STORE, BY_SEQ_STORE, ATTACH_STORE, META_STORE,
            LOCAL_STORE, ATTACH_AND_SEQ_STORE];
          stores.forEach(function (store) {
            tx.executeSql('DROP TABLE IF EXISTS ' + store, []);
          });
        }, websqlError(callback), function () {
          if (hasLocalStorage()) {
            delete window.localStorage['_pouch__websqldb_' + api._name];
            delete window.localStorage[api._name];
          }
          callback(null, {'ok': true});
        });
      };
    }
    
    function canOpenTestDB() {
      try {
        openDatabase('_pouch_validate_websql', 1, '', 1);
        return true;
      } catch (err) {
        return false;
      }
    }
    
    // WKWebView had a bug where WebSQL would throw a DOM Exception 18
    // (see https://bugs.webkit.org/show_bug.cgi?id=137760 and
    // https://github.com/pouchdb/pouchdb/issues/5079)
    // This has been fixed in latest WebKit, so we try to detect it here.
    function isValidWebSQL() {
      // WKWebView UA:
      //   Mozilla/5.0 (iPhone; CPU iPhone OS 9_2 like Mac OS X)
      //   AppleWebKit/601.1.46 (KHTML, like Gecko) Mobile/13C75
      // Chrome for iOS UA:
      //   Mozilla/5.0 (iPhone; U; CPU iPhone OS 5_1_1 like Mac OS X; en)
      //   AppleWebKit/534.46.0 (KHTML, like Gecko) CriOS/19.0.1084.60
      //   Mobile/9B206 Safari/7534.48.3
      // Firefox for iOS UA:
      //   Mozilla/5.0 (iPhone; CPU iPhone OS 8_3 like Mac OS X) AppleWebKit/600.1.4
      //   (KHTML, like Gecko) FxiOS/1.0 Mobile/12F69 Safari/600.1.4
    
      // indexedDB is null on some UIWebViews and undefined in others
      // see: https://bugs.webkit.org/show_bug.cgi?id=137034
      if (typeof indexedDB === 'undefined' || indexedDB === null ||
          !/iP(hone|od|ad)/.test(navigator.userAgent)) {
        // definitely not WKWebView, avoid creating an unnecessary database
        return true;
      }
      // Cache the result in LocalStorage. Reason we do this is because if we
      // call openDatabase() too many times, Safari craps out in SauceLabs and
      // starts throwing DOM Exception 14s.
      var hasLS = hasLocalStorage();
      // Include user agent in the hash, so that if Safari is upgraded, we don't
      // continually think it's broken.
      var localStorageKey = '_pouch__websqldb_valid_' + navigator.userAgent;
      if (hasLS && localStorage[localStorageKey]) {
        return localStorage[localStorageKey] === '1';
      }
      var openedTestDB = canOpenTestDB();
      if (hasLS) {
        localStorage[localStorageKey] = openedTestDB ? '1' : '0';
      }
      return openedTestDB;
    }
    
    function valid() {
      if (typeof openDatabase !== 'function') {
        return false;
      }
      return isValidWebSQL();
    }
    
    function openDB$1(name, version, description, size) {
      // Traditional WebSQL API
      return openDatabase(name, version, description, size);
    }
    
    function WebSQLPouch(opts, callback) {
      var msg = 'WebSQL is deprecated and will be removed in future releases of PouchDB. ' +
          'Please migrate to IndexedDB: https://pouchdb.com/2018/01/23/pouchdb-6.4.2.html';
      guardedConsole('warn', msg);
      var _opts = $inject_Object_assign({
        websql: openDB$1
      }, opts);
    
      WebSqlPouch.call(this, _opts, callback);
    }
    
    WebSQLPouch.valid = valid;
    
    WebSQLPouch.use_prefix = true;
    
    function WebsqlPouchPlugin (PouchDB) {
      PouchDB.adapter('websql', WebSQLPouch, true);
    }
    
    /* global PouchDB */
    
    if (typeof PouchDB === 'undefined') {
      guardedConsole('error', 'websql adapter plugin error: ' +
        'Cannot find global "PouchDB" object! ' +
        'Did you remember to include pouchdb.js?');
    } else {
      PouchDB.plugin(WebsqlPouchPlugin);
    }
    
    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
    },{"1":1,"11":11,"2":2,"3":3,"4":4,"5":5,"6":6}]},{},[12]);
    