import titleTpl from './partials/dashboard-title-buttons.html';
import widgetTpl from './partials/widget.html';

var tplMap = {
  'app/components/dashboard/partials/dashboard-title-buttons.html': titleTpl,
  'app/components/dashboard/partials/widget.html': widgetTpl
};

(function() {
  'use strict';

  function DashboardUtils(Profile, $templateCache) {
    var service = {};

    _.forEach(tplMap, function(value, key) {
      $templateCache.put(key, value);
    });

    /**
     * Save the last used template, dashboard or profile, to the user's preferences.
     * @param  {string} type The type of template. Can be either dashboard or profile.
     * @param  {string} id   The ID of the template.
     * @return {void}
     */
    service.saveLastUsedTemplate = function(type, id) {
      var data = {};
      data[type] = {};
      data[type].lastUsed = id;

      var prefs = _.assignIn(
        {},
        Profile.getPreferences(),
        data
      );

      Profile.savePreferences(prefs);
    };

    /**
     * Create the template for the user, making sure all the
     * correct properties are set.
     * @param {Object} template The template object to set.
     * @return {Object}         The template object to be used in the view.
     */
    service.setTemplate = function(type, template) {
      if (!template.ignoreSaveLastUsed) {
        service.saveLastUsedTemplate(type, template._id);
      }

      return _.assign(
        {},
        template,
        {
          titleTemplateUrl: 'app/components/dashboard/partials/dashboard-title-buttons.html',
          structure: template.template.structure,
          rows: template.template.rows,
          options: {
            hideTitle: !template.showTitle
          }
        }
      );
    };

    return service;
  }

  DashboardUtils.$inject = ['ProfileService', '$templateCache'];

  angular.module('component.dashboard')
    .factory('DashboardUtilsService', DashboardUtils);
})();
