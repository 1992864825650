(function() {
  'use strict';

  function ApiService(
    $http, $q, $log, $timeout, $window, $location, CacheFactory, Network, OidcService,
    ClusterService, API_PATHS, DB_ERRORS
  ) {
    var _cache = CacheFactory.get('api') || CacheFactory.createCache(
      'api',
      { maxAge: 1000 * 60 * 5, deleteOnExpire: 'aggressive' }
    );

    // var API_URL = _.trimEnd(COUCHDB_URLS.baseApi, '/');
    // var BASE_URL = _.trimEnd(COUCHDB_URLS.base, '/');

    var service = {};
    service.getAPI_URL = function() {
      var urls = ClusterService.getCurrentUrls();
      return _.trimEnd(urls.lapi, '/');
    };

    service.absUrl = function(apiName, urlParams, _options) {
      var baseUrl = service.getAPI_URL();
      var url = baseUrl + API_PATHS[apiName];
      if (urlParams) {
        _.forEach(urlParams, function(val, key) {
          url = url.replace('{' + key + '}', val);
        });
      }
      return url;
    };

    service.absHostUrl = function(apiName, urlParams) {
      var baseUrl = $location.protocol() + '://' + $location.host();
      var url = baseUrl + API_PATHS[apiName];
      if (urlParams) {
        _.forEach(urlParams, function(val, key) {
          url = url.replace('{' + key + '}', val);
        });
      }
      return url;
    };

    service.call = function(method, apiName, data, urlParams, options) {
      options = options || {};

      if (!options.force && Network.isOffline()) {
        return $q.reject(DB_ERRORS.notAvailableOffline);
      }

      var ldata = angular.copy(data) || {},
          lid = ldata._id || '';


      var url = service.getAPI_URL() + API_PATHS[apiName];
      url += $window.encodeURIComponent(lid);

      if (urlParams) {
        _.forEach(urlParams, function(val, key) {
          url = url.replace('{' + key + '}', val);
        });
      }

      // $log.debug(url);

      if (method === 'REDIRECT') {
        var args = _.map(urlParams, function(value, key) {
          return key + '=' + value;
        });

        args = args.join('&');

        url = url + '?' + args;

        $window.location.href = url;
        return;
      }

      function handleError(error) {
        if (error && error.status === 0) {
          $log.warn('Rest API: No response from server');
          return $q.reject(
            { status: 0, message: 'Cannot reach the server. Your connection is down.' }
          );
        }

        var status = error.status || 0;
        var errText;
        var errDetail;

        if (_.isEmpty(error.data)) {
          errText = 'Cannot reach the server';
        } else if (_.isString(error.data)) {
          errText = error.data;
          errDetail = error.data.details;
        } else {
          errText = error.data.message;
          errDetail = error.data.details;
        }

        return $q.reject({ status: status, message: errText, details: errDetail });
      }

      if (method === 'BLOB') {
        method = 'GET';
        return $http({
          method: method,
          url: url,
          params: (method === 'GET') ? ldata : {},
          data: (method !== 'GET') ? data : {},
          withCredentials: true,
          cache: !options.noCache && method === 'GET' ? _cache : false,
          headers: {
            Authorization: OidcService.bearer,
            'EAS-Organisation': OidcService.organisation,
            'EAS-Username': OidcService.usernameHeader
          },
          responseType: 'blob' // very important, otherwise zip file won't work
        })
          .then(function(result) {
            var blobName = result.headers('Content-disposition')
              .split('filename=')[1].split(';')[0];

            var blobContent = result.data,
                blobType = result.headers('content-type').split(';')[0];

            var blob = new Blob([blobContent], { type: blobType });
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = blobName;
            a.style.display = 'none';
            // a.srcObject = blob;

            document.body.appendChild(a);
            a.click();

            $timeout(function() { window.URL.revokeObjectURL(url); }, 10 * 60 * 1000);
          });
      } else if (method === 'MULTIPART') {
        const fd = new FormData();
        fd.append('data', JSON.stringify(data));

        _.forEach(options.files || {}, function(fi, name) {
          const blob = new Blob([fi], { type: fi.type });
          fd.append(name, blob);
        });

        return $http.post(url, fd, {
          // transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
            Authorization: OidcService.bearer,
            'EAS-Organisation': OidcService.organisation,
            'EAS-Username': OidcService.usernameHeader
          }
        })
          .then(function(resp) { return resp.data; })
          .catch(handleError);
      }

      var skipCache = false;
      if (options.cached !== undefined) {
        skipCache = !options.cached;
      }

      if (options.noCache !== undefined) {
        skipCache = options.noCache;
      }

      return $http({
        method: method,
        url: url,
        params: (method === 'GET') ? ldata : {},
        data: (method !== 'GET') ? data : {},
        withCredentials: true,
        cache: !skipCache && method === 'GET' ? _cache : false,
        headers: {
          Authorization: OidcService.bearer,
          'EAS-Organisation': OidcService.organisation,
          'EAS-Username': OidcService.usernameHeader
        }
      })
        .then(function(resp) { return resp.data; })
        .catch(handleError);
    };

    service.get = function(apiName, data, urlParams, options) {
      return service.call('GET', apiName, data, urlParams, options);
    };

    service.post = function(apiName, data, urlParams, options) {
      // service.purgeCache();
      return service.call('POST', apiName, data, urlParams, options);
    };

    service.postMultiPart = function(apiName, data, urlParams, files, options) {
      options = options || {};
      options.files = files;
      return service.call('MULTIPART', apiName, data, urlParams, options);
    };

    service.put = function(apiName, data, urlParams) {
      service.purgeCache();
      return service.call('PUT', apiName, data, urlParams);
    };

    service.delete = function(apiName, data, urlParams) {
      service.purgeCache();
      return service.call('DELETE', apiName, data, urlParams);
    };

    service.download = function(apiName, data, urlParams) {
      return service.call('REDIRECT', apiName, data, urlParams);
    };

    service.downloadBlob = function(apiName, data, urlParams) {
      return service.call('BLOB', apiName, data, urlParams);
    };

    service.purgeCache = function() {
      _cache.removeAll();
    };

    return service;
  }

  ApiService.$inject = [
    '$http',
    '$q',
    '$log',
    '$timeout',
    '$window',
    '$location',
    'CacheFactory',
    'NetworkService',
    'OidcService',
    'ClusterService',
    'API_PATHS',
    'DB_ERRORS'
  ];

  angular.module('blocks.api')
    .service('ApiService', ApiService);
})();
