import Quill from 'quill';

(function() {
  'use strict';

  angular.module('blocks.utils').constant('NG_QUILL_CONFIG', {
    /*
     * @NOTE: this config/output is not localizable.
     */
    modules: {
      toolbar: {
        container: [
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          // ['blockquote', 'code-block'],

          // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          // [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
          // [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
          // [{ 'direction': 'rtl' }],                         // text direction

          // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [
            'rgb(0, 0, 0)',
            'rgb(230, 0, 0)',
            'rgb(255, 153, 0)',
            'rgb(255, 255, 0)',
            'rgb(0, 138, 0)',
            'rgb(0, 102, 204)',
            'rgb(153, 51, 255)'
          ] },
          { background: [
            'rgb(255, 255, 255)',
            'rgb(230, 0, 0)',
            'rgb(255, 153, 0)',
            'rgb(255, 255, 0)',
            'rgb(0, 138, 0)',
            'rgb(0, 102, 204)',
            'rgb(153, 51, 255)'
          ] }], // dropdown with defaults from theme
          // [{ 'font': [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button

          ['link', 'image'] // link and image, video
        ],
        handlers: {
          image: function imageHandler() {
            var range = this.quill.getSelection();
            // eslint-disable-next-line
            var value = prompt('What is the image URL');
            if (value) {
              // eslint-disable-next-line no-undef
              this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
            }
          }
        }
      }
    },
    theme: 'snow',
    debug: 'warn',
    placeholder: '',
    readOnly: false,
    bounds: document.body,
    scrollContainer: null
  }).config([
    'ngQuillConfigProvider',
    'NG_QUILL_CONFIG',

    function(ngQuillConfigProvider, NG_QUILL_CONFIG) {
      ngQuillConfigProvider.set(NG_QUILL_CONFIG);
    }
  ]);
})();
