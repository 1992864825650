(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider

      .state('epf.files', {
        url: 'files',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }]
        }
      })
      .state('epf.files.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/files/files.html',
            controller: 'FilesController',
            controllerAs: 'filesCtrl'
          }
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.files')
    .config(config);
})();
