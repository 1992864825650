(function() {
  'use strict';

  function BaseUserStoreService($rootScope, BaseStore, Utils, Auth) {
    var Service = function() {
      BaseStore.call(this);
      this.id = 'userstore';
      this.by_type_view = 'by_org_user_and_type';
      this.by_id_view = 'by_org_user_and_id';
    };

    Utils.extends(Service, BaseStore);

    Service.prototype.ddoc = function(view) {
      return 'public/' + view;
    };

    Service.prototype.injectKeys = function(options) {
      options = angular.copy(options || {});
      var user = Auth.currentUser();
      var org = Auth.currentOrganisation();

      if (options.key) {
        if (_.isArray(options.key)) {
          options.key = [org, user].concat(options.key);
        } else {
          options.key = [org, user, options.key];
        }
      } else if (options.keys) {
        options.keys = _.map(options.keys, function(key) {
          return [org, user, key];
        });
      } else {
        // $log.error('Not yet implemented');
      }

      return options;
    };

    var service = new Service();
    $rootScope.$on('KZClearCache', function() {
      service.invalidate();
    });
    return service;
  }

  BaseUserStoreService.$inject = ['$rootScope', 'BaseStoreFactory', 'UtilsService', 'AuthService'];

  angular.module('blocks.stores')
    .service('BaseUserStoreService', BaseUserStoreService);
})();
