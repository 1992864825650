(function() {
  'use strict';

  function InboxDashboardWidgetController($scope, $uibModal, Announcements, Auth, Utils,
                                          LocalizationService) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.archiveIsVisible = false;

    /**
     * Show the Show archive link.
     * @param  {Array} announcements The list of announcements.
     * @return {void}
     */
    var toggleItemViews = function(announcements) {
      ctrl.hasArchive = _.some(announcements, 'isDismissed');
      ctrl.hasCurrentAnnouncements = !_.every(announcements, 'isDismissed');
    };

    $scope.$watch(
      function() {
        return ctrl.announcements;
      }, function(newValue, oldValue) {
        if (newValue !== oldValue) {
          toggleItemViews(newValue);
        }
      }, true);

    /**
     * Open an announcement in a preview modal.
     * @param  {Object} announcement The announcement object.
     * @return {void}
     */
    ctrl.openPreview = function(announcement) {
      $uibModal.open({
        animation: true,
        size: 'md',
        templateUrl: 'app/components/announcements/preview.html',
        controller: 'AnnouncementPreviewController',
        controllerAs: 'announcementPreviewCtrl',
        resolve: {
          announcementDoc: function() {
            return announcement;
          },
          allowDismiss: function() {
            // Only allow dismiss if the announcement is not dismissed
            return !ctrl.archiveIsVisible;
          },
          markAsRead: function() {
            // Only allow to be marked as read if not already
            return !announcement.isRead;
          }
        }
      });
    };

    /**
     * Toggle between the regular view and the archive view.
     * @return {void}
     */
    ctrl.toggleArchiveView = function() {
      ctrl.archiveIsVisible = !ctrl.archiveIsVisible;
    };

    /**
     * Figure out if an announcement should be shown in the current view or not.
     * @param  {Object} announcement The announcement object.
     * @return {Boolean}             True if the announcement should be shown, False otherwise.
     */
    ctrl.shouldShow = function(announcement) {
      return ctrl.archiveIsVisible ? announcement.isDismissed : !announcement.isDismissed;
    };

    Announcements.findAllForUser(Auth.currentUser(), { include_docs: true })
      .then(function(announcements) {
        ctrl.announcements = announcements;
        toggleItemViews(announcements);
      })
      .catch(Utils.showError);
  }

  InboxDashboardWidgetController.$inject = [
    '$scope',
    '$uibModal',
    'AnnouncementsService',
    'AuthService',
    'UtilsService',
    'LocalizationService'
  ];

  function config(dashboardProvider) {
    dashboardProvider.widget('inbox', {
      title: 'Inbox',
      description: 'View your announcements',
      controller: 'InboxDashboardWidgetController',
      controllerAs: 'inboxCtrl',
      templateUrl: 'app/components/announcements/widgets/inbox/view.html',
      edit: {},
      category: 'General'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.announcements')
    .config(config)
    .controller('InboxDashboardWidgetController', InboxDashboardWidgetController);
})();
