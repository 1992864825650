(function() {
  'use strict';

  angular.module('epf.component', [

      // put these in the order you would like them to show up in the sidebar

    'component.dashboard',
    'component.events',
    'component.files',
    'component.todos',
    'component.faqs',
    'component.eventTypes',
    'component.blueprints',
    'component.goals',
    'component.users',
    'component.userFields',
    'component.relations',
    'component.roles',
    'component.organisations',
    'component.accounts',
    'component.settings',
    'component.reports',
    'component.admin',
    'component.announcements',
    'component.dashboardTemplates',
    'component.drafts',
    'component.importer',
    'component.emailTemplates',
    'component.emailLogs'
  ]);
})();
