(function() {
  'use strict';

  function EventsFilter(
    $q,
    EventSearch,
    List
  ) {
    var service = {};

    service.filterEvents = function(events, searchModel) {
      if (_.isEmpty(searchModel)) {
        console.log('No filtering because no filter');
        return $q.when(events);
      }
      var items = events;
      var list;
      searchModel = angular.copy(searchModel);
      return EventSearch.preFilteredSearch(searchModel)
        .then(function(search) {
          list = new List({
            search: search,
            model: { filteredBy: {} },
            idField: 'id',
            // findOptions: { limit: 0 },
            defaultFilter: search.defaultFilter || {}
          });
          return list.doLoadItems(items);
        })
        .then(function() {
          return list.filtered;
        });
    };

    return service;
  }

  EventsFilter.$inject = [
    '$q',
    'EventSearch',
    'ListFactory'
  ];

  angular.module('component.events')
    .service('EventsFilter', EventsFilter);
})();
