(function() {
  'use strict';

  function kzAuditLog($uibModal, Security, ACTION_MAPPING, LocalizationService) {
    return {
      scope: {
        dates: '=',
        doc: '=',
        showHeader: '=?',
        showDetails: '=?',
        perPage: '=?'
      },
      replace: true,
      restrict: 'E',
      templateUrl: 'app/blocks/widgets/auditlog/audit-log.html',
      link: function(scope) {
        scope.showDetails = scope.showDetails === undefined ? true : scope.showDetails;
        scope.actionMapping = ACTION_MAPPING;
        scope.showHeader = typeof scope.showHeader !== 'undefined' ? scope.showHeader : true;
        Security.hasPermission('emailTemplates.view')
          .then(function(res) {
            if (res) {
              scope.showEmailPreview = true;
            }
          });
        scope.perPage = scope.perPage || scope.dates.length;
        scope.dateFormat = LocalizationService.getDateTimeFormat('datetime');
        scope.showOlderEntries = function(dates) {
          $uibModal.open({
            animation: true,
            size: 'lg',
            templateUrl: 'app/blocks/widgets/auditlog/audit-log.preview.html',
            controller: 'AuditLogPreviewController',
            controllerAs: 'ctrl',
            resolve: {
              dates: function() {
                return dates;
              },
              showDetails: function() {
                return scope.showDetails;
              }
            }
          });
        };
      }
    };
  }

  kzAuditLog.$inject = ['$uibModal', 'SecurityService', 'ACTION_MAPPING', 'LocalizationService'];

  angular.module('blocks.widgets')
    .directive('kzAuditLog', kzAuditLog);
})();
