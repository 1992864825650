import _ from 'lodash';
(function() {
  'use strict';

  var seoTags = {
    pageTitleDefault: 'risr/advance',
    pageTitlePrefix: 'risr/advance - '
  };

  var databases,
      settings,
      actionMapping;

  databases = {
    master: 'master',
    queue: 'master',
    organisation: 'org_<ORGANISATION>_conf',
    user: {
      shared: 'org_<ORGANISATION>_user_<USERID>_shared',
      private: 'user_<USERID>_private',
      syncdb: 'user_<USERID>_syncdb'
    }
  };

  settings = {
    pagination: {
      limit: 10
    }
  };

  actionMapping = {
    add_credential: 'credential added',
    remove_credential: 'credential removed',
    edit_credential_username: 'credential updated',
    edit_credential_password: 'password updated',
    pin_updated: 'PIN updated',
    document_created: 'uploaded document',
    document_delete: 'deleted document',
    event_created: 'created event',
    request_created: 'sent invitation',
    section_assign: 'assigned section',
    section_close: 'closed section',
    section_closegoal: 'closed goal set',
    section_edit: 'edited section',
    section_import: 'imported response',
    section_invite: 'sent invitation', // This is not used likely as it does request_created
    section_reject: 'rejected invitation',
    section_remind: 'sent reminder',
    section_reopen: 're-opened section',
    section_retract: 'retracted invitation',
    section_publish: 'published section',
    section_approve: 'approved section',
    response_publish: 'submitted response',
    response_approve: 'approved response',
    pending_section_added: 'published section for approval',
    pending_response_added: 'submitted response for approval',
    goal_reopen: 're-opened goal set',
    make_private: 'set visibility to private',
    make_public: 'set visibility to shared',
    make_hidden: 'hid from navigation',
    make_shown: 'made visible in navigation',
    user_create: 'created user',
    rcpch_user_create: 'created user',
    user_disable: 'disabled user',
    user_enable: 'enabled user',
    user_edit: 'edited profile',
    user_post: 'edited profile',
    user_put: 'edited profile',
    extra_commented: 'commented',
    extra_document: 'attached a document',
    extra_documents: 'attached documents',
    extra_tag: 'added a tag',
    extra_tags: 'added tags',
    extra_document_tag: 'attached a document and added a tag',
    extra_documents_tag: 'attached documents and added a tag',
    extra_document_tags: 'attached a document and added tags',
    extra_documents_tags: 'attached documents and added tags',
    celerytask_edit: 'modified',
    goals_add: 'added goal',
    goal_update: 'updated goal',

    // New
    user_approve: 'approved user',
    invited: 'invited',
    reminded: 'reminded',

    created: 'created', // Anything else event types, roles...
    modified: 'modified', // Anything else
    deleted: 'deleted',
    published: 'published',
    unpublished: 'unpublished', // Announcements
    archived: 'archived',

    event_edit: 'edited', // I'm not sure what we said about this - but it could be used in theory
    response_edit: 'edited',
    ms_response_delete: 'response deleted',

    relation_edit: 'edited',
    blueprint_edit: 'edited',
    blueprint_map_edit: 'edited',
    user_field_edit: 'edited',
    role_edit: 'edited',
    event_type_edit: 'edited',
    report_edit: 'edited',

    relation_create: 'created',
    blueprint_create: 'created',
    blueprint_map_create: 'created',
    user_field_create: 'created',
    role_create: 'created',
    event_type_create: 'created',
    report_create: 'created',

    relation_update_order: 'reordered',
    user_field_update_order: 'reordered',

    event_type_publish: 'published',
    event_type_archive: 'archived',
    event_type_new_version: 'created new version',
    event_type_delete: 'deleted',

    report_publish: 'published',
    report_archive: 'archived',
    report_new_version: 'created new version',
    report_delete: 'deleted',

    version_linked_new_version: 'linked new version',
    version_unlink_last_version: 'unlinked last version',
    version_archive_by_publishing: 'archived by publishing'
  };

  _.assignIn(actionMapping, {
    // Comes from old, here just to make sure we translate them ok
    edited: actionMapping.event_edit,
    commented: actionMapping.extra_commented,
    retracted: actionMapping.section_retract,
    rejected: actionMapping.section_reject,
    imported: actionMapping.section_import,
    'section completed': actionMapping.section_publish,
    'added tags': actionMapping.extra_tags,
    'added a tag': actionMapping.extra_tag,
    'made private': actionMapping.make_private,
    'section closed': actionMapping.section_close,
    'section submitted and waiting for admin approval': actionMapping.pending_section_added,
    'added comment': actionMapping.extra_commented,
    'attached a document': actionMapping.extra_document,
    'attached documents': actionMapping.extra_documents,
    'made public': actionMapping.make_public,
    'section re-opened': actionMapping.section_reopen,
    'reverted section': 'reverted section', // I think this one was manual revert
    'deleted comment': 'deleted comment' // Has this been implemented?
  });

  // Usage while processing:
  // {{ action }} is scheduled
  // {{ action }} is being processed
  // {{ action }} failed

  //  Submitting response is scheduled

  var scheduleMapping = {
    organisation_create: 'Creating organisation',
    organisation_update: 'Updating organisation',

    reset_password: 'Setting new password',
    user_enable: 'Enabling user',
    user_disable: 'Disabling user',
    user_create: 'Creating user',
    user_edit: 'Modifying profile',
    reset_password_token_create: 'Requesting password reset',

    section_publish: 'Submission',
    section_reject: 'Rejecting invitation',
    section_retract: 'Retracting invitation',
    section_remind: 'Sending reminder',
    section_close: 'Closing section',
    section_closegoal: 'Closing goal set',
    section_reopen: 'Re-opening',
    section_invite: 'Sending invitation',
    section_assign: 'Assigning section to user',
    section_edit: 'Modifying response',
    event_change_visibility: 'Changing visibility',
    event_delete: 'Deletion',
    response_edit: 'Modifying event',
    event_edit: 'Modifying event',

    extra_add: 'Adding comment',
    extra_post_add: 'Adding to event',

    document_create_event: 'Creating',
    document_delete: 'Deleting document',

    goals_add: 'Adding goal',
    goal_update: 'Updating goal',
    goal_delete: 'Deleting goal',

    celerytask_edit: 'Updating',
    celerytask_start: 'Triggering',

    rcpch_user_create: 'Creating user'

    // These two should not be public
    // exam_result_create: '',
    // exams_results_visibility_update: ''
  };

  function URLService(_$q, env) {
    var hostname = window.location.host;
    var protocol = window.location.protocol;
    var baseUri = protocol + '//' + hostname;

    if (env.backend_url !== undefined) {
      baseUri = env.backend_url;
    }

    // baseUri = 'https://dev.kaizenep.com';

    baseUri = _.trimEnd(baseUri, '/');

    var urls = {
      base: baseUri,
      // baseApi: baseUri + '/api/',
      baseApi: 'https://lapi.eas:8443/',
      remote: 'https://ldb.eas:8443/',
      local: 'eportfolio_local_',
      login: baseUri + '/db/_session'
      // getUrls: function(org) {
      //   return ClusterService.getOrganisation(org).urls;
      // }
      // getUrls: function(org) {
      //   var def = $q.defer();
      //   var urls1 = {
      //     lapi: 'https://lapi.eas:8443',
      //     ldb: 'https://ldb.eas:8443',
      //     api: 'https://api.eas:8443'
      //   };
      //   var urls2 = {
      //     lapi: 'https://lapi2.eas:8443',
      //     ldb: 'https://ldb2.eas:8443',
      //     api: 'https://api.eas:8443'
      //   };
      //   setTimeout(function() {
      //     if (org === 'org_fry') {
      //       def.resolve(urls1);
      //     } else {
      //       def.resolve(urls2);
      //     }
      //   }, 100);
      //   return def.promise;
      // }
    };

    return urls;
  }

  function kzConfig() {
    return _.assignIn({
      authType: 'local',
      liveChat: {
        licenses: {}
      }
    }, window.kzConfig || {});
  }

  URLService.$inject = ['$q', 'KZ_CONFIG'];

  angular.module('epf.core')
    .constant('SEO_TAGS', seoTags)
    .constant('DATABASES', databases)
    .constant('DB_SETTINGS', settings)
    .constant('ALLOW_DRAFT_EVENT_TYPES', false)
    .constant('ACTION_MAPPING', actionMapping)
    .constant('ACTION_SCHEDULE_MAPPING', scheduleMapping)
    .service('SERVER_CONFIG', kzConfig)
    .service('COUCHDB_URLS', URLService);
})();
