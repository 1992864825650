(function() {
  'use strict';

  function LunrService($q, $log, CacheService, LunrIndex, Auth) {
    var indexes = {};
    var service = {};

    service.getIndexId = function(id, options) {
      options = options || {};
      var tokens = [];
      tokens.push(Auth.currentOrganisation());
      if (options.userAware) {
        tokens.push(Auth.currentUser());
      }

      tokens.push(id);
      return tokens.join('_');
    };

    service.createIndex = function(id, config, options) {
      options = options || {};
      id = service.getIndexId(id, options);

      if (indexes[id] !== undefined) {
        $log.error('Index already exists');
        return $q.when(indexes[id]);
      }

      indexes[id] = new LunrIndex(id, config, options);
      return $q.when(indexes[id]);
    };

    service.getIndex = function(id, options) {
      options = options || {};
      var cid = service.getIndexId(id, options);
      var ext = _.assignIn({
        key: 'index-' + cid,
        maxAge: 0,
        cached: true
      });
      var func = this._getIndex.bind(this, id, options);
      return CacheService.cachedPromise(func, ext);
    };

    service._getIndex = function(id, options) {
      options = options || {};
      id = service.getIndexId(id, options);

      var index = indexes[id];
      if (index !== undefined) {
        return $q.when(index);
      }

      if (!options.persistentCache) {
        return $q.when();
      }

      return CacheService.persistentGet(id, { version: options.version })
        .then(function(idx) {
          index = new LunrIndex(id, undefined, options);
          if (!index.loadIndex(idx)) {
            return $q.reject('Index version mismatch');
          }

          indexes[id] = index;
          return index;
        })
        .catch(function(err) {
          console.log(err);
        });
    };

    service.tokenize = function(query) {
      var index = service._dummyindex;
      if (index === undefined) {
        index = new LunrIndex('dummy', function() {});

        service._dummyindex = index;
      }

      return index.tokenize(query);
    };

    return service;
  }

  LunrService.$inject = ['$q', '$log', 'CacheService', 'KZLunrIndex', 'AuthService'];

  angular.module('blocks.lunr')
    .service('KZLunr', LunrService);
})();
