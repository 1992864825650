(function() {
  'use strict';

  function ProfileTemplateController(
    $rootScope,
    $state,
    $stateParams,
    ProfileTemplates,
    ProfileTemplatesUtils,
    Utils,
    Auth,
    Notify
  ) {
    var ctrl = this;

    var org = Auth.currentOrganisation();

    ctrl.isNew = _.isUndefined($stateParams.id);
    ctrl.templateType = 'summary';
    ctrl.cancelUrl = $state.href('epf.profile-templates.index');
    ctrl.isEditingCopy = !_.isUndefined($stateParams.template);

    /**
     * Filter the widgets and only allow certain widgets to be added.
     * This function is run by the adf-dashboard framework for every
     * widget in the colleciton.
     * @param  {Object} widget The widget object.
     * @param  {string} type   The type of the widget. This is set up when creating
     *                         the widget.
     * @return {Boolean}       Whether to allow the widget or not.
     */
    ctrl.filterWidgets = function(_widget, type) {
      var disallowedWidgets = [
        'eventDraft',
        'inbox',
        'kaizenhq',
        'connections',
        'goalSetDetails',
        'eventDrafts'
      ];

      return !_.includes(disallowedWidgets, type);
    };

    /**
     * Listen for changes coming in from the form and update the dashboard adf-model as needed.
     */
    $rootScope.$on('KZTemplate:FormChanged', function(_evt, args) {
      ctrl.template.template = _.assign(
        {},
        ctrl.template.template,
        args
      );
    });

    /**
     * Set the profile template and the workable template object.
     * @param  {Object} data The object to base the template on.
     * @return {Object}      The extended profile template.
     */
    var setProfileTemplate = function(data) {
      ctrl.template = ProfileTemplatesUtils.extendTemplate(data, org);
      // This hides the title just for the editing interface
      ctrl.template.template.options.hideTitle = true;
      return ctrl.template;
    };

    setProfileTemplate($stateParams.template || {});

    // If we are editing, let's load the template
    if ($stateParams.id) {
      ProfileTemplates.find($stateParams.id)
        .then(function(data) {
          Utils.setPageTitle('Edit summary template: ' + data.template.title);
          return setProfileTemplate(data);
        })
        .then(function() {
          ctrl.loaded = true;
        })
        .catch(Utils.showError);
    } else {
      Utils.setPageTitle('New summary template');
      ctrl.loaded = true;
    }

    /**
     * Save the profile template.
     * This also saves the audit log.
     * @param  {Object}  doc The template to save.
     * @param  {String}  msg The message to display to the user when the save is complete.
     * @return {Promise}     A promise for chaining.
     */
    var saveProfileTemplate = function(doc, msg) {
      return ProfileTemplatesUtils
              .saveAuditLog(doc, ctrl.isNew || ctrl.isEditingCopy, Auth.currentUser())
              .then(function(doc) {
                doc.template = _.pick(doc.template, ['structure', 'rows', 'title']);
                return ProfileTemplates.save(doc);
              })
              .then(function() {
                Notify.success(msg, 'Success');
                $state.go('epf.profile-templates.index');
              })
              .catch(Utils.showError);
    };

    /**
     * Form validations.
     * We cannot use form.$invalid because the profile template
     * can contain nested forms and the validation will only pass
     * if all the nested forms are also valid.
     * @return {Boolean} Whether the form has errors or not.
     */
    var validateForm = function() {
      var errors = [],
          hasError = false;

      // At the moment, only the title is required
      if (_.isUndefined(ctrl.template.title) || ctrl.template.title === '') {
        hasError = true;
        errors.push('Please make sure the profile template has a title!');
      }

      if (hasError) {
        Utils.swal({
          title: errors.join('\n'),
          type: 'error'
        });

        return false;
      }

      return !hasError;
    };

    ctrl.canRemoveDefault = false;

    ProfileTemplatesUtils.canRemoveDefaultProfileTemplate()
      .then(function(res) {
        ctrl.canRemoveDefault = res === undefined ? true : res;
      });

    /**
     * Handles the user click on Save.
     * @param  {Object} form The form object being saved.
     * @return {void}
     */
    ctrl.save = function(form) {
      form.$setSubmitted();

      if (!validateForm()) {
        return;
      }

      saveProfileTemplate(ctrl.template, 'Profile template successfully saved!');
    };

    /**
     * Handle the user click on Delete.
     * @return {void}
     */
    ctrl.remove = function() {
      var opts = {
        title: 'Are you sure you want to remove this profile template?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      };

      // If the profile template is the default, we schange the message being displayed
      if (ctrl.template.isDefault) {
        opts.text = 'This is the default profile. Removing this profile ' +
                    'template will only recreate it automatically for you, ' +
                    'but you would lose all the changes you\'ve made!';
      }

      Utils.swal(
        opts,
        function(isConfirm) {
          if (isConfirm) {
            ProfileTemplates.remove($stateParams.id)
              .then(function() {
                Notify.success('Profile template successfully removed!', 'Success');
                $state.go('epf.profile-templates.index');
              })
              .catch(Utils.showError);
          }
        }
      );
    };
  }

  ProfileTemplateController.$inject = [
    '$rootScope',
    '$state',
    '$stateParams',
    'ProfileTemplatesService',
    'ProfileTemplatesUtilsService',
    'UtilsService',
    'AuthService',
    'NotifyService'
  ];

  angular.module('component.dashboardTemplates')
    .controller('ProfileTemplateController', ProfileTemplateController);
})();
