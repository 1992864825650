(function() {
  'use strict';

  function EmailLogsComponentController($state, $rootScope, Profile, EmailLogSearch,
      EmailLogs, EmailLog, List, Utils) {
    var ctrl = this;
    ctrl.options = {
      stateField: 'doc.id',
      trackBy: 'doc.id'
    };
    var listName = 'emailLogs-' + $state.$current.name;

    Utils.setPageTitle('Email logs');

    ctrl.$onInit = function() {
      ctrl.setup();
    };

    ctrl.setup = function() {
      ctrl.searchModel = Profile.getListPreferences(listName).current;
      var makeDocs = function(item) {
        return new EmailLog(item);
      };

      ctrl.list = new List({
        search: EmailLogSearch.search,
        model: ctrl.searchModel,
        idField: 'doc.id',
        makeDocs: makeDocs,
        defaultFilter: ctrl.defaultFilter,
        searchFn: EmailLogs.searchIds.bind(EmailLogs),
        fetchFn: EmailLogs.fetchIds.bind(EmailLogs)
      });
      $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: ctrl.list.search });
      ctrl.list.doLoadItems()
        .catch(function(err) {
          console.log(err);
        });

      ctrl.actions = [
      ];

      // Defined here so that I don't have to create a special slot that does nothing
      ctrl.options.actionButtons = function(_item) {
        return [
          // {
          //   label: 'Edit',
          //   icon: 'icon-edit',
          //   href: (function() {
          //     return $state.href('epf.email-templates.email-type', { emailTypeId: item.doc.id });
          //   })(),
          //   showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          //   klass: 'text-info'
          // }
        ];
      };
    };
  }

  EmailLogsComponentController.$inject = [
    '$state',
    '$rootScope',
    'ProfileService',
    'EmailLogSearch',
    'EmailLogsService',
    'EmailLogFactory',
    'APIIDListFactory',
    'UtilsService'
  ];

  angular.module('component.emailLogs')
    .component('emailLogsComponent', {
      templateUrl: 'app/components/email-logs/email-logs.html',
      controller: EmailLogsComponentController,
      bindings: {
        defaultFilter: '<?'
      }
    });
})();
