
(function() {
  'use strict';

  function ReportOutputFieldDirective($log, Form, Utils) {
    return {
      scope: {
        report: '=',
        field: '=',
        editMode: '=',
        preCalculatedData: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-output-field.html',
      replace: true,
      controllerAs: 'ctrl',
      controller: ['$scope', function(scope) {
        var ctrl = this;

        this.$onInit = function() {
          scope.$watch('field.field', function(value) {
            $log.debug('Initializing form', value);
            var disableAll = !scope.editMode;
            var fields = scope.report.getOutputFormFields(
              scope.field.field,
              disableAll,
              scope.preCalculatedData
            );
            ctrl.outputFields = new Form(fields);
          });

          scope.field.id = scope.field.id || Utils.guid();

          ctrl.removeOutputField = function() {
            _.remove(scope.report.conf.outputFields, scope.field);
          };
        };
      }]
    };
  }

  ReportOutputFieldDirective.$inject = ['$log', 'FormsService', 'UtilsService'];

  angular.module('component.reports')
    .directive('kzReportOutputField', ReportOutputFieldDirective);
})();
