(function() {
  'use strict';

  function MenuTodoLinkDirective() {
    function MenuTodoLinkController($scope, $state, $window, $timeout, EventSections) {
      var ctrl = this;

      ctrl.goToState = function(stateName) {
        var reload = $state.current.name === stateName;

        // We need to hide it for same media as in app/layout/header/header.styles.scss
        if ($window.width <= 992) {
          $timeout(function() {
            angular.element('#collapse-navbar').trigger('click');
          });
        }

        // detect if they are trying to refresh the page by going from stateA to the same stateA
        var opts = { reload: reload };
        $state.transitionTo(stateName, {}, opts);
      };

      var updateTodosCount = function() {
        return EventSections.getTodoInfo()
          .then(function(result) {
            ctrl.count = result.todo;
            ctrl.hasDrafts = result.draft;
          });
      };

      updateTodosCount();

      $scope.$on('DBPullChange', function(_evt, args) {
        if (args.doc.type === 'eventSection') {
          updateTodosCount();
        }
      });

      $scope.$on('ItemReloadRequested', function(_evt, args) {
        if (args.type === 'eventSection') {
          updateTodosCount();
        }
      });
    }

    MenuTodoLinkController.$inject = [
      '$scope',
      '$state',
      '$window',
      '$timeout',
      'EventSectionsService'
    ];

    return {
      restrict: 'EA',
      templateUrl: 'app/layout/header/kz-menu-todo-link.html',
      replace: true,
      controller: MenuTodoLinkController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('kzHeaderCard')
    .directive('kzMenuTodoLink', MenuTodoLinkDirective);
})();
