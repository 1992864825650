(function() {
  'use strict';

  function TargetTitleDirective(Goal) {
    return {
      scope: {
        target: '=?',
        targetId: '@?',
        goalId: '@?',
        includeGoal: '@',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/target.title.html',
      link: function(scope) {
        scope.opts = scope.options || {};

        if (scope.target === undefined && scope.goalId !== undefined) {
          Goal.find(scope.goalId)
            .then(function(goal) {
              var targets = _.filter(goal.targets, { _id: scope.targetId });
              if (targets.length === 1) {
                scope.target = targets[0];
              }
            });
        }
      }
    };
  }

  TargetTitleDirective.$inject = ['GoalFactory'];

  angular.module('component.goals')
    .directive('targetTitle', TargetTitleDirective);
})();
