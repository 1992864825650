(function() {
  'use strict';

  function Slot($rootScope, $state, $location, $window, $transitions) {
    return {
      restrict: 'A',
      template: '<p class="text-gray">' +
      'You have been logged out, probably due to inactivity. ' +
      'Please <a target="_blank" href="{{ href }}?came_from={{ location }}">re-login</a></p>',
      link: function(scope) {
        scope.$watch(function() {
          return $rootScope.needRelogin;
        }, function(value) {
          scope.relogin = value;
          scope.hidden = !value;
        });

        // $rootScope.$on('$stateChangeSuccess', function() {
        $transitions.onSuccess({}, function() {
          scope.location = $window.encodeURIComponent($location.url());
        });

        scope.location = $window.encodeURIComponent($location.url());
        scope.href = $state.href('accounts.login');
      }
    };
  }

  Slot.$inject = ['$rootScope', '$state', '$location', '$window', '$transitions'];

  angular.module('layout.sidebar')
    .directive('sidebarRelogin', Slot);
})();
