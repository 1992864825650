(function() {
  'use strict';

  function UserReviewController(
    $state,
    $stateParams,
    $q,
    $scope,
    Users,
    UserFields,
    Events,
    Notify,
    Auth,
    Utils,
    SweetAlert,
    EVENT_STATES
  ) {
    var ctrl = this;
    var username = $stateParams.user;

    if (username === undefined) {
      $state.go('epf.users.index');
    }

    ctrl.userFields = [];
    UserFields.findAll()
      .then(function(data) {
        ctrl.userFields = data;
      });

    ctrl.user = {};
    ctrl.loadUser = function() {
      return $q.all([
        Users.find(username),
        Events.findAllFor(username, { type: 'private' })
      ])
        .then(function(result) {
          var user = result[0];
          var events = result[1].filter(function(item) {
            return item.doc.state === EVENT_STATES.PENDING_APPROVAL.id;
          });

          if (user.state !== 'waiting_for_approval') {
            $state.go('epf.users.index');
          }

          ctrl.user = user;
          ctrl.events = events;

          var fullName = _.trim(
            (ctrl.profile.firstName || '') + ' ' + (ctrl.profile.lastName || '')
          );
          Utils.setPageTitle('Review ' + fullName);
        })
        .catch(function(err) {
          Utils.showError(err, function() {
            $state.go('epf.users.index');
          });
        });
    };

    function reloadEvents() {
      return Events.findAllFor(username, { type: 'private' })
        .then(function(events) {
          ctrl.events = events.filter(function(item) {
            return item.doc.state === EVENT_STATES.PENDING_APPROVAL.id;
          });
          ctrl.eventRoles = {};
        });
    }

    $scope.$on('ReviewEventUpdated', function() {
      reloadEvents();
    });

    ctrl.loadUser();

    ctrl.eventRoles = {};
    ctrl.registerRoles = function(event, roles) {
      ctrl.eventRoles[event] = roles;
    };

    ctrl.isSatisfied = function() {
      var values = _.values(ctrl.eventRoles).filter(function(val) {
        return _.intersection(val, ctrl.user.roles).length === 0;
      });
      return values.length === 0;
    };

    // Duplicate code with the users.controller
    ctrl.updateState = function(user, action) {
      var options = {
        title: 'Are you sure you want to ' + action + ' this user?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      };

      if (action === 'reject') {
        options.type = 'input';
        options.inputPlaceholder = 'Tell us the reason of that rejection';
      }

      SweetAlert.swal(options,
        function(data) {
          var reason = '';
          if (action === 'reject') {
            if (data === '') {
              SweetAlert.swal.showInputError('Please give a reason.');
              return false;
            }

            reason = data;
          }

          if (data) {
            Users.save(ctrl.user)
              .then(function() {
                return Users.updateState(user, action, Auth.currentUser(), reason);
              })
              .then(function() {
                Notify.success('The user has been ' + action + ' successfully!', 'Nice!');
                $state.go('dashboard.summary', { user: ctrl.user.username });
              })
              .catch(function(error) {
                ctrl.loadUser();
                Utils.showError(error);
              });
          }
        });
    };
  }

  UserReviewController.$inject = [
    '$state',
    '$stateParams',
    '$q',
    '$scope',
    'UsersService',
    'UserFieldsService',
    'EventsService',
    'NotifyService',
    'AuthService',
    'UtilsService',
    'SweetAlert',
    'EVENT_STATES'
  ];

  angular.module('component.users')
    .controller('UserReviewController', UserReviewController);
})();
