import * as Hashes from 'jshashes';

(function() {
  'use strict';

  function TurnitinService($q, $uibModal, Notify, Features, EasApi) {
    var service = {};

    service.timers = {};

    service.isEnabled = function() {
      if (service.enabled !== undefined) {
        return $q.when(service.enabled);
      }

      return Features.isOn('turnitin', {})
        .then(function(res) {
          service.enabled = res;
          return res;
        });
    };

    service.submitFile = function(file, title, metadata) {
      var payload = {
        title: title || 'Checked data',
        data: file,
        metadata: metadata || {}
      };
      return service.submit(payload)
        .then(function(res) {
          if (res.status === 'action_required') {
            return service.acceptEula(res.eula)
              .then(function(accepted) {
                if (accepted) {
                  payload.eula = {
                    version: res.eula.version
                  };
                  return service.submit(payload);
                }
              });
          }
          return res;
        })
        .then(function(res) {
          var submission = {
            id: res.id,
            state: res.state,
            score: res.report ? res.report.overall_match_percentage : null,
            uploaded_hash: res.uploaded_hash,
            error_message: res.error_message,
            error_code: res.error_code,
            created_date: res.created_date
          };
          return submission;
        });
    };


    service.waitFor = function(submission) {
      var q = $q.defer();

      var updateSubmission = function(submission) {
        return service.get(submission.id)
          .then(function(data) {
            console.log(data);
            const newSubmission = angular.copy(submission);
            newSubmission.state = data.state;
            // subId.report = data.report;
            newSubmission.score = data.report ? data.report.overall_match_percentage : null;
            newSubmission.uploaded_hash = data.uploaded_hash;
            newSubmission.error_message = data.error_message;
            newSubmission.error_code = data.error_code;
            newSubmission.created_date = data.created_date;
            return newSubmission;
          });
      };

      var updator = function(submission) {
        updateSubmission(submission)
          .then(function(newSubmission) {
            if (service.isFinished(newSubmission)) {
              console.log('turnitIn: startUpdator: finished state');
              q.resolve(newSubmission);
              return;
            }

            if (submission.state.state !== newSubmission.state.state) {
              console.log('turnitIn: startUpdator: state changed');
              q.notify(newSubmission);
            }

            service.stopUpdator(newSubmission);
            const timer = setTimeout(function() {
              console.log('Running timer', timer);
              updator(newSubmission);
              delete service.timers[newSubmission.id];
            }, 5000);
            service.timers[newSubmission.id] = timer;
            console.log('Start timer', timer);
          })
          .catch(function(err) {
            console.log(err);
            q.reject(err);
          });
      };
      updator(submission);

      return q.promise;
    };

    service.stopUpdator = function(submission) {
      if (service.timers[submission.id]) {
        console.log('Clearing timer', service.timers[submission.id]);
        clearTimeout(service.timers[submission.id]);
        delete service.timers[submission.id];
      }
    };

    service.openViewer = function(submission) {
      return service.getViewerUrl(submission.id)
        .then(function(res) {
          console.log(res);
          if (res.status === 'action_required') {
            return service.acceptEula(res.eula)
              .then(function(accepted) {
                if (accepted) {
                  var eula = {
                    version: res.eula.version
                  };
                  return service.getViewerUrl(submission.id, eula);
                }
              });
          }
          return res;
        })
        .then(function(res) {
          console.log(res);
          if (res.response.viewer_url) {
            window.open(res.response.viewer_url, '_blank');
          } else {
            Notify.error('Report Viewer not currently available, please try again later');
          }
        })
        .catch(function(err) {
          console.log(err);
          Notify.error('Report Viewer not currently available, please try again later');
        });
    };

    service.acceptEula = function(eula) {
      var modal = $uibModal.open({
        animation: true,
        size: 'lg',
        component: 'turnitinEula',
        resolve: {
          eula: function() {
            return eula;
          }
        }
      }).result;

      return modal
      .then(function(data) {
        console.log(data);
        return data;
      })
      .catch(function(err) {
        console.log(err);
        return false;
      });
    };

    service.isFinished = function(submission) {
      var finishedStates = ['file_error', 'complete', 'report_error'];
      return finishedStates.indexOf(submission.state.state) !== -1;
    };

    /** Low level API */
    service.submit = function(payload) {
      var url = 'turnitin/submissions';
      // var payload = {
      //   data: data,
      //   metadata: metadata || {}
      // };
      return EasApi.post(url, payload, undefined, { version: '' });
    };

    service.forceUpdate = function(submissionId) {
      var url = `turnitin/submissions/${submissionId}/force_update`;

      return EasApi.post(url, {}, undefined, { version: '' });
    };

    service.getViewerUrl = function(submissionId, eula) {
      var url = `turnitin/submissions/${submissionId}/viewer_url`;
      var params = {};
      if (eula) {
        params.eula = eula;
      }

      return EasApi.post(url, params, undefined, { version: '' });
    };

    service.get = function(submissionId) {
      var url = `turnitin/submissions/${submissionId}`;

      return EasApi.get(url, {}, undefined, { version: '' });
    };

    service.getHash = function(data) {
      if (!data) {
        return '';
      }

      if (data.key) {
        return new Hashes.MD5().hex(data.key);
      }

      return new Hashes.MD5().hex(data);
    };

    service.getSettings = function() {
      return EasApi.get('turnitin/settings', undefined, undefined, { version: '' });
    };

    service.postSettings = function(payload) {
      return EasApi.post('turnitin/settings', payload, undefined, { version: '' });
    };

    service.registerWebhook = function(payload) {
      return EasApi.post('turnitin/webhooks/register', payload, undefined, { version: '' });
    };

    service.getWebhookUrl = function() {
      return EasApi.absUrl('turnitin/webhooks', { version: '' });
    };

    return service;
  }

  TurnitinService.$inject = [
    '$q',
    '$uibModal',
    'NotifyService',
    'FeaturesService',
    'EasApiService'
  ];

  angular.module('blocks.utils')
    .factory('TurnitinService', TurnitinService);
})();
