(function() {
  'use strict';

  function EventSectionExtraDirective(Utils, Auth, Security,
                                      LocalizationService) {
    return {
      scope: {
        event: '=',
        displayOptions: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-extra.html',
      replace: true,
      link: function($scope) {
        $scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

        // Not doing this will cause a angular expression is non-assignable error
        $scope.opts = _.assign({}, $scope.displayOptions);

        var updateCounts = function(extras) {
          var totalComments,
              totalTags;

          totalComments = _.reduce(extras, function(total, extra) {
            return extra.comment ? total + 1 : total;
          }, 0);

          totalTags = _.chain(extras)
                      .filter(function(extra) {
                        return extra.blueprints;
                      })
                      .map(function(extra) {
                        return extra.blueprints;
                      })
                      .flatten()
                      .uniq()
                      .value()
                      .length;

          return {
            tags: totalTags,
            comments: totalComments
          };
        };

        function loadExtra() {
          return $scope.event.getExtra()
            .then(function(extras) {
              return _.map(extras, function(extra) {
                if (angular.isString(extra.date)) {
                  var modified = extra.date;
                  delete extra.date;
                  extra.date = { modified: modified };
                }

                return extra;
              });
            })
            .then(function(extras) {
              $scope.extras = extras.filter(x =>
                (x.tags && x.tags.length > 0) ||
                (x.blueprints && x.blueprints.length > 0) ||
                x.comment
              );
              $scope.total = updateCounts(extras);
            });
        }

        loadExtra();

        $scope.addPendingEventExtra = function(newExtra) {
          $scope.extras = _.union($scope.extras, [newExtra]);
        };

        // pre-load extra
        $scope.extra = { tags: [], commentType: null };

        $scope.canDelete = function(extra) {
          if ($scope.deleteAll) {
            return true;
          }

          if (extra.actor === Auth.currentUser() && $scope.deleteOwn) {
            return true;
          }

          return false;
        };

        var loadPermissions = function() {
          var defAll;
          var defOwn;

          if ($scope.event.doc.user === Auth.currentUser()) {
            defAll = Security.hasPermission('comments.deleteall.own');
            defOwn = Security.hasPermission('comments.deleteown.own');
          } else {
            defAll = Security.hasPermissionFor('comments.deleteall', $scope.event.doc.user);
            defOwn = Security.hasPermissionFor('comments.deleteown', $scope.event.doc.user);
          }

          defAll.then(function() {
            $scope.deleteAll = true;
          });

          defOwn.then(function() {
            $scope.deleteOwn = true;
          });
        };

        loadPermissions();

        $scope.delete = function(extra, dbType, showDialog) {
          function _delete() {
            return $scope.event.deleteExtra(extra, dbType)
              .then(function() {
                // Remove object from $scope.extras and don't query the db
                var index = $scope.extras.indexOf(extra);
                if (index !== -1) {
                  $scope.extras.splice(index, 1);
                }

                $scope.extra = {};
                $scope.showForm = false;
              })
              .catch(function(error) {
                if (error.reason) {
                  error.message = error.reason;
                }

                Utils.showError(error);
              });
          }

          if (showDialog) {
            Utils.swal({
              title: 'Are you sure you want to remove this comment?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK'
            },
              function(isConfirm) {
                if (isConfirm) {
                  return _delete();
                }
              });
          } else {
            return _delete();
          }
        };
      }
    };
  }

  EventSectionExtraDirective.$inject = [
    'UtilsService',
    'AuthService',
    'SecurityService',
    'LocalizationService'
  ];

  angular.module('events.directives')
    .directive('eventSectionExtra', EventSectionExtraDirective);
})();
