(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.todos', {
        url: 'todos/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          // setup: ['SetupService', function(Setup) {
          //   return Setup.setupApp();
          // }],
          redirect: ['$q', function($q) {
            return $q.reject({
              status: 302,
              state: 'dashboard.activity'
            });
          }]
        }
      })

      .state('epf.todos.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/todos/list.html',
            controller: 'TodosController',
            controllerAs: 'todosCtrl'
          }
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.todos')
    .config(config);
})();
