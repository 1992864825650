(function() {
  'use strict';

  function size() {
    return function(bytes) {
      if (bytes < 1024) {
        return bytes + ' B';
      } else if (bytes < 1048576) {
        return (bytes / 1024).toFixed(2) + ' KB';
      }

      return (bytes / 1048576).toFixed(2) + ' MB';
    };
  }

  size.$inject = [];

  angular.module('component.roles')
    .filter('fileSize', size);
})();
