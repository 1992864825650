/* jshint unused: false */

(function() {
  'use strict';

  function config($stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider) {
    $urlMatcherFactoryProvider.strictMode(false);

    $urlRouterProvider.otherwise(function($injector) {
      var $state = $injector.get('$state');
      $state.go('dashboard.index');
    });

    $stateProvider
      .state('layout', {
        url: '',
        templateUrl: 'app/layout/templates/main.html',
        abstract: true,
        resolve: {
          appInit: ['SetupService', function(Setup) {
            return Setup.initApp();
          }],
          setup: ['appInit', 'SetupService', function(_appInit, Setup) {
            return Setup.setupApp();
          }]
        }
      })
      .state('epf', {
        url: '/',
        parent: 'layout',
        abstract: true,
        views: {
          main: {
            templateUrl: 'app/layout/templates/2-columns-right.html'
          }
        }
      })

      .state('errors', {
        url: '/',
        abstract: true,
        templateUrl: 'app/layout/templates/1-column.html'
      })

      .state('errors.error', {
        url: 'error/',
        views: {
          content: {
            templateUrl: 'app/core/error.html',
            controller: 'ErrorController',
            controllerAs: 'ctrl'
          }
        },
        params: {
          error: null,
          toState: null,
          toParams: null
        }
      })

      .state('errors.upgrade', {
        url: 'error/',
        views: {
          content: {
            templateUrl: 'app/core/upgrade.html',
            controller: 'UpgradeController',
            controllerAs: 'ctrl'
          }
        },
        params: {
          error: null,
          toState: null,
          toParams: null
        }
      })

      .state('help', {
        url: '/',
        abstract: true,
        templateUrl: 'app/layout/templates/1-column.html'
      })
      .state('help.helpeditor', {
        url: '__help__secret__',
        views: {
          content: {
            templateUrl: 'app/core/helpeditor.html',
            controller: 'HelpEditorController',
            controllerAs: 'ctrl'
          }
        }
      })

      .state('errors.differentUser', {
        url: 'differentUser/',
        views: {
          content: {
            templateUrl: 'app/core/error.different-user.html',
            controller: 'ErrorDifferentUserController',
            controllerAs: 'ctrl'
          }
        },
        params: {
          error: null,
          toState: null,
          toParams: null
        }
      });

    // $locationProvider.html5Mode(true).hashPrefix('!');
  }

  config.$inject = ['$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider'];

  angular
    .module('epf.core')
      .config(config);
})();
