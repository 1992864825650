(function() {
  'use strict';

  function eventPendingSections(Events, Utils) {
    return {
      scope: {
        event: '=',
        section: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-pending-sections.html',
      replace: true,
      link: function(scope) {
        scope.processing = {};
        scope.inner = {};

        scope.update = function() {
          return scope.event.getPendingSections(scope.section._id)
            .then(function(data) {
              scope.inner.pending = _.sortBy(data, 'id');
              scope.show = true;
            });
        };

        scope.update();
        scope.accept = function(eventSection) {
          scope.processing[eventSection.id] = true;
          Events.sectionAccept(eventSection.id)
            .then(function(res) {
              console.log(res);
            })
            .then(function() {
              return scope.event.reload();
            })
            .then(function() {
              return scope.update();
            })
            .catch(Utils.showError)
            .finally(function() {
              scope.processing[eventSection.id] = false;
            });
        };
      }
    };
  }

  eventPendingSections.$inject = ['EventsService', 'UtilsService'];

  angular.module('events.directives')
    .directive('eventPendingSections', eventPendingSections);
})();
