(function() {
  'use strict';

  function EventSectionSlotController(
    $scope,
    $stateParams,
    $timeout,
    Auth,
    LocalizationService,
    EVENT_STATES
  ) {
    var ctrl = this;

    ctrl.EVENT_STATES = EVENT_STATES;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    ctrl.useCompactView = $scope.useCompactView || false;

    ctrl.eventSection = $scope.eventSection;

    var mapping = {
      recent: 'modifiedDate',
      notrecent: 'modifiedDate',
      startDate: 'occured',
      endDate: 'occured'
    };

    var orderedBy = _.isObject($scope.options) ? $scope.options.orderedBy : 'addedDate';
    ctrl.dateToShow = mapping[orderedBy] || 'createdDate';

    ctrl.username = $stateParams.user || Auth.currentUser();
    function load() {
      ctrl.eventSection.init()
        .then(function() {
          ctrl.href = ctrl.eventSection.getViewLink($stateParams.user);
          ctrl.sectionStates = ctrl.eventSection.getProgress();

          if ($scope.options && $scope.options.search && $scope.options.search.filteredBy) {
            ctrl.highlight = $scope.options.search.filteredBy[$scope.options.highlightField];
          }

          /* Action buttons */
          if (($scope.options || {}).actionButtons) {
            ctrl.actionButtons = $scope.options.actionButtons(ctrl.eventSection);
          } else {
            ctrl.actionButtons = angular.copy(
              ctrl.eventSection.loadActionButtons($stateParams.user) || []
            );
          }

          var ab = angular.copy(ctrl.actionButtons);
          var previewActions = _.filter(ab, { position: 0 });
          var previewSecondaryActions = _.filter(ab, { position: 1 });

          ctrl.secondaryActionButtons = angular.copy(
            ctrl.eventSection.secondaryActionButtons || []
          );

          if (!$scope.useCompactView) {
            ctrl.secondaryActionButtons.push({
              label: 'Preview',
              icon: 'icon-preview',
              onClick: function() {
                ctrl.eventSection.openEventPreview({
                  actions: previewActions,
                  secondaryActions: previewSecondaryActions
                });
              },
              klass: 'text-info'
            });

            if (ctrl.eventSection.doc.dates) {
              ctrl.actionButtons.push({
                id: 'showDescription',
                icon: 'icon-history',
                label: ctrl.shownDescription ? 'Hide audit log' : 'Show audit log',
                onClick: function() {
                  ctrl.shownDescription = !ctrl.shownDescription;
                  this.label = ctrl.shownDescription ? 'Hide audit log' : 'Show audit log';
                }
              });
            }
          }

          ctrl.featuredButtons = [];
          ctrl.featuredButtons.push({
            label: '',
            icon: 'icon-preview',
            onClick: function() {
              ctrl.eventSection.openEventPreview({
                actions: previewActions,
                secondaryActions: previewSecondaryActions
              });
            },
            klass: 'text-info'
          });

          // Make sure we load them all as links on a slot
          _.forEach(ctrl.actionButtons, function(button) {
            button.position = 0;
            button.type = 'link';
          });

        //   getTokens();
        });
    }
    ctrl.shownDescription = false;
    load();

    ctrl.update = function() {
      load();
    };

    $scope.$watch('eventSection.doc._rev', function() {
      if (ctrl.eventSection.doc._rev === $scope.eventSection.doc._rev) {
        return;
      }
      ctrl.eventSection = $scope.eventSection;
      $timeout(function() {
        console.log('Updating: reloading actions');
        ctrl.update();
      });
    });
  }

  EventSectionSlotController.$inject = [
    '$scope',
    '$stateParams',
    '$timeout',
    'AuthService',
    'LocalizationService',
    'EVENT_STATES'
  ];

  function EventSectionSlotDirective() {
    return {
      scope: {
        eventSection: '=',
        options: '=',
        dates: '=',
        showActionButtons: '=',
        bulkTaggingSelectedTags: '=',
        useCompactView: '=?'
      },
      restrict: 'AE',
      templateUrl: function(_elem, attrs) {
        return attrs.useCompactView
          ? 'app/components/events/directives/eventsection-slot-compact.html'
          : 'app/components/events/directives/eventsection-slot.html';
      },
      replace: true,
      controller: EventSectionSlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventSectionSlot', EventSectionSlotDirective)
    .controller('EventSectionSlotController', EventSectionSlotController);
})();
