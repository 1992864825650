(function() {
  'use strict';

  function SessionStorageService($window) {
    var service = {};
    service.data = {};

    service.popItem = function(key) {
      try {
        var data = $window.localStorage.getItem(key);
        $window.sessionStorage.removeItem(key);
        return (angular.extend({}, angular.fromJson(data)));
      } catch (err) {
        console.log(err);
        return {};
      }
    };

    service.getItem = function(key) {
      try {
        var data = $window.sessionStorage.getItem(key);
        return (angular.extend({}, angular.fromJson(data)));
      } catch (err) {
        console.log(err);
        return {};
      }
    };

    service.setItem = function(key, item) {
      try {
        $window.sessionStorage.setItem(key, angular.toJson(item));
      } catch (err) {
        console.log(err);
      }
    };

    return service;
  }

  SessionStorageService.$inject = ['$window'];

  angular.module('blocks.network')
    .service('kzSessionStorage', SessionStorageService);
})();
