(function() {
  'use strict';

  function NewDraftController(Form, EventSections, AutoSave, Utils, Security, Notify) {
    var ctrl = this;

    ctrl.model = {
      description: ''
    };

    ctrl.form = new Form([
      { id: 'description', type: 'text', label: 'Notes', required: true }
    ]);

    // ctrl.event = new Event({});
    // ctrl.event.doc.quickDraft = true;
    // ctrl.event.setInitial()
    //   .then(function() {
    //     return ctrl.event.checkPermission('canCreate');
    //   })
    //   .then(function() {
    //     ctrl.authorized = true;
    //   });

    Security.hasPermission('events.create.own')
      .then(function() {
        ctrl.authorized = true;
      });

    ctrl.save = function(form) {
      if (form.$invalid) {
        return;
      }

      var doc = EventSections.getInitial();
      doc.meta = _.assignIn({ quickDraft: true }, doc.meta, ctrl.model);
      doc.saveInfo = AutoSave.createSaveInfo('manual');

      EventSections.save(doc)
        .then(function() {
          // clear the draft
          ctrl.model.description = '';
          form.$setPristine();
          form.$setUntouched();
          Notify.success('You can retrieve this from your own timeline.', 'Saved as draft');
        })
        .catch(function(error) {
          Utils.swal({
            title: error.message,
            type: 'error'
          });
        });
    };
  }

  NewDraftController.$inject = [
    'FormsService',
    'EventSectionsService',
    'AutoSaveService',
    'UtilsService',
    'SecurityService',
    'NotifyService'
  ];

  function config(dashboardProvider) {
    dashboardProvider.widget('eventDraft', {
      title: 'Quick note',
      description: 'Create a quick note',
      controller: 'NewDraftController',
      controllerAs: 'newDraftCtrl',
      templateUrl: 'app/components/events/widgets/new-draft/view.html',
      edit: {},
      category: 'Events'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.events')
    .config(config)
    .controller('NewDraftController', NewDraftController);
})();
