(function() {
  'use strict';

  function UpdatorService(
    $rootScope,
    // eslint-disable-next-line no-unused-vars
    $document,
    $q,
    $timeout,
    $log,
    kzLocalStorage,
    Auth,
    LocalStore,
    BaseVersionStore,
    BaseUserStore,
    BaseConfStore,
    Network,
    EventTypes,
    Events,
    EventSections
  ) {
    var service = {};

    var pushTimer;
    var updateTimer;
    var pushError;
    var preloadTimer;

    function keepPushing() {
      // Do not even try if current user is not set
      // as we know we are unauthorised and we don't even need
      // to broadcast we tried
      if (_.isEmpty(Auth.currentUser()) || $rootScope.needRelogin) {
        pushTimer = $timeout(keepPushing, 5000);
        return $q.when();
      }

      return Auth.getAllowSyncInOffline()
        .then(function(res) {
          if (res) {
            return LocalStore.pushAll();
          }
        })
        .then(function() {
          pushError = undefined;
        })
        .catch(function(err) {
          pushError = err;
        })
        .finally(function() {
          $rootScope.$broadcast('KZLocalPushed', { error: pushError });
          pushTimer = $timeout(keepPushing, 5000);
        });
    }

    // eslint-disable-next-line no-unused-vars
    var updateTypes = function() {
      return Auth.hasCurrentUser()
        .then(function() {
          return Auth.getStorageMode();
        })
        .then(function(mode) {
          if (mode !== 'persistent') {
            return $q.reject();
          }

          $log.debug('Updating local store');
          return $q.all([
            BaseUserStore.updateTypes(),
            BaseVersionStore.updateTypes(),
            BaseConfStore.updateTypes()
          ])
          .catch(function(err) {
            console.log(err);
          });
        })
        .then(function() {
          updateTimer = $timeout(updateTypes, 600000);
        });
    };

    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'hidden') {
        service.stopUpdator();
      } else {
        service.startUpdator();
      }
    });

    var started;
    service.startUpdator = function() {
      if (started) {
        // $log.warn('Updator is already running');
        return;
      }

      started = true;
      $log.debug('Starting updator');
      keepPushing();
      // updateTimer = $timeout(updateTypes, 15000);
      preloadTimer = $timeout(function() {
        return service.preloadForOffline();
      }, 30000);
    };

    service.stopUpdator = function() {
      $log.debug('Stopping updator');
      if (pushTimer) { $timeout.cancel(pushTimer); }
      if (updateTimer) { $timeout.cancel(updateTimer); }
      if (preloadTimer) { $timeout.cancel(preloadTimer); }
      started = false;
    };

    service.getPreloadKey = function() {
      return Auth.currentUser() + '_' + Auth.currentOrganisation() + ':last_offline_preload:v2';
    };

    service.preloadForOffline = function() {
      if (Auth.noIDBSupport) {
        console.log('Preload: Skip due to no IDB support ');
      }

      console.log('Preload: Start preloading data');
      return service._preloadForOffline()
        .finally(function() {
          console.log('Preload: Finished preloading data');
          kzLocalStorage.setItem(service.getPreloadKey(), { date: new Date() });
          preloadTimer = $timeout(function() {
            return service._preloadForOffline();
          }, 3600 * 1000);
        });
    };

    function updateEventTypes() {
      return EventTypes.findAvailableForViaApi(Auth.currentUser())
        .then(function(evts) {
          var ids = _.map(evts, '_id');
          return EventTypes.fetchIds(ids, { transformType: 'full' });
        })
        .then(function(data) {
          return EventTypes.storeItems(_.map(data, function(item) {
            return item.doc;
          }), { reset: true });
        })
        .catch(function(err) {
          console.log('Failed to store event types', err);
        });
    }

    function _updateEvents(fltr) {
      var search = _.assign({}, { size: 50 }, fltr);
      return Events.searchOwnIds(search)
        .then(function(evs) {
          var ids = _.map(evs.hits, 'id');
          return Events.fetchIds(ids);
        })
        .then(function(evsObjs) {
          var docs = _.map(evsObjs, 'doc');
          var evs = _.filter(docs, { type: 'event' });
          var evsecs = _.filter(docs, { type: 'eventSection' });
          var evts = _.filter(_.uniqBy(_.map(evsObjs, 'eventType'), '_id'), Boolean);

          return $q.all([
            Events.storeItems(evs),
            EventSections.storeItems(evsecs),
            EventTypes.storeItems(evts)
          ]);
        })
        .then(function() {
          console.log('Preload events done');
        })
        .catch(function(err) {
          console.log('Failed to store events', err);
        });
    }

    function updateTodos() {
      return _updateEvents({ extendedState: ['todo'] });
    }

    function updateDrafts() {
      return _updateEvents({ extendedState: ['draft'] });
    }

    function updateEvents() {
      return _updateEvents({});
    }

    service._preloadForOffline = function() {
      if (Network.isOffline()) {
        return $q.when();
      }

      var lastPreload = kzLocalStorage.getItem(service.getPreloadKey());
      if (lastPreload) {
        try {
          var lastDate = new Date(lastPreload.date);
          var now = new Date();
          if (now - lastDate < 3600 * 1000) {
            console.log('Preload: Skipping as it is still recent');
            return $q.when();
          }
        } catch (err) {
          console.log('Preload: Failed parsing date. Continuing');
        }
      }

      return $q.when()
        .then(updateEventTypes)
        .then(updateTodos)
        .then(updateDrafts)
        .then(updateEvents);
    };

    return service;
  }

  UpdatorService.$inject = [
    '$rootScope',
    '$document',
    '$q',
    '$timeout',
    '$log',
    'kzLocalStorage',
    'AuthService',
    'LocalStoreService',
    'BaseVersionStoreService',
    'BaseUserStoreService',
    'BaseConfStoreService',
    'NetworkService',
    'EventTypesService',
    'EventsService',
    'EventSectionsService'
  ];

  angular.module('blocks.network')
    .service('UpdatorService', UpdatorService);
})();
