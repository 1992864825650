(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.relations', {
        url: 'relations',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('relations.view');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.relations.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/relations/list.html',
            controller: 'RelationsController',
            controllerAs: 'relationsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.relations.new', {
        url: '/new',
        views: {
          content: {
            templateUrl: 'app/components/relations/form.html',
            controller: 'RelationController',
            controllerAs: 'relationCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('relations.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        },
        params: {
          defaults: null
        }
      })

      .state('epf.relations.edit', {
        url: '/edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/relations/form.html',
            controller: 'RelationController',
            controllerAs: 'relationCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.relations.reorder', {
        url: '/reorder',
        views: {
          content: {
            templateUrl: 'app/components/relations/list-reorder.html',
            controller: 'RelationsReorderController',
            controllerAs: 'relationsReorderCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('relations.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.relations')
    .config(config);
})();
