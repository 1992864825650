(function() {
  'use strict';

  function EventsStore(
    $q,
    $log,
    $rootScope,
    Cache,
    Lunr,
    Notify,
    Auth,
    Events,
    Event,
    EventSections,
    EventSection,
    EventTypes
  ) {
    var service = {};

    // var invalidateKey = 'events-store-events';

    var indexOptions = {
      version: '0.0.9',
      userAware: true,
      persistentCache: true
    };

    $rootScope.$on('KZStoreInvalidated', function(_evt, args) {
      if (args.type === 'event' || args.type === 'eventSection') {
        delete service._data;
      }
    });

    $rootScope.$on('KZEventInvalidated', function(_evt, args) {
      $log.debug('Invalidating: ', args.id);
      service.invalidateEvent(args.id);
    });

    $rootScope.$on('KZClearCache', function() {
      service._data = undefined;
      service._legace_data = undefined;
    });

    $rootScope.$on('StoreUpdated', function(_evt, args) {
      if (['event', 'eventSection'].indexOf(args.doc.type) === -1) {
        return;
      }

      if (service._data === undefined) {
        return;
      }

      var gid = args.doc.event || args.doc._id;
      if (args.doc.type === 'eventSection' && args.doc.eventOwner !== Auth.currentUser()) {
        gid = args.doc._id;
      }

      var found = _.find(service._data, function(item) {
        return item.id === gid;
      });

      if (args.doc._deleted) {
        if (found !== undefined) {
          _.remove(service._data, found);
        }

        $rootScope.$broadcast('SpecialStoreUpdated', {
          store: 'events',
          action: 'remove',
          id: gid,
          type: 'event'
        });
      } else if (found === undefined) {
        var obj = { id: gid };
        if (args.doc.type === 'event') {
          obj.event = service.toObject({ doc: args.doc });
          obj.sections = [];
        } else if (args.doc.type === 'eventSection') {
          obj.sections = [service.toObject({ doc: args.doc })];
        }

        service._data.push(service.pickMain(obj));

        $rootScope.$broadcast('SpecialStoreUpdated', {
          store: 'events',
          action: 'add',
          item: obj,
          type: 'event'
        });
      } else {
        $log.warn('Reloading', found.id);
        if (args.doc.type === 'event') {
          if (found.event !== undefined) {
            console.log('Reloading event for ', gid);
            found.event.reload(args.doc);
          } else {
            found.event = service.toObject({ doc: args.doc });
          }
        } else {
          var item = service.toObject({ doc: args.doc });

          var sec = _.findIndex(found.sections || [], function(section) {
            return section.doc._id === args.doc._id;
          });

          if (sec === -1) {
            found.sections.push(item);
          } else {
            found.sections.splice(sec, 1, item);
          }
        }

        service.pickMain(found);
        $rootScope.$broadcast('SpecialStoreUpdated', {
          store: 'events',
          action: 'update',
          item: found,
          type: 'event'
        });
      }
    });

    service.toObject = function(item) {
      if (item.doc.type === 'event') {
        return new Event(item.doc, { shared: item.isShared });
      } else if (item.doc.type === 'eventSection') {
        return new EventSection(item.doc, { isLocal: true, noExtra: false });
      }

      throw { status: 500, message: 'Cannot construct ' + item.doc.type };
    };

    service.loadEvents = function() {
      $log.debug('Re-fetching events');
      return Events.findAll();
    };

    service.loadEventSections = function() {
      $log.debug('Re-fetching events sections');
      return EventSections.findAll()
        .then(function(data) {
          return _.filter(data, function(item) {
            return item.doc.state !== 'merged' || item.doc.user !== item.doc.eventOwner;
          });
        });
    };

    service.load = function() {
      if (service._legace_data !== undefined) {
        return $q.when(service._legace_data);
      }

      return EventTypes.findAll()
        .then(function() {
          return $q.all([service.loadEventSections(), service.loadEvents()]);
        })
        .then(function(result) {
          // Filter out events that have their own eventSection
          // as sections take precendence

          var secIds = _.map(result[0], function(item) {
            return item.doc.event;
          });

          var filteredEvents = _.filter(result[1], function(item) {
            return secIds.indexOf(item.doc._id) === -1;
          });

          // Merge events with sections
          var res = _(result[0]).concat(filteredEvents).value();

          // Create objects using relevant factories
          res = _.map(res, service.toObject.bind(this));

          // Store for later access
          service._legace_data = res;
          return res;
        });
    };

    service.loadGrouped = function(options) {
      options = options || {};

      if (service._data !== undefined) {
        return $q.when(service._data);
      }

      var currentUser = Auth.currentUser();

      return EventTypes.findAll()
        .then(function() {
          var promises = [service.loadEventSections()];
          if (!options.onlySections) {
            promises.push(service.loadEvents());
          }

          return $q.all(promises);
        })
        .then(function(result) {
          // Filter out events that have their own eventSection
          // as sections take precendence
          var events = result.length === 2 ? result[1] : [];

          var grouped = _.groupBy(result[0], 'doc.event');
          var merged = [];
          _.forEach(events, function(item) {
            merged.push({
              id: item.doc._id,
              event: service.toObject(item),
              sections: _.map(grouped[item.doc._id] || [], service.toObject.bind(this))
            });

            delete grouped[item.doc._id];
          });

          _.forOwn(grouped, function(sections, id) {
            var same = [];
            _.forEach(sections, function(item) {
              if (item.doc.eventOwner === currentUser) {
                same.push(service.toObject(item));
              } else {
                merged.push({
                  id: item.doc._id,
                  sections: [service.toObject(item)]
                });
              }
            });

            if (same.length > 0) {
              merged.push({
                id: id,
                sections: same
              });
            }
          });

          // Load and figure out the main
          merged = _.map(merged, service.pickMain.bind(this));

          // Store for later access
          if (!options.onlySections) {
            service._data = merged;
          }
          return merged;
        });
    };

    service.pickMain = function(group) {
      if (group.sections === undefined || group.sections.length === 0) {
        group.main = group.event;
        return group;
      }

      var draft = _.find(group.sections, function(item) {
        return item.doc.state === 'draft';
      });

      if (draft !== undefined) {
        group.main = draft;
        return group;
      }

      group.main = group.event || group.sections[0];
      return group;
    };

    function prepareItemForFulltext(event) {
      return event.init()
        .then(function() {
          return event.prepareForFulltext();
        })
        .catch(function(error) {
          $log.error('Could not prepare event ', event.doc._id, ': ', error);
          return {
            id: event.doc._id,
            title: event.doc._id
          };
        });
    }

    function _identity(item) {
      return item.getExtra({ cached: true })
        .then(function(extra) {
          return [item.doc._id, item.doc._rev + '-' + extra.length];
        });
    }

    service.updateIndex = function(index) {
      return service.load()
        .then(function(data) {
          return index.index(data, _.assign({}, indexOptions, {
            preprocess: prepareItemForFulltext,
            ident: _identity
          }));
        })
        .then(function(index) {
          return index.cache();
        });
    };

    service.getIndex = function(options) {
      var ext = _.assignIn({
        key: 'kzindex-events.timeline',
        maxAge: 0,
        cached: true
      });

      var func = service._getIndex.bind(this, options);
      return Cache.cachedPromise(func, ext);
    };

    service._getIndex = function(options) {
      options = options || {};
      var promise = Lunr.getIndex('events.timeline', indexOptions);

      if (options.create || options.uptodate) {
        promise = promise.then(function(index) {
          if (index === undefined) {
            Notify.warning('Updating index');
          }

          return index !== undefined ? index : service.createIndex();
        });
      }

      if (options.uptodate) {
        promise = promise
          .then(function(index) {
            return service.updateIndex(index);
          });
      }

      return promise;
    };

    service.invalidateEvent = function(eventId) {
      return service.getIndex()
        .then(function(index) {
          if (index) {
            index.remove(eventId);
            return index.cache();
          }
        });
    };

    service.createIndex = function() {
      return Lunr.createIndex('events.timeline', function() {
        this.field('title');
        this.field('description');
        this.field('user');
        this.field('field');
        this.field('extra');
        this.field('blueprints');
        this.field('documents');
        this.ref('id');
      }, indexOptions);
    };

    service.search = function(data, options) {
      options = options || {};
      var prom = options.own ? Events.searchOwnIds(data) : Events.searchIds(data);
      return prom.then(function(ids) {
        return _.map(ids.hits, 'id');
      });
    };

    service.fetch = function(data, options) {
      return service.search(data, options)
        .then(function(ids) {
          return Events.fetchIds(_.map(ids.hits, 'id'));
        });
    };

    /**
     * Transform an event for use in light (normally this should come from API)
     * So this is mainly for offline use
     */
    service.transform = function(event) {
      var transformed = {
        type: 'light',
        doc: event.doc
      };

      var prom = $q.when();
      if (event.doc.eventType) {
        prom = EventTypes.find(event.doc.eventType)
          .then(function(eventType) {
            transformed.eventType = eventType;
            // Prepare sections
            var sections = event.doc.sections || {};
            transformed.sections = _.map(eventType.sections || [], function(section) {
              var evSec = sections[section._id];
              var item = {
                id: section._id,
                goalSection: !!_.find(section.fields || [], { type: 'goal' }),
                multiSource: !!section.multiSource
              };
              if (evSec === undefined) {
                item.state = 'missing';
              } else {
                item.state = evSec.state;
                item.responseCount = (evSec.items || []).length;
              }
              return item;
            });
          })
          .catch(function(error) {
            console.log(error);
          });
      }
      return prom
        .then(function() {
          return transformed;
        });
    };

    return service;
  }

  EventsStore.$inject = [
    '$q',
    '$log',
    '$rootScope',
    'CacheService',
    'KZLunr',
    'NotifyService',
    'AuthService',
    'EventsService',
    'EventFactory',
    'EventSectionsService',
    'EventSectionFactory',
    'EventTypesService'
  ];

  angular.module('component.events')
    .factory('EventsStore', EventsStore);
})();
