(function() {
  'use strict';

  function EventPreviewDirective($q, $rootScope, Event, Events, Roles) {
    return {
      scope: {
        event: '=',
        user: '=',
        registerRoles: '&'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-preview.html',
      link: function link(scope) {
        scope.eventObj = new Event();
        scope.roles = [];
        scope.eventObj.init(scope.event)
          .then(function() {
            scope.neededRoles = scope.eventObj.currentSection.def.filledBy;

            var roles = [];
            scope.neededRoles.forEach(function(roleId) {
              roles.push(Roles.find(roleId, { cached: true }));
            });

            $q.all(roles)
              .then(function(result) {
                scope.roles = result;
              });
            scope.registerRoles({ event: scope.event._id, roles: scope.neededRoles });
          });

        // Load original event
        function loadOriginal() {
          return Events.findFor(scope.event._id, scope.event.user)
            .then(function(data) {
              var original = new Event(data.doc);
              return original.init();
            })
            .then(function(original) {
              scope.original = original;
            });
        }

        loadOriginal();

        scope.grant = function(role) {
          if (scope.user.roles.indexOf(role._id) === -1) {
            scope.user.roles.push(role._id);
          }
        };

        scope.revoke = function(role) {
          if (scope.user.roles.indexOf(role._id) > -1) {
            _.remove(scope.user.roles, function(value) {
              return value === role._id;
            });
          }
        };

        scope.makeObsolete = function() {
          scope.eventObj.originUser = scope.user.username;
          return scope.eventObj.makeObsolete()
            .then(function() {
              $rootScope.$broadcast('ReviewEventUpdated');
            });
        };

        scope.hasNeededRoles = function() {
          return _.intersection(scope.neededRoles, scope.user.roles).length > 0;
        };
      }
    };
  }

  EventPreviewDirective.$inject = ['$q', '$rootScope', 'EventFactory', 'EventsService',
    'RolesService'];

  angular.module('events.directives')
    .directive('eventPreview', EventPreviewDirective);
})();
