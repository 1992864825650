(function() {
  'use strict';

  function SearchService() {
    var service = {};

    service.getFilters = function() {
      return ['filter1', 'filter2'];
    };

    return service;
  }

  angular.module('blocks.widgets')
    .service('SearchService', SearchService);
})();
