(function() {
  'use strict';

  function ActivityController($rootScope, $timeout, Utils) {
    var ctrl = this;
    ctrl.name = ' ';

    Utils.setPageTitle('Activities');

    ctrl.todoWidget = {
      type: 'eventTodo',
      config: {
        show: 'todo',
        sort: 'recent'
      },
      title: 'To Do'
    };

    ctrl.draftWidget = {
      type: 'eventTodo',
      config: {
        show: 'draft',
        sort: 'recent'
      },
      title: 'Saved drafts'
    };

    ctrl.template = {
      title: ctrl.name,
      structure: '6-6',
      rows: [
        {
          columns: [
            {
              styleClass: 'col-sm-6',
              widgets: [
                ctrl.todoWidget
              ]
            },
            {
              styleClass: 'col-sm-6',
              widgets: [
                ctrl.draftWidget
              ]
            }
          ]
        }
      ]
    };

    $timeout(function() {
      $rootScope.$broadcast('KzPageLoaded');
    }, 1000);
  }

  ActivityController.$inject = [
    '$rootScope',
    '$timeout',
    'UtilsService'
  ];

  angular.module('component.todos')
    .controller('ActivityController', ActivityController);
})();
