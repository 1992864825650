(function() {
  'use strict';

  function EventTypesStore($q, EventTypes, EventTypeLightFactory, EventTypeFactory) {
    var service = {};

    service.searchIds = function(data) {
      return EventTypes.searchIds(data);
    };

    service.fetchIds = function(ids, options) {
      return EventTypes.fetchIds(ids, options);
    };

    service.fetch = function(data, options) {
      return service.searchIds(data)
        .then(function(ids) {
          return service.fetchIds(_.map(ids.hits, 'id'), options);
        });
    };

    service.fetchLight = function(data, options) {
      return service.fetch(data, options)
        .then(function(data) {
          return _.map(data, function(item) {
            return new EventTypeLightFactory(item);
          });
        });
    };

    service.getLight = function(id) {
      return service.fetchIds([id], { transformType: 'light' })
        .then(function(data) {
          if (data.length === 0) {
            return $q.reject({ status: 404, message: 'Not found' });
          }

          return new EventTypeLightFactory(data[0]);
        });
    };

    service.getFull = function(id) {
      return service.fetchIds([id], { transformType: 'full' })
        .then(function(data) {
          if (data.length === 0) {
            return $q.reject({ status: 404, message: 'Not found' });
          }

          return EventTypeFactory.fromFull(data[0]);
        });
    };

    return service;
  }

  EventTypesStore.$inject = [
    '$q', 'EventTypesService', 'EventTypeLightFactory', 'EventTypeFactory'
  ];

  angular.module('component.eventTypes')
    .factory('EventTypesStore', EventTypesStore);
})();
