(function() {
  'use strict';

  function Form($q, EventTypes, Utils) {
    return {
      scope: {
        fields: '=',
        model: '=',
        eventTypeId: '=',
        eventId: '=',
        sectionId: '=',
        user: '=',
        highlight: '=',
        options: '=',
        parent: '=?'
      },
      restrict: 'EA',
      templateUrl: 'app/blocks/widgets/forms/readonly-form.html',
      link: function(scope) {
        var options = scope.options || {};

        var prom;
        if (scope.eventTypeId) {
          prom = EventTypes.find(scope.eventTypeId, { cached: true })
            .then(function(def) {
              var fieldsDef = {};
              _.forEach(def.sections, function(section) {
                _.forEach(section.fields, function(field) {
                  if (field.restricted) {
                    fieldsDef[field._id] = field.restricted;
                  }
                });
              });
              return fieldsDef;
            });
        } else {
          prom = $q.when();
        }

        prom
          .then(function(fieldsDef) {
            _.forEach(scope.fields, function(field, idx) {
              var fieldProm = $q.when(field);
              if (field.templateOptions && field.templateOptions.loader) {
                fieldProm = field.templateOptions.loader()
                  .then(function(fld) {
                    // this is overriding the field and "field" here is not exploitable anymore
                    field.templateOptions.applyLoader(fld);
                    return scope.fields[idx];
                  });
              }

              fieldProm
                .then(function(f) {
                  var show = true;
                  var fid = (f.templateOptions || {}).fieldId || f.id;
                  if (
                    !_.isUndefined(f.id) &&
                    !_.isUndefined(fieldsDef) &&
                    fieldsDef[fid] &&
                    fieldsDef[fid].by &&
                    !options.ignoreRestricted
                  ) {
                    show = false;

                    var restricted = fieldsDef[fid],
                        value = scope.model[restricted.by];

                    if (_.isUndefined(restricted.to)) {
                      show = Utils.bool(value);
                    } else if (_.indexOf(restricted.to, 'any') > -1) {
                      show = !_.isUndefined(value);
                    } else if (_.isArray(value) && _.isArray(restricted.to)) {
                      show = !_.isEmpty(_.intersection(value, restricted.to));
                    } else if (_.isArray(value) && !_.isArray(restricted.to)) {
                      show = _.indexOf(value, restricted.to) > -1;
                    } else if (!_.isArray(value) && _.isArray(restricted.to)) {
                      show = _.indexOf(restricted.to, value) > -1;
                    } else {
                      show = value === restricted.to;
                    }
                  }
                  f.templateOptions.show = show;
                });
            });
          })
          .finally(function() {
            scope.show = true;
          });
      }
    };
  }

  Form.$inject = ['$q', 'EventTypesService', 'UtilsService'];

  angular.module('widgets.forms')
    .directive('kzReadonlyForm', Form);
})();
