(function() {
  'use strict';

  function UserDetailsController($scope, Form, Notify, Security, UserFields, User, Utils) {
    var ctrl = this;

    ctrl.action = $scope.action;

    if (ctrl.action === 'edit') {
      $scope.$watch('ctrl.userDetailsFormCon', function(val) {
        $scope.$emit('KZUserFormChanged', { form: val });
      });
      ctrl.profile = angular.copy($scope.profile);
    } else {
      ctrl.profile = $scope.profile;
    }

    UserFields.findAll({ withVisibility: true, owner: ctrl.profile.username })
      .then(function(userFields) {
        userFields = angular.copy(userFields);
        // all fields should be not disabled by default
        _.forEach(userFields, function(field) {
          field.doc.disabled = false;
        });
        userFields = _.sortBy(userFields, function(field) {
          return (field.doc.order);
        });

        if (ctrl.action === 'view') {
          ctrl.userFields = _.chain(userFields)
            .map(function(field) {
              return { id: field.doc._id, value: field.doc.name, type: field.doc.fieldType };
            })
            .value();
        } else if (_.indexOf(['create', 'edit'], ctrl.action) > -1) {
          ctrl.userDetailsForm = new Form();
          ctrl.detailsGroup = ctrl.userDetailsForm.addGroup();

          Security.hasPermission('users.edit.locked')
            .then(function() { return true; }).catch(function() { return false; })
            .then(function(canEditLockedUserFields) {
              return _.chain(angular.copy(userFields))
                      .filter(function(field) {
                        // hide all fields non visible to users
                        return field.doc.isVisible;
                      })
                      .map(function(field) {
                        // disable all locked fields if the user doesn't have the permission
                        if ((field.doc.isLocked && !canEditLockedUserFields)) {
                          field.doc.disabled = true;
                        }
                        return field;
                      })
                      .value();
            })
            .then(function(fields) {
              ctrl.userDetailsForm.extendFromDb(fields, ctrl.detailsGroup);
              fields.forEach(function(fld) {
                // We should be sanitizing the data somehow. Not sure how though
                if (fld.doc.fieldType === 'numeric') {
                  var value = ctrl.profile[fld.id];
                  if (value && typeof value !== 'number') {
                    ctrl.profile[fld.id] = parseInt(value);
                  } else if (value === '') {
                    ctrl.profile[fld.id] = undefined;
                  }
                }
              });
            });
        }
      });

    ctrl.isArray = function(item) {
      return _.isArray(item);
    };

    ctrl.saveProfile = function(isValid) {
      if (!isValid) {
        Utils.showError({ message: 'Form is not valid' });
        return;
      }

      var formFields = _.filter(ctrl.userDetailsForm.fields[0].fieldGroup, function(field) {
        return !field.templateOptions.disabled;
      });

      return User.partiallySaveProfile('userDetails', formFields, ctrl.profile)
        .then(function(newProfile) {
          _.assignIn($scope.profile, newProfile);
          ctrl.profile = newProfile;
          Notify.success('The additional details have been saved!');
          ctrl.userDetailsFormCon.$setPristine();
        })
        .catch(function(e) {
          Notify.error(e.message, 'An error occured while saving');
        });
    };
  }

  UserDetailsController.$inject = [
    '$scope',
    'FormsService',
    'NotifyService',
    'SecurityService',
    'UserFieldsService',
    'UserFactory',
    'UtilsService'
  ];

  function userDetailsDirective() {
    return {
      scope: {
        action: '@',
        profile: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/directives/userDetails.html',
      replace: true,
      controller: UserDetailsController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.users')
    .directive('userDetails', userDetailsDirective)
    .controller('UserDetailsController', UserDetailsController);
})();
