(function() {
  'use strict';

  function ReportViewEventsController($state, $scope, ReportViewer, Utils) {
    var ctrl = this;

    Utils.setPageTitle('Report viewer');

    ctrl.loading = true;
    function loadEvents() {
      if (ctrl.list === undefined) {
        ctrl.list = ReportViewer.list;
      }
    }

    // This is for the case when it's done faster then this controller is loaded
    if (ReportViewer.list && ReportViewer.list.initialized) {
      loadEvents();
    }

    $scope.$on('reportsviewer.listUpdated', function() {
      loadEvents();
    });

    ctrl.showActionButtons = $state.current.data.showActionButtons;

    ctrl.report = {};
  }

  ReportViewEventsController.$inject = ['$state', '$scope', 'reportViewer', 'UtilsService'];

  angular.module('component.reports')
    .controller('ReportViewEventsController', ReportViewEventsController);
})();
