(function() {
  'use strict';

  function validator(Utils) {
    return {
      require: 'ngModel',
      link: function(scope, _element, _attrs, ngModel) {
        ngModel.$validators.emailDomain = function(value) {
          if (!scope.allowedDomains) {
            return true;
          }

          if (_.isEmpty(value)) {
            return true;
          }

          if (_.isObject(value)) {
            return true;
          }
          return Utils.validateEmail(value, scope.allowedDomains);
        };
      }
    };
  }

  validator.$inject = ['UtilsService'];
  angular.module('blocks.utils')
    .directive('inviteDomainValidator', validator);
})();
