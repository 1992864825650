(function() {
  'use strict';

  function ProfileTemplatesService(
    $q,
    EasApiStore,
    Profile,
    Users,
    TemplatesUtils,
    DEFAULT_PROFILE_TEMPLATE
  ) {
    var service = new EasApiStore('profile-template');
    service.preCache = function() { return $q.all([service.findAll()]); };

    /**
     * Return all the profile templates for the currently viewed user.
     * @return {Promise}        A promise containing all the templates the logged in user
     *                          can view for the currently viewed user.
     */
    service.findAllForUser = function() {
      return service.findAll() // find all the templates
        .then(function(items) {
          var templates = _.chain(items)
                  .map(function(item) { return item; }) // we just need the doc
                  .value();

          var remoteUser = Users.remoteUser;

          var promises = [
            Users.find(remoteUser), // we need the currently viewed user's profile for the roles
            Profile.getPreferences(), // and the currently logged in user's preferences
            templates
          ];

          return $q.all(promises)
            .then(function(data) {
              return TemplatesUtils.getTemplatesForUser(
                data[0].roles, // roles
                data[1], // prefs
                data[2], // templates
                'profiles', // type
                DEFAULT_PROFILE_TEMPLATE // defaultTemplate
              );
            });
        });
    };

    return service;
  }

  ProfileTemplatesService.$inject = [
    '$q',
    'EasApiStore',
    'ProfileService',
    'UsersService',
    'TemplatesUtilsService',
    'DEFAULT_PROFILE_TEMPLATE'
  ];

  angular.module('component.dashboardTemplates')
    .factory('ProfileTemplatesService', ProfileTemplatesService);
})();
