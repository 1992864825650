(function() {
  'use strict';

  function AuditLogController() {
    var ctrl = this;
    ctrl.conf = {
      shown: false,
      showHeader: true,
      loaded: false
    };

    ctrl.toggleExpand = function() {
      if (!ctrl.conf.shown && !ctrl.conf.loaded) {
        if (ctrl.onExpand !== undefined) {
          ctrl.onExpand();
          ctrl.conf.loaded = true;
        }
      }
      ctrl.conf.shown = !ctrl.conf.shown;
    };
  }

  AuditLogController.$inject = [
  ];

  angular.module('blocks.widgets')
    .component('auditLogComponent', {
      templateUrl: 'app/blocks/widgets/auditlog/audit-log.component.html',
      controller: AuditLogController,
      bindings: {
        entries: '<',
        onExpand: '&'
      }
    });
})();
