(function() {
  'use strict';

  function UsersStubService($q, RestApi, Api, Profile, Auth) {
    var service = new RestApi('usersstub');

    service.findAll = function(options) {
      options = options || {};

      return Api.post('es_usersstub', options)
        .then(function(data) {
          return data.hits;
        });
    };

    service.getAllOptions = function(filter) {
      var options = _.assignIn({}, { sort_on: 'firstname' }, filter);

      return service.findAll(options)
        .then(function(users) {
          return _.chain(users)
            .map(function(user) {
              return {
                _id: user.username,
                key: user.username,
                name: _.trim(
                  (user.firstName || '') + ' ' +
                  (user.lastName || '') + ' - ' +
                  (user.email || '')
                ) || user.username
              };
            })
            .value();
        });
    };

    service.find = function(id) {
      if (!_.isString(id)) {
        return $q.reject({ status: 404, message: 'Unknown user' });
      }

      if (id === Auth.currentUser()) {
        return Profile.find();
      }

      return Api.get(this.rest_endpoint, { _id: id });
    };

    return service;
  }

  UsersStubService.$inject = ['$q', 'RestApi', 'ApiService', 'ProfileService', 'AuthService'];

  angular.module('component.users')
    .factory('UsersStubService', UsersStubService);
})();
