(function() {
  'use strict';

  function filter(Utils) {
    return function(item, join) {
      return Utils.separateByUppercase(item, join);
    };
  }

  filter.$inject = ['UtilsService'];

  angular.module('blocks.utils')
    .filter('separateByUppercase', filter);
})();
