(function() {
  'use strict';

  function AutoSaveDirective(
    $rootScope,
    $timeout,
    Drafts,
    AutoSave,
    AUTOSAVE_INTERVAL,
    AUTOSAVE_EVENTS
  ) {
    return {
      scope: {
        info: '=',
        skipInitialSave: '=?',
        handleSave: '&?'
      },
      restrict: 'AE',
      template: '',
      replace: true,
      link: function(scope) {
        /**
         * Hold the autosave action into a variable so that
         * we can later cancel the $timeout when the $scope is destroyed.
         */
        var saveAction;

        /**
         * Kick start the autosave process
         */
        var startAutoSave;

        var skipInitialSave = scope.skipInitialSave || false;

        var startTimer = function() {
          saveAction = $timeout(startAutoSave, AUTOSAVE_INTERVAL);
        };

        startAutoSave = function() {
          return AutoSave.save(scope.info)
            .then(function() {
              skipInitialSave = false;
            })
            .finally(function() {
              startTimer();
            });
        };

        // Let's watch for model changes
        var updateModel = function(newVal, oldVal) {
          scope.info.shouldSave = Drafts.shouldSave(newVal, oldVal);

          if (scope.info.shouldSave && !skipInitialSave) {
            $rootScope.$broadcast(AUTOSAVE_EVENTS.unsavedChanges, { id: newVal._id });
          }
        };

        function init() {
          startAutoSave();

          // FIXME: This is an expensive operation
          // Can we just watch on certain props of the model?
          scope.$watch('info.model', updateModel, true);

          // Let's remove the timeout
          scope.$on('$destroy', function() {
            $timeout.cancel(saveAction);
          });

          // Listen for any action where the user saved/published a document
          $rootScope.$on(AUTOSAVE_EVENTS.userSaved, function(_evt, args) {
            if (args.id) {
              Drafts.findForId(args.id)
                .then(function(doc) {
                  if (doc) {
                    Drafts.remove(doc.id);
                  }
                });
            }
          });
        }

        // Delay the init till fields are loaded, this means that we would
        // start autosave a bit later
        $timeout(init.bind(this), AUTOSAVE_INTERVAL);
      }
    };
  }

  AutoSaveDirective.$inject = [
    '$rootScope',
    '$timeout',
    'DraftsService',
    'AutoSaveService',
    'AUTOSAVE_INTERVAL',
    'AUTOSAVE_EVENTS'
  ];

  angular.module('component.drafts')
    .directive('kzAutoSave', AutoSaveDirective);
})();
