(function() {
  'use strict';

  function Slot($q, Profile, Event, Goal, LocalizationService) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/events/cards/linkingtargets.card.html',
      link: function(scope) {
        scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

        // load event and watch changes
        var prefs = Profile.getPreferences();
        scope.eventSection = prefs.currentEventSection;

        scope.hidden = _.isUndefined(scope.eventSection.doc.meta);

        // load targets
        scope.loadTargets = function(options) {
          scope.isLoading = true;

          // hide the card if it's not mine or there are some completed sections
          if (scope.eventSection === undefined ||
              !scope.eventSection.isMine() ||
              scope.eventSection.event.completedSections.length > 0) {
            scope.hidden = true;
            return;
          }

          if (_.isUndefined(scope.eventSection)) {
            scope.hidden = true;
          } else {
            var event = scope.eventSection.originalEvent || { completedSections: [] };
            if (!scope.eventSection.isEventOwners() || event.completedSections.length) {
              scope.hidden = true;
            }
          }

          if (scope.hidden) {
            return;
          }

          // find all the targets that have the selected event type as a definition
          options = options || {};
          options.eventType = scope.eventSection.eventType.versionGroupId;
          var linkable = Goal.findLinkableTargets(options);

          // get targets for linked Goal Ids
          var linkedGoalIds = _.map(
            scope.eventSection.doc.meta.linkedTargets || [], function(item) {
              return item.goalId;
            });

          var linked = $q.when([]);
          if (linkedGoalIds.length > 0) {
            linked = Goal.findAll({ goalsIds: linkedGoalIds });
          }

          linked = linked.then(function(goals) {
            var targets = [];
            _.forEach(goals, function(goal) {
              _.forEach(goal.targets, function(target) {
                var item = _.find(
                  scope.eventSection.doc.meta.linkedTargets || [],
                  { goalId: goal.doc._id, targetId: target._id }
                );
                if (item !== undefined) {
                  targets.push(target);
                }
              });
            });
            return targets;
          });
          return $q.all([linked, linkable])
            .then(function(results) {
              var targets = _.chain(results)
                .map(function(targets) {
                  return _.sortBy(
                    targets,
                    function(target) { return target.goal.doc.dueDate; },
                    ['asc']
                  );
                })
                .reduce(function(pre, cur) {
                  return pre.concat(cur);
                })
                .uniq(function(target) {
                  return target.goal.doc._id + target._id;
                })
                .value();

              if (options.generalTargets) {
                scope.generalTargetsLoaded = true;
                scope.generalTargets = targets;
              } else {
                scope.targets = targets;
              }


              scope.isLoading = false;
            })
            .then(function() {
              // initialize linkedTargets
              scope.eventSection.doc.meta.linkedTargets =
                scope.eventSection.doc.meta.linkedTargets || [];
            });
        };

        if (!scope.hidden) {
          scope.$watchCollection('eventSection.doc.eventType', function() {
            scope.targets = [];
            scope.generalTargets = [];
            scope.generalTargetsLoaded = false;

            scope.eventSection.loadEventType()
              .then(function(eventType) {
                scope.eventSection.eventType = eventType || {};
                scope.eventSection.loadMeta();
                scope.eventSection.event = new Event(scope.eventSection.meta);
                scope.eventSection.event.loadSections();
                scope.loadTargets();
              });
          });
        }

        function getLinkedObj(target) {
          return {
            targetId: target._id,
            goalId: target.goal.doc._id
          };
        }

        scope.isLinked = function(target) {
          return _.find(
            scope.eventSection.doc.meta.linkedTargets,
            getLinkedObj(target)
          ) !== undefined;
        };

        scope.linkTarget = function(target) {
          var linkedObj = getLinkedObj(target);
          if (scope.isLinked(target)) {
            _.remove(scope.eventSection.doc.meta.linkedTargets, linkedObj);
          } else {
            scope.eventSection.doc.meta.linkedTargets.push(linkedObj);
          }
        };
      }
    };
  }

  Slot.$inject = ['$q', 'ProfileService', 'EventFactory', 'GoalFactory', 'LocalizationService'];

  angular.module('component.events')
    .directive('sidebarLinkingTargets', Slot);
})();
