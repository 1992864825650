(function() {
  'use strict';

  var CONDITIONS = {
    '=': 'is equal to',

    // '!=': 'is not equal to',
    '<': 'is less than',
    '<=': 'is less or equal than',
    '>': 'is greater than',
    '>=': 'is greater or equal than',
    h: 'has',
    dh: 'does not have',
    relation: 'is in the range of the user\'s relation'
  };

  var FILTERS = {
    goals: {
      eventType: {
        id: 'eventType',
        type: 'discrete_multiple',
        formType: 'eventType',
        label: 'Event type',
        optionsLoader: 'eventType',
        availableConditions: ['=']
      },
      dueDate: {
        id: 'dueDate',
        type: 'date',
        label: 'Due date',
        availableConditions: ['=', '<=', '>=']
      },
      goalTitle: {
        id: 'goalTitle',
        type: 'string',
        label: 'Goal title',
        availableConditions: ['=']
      },
      goalSetState: {
        id: 'goalSetState',
        type: 'discrete_multiple',
        label: 'Goal set state',
        optionsLoader: 'goalSetStates',
        availableConditions: ['=']
      },
      user: {
        id: 'user',
        type: 'discrete_multiple',
        label: 'Event owner',
        fieldExtender: 'user',
        availableConditions: ['=']
      },
      userRoles: {
        id: 'userRoles',
        type: 'discrete_multiple',
        optionsLoader: 'roles',
        label: 'Role of event owner',
        availableConditions: ['=']
      },
      limitToRoles: {
        id: 'limitToRoles',
        type: 'discrete_multiple',
        optionsLoader: 'roles',
        label: 'Event owner visible because of my role as',
        availableConditions: ['=']
      },
      progress: {
        id: 'progress',
        type: 'numeric',
        label: 'Progress',
        extraField: 'periodChooser',
        extraOutField: 'periodChooser',
        availableConditions: ['=', '<=', '<', '>', '>=']
      },
      goalResolve: {
        id: 'goalResolve',
        type: 'discrete_multiple',
        label: 'Resolution',
        optionsLoader: 'goalResolveStates',
        availableConditions: ['=']
      }
    },
    events: {
      eventType: {
        id: 'eventType',
        type: 'discrete_multiple',
        label: 'Event type',
        optionsLoader: 'eventType',
        availableConditions: ['=']
      },
      eventType_versionGroupId: {
        id: 'eventType_versionGroupId',
        type: 'discrete_multiple',
        formType: 'eventType_versionGroupId',
        label: 'Event type',
        optionsLoader: 'eventType_versionGroupId',
        availableConditions: ['=']
      },
      eventState: {
        id: 'eventState',
        type: 'discrete_multiple',
        label: 'Event state',
        optionsLoader: 'eventState',
        availableConditions: ['=']
      },
      blueprint: {
        id: 'blueprint',
        type: 'tree',
        formType: 'discrete',
        label: 'Blueprint',
        fieldExtender: 'blueprint',
        extraOutField: 'blueprintCategory',
        availableConditions: ['=']
      },
      relation: {
        id: 'relation',
        type: 'discrete',
        label: 'Relation of user at start date of event',
        fieldExtender: 'relation',
        extraOutField: 'relationChooser',
        availableConditions: ['=']
      },
      user: {
        id: 'user',
        type: 'discrete_multiple',
        label: 'Event owner',
        fieldExtender: 'user',
        availableConditions: ['=']
      },
      userRoles: {
        id: 'userRoles',
        type: 'discrete_multiple',
        optionsLoader: 'roles',
        label: 'Role of event owner',
        availableConditions: ['=']
      },
      limitToRoles: {
        id: 'limitToRoles',
        type: 'discrete_multiple',
        optionsLoader: 'roles',
        label: 'Event owner visible because of my role as',
        availableConditions: ['=']
      },
      modifiedDate: {
        id: 'modifiedDate',
        type: 'date',
        label: 'Last modified date',
        availableConditions: ['=', '<=', '>='],
        extraConditions: ['relation']
      },
      addedDate: {
        id: 'addedDate',
        type: 'date',
        label: 'Created date',
        availableConditions: ['=', '<=', '>='],
        extraConditions: ['relation']
      },
      completedDate: {
        id: 'completedDate',
        type: 'date',
        label: 'Completed date',
        availableConditions: ['=', '<=', '>=', 'relation'],
        extraConditions: ['relation']
      },
      startDate: {
        id: 'startDate',
        type: 'date',
        label: 'Start date',
        availableConditions: ['=', '<=', '<', '>', '>='],
        extraConditions: ['relation']
      },
      endDate: {
        id: 'endDate',
        type: 'date',
        label: 'End date',
        availableConditions: ['=', '<=', '<', '>', '>='],
        extraConditions: ['relation']
      },
      eventProperty: {
        id: 'eventProperty',
        type: 'string',
        label: 'Event property',
        extraField: 'eventProperty',
        availableConditions: ['=']
      },
      numberOfSections: {
        id: 'numberOfSections',
        type: 'numeric',
        label: 'Number of sections',
        availableConditions: ['=']
      },
      awaitingSection: {
        id: 'awaitingSection',
        type: 'numeric',
        label: 'Awaiting section number',
        availableConditions: ['=']
      },
      inviteDate: {
        id: 'inviteDate',
        type: 'date',
        label: 'Current section - invitation date',
        availableConditions: ['=', '<=', '<', '>', '>=']
      },
      responsibleUser: {
        id: 'responsibleUser',
        type: 'discrete_multiple',
        label: 'Current section - user information of person invited',
        extraOutField: 'userOutputField',
        fieldExtender: 'user',
        availableConditions: ['=']
      },
      responsibleRole: {
        id: 'responsibleRole',
        type: 'discrete_multiple',
        label: 'Current section - role of person invited',
        optionsLoader: 'roles',
        availableConditions: ['=']
      },
      responsibleRelation: {
        id: 'responsibleRelation',
        type: 'discrete_multiple',
        label: 'Current section - relations of person invited',
        fieldExtender: 'relation',
        extraOutField: 'relationChooser',
        availableConditions: ['=']
      }
    },
    sections: {
      eventType: {
        id: 'eventType',
        type: 'discrete_multiple',
        label: 'Event type',
        optionsLoader: 'eventType',
        availableConditions: ['=']
      },
      eventType_versionGroupId: {
        id: 'eventType_versionGroupId',
        type: 'discrete_multiple',
        formType: 'eventType_versionGroupId',
        label: 'Event type',
        optionsLoader: 'eventType_versionGroupId',
        availableConditions: ['=']
      },
      user: {
        id: 'user',
        type: 'discrete_multiple',
        label: 'Respondee',
        fieldExtender: 'user',
        availableConditions: ['='],
        extraOutField: 'userOutputField'
      },
      userRole: {
        id: 'userRole',
        type: 'discrete_multiple',
        label: 'Role of respondee',
        optionsLoader: 'roles',
        availableConditions: ['=']
      },
      userRelation: {
        id: 'userRelation',
        type: 'discrete_multiple',
        label: 'Relations of respondee',
        fieldExtender: 'relation',
        extraOutField: 'relationChooser',
        availableConditions: ['=']
      },
      eventOwner: {
        id: 'eventOwner',
        type: 'discrete_multiple',
        label: 'Event owner',
        fieldExtender: 'user',
        availableConditions: ['='],
        extraOutField: 'userOutputField'
      },
      ownerRole: {
        id: 'ownerRole',
        type: 'discrete_multiple',
        label: 'Role of owner',
        optionsLoader: 'roles',
        availableConditions: ['=']
      },
      ownerRelation: {
        id: 'ownerRelation',
        type: 'discrete_multiple',
        label: 'Relations of owner',
        fieldExtender: 'relation',
        extraOutField: 'relationChooser',
        availableConditions: ['=']
      },
      eventCreatedDate: {
        id: 'eventCreatedDate',
        type: 'date',
        label: 'Event creation date',
        availableConditions: ['=', '<=', '<', '>', '>=']
      },
      startDate: {
        id: 'startDate',
        type: 'date',
        label: 'Start date',
        availableConditions: ['=', '<=', '<', '>', '>=']
      },
      inviteDate: {
        id: 'inviteDate',
        type: 'datetime',
        label: 'Invitation date',
        availableConditions: ['=', '<=', '<', '>', '>=']
      },
      publishDate: {
        id: 'publishDate',
        type: 'datetime',
        label: 'Date responded',
        availableConditions: ['=', '<=', '<', '>', '>=']
      },
      timeToSubmit: {
        id: 'timeToSubmit',
        type: 'timedelta',
        label: 'Time to submit (in seconds)',
        availableConditions: ['=', '<=', '<', '>', '>=']
      },
      sectionIdx: {
        id: 'sectionIdx',
        type: 'numeric',
        label: 'Section number',
        availableConditions: ['=']
      },
      numberOfSections: {
        id: 'numberOfSections',
        type: 'numeric',
        label: 'Number of sections',
        availableConditions: ['=']
      }
    },
    users: {
      user: {
        id: 'user',
        type: 'discrete_multiple',
        label: 'Specific user',
        fieldExtender: 'user',
        availableConditions: ['=']
      },
      firstName: {
        id: 'firstName',
        type: 'string',
        label: 'First name',
        availableConditions: ['=']
      },
      lastName: {
        id: 'lastName',
        type: 'string',
        label: 'Last name',
        availableConditions: ['=']
      },
      fullname: {
        id: 'fullname',
        type: 'string',
        label: 'Full name',
        availableConditions: ['=']
      },
      email: {
        id: 'email',
        type: 'string',
        label: 'E-mail',
        availableConditions: ['=']
      },
      // createdDate: {
      //   id: 'createdDate',
      //   type: 'date',
      //   label: 'Created date',
      //   availableConditions: ['=', '<=', '<', '>', '>=']
      // },
      modifiedDate: {
        id: 'modifiedDate',
        type: 'date',
        label: 'Last modified date',
        availableConditions: ['=', '<=', '<', '>', '>=']
      },
      state: {
        id: 'state',
        type: 'discrete_multiple',
        optionsLoader: 'userState',
        label: 'State',
        availableConditions: ['=']
      },
      roles: {
        id: 'roles',
        type: 'discrete_multiple',
        optionsLoader: 'roles',
        label: 'Roles',
        availableConditions: ['=']
      },
      limitToRoles: {
        id: 'limitToRoles',
        type: 'discrete_multiple',
        optionsLoader: 'roles',
        label: 'User visible because of my role as',
        availableConditions: ['=']
      },
      userField: {
        id: 'userField',
        type: 'string',
        label: 'User field',
        extraField: 'userField',
        availableConditions: ['=']
      },
      relation: {
        id: 'relation',
        type: 'discrete',
        label: 'Current relation',
        fieldExtender: 'relation',
        extraOutField: 'relationChooser',
        availableConditions: ['=']
      },
      dependent: {
        id: 'dependent',
        type: 'discrete_multiple',
        label: 'Users who are linked to',
        // fieldExtender: 'relation',
        extraField: 'roleChooser',
        extraOutField: 'roleChooser',
        fieldExtender: 'user',
        availableConditions: ['=']
      },
      hasevent: {
        id: 'hasevent',
        type: 'discrete_multiple',
        label: 'Has event',
        optionsLoader: 'eventType',
        availableConditions: ['h', 'dh']
      }
    }
  };

  var OUTPUT_FIELDS = angular.copy(FILTERS);

  // We want datetime to show as datetime even-though the filter
  // is just for a date
  OUTPUT_FIELDS.events.addedDate.type = 'datetime';
  OUTPUT_FIELDS.events.modifiedDate.type = 'datetime';
  OUTPUT_FIELDS.events.completedDate.type = 'datetime';
  OUTPUT_FIELDS.events.inviteDate.type = 'datetime';
  delete OUTPUT_FIELDS.users.limitToRoles;
  delete OUTPUT_FIELDS.events.eventType_versionGroupId;
  delete OUTPUT_FIELDS.sections.eventType_versionGroupId;
  delete OUTPUT_FIELDS.events.userRoles;
  delete OUTPUT_FIELDS.events.limitToRoles;
  delete OUTPUT_FIELDS.goals.userRoles;
  delete OUTPUT_FIELDS.goals.limitToRoles;

  OUTPUT_FIELDS.users.credentials = {
    id: 'credentials',
    type: 'string',
    label: 'Login credentials'
  };

  OUTPUT_FIELDS.events.preview = {
    id: 'preview',
    type: 'preview',
    label: 'Preview',
    availableConditions: ['=']
  };

  OUTPUT_FIELDS.events.userField = {
    id: 'userField',
    type: 'string',
    label: 'User field',
    extraField: 'userField',
    availableConditions: ['=']
  };

  OUTPUT_FIELDS.events.goalProgress = {
    id: 'goalProgress',
    type: 'string',
    label: 'Goal progress',
    extraOutFields:
      ['goalProgress', 'periodChooser', 'goalViewType', 'achievedText', 'notAchievedText'],
    availableConditions: ['=']
  };

  OUTPUT_FIELDS.events.allFields = {
    id: 'allFields',
    type: 'extended',
    label: 'All fields'
  };

  OUTPUT_FIELDS.goals.progress.type = 'percent';
  OUTPUT_FIELDS.goals.goalDescription = {
    id: 'goalDescription',
    type: 'string',
    label: 'Description'
  };

  OUTPUT_FIELDS.goals.goalResolveComment = {
    id: 'goalResolveComment',
    type: 'string',
    label: 'Resolution comment'
  };

  OUTPUT_FIELDS.goals.preview = {
    id: 'preview',
    type: 'preview',
    label: 'Preview',
    availableConditions: ['=']
  };

  OUTPUT_FIELDS.goals.order = {
    id: 'goalOrder',
    type: 'numeric',
    label: 'Goal order'
  };

  OUTPUT_FIELDS.sections.event = {
    id: 'event',
    type: 'string',
    label: 'Event ID'
  };

  OUTPUT_FIELDS.sections.eventSection = {
    id: 'eventSection',
    type: 'string',
    label: 'Log ID'
  };

  OUTPUT_FIELDS.sections.timeToSubmit.label = 'Time to submit';
  OUTPUT_FIELDS.sections.eventCreatedDate.tupe = 'datetime';


  // Mark field is disabled for now
  // OUTPUT_FIELDS.goals.mark = {
  //   id: 'mark',
  //   type: 'mark',
  //   label: 'Mark',
  //   availableConditions: ['=']
  // };


  OUTPUT_FIELDS.users.dependent.label = 'Full name of the user(s) with the following role';

  var SORT_FIELDS = angular.copy(OUTPUT_FIELDS);
  delete SORT_FIELDS.events.goalProgress;

  var SINGLE_REPORT_ATTRS = [
    'charts', 'filter', 'description', 'outputFields', 'presource', 'sort', 'source'
  ];

  angular.module('component.reports')
    .constant('REPORT_FILTERS', FILTERS)
    .constant('REPORT_CONDITIONS', CONDITIONS)
    .constant('REPORT_OUTPUT_FIELDS', OUTPUT_FIELDS)
    .constant('REPORT_SORT_FIELDS', SORT_FIELDS)
    .constant('SINGLE_REPORT_ATTRS', SINGLE_REPORT_ATTRS);
})();
