(function() {
  'use strict';

  function UserService(Store) {
    var service = function(docType) {
      this.docType = docType;
      this.store = Store;
    };

    service.prototype.findAll = function(_options, kzOpts) {
      return this.store.findAll(this.docType, kzOpts);
    };

    service.prototype.findKeys = function(keys) {
      return this.store.findKeys(this.docType, keys);
    };

    service.prototype.save = function(doc) {
      return this.store.save(this.docType, doc);
    };

    service.prototype.find = function(id, kzOpts) {
      return this.store.get(this.docType, id, kzOpts);
    };

    service.prototype.remove = function(id) {
      return this.store.remove(this.docType, id);
    };

    service.prototype.storeItems = function(docs) {
      return this.store.storeItems(this.docType, docs);
    };

    return service;
  }

  UserService.$inject = ['BaseUserStoreService'];

  angular.module('blocks.services')
    .factory('UserService', UserService);
})();
