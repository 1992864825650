(function() {
  'use strict';

  var availableDateTimeFormatOptions = {
    inputdate: [
      { format: 'd/M/yyyy', description: 'day/month/year', jq: 'd/m/yyyy', mnt: 'DD/MM/YYYY' },
      { format: 'M/d/yyyy', description: 'month/day/year', jq: 'm/d/yyyy', mnt: 'MM/DD/YYYY' },
      { format: 'd.M.yyyy', description: 'day.month.year', jq: 'd.m.yyyy', mnt: 'DD.MM.YYYY' }
    ],
    date: [
      { format: 'd MMM, yyyy', description: 'day Month, year' },
      { format: 'MMM d, yyyy', description: 'Month, day year' },
      { format: 'd/M/yyyy', description: 'day/month/year' },
      { format: 'M/d/yyyy', description: 'month/day/year' },
      { format: 'd.M.yyyy', description: 'day.month.year' },
      { format: 'd MMMM, yyyy', description: 'day Month, year' },
      { format: 'MMMM d, yyyy', description: 'Month, day year' }
    ],
    time: [
      { format: 'h:mm a', description: '12h format' },
      { format: 'H:mm', description: '24h format' }
    ]
  };

  function LocalizationService($rootScope, $filter, Auth, kzLocalStorage, DATETIME_FORMAT) {
    function formatToOption(dateFormatOption) {
      var format = dateFormatOption.format;
      var description = dateFormatOption.description;
      return {
        _id: format,
        key: format,
        name: $filter('date')(new Date(), format) + ' [' + description + ']'
      };
    }

    var service = {};
    service.defaultDateTimeFormats = {
      inputdate: DATETIME_FORMAT.inputdate,
      dateonly: DATETIME_FORMAT.dateonly,
      timeonly: DATETIME_FORMAT.timeonly,
      datetime: DATETIME_FORMAT.datetime
    };

    service.getDateTimeFormatOptions = function() {
      var inputdate = availableDateTimeFormatOptions.inputdate.map(formatToOption);
      var date = availableDateTimeFormatOptions.date.map(formatToOption);
      var time = availableDateTimeFormatOptions.time.map(formatToOption);

      return {
        inputdate: inputdate,
        date: date,
        time: time
      };
    };

    service.loadDateTimeFormats = function(org, force) {
      if (!force && org && org._id === service.loadedForOrg) {
        console.log('LocalisationService: Skipping for', org ? org._id : 'unknown org');
        return;
      }

      console.log('LocalisationService: Loading date formats', org ? org._id : 'unknown org');
      var setup = org && org.localizationSetup;
      var dateFormats = angular.copy(service.defaultDateTimeFormats);

      if (setup === undefined || setup === null) {
        service.dateFormats = dateFormats;
        service.loadedForOrg = org._id;
        return;
      }

      if (setup.inputDateFormat) {
        dateFormats.inputdate = setup.inputDateFormat;
      }

      if (setup.dateFormat) {
        dateFormats.dateonly = setup.dateFormat;
      }

      if (setup.timeFormat) {
        dateFormats.timeonly = setup.timeFormat;
      }

      dateFormats.datetime = dateFormats.dateonly + ' ' + dateFormats.timeonly;
      kzLocalStorage.setItem('lastUsedDateFormats', { dateTimeFormats: dateFormats });
      service.dateFormats = dateFormats;
      service.loadedForOrg = org._id;
      console.log('LocalisationService: Loaded', dateFormats);
    };

    service.getJQDateTimeFormat = function(option) {
      var lastUsedDateFormats = this.getDateTimeFormats();
      var available = availableDateTimeFormatOptions[option] || [];
      var fm = _.find(available, { format: lastUsedDateFormats[option] });
      if (fm) {
        return fm.jq;
      }
      return lastUsedDateFormats[option];
    };

    service.getMomentDateTimeFormat = function(option) {
      var lastUsedDateFormats = this.getDateTimeFormats();
      var available = availableDateTimeFormatOptions[option] || [];
      var fm = _.find(available, { format: lastUsedDateFormats[option] });
      if (fm) {
        return fm.mnt;
      }
      return lastUsedDateFormats[option];
    };


    service.getDateTimeFormat = function(option) {
      var lastUsedDateFormats = this.getDateTimeFormats();
      return lastUsedDateFormats[option];
    };

    service.getDateTimeFormats = function() {
      if (!service.dateFormats) {
        throw new Error('Date formats are not initialised');
      }
      return service.dateFormats;
    };

    $rootScope.$on('OrganisationSettingsChanged', function(_evt, args) {
      if (args && args.org && args.org._id === Auth.currentOrganisation()) {
        service.loadDateTimeFormats(args.org, true);
      }
    });

    // $rootScope.$watch(function() {
    //   return Auth.currentOrganisation();
    // }, function(org) {
    //   service.loadDateTimeFormats(org);
    // });

    return service;
  }

  LocalizationService.$inject = [
    '$rootScope',
    '$filter',
    'AuthService',
    'kzLocalStorage',
    'DATETIME_FORMAT'
  ];

  angular.module('component.organisations')
    .service('LocalizationService', LocalizationService);
})();
