(function() {
  'use strict';

  var mapping = {
    pending: 'progress-border-complete',
    bounced: 'progress-border-danger',
    delivered: 'progress-border-complete',
    other: 'progress-border-info'
  };

  function EmailLogFactory() {
    var EmailLog = function(doc) {
      this.doc = doc.doc;
    };

    EmailLog.prototype.getBorderClass = function() {
      return mapping[this.doc.state_summary];
    };

    return EmailLog;
  }

  EmailLogFactory.$inject = [
  ];

  angular.module('component.emailLogs')
    .factory('EmailLogFactory', EmailLogFactory);
})();
