(function() {
  'use strict';

  function LastSavedDirective($rootScope, $filter, AutoSave, moment,
    LocalizationService, AUTOSAVE_EVENTS) {
    return {
      scope: {
        info: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/drafts/last-saved.html',
      replace: true,
      link: function($scope) {
        /**
         * Calculate the last modified date and format it properly.
         * @param  {String} date The date to format.
         * @return {String}     The formatted date.
         */
        var calculateLastModified = function(date) {
          if (date === undefined) {
            return 'unsaved';
          }
          var dateFormat = LocalizationService.getDateTimeFormat('datetime');
          var timeFormat = LocalizationService.getDateTimeFormat('timeonly');
          var md;
          if (moment().diff(moment(date), 'hours') < 48) {
            md = moment(date).calendar(moment(), timeFormat);
          } else {
            md = $filter('date')(date, dateFormat);
          }

          return md;
        };

        var updateLastSave = function() {
          var lastSaved = AutoSave.getSaveInfo($scope.info).lastSaved;
          $scope.lastSaved = calculateLastModified(lastSaved);
        };

        // // Watch for ID changes
        // $scope.$watchCollection(
        //   function() {
        //     return $scope.id;
        //   },
        //   function(newVal, oldVal) {
        //     if (newVal !== oldVal) {
        //       // Get the last saved date for this ID
        //       Drafts.getLastSavedForId($scope.id)
        //         .then(function(date) {
        //           $scope.lastSaved = calculateLastModified(date);
        //         })
        //         .catch(function(_err) {
        //           $scope.lastSaved = null;
        //         });
        //     }
        //   }
        // );

        $scope.unsavedChanges = false;

        updateLastSave();
        // Watch for when the model has just been saved
        $rootScope.$on(AUTOSAVE_EVENTS.saved, function() {
          $scope.unsavedChanges = false;
          updateLastSave();
        });

        $rootScope.$on(AUTOSAVE_EVENTS.saveInfoUpdate, function() {
          updateLastSave();
        });

        // Watch for when the model has unsaved changes
        $rootScope.$on(AUTOSAVE_EVENTS.unsavedChanges, function() {
          $scope.unsavedChanges = true;
        });
      }
    };
  }

  LastSavedDirective.$inject = [
    '$rootScope',
    '$filter',
    'AutoSaveService',
    'moment',
    'LocalizationService',
    'AUTOSAVE_EVENTS'
  ];

  angular.module('component.drafts')
    .directive('kzLastSaved', LastSavedDirective);
})();
