(function() {
  'use strict';

  function EventExtrasService(UserService, Auth, Api, Utils) {
    var service = new UserService('eventExtra');

    service.saveApi = function(eventId, extra) {
      return Api.put('eventextras', extra, { event_id: eventId });
    };

    service.removeApi = function(eventId, extra) {
      return Api.delete('eventextras', extra, { event_id: eventId });
    };

    service.findApi = function(eventId) {
      return Api.get('eventextras', {}, { event_id: eventId });
    };

    service.findSectionApi = function(eventSectionId) {
      return Api.get('eventsectionextras', {}, { eventsection_id: eventSectionId });
    };

    service.getInitial = function() {
      return angular.copy({
        _id: Utils.guid(),
        type: 'eventExtra',
        actor: Auth.currentUser(),
        organisation: Auth.currentOrganisation(),
        visibility: 'public',
        date: {
          added: Utils.now(),
          modified: Utils.now()
        }
      });
    };

    service.findForEvent = function(eventId) {
      return this.findAll()
        .then(function(data) {
          return _.chain(data)
            .filter(function(item) {
              return item.doc.event === eventId;
            })
            .map(function(item) {
              return item.doc;
            })
            .value();
        });
    };

    return service;
  }

  EventExtrasService.$inject = [
    'UserService',
    'AuthService',
    'ApiService',
    'UtilsService'
  ];

  angular.module('component.events')
    .factory('EventExtrasService', EventExtrasService);
})();
