(function() {
  'use strict';

  function SlotController($scope, $uibModal, LocalizationService) {
    var ctrl = this;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('datetime');
    if (!$scope.item.loaded) {
      $scope.item.init()
        .then(function() {
          ctrl.sectionStates = $scope.item.getProgress();
        });
    }

    $scope.submitted = $scope.item.getDateFor('section_publish');

    function openPreview() {
      $uibModal.open({
        animation: true,
        size: 'lg',
        templateUrl: 'app/components/admin/pending-section.preview.html',
        controller: 'SectionPreviewController',
        controllerAs: 'sectionCtrl',
        resolve: {
          resolvedDoc: function() {
            return $scope.item;
          },
          resolvedEventType: function() {
            return $scope.item.eventType;
          }
        }
      });
    }

    ctrl.actionButtons = [
      {
        label: 'Preview',
        icon: 'icon-view',
        onClick: openPreview,
        klass: 'text-info'
      }
    ];

    ctrl.secondaryActionButtons = [
      {
        label: 'Show audit log',
        icon: 'icon-history',
        onClick: function() {
          ctrl.shownDescription = !ctrl.shownDescription;
          this.label = ctrl.shownDescription ? 'Hide audit log' : 'Show audit log';
        },
        klass: 'text-info'
      }
    ];
  }

  SlotController.$inject = ['$scope', '$uibModal', 'LocalizationService'];

  function SlotDirective() {
    return {
      scope: {
        item: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/admin/pending-section.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.admin')
    .directive('sectionSlot', SlotDirective)
    .controller('SectionSlotController', SlotController);
})();
