(function() {
  'use strict';

  function BlueprintMapsService($q, Blueprints, EasApiStore, Utils) {
    var service = new EasApiStore(
      'blueprint-maps',
      { cacheKeys: [], usePersistentCache: true, envelope: 'doc' }
    );

    service.getByUsage = function(usage) {
      return service.findAll()
        .then(function(data) {
          var filtered = _.filter(data, function(item) {
            return (item.doc.usage || []).indexOf(usage) !== -1;
          });

          return _.sortBy(filtered, function(item) {
            return item.doc.name;
          });
        });
    };

    service.loadBMapBlueprints = function(blueprintMap) {
      var sourceBlueprints = blueprintMap.blueprints;
      var destCoords = blueprintMap.coordinate_values;
      var destBlueprints = _.chain(destCoords)
        .filter(function(coord) {
          return coord.type === 'blueprint';
        })
        .map(function(coord) {
          return coord.id;
        })
        .value();

      var allBlueprints = [];
      allBlueprints = allBlueprints.concat(sourceBlueprints);
      allBlueprints = allBlueprints.concat(destBlueprints);

      return Blueprints.ensureUpdatedStore()
        .then(function() {
          var proms = _.map(allBlueprints, function(blp) {
            return Blueprints.find(blp, { cached: true });
          });
          return $q.all(proms);
        })
        .then(function(blps) {
          var blueprints = {};
          _.forEach(blps, function(blp) {
            blueprints[blp._id] = {
              blueprint: blp,
              categories: Utils.flattenCategories(blp.categories, blp, blp)
            };
          });
          return blueprints;
        });
    };

    return service;
  }


  BlueprintMapsService.$inject = [
    '$q',
    'BlueprintsService',
    'EasApiStore',
    'UtilsService'
  ];

  angular.module('component.blueprints')
    .factory('BlueprintMapsService', BlueprintMapsService);
})();
