(function() {
  'use strict';

  function EventDraftsController(config, Auth, Drafts, LocalizationService) {
    var ctrl = this;

    config = _.defaults(config, { eventOwner: ['personal'] });

    // Get the drafts for the provided document type
    var matchFunc = function(doc) {
      var userMatches = function(eventOwner) {
        var matches = false;

        if (_.indexOf(config.eventOwner, 'personal') !== -1) {
          matches = eventOwner === Auth.currentUser();
        }

        if (_.indexOf(config.eventOwner, 'other_users') !== -1) {
          matches = matches || eventOwner !== Auth.currentUser();
        }

        return matches;
      };

      return (
        doc.isFor === 'eventSection' &&
        userMatches(doc.data.eventOwner)
      );
    };

    /* eslint-disable max-len */
    var yourDraftMessages = {
      default: 'You\'ve previously worked on these events and you can continue working on them from here.',
      empty: 'You have no drafts yet. Every time you create or edit a form, we will automatically save your changes so you can continue working on them. These as well as forms you manually save as draft will show up here.'
    };

    var userDraftsMessages = {
      default: 'You\'ve previously worked on these events for other users and you can continue working on them from here.',
      empty: 'You have no draft events for other users yet. Every time you create or edit an event for another user, we will automatically save your changes so you can continue working on them. These as well as events you manually save as draft will show up here.'
    };
    /* eslint-enable */

    Drafts.findAllBy(matchFunc)
      .then(function(drafts) {
        if (config.eventOwner.length > 1) {
          ctrl.drafts = _.groupBy(drafts, function(draft) {
            return draft.data.eventOwner === Auth.currentUser()
              ? 'yourDrafts'
              : 'userDrafts';
          });

          ctrl.yourDraftsMessages = yourDraftMessages;
          ctrl.userDraftsMessages = userDraftsMessages;

          ctrl.hasMultipleDraftGroups = true;
        } else {
          ctrl.drafts = drafts;
          ctrl.messages = yourDraftMessages;
        }
      });

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('datetime');
  }

  EventDraftsController.$inject = [
    'config',
    'AuthService',
    'DraftsService',
    'LocalizationService'
  ];

  function EventDraftsWidgetController(config) {
    var ctrl = this;

    ctrl.config = config;

    config = _.defaults(config, { eventOwner: ['personal'] });

    ctrl.options = [
      {
        id: 'personal',
        name: 'Personal drafts'
      },
      {
        id: 'other_users',
        name: 'Drafts for other users'
      }
    ];
  }

  EventDraftsWidgetController.$inject = ['config'];

  function config(dashboardProvider) {
    dashboardProvider.widget('eventDrafts', {
      title: 'Event drafts',
      description: 'List of your event drafts',
      controller: 'EventDraftsController',
      controllerAs: 'eventDraftsCtrl',
      templateUrl: 'app/components/drafts/widgets/event-drafts/view.html',
      edit: {
        templateUrl: 'app/components/drafts/widgets/event-drafts/edit.html',
        controller: 'EventDraftsWidgetController',
        controllerAs: 'eventDraftsEditCtrl'
      },
      category: 'Events'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.drafts')
    .config(config)
    .controller('EventDraftsController', EventDraftsController)
    .controller('EventDraftsWidgetController', EventDraftsWidgetController);
})();
