(function() {
  'use strict';

  function GoalsOverviewWidgetController(
    config,
    Auth,
    Goal,
    GoalsStore,
    Security,
    Network,
    Users,
    GOALS_STATES,
    LocalizationService
  ) {
    var ctrl = this;

    ctrl.config = config;
    ctrl.GOALS_STATES = GOALS_STATES;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.user = Users.remoteUser || Auth.currentUser();
    ctrl.isRemoteUser = ctrl.user !== Auth.currentUser();
    var DEFAULT_LIMIT = 8;
    // Set the display limit
    if (_.isUndefined(ctrl.config.limit)) {
      ctrl.displayLimit = DEFAULT_LIMIT;
    } else if (ctrl.config.limit === 0) {
      ctrl.displayLimit = 1000;
    } else {
      ctrl.displayLimit = ctrl.config.limit;
    }

    var fetchGoalsOnline = function() {
      var data = {
        user: Users.remoteUser,
        goalset_state: 'open',
        size: ctrl.displayLimit
      };
      var options = {
        own: !ctrl.isRemoteUser
      };
      return GoalsStore.fetchLight(data, options);
    };

    var fetchGoalsOffline = function() {
      return Goal.findAll()
        .then(function(goals) {
          return _.filter(goals, function(goal) {
            return !goal.isSetClosed();
          });
        });
    };

    var fetchGoals = function() {
      if (Network.isOffline()) {
        return fetchGoalsOffline();
      }

      return fetchGoalsOnline();
    };

    var transform = function(goals) {
      return _.map(goals, function(goal) {
        var periodId = goal.getCurrentPeriodId();
        var transformed = {
          href: goal.getViewLink().then(function(link) {
            transformed.link = link;
          }),
          avgRate: goal.getAvgRate(periodId),
          state: goal.doc.state,
          totalLinkedEvents: goal.getTotalLinkedEvents(),
          title: goal.doc.title
        };
        return transformed;
      });
    };

    var load = function() {
      ctrl.loading = true;
      ctrl.authorized = false;
      // ctrl.goals = undefined;

      var perm;
      if (ctrl.isRemoteUser) {
        perm = Security.hasPermissionFor('goals.view', ctrl.user);
      } else {
        perm = Security.hasPermission('goals.work.own', { noCatch: true });
      }

      return perm.then(function() {
        ctrl.authorized = true;
        return fetchGoals();
      })
        .then(function(goals) {
          ctrl.goals = transform(goals);
          window.loadedGoals = ctrl.goals;
          window.loadedGoalsFor = ctrl.user;
          ctrl.loading = false;
        })
        .catch(function() {
          ctrl.loading = false;
          ctrl.authorized = false;
        });
    };

    if (ctrl.user === window.loadedGoalsFor) {
      ctrl.goals = window.loadedGoals;
    }
    ctrl.authorized = true;
    if (ctrl.goals === undefined) {
      load();
    } else {
      window.setTimeout(function() {
        load();
      }, 0);
    }
  }

  GoalsOverviewWidgetController.$inject = [
    'config',
    'AuthService',
    'GoalFactory',
    'GoalsStore',
    'SecurityService',
    'NetworkService',
    'UsersService',
    'GOALS_STATES',
    'LocalizationService'
  ];

  function config(dashboardProvider) {
    dashboardProvider.widget('goalsOverview', {
      title: 'An overview of all your goals',
      description: 'Displays all the goals I am working towards',
      controller: 'GoalsOverviewWidgetController',
      controllerAs: 'goalsOverviewWidgetCtrl',
      templateUrl: 'app/components/goals/widgets/overview/overview.html',
      reload: true,
      edit: {
        templateUrl: 'app/components/goals/widgets/overview/edit.html',
        controller: 'GoalsOverviewWidgetController',
        controllerAs: 'goalsOverviewWidgetCtrl'
      },
      category: 'Goals'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.goals')
    .config(config)
    .controller('GoalsOverviewWidgetController', GoalsOverviewWidgetController);
})();
