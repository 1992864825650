(function() {
  'use strict';

  function FilesController(
      $log,
      $rootScope,
      $stateParams,
      ApiIDList,
      FilesSearch,
      Files,
      Profile,
      List,
      Utils,
      FILES_SLOT_STYLES) {
    var ctrl = this;

    if ($stateParams.user) {
      ctrl.user = $stateParams.user;
    }

    Utils.setPageTitle('Files');

    ctrl.options = {
      trackBy: 'doc.id',
      borderClass: function(item) {
        var borderStyle = 'info',
            filesSlotStyles = FILES_SLOT_STYLES[item.visbility];
        if (!_.isUndefined(filesSlotStyles)) {
          borderStyle = filesSlotStyles.borderStyle;
        }
        return 'progress-border-' + borderStyle;
      }
    };

    ctrl.list = new List({ idField: 'doc.id' });

    var listName = 'files';
    ctrl.searchModel = Profile.getListPreferences(listName).current;
    var searchCode = 'search';

    FilesSearch.getSearch(searchCode)
      .then(function(search) {
        var listOptions = {
          search: search,
          model: ctrl.searchModel,
          idField: 'id',
          defaultFilter: { user: ctrl.user, own: !ctrl.user },
          searchFn: Files.searchIds.bind(Files),
          fetchFn: Files.fetchIds.bind(Files)
        };

        search.ready = true;
        return new ApiIDList(listOptions);
      })
      .then(function(list) {
        ctrl.list = list;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: list.search });
        $rootScope.loading = false;
        ctrl.list.doLoadItems();
        ctrl.loaded = true;
      })
      .catch(function(error) {
        $rootScope.loading = false;
        ctrl.loaded = true;
        $log.warn(error);
      });
  }

  FilesController.$inject = [
    '$log',
    '$rootScope',
    '$stateParams',
    'APIIDListFactory',
    'FilesSearch',
    'FilesService',
    'ProfileService',
    'ListFactory',
    'UtilsService',
    'FILES_SLOT_STYLES'
  ];

  angular.module('component.files')
    .controller('FilesController', FilesController);
})();
