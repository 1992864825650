(function() {
  'use strict';

  function Search() {
    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              placeholder: 'Start typing to search'
            },
            {
              id: 'recipients',
              type: 'text',
              label: 'Recipient',
              facetly: false,
              advanced: true
            },
            {
              id: 'block',
              type: 'select',
              label: 'Part',
              multiselect: true,
              facetly: false,
              advanced: false,
              options: [
                { id: 'events', title: 'Events' },
                { id: 'goals', title: 'Goals' },
                { id: 'users', title: 'Users' },
                { id: 'payments', title: 'Payments' },
                { id: 'bookings', title: 'Bookings' }
              ]
            },
            {
              id: 'state',
              type: 'select',
              label: 'Status',
              multiselect: true,
              facetly: false,
              advanced: true,
              options: [
                { id: 'pending', title: 'Pending' },
                { id: 'delivered', title: 'Delivered' },
                { id: 'bounced', title: 'Bounced' },
                { id: 'other', title: 'Other' }
              ]
            },
            {
              id: 'after',
              type: 'date',
              label: 'Sent after',
              facetly: false,
              advanced: true
            },
            {
              id: 'before',
              type: 'date',
              label: 'Sent before',
              facetly: false,
              advanced: true
            }
          ]
        },
        filters: [
        ],
        orderGroups: {
          name: {
            title: 'addedDate',
            orders: ['addedDate']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'addedDate'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = [];

  angular.module('component.emailLogs')
    .service('EmailLogSearch', Search);
})();
