(function() {
  'use strict';

  function DraftSlotDirective(
    $rootScope,
    Drafts,
    EventSection,
    Notify,
    Utils,
    Autosave,
    UAParser,
    LocalizationService,
    AUTOSAVE_EVENTS
  ) {
    return {
      scope: {
        draft: '=',
        info: '=',
        canDelete: '=?',
        idx: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/drafts/draft-slot.html',
      link: function link(scope) {
        /**
         * This will determine whether to show the delete button in the slot.
         * @type {Boolean}
         */
        scope.canDelete = scope.canDelete || false;
        var saveInfo = scope.draft.saveInfo || {};
        scope.identifier = UAParser.getName(saveInfo.agent);
        scope.active = scope.draft._id === scope.info.id;
        scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
        /**
         * Remove an autosave from the db.
         * @param  {Object}  draft The Autosave doc to remove.
         * @return {Promise}      A Promise Object with the result of the operation.
         */
        scope.remove = function() {
          return Drafts.remove(scope.draft._id)
            .then(function() {
              $rootScope.$broadcast(AUTOSAVE_EVENTS.removed, { draft: scope.draft });
              Notify.success('Draft successfully deleted!', 'Success');
            })
            .catch(Utils.showError);
        };

        scope.openPreview = function() {
          var model = Autosave.getAutoSaved(scope.info, scope.draft);
          var doc = model.doc;
          var ev = new EventSection(doc);
          var actions = [];

          if (!scope.active) {
            actions.push({
              label: 'Switch to this autosave',
              icon: 'icon-edit',
              onClick: scope.switchToAutosave.bind(this),
              klass: 'btn-sm btn-success',
              close: true,
              showCondition: function() {
                return !scope.active;
              }
            });
          }

          ev.init()
            .then(function() {
              ev.openEventPreview({ actions: actions, noFillin: true });
            });
        };

        scope.switchToAutosave = function() {
          return Autosave.switchToAutosave(scope.info, scope.draft);
        };
      }
    };
  }

  DraftSlotDirective.$inject = [
    '$rootScope',
    'DraftsService',
    'EventSectionFactory',
    'NotifyService',
    'UtilsService',
    'AutoSaveService',
    'UAParser',
    'LocalizationService',
    'AUTOSAVE_EVENTS'
  ];

  angular.module('component.drafts')
    .directive('kzDraftSlot', DraftSlotDirective);
})();
