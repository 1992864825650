(function() {
  'use strict';

  function filter(moment, Utils) {
    return function(input) {
      if (Utils.isBlank(input) || input === '-') {
        return '';
      }
      try {
        return moment.duration(Number.parseInt(input) * 1000).humanize();
      } catch (err) {
        return input;
      }
    };
  }

  filter.$inject = ['moment', 'UtilsService'];

  angular.module('blocks.utils')
    .filter('kzTimeDelta', filter);
})();
