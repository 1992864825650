(function() {
  'use strict';

  function datePicker(Utils, moment, LocalizationService) {
    return {
      scope: {
        model: '=',
        options: '=?',
        startField: '@',
        endField: '@',
        disabled: '=',
        labeledBy: '@?'
      },
      templateUrl: 'app/blocks/utils/datepicker.html',
      link: function(scope, el) {
        // We need an ID for the input
        // This should correspond to the <label for="ID">
        // FIXME: This doesn't actually work
        // because formly just adds the id attribute by itself
        // and we end up with two IDs on the page
        // See: http://docs.angular-formly.com/docs/ngmodelattrstemplatemanipulator
        scope.labeledBy = scope.labeledBy || Utils.guid();

        function isoDateToDate(date) {
          if (date === undefined) {
            return undefined;
          }

          if (!_.isString(date)) {
            return undefined;
          }

          if (_.isEmpty(date)) {
            return undefined;
          }

          var dt = moment(date, 'YYYY-MM-DD');
          if (!dt.isValid()) {
            return null;
          }
          if (dt.year() < 1900) {
            return null;
          }
          return dt.toDate();
        }

        function dateToIsoDate(date) {
          if (date === undefined) {
            return null;
          }
          date = moment(date, scope.momentFormat);
          if (!date.isValid()) {
            return null;
          }
          if (date.year() < 1900) {
            return null;
          }
          return date.format('YYYY-MM-DD');
        }

        scope.format = LocalizationService.getJQDateTimeFormat('inputdate');
        scope.momentFormat = LocalizationService.getMomentDateTimeFormat('inputdate');

        var options = _.assign({}, {
          format: LocalizationService.getJQDateTimeFormat('inputdate'),
          todayBtn: 'linked',
          clearBtn: true,
          todayHighlight: true,
          autoclose: true,
          weekStart: 1,
          startDate: '01/01/1900',
          endDate: '01/01/2100',
          showOnFocus: true
        }, scope.options);

        var inputEl = el.find('input.date-picker');
        inputEl.on('change', function(evt) {
          var inputValue = evt.target.value;
          if (inputValue === '') {
            scope.model[scope.startField] = inputValue;
            return;
          }

          var dt = moment(evt.target.value, scope.momentFormat);
          var val = dateToIsoDate(dt);
          console.log(val);
          if (val && scope.startField && val !== scope.model[scope.startField]) {
            scope.model[scope.startField] = val;
          }
        });

        var pickerEl = inputEl.datepicker(options);

        // scope.openCalendar = function() {
        //   inputEl.datepicker('show');
        // };
        // _.forEach(['hide', 'clearDate'], function(evtAttr) {
        //   pickerEl.on(evtAttr,
        //     function(evt) {
        //       var val = evt.date;
        //       return $timeout(function() {
        //         if (scope.startField) {
        //           scope.model[scope.startField] = dateToIsoDate(val);
        //         }
        //       });
        //     });
        // });

        // watch scope.model and convert isoDate to UI format so that the datepicket could read it
        scope.$watch(function() {
          return scope.model ? scope.model[scope.startField] : undefined;
        }, function(value) {
          var val = isoDateToDate(value);
          console.log('To datepicker', val);
          if (val) {
            pickerEl.datepicker('update', val);
          }
        });
      }
    };
  }

  datePicker.$inject = ['UtilsService', 'moment', 'LocalizationService'];

  angular.module('blocks.utils')
    .directive('kzDatePicker', datePicker);
})();
