(function() {
  'use strict';

  function TypesService($uibModal, EventSection, EventSections) {
    var service = {};

    var openPreview = function(doc, opts) {
      var options = _.assignIn({}, {
        animation: true,
        size: 'lg',
        templateUrl: 'app/blocks/types/type.preview.html',
        controller: 'TypePreviewController',
        controllerAs: 'ctrl',
        resolve: {
          item: function() {
            return doc;
          }
        }
      }, opts || {});
      $uibModal.open(options);
    };

    service.openPreview = function(doc) {
      if (doc.type === 'eventSection') {
        var obj = new EventSection(doc);
        obj.openEventPreview();
      } else if (doc.type === 'goal') {
        openPreview(doc, {
          templateUrl: 'app/components/goals/goal.preview.html',
          controller: 'GoalPreviewController',
          controllerAs: 'goalCtrl'
        });
      } else if (doc.type === 'autosave') {
        // This is hacky as I don't know now how to do it
        // We'd need to separate info into some external place
        // and dynamically get it to build the actual object

        // Now I expect only event sections
        EventSections.find(doc.docId)
          .then(function(evSec) {
            var obj = new EventSection(evSec);
            _.assignIn(obj.doc, doc.data.doc);
            obj.openEventPreview();
          });
      } else {
        openPreview(doc);
      }
    };

    return service;
  }

  TypesService.$inject = ['$uibModal', 'EventSectionFactory', 'EventSectionsService'];

  angular.module('blocks.types')
    .service('TypesService', TypesService);
})();
