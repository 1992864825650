(function() {
  'use strict';

  function config() {
    // eslint-disable-next-line no-undef
    // PouchDB.plugin(Validation);
  }

  angular
    .module('blocks.db', [])
    .config(config);
})();
