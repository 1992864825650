(function() {
  'use strict';

  function Cards(Sidebar) {
    var service = {};

    service.register = function() {
      var cards = [
        {
          id: 'message-details',
          title: 'Message details',
          directive: 'inbox-message-details',
          options: {
            routes: ['epf.inbox.view']
          }
        }
      ];

      Sidebar.registerCards(cards);
    };

    return service;
  }

  Cards.$inject = ['SidebarService'];

  angular.module('component.announcements')
    .service('InboxCards', Cards);
})();
