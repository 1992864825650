import { UserFieldsTemplates } from './components/user-fields/tplCache';
import { EventTypesTemplates } from './components/event-types/partials/tplCache';
import { GoalsTypesTemplates } from './components/goals/tplCache';
import { BlueprintsTemplates } from './components/blueprints/tplCache';
import { RelationsTemplates } from './components/relations/tplCache';
import { RolesTemplates } from './components/roles/tplCache';
import { EventsTemplates } from './components/events/tplCache';
import { ReportsTemplates } from './components/reports/tplCache';

export const templates = {
  'app/layout/placeholders/list.html': require('./layout/placeholders/list.html').default,
  'versions-list.html': require('./blocks/versions/versions-list.html').default,
  'app/blocks/image/image.html': require('./blocks/image/image.html').default,
  'app/blocks/image/picture.html': require('./blocks/image/picture.html').default,
  'app/blocks/list/dotbuttons.html': require('./blocks/list/dotbuttons.html').default,
  'app/blocks/list/listbuttons.html': require('./blocks/list/listbuttons.html').default,
  'app/blocks/list/listview.html': require('./blocks/list/listview.html').default,
  'app/blocks/widgets/progress/progress-inline.html': require('./blocks/widgets/progress/progress-inline.html').default,
  'app/blocks/widgets/progress/progress.html': require('./blocks/widgets/progress/progress.html').default,
  'app/components/dashboard/partials/widget.html': require('./components/dashboard/partials/widget.html').default,
  'app/components/dashboard/partials/dashboard-title.html': require('./components/dashboard/partials/dashboard-title.html').default,
  'app/components/dashboard/partials/dashboard-title-buttons.html': require('./components/dashboard/partials/dashboard-title-buttons.html').default,
  'app/components/dashboard/partials/dashboard-edit.html': require('./components/dashboard/partials/dashboard-edit.html').default,
  'app/components/dashboard/partials/dashboard-addwidget.html': require('./components/dashboard/partials/dashboard-addwidget.html').default,
  'app/components/dashboard/partials/widget-title.html': require('./components/dashboard/partials/widget-title.html').default,
  'app/components/accounts/partials/organisation-select.html': require('./components/accounts/partials/organisation-select.html').default,
  'app/layout/templates/2-columns-left.html': require('./layout/templates/2-columns-left.html').default,
  ...UserFieldsTemplates,
  ...EventTypesTemplates,
  ...GoalsTypesTemplates,
  ...BlueprintsTemplates,
  ...RelationsTemplates,
  ...GoalsTypesTemplates,
  ...RolesTemplates,
  ...EventsTemplates,
  ...ReportsTemplates,
};
