(function() {
  'use strict';

  function Search(Utils) {
    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              placeholder: 'Start typing to search',
              facetly: true
            },
            {
              id: 'block',
              type: 'select',
              label: 'Part',
              multiselect: true,
              options: [
                { id: 'events', title: 'Events' },
                { id: 'goals', title: 'Goals' },
                { id: 'users', title: 'Users' }
              ],
              facetly: false,
              advanced: true
            }
          ]
        },
        filters: [
          {
            id: 'text',
            matchFunc: function(item, _key, value) {
              return item.doc.name.indexOf(value) !== -1;
            }
          },
          {
            id: 'block',
            matchFunc: function(item, _key, value) {
              if (value.length === 0) {
                return true;
              }
              return value.indexOf(item.doc.block) !== -1;
            }
          }
        ],
        orders: {
          name: function(item) {
            return Utils.safeLowerStr(item.doc.name);
          },
          block: function(item) {
            return item.doc.block;
          }
        },
        orderGroups: {
          name: {
            title: 'name',
            orders: ['block', 'name'],
            groupBy: {
              title: '',
              notitle: 'other',
              getter: function(item) {
                return _.startCase(item.doc.block);
              }
            }
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'name'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = ['UtilsService'];

  angular.module('component.emailTemplates')
    .service('EmailTypeSearch', Search);
})();
