(function() {
  'use strict';

  function filter() {
    return function(input) {
      if (!input) {
        return input;
      }

      return input.replace(/\n\r?/g, '<br/>');
    };
  }

  angular.module('blocks.utils')
    .filter('nl2br', filter);
})();
