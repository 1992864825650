(function() {
  'use strict';

  var ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
  var AMERICAN_FORMAT = /^\d{4}-\d\d-\d\d?$/i;

  function filter($filter, LocalizationService) {
    return function(input, format, timezone) {
      if (ISO_8601_FULL.test(input) || AMERICAN_FORMAT.test(input)) {
        if (_.isUndefined(format) && ISO_8601_FULL.test(input) && !_.includes(input, '00:00:00')) {
          format = LocalizationService.getDateTimeFormat('datetime');
        }

        return $filter('date')(input, format, timezone);
      }

      return input;
    };
  }

  filter.$inject = ['$filter', 'LocalizationService'];

  angular.module('blocks.utils')
    .filter('smartDate', filter);
})();
