(function() {
  'use strict';

  function PageButtons(Utils) {
    return {
      restrict: 'AE',
      scope: {
        actions: '=',
        useContextualMenu: '=?'
      },
      templateUrl: 'app/blocks/widgets/buttons/pagebuttons.html',
      link: function(scope) {
        scope.actionButtons = [];

        /**
         * useContextualMenu will determine if the page buttons will
         * behave differently on a mobile device or not.
         * i.e. with a true value, a mobile-sized screen width will show a plus
         * button and a popup when it is clicked. The popup will display
         * the list of actionButtons passed in via actions.
         * with a false value or undefined, all screen widths will simply show the list of
         * actionButtons.
         * Here we are setting the default to be true, when useContextualMenu is undefined.
         * @type {boolean}
         */
        scope.useContextualMenu = _.isUndefined(scope.useContextualMenu)
          ? true
          : scope.useContextualMenu;

        /**
         * We may want to show buttons on different rows within the same container,
         * e.g. having one primary Call to Action, such as Create New Event and
         * a secondary action, such as Bulk Tagging.
         * See KZ-2817
         * @param  {Array} buttons The list of buttons.
         * @return {Object}       The buttons split on rows.
         */
        var splitButtonsOnRows = function(buttons) {
          return _.reduce(buttons, function(rows, button) {
            button.position = button.position || 0;
            rows[button.position] = rows[button.position] || [];
            rows[button.position].push(button);

            return rows;
          }, []);
        };

        scope.$watch('actions', function() {
          Utils.filterButtons(scope.actions)
            .then(function(buttons) {
              // Split the buttons into groups so that we can show them on different rows
              scope.actionButtons = splitButtonsOnRows(buttons);
            });
        });
      }
    };
  }

  PageButtons.$inject = ['UtilsService'];

  angular.module('blocks.widgets')
    .directive('kzPageButtons', PageButtons);
})();
