import { mimeMapping } from 'src/app/components/files/mimes';

(function() {
  'use strict';

    /*
        interface File {
            name: string;
            type: string;
            contentType: string;
            size: number;
            status: string;
        }
    */


/*
      const turnitInOptions = {
        enabled: field.enableAntiPlagiarism,
        attempts: field.enableAntiPlagiarismAttempts,
        required: field.enableAntiPlagiarismRequired,
        buttonLabel: field.enableAntiPlagiarismTitle
      };
*/

  const TURNITIN_PENDING_STATES = ['file_pending', 'report_not_requested', 'report_pending'];

  const defaultFileOptions = {
    accept: ['all'],
    multiple: false,
    sizeLimit: 100,
    fileLimit: 0,
    storeSingle: false
  };

  const getReadableFileSizeString = function(fileSizeInBytes) {
    if (!fileSizeInBytes) {
      return 0;
    }

    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  };


  function FileWidget(Files, FileUpload, Turnitin, Notify, FILE_TYPES) {
    var ctrl = this;

    ctrl.files = [];

      // Convert data model to a file model
    ctrl.modelToFileModel = function(model) {
      let currentSubmission;
      let currentHash = Turnitin.getHash(model);
      if (model.turnitin && model.turnitin.submissions) {
        currentSubmission = model.turnitin.submissions[model.turnitin.submissions.length - 1];
        if (currentSubmission && currentSubmission.uploaded_hash !== currentHash) {
          currentSubmission = undefined;
        }
      }

      return {
        filename: model.filename,
        type: mimeMapping[model.contentType] ?? model.type ?? model.filename.split('.').pop(),
        contentType: model.contentType,
        size: model.size,
        readableSize: getReadableFileSizeString(model.size),
        vendor: model.vendor,
        key: model.key,
        status: 'uploaded',
        turnitin: model.turnitin,
        currentSubmission: currentSubmission,
        model: model,
        currentHash: currentHash
      };
    };

    // Convert browser file to a file model
    ctrl.fileToFileModel = function(file) {
      return {
        filename: file.name,
        type: mimeMapping[file.type] ?? file.type.split('/')[1],
        contentType: file.type,
        size: file.size,
        readableSize: getReadableFileSizeString(file.size),
        status: 'waiting',
        file: file
      };
    };

    // Convert file model to data model for saving
    ctrl.fileModelToModel = function(file) {
      if (file.status !== 'uploaded') {
        throw new Error('File is not uploaded');
      }

      return {
        filename: file.filename,
        type: file.type,
        contentType: file.contentType,
        size: file.size,
        vendor: file.vendor,
        key: file.key,
        turnitin: file.turnitin
      };
    };

    ctrl.handleButtons = function() {
      ctrl.files.forEach(file => {
        file.buttons = ctrl.actions.filter(action => action.showCondition(file));
      });
    };

    ctrl.getAccept = function(value) {
      if (value === undefined || value === null) {
        return '';
      }

      if (!Array.isArray(value)) {
        value = [value];
      }

      const types = value.reduce(function(acc, type) {
        const tp = _.find(FILE_TYPES, { id: type });
        if (tp) {
          acc.push(tp.accept);
        }
        return acc;
      }, []);

      return types.join(',');
    };

    ctrl.getTypeName = function(type) {
      const tp = _.find(FILE_TYPES, { id: type });
      return tp ? tp.descriptor : '';
    };

    ctrl.$onInit = function() {
      ctrl.actions = [
        {
          label: 'Download',
          klass: 'btn-primary',
          showCondition: file => ctrl.readOnly && file.status === 'uploaded',
          action: file => ctrl.download(file)
        },
        {
          label: 'Preview',
          klass: 'btn-primary',
          showCondition: file => {
            if (!ctrl.readOnly) {
              return false;
            }

            if (file.status !== 'uploaded') {
              return false;
            }

            if (!file.contentType) {
              return false;
            }

            if (file.contentType.startsWith('image') || file.contentType === 'application/pdf') {
              return true;
            }

            if (file.contentType === 'pdf' || file.contentType === 'png' ||
                file.contentType === 'jpg' || file.contentType === 'jpeg'
            ) {
              return true;
            }
            return false;
          },
          action: file => ctrl.preview(file)
        },
        {
          label: 'Replace',
          klass: 'btn-primary',
          showCondition: file => {
            return !ctrl.readOnly && ctrl.allowEdit &&
            (file.status === 'uploaded' || file.status === 'failed');
          },
          action: file => ctrl.replace(file)
        },
        {
          label: 'Remove',
          klass: 'btn-danger',
          showCondition: file =>
            !ctrl.readOnly && (file.status === 'uploaded' || file.status === 'failed'),
          action: file => ctrl.remove(file)
        },
        {
          label: ctrl.turnitInOptions?.buttonLabel || 'Check for plagiarism',
          klass: 'btn-primary',
          showCondition: file => {
            if (!ctrl.turnitInOptions?.enabled) {
              return false;
            }

            if (ctrl.readOnly) {
              return false;
            }

            if (file.status !== 'uploaded') {
              return false;
            }

            const state = file.currentSubmission?.state?.state;
            if (TURNITIN_PENDING_STATES.includes(state)) {
              return false;
            }

            if (
              state === 'complete' &&
              Turnitin.getHash(file.model) === file.currentSubmission.uploaded_hash
            ) {
              return false;
            }

            return true;
          },
          action: file => ctrl.checkForPlagiarism(file)
        },
        {
          label: 'View plagiarism detail',
          klass: 'btn-primary',
          showCondition: file => {
            if (!ctrl.turnitInOptions?.enabled) {
              return false;
            }

            if (file.status !== 'uploaded') {
              return false;
            }

            if (file.currentSubmission === undefined || file.currentSubmission === null) {
              return false;
            }

            const state = file.currentSubmission?.state?.state;
            if (Turnitin.getHash(file.model) !== file.currentSubmission.uploaded_hash) {
              return false;
            }

            return state === 'complete';
          },
          action: file => ctrl.viewPlagiarismDetail(file)
        }
      ];
      let files = ctrl.model || [];
      if (!Array.isArray(files)) {
        files = [files];
      }
      ctrl.files = files.map(file => ctrl.modelToFileModel(file));
      ctrl.uploadedCount = ctrl.files.filter(file => file.status === 'uploaded').length;
      ctrl.startAllUpdators();
      ctrl.handleButtons();
      ctrl.handleModel();
    };

    ctrl.$onChanges = function() {
      ctrl.fullFileOptions = {
        ...defaultFileOptions,
        ...ctrl.fileOptions
      };

      ctrl.fullFileOptions.fileLimit = ctrl.fullFileOptions.multiple ?
        ctrl.fullFileOptions.fileLimit || 0 : 1;

      ctrl.allowEdit = ctrl.removeOnly === undefined ? true : !ctrl.removeOnly;
      ctrl.handleButtons();
    };

    ctrl.upload = function(files) {
      const uploadedFiles = Array.from(files).map(file => ctrl.fileToFileModel(file));
      ctrl.files = ctrl.files.concat(uploadedFiles);
      ctrl.handleFiles();
    };

    ctrl.validateFile = function(file) {
      // Validate mime type
      if (ctrl.fullFileOptions.accept) {
        const accept = ctrl.getAccept(ctrl.fullFileOptions.accept);
        if (accept) {
          const valid = accept.split(',').some(type => {
            type = type.trim();
            if (type.startsWith('.')) {
              return file.filename.endsWith(type);
            }
            const stripped = type.split('*')[0];
            return file.contentType.startsWith(stripped);
          });

          if (!valid) {
            return {
              status: 'error',
              message: 'Invalid file type'
            };
          }
        }
      }

      // Validate file size
      if (ctrl.fullFileOptions.sizeLimit
        && file.size > ctrl.fullFileOptions.sizeLimit * 1024 * 1024
      ) {
        return {
          status: 'error',
          message: 'File is too large'
        };
      }

      // Validate file count
      if (ctrl.fullFileOptions.fileLimit) {
        const count = ctrl.files.filter(
            f => f.status === 'uploaded' || f.status === 'uploading'
        ).length;
        if (count >= ctrl.fullFileOptions.fileLimit) {
          return {
            status: 'error',
            message: 'Too many files'
          };
        }
      }

      return {
        message: 'File is valid'
      };
    };


    ctrl.handleFiles = function() {
      ctrl.files.forEach(function(file) {
        if (file.status === 'waiting') {
          const status = ctrl.validateFile(file);
          if (status.status === 'error') {
            file.status = 'failed';
            file.error = status.message;
            return;
          }

          ctrl.ngModelCtrl.$setValidity('fileStatus', false);

          file.status = 'uploading';
          FileUpload.uploadToS3(file.file, { oid: ctrl.docId, name: file.filename }, progress => {
            console.log('Setting progress', progress);
            file.progress = progress;
          })
            .then(function(model) {
              file.key = model.key;
              file.model = model;
              file.vendor = model.vendor;
              file.status = 'uploaded';
              file.currentHash = Turnitin.getHash(file.model);
              ctrl.handleModel();
              ctrl.handleButtons();
            })
            .catch(function(err) {
              file.status = 'failed';
              Notify.error(err);
              ctrl.handleModel();
              ctrl.handleButtons();
            });
        }

        if (file.currentSubmission) {
          file.currentHash = Turnitin.getHash(file.model);
        }
      });
      ctrl.handleButtons();
    };

    ctrl.handleModel = function() {
      const uploadedFiles = ctrl.files.filter(file => file.status === 'uploaded');
      const data = uploadedFiles.map(file => ctrl.fileModelToModel(file));

      ctrl.uploadedCount = data.length;
      if (!ctrl.fullFileOptions.multiple && ctrl.fullFileOptions.storeSingle) {
        ctrl.model = data.length > 0 ? data[0] : null;
      } else {
        ctrl.model = data;
      }

      if (ctrl.onModelUpdated) {
        ctrl.onModelUpdated({ files: uploadedFiles });
      }
      const pendingFiles = ctrl.files.filter(file => file.status === 'uploading');
      ctrl.ngModelCtrl.$setValidity('fileStatus', pendingFiles.length === 0);
    };

    ctrl.replace = function(file) {
      var input = document.createElement('input');
      input.type = 'file';
      input.accept = ctrl.getAccept(ctrl.fullFileOptions.accept);
      input.onchange = function() {
        var files = input.files;
        if (files.length > 0) {
          const fileModel = ctrl.fileToFileModel(files[0]);
          _.extend(file, fileModel);
          ctrl.handleFiles();
        }
      };
      input.click();
    };

    ctrl.select = function() {
      var input = document.createElement('input');
      input.type = 'file';
      input.multiple = ctrl.fullFileOptions.multiple;
      input.accept = ctrl.getAccept(ctrl.fullFileOptions.accept);
      input.onchange = function() {
        var files = input.files;
        if (files.length > 0) {
          ctrl.upload(files);
        }
      };
      input.click();
    };

    ctrl.download = function(file) {
      FileUpload.getDownloadUrl(file.model, { oid: ctrl.docId })
                .then(function(res) {
                  window.open(res.url, '_blank');
                })
                .catch(function(err) {
                  console.log(err);
                  Notify.error(err.message || 'The file cannot be downloaded');
                });
    };

    ctrl.preview = function(file) {
      Files.openPreviewModal(file.model, ctrl.docId);
    };

    ctrl.remove = function(file) {
      ctrl.files = ctrl.files.filter(f => f !== file);
      ctrl.handleModel();
    };

    ctrl.checkForPlagiarism = function(file) {
      if (file.status !== 'uploaded') {
        return;
      }

      if (ctrl.turnitInOptions && ctrl.turnitInOptions.enabled) {
        Turnitin.submitFile(file.model, ctrl.turnitInOptions.title, { oid: ctrl.docId })
          .then(function(submission) {
            if (file.turnitin === undefined || file.turnitin.submissions === undefined) {
              file.turnitin = { submissions: [] };
            }

            file.turnitin.submissions.push(submission);
            file.currentSubmission = submission;
            file.currentHash = Turnitin.getHash(file.model);
            ctrl.handleModel();
            ctrl.handleButtons();
            ctrl.updator(submission);
          })
          .catch(function(err) {
            console.error(err);
            Notify.error(
              err.message || 'Turnitin service is currently unavailable. Please try again later.'
            );
          });
      }
    };

    ctrl.viewPlagiarismDetail = function(file) {
      if (!file.currentSubmission) {
        return;
      }

      Turnitin.openViewer(file.currentSubmission);
    };

    ctrl.updator = function(submission) {
      if (Turnitin.isFinished(submission)) {
        return;
      }

      if (ctrl.readOnly) {
        return false;
      }

      return Turnitin.waitFor(submission)
        .then(function(newSubmission) {
          console.log('Finished');
          _.extend(submission, newSubmission);
          ctrl.handleModel();
          ctrl.handleButtons();
        }, null, function(newSubmission) {
          console.log('Updating');
          _.extend(submission, newSubmission);
          ctrl.handleModel();
          ctrl.handleButtons();
        })
        .catch(function(err) {
          console.error(err);
          Notify.error(
            err.message || 'Turnitin service is currently unavailable. ' +
            'Please try again later.'
          );
        });
    };

    ctrl.startAllUpdators = function() {
      ctrl.files.forEach(file => {
        if (file.turnitin && file.turnitin.submissions) {
          file.turnitin.submissions.forEach(submission => {
            ctrl.updator(submission);
          });
        }
      });
    };

    ctrl.stopAllUpdators = function() {
      ctrl.files.forEach(file => {
        if (file.turnitin && file.turnitin.submissions) {
          file.turnitin.submissions.forEach(submission => {
            ctrl.stopUpdator(submission);
          });
        }
      });
    };

    ctrl.stopUpdator = function(submission) {
      Turnitin.stopUpdator(submission);
    };

    ctrl.$onDestroy = function() {
      ctrl.stopAllUpdators();
    };
  }

  FileWidget.$inject = [
    'FilesService',
    'FileUploadService',
    'TurnitinService',
    'NotifyService',
    'FILE_TYPES'
  ];

  angular.module('blocks.utils')
        .component('fileWidget', {
          templateUrl: 'app/blocks/widgets/forms/file-widget.html',
          controller: FileWidget,
          require: {
            ngModelCtrl: 'ngModel'
          },
          bindings: {
            label: '<',
            model: '=',
            readOnly: '<',
            removeOnly: '<',
            docId: '<',
            required: '@',
            hideList: '<',
            fileOptions: '<',
            turnitInOptions: '<',
            onModelUpdated: '&'
          }
        });
})();
