(function() {
  'use strict';

  function RelationsController(scope, Auth, User, Users, LocalizationService) {
    var ctrl = this;

    ctrl.headerClassNames = typeof scope.headerClassNames !== 'undefined'
      ? scope.headerClassNames
      : '';

    switch (scope.accordionType) {
      case 'icons':
        ctrl.collapseAction = '<i class="icon-up-open-big"></i>';
        ctrl.expandAction = '<i class="icon-down-open-big"></i>';
        break;
      default:
        ctrl.collapseAction = 'Collapse';
        ctrl.expandAction = 'Expand';
    }

    ctrl.opts = scope.options || {};
    ctrl.periodsToDisplay = ['current', 'previous', 'future'];
    if (ctrl.opts.showPeriods) {
      if (_.indexOf(ctrl.opts.showPeriods, '__all__') !== -1) {
        ctrl.periodsToDisplay = ['current', 'previous', 'future'];
      } else {
        ctrl.periodsToDisplay = ctrl.opts.showPeriods;
      }
    }

    ctrl.relationsToShow = ['__all__'];
    if (ctrl.opts.relationsToShow) {
      ctrl.relationsToShow = ctrl.opts.relationsToShow;
    }

    ctrl.roleConnectionsToShow = ['__all__'];
    if (ctrl.opts.roleConnectionsToShow) {
      ctrl.roleConnectionsToShow = ctrl.opts.roleConnectionsToShow;
    }

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.user = Users.remoteUser || Auth.currentUser();
    ctrl.remoteUser = Users.remoteUser;

    ctrl.historySort = {
      previous: '-start',
      current: '-start',
      future: 'start'
    };

    var options = {
      user: ctrl.user,
      includes: { relations: ctrl.relationsToShow, roles: ctrl.roleConnectionsToShow }
    };
    User.getHistorySorted(options)
      .then(function(history) {
        ctrl.history = history;
      });

    ctrl.shownPeriod = { current: true };
    ctrl.showPeriod = function(period) {
      ctrl.shownPeriod = ctrl.shownPeriod ? ctrl.shownPeriod : {};
      ctrl.shownPeriod[period] = _.has(ctrl.shownPeriod, period) ? !ctrl.shownPeriod[period] : true;
    };
  }

  RelationsController.$inject = [
    '$scope',
    'AuthService',
    'UserFactory',
    'UsersService',
    'LocalizationService'
  ];

  function RelationsDirective() {
    return {
      scope: {
        headerClassNames: '=?',
        accordionType: '@?',
        options: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/directives/relations.html',
      replace: true,
      controller: RelationsController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.users')
    .directive('relations', RelationsDirective)
    .controller('RelationsController', RelationsController);
})();
