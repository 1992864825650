(function() {
  'use strict';

  function DashboardTemplatesService(
    $q,
    EasApiStore,
    Profile,
    TemplatesUtils,
    DEFAULT_DASHBOARD_TEMPLATE
  ) {
    var service = new EasApiStore('dashboard-template');
    service.preCache = function() { return $q.all([service.findAll()]); };

    /**
     * Return all the dashboard templates for the currently logged in user.
     * @return {Promise}        A promise containing all the templates the logged in user
     *                          can view.
     */
    service.findAllForUser = function() {
      return service.findAll() // find all the templates
        .then(function(items) {
          var templates = _.chain(items)
                  .map(function(item) { return item; }) // we just need the doc
                  .value();

          var promises = [
            Profile.getRoles(), // we need the current user's roles
            Profile.getPreferences(), // and his preferences
            templates
          ];

          return $q.all(promises)
            .then(function(data) {
              return TemplatesUtils.getTemplatesForUser(
                data[0], // roles
                data[1], // prefs
                data[2], // templates
                'dashboards', // type
                DEFAULT_DASHBOARD_TEMPLATE // defaultTemplate
              );
            });
        });
    };

    return service;
  }

  DashboardTemplatesService.$inject = [
    '$q',
    'EasApiStore',
    'ProfileService',
    'TemplatesUtilsService',
    'DEFAULT_DASHBOARD_TEMPLATE'
  ];

  angular.module('component.dashboardTemplates')
    .factory('DashboardTemplatesService', DashboardTemplatesService);
})();
