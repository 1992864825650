(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.goals', {
        url: 'goals/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('goals.work.own');
          }]
        }
      })

      .state('epf.goals.index', {
        url: 'list/:filterId',
        views: {
          content: {
            templateUrl: 'app/components/goals/list.html',
            controller: 'GoalsController',
            controllerAs: 'goalsCtrl'
          }
        },
        params: {
          filterId: ''
        },
        resolve: {
          resolvedFilter: ['setup', '$stateParams', 'OrganisationsService',
            function(_setup, $stateParams, Organisations) {
              if (_.isEmpty($stateParams.filterId)) {
                return;
              }

              return Organisations.getFilter('epf.goals.index', $stateParams.filterId)
                .then(function(data) {
                  return data;
                });
            }]
        }
      })

      .state('epf.goals.view', {
        url: 'view/:id/:periodId',
        params: {
          periodId: ''
        },
        views: {
          content: {
            templateUrl: 'app/components/goals/goal.html',
            controller: 'GoalController',
            controllerAs: 'goalCtrl'
          }
        },
        data: {
          action: 'view'
        }
      })

      .state('epf.goals.work', {
        url: 'work/:id',
        views: {
          content: {
            templateUrl: 'app/components/goals/goal.html',
            controller: 'GoalController',
            controllerAs: 'goalCtrl'
          }
        },
        data: {
          action: 'work'
        }
      })

      .state('epf.goals.add', {
        url: 'add/:eventId/:sectionId/:fieldId',
        views: {
          content: {
            templateUrl: 'app/components/goals/goals-definition.html',
            controller: 'GoalsDefinitionController',
            controllerAs: 'GoalsDefinitionCtrl'
          }
        },
        data: {
          action: 'add'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.goals.edit', {
        url: 'edit/:id',
        views: {},
        resolve: {
          redirect: ['$q', '$stateParams', function($q, $stateParams) {
            return $q.reject({
              status: 302,
              state: 'epf.goals.work',
              stateParams: $stateParams
            });
          }]
        }
      })

      .state('epf.goals.editDefinition', {
        url: 'edit-definition/:id',
        views: {
          content: {
            templateUrl: 'app/components/goals/goals-definition.html',
            controller: 'GoalsDefinitionController',
            controllerAs: 'GoalsDefinitionCtrl'
          }
        },
        data: {
          action: 'edit'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.goals.link', {
        url: 'targets/link/:goalId/:targetId/:periodId',
        params: {
          periodId: ''
        },
        views: {
          content: {
            templateUrl: 'app/components/goals/targets.link.html',
            controller: 'TargetsLinkController',
            controllerAs: 'targetsLinkController'
          }
        }
      })

      .state('epf.goals.manage-categories', {
        url: 'manage',
        views: {
          content: {
            templateUrl: 'app/components/goals/manage-categories.html',
            controller: 'ManageCategoriesController',
            controllerAs: 'ctrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_db, Security) {
            return Security.hasPermission('organisation.manage-categories');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.goals')
    .config(config);
})();
