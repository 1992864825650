(function() {
  'use strict';

  function TitleDirective(UserFields) {
    return {
      scope: {
        field: '@'
      },
      restrict: 'EA',
      template: '<i class="icon-spin animate-spin" ng-if="!fieldName"></i>{{ fieldName }}',
      link: function(scope) {
        if (_.isEmpty(scope.field)) {
          scope.fieldName = 'n/a';
          return;
        }

        UserFields.findByCategoryId(scope.field)
          .then(function(field) {
            if (field === undefined) {
              scope.fieldName = scope.field;
              return;
            }

            scope.fieldName = field.value;
          })
          .catch(function(error) {
            console.log(error);
            scope.fieldName = scope.field;
          });
      }
    };
  }

  TitleDirective.$inject = ['UserFieldsService'];

  angular.module('component.userFields')
    .directive('epfUserFieldTitle', TitleDirective);
})();
