(function() {
  'use strict';

  var getLabelStyle = function(completionRate, forceSuccess) {
    var labelStyle = '';

    if (completionRate >= 100) {
      labelStyle = forceSuccess === false ? 'danger' : 'success';
    } else if (completionRate >= 66) {
      labelStyle = 'info';
    } else if (completionRate >= 33) {
      labelStyle = 'warning';
    } else if (completionRate >= 0) {
      labelStyle = 'danger';
    }

    return labelStyle;
  };

  var getStatsCount = function(stats, completionRate, completed, needed) {
    var count = '';

    switch (stats) {
      case 'percentage': // e.g. 50%
        count = completionRate + '%';
        break;

      case 'fractional': // e.g. 1 / 2
        count = completed + ' / ' + needed;
        break;

      case 'combined': // combined, e.g. 1 / 2 (50%)
        count = completed + ' / ' + needed + '(' + completionRate + '%)';
        break;

      default: // hide
        break;
    }

    return count;
  };

  function Progress(Utils) {
    return {
      restrict: 'AE',
      scope: {
        showBar: '=?',
        completed: '=',
        needed: '=',
        stats: '@?',
        rate: '=',
        objName: '@',
        success: '=?',
        inline: '=?'
      },
      templateUrl: 'app/blocks/widgets/progress/progress.new.html',
      replace: true,
      link: function($scope) {
        $scope.showBar = $scope.showBar || true;
        $scope.stats = $scope.stats || 'percentage';

        var update = function() {
          $scope.completionRate = $scope.rate ||
            Utils.getRate($scope.completed, $scope.needed);

          $scope.labelStyle = getLabelStyle($scope.completionRate, $scope.success);
          $scope.statsCount =
            getStatsCount($scope.stats, $scope.completionRate, $scope.completed, $scope.needed);
        };

        update();

        $scope.$watchCollection('completed', update);
        $scope.$watchCollection('needed', update);
      }
    };
  }

  Progress.$inject = ['UtilsService'];

  angular.module('blocks.widgets')
    .directive('kzProgressNew', Progress);
})();
