(function() {
  'use strict';

  function RolesController($state, $rootScope, Profile, Roles, RoleSearch, Security, List, Utils) {
    var ctrl = this;
    ctrl.options = {
      trackBy: 'doc._id'
    };

    Utils.setPageTitle('Roles');

    ctrl.searchModel = Profile.getListPreferences('roles').current;

    ctrl.list = new List({
      search: RoleSearch.search,
      model: ctrl.searchModel,
      idField: 'doc._id'
    });

    $rootScope.$broadcast('KZCurrentListChange', { list: 'roles', search: ctrl.list.search });

    Roles.findAll({}, {}, { cached: false })
      .then(function(data) {
        ctrl.list.doLoadItems(data);
      });

    ctrl.actions = [
      {
        label: 'New role',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.roles.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'roles.edit'),
        klass: 'btn-success'
      }
    ];

    // Defined here so that I don't have to create a special slot that does nothing
    ctrl.options.actionButtons = function(item) {
      return [
        {
          label: 'Edit',
          icon: 'icon-edit',
          href: (function() { return $state.href('epf.roles.edit', { id: item.doc._id }); })(),
          showCondition: Security.hasPermission.bind(this, 'roles.edit'),
          klass: 'text-info'
        },
        {
          label: 'View',
          icon: 'icon-edit',
          href: (function() { return $state.href('epf.roles.edit', { id: item.doc._id }); })(),
          showCondition: function() {
            return Security.hasPermission('roles.edit')
              .then(function(has) {
                return !has;
              })
              .catch(function() {
                return true;
              });
          },
          klass: 'text-info'
        }
      ];
    };

    ctrl.options.secondaryActionButtons = function(item) {
      return [
        {
          label: 'Duplicate',
          icon: 'icon-duplicate',
          onClick: function() {
            var duplicateDoc = angular.copy(item.doc);
            delete duplicateDoc._id;
            delete duplicateDoc._rev;
            duplicateDoc.title = 'Copy of ' + item.doc.title;

            $state.go('epf.roles.new', { defaults: duplicateDoc });
          },
          showCondition: Security.hasPermission.bind(this, 'roles.edit'),
          klass: 'text-info'
        }
      ];
    };
  }

  RolesController.$inject = [
    '$state',
    '$rootScope',
    'ProfileService',
    'RolesService',
    'RoleSearch',
    'SecurityService',
    'ListFactory',
    'UtilsService'
  ];

  angular.module('component.roles')
    .controller('RolesController', RolesController);
})();
