(function() {
  'use strict';

  /*
   * List Factory
   * provides function to set, get, filter and paginate a list of items
   * e.g.
   * var list = new ListFactory();
   * list.set(items);
   * list.filter().paginate(); // returns all the items in the list
   * list.filter(options).paginate(); // returns an array of filtered items in the list, based on
   * options
   * list.filter(options).paginate(paginationOptions); // returns an array of filtered items in the
   * list, sliced according to the pagination options
  */

  function ListFactory($q, $log, $rootScope, $timeout, Utils, DB_SETTINGS) {
    var List = function(options) {
      options = options || {};
      options.pagination = options.pagination || 'infinite';
      this.idField = options.idField || 'id';
      this.options = options;
      this.findOptions = options.findOptions || { limit: DB_SETTINGS.pagination.limit };
      this.search = options.search || {};
      this.searchModel = options.model || {};
      this.defaultFilter = _.assign({}, this.search.defaultFilter, options.defaultFilter || {});
      this.defaultOrder = options.defaultOrder || this.search.defaultOrder;
      this.appliedFilters = {};
      this.total_count = 0;
      this.reset();
    };

    // Sets all the items in the list, disregarding filters
    List.prototype.set = function(items) {
      var _this = this;
      items = _.isArray(items) ? items : [];
      return this._filter(items, this.defaultFilter)
        .then(function(items) {
          _this.items = [];
          _this.allItems = items;
          _this.total_count = items.length;
          _this._setStartKey();
          _this.ready = true;
          _this.isLoading = true;
        });
    };

    List.prototype.reset = function() {
      this.allItems = [];
      this.filtered = [];
      this.startkey = 0;
      this.total_count = 0;

      // For infinite scroll
      this.items = [];
      this.isLoading = false;
      this.finished = false;
      this.ready = false;
      return this; // return this for method chaining
    };

    // Returns all items in the list
    // most likely, this will never be used by the controllers, but could be used to get a
    // totalItems count
    List.prototype.get = function() {
      return this.allItems;
    };

    // Clears the filtered items
    List.prototype.resetFilters = function() {
      this._setStartKey();
      $rootScope.$broadcast('KZListResetted');
      return this;
    };

    // Returns all filtered items in the list
    // most likely, this will never be used by the controllers, but could be used to get a
    // totalItems count
    List.prototype.getFiltered = function() {
      return this.filtered;
    };

    // Creates a filtered list of items with filters if provided
    // If no filters are provided, this.filtered = this.items
    List.prototype._filter = function(items, filters) {
      var _this = this,
          promise = $q.when(items);

      filters = _.assignIn({}, this.defaultFilter || {}, filters);
      if (_.isObject(filters) && !_.isEmpty(filters)) {
        var keys = _.keys(filters);

        var defaultMatchFunc = function(item, key, value) {
          if (!value) { return true; }

          var doc = item.doc || {};
          var res;
          if (_.isArray(value)) {
            if (!_.isEmpty(value)) {
              if (_.isArray(doc[key])) {
                // multivalued doc[key]
                res = !_.isEmpty(_.intersection(value, doc[key]));
              } else {
                // singlevalued doc[key]
                res = _.includes(value, doc[key]);
              }
            } else {
              // value is empty - it means the key is present in filteredBy
              // but nothing is selected
              res = true;
            }
          } else {
            // exact term matching
            res = doc[key] === value;
          }

          return res;
        };

        var defaultPreMatchFunc = function() {
          return $q.when();
        };

        _.forEach(keys, function(key) {
          var facet = _.find(_this.search.facetly.facets, { id: key });
          var filterId = key;
          if (facet) {
            filterId = facet.filterId || facet.id;
          }

          if (_.has(filters, key) && filters[key]) {
            var filterDef = _.find(_this.search.filters, function(item) {
              return item.id === filterId;
            });
            if (filterDef === undefined) {
              return;
            }

            var isMatchFunc = _.isUndefined(filterDef.matchFunc);
            var matchFunc = isMatchFunc ? defaultMatchFunc : filterDef.matchFunc;

            var isPreMatchFunc = _.isUndefined(filterDef.preMatchFunc);
            var preMatchFunc = isPreMatchFunc ? defaultPreMatchFunc : filterDef.preMatchFunc;

            promise = $q.all([promise, preMatchFunc(filters[key])]);
            promise = promise.then(function(result) {
              var _items = result[0];
              var _preMatch = result[1];
              return Utils.asyncFilter(_items, function(item) {
                return matchFunc(item, key, filters[key], _preMatch);
              });
            });
          }
        });
      }

      return promise
        .catch(function(error) {
          $log.error(error);
          return $q.reject(error);
        });
    };

    List.prototype.filter = function(filters) {
      var _this = this;
      return this._filter(this.allItems, filters)
        .then(function(_items) {
          _this.filtered = _items;
          _this.found_count = _items.length;
        });
    };

    List.prototype.verifyOne = function(item) {
      return this._filter([item])
        .then(function(items) {
          if (items.length === 0) {
            return;
          }

          return items[0];
        });
    };

    // Sort filtered list
    List.prototype.sort = function(orderBy, orders) {
      var _this = this,
          _iteratees = [],
          _orders = [],
          _order = orderBy || this.defaultOrder;

      if (_order && _.has(this.search.orderGroups, _order)) {
        this.search.orderGroups[_order].orders.forEach(function(order) {
          if (_.startsWith(order, '-')) {
            _orders.push('desc');
            order = order.replace('-', '');
          } else {
            _orders.push('asc');
          }
          _iteratees.push(orders[order]);
        });

        _this.filtered = _.orderBy(_this.filtered, _iteratees, _orders);
      }

      return this;
    };

    // Returns a list of paginated items, if pagination options are provided
    // If no options are provided, it returns this.filtered (which might just be this.items)
    // This is the function that actually returns items, all calls that return items from List
    // should end up with this function
    /*
      * The intended worflow is this:
      * filtered = [a,b,c,d]; limit = 2; startkey = 0;
      * On the first iteration, paginated = [a,b]; startkey = 2;
      * On the second interation, paginated = [c,d]; startkey = 4;
    */
    List.prototype.paginate = function(options) {
      var _this = this,
          paginated = [];

      if (_.isObject(options) && options.limit && this.filtered.length) {
        paginated = this.filtered.slice(_this.startkey, _this.startkey + options.limit);
      } else {
        paginated = this.filtered;
      }

      this._setStartKey(this._getLast(paginated)); // set the new startkey

      return paginated;
    };

    /*
      External methods to be used in controllers
     */

    List.prototype.doPaginate = function() {
      this.isLoading = true;

      var items = this.paginate(this.findOptions);

      if (items.length > 0) {
        this.items = this.options.pagination === 'infinite' ? _.union(this.items, items) : items;
        this.finished = false;
      } else {
        this.finished = true;
      }

      this.isLoading = false;
      this.hideList = false;
    };

    List.prototype.doLoadItems = function(items) {
      var _this = this;

      _this.isLoading = true;

      var promise = $q.when();

      if (items) {
        promise = promise.then(function() {
          return _this.set(items);
        });
      }

      return promise
          .then(function() {
            return _this.filter(_this.searchModel.filteredBy);
          })
          .then(function() {
            _this.sort(_this.searchModel.orderBy, _this.search.orders)
                 .doPaginate();
          })
          .finally(function() {
            _this.hideList = false;
          });
    };

    List.prototype.doSearch = function() {
      var _this = this;
      this.resetFilters();
      this.items = [];
      this.showFilters = true;
      this.finished = false;
      this.isLoading = true;
      this.hideList = true;
      var def = $q.defer();

      // This timeout hack is here so that the entire list is cleared in the UI
      // so that everything gets re-rendered correctly (especially group titles
      // as they need to be done in order)
      $timeout(function() {
        _this.doLoadItems()
          .then(function() {
            def.resolve();
          });
      });

      return def.promise;
    };

    /*
     * Private methods
    */

    // get the last item in the list
    List.prototype._getLast = function(list) {
      return _.result(_.last(list), this.idField);
    };

    // set the startkey
    List.prototype._setStartKey = function(startkey) {
      var _this = this;

      if (_.isUndefined(startkey)) {
        this.startkey = 0;
      } else {
        this.startkey = _.findIndex(_this.filtered,
                                    function(d) {
                                      // idField can be a path, eg. doc._id
                                      return _.get(d, _this.idField) === startkey;
                                    }) + 1;
      }
    };

    // have we reached the end of the list?
    List.prototype._endOfList = function(list) {
      return list[list.length - 1].key === this.filtered[this.filtered.length - 1].key;
    };

    List.prototype.orderedBy = function() {
      return this.searchModel.orderBy || this.defaultOrder;
    };

    List.prototype.getTotalCount = function() {
      return this.filtered.length;
    };

    List.prototype.goToPage = function(page) {
      page = page || 0;
      // Get the this.idField of the this.filtered at the page * this.findOptions.limit index - 1
      var startkey = page === 0
        ? undefined
        : _.get(this.filtered[(page * this.findOptions.limit) - 1], this.idField);
      this._setStartKey(startkey);
      return this.doPaginate();
    };

    return List;
  }

  ListFactory.$inject = ['$q', '$log', '$rootScope', '$timeout', 'UtilsService', 'DB_SETTINGS'];

  angular.module('blocks.db')
    .factory('ListFactory', ListFactory);
})();
