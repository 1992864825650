(function() {
  'use strict';

  function EventSectionCloseController($scope, Form) {
    var ctrl = this;

    ctrl.show = false;
    ctrl.form = new Form();
    ctrl.invites = {};

    $scope.source = $scope.event.getResponsibles.bind($scope.event);
    ctrl.options = {
      formData: {
        label: 'Who would you like to fill in the next section of this form?'
      }
    };

    $scope.event.checkPermission('canClose')
      .then(function() {
        ctrl.show = true;
      });

    ctrl.close = function() {
      $scope.event.close(ctrl.invites)
        .then(function() {
          $scope.$emit('EventReload', { id: $scope.event.doc._id });
          // $scope.event.reload();
          // $window.history.back();
        });
    };
  }

  EventSectionCloseController.$inject = ['$scope', 'FormsService'];

  function EventSectionCloseDirective() {
    return {
      scope: {
        section: '=',
        event: '=',
        def: '=',
        options: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-close.html',
      replace: true,
      controller: EventSectionCloseController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventSectionClose', EventSectionCloseDirective)
    .controller('EventSectionCloseController', EventSectionCloseController);
})();
