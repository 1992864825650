(function() {
  'use strict';

  function Slot() {
    return {
      restrict: 'A',
      template: '{{ card }}'
    };
  }

  Slot.$inject = [];

  angular.module('layout.sidebar')
    .directive('sidebarDefault', Slot);
})();
