(function() {
  'use strict';

  function NewEventController(
    $rootScope,
    $state,
    $stateParams,
    $q,
    Auth,
    EventTypes,
    Organisations,
    Utils
  ) {
    var ctrl = this;

    ctrl.loaded = false;

    ctrl.defaults = $stateParams.defaults;
    ctrl.currentUser = Auth.currentUser();
    ctrl.eventOwner = $stateParams.user || Auth.currentUser();

    var DEFAULT_ROUTE = 'epf.events.section-new';

    Utils.setPageTitle('Create a new event');

    ctrl.convertingADraft = $stateParams.route !== DEFAULT_ROUTE;

    var getParams = function(params, eventTypeId) {
      params = params || {};

      return {
        id: params._id,
        eventTypeId: eventTypeId,
        user: ctrl.eventOwner === ctrl.currentUser ? null : ctrl.eventOwner,
        defaults: params,
        previousRoute: $rootScope.previousState
      };
    };

    ctrl.getEventTypesFromGroup = function(group) {
      if (!group.filters) {
        return [];
      }
      var eventTypes = _.flatten(
        _.map(
          _.filter(
            group.filters, function(itm) {
              return itm.dataType === 'eventType_versionGroupId';
            }
          ),
          function(itm) {
            return itm.value;
          }
        )
      );
      return eventTypes;
    };

    ctrl.getRouteForEventType = function(eventTypeId) {
      var route = $stateParams.route;
      var params = getParams($stateParams.defaults, eventTypeId);

      return $state.go(route, params);
    };

    /**
     * Will set the event type groupped on the ctrl.
     * @param {Array} eventTypes The list of event types to be grouped.
     */
    var setEventTypeGroups = function(eventTypeGroups) {
      ctrl.eventTypeGroups = eventTypeGroups;
      ctrl.hasMultipleEventTypeGroups = _.keys(eventTypeGroups).length > 1;
    };

    /**
     * Get all the available Event types disregarding groups.
     * This should only happen if there is an error in the main call.
     * @return {Promise}
     */
    var getAvailableEventTypes = function() {
      return EventTypes.findAvailableFor(ctrl.eventOwner)
        .then(function(data) {
          return _.chain(data)
                .map(function(obj) {
                  return { group: 'Other', _id: obj._id, name: obj.name };
                })
                .groupBy('group')
                .value();
        });
    };

    /**
     * Gets available event types and sets it up for grouping
     * based on the categories from the Manage Timelines section.
     * @return {Promise}
     */
    var getGroupedEventTypes = function() {
      var promises = [
        Organisations.getListOptionsFor('epf.events.index', { cached: false }),
        EventTypes.findAvailableFor(ctrl.eventOwner)
      ];

      /**
       * Format an event type by picking just its necessary values.
       * @param  {Object} et  The event type object.
       * @param  {String} key The key to use when looking for the eventType's properties.
       * @return {Object}     The formatted event type.
       */
      var formatEventType = function(et, key) {
        if (et.doc !== undefined) {
          key = 'doc';
        }
        return key
          ? { _id: et[key]._id, name: et[key].name, versionGroupId: et[key].versionGroupId }
          : { _id: et._id, name: et.name, versionGroupId: et.versionGroupId };
      };

      return $q.all(promises)
        .then(function(data) {
          /**
           * Will filter the list of event types to only get the ones
           * that are needed by the group, by looking at a list of IDs.
           * @param  {Array} ids The IDs that should be picked from the event types.
           * @return {Array}     The formatted list of event types.
           */
          var getEventTypesByIds = function(ids) {
            return _.chain(data[1])
                  .map(function(et) {
                    var key = et.doc !== undefined
                      ? 'doc'
                      : null;
                    return formatEventType(et, key);
                  })
                  .filter(function(et) {
                    return _.indexOf(ids, et.versionGroupId) !== -1;
                  })
                  .value();
          };

          /**
           * The fn needed by the reduce. It will return the groups.
           * @param  {Array} groups  The accumulator.
           * @param  {Object} group  The current group object.
           * @return {Array}        The new accumulated value.
           */
          var createGroup = function(groups, group) {
            // We don't need groups that have empty filters
            var eventTypes = ctrl.getEventTypesFromGroup(group);
            if (eventTypes) {
              groups[group.id] = {
                name: group.name,
                eventTypes: getEventTypesByIds(eventTypes)
              };
            }
            return groups;
          };

          /**
           * Figure out if an event type is in a group by looking at all the groups
           * and eliminating the event types that are present in a group already.
           * @param  {Object} eventType The event type to check for.
           * @param  {Array} groups    The groups to look into.
           * @return {Boolean}
           */
          var eventTypeIsInAGroup = function(eventType, groups) {
            var result = false;
            _.forEach(groups, function(group) {
              if (group.filters) {
                var eventTypeDoc = eventType.doc ? eventType.doc : eventType;
                var eventTypes = ctrl.getEventTypesFromGroup(group);
                if (
                  _.indexOf(
                    eventTypes,
                    eventTypeDoc.versionGroupId
                  ) !== -1
                ) {
                  result = true;
                  return false;
                }
              }
            });

            return result;
          };

          // For each group, get its event types
          var groups = _.reduce(data[0].sections, createGroup, {});

          /**
           * Get the first available key for the Uncategorised events.
           * @param  {Array} keys A list of already present keys.
           * @return {String}     The key to use.
           */
          var getUncategorisedKey = function(keys) {
            var availableKeys = ['Uncategorised', 'Other', 'More'],
                result = 'Uncategorised';

            _.forEach(availableKeys, function(key) {
              if (_.indexOf(keys, key) === -1) {
                result = key;
                return false;
              }
            });

            return result;
          };

          // Get the other event types that don't fit into any group
          groups[getUncategorisedKey(_.keys(groups))] = {
            name: 'Uncategorised',
            eventTypes: _.reduce(data[1], function(eventTypes, et) {
              var eventType = eventTypeIsInAGroup(et, data[0].sections) ? [] : formatEventType(et);
              return eventTypes.concat(eventType);
            }, [])
          };

          return groups;
        })
        .catch(function(err) {
          console.log(err);
          // We have an error, let's at least try to fetch the event types
          // and not worry about the groups.
          return getAvailableEventTypes();
        })
        .then(setEventTypeGroups);
    };

    getGroupedEventTypes()
      .catch(function(err) {
        Utils.showError(err);
      })
      .finally(function() {
        ctrl.loaded = true;
      });
  }

  NewEventController.$inject = [
    '$rootScope',
    '$state',
    '$stateParams',
    '$q',
    'AuthService',
    'EventTypesService',
    'OrganisationsService',
    'UtilsService'
  ];

  angular.module('component.events')
    .controller('NewEventController', NewEventController);
})();
