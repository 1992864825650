(function() {
  'use strict';

  function RequestPasswordController($state, Accounts, Form, Notify, Utils) {
    var ctrl = this;

    Utils.setPageTitle('Request a password');

    ctrl.resetSuccess = false;

    ctrl.user = {};

    var basicFields = [
      { id: 'email', type: 'email', label: 'Email', required: true }
    ];

    // User basic form
    ctrl.form = new Form();
    var basicGroup = ctrl.form.addGroup();

    ctrl.form.extend(basicFields, basicGroup);

    ctrl.requestResetPassword = function() {
      Accounts.requestResetPassword({ email: ctrl.user.email })
        .then(function() {
          Notify.success(
            'We have sent a link to the email address you provided which you can use to safely ' +
            'change your password.',
            'Nice!'
          );
          $state.go('accounts.login');
        })
        .catch(Utils.showError);
    };
  }

  RequestPasswordController.$inject = [
    '$state',
    'AccountsService',
    'FormsService',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.accounts')
    .controller('RequestPasswordController', RequestPasswordController);
})();
