(function() {
  'use strict';

  function FormPopupComponent() {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.setup();
    };


    ctrl.setup = function() {
      ctrl.innerModel = angular.copy(ctrl.resolve.model);
      ctrl.form = ctrl.resolve.form;
    };

    ctrl.submit = function(form) {
      if (form.$invalid) {
        console.log('Invalid');
        return;
      }
      ctrl.modalInstance.close(ctrl.innerModel);
    };

    ctrl.dismiss = function() {
      ctrl.modalInstance.dismiss('cancel');
    };

    ctrl.$onDestroy = function() {
    };
  }

  FormPopupComponent.$inject = [
  ];

  angular.module('component.organisations')
    .component('formPopupComponent', {
      templateUrl: 'app/components/organisations/form.popup.component.html',
      controller: FormPopupComponent,
      bindings: {
        resolve: '<',
        modalInstance: '<'
      }
    });
})();
