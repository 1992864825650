(function() {
  'use strict';

  var targetsStates = {
    notStarted: {
      id: 'notStarted',
      name: 'Not started',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    inProgress: {
      id: 'inProgress',
      name: 'In progress',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    completed: {
      id: 'completed',
      name: 'Completed',
      labelStyle: 'success',
      borderStyle: 'success'
    }
  };

  angular.module('component.goals')
    .constant('TARGETS_STATES', targetsStates);
})();
