(function() {
  'use strict';

  var getLabel = function(type) {
    var defaultLabel = 'Select roles...';
    var label = defaultLabel;

    if (type === 'dashboard') {
      label = 'Please select all roles this template should be visible to:';
    }

    if (type === 'summary') {
      // eslint-disable-next-line max-len
      label = 'This template will be shown when viewing the summary page of users with the following roles:';
    }

    // If type is not a recognized type, ah TypeScript I miss you,
    // then let's warn the user
    if (label === defaultLabel) {
      console.warn(
        type + ' is not a valid template type!',
        'Valid template types are "dashboard", "summary".',
        'Please check your ExtrasFormController form directive!'
      );
    }

    return label;
  };

  function ExtrasFormController($scope, Form, Roles, Utils) {
    var ctrl = this;
    var type = $scope.type || 'dashboard';

    ctrl.form = new Form();

    Roles.findAll()
      .then(function(roles) {
        var options = roles.map(function(role) {
          return {
            _id: role.doc._id,
            key: role.doc._id,
            name: role.doc.title
          };
        });

        ctrl.form.addField({
          id: 'visibleForRoles',
          type: 'discrete_multiple',
          label: getLabel(type),
          options: options,
          required: false
        });

        ctrl.form.addField({
          type: 'link',
          label: 'Select all roles',
          onClick: function() {
            $scope.template.visibleForRoles = _.map(options, function(role) {
              return role._id;
            });
          },
          hideExpression: function() {
            return (
              $scope.template.visibleForRoles &&
              $scope.template.visibleForRoles.length === options.length
            );
          }
        });

        ctrl.form.addField({
          type: 'link',
          label: 'Deselect all roles',
          onClick: function() {
            $scope.template.visibleForRoles = [];
          },
          hideExpression: function() {
            return (
              _.isUndefined($scope.template.visibleForRoles) ||
              $scope.template.visibleForRoles.length === 0
            );
          }
        });
      })
      .catch(Utils.showError);
  }

  function ExtrasFormDirective() {
    return {
      scope: {
        template: '=',
        type: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/dashboard-templates/directives/form.html',
      controller: ExtrasFormController,
      controllerAs: 'ctrl'
    };
  }

  ExtrasFormController.$inject = [
    '$scope',
    'FormsService',
    'RolesService',
    'UtilsService'
  ];

  angular.module('component.dashboardTemplates')
    .directive('dashboardTemplateExtrasForm', ExtrasFormDirective);
})();
