(function() {
  'use strict';

  function RelationUtils($q, Profile, Relations) {
    var service = {};

    service.getRestrictedRelations = function(originalRelations) {
      return Profile.getRelations()
        .then(function(relations) {
          var promises = [];

          // Filter out only relations that we are interested in (in the originalRelations)
          var relationIds = _.map(originalRelations, '_id');
          relations = _.pickBy(relations, function(_value, key) {
            return relationIds.indexOf(key) !== -1;
          });

          // if the user does not have any relation's categories assigned to him return the
          // whole tree
          if (_.isEmpty(relations)) {
            return originalRelations;
          }

          function getSubCategory(subsubrel) {
            promises.push(Relations.findSubCategory(subsubrel));
          }

          _.forOwn(relations, function(subrel) {
            subrel.forEach(getSubCategory);
          });

          return $q.all(promises);
        })
        .then(function(relations) {
          // check that the relation is not in the sub-item IDs set
          var allSubItemIds = [];
          function collectSubItemIds(item, allSubItemIds) {
            allSubItemIds.push(item._id);
            if (item.categories && item.categories.length > 0) {
              item.categories.forEach(function(subItem) {
                collectSubItemIds(subItem, allSubItemIds);
              });
            }
          }

          relations.forEach(function(relation) {
            if (relation.categories) {
              relation.categories.forEach(function(subItem) {
                collectSubItemIds(subItem, allSubItemIds);
              });
            }
          });

          // Then filter relations to exclude any that are in the sub-item IDs set
          return _.filter(relations, function(item) {
            return item !== undefined && _.indexOf(allSubItemIds, item._id) === -1;
          });
        });
    };

    service.getLabel = function(relations) {
      if (relations.length === 0) {
        return 'n/a';
      }

      var relationNames = _.map(relations, function(relation) { return relation.name; });

      if (relations[0].name !== relations[0].origName && !_.isUndefined(relations[0].origName)) {
        return relations[0].origName + ' at ' + relationNames.join(', ');
      }

      return relationNames.join(', ');
    };

    return service;
  }

  RelationUtils.$inject = ['$q', 'ProfileService', 'RelationsService', 'BlueprintsService'];

  angular.module('component.relations')
    .service('RelationUtils', RelationUtils);
})();
