(function() {
  'use strict';

  function Cards(Sidebar) {
    var service = {};

    service.register = function() {
      var cards = [
        {
          id: 'offline-status',
          title: 'Status',
          directive: 'offline-status',
          options: {
            routes: [
              'epf.settings.offlinesettings',
              'epf.settings.review'
            ]
          }
        }
      ];

      Sidebar.registerCards(cards);
    };

    return service;
  }

  Cards.$inject = ['SidebarService'];

  angular.module('component.settings')
    .service('SettingsCards', Cards);
})();
