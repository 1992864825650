(function() {
  'use strict';

  function MappingBlueprintItemController(
    Form
  ) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.load();
    };

    ctrl.load = function() {
      var fields = _.map(ctrl.resolve.coords, function(coord) {
        if (coord.type === 'blueprint') {
          var blp = ctrl.resolve.blueprints[coord.id].blueprint;
          return {
            id: coord.id,
            label: blp.name,
            type: blp.blueprintType,
            options: Form.prototype.buildOptions(blp.categories)
          };
        }
        return {
          id: coord.id,
          label: coord.id,
          type: coord.type
        };
      });
      ctrl.itemForm = new Form(fields);
      ctrl.model = angular.copy(ctrl.resolve.model || {});
      ctrl.itemName = _.map(ctrl.resolve.row.blueprints, 'value').join(', ');
    };

    ctrl.close = function() {
      ctrl.modalInstance.dismiss('cancel');
    };

    ctrl.submit = function() {
      ctrl.modalInstance.close(ctrl.model);
    };
  }

  MappingBlueprintItemController.$inject = [
    'FormsService'
  ];

  angular.module('component.blueprints')
    .component('blueprintMapItemEdit', {
      templateUrl: 'app/components/blueprints/blueprintMapItem.edit.html',
      controller: MappingBlueprintItemController,
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });
})();
