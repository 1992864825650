(function() {
  'use strict';

  function UserFieldsController($state, $rootScope, UserFields, UserFieldSearch, Profile,
                List, Security, Utils, BLUEPRINT_TYPES) {
    var ctrl = this;

    Utils.setPageTitle('User fields');

    ctrl.options = {
      trackBy: 'doc._id',
      stateMapping: _.keyBy(_.map(BLUEPRINT_TYPES, function(item) {
        return { id: item.name, name: item.label };
      }), 'id'),
      stateField: 'doc.fieldType'
    };

    ctrl.searchModel = Profile.getListPreferences('userfields').current;

    ctrl.list = new List({
      search: UserFieldSearch.search,
      model: ctrl.searchModel,
      idField: 'doc._id'
    }
    );
    $rootScope.$broadcast('KZCurrentListChange', { list: 'userfields', search: ctrl.list.search });

    UserFields.findAll({ cached: false, includeSystemUserFields: true })
      .then(function(userFields) {
        return ctrl.list.doLoadItems(userFields);
      });

    ctrl.actions = [
      {
        label: 'Reorder',
        icon: 'icon-move',
        href: (function() { return $state.href('epf.user-fields.reorder'); })(),
        showCondition: Security.hasPermission.bind(this, 'userFields.edit'),
        klass: 'btn-border btn-info'
      },
      {
        label: 'New user field',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.user-fields.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'userFields.edit'),
        klass: 'btn-success'
      }
    ];

    // Defined here so that I don't have to create a special slot that does nothing
    ctrl.options.actionButtons = function(item) {
      if (item.doc.isSystem) {
        return [];
      }

      return [
        {
          label: 'Edit',
          icon: 'icon-edit',
          href: (
            function() { return $state.href('epf.user-fields.edit', { id: item.doc._id }); }
          )(),
          showCondition: Security.hasPermission.bind(this, 'userFields.edit'),
          klass: 'text-info'
        },
        {
          label: 'View',
          icon: 'icon-edit',
          href: (
            function() { return $state.href('epf.user-fields.edit', { id: item.doc._id }); }
          )(),
          showCondition: function() {
            return Security.hasPermission('userFields.edit')
              .then(function(has) {
                return !has;
              })
              .catch(function() {
                return true;
              });
          },
          klass: 'text-info'
        }
      ];
    };
  }

  UserFieldsController.$inject = [
    '$state',
    '$rootScope',
    'UserFieldsService',
    'UserFieldSearch',
    'ProfileService',
    'ListFactory',
    'SecurityService',
    'UtilsService',
    'BLUEPRINT_TYPES'
  ];

  angular.module('component.userFields')
    .controller('UserFieldsController', UserFieldsController);
})();
