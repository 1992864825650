(function() {
  'use strict';

  function OrganisationController($state, Organisations, Utils) {
    var ctrl = this;

    ctrl.organisation = {};
    ctrl.user = {};
    ctrl.loaded = true;

    Utils.setPageTitle('New organisation');

    // Organisations.find($stateParams.id)
    //   .then(function(data) {
    //     ctrl.organisation = data;
    //     ctrl.showDelete = true;
    //   });

    ctrl.request = function(isValid) {
      ctrl.formIsSubmitted = true;

      if (isValid) {
        var rqOrg = angular.copy(ctrl.organisation);

        ctrl.user.state = 'active';
        Organisations.create(rqOrg)
          .then(function() {
            $state.go('epf.organisations.index');
          })
          .catch(Utils.showError);
      }
    };

    ctrl.remove = function() {
      Utils.swal({
        title: 'Are you sure you want to remove this organisation?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      });
    };
  }

  OrganisationController.$inject = [
    '$state',
    'OrganisationsService',
    'UtilsService'
  ];

  angular.module('component.organisations')
    .controller('OrganisationController', OrganisationController);
})();
