(function() {
  'use strict';

  function config(dashboardProvider) {
    dashboardProvider.customWidgetTemplatePath('app/components/dashboard/partials/widget.html');

    dashboardProvider
      .structure('6-6', {
        rows: [{
          columns: [{
            styleClass: 'col-sm-6',
            widgets: []
          }, {
            styleClass: 'col-sm-6',
            widgets: []
          }]
        }]
      })
      .structure('8-4', {
        rows: [{
          columns: [{
            styleClass: 'col-sm-8',
            widgets: []
          }, {
            styleClass: 'col-sm-4',
            widgets: []
          }]
        }]
      })
      .structure('4-4-4', {
        rows: [{
          columns: [{
            styleClass: 'col-sm-4',
            widgets: []
          }, {
            styleClass: 'col-sm-4',
            widgets: []
          },
          {
            styleClass: 'col-sm-4',
            widgets: []
          }]
        }]
      })
      .structure('12/4-4-4', {
        rows: [{
          columns: [{
            styleClass: 'col-xs-12',
            widgets: []
          }, {
            styleClass: 'col-sm-4',
            widgets: []
          },
          {
            styleClass: 'col-sm-4',
            widgets: []
          },
          {
            styleClass: 'col-sm-4',
            widgets: []
          }]
        }]
      })
      .structure('3-5-4', {
        rows: [{
          columns: [{
            styleClass: 'col-sm-3',
            widgets: []
          }, {
            styleClass: 'col-sm-5',
            widgets: []
          }, {
            styleClass: 'col-sm-4',
            widgets: []
          }]
        }]
      });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.dashboard', [])
    .config(config);
})();
