(function() {
  'use strict';

  function EventSlotController(
    $q,
    $scope,
    $stateParams,
    EventSections,
    Auth,
    Api,
    LocalizationService,
    EVENT_STATES
  ) {
    var ctrl = this;
    ctrl.formOptions = { featuredFieldView: true, ignoreRestricted: true };
    ctrl.sectionsToFillIn = [];

    var getTokens = function() {
      if (ctrl.event.currentSection.def && ctrl.event.currentSection.def.multiSource) {
        return ctrl.event.getSectionTokens(ctrl.event.currentSection._id)
          .then(function(data) {
            ctrl.tokens = _.map(data, function(token) {
              return {
                url: Api.absHostUrl('token_url', { token: token.short_token }, { baseUrl: true })
              };
            });
          })
          .catch(function(err) {
            console.log(err);
          });
      }
    };

    /*
      Get drafts sections so that we can show a quick fill-in link
     */
    var getDraftSections = function() {
      if (ctrl.event.doc.state === 'complete' || ctrl.event.isMine()) {
        return $q.when([]);
      }

      return EventSections.getTodoFor(ctrl.event.doc._id)
        .then(function(res) {
          if (res.total === 1 && res.hits.length === 1) {
            return res.hits;
          }
        })
        .catch(function(err) {
          console.log(err);
          return [];
        });
    };

    ctrl.EVENT_STATES = EVENT_STATES;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    ctrl.useCompactView = $scope.useCompactView || false;

    ctrl.event = $scope.event;

    ctrl.user = $stateParams.user;
    ctrl.username = $stateParams.user || Auth.currentUser();
    var initEvent = function() {
      ctrl.event.init()
        .then(function() {
          if ($scope.options && $scope.options.search && $scope.options.search.filteredBy) {
            ctrl.highlight = $scope.options.search.filteredBy[$scope.options.highlightField];
          }

          ctrl.sectionStates = ctrl.event.getProgress();

          /* Action buttons */
          if (($scope.options || {}).actionButtons) {
            ctrl.actionButtons = $scope.options.actionButtons(ctrl.event);
          } else {
            ctrl.actionButtons = angular.copy(ctrl.event.actionButtons || []);
          }

          var ab = angular.copy(ctrl.actionButtons);
          var previewActions = [];
          var previewSecondaryActions = ab;

          if (ctrl.event.doc.dates) {
            ctrl.actionButtons.push({
              id: 'showDescription',
              icon: 'icon-history',
              label: ctrl.shownDescription ? 'Hide audit log' : 'Show audit log',
              onClick: function() {
                ctrl.shownDescription = !ctrl.shownDescription;
                this.label = ctrl.shownDescription ? 'Hide audit log' : 'Show audit log';
              }
            });
          }

          ctrl.secondaryActionButtons = angular.copy(ctrl.event.secondaryActionButtons || []);
          ctrl.secondaryActionButtons.push({
            label: 'Preview',
            icon: 'icon-preview',
            onClick: function() {
              ctrl.event.openEventPreview({
                actions: previewActions,
                secondaryActions: previewSecondaryActions
              });
            },
            klass: 'text-info'
          });

          getTokens();
          getDraftSections()
            .then(function(sections) {
              ctrl.sectionsToFillIn = sections;
            });
        });
    };

    initEvent();

    ctrl.shownDescription = false;

    ctrl.updateEvent = function() {
      $scope.$broadcast('kzReloadActions');
    };

    $scope.$watch('event.doc._rev', function() {
      ctrl.event = $scope.event;
      initEvent();
    });
  }

  EventSlotController.$inject = [
    '$q',
    '$scope',
    '$stateParams',
    'EventSectionsService',
    'AuthService',
    'ApiService',
    'LocalizationService',
    'EVENT_STATES'
  ];

  function EventSlotDirective() {
    return {
      scope: {
        event: '=',
        options: '=',
        showActionButtons: '=',
        bulkTaggingSelectedTags: '=',
        useCompactView: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/events/directives/event-slot.html',
      controller: EventSlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventSlot', EventSlotDirective)
    .controller('EventSlotController', EventSlotController);
})();
