(function() {
  'use strict';

  function filter() {
    return function(input) {
      return (!input) ? '' : input.replace(/_/g, ' ');
    };
  }

  angular.module('blocks.utils')
    .filter('nounderscore', filter);
})();
