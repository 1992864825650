(function() {
  'use strict';

  //
  // Should not be used. We are now using default angular
  // $filter('date') filter
  //

  function filter($filter, LocalizationService) {
    return function(input, format) {
      var dateFormat = LocalizationService.getDateTimeFormat(format);
      return $filter('date')(input, dateFormat);
    };
  }

  filter.$inject = ['$filter', 'LocalizationService'];

  angular.module('blocks.utils')
    .filter('kzDateFormat', filter);
})();
