(function() {
  'use strict';

  var deltaDismissed = 30 * 24 * 60 * 60 * 1000;
  var deltaQuestion = 10 * 24 * 60 * 60 * 1000;

  function CleanupBanner(Cache, ConfStore, kzLocalStorage) {
    return {
      scope: {
        test: '=',
        level: '=?'
      },
      restrict: 'EA',
      templateUrl: 'app/components/settings/cleanup.banner.html',
      link: function(scope) {
        scope.shown = false;

        function checkShown() {
          var stored = kzLocalStorage.getItem('cleanUpWarning');
          var now = new Date();
          console.log('Cleanup', stored);
          var dt;
          if (stored.dismissedOn) {
            dt = new Date(stored.dismissedOn);
            if (now - dt < deltaDismissed) {
              console.log(
                'Cleanup: Ignoring because of dismissed. Will try again on ',
                new Date(dt.getTime() + deltaDismissed)
              );
              scope.shown = false;
              return;
            }
          }

          if (stored.clearedOn) {
            dt = new Date(stored.clearedOn);
            if (now - dt < deltaQuestion) {
              console.log(
                'Cleanup: Ignoring because asked recently. Will try again on ',
                new Date(dt.getTime() + deltaQuestion)
              );
              scope.shown = false;
              return;
            }
          }

          ConfStore.getStoredSize('eventType')
            .then(function(data) {
              scope.shown = data > 500;
            });
        }

        checkShown();
        scope.$on('StoreUpdated', function() {
          checkShown();
        });

        scope.cleanUp = function() {
          Cache.removeLocalData()
            .then(function() {
              kzLocalStorage.setItem('cleanUpWarning', { clearedOn: new Date() });
              console.log('Done');
              window.location.reload();
            });
        };

        scope.dismiss = function() {
          kzLocalStorage.setItem('cleanUpWarning', { dismissedOn: new Date() });
        };
      }
    };
  }

  CleanupBanner.$inject = ['CacheService', 'BaseConfStoreService', 'kzLocalStorage'];

  angular.module('component.settings')
    .directive('kzCleanupBanner', CleanupBanner);
})();
