(function() {
  'use strict';

  function SetPasswordController($q, $state, $stateParams, Accounts, Auth, Form, Notify,
                                 Utils) {
    var ctrl = this;

    Utils.setPageTitle('Set your account password');

    ctrl.action = $state.current.data.action;
    ctrl.model = {};

    Auth.isLoggedIn()
      .then(function() {
        return Auth._logout();
      })
      .catch(function() {
        console.log('You are not logged in. OK');
      })
      .then(function() {
        return Accounts.findByTokenId($stateParams.token);
      })
      .then(function(credentials) {
        if (credentials.error) {
          ctrl.tokenData = credentials.token_data;
          ctrl.organisationId = ctrl.tokenData.organisation;
          return $q.reject(credentials.error);
        }
        if (!_.isEmpty(credentials)) {
          ctrl.organisationId = credentials[0].org;
        }

        var options = _.map(credentials, function(cred) {
          var name = cred.username;
          if (_.isUndefined(cred._id)) {
            name += ' (' + cred._id + ')';
          }
          return {
            _id: cred.org + '|' + cred.username,
            key: cred.org + '|' + cred.username,
            name: name
          };
        });
        ctrl.credentialsOptions = options;

        var fields = [];
        if (options.length > 1) {
          fields.push({
            id: 'credential',
            type: 'discrete',
            label: 'Login credential',
            required: true,
            options: options
          });
        }

        fields.push(
          {
            id: 'newPassword',
            type: 'password',
            label: 'New password',
            required: true
          },
          {
            id: 'passwordConfirmation',
            type: 'password',
            label: 'Confirm your new password',
            required: true
          }
        );

        ctrl.form = new Form(fields);
      })
      .catch(function(err) {
        console.log(err);
        ctrl.error = (err && err.message) || 'Could not validate the request';
      })
      .finally(function() {
        ctrl.loaded = true;
      });

    ctrl.setPassword = function(isValid) {
      if (!isValid) {
        return;
      }

      if (ctrl.model.newPassword !== ctrl.model.passwordConfirmation) {
        Utils.showError({ message: 'The passwords are not identical' });
        return;
      }


      var credential = ctrl.model.credential;
      if (_.isUndefined(credential)) {
        if (_.isEmpty(ctrl.credentialsOptions)) {
          Utils.showError({ message: 'No credentials have been found for that account' });
        }

        credential = ctrl.credentialsOptions[0]._id;
      }

      var credentialSplitted = credential.split('|'),
          organisation = credentialSplitted[0],
          username = credentialSplitted[1],
          newPassword = ctrl.model.newPassword,
          data = {
            token_id: $stateParams.token,
            organisation: organisation,
            username: username,
            new_password: newPassword
          };

      Accounts.setPassword(data)
        .then(function() {
          if (ctrl.action === 'set-password') {
            Notify.success(
              'Your password has been set successfully!',
              'Welcome to risr/advance!'
            );
          } else {
            Notify.success('Your password has been reset successfully!', 'Nice!');
          }

          Auth.setOrganisation(organisation);
          return Auth.login(organisation, username, newPassword);
        })
        .then(function() {
          $state.go('dashboard.index');
        })
        .catch(Utils.showError);
    };

    ctrl.resetPassword = function() {
      if (ctrl.tokenData === undefined) {
        return;
      }

      var data = ctrl.tokenData;
      Accounts.requestResetPassword(data)
        .then(function() {
          Notify.success(
            'Password reset email sent'
          );
        })
        .catch(Utils.showError);
    };
  }

  SetPasswordController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    'AccountsService',
    'AuthService',
    'FormsService',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.accounts')
    .controller('SetPasswordController', SetPasswordController);
})();
