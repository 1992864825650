(function() {
  'use strict';

  var defaultTemplateId = '__default__';
  var disbabledTemplateId = '__disabled__';

  angular.module('component.emailTemplates')
    .constant('DEFAULT_TEMPLATE_ID', defaultTemplateId)
    .constant('DISABLED_TEMPLATE_ID', disbabledTemplateId);
})();
