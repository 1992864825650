(function() {
  'use strict';

  function BlueprintUtils($q, Cache, User, Relations, Blueprints, Auth) {
    var service = {};

    service.getRestrictedBlueprints = function(originalBlueprints, options) {
      options = options || {};

      var opts = { period: options.period };
      if (!_.isUndefined(options.user) && options.user !== Auth.currentUser()) {
        opts = { user: options.user };
      }

      // Get all user relations
      var areSomeByRelations = _.chain(originalBlueprints)
        .map(function(blueprint) {
          return blueprint.restrictable;
        })
        .some()
        .value();

      var relationBlueprints;
      if (areSomeByRelations) {
        relationBlueprints = User.getRelations(opts)
          .then(function(relations) {
            // format relations and merge then if needed
            var periods = [];
            if (options.period === 'all') {
              periods = [relations.current, relations.previous, relations.future];
            } else if (options.period === 'until now') {
              periods = [relations.current, relations.previous];
            } else if (options.period === 'previous') {
              periods = [relations.previous];
            } else if (options.period === 'future') {
              periods = [relations.future];
            } else {
              periods = [relations.current];
            }

            var formatedRelations = {};
            _.forEach(periods, function(byPeriod) {
              _.forEach(byPeriod, function(obj, itemId) {
                var values = _.keysIn(obj.values);
                formatedRelations[itemId] = _.union(formatedRelations[itemId] || [], values);
              });
            });

            return formatedRelations;
          })

          // Find the actual subcategory for all of them
          .then(function(relations) {
            var promises = [];

            function getSubCategory(subsubrel) {
              var prom = Relations.findSubCategory(subsubrel, { cached: true })
                .catch(function(error) {
                  console.log(error);
                });
              promises.push(prom);
            }

            _.forOwn(relations, function(subrel) {
              subrel.forEach(getSubCategory);
            });

            return $q.all(promises)
              .then(function(relations) {
                return _.filter(relations, function(rel) {
                  return !_.isUndefined(rel);
                });
              });
          })

          // Retrieve blueprints from subcategories and merge them
          .then(function(relations) {
            var blueprints = {};
            relations.forEach(function(item) {
              if (item === undefined) {
                return;
              }

              if (item.blueprints) {
                _.forOwn(item.blueprints, function(value, key) {
                  blueprints[key] = _.union(blueprints[key] || [], value);
                });
              }
            });

            return blueprints;
          })

          // Replace id by blueprint subcategories;
          .then(function(blueprints) {
            var promises = [$q.when(blueprints)];
            _.forOwn(blueprints, function(value) {
              value.forEach(function(item) {
                promises.push(
                  Blueprints.findSubCategory(item, _.assign({}, options, { cached: true }))
                    .catch(function(error) {
                      if (error && error.status === 404) {
                        console.log('Sub category not found', item, error);
                        return;
                      }
                      console.log('Error when fetching subcategory', item, error);
                      return $q.reject(error);
                    })
                );
              });
            });
            return $q.all(promises);
          })

          // Populate blueprints by the loaded data
          .then(function(result) {
            var blps = result[0];
            var full = {};
            _.forOwn(blps, function(value, key) {
              full[key] = [];
              value.forEach(function(item) {
                var blp = _.find(result, { _id: item });
                if (blp) {
                  full[key].push(_.find(result, { _id: item }));
                }
              });
              full[key] = _.sortBy(full[key], function(category) {
                return (category.meta || []).order;
              });
            });

            return full;
          });
      }

      if (originalBlueprints === undefined) {
        originalBlueprints = Blueprints.findAll()
          .then(function(blueprints) {
            blueprints = blueprints.map(function(item) {
              return item.doc;
            });

            blueprints = _.filter(blueprints, function(item) {
              return _.indexOf(['discrete', 'discrete_multiple', 'tree'], item.blueprintType) > -1;
            });

            return blueprints;
          });
      } else {
        originalBlueprints = $q.when(originalBlueprints);
      }

      return $q.all([relationBlueprints, originalBlueprints])
        .then(function(result) {
          var relBlp = result[0];
          var blueprints = angular.copy(result[1]);

          return _.chain(blueprints)
            .filter(function(blueprint) {
              // If blueprint is byRelation filter out otherwise do not filter
              if (blueprint.restrictable) {
                if (!_.isUndefined(relBlp)) {
                  return !_.isUndefined(relBlp[blueprint._id]);
                }
                return false;
              }

              return true;
            })
            .map(function(blueprint) {
              if (!_.isUndefined(relBlp) && !_.isUndefined(relBlp[blueprint._id])) {
                var subBlueprint = relBlp[blueprint._id];
                // In case there was only one blueprint selected, take it as a root
                // otherwise show the entire list
                if (subBlueprint.length === 1 && blueprint._id === subBlueprint[0]._id) {
                  blueprint.categories = subBlueprint[0].categories;
                } else {
                  blueprint.categories = subBlueprint;
                }
              }
              return blueprint;
            })
            .value();
        })
        .catch(function(error) {
          console.log(error);
          // Quick fix - we need to decide what we do in this case
          return [];
        });
    };

    // todo rename this
    service.getTaggable = function() {
      return Blueprints.findDiscreteOnly()
        .then(function(blueprints) {
          // filter by taggable options
          var taggable = {};

          taggable.openToEveryone = _.filter(blueprints, function(blueprint) {
            return !blueprint.restrictable && (blueprint.usage || []).indexOf('events') !== -1;
          });

          taggable.openByRelation = _.filter(blueprints, function(blueprint) {
            return blueprint.restrictable && (blueprint.usage || []).indexOf('events') !== -1;
          });

          return taggable;
        });
    };

    service.getTaggableBlueprints = function(options) {
      var opts = options || {};
      return service.getTaggable()
        .then(function(taggable) {
          var blueprints = angular.copy(taggable.openToEveryone);
          return service.getRestrictedBlueprints(taggable.openByRelation, opts)
            .then(function(restrictedBlueprint) {
              return blueprints.concat(restrictedBlueprint);
            });
        })
        .then(function(result) {
          return _.filter(result, function(item) {
            return item !== undefined;
          });
        });
    };

    service.getHistoricalCoverages = function(user, options) {
      var opts = options || {};
      // return format:
      // current: [{ blueprints: {}, relation: '', dates: []}], previous: [...]

      var cacheKey = 'blueprints-historical-coverages-' + user;
      if (opts.cached) {
        var historicalCoverages = Cache.get(cacheKey, opts);
        if (historicalCoverages) {
          return $q.when(historicalCoverages);
        }
      }

      var blueprintsByPeriod = User.getRelations({ user: user })
        .then(function(relations) {
          // format it in plain
          var result = {};
          _.forEach(relations, function(relationIds, period) {
            result[period] = [];
            _.forEach(relationIds, function(relation) {
              _.forEach(relation.values, function(dates, relationValue) {
                result[period].push({ relation: relationValue, dates: dates });
              });
            });
          });

          return result;
        })
        .then(function(relations) {
          var promises = [];
          _.forEach(relations, function(rs) {
            var _rs = angular.copy(rs);
            _.forEach(_rs, function(r, i) {
              var prom = Relations.findSubCategory(r.relation, { cached: true })
                .catch(function(error) {
                  rs.splice(i, 1);
                  console.log(error);
                });
              promises.push(prom);
            });
          });

          return $q.all(promises)
            .then(function(results) {
              results = _.filter(results, function(result) {
                return !_.isUndefined(result);
              });

              var mapRelations = {};
              _.forEach(results, function(result) {
                if (result === undefined) {
                  return;
                }

                mapRelations[result._id] = result.blueprints;
              });

              return [relations, mapRelations];
            });
        })
        .then(function(result) {
          // incude blueprints in relations and filter by blueprintId;
          var relations = result[0];
          var mapRelations = result[1];

          _.forEach(relations, function(rs, period) {
            relations[period] = _.map(rs, function(r) {
              r.blueprint = mapRelations[r.relation];
              return r;
            });
          });

          return relations;
        });

      return $q.all([
        service.getTaggable(),
        blueprintsByPeriod
      ]).then(function(result) {
        var taggable = result[0];
        var blueprintsByPeriod = result[1];

        var historicalCoverages = {};
        if (taggable.openToEveryone) {
          historicalCoverages.current = _.map(taggable.openToEveryone, function(blueprint) {
            var result = { blueprint: {} };
            result.blueprint[blueprint._id] = [];
            return result;
          });
        }

        taggable.openByRelation = _.map(taggable.openByRelation, function(blueprint) {
          return blueprint._id;
        });

        _.forEach(blueprintsByPeriod, function(blueprints, periodName) {
          _.forEach(blueprints, function(blueprint) {
            // filter by only openByRelation
            var b = {};
            _.forEach(blueprint.blueprint, function(_values, blueprintId) {
              if (_.indexOf(taggable.openByRelation, blueprintId) > -1) {
                b[blueprintId] = _values;
              }
            });
            if (!_.isEmpty(b)) {
              if (_.isUndefined(historicalCoverages[periodName])) {
                historicalCoverages[periodName] = [];
              }

              blueprint.blueprint = b;
              historicalCoverages[periodName].push(blueprint);
            }
          });
        });

        Cache.put(cacheKey, historicalCoverages, opts);
        return historicalCoverages;
      });
    };

    service.getBlueprintsField = function(user) {
      return service.getTaggableBlueprints({
        period: 'until now',
        user: user
      })
        .then(function(blueprints) {
          // Return undefined if no blueprints so that we can display a nice error
          if (blueprints.length === 0) {
            return;
          }

          return {
            id: 'blueprints',
            controller: ['$scope', function($scope) {
              if (blueprints.length === 0) {
                $scope.tree = [];
              } else if (blueprints.length === 1) {
                $scope.tree = blueprints[0].categories;
              } else {
                $scope.tree = blueprints;
              }
              $scope.selected = [];
              $scope.kzTree = '';
              $scope.treeOptions = {
                selectable: true,
                searchable: true,
                icons: {
                  arrowRight: 'icon-right-open-big',
                  arrowDown: 'icon-down-open-big',
                  empty: 'icon-minus'
                }
              };
            }],
            type: 'tree',
            label: 'Tags',
            required: false,
            ngModelAttrs: {
              kzTree: {
                bound: 'kzTree',
                attribute: 'kz-tree'
              },
              selected: {
                bound: 'selected',
                attribute: 'selected'
              },
              nodes: {
                bound: 'nodes',
                attribute: 'nodes'
              },
              options: {
                bound: 'treeOptions',
                attribute: 'options'
              }
            },
            ngModelAttrsValues: [
              {
                name: 'kzTree',
                value: 'kzTree'
              },
              {
                name: 'selected',
                value: 'selected'
              },
              {
                name: 'nodes',
                value: 'tree'
              },
              {
                name: 'options',
                value: 'treeOptions'
              }
            ]
          };
        });
    };

    return service;
  }

  BlueprintUtils.$inject = [
    '$q',
    'CacheService',
    'UserFactory',
    'RelationsService',
    'BlueprintsService',
    'AuthService'
  ];

  angular.module('component.blueprints')
    .factory('BlueprintUtils', BlueprintUtils);
})();
