(function() {
  'use strict';

  function ToggleSidebar($aside) {
    return {
      restrict: 'AE',
      template: '<p class="pull-right visible-xs visible-sm">' +
      '<button type="button" class="btn btn-primary btn-xs" data-ng-click="open()">' +
      'Show more...' +
      '</button>' +
      '</p>',
      link: function(scope) {
        scope.open = function() {
          $aside.open({
            template: '<div class="modal-body visible-xs visible-sm" style="height: 100%;">' +
            '<div class="row">' +
            '<p class="pull-right"><button type="button" class="btn btn-primary btn-xs" ' +
            'data-ng-click="cancel($event)" aria-label="Cancel"><i class="icon-cancel"></i>' +
            '</button></p>' +
            '<div class="row"><div class="col-xs-12">' +
            '<div data-kz-sidebar ></div></div></div>' +
            '</div>',
            placement: 'right',
            size: 'xl',
            controller: [
              '$scope',
              '$uibModalInstance',
              'SidebarService',
              function($scope, $uibModalInstance, SidebarService) {
                $scope.cards = SidebarService.getCardsForRoute();

                $scope.cancel = function(e) {
                  $uibModalInstance.dismiss();
                  e.stopPropagation();
                };
              }
            ]
          });
        };
      }
    };
  }

  ToggleSidebar.$inject = ['$aside'];

  angular.module('layout.sidebar')
    .directive('kzToggleSidebar', ToggleSidebar);
})();
