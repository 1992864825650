(function() {
  'use strict';

  var versionStates = {
    draft: 'draft',
    published: 'published',
    archived: 'archived'
  };

  angular.module('blocks.versions')
    .constant('VERSION_STATES', versionStates);
})();
