(function() {
  'use strict';

  function EmailTemplatesService(RestEasApi, _EasApi) {
    var service = new RestEasApi('email-templates');

    service.duplicate = function(emailTemplateId) {
      return service.find(emailTemplateId)
        .then(function(obj) {
          var res = _.pick(obj, ['email_type', 'name', 'partials']);
          res.name = 'Copy of ' + res.name;
          return res;
        });
    };

    return service;
  }

  EmailTemplatesService.$inject = ['RestEasApi', 'EasApiService'];

  angular.module('component.emailTemplates')
    .factory('EmailTemplatesService', EmailTemplatesService);
})();
