(function() {
  'use strict';

  function EmailLogsService(RestEasApi, EasApi) {
    var service = new RestEasApi('email-logs');

    service.resend = function(emailLogId, options) {
      var url = 'email-logs/' + emailLogId + '/resend';
      return EasApi.post(url, options);
    };

    return service;
  }

  EmailLogsService.$inject = ['RestEasApi', 'EasApiService'];

  angular.module('component.emailTemplates')
    .factory('EmailLogsService', EmailLogsService);
})();
