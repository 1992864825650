(function() {
  'use strict';

  function Slot($rootScope, Profile) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/events/cards/bulktagging.card.html',
      link: function(scope) {
        var prefs = Profile.getPreferences();
        if (prefs.bulkTagging) {
          scope.model = prefs.bulkTagging.selected;
        }

        scope.remove = function(item) {
          _.remove(scope.model, function(val) {
            return val === item;
          });
          $rootScope.$broadcast('KZPreferencesChange');
        };

        scope.removeAll = function() {
          _.remove(scope.model, function() {
            return true;
          });
          $rootScope.$broadcast('KZPreferencesChange');
        };
      }
    };
  }

  Slot.$inject = ['$rootScope', 'ProfileService'];

  angular.module('component.events')
    .directive('sidebarBulk', Slot);
})();
