(function() {
  'use strict';

  var types = [
    {
      label: 'Heading',
      name: 'heading'
    },
    {
      label: 'Descriptive text',
      name: 'text'
    }
  ];

  angular.module('component.eventTypes')
    .constant('FORM_TEXT_TYPES', types);
})();
