(function() {
  'use strict';

  function HeaderMenuController($scope, $state, $timeout, $transitions, $window) {
    var ctrl = this;
    // var getBaseStateName = function(stateName) {
    //   // Because some routes are either epf.*.* or dashboard.*, without epf
    //   // We strip the last occurence of the "."
    //   // Only if there is more than one occurence
    //   // https://ui-router.github.io/ng1/docs/0.3.1/index.html#/api/ui.router.state.$state
    //   var idx = _.lastIndexOf(stateName, '.');
    //   var isAlreadyBase = idx === _.indexOf(stateName, '.');

    //   return isAlreadyBase ? stateName : stateName.slice(0, idx === -1 ? stateName.length : idx);
    // };

    this.stateIncludes = function(stateName) {
      var res = $state.includes(stateName);
      // res = res || $state.includes(getBaseStateName(stateName));
      return res;
    };

    var isStateActive = function(link) {
      if (!ctrl.stateIncludes(link.state, link.stateOptions)) {
        return false;
      }

      if (link.stateOptions) {
        var prms = $state.params;
        var matched = true;
        _.forOwn(link.stateOptions, function(value, key) {
          matched = matched && prms[key] === value;
        });
        return matched;
      }
      return true;
    };

    var updateActive = function() {
      _.forEach($scope.links || [], function(link) {
        _.forEach(link.links || [], function(sublink) {
          sublink.active = isStateActive(sublink);
        });
        var other = _.some(link.links || [], { active: true });
        link.active = isStateActive(link) || other;
      });
    };

    // var stateListener = $scope.$on('$stateChangeSuccess',
    var stateListener = $transitions.onSuccess({},
    function() {
      updateActive();
    });

    $scope.$on('$destroy', function() {
      if (stateListener) {
        stateListener();
      }
    });
    updateActive();

    this.hideMenu = function() {
      // We need to hide it for same media as in app/layout/header/header.styles.scss
      if ($window.innerWidth <= 992) {
        $timeout(function() {
          // angular.element('#collapse-navbar').trigger('click');
          angular.element('#collapse-navbar-remote').trigger('click');
        });
      }
    };
  }

  function HeaderMenuDirective() {
    return {
      restrict: 'EA',
      templateUrl: 'app/layout/header/kz-header-menu.html',
      transclude: true,
      scope: {
        links: '=menuItems'
      },
      link: function(scope, element, _attrs, _ctrls, $transclude) {
        $transclude(scope.$parent, function(clone) {
          element.prepend(clone);
        });
      },
      controller: 'HeaderMenuController',
      controllerAs: 'headerMenuCtrl'
    };
  }

  HeaderMenuController.$inject = ['$scope', '$state', '$timeout', '$transitions', '$window'];

  angular.module('kzHeaderCard')
    .directive('kzMenu', HeaderMenuDirective)
    .controller('HeaderMenuController', HeaderMenuController);
})();
