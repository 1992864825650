(function() {
  'use strict';

  function GoalsDefinitionController($state, $stateParams, Event, Goal, Goals, Utils,
                                     Notify) {
    var ctrl = this;

    ctrl.action = $state.current.data.action;
    // add mode
    ctrl.eventId = $stateParams.eventId;
    ctrl.sectionId = $stateParams.sectionId;
    ctrl.fieldId = $stateParams.fieldId;

    // edit mode
    ctrl.goalId = $stateParams.id;

    ctrl.loaded = false;

    if (ctrl.action === 'add') {
      var event = new Event();
      event.load(ctrl.eventId, $stateParams.user)
        .then(function(event) {
          return event.checkPermission('canAddGoals');
        })
        .then(function() {
          ctrl.title = 'Add new goals to ' + event.eventType.name;
          Utils.setPageTitle(ctrl.title);
          ctrl.eventTypeId = event.doc.eventType;
        })
        .catch(function() {
          Utils.swal({
            title: 'This page could not be loaded!',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK'
          },
          function() {
            $state.go('epf.goals.index', {}, { reload: true });
          });
        });
    } else if (ctrl.action === 'edit') {
      // load the goal
      Goal.find(ctrl.goalId, $stateParams.user)
        .then(function(goal) {
          ctrl.goal = goal;
          return ctrl.goal.checkPermission('canEdit');
        })
        .then(function() {
          ctrl.eventTypeId = ctrl.goal.event.doc.eventType;

          _.forEach(ctrl.goal.doc.targets, function(target) {
            if (_.isUndefined(target.title)) {
              target.title = 'Linked Events';
            }
          });

          ctrl.definitions = [ctrl.goal.doc];
          ctrl.title = 'Edit ' + ctrl.goal.doc.title;
          Utils.setPageTitle(ctrl.title);
        })
        .catch(function(error) {
          console.log(error);
          Utils.swal({
            title: 'The goal could not be loaded!',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK'
          },
          function() {
            $state.go('epf.goals.index', {}, { reload: true });
          });
        });
    }

    ctrl.saveDefinitions = function(goalsDefinitions, isValid) {
      if (!isValid) {
        return;
      }

      if (ctrl.action === 'add') {
        Goals.addGoalsToGoalSet(goalsDefinitions, ctrl.eventId, ctrl.sectionId, ctrl.fieldId)
          .then(function() {
            if ($stateParams.user) {
              $state.go('epf.users.goals', { user: $stateParams.user }, { reload: true });
            } else {
              $state.go('epf.goals.index', {}, { reload: true });
            }
          })
          .catch(function(err) {
            Notify.error(err.message);
          });
      } else if (ctrl.action === 'edit') {
        var goal = goalsDefinitions[0];
        var dataToSave = {
          _id: goal._id,
          title: goal.title,
          description: goal.description,
          targets: goal.targets
        };

        Goals.saveViaApi(dataToSave)
          .then(function() {
            if ($stateParams.user) {
              $state.go(
                'epf.users.goals-work',
                { id: goal._id, user: $stateParams.user },
                { reload: true }
              );
            } else {
              $state.go('epf.goals.work', { id: goal._id }, { reload: true });
            }
          })
          .catch(function(err) {
            Notify.error(err.message);
          });
      }
    };
  }

  GoalsDefinitionController.$inject = [
    '$state',
    '$stateParams',
    'EventFactory',
    'GoalFactory',
    'GoalsService',
    'UtilsService',
    'NotifyService'
  ];

  angular.module('component.goals')
    .controller('GoalsDefinitionController', GoalsDefinitionController);
})();
