(function() {
  'use strict';

  function AccountsService(Api) {
    var service = {};

    service.requestResetPassword = function(data) {
      return Api.post('reset_password_token_create', data);
    };

    service.getResetPasswordToken = function(tokenId) {
      return Api.get('reset_password_token_get', {}, { tokenId: tokenId });
    };

    service.setPassword = function(data) {
      return Api.put('reset_password', data, {});
    };

    service.find = function(accountId) {
      return Api.get('useraccounts', {}, { account_id: accountId });
    };

    service.findByTokenId = function(tokenId) {
      return Api.get('useraccountsbytoken', {}, { token_id: tokenId });
    };

    service.updateCredentials = function(actionName, accountId, data) {
      return Api.put(
        'updatecredentials',
        { data: data },
        { actionName: actionName, accountId: accountId }
      );
    };

    service.getIntegrations = function() {
      return { org_rcpch: { autoGenerateUsername: true, autoGeneratePassword: false } };
    };

    return service;
  }

  AccountsService.$inject = ['ApiService'];

  angular.module('component.accounts')
    .factory('AccountsService', AccountsService);
})();
