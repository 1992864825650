(function() {
  'use strict';

  function BulkActionsService($q, Security, BULK_ACTIONS_DEFINITIONS) {
    var service = {};

    service.getAvailableBulkActions = function() {
      return $q.all(BULK_ACTIONS_DEFINITIONS.map((taskDef) => {
        return $q.all([
          $q.all(taskDef.securityPolicy.roles.map(role =>
            Security.hasRole(role, { promise: true })
              .catch(() => false)
          )).then(results => results.some(hasRole => hasRole)),

          $q.all(taskDef.securityPolicy.permissions.map(permission =>
            Security.hasPermission(permission, { promise: true })
              .catch(() => false)
          )).then(results => results.some(hasPermission => hasPermission))
        ]).then(([anyRoleSatisfied, anyPermissionSatisfied]) => {
          if (anyRoleSatisfied || anyPermissionSatisfied) {
            return taskDef;
          }
          return null;
        });
      })).then(results => results.filter(item => item !== null));
    };

    return service;
  }

  BulkActionsService.$inject = [
    '$q',
    'SecurityService',
    'BULK_ACTIONS_DEFINITIONS'
  ];

  angular.module('component.importer')
    .factory('BulkActionsService', BulkActionsService);
})();
