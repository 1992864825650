(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.settings', {
        url: 'settings/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }]
        }
      })

      .state('epf.settings.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/settings/form.html',
            controller: 'SettingsController',
            controllerAs: 'settingsCtrl'
          }
        }
      })

      .state('epf.settings.review', {
        url: 'review',
        views: {
          content: {
            templateUrl: 'app/components/settings/review.html',
            controller: 'ReviewController',
            controllerAs: 'reviewCtrl'
          }
        }
      })

      .state('epf.settings.status', {
        url: 'status',
        views: {
          content: {
            templateUrl: 'app/components/settings/status.html',
            controller: 'StatusController',
            controllerAs: 'statusCtrl'
          }
        }
      })

      .state('epf.settings.offlinesettings', {
        url: 'offline',
        params: {
          showAdvanced: null
        },
        views: {
          content: {
            templateUrl: 'app/components/settings/offlinesettings.html',
            controller: 'OfflineSettingsController',
            controllerAs: 'offlineCtrl'
          }
        }
      })

      .state('epf.settings.offlinetest', {
        url: 'test',
        views: {
          content: {
            templateUrl: 'app/components/settings/offlinetest.html',
            controller: 'OfflineTestController',
            controllerAs: 'testCtrl'
          }
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.settings')
    .config(config);
})();
