(function() {
  'use strict';

  function kzField(Form) {
    var controller = ['$scope', function($scope) {
      var ctrl = this;

      ctrl.form = new Form();
      ctrl.form.addField($scope.filter);
    }];

    return {
      restrict: 'AEC',
      scope: {
        search: '=',
        filter: '=',
        searchModel: '='
      },
      controller: controller,
      controllerAs: 'ctrl',
      templateUrl: 'app/blocks/widgets/filters/kz-field.html'
    };
  }

  kzField.$inject = ['FormsService'];

  angular.module('blocks.widgets')
    .directive('kzFieldFilter', kzField);
})();
