(function() {
  'use strict';

  var goalsStates = {
    inProgress: {
      id: 'inProgress',
      name: 'In progress',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    carriedForward: {
      id: 'carriedForward',
      name: 'Carried forward',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    partiallyAchieved: {
      id: 'partiallyAchieved',
      name: 'Partially achieved',
      labelStyle: 'success',
      borderStyle: 'complete'
    },
    notAchieved: {
      id: 'notAchieved',
      name: 'Not achieved',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    achieved: {
      id: 'achieved',
      name: 'Achieved',
      labelStyle: 'success',
      borderStyle: 'complete'
    }
  };

  angular.module('component.goals')
    .constant('GOALS_STATES', goalsStates);
})();
