(function() {
  'use strict';

  function EventLinkedTargetsController() {
    var ctrl = this;

    ctrl.show = function() {

    };
  }

  EventLinkedTargetsController.$inject = [];

  function EventLinkedTargetsDirective() {
    return {
      scope: {
        event: '=',
        goals: '=',
        linkedTargets: '='
      },
      restrict: 'AE',
      template: '<span data-ng-show="linkedTargets.length" class="label label-danger">' +
      'THIS EVENT IS LINKED TO <a href="" data-ng-click="eventLinkedTargetsCtrl.show()">' +
      '{{ linkedTargets.length }} OTHER TARGETS</a>' +
      '</span>',
      replace: true,
      controller: EventLinkedTargetsController,
      controllerAs: 'eventLinkedTargetsCtrl'
    };
  }

  angular.module('component.goals')
    .directive('eventLinkedTargets', EventLinkedTargetsDirective)
    .controller('EventLinkedTargetsController', EventLinkedTargetsController);
})();
