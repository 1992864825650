(function() {
  'use strict';

  function GoalController(
    $q,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    Goal,
    Profile,
    List,
    Utils,
    Users,
    Network,
    Notify,
    GOALS_STATES,
    LocalizationService
  ) {
    var ctrl = this;

    ctrl.GOALS_STATES = GOALS_STATES;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.periodId = $stateParams.periodId;
    ctrl.action = $state.current.data.action;

    var prefs = Profile.getPreferences();
    ctrl.options = {};

    function hasPerm(action) {
      var permission;

      if (action === 'view') {
        permission = 'canViewAny';
      } else if (action === 'work') {
        permission = 'canWork';
      }

      if (!permission) {
        return $q.reject({ status: 403 });
      }

      return ctrl.goal.checkPermission(permission);
    }

    function buildEventHref() {
      var route = 'epf.events.view';
      var args = { id: ctrl.goal.event.doc._id };
      if (!ctrl.goal.isMine()) {
        route = 'epf.users.events-view';
        args.user = ctrl.goal.event.doc.user;
      }

      ctrl.eventHref = $state.href(route, args);
    }

    var updateVisibility = function(event, changeTo) {
      Utils.swal({
        title: 'This will change the visibility of the event and ' +
                'all the goals. Do you wish to continue?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          if (changeTo === 'private') {
            event.makePrivate();
          } else if (changeTo === 'public') {
            event.makePublic();
          }
        }
      });
    };

    ctrl.markGoal = function() {
      Network.forceOnline({ showToastr: true })
        .then(function() {
          return ctrl.goal.openResolveDataModal();
        })
        .then(function() {
          ctrl.goal.reloadState();
        });
    };

    function loadButtons() {
      ctrl.actionButtons = [
        {
          id: 'showDescription',
          icon: 'icon-history',
          label: ctrl.shownDescription ? 'Hide audit log' : 'Show audit log',
          klass: 'text-info',
          onClick: function() {
            ctrl.shownDescription = !ctrl.shownDescription;
            this.label = ctrl.shownDescription ? 'Hide audit log' : 'Show audit log';
          },
          showCondition: function() {
            return !_.isUndefined(ctrl.goal.doc.dates);
          }
        }
      ];

      ctrl.actionButtons.push.apply(
        ctrl.actionButtons,
        [
          {
            label: 'Reload',
            icon: 'icon-reload',
            onClick: function() {
              ctrl.loadGoal()
                .then(function() {
                  Notify.success('Goal reloaded');
                })
                .catch(function(err) {
                  console.log(err);
                  Notify.error('Reloading of goal failed');
                });
            },
            klass: 'text-info',
            showCondition: ctrl.goal.checkPermission.bind(ctrl.goal, 'canEdit')
          },
          {
            label: 'Edit',
            icon: 'icon-edit',
            href: (function() {
              var route = 'epf.goals.editDefinition';
              if (!ctrl.goal.isMine()) {
                route = 'epf.users.goals-editDefinition';
              }

              return $state.href(route, { id: ctrl.goal.doc._id, user: Users.remoteUser });
            })(),
            klass: 'text-info',
            showCondition: ctrl.goal.checkPermission.bind(ctrl.goal, 'canEdit')
          },
          {
            label: 'Delete',
            icon: 'icon-trash',
            onClick: function() {
              Network.forceOnline({ showToastr: true })
                .then(function() {
                  return ctrl.goal.remove();
                })
                .then(function() {
                  if (ctrl.goal.isMine()) {
                    $state.go('epf.goals.index');
                  } else {
                    $state.go('epf.users.goals', { user: $stateParams.user }, { reload: true });
                  }
                });
            },
            klass: 'text-danger',
            showCondition: ctrl.goal.checkPermission.bind(ctrl.goal, 'canRemove')
          }
        ]
      );

      if (!_.isEmpty(ctrl.goal.event)) {
        ctrl.actionButtons.push.apply(
          ctrl.actionButtons,
          [
            {
              label: 'Share',
              icon: 'icon-lock-open',
              onClick: function() {
                updateVisibility(ctrl.goal.event, 'public');
              },
              klass: 'text-info',
              showCondition: ctrl.goal.event.checkPermission.bind(
                ctrl.goal.event,
                'canMakePublic'
              )
            },
            {
              label: 'Make private',
              icon: 'icon-lock',
              onClick: function() {
                updateVisibility(ctrl.goal.event, 'private');
              },
              klass: 'text-danger',
              showCondition: ctrl.goal.event.checkPermission.bind(
                ctrl.goal.event,
                'canMakePrivate'
              )
            }
          ]
        );
      }
    }

    var load;
    var loadWatched;

    loadWatched = function() {
      $rootScope.$on('goalProgressUpdated', function() {
        load().then(function() {
          return ctrl.goal.setPeriod();
        });
      });

      // used to change visibility
      $scope.$on('DBPullChange', function(_evt, args) {
        if (!_.isEmpty(ctrl.goal.event) && args.id === ctrl.goal.event.doc._id) {
          load();
        }
      });
    };

    ctrl.list = new List({ idField: '_id' });
    ctrl.options = { trackBy: '_id' };

    function selectPeriod(periodId) {
      ctrl.goal.setPeriod(periodId);
    }

    $scope.$on('GoalPeriodSelected', function(_evt, periodFilter) {
      var splitted = periodFilter.split('|'),
          periodId = splitted[1];
      selectPeriod(periodId);
    });

    load = function() {
      return Goal.find($stateParams.id, Users.remoteUser, { loadGoalSetSummary: true })
        .then(function(goal) {
          if (!goal) {
            $state.go('epf.goals.index');
            return $q.reject();
          }

          ctrl.goal = goal;
          Utils.setPageTitle('Goals: ' + ctrl.goal.doc.title);
          prefs.goalDoc = ctrl.goal.doc;
          var periodId = goal.getCurrentPeriodId();
          selectPeriod(periodId);

          return goal;
        })
        .then(function(goal) {
          // in case the event has been deleted, let the opportunity to the owner to delete it.
          if (_.isUndefined(goal.event.eventType) && goal.isMine()) {
            return $q.when(true);
          }
          return hasPerm(ctrl.action);
        })
        .then(function() {
          if (!_.isEmpty(ctrl.goal.event)) {
            buildEventHref();
          } else {
            ctrl.noEvent = true;
          }

          loadButtons();
          return $q.when();
        })
        .then(function() {
          ctrl.isSetClosed = ctrl.goal.isSetClosed();
          var canMark = ctrl.goal.checkPermission('canMark')
            .catch(function() { return $q.when(false); });

          return $q.all([ctrl.goal.isMarkStepEnabled(), canMark])
            .then(function(result) {
              ctrl.isMarkStepEnabled = result[0];
              ctrl.canMark = result[1];
            });
        })
        .then(function() {
          loadWatched();
        });
    };

    load()
      .catch(function(error) {
        if (error && error.status === 404) {
          $state.go('epf.goals.index');
          return;
        }

        // Utils.showError(error);
        $state.go('epf.goals.index');
      });
    ctrl.loadGoal = load;

    $scope.$on('$destroy', function() {
      prefs.goalDoc = undefined;
    });
  }

  GoalController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'GoalFactory',
    'ProfileService',
    'ListFactory',
    'UtilsService',
    'UsersService',
    'NetworkService',
    'NotifyService',
    'GOALS_STATES',
    'LocalizationService'
  ];

  angular.module('component.goals')
    .controller('GoalController', GoalController);
})();
