(function() {
  'use strict';

  var regexp = /{{(.*?)}}/g;

  function filter($filter, LocalizationService) {
    return function(content) {
      var result = content.replaceAll(regexp, function(_item, inner) {
        var parts = _.map(inner.split('|'), function(itm) {
          return itm.trim();
        });
        console.log(parts);

        if (parts[0] === 'current_date') {
          var format = parts[1] || 'dateonly';
          var dateFormat = LocalizationService.getDateTimeFormat(format);
          var str;
          if (dateFormat === undefined) {
            str = '<- invalid date format ->';
          } else {
            var today = new Date();
            str = $filter('date')(today, dateFormat);
          }
          return str;
        }
        return 'n/a';
      });
      return result;
    };
  }

  filter.$inject = ['$filter', 'LocalizationService'];

  angular.module('blocks.utils')
    .filter('placeholder', filter);
})();
