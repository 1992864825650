(function() {
  'use strict';

  function OrganisationsService($q, $log, BaseConfStore, Auth, RestApi, Api,
    Cluster, kzLocalStorage
  ) {
    var service = new RestApi('organisations');

    service.findAllWithCluster = function() {
      return $q.all([
        service.findAll({}, {}, { noCache: true }),
        Cluster.getAllOrganisations()
      ]).then(function(result) {
        var localOrgs = result[0];
        var allOrgs = result[1];

        var final = _.map(allOrgs, function(org) {
          var local = _.find(localOrgs, function(item) {
            return item.doc._id === org.id;
          });

          if (!local) {
            org.description = 'This organisation is in a different cluster. To edit please switch.';
          }
          return {
            id: org.id,
            doc: org,
            local: local !== undefined,
            state: org.id + ' in ' + org.cluster
          };
        });
        return final;
      });
    };

    service.findAllPublic = function(options) {
      // TODO: Change this logins to a better name, maybe organisations
      return Api.get('logins', undefined, undefined, options)
        .then(function(logins) {
          kzLocalStorage.setItem('allPublic', logins);
          return logins;
        })
        .catch(function(err) {
          console.log(err);
          var logins = kzLocalStorage.getItem('allPublic');
          if (!logins) {
            return $q.reject(err);
          }

          return logins;
        });
    };

    service.getClusterAvailableLanguages = function() {
      return Api.get('cluster_available_languages');
    };

    service.getLoginOptions = function(orgId, options) {
      return service.findAllPublic(options)
        .then(function(data) {
          var org = _.find(data.logins, { id: orgId });
          if (org === undefined) {
            return $q.reject({ status: 404, message: 'Organisation not found' });
          }
          return org;
        });
    };

    service.find = function(orgId) {
      if (orgId === undefined) {
        return BaseConfStore.getOneOf('organisation');
      }

      return Api.get(this.rest_endpoint, { _id: orgId });
    };

    service.getConf = function(options) {
      options = _.assignIn({}, { allowEmpty: true, cached: true }, options || {});
      return BaseConfStore.getOneOf('orgConf', options);
    };

    service.getListOptions = function(options) {
      return service.getConf(options)
        .then(function(conf) {
          return conf.listOptions || {};
        });
    };

    service.getListOptionsFor = function(route, options) {
      return service.getListOptions(options)
        .then(function(conf) {
          var _route = conf[route] || {};
          // Transform if needed
          if (route === 'epf.events.index' && _route.sections) {
            _.forEach(_route.sections, function(section) {
              if (section.filter && !section.filters && section.filter.eventType_versionGroupId) {
                section.filters = [
                  {
                    dataType: 'eventType_versionGroupId',
                    value: section.filter.eventType_versionGroupId,
                    condition: '='
                  }
                ];
                delete section.filter;
              }
            });
          }

          return _route;
        });
    };

    service.getFilter = function(route, filterId) {
      return service.getListOptionsFor(route)
        .then(function(listOption) {
          return _.find(listOption.sections || [], { id: filterId });
        });
    };

    service.getUserOrganisations = function() {
      return Api.get('userOrganisations')
        .then(function(data) {
          return _.filter(data.organisations, function(org) {
            if (!org.allowedProducts) {
              return false;
            }

            return org.allowedProducts.indexOf('kaizen') !== -1;
          });
        });
    };

    service.saveListOptionsFor = function(route, listOptions) {
      return service.getConf({ cached: false })
        .then(function(conf) {
          return conf;
        })
        .catch(function(err) {
          if (err.status === 404) {
            return {};
          }
        })
        .then(function(conf) {
          if (_.isEmpty(listOptions) || _.isEmpty(listOptions.sections)) {
            if (conf.listOptions && conf.listOptions[route]) {
              delete conf.listOptions[route];
            }
          } else {
            conf.listOptions = conf.listOptions || {};
            conf.listOptions[route] = listOptions;
          }

          return service.saveConf(conf);
        });
    };

    service.saveConf = function(conf) {
      if (conf._rev === undefined) {
        conf.type = 'orgConf';
        conf._id = Auth.currentOrganisation() + '_conf';
        conf.organisation = Auth.currentOrganisation();
      }
      return BaseConfStore.save('orgConf', conf);
    };

    // This is the crucial bit - how do we do that?
    service.findByCategoryId = function(category) {
      return BaseConfStore.query('category_by_id', { key: category })
        .then(function(res) {
          if (res.rows.length === 1) {
            return res.rows[0].value;
          }
          $log.warn('Could not find category', category);
        });
    };

    return service;
  }

  OrganisationsService.$inject = [
    '$q',
    '$log',
    'BaseConfStoreService',
    'AuthService',
    'RestApi',
    'ApiService',
    'ClusterService',
    'kzLocalStorage'
  ];

  angular.module('component.organisations')
    .factory('OrganisationsService', OrganisationsService);
})();
