(function() {
  'use strict';

  function RoleTitleDirective(Roles) {
    return {
      scope: {
        role: '@userRole'
      },
      restrict: 'EA',
      template: '<i class="icon-spin animate-spin" ng-if="!role"></i>{{ role.title }}',
      link: function(scope) {
        Roles.find(scope.role, { cached: true })
          .then(function(role) {
            scope.role = role;
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    };
  }

  RoleTitleDirective.$inject = ['RolesService'];

  function RolesTitleDirective() {
    return {
      scope: {
        roles: '='
      },
      restrict: 'EA',
      template: '<span data-ng-repeat="role in roles">' +
      '<span epf-role-title user-role="{{role}}"></span><span ng-if="!$last">, </span>' +
      '</span>'
    };
  }

  angular.module('component.roles')
    .directive('epfRoleTitle', RoleTitleDirective)
    .directive('epfRolesTitle', RolesTitleDirective);
})();
