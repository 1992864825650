import * as Sentry from '@sentry/browser';

(function() {
  'use strict';

  function config($provide) {
    $provide.decorator('$exceptionHandler', ['$delegate',
      function($delegate) {
        return function(exception, cause) {
          if (exception.message === 'cancelled' || exception.status === 401) {
            console.log(exception.message);
            return;
          }

          $delegate(exception, cause);

          if (exception &&
            exception.indexOf &&
            exception.indexOf('Possibly unhandled rejection') !== -1
          ) {
            return;
          }

          if (exception && exception.stack &&
              (
                exception.stack.includes('CustomPouchError') ||
                exception.stack.includes('$digest') ||
                exception.stack.includes('not_found: missing')
              )
          ) {
            // Ignore the rest if just possible error
            return;
          }

          if (exception && exception.name === 'not_found' && exception.message === 'missing') {
            return;
          }

          if (exception && exception.status && exception.status === 401) {
            return;
          }

          // $log.debug(exception.stack);
          if (window.kzConfig && window.kzConfig.sentry && window.kzConfig.sentry.enabled) {
            Sentry.captureException(exception);
          }

          // alert(exception.message); // jshint ignore:line
        };
      }
    ]);
  }

  config.$inject = ['$provide'];

  angular.module('blocks.utils')
    .config(config);
})();
