(function() {
  'use strict';

  function EmailLogSlotController($uibModal, $state, EmailLogs, Security, Notify) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.setup();
    };

    Security.hasPermission('emailTemplates.view')
      .then(function(allowed) {
        ctrl.canViewEmailSent = allowed;
      });

    ctrl.setup = function() {
      if (ctrl.emailLog.doc.related) {
        ctrl.related = {
          id: ctrl.emailLog.doc.related.id,
          type: ctrl.emailLog.doc.related.type
        };

        if (ctrl.related.type === 'event') {
          ctrl.related.href = $state.href('epf.users.events-view-any', { id: ctrl.related.id });
        }
      }
      ctrl.actionButtons = [
        {
          label: ctrl.shownDetails ? 'Hide details' : 'Show details',
          icon: 'icon-history',
          onClick: function() {
            ctrl.shownDetails = !ctrl.shownDetails;
            this.label = ctrl.shownDetails ? 'Hide details' : 'Show details';
          },
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          klass: 'text-info'
        }
      ];
      ctrl.secondaryActionButtons = [
        {
          label: 'Send again',
          icon: 'icon-undo',
          onClick: function() {
            ctrl.resend();
          },
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          klass: 'text-warning'
        }
      ];

      ctrl.preview = function() {
        $uibModal.open({
          animation: true,
          template: '<email-view data="ctrl.data" on-close="$close()"></email-view>',
          controller: ['data',
            function(data) {
              this.data = data;
            }
          ],
          controllerAs: 'ctrl',
          resolve: {
            data: function() {
              return {
                subject: ctrl.emailLog.doc.subject,
                html: ctrl.emailLog.doc.html,
                email_from: ctrl.emailLog.doc.email_from,
                recipients: ctrl.emailLog.doc.recipients,
                messageId: ctrl.emailLog.doc.messageId,
                state_log: ctrl.emailLog.doc.state_log,
                date: ctrl.emailLog.doc.createdDate
              };
            }
          },
          size: 'lg'
        }).result.catch(function(err) {
          console.log('Dismissed', err);
        });
      };

      ctrl.resend = function() {
        var result = $uibModal.open({
          animation: true,
          templateUrl: 'app/components/email-logs/email-log-resend.html',
          controller: ['emailLog', 'UsersStubService',
            function(emailLog, UsersStub) {
              var innerCtrl = this;
              this.emailLog = emailLog;
              this.data = {};
              this.allowRegenerate = !emailLog.doc.using_preferred_partials;

              if (this.emailLog.doc.recipient_username) {
                UsersStub.find(this.emailLog.doc.recipient_username)
                  .then(function(stub) {
                    innerCtrl.currentEmail = stub.email;
                    innerCtrl.originalEmail = ctrl.emailLog.doc.recipients.length === 1 ?
                      ctrl.emailLog.doc.recipients[0] : null;
                  })
                  .catch(function(err) {
                    console.log(err);
                    Notify.warning('Could not find profile');
                  });
              }
            }
          ],
          controllerAs: 'ctrl',
          resolve: {
            emailLog: ctrl.emailLog
          },
          size: 'md'
        }).result;

        return result
        .then(function(data) {
          if (!data) {
            Notify.error('No data received');
            return;
          }

          return EmailLogs.resend(ctrl.emailLog.doc.id, data)
            .then(function() {
              Notify.success('Email has been resent');
            })
            .catch(function(err) {
              var errorMsg;
              if (err && err.message) {
                errorMsg = err.message;
              } else {
                errorMsg = err;
              }
              Notify.error(errorMsg);
            });
        })
        .catch(function(err) {
          console.log('Dismissed', err);
        });
      };
    };
  }

  EmailLogSlotController.$inject = [
    '$uibModal',
    '$state',
    'EmailLogsService',
    'SecurityService',
    'NotifyService'
  ];

  angular.module('component.emailLogs')
    .component('emailLogSlot', {
      templateUrl: 'app/components/email-logs/email-log-slot.html',
      controller: EmailLogSlotController,
      bindings: {
        emailLog: '<',
        options: '<'
      }
    });
})();
