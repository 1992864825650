(function() {
  'use strict';


  function ReportController($q, $scope, Utils, Blueprints, EventTypes, Api, LocalizationService) {
    var ctrl = this;
    ctrl.event = $scope.event;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    var colors = [
      'rgba(180,20,180,0.5)',
      'rgba(180,180,180,0.5)',
      'rgba(151,187,205,0.5)'
    ];

    var preprocessFields = function(eventType) {
      var sectionPromises = _.map(eventType.sections, function(def) {
        return Blueprints.ensureUpdatedStore()
          .then(function() {
            var promises = _.map(def.fields, function(field) {
              if (field.type === 'blueprint') {
                return Blueprints.find(field.blueprint, { cached: true });
              }
              return $q.when(field);
            });
            return $q.all(promises);
          })
          .then(function(fields) {
            return {
              _id: def._id,
              fields: fields
            };
          });
      });
      return $q.all(sectionPromises)
        .then(function(result) {
          var sections = [];
          _.forEach(result, function(subres) {
            sections[subres._id] = subres.fields;
          });
          return sections;
        });
    };

    var expandFields = function(fields, def) {
      if (_.isArray(fields)) {
        return fields;
      } else if (fields === '__all_likert__') {
        fields = _.chain(ctrl.eventTypeFields[def._id])
          .filter(function(item) {
            return item.blueprintType === 'likert';
          })
          .map(function(item) {
            return item._id;
          })
          .value();
        return fields;
      }
    };

    var systemFieldValue = function(fieldName) {
      if (['createdDate', 'startDate', 'endDate', 'section closed'].indexOf(fieldName) !== -1) {
        return ctrl.event.getDateFor(fieldName);
      }

      return ctrl.event.doc[fieldName];
    };

    var updateSectionField = function(field) {
      var def = _.find(ctrl.event.eventType.sections, { _id: field.section });
      if (_.isUndefined(def)) {
        console.log('Section not found');
        return;
      }

      field.def = _.find(ctrl.eventTypeFields[field.section], { _id: field.field });
      if (field.def === undefined) {
        console.log('Missing field for', field.field);
        return;
      }
      field.value = ctrl.event.getFieldValue(field.field, field.section);
      field.title = field.title || field.def.name;
    };

    var getNumField = function(field, section, _def) {
      var fldDef = _.find(ctrl.eventTypeFields[section], { _id: field });

      if (_.isUndefined(fldDef)) {
        console.log('Field not found');
        return;
      }

      var values = [];
      var fldValues = ctrl.event.getFieldValue(field, section);
      var fieldValue;
      if (fldDef.blueprintType === 'likert') {
        fldValues.forEach(function(value) {
          fieldValue = _.find(fldDef.categories, { _id: value });
          if (_.isUndefined(fieldValue)) {
            console.log('Value not found');
            return;
          }

          values.push(fieldValue.value);
        });
      } else if (fldDef.blueprintType === 'numeric') {
        values = values.concat(fldValues);
      } else {
        console.log('Only numerical fields can be here');
        return;
      }

      return _.filter(values, function(item) {
        return item !== null && item !== undefined;
      });
    };

    var getResponseCount = function(section) {
      return ctrl.event.doc.sections[section].items.length;
    };

    var updateAggField = function(field) {
      var def = _.find(ctrl.event.eventType.sections, { _id: field.section });
      if (_.isUndefined(def)) {
        return;
      }

      var values = [];
      var fields = expandFields(field.fields, def);
      fields.forEach(function(fld) {
        values = values.concat(getNumField(fld, field.section, def));
      });

      switch (field.aggType) {
        case 'avg':
          field.value = Utils.avg(values);
          break;
        case 'sum':
          field.value = Utils.sum(values);
          break;
        case 'count':
          field.value = getResponseCount(field.section);
          break;
        case 'stddev':
          field.value = Utils.stddev(values);
          break;
        default:
          console.log('Unknown aggType', field.aggType);
      }

      field.extra = {
        count: getResponseCount(field.section)
      };
    };

    var updateCountDistinctDiscreteCategoriesField = function(field) {
      var def = _.find(ctrl.event.eventType.sections, { _id: field.section });
      if (_.isUndefined(def)) {
        return;
      }

      var values = {};
      var fields = expandFields(field.fields, def);
      fields.forEach(function(fld) {
        // get field def
        var fldDef = _.find(ctrl.eventTypeFields[def._id], { _id: fld });
        if (_.isUndefined(fldDef)) {
          console.log('Field not found');
          return;
        }

        // get all values from all items
        var fldCounts = {};
        var fldValues = ctrl.event.getFieldValue(fld, field.section);
        fldValues.forEach(function(value) {
          var fieldCat = _.find(fldDef.categories, { _id: value });
          if (_.isUndefined(fieldCat)) {
            console.log('Value not found');
            return;
          }

          if (_.isUndefined(fldCounts[fieldCat._id])) {
            fldCounts[fieldCat._id] = 0;
          }

          fldCounts[fieldCat._id] += 1;
        });

        // reorder them
        var valuesOrdered = [];
        _.forEach(fldDef.categories, function(cat) {
          valuesOrdered.push(
            {
              name: cat.name,
              count: fldCounts[cat._id] || 0
            }
          );
        });

        values[fldDef.name] = valuesOrdered;
      });

      field.value = values;
    };

    var findMinMax = function(fld) {
      var values = _.chain(fld.categories)
        .map(function(item) {
          return item.value;
        })
        .filter(function(item) {
          return item !== undefined || item !== null;
        })
        .value();

      return {
        min: _.min(values),
        max: _.max(values)
      };
    };

    var updateChartDataSet = function(set, idx) {
      var oneset = {
        label: set.title,
        backgroundColor: colors[idx],
        errorStrokeWidth: 1,
        errorColor: 'rgba(220,0,0,0.8)',
        type: set.chartType || 'bar',
        options: set.options || {},
        includeInKey: set.includeInKey
      };

      oneset = _.assignIn(oneset, set.options || {});

      if (set.remote) {
        return Api.post('run_report_field', set, { event_id: $scope.event.doc._id })
          .then(function(data) {
            // oneset = _.assignIn(oneset, data);
            console.log('Processing', set.title);
            oneset.legend = data.legend;
            oneset.data = data.data;
            oneset.error = data.error;

            // Commented out - uncomment to get the actual views to see in the log
            // for (var i = 0; i < oneset.data.length; i++) {
            //   console.log(
            //       'question',
            //       i + 1,
            //       data.counts[i],
            //       data.data[i],
            //       data.error[i],
            //       data.data[i] - data.error[i],
            //       data.data[i] + data.error[i]
            //     );
            // }
            return oneset;
          })
          .catch(function(error) {
            console.log(error);
            oneset.data = [];
            oneset.error = [];
            oneset.legend = [];
            return oneset;
          });
      }

      var onedata = [];
      var errors = [];
      var values;
      var def = _.find(ctrl.event.eventType.sections, { _id: set.section });
      if (_.isUndefined(def)) {
        return $q.when();
      }

      var fields = expandFields(set.fields, def);
      var defFld;
      var legend = [];
      var range;
      var totalRange = {};
      var avg;
      var error;

      console.log('Processing', set.title);
      fields.forEach(function(fld, idx2) {
        defFld = _.find(ctrl.eventTypeFields[def._id], { _id: fld });
        if (_.isUndefined(defFld)) {
          return;
        }

        legend[idx2] = defFld.name;
        range = findMinMax(defFld);
        totalRange.min = _.min([range.min, totalRange.min]);
        totalRange.max = _.max([range.max, totalRange.max]);

        values = getNumField(fld, set.section, def);

        avg = _.round(Utils.avg(values), set.precision || 2);
        error = Utils.t_interval(values);
        onedata.push(avg);
        errors.push(error);
        // console.log.apply(null, ['detail', idx2 + 1].concat(values));
        console.log('question', idx2 + 1, values.length, avg, error, avg - error, avg + error);
      });

      oneset.legend = legend;
      oneset.data = onedata;
      oneset.error = errors;
      oneset.range = totalRange;

      return $q.when(oneset);
    };

    var updateChartField = function(field) {
      var data = { datasets: [] };

      field.hasErrorBar = false;
      var count = 0;
      var promises = [];
      _.forOwn(field.sets, function(set, idx) {
        field.hasErrorBar = field.hasErrorBar || (set.chartType === 'barError');
        promises.push(updateChartDataSet(set, idx));
      });

      return $q.all(promises)
        .then(function(datasets) {
          data.datasets = datasets;

          count = Math.max.apply(this, _.map(data.datasets, function(dataset) {
            return dataset.data.length;
          }));

          data.labels = Array.apply(null, { length: count }).map(function(_val, idx) {
            return idx + 1;
          });

          field.data = data;
          field.legend = _.chain(data.datasets)
            .filter(function(set) {
              return set.includeInKey;
            })
            .map(function(set) {
              return set.legend;
            })
            .value();

          field.legend = _.zip.apply(_, field.legend);

          field.legend = _.map(field.legend, function(legends) {
            return legends.join(' / ');
          });

          field.options = {
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: field.yLabel || 'rating'
                },
                display: true,
                ticks: {
                  suggestedMin: _.min(_.map(datasets, function(set) {
                    return set.range !== undefined ? set.range.min : undefined;
                  })),
                  suggestedMax: _.max(_.map(datasets, function(set) {
                    return set.range !== undefined ? set.range.max : undefined;
                  }))
                }
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: field.xLabel || 'question'
                }
              }]
            },
            maintainAspectRatio: false,
            responsive: true,
            legendTemplate: '<ul class="tc-chart-js-legend">' +
            '<% for (var i=0; i<datasets.length; i++)' +
            '{%><li><span style="background-color:<%=datasets[i].fillColor%>">' +
            '</span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>'
          };

          return field;
        });
    };

    var typeMapping = {
      createdDate: 'dateTime',
      startDate: 'date',
      endDate: 'date',
      'section closed': 'date',
      user: 'user'
    };

    preprocessFields($scope.event.eventType)
      .then(function(fields) {
        ctrl.eventTypeFields = fields;
        return EventTypes.findReport($scope.report);
      })
      .then(function(report) {
        ctrl.report = report;

        var promises = _.map(report.fields, function(field) {
          if (field.remote) {
            return Api.post('run_report_field', field, { event_id: $scope.event.doc._id })
              .catch(function(error) {
                console.log(error);
                return angular.copy(field);
              });
          }
          field = angular.copy(field);
          switch (field.type) {
            case 'systemField':
              field.value = systemFieldValue(field.field);
              field.fieldType = typeMapping[field.field];
              break;

            case 'sectionField':
              updateSectionField(field);
              break;

            case 'aggField':
              updateAggField(field);
              break;

            case 'countDistinctDiscreteCategories':
              updateCountDistinctDiscreteCategoriesField(field);
              break;
            case 'chart':
              // This function returns a promise
              return updateChartField(field);

            case 'text':
              field.content = '<p>' + field.content + '</p>';
              break;
            default:
              console.log('Uknown field type in reports', field.type);
          }

          return $q.when(field);
        });
        return $q.all(promises);
      })
      .then(function(result) {
        ctrl.fields = result;
        ctrl.loaded = true;
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  ReportController.$inject = [
    '$q',
    '$scope',
    'UtilsService',
    'BlueprintsService',
    'EventTypesService',
    'ApiService',
    'LocalizationService'
  ];

  function EventReportDirective() {
    return {
      scope: {
        event: '=',
        report: '@'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-report.html',
      replace: true,
      controller: ReportController,
      controllerAs: 'ctrl'
    };
  }


  angular.module('events.directives')
    .directive('eventReport', EventReportDirective)
    .controller('eventReportController', ReportController);
})();
