(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.admin', {
        url: 'admin/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }]
        }
      })

      .state('epf.admin.pending-sections', {
        url: 'pending-sections',
        views: {
          content: {
            templateUrl: 'app/components/admin/pending-sections.html',
            controller: 'PendingSectionsController',
            controllerAs: 'pendingCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.admin')
    .config(config);
})();
