(function() {
  'use strict';

  function OfflineBanner(Auth) {
    return {
      scope: {
        test: '=',
        level: '=?'
      },
      restrict: 'EA',
      templateUrl: 'app/components/settings/offline.banner.html',
      link: function(scope) {
        scope.shown = false;

        function checkShown() {
          Auth.getLocalSettings()
            .then(function(res) {
              scope.shown = _.isEmpty(res);
            });
        }

        checkShown();
        scope.$on('KZLocalSettingsChanged', function() {
          checkShown();
        });

        function setMode(mode) {
          return Auth.setStorageMode(mode)
            .then(function() {
              scope.shown = false;
            });
        }

        scope.setTrusted = function() {
          return setMode('persistent');
        };

        scope.setShared = function() {
          return setMode('session');
        };
      }
    };
  }

  OfflineBanner.$inject = ['AuthService'];

  angular.module('component.settings')
    .directive('kzOfflineBanner', OfflineBanner);
})();
