(function() {
  'use strict';

  function RegisterController($state, $scope, $location, $window, Notify,
                              kzLocalStorage, Network, Form, Auth, Api, Utils) {
    var ctrl = this;

    Utils.setPageTitle('Register');

    ctrl.credentials = {};
    ctrl.loginNotifications = [];
    ctrl.registerNotifications = [];

    $scope.$watch(function() {
      return $location.search().token;
    }, function(token) {
      if (token === undefined) {
        return;
      }

      ctrl.quickRegistration = false;

      Api.get('verify_token', {}, { token: token })
        .then(function(data) {
          ctrl.register = {};
          ctrl.register.username = data.username;
          ctrl.register.email = data.email;
          ctrl.register.token = token;
          ctrl.organisation = data.organisation;

          var fields;
          if (data.organisation === 'org_rcpch') {
            fields = [
              {
                id: 'firstName',
                type: 'string',
                label: 'First Name',
                required: true
              },
              {
                id: 'lastName',
                type: 'string',
                label: 'Last Name',
                required: true
              },
              {
                id: 'headerNumber',
                type: 'text_text',
                label: 'If you are based overseas and have a registration number ' +
                        ' that is not covered by GMC/NMC please use the Allied Health ' +
                        'Professional (AHP) box',
                required: false },
              {
                id: 'gmcNumber',
                type: 'numeric',
                label: 'GMC Number',
                required: false },
              {
                id: 'nmcNumber',
                type: 'string',
                label: 'NMC Number',
                required: false },
              {
                id: 'ahpNumber',
                type: 'string',
                label: 'Allied Health Professional Number',
                required: false },
              {
                id: 'dob',
                type: 'date',
                label: 'Date of Birth',
                required: true
              },
              {
                id: 'email',
                type: 'email',
                label: 'Email',
                required: true
              }
            ];
          } else {
            fields = [
              {
                id: 'username',
                type: 'string',
                label: 'Username',
                required: true
              },
              {
                id: 'email',
                type: 'email',
                label: 'Email',
                required: true
              }
            ];
          }

          ctrl.registerForm = new Form(fields);
        })
        .catch(function(error) {
          ctrl.register = {};
          ctrl.registerNotifications.push({
            type: error.status,
            classes: 'text-danger',
            msg: error.data
          });
        });
    });

    ctrl.registerUser = function() {
      if (
        ctrl.organisation === 'org_rcpch' &&
        !(
          ctrl.register.gmcNumber ||
          ctrl.register.nmcNumber || ctrl.register.ahpNumber
        )
      ) {
        ctrl.registerNotifications = [];
        ctrl.registerNotifications.push({
          type: 500,
          classes: 'text-danger',
          msg: 'Please specify at least one of GMC, NMC or AHP Number.'
        });
        return;
      }

      ctrl.registerNotifications = [];
      ctrl.registering = true;
      Auth.register(ctrl.register)
        .then(function() {
          ctrl.registering = false;
          var cameFrom = $location.search().came_from || '/';
          $location.url(cameFrom);
        })
        .catch(function(error) {
          ctrl.registering = false;
          ctrl.registerNotifications.push({
            type: error.status,
            classes: 'text-danger',
            msg: error.message
          });
        });
    };

    ctrl.logins = [];

    if (Network.isOffline()) {
      $state.go('accounts.pin');
      return;
    }

    Network.checkConnectivity()
      .then(Auth.isLoggedIn)
      .then(function() {
        Notify.success('You are already logged in');
        var cameFrom = $location.search().came_from || '/';
        $location.url(cameFrom);
      });

    Api.get('logins')
      .then(function(data) {
        ctrl.logins = data.logins;
      });

    ctrl.loginExternal = function(login) {
      var cameFrom = $location.search().came_from || '/';
      kzLocalStorage.setItem('kz-cameFrom', { url: cameFrom });
      kzLocalStorage.setItem('kz-login', login);
      $window.location.href = login.loginPath;
    };

    ctrl.login = function() {
      // After submitting the form we want to clear all notifications
      ctrl.loginNotifications = [];

      // Make sure we remove kz-login if using the normal one
      kzLocalStorage.popItem('kz-login');

      Auth.login(ctrl.credentials.username, ctrl.credentials.password)
        .then(function() {
          var cameFrom = $location.search().came_from || '/';
          $location.url(cameFrom);
        }, function(error) {
          if (error.status === 401) {
            ctrl.loginNotifications.push({
              type: error.status,
              classes: 'text-danger',
              msg: error.message
            });
          } else {
            console.log('Unknown error:' + error.message);
          }
        });
    };
  }

  RegisterController.$inject = [
    '$state',
    '$scope',
    '$location',
    '$window',
    'NotifyService',
    'kzLocalStorage',
    'NetworkService',
    'FormsService',
    'AuthService',
    'ApiService',
    'UtilsService'
  ];

  angular.module('component.accounts')
    .controller('RegisterController', RegisterController);
})();
