(function() {
  'use strict';

  function VersionService(PACKAGE_VERSIONS) {
    var service = {};
    service.serverVersion = window.kzVersion || {};

    service.versions = function() {
      var serverFrontend = (this.serverVersion.versions || {}).kaizen_frontend;

      var upgradeNeeded;
      // If server frontend is defined and is in the form of d.d.d we compare
      // Otherwise we are on a branch version and comparison does not make sense
      if (serverFrontend !== undefined && /\d+\.\d+\.\d+/.test(serverFrontend)) {
        upgradeNeeded = serverFrontend !== PACKAGE_VERSIONS.frontend;
      } else {
        upgradeNeeded = false;
      }

      return {
        platform: this.serverVersion.name,
        serverFrontend: (this.serverVersion.versions || {}).kaizen_frontend,
        frontend: PACKAGE_VERSIONS.frontend,
        buildAt: PACKAGE_VERSIONS.buildAt,
        upgradeNeeded: upgradeNeeded
      };
    };

    service.upgradeNeeded = function() {
      const versions = this.versions();
      return versions.upgradeNeeded;
    };

    return service;
  }

  VersionService.$inject = [
    'PACKAGE_VERSIONS'
  ];

  angular.module('component.settings')
    .service('VersionService', VersionService);
})();
