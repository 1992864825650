
(function() {
  'use strict';

  function GoalsDefineFilterMeasurementsController($scope, Form) {
    var ctrl = this;

    function loadMeasurementForms() {
      if (_.isUndefined(ctrl.filterMeasurementForms)) {
        ctrl.filterMeasurementForms = {};
      }

      ctrl.filterMeasurementForms = _.map($scope.measurements, function() {
        return new Form([
          {
            id: 'aggr',
            type: 'discrete',
            label: 'Aggregate',
            required: true,
            options: [
              { _id: 'count', key: 'count', name: 'COUNT' },
              { _id: 'sum', key: 'sum', name: 'SUM' },
              { _id: 'avg', key: 'avg', name: 'AVG' },
              // { _id: 'min', key: 'min', name: 'MIN' },
              { _id: 'max', key: 'max', name: 'MAX' }
            ],
            getTitle: function(val) {
              return val ? val.toUpperCase() : val;
            }
          },
          {
            id: 'count',
            type: 'numeric',
            label: 'How many do you want to achieve? (0 for unlimited)',
            required: true
          }
        ]);
      });
    }

    loadMeasurementForms();
  }

  GoalsDefineFilterMeasurementsController.$inject = [
    '$scope',
    'FormsService'
  ];

  function GoalsDefinePeriodMeasurementsDirective() {
    return {
      scope: {
        measurements: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/goals.define.filter.measurements.html',
      replace: true,
      controller: GoalsDefineFilterMeasurementsController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('goalsDefineFilterMeasurements', GoalsDefinePeriodMeasurementsDirective)
    .controller('GoalsDefineFilterMeasurementsController', GoalsDefineFilterMeasurementsController);
})();
