(function() {
  'use strict';

  function DashboardTemplateSelectorDirective() {
    return {
      scope: {
        showAsTitle: '=',
        currentTemplate: '=',
        templates: '=',
        handleChangeSelected: '&'
      },
      restrict: 'AE',
      templateUrl: 'app/components/dashboard-templates/directives/dashboard-template-selector.html'
    };
  }

  angular.module('component.dashboardTemplates')
    .directive('dashboardTemplateSelector', DashboardTemplateSelectorDirective);
})();
