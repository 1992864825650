export const mimeMapping = {
  'application/json': 'JSON',
  'text/plain': 'Plain Text',
  'text/html': 'HTML',
  'application/xml': 'XML',
  'application/pdf': 'PDF',
  'image/jpeg': 'JPEG Image',
  'image/png': 'PNG Image',
  'image/gif': 'GIF Image',
  'audio/mpeg': 'MPEG Audio',
  'audio/wav': 'WAV Audio',
  'video/mp4': 'MPEG-4 Video',
  'video/mpeg': 'MPEG Video',
  'application/msword': 'MS Word Document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'MS Word OpenXML Document',
  'application/vnd.ms-excel':
      'Microsoft Excel Spreadsheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'MS Excel OpenXML Spreadsheet',
  'application/vnd.ms-powerpoint':
      'MS PowerPoint Presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'MS PowerPoint OpenXML Presentation'
};
