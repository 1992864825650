(function() {
  'use strict';

  function DeletableItems() {
    return {
      restrict: 'AE',
      scope: {
        model: '=',
        readOnly: '=',
        emptyMessage: '='
      },
      transclude: true,
      templateUrl: 'app/blocks/widgets/deletable-items/deletable-items.html',
      link: function(scope) {
        scope.removeItem = function(itemToRemove) {
          scope.model = _.reject(scope.model, function(item) {
            return item === itemToRemove;
          });
        };
      }
    };
  }

  DeletableItems.$inject = [];

  angular.module('blocks.widgets')
    .directive('kzDeletableItems', DeletableItems);
})();
