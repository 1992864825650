(function() {
  'use strict';

  function EventIcons() {
    return {
      scope: {
        event: '='
      },
      restrict: 'A',
      template:
        '<i data-ng-if="icons.length" data-ng-class="icon" data-ng-repeat="icon in icons"></i>',
      link: function(scope) {
        scope.icons = [];

        var setIcons = function() {
          if (scope.event.files &&
            scope.event.files.length &&
            scope.icons.indexOf('icon-attachemnt') === -1) {
            scope.icons.push('icon-attachment');
          }

          if (scope.event.categories &&
            scope.event.categories.length &&
            scope.icons.indexOf('icon-tags') === -1) {
            scope.icons.push('icon-tags');
          }

          // Check for tags and comments.
          if (scope.event._extra && scope.event._extra.length) {
            var hasComments = false,
                hasTags = false;

            _.forEach(scope.event._extra, function(extra) {
              if (extra.comment && extra.comment.length) {
                hasComments = true;
              }

              if ((extra.tags && extra.tags.length) ||
                  (extra.blueprints && extra.blueprints.length)) {
                hasTags = true;
              }
            });

            // Add the comments icon.
            if (hasComments && scope.icons.indexOf('icon-comment') === -1) {
              scope.icons.push('icon-comment');
            }

            // Add the tags icon.
            if (hasTags && scope.icons.indexOf('icon-tags') === -1) {
              scope.icons.push('icon-tags');
            }
          }

          if (scope.event.containsGoalInOneOfTheSection &&
              scope.event.containsGoalInOneOfTheSection()) {
            if (scope.icons.indexOf('icon-goal') === -1) {
              scope.icons.push('icon-goal');
            }
          }

          scope.icons = _.uniq(scope.icons);
        };

        if (scope.event.loaded) {
          setIcons();
        } else {
          scope.$watch('event.loaded', function() {
            setIcons();
          });
        }
      }
    };
  }

  EventIcons.$inject = [];

  angular.module('events.directives')
    .directive('kzEventIcons', EventIcons);
})();
