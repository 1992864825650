(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.faqs', {
        url: 'faqs',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('faqs.view');
          }]
        }
      })

      .state('epf.faqs.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/faqs/list.html',
            controller: 'FAQsController',
            controllerAs: 'faqsCtrl'
          }
        }
      })

      .state('epf.faqs.new', {
        url: '/new',
        views: {
          content: {
            templateUrl: 'app/components/faqs/form.html',
            controller: 'FAQController',
            controllerAs: 'faqCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('faqs.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.faqs.edit', {
        url: '/edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/faqs/form.html',
            controller: 'FAQController',
            controllerAs: 'faqCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('faqs.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.faqs')
    .config(config);
})();
