(function() {
  'use strict';

  function Card($stateParams, $log, Auth, Drafts) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/drafts/cards/drafts.list.card.html',
      link: function(scope) {
        // We need diroptions on the card
        if (_.isUndefined(scope.card.diroptions)) {
          $log.error('This card requires diroptions');
          return;
        }

        // We need the type prop on diroptions in order to get autosaves
        // just for this document type
        if (_.isUndefined(scope.card.diroptions.type)) {
          $log.error('This card has to specify \'type\' in diroptions');
          return;
        }

        // We need the matchUserProp prop on diroptions in order to get autosaves
        // just for the correct user
        if (_.isUndefined(scope.card.diroptions.matchUserProp)) {
          $log.error('This card has to specify \'matchUserProp\' in diroptions');
          return;
        }

        var user = $stateParams.user || Auth.currentUser();

        // Get the drafts for the provided document type
        var matchFunc = function(doc) {
          return (
            doc.isFor === scope.card.diroptions.type &&
            doc.data[scope.card.diroptions.matchUserProp] === user &&
            !doc.isConflict
          );
        };

        /* eslint-disable max-len */
        scope.messages = {
          default: 'You\'ve previously worked on these events and you can continue working on them from here.',
          empty: 'You have no drafts yet. Every time you create or edit a form, we will automatically save your changes so you can continue working on them. These as well as forms you manually save as draft will show up here.'
        };
        /* eslint-enable */

        Drafts.findAllBy(matchFunc)
          .then(function(drafts) {
            scope.drafts = drafts;
          });
      }
    };
  }

  Card.$inject = [
    '$stateParams',
    '$log',
    'AuthService',
    'DraftsService'
  ];

  angular.module('component.drafts')
    .directive('draftsListCard', Card);
})();
