(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.announcements', {
        url: 'announcements/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('announcements.view');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.announcements.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/announcements/list.html',
            controller: 'AnnouncementsController',
            controllerAs: 'announcementsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.announcements.reorder', {
        url: 'reorder',
        views: {
          content: {
            templateUrl: 'app/components/announcements/list-reorder.html',
            controller: 'AnnouncementsReorderController',
            controllerAs: 'announcementsReorderCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('announcements.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.announcements.new', {
        url: 'new',
        views: {
          content: {
            templateUrl: 'app/components/announcements/form.html',
            controller: 'AnnouncementController',
            controllerAs: 'announcementCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('announcements.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.announcements.edit', {
        url: 'edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/announcements/form.html',
            controller: 'AnnouncementController',
            controllerAs: 'announcementCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('announcements.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      // The Inbox views
      .state('epf.inbox', {
        url: 'inbox/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.inbox.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/announcements/inbox/list.html',
            controller: 'InboxController',
            controllerAs: 'inboxCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.inbox.view', {
        url: ':id',
        views: {
          content: {
            templateUrl: 'app/components/announcements/inbox/view.html',
            controller: 'InboxMessageController',
            controllerAs: 'inboxMessageCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.announcements')
    .config(config);
})();
