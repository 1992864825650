(function() {
  'use strict';

  function TargetSlotController($q, $scope, $uibModal, EventTypesStore, Utils, TARGETS_STATES) {
    var ctrl = this;
    ctrl.TARGETS_STATES = TARGETS_STATES;
    ctrl.eventTypeLinks = [];

    ctrl.shownLinkedEvents = false;
    ctrl.showActionButtons = false;
    ctrl.targetTitleOptions = { showDescription: true };

    var options = $scope.options || {};
    if (!options.hideButtons) {
      $scope.target.goal.checkPermission('canWork')
        .then(function() {
          // the link functionnality has been disabled if you are not the owner.
          if ($scope.target.goal.isMine()) {
            ctrl.showActionButtons = true;
          }
        })
        .then(function() {
          var eventGroups = $scope.target.getRelevantEventTypes();

          var prom = $q.when([]);
          if (_.indexOf(eventGroups, '__all__') === -1 && !_.isEmpty(eventGroups)) {
            // prom = EventTypes.getGroupsByIds(eventGroups)
            prom = EventTypesStore.fetch({
              versionGroupId: eventGroups,
              purpose: 'latestPublished',
              size: 10000
            }, { transformType: 'stub' })
              .then(function(data) {
                var goal = $scope.target.goal,
                    versionGroupId = goal.event.eventType.versionGroupId;
                var eventTypeLinks = _.map(data, function(item) {
                  var user = $scope.target.goal.doc.user;
                  var args = {
                    eventTypeId: item.id,
                    user: user,
                    defaults: {
                      linkedTargets: [
                        {
                          targetId: $scope.target._id,
                          goalId: $scope.target.goal.doc._id,
                          eventTypeVersionGroupId: versionGroupId
                        }
                      ]
                    }
                  };

                  if (!$scope.target.goal.isMine()) {
                    args.user = user;
                  }

                  return {
                    args: args,
                    name: item.name,
                    showCondition: $scope.target.goal.checkPermission.bind(
                      $scope.target.goal,
                      'canWorkAndCreateEvent',
                      { eventTypeId: item.id, user: user, eventOwner: user }
                    )
                  };
                });

                return Utils.asyncFilter(eventTypeLinks, function(link) {
                  return link.showCondition()
                    .then(function() {
                      return true;
                    })
                    .catch(function(err) {
                      console.log(err);
                      return false;
                    });
                });
              });
          }

          prom
            .then(function(eventTypeLinks) {
              if (_.isEmpty(eventTypeLinks)) {
                var goal = $scope.target.goal,
                    versionGroupId = goal.event.eventType.versionGroupId;
                var args = {
                  defaults: {
                    linkedTargets: [
                      {
                        targetId: $scope.target._id,
                        goalId: goal.doc._id,
                        eventTypeVersionGroupId: versionGroupId
                      }
                    ]
                  }
                };

                if (!goal.isMine()) {
                  args.user = goal.doc.user;
                }

                eventTypeLinks = [
                  {
                    args: args,
                    name: 'Create new',
                    isNew: true,
                    showCondition: goal.checkPermission.bind(goal, 'canWorkAndCreateEvent')
                  }
                ];
                return Utils.asyncFilter(eventTypeLinks, function(link) {
                  return link.showCondition()
                    .then(function() {
                      return true;
                    })
                    .catch(function(err) {
                      console.log(err);
                      return false;
                    });
                });
              }

              return eventTypeLinks;
            })
            .then(function(links) {
              ctrl.eventTypeLinks = links;
            });
        });
    }

    ctrl.showFilters = function() {
      $uibModal.open({
        animation: true,
        size: 'md',
        controller: ['target', '$uibModalInstance', function(target, $uibModalInstance) {
          var linkingFilters = target.getLinkingSearchModel();
          this.filtersByName = {
            Linking: linkingFilters
          };

          var progressFilters = target.getProgressSearchModel();
          this.isProgressDifferent = !angular.equals(linkingFilters, progressFilters);
          if (this.isProgressDifferent) {
            this.filtersByName.Progress = progressFilters;
          }

          this.dismiss = function() {
            $uibModalInstance.dismiss('cancel');
          };
        }],
        controllerAs: 'ctrl',
        templateUrl: 'app/components/goals/directives/target-filter-popup.html',
        resolve: {
          target: function() {
            return $scope.target;
          }
        }
      });
    };
  }

  TargetSlotController.$inject = [
    '$q',
    '$scope',
    '$uibModal',
    'EventTypesStore',
    'UtilsService',
    'TARGETS_STATES'
  ];

  function TargetSlotDirective() {
    return {
      scope: {
        target: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/target.slot.html',
      replace: true,
      controller: TargetSlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('targetSlot', TargetSlotDirective)
    .controller('TargetSlotController', TargetSlotController);
})();
