(function() {
  'use strict';

  function EventSectionViewController(
    resolvedEventSection,
    $scope,
    $rootScope,
    $stateParams,
    Utils,
    Auth,
    EVENT_STATES
  ) {
    var ctrl = this;

    ctrl.EVENT_STATES = EVENT_STATES;
    ctrl.scrollTo = $stateParams.scrollTo;
    ctrl.extraIsExpanded = $stateParams.scrollTo === 'tags' ||
                            $stateParams.scrollTo === 'documents';

    ctrl.username = $stateParams.user || Auth.currentUser();
    ctrl.auditLogShown = false;

    if (_.isUndefined(resolvedEventSection)) {
      throw { status: 500, message: 'There is no resolved event' };
    }
    ctrl.eventSection = resolvedEventSection;

    $rootScope.$broadcast('KZCurrentEventChange', { event: ctrl.eventSection });

    // We have to broadcast the formControll only when initialized and it's done only after
    // the form element (directive) is processed
    // $scope.$watch('eventCtrl.form', function(form) {
    //   if (form !== undefined) {
    //     $rootScope.$broadcast('KZCurrentFormChange', { form: form });
    //   }
    // });

    function loadEvent() {
      // console.log(Date(), 'Starting event loading');
      ctrl.eventSection.init()
        .then(function() {
          return ctrl.eventSection.loadOriginalEvent()
            .then(function() {
              ctrl.eventSection.originalEvent = ctrl.eventSection.loadAdditionalActions(
                ctrl.eventSection.originalEvent
              );
            });
        })
        .then(function() {
          ctrl.actionButtons = ctrl.eventSection.actionButtons;
          ctrl.actionButtons.push({
            label: 'Back',
            position: 1,
            back: true,
            type: 'link',
            icon: 'icon-left-open-big',
            color: 'primary'
          });
          // Commenting are disabled for merged section untill we decide
          // what to do with revalidation
          ctrl.commentAllowed = ctrl.eventSection.doc.state !== 'merged';
          var pageEventTitle = ctrl.eventSection.eventType
            ? ctrl.eventSection.eventType.name
            : 'draft';
          Utils.setPageTitle('View ' + pageEventTitle);
          ctrl.authorized = true;
          ctrl.loaded = true;
        })
        .catch(function(error) {
          if (error && error.status && error.status === 403) {
            Utils.unauthorized(error);
            return;
          }

          Utils.showError(error);
        });
    }

    // If action is edit and we don't have state params redirect back
    if (!$stateParams.id) {
      Utils.go('epf.events.index', {}, { tryBack: true });
    } else {
      loadEvent($stateParams.id);
    }

    $scope.$on('KZItemRemoved', function() {
      if (ctrl.username === ctrl.event.doc.user) {
        Utils.go('epf.events.index', {}, { tryBack: true });
      } else {
        return Utils.go('epf.users.events', { user: ctrl.username }, { tryBack: true });
      }
    });

    $scope.$on('EventReload', function(_evt, args) {
      if (ctrl.eventSection.doc !== undefined && args.id === ctrl.eventSection.doc._id) {
        ctrl.updateEvent();
      }
    });

    ctrl.updateEvent = function() {
      console.log('Reloading event');
      return ctrl.eventSection.reload()
        .then(function() {
          loadEvent();
        });
    };
  }

  EventSectionViewController.$inject = [
    'resolvedEventSection',
    '$scope',
    '$rootScope',
    '$stateParams',
    'UtilsService',
    'AuthService',
    'EVENT_STATES'
  ];

  angular.module('component.events')
    .controller('EventSectionViewController', EventSectionViewController);
})();
