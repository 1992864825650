(function() {
  'use strict';

  function EasApiVersionStore($q, $log, EasApi, EasApiStore, Cache, Auth, Utils, Network) {
    var service = function(endpoint, options) {
      EasApiStore.call(this, endpoint, options);
    };

    Utils.extends(service, EasApiStore);
    /**
     * Creates the structure for a version
     * @return {object}
     */
    service.prototype.getDefaultGroup = function() {
      var groupid = Utils.guid(),
          ddoc = _.assignIn(
            {},
            {
              _id: Utils.guid(),
              versionGroupId: groupid,
              version: 1,
              type: this.docType,
              state: 'draft',
              organisation: Auth.currentOrganisation()
            },
            this.getDefaultDoc()
          );

      return {
        doc: ddoc,
        linkedVersions: [ddoc],
        _id: groupid
      };
    };

    service.prototype.find = function(oid, options) {
      /* This is overriden so that we can do a temporary cache */
      var _this = this;
      var opts = _.assignIn({}, options || {}, { version: this.apiVersion });
      if (opts.cache === 'cached') {
        $log.warn('EasApiStore: Using obsolete cache option');
        opts.cached = true;
      }
      var ext = {
        key: this.rest_endpoint + '-find-' + oid,
        maxAge: 0
      };

      if (!opts.cached) {
        $log.warn('EasApiStore: Someone wants a non-cached version. Ignoring');
        opts.cached = true;
      }

      var func = function() {
        return _this.ensureStore()
          .then(function() {
            if (_this.store.all && _this.store.all[oid]) {
              $log.debug('EasApiStore: Returning single from store cache for ' +
                        _this.rest_endpoint);
              return $q.when(_this.store.all[oid]);
            }

            if (_this.store.temporaryCache && _this.store.temporaryCache[oid]) {
              $log.debug('EasApiStore: Returning single from temporary cache for ' +
                        _this.rest_endpoint);
              return $q.when(_this.store.temporaryCache[oid]);
            }
            ext.cached = true;

            $log.debug('EasApiStore: Going to fetch single data for ' + _this.rest_endpoint);
            var url = _this.specificUrl(oid);
            return EasApi.get(url, undefined, undefined, opts)
              .then(function(itm) {
                $log.debug('EasApiStore: Going to fetch single data for ' + _this.rest_endpoint);
                if (_this.store.temporaryCache === undefined) {
                  _this.store.temporaryCache = {};
                }
                _this.store.temporaryCache[itm._id] = itm;

                return itm;
              });
          });
      };

      return Cache.cachedPromise(func, ext);
    };

    service.prototype.findLatestPublishedCached = function() {
      var _this = this;
      return this.ensureStore()
        .then(function() {
          return _this.envelopAll();
        })
        .then(function(data) {
          return _.filter(data, function(item) {
            return item.doc.state === 'published';
          });
        });
    };

    service.prototype.storeItems = function(items, options) {
      var opts = options || {};
      var _this = this;
      return this.ensureStore()
        .then(function() {
          if (opts.reset || _this.store.all === undefined) {
            _this.store.all = {};
            // _this.store.grouped = {};
          }

          _.forEach(items, function(item) {
            _this.store.all[item._id] = item;
            // _this.store.grouped[item.versionGroupId] = item;
          });

          console.log('EasApiStore: Stored items for',
            _this.rest_endpoint,
            _.keys(_this.store.all).length
            // _.keys(_this.store.grouped).length
          );

          return _this.saveStore();
        });
    };

    service.prototype.fetch = function(id, transformType) {
      return this.fetchIds([id], { transformType: transformType })
        .then(function(data) {
          if (data.length === 0) {
            return $q.reject({ status: 404, message: 'Not found' });
          }

          return data[0];
        });
    };

    service.prototype.search = function(data, transformType) {
      var _this = this;
      return this.searchIds(data)
        .then(function(res) {
          var ids = _.map(res.hits, function(item) {
            return item.id;
          });
          return _this.fetchIds(ids, { transformType: transformType });
        });
    };

    service.prototype.fetchByGroup = function(groupId, transformType) {
      return this.search({
        versionGroupId: groupId,
        purpose: 'latestAvailable'
      }, transformType)
        .then(function(res) {
          if (res.length !== 1) {
            throw new Error('Could not fetch');
          }
          return res[0];
        });
    };


    service.prototype.findLatestPublished = function(transformType) {
      var _this = this;
      return this.searchIds({
        purpose: 'latestPublished',
        size: 3000
      })
      .then(function(res) {
        var ids = _.map(res.hits, function(item) {
          return item.id;
        });
        return _this.fetchIds(ids, { transformType: transformType });
      });
    };

    service.prototype.publish = function(id) {
      var opts = { version: this.apiVersion };
      var url = this.specificUrl(id) + '/publish';
      return EasApi.post(url, undefined, undefined, opts);
    };

    service.prototype.archive = function(id) {
      var opts = { version: this.apiVersion };
      var url = this.specificUrl(id) + '/archive';
      return EasApi.post(url, undefined, undefined, opts);
    };

    service.prototype.new_version = function(versionGroupId) {
      var opts = { version: this.apiVersion };
      var url = this.rest_endpoint + '/by_group_id/' + versionGroupId + '/new_version';
      return EasApi.post(url, undefined, undefined, opts);
    };

    /* Backward compatibility */
    service.prototype.getGroupById = function(groupId) {
      return this.fetchByGroup(groupId, 'full');
    };

    service.prototype.getGroupsByIds = function(groupIds) {
      var _this = this;
      if (Network.isOffline) {
        return this.ensureStore()
          .then(function() {
            var eventTypes = _.filter(_.values(_this.store.all), function(item) {
              return groupIds.indexOf(item.versionGroupId) !== -1;
            });
            return eventTypes;
          });
      }
      return this.search({
        versionGroupId: groupIds,
        purpose: 'latestAvailable'
      }, 'full');
    };

    service.prototype.getGroupsByItemIds = function(itemIds) {
      var _this = this;
      if (Network.isOffline()) {
        return this.ensureStore()
          .then(function() {
            var eventTypes = _.filter(_.values(_this.store.all), function(item) {
              return itemIds.indexOf(item._id) !== -1;
            });
            return eventTypes;
          });
      }
      return this.fetchIds(itemIds);
    };

    service.prototype.getGroupByItemId = function(itemId) {
      return this.fetch(itemId, 'full');
    };

    service.prototype.clearGroupById = function() {
      return $q.when();
    };

    service.prototype.updateVersionGroup = function() {
      return $q.when();
    };

    return service;
  }

  EasApiVersionStore.$inject = [
    '$q',
    '$log',
    'EasApiService',
    'EasApiStore',
    'CacheService',
    'AuthService',
    'UtilsService',
    'NetworkService'
  ];

  angular.module('blocks.easapi')
  .factory('EasApiVersionStore', EasApiVersionStore);
})();
