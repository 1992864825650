(function() {
  'use strict';

  function EventInfoDirective() {
    return {
      scope: {
        eventId: '@',
        event: '=?'
      },
      restrict: 'A',
      template: '<a href="" ' +
        'ui-sref="epf.users.events-view-any({id: eventId})">View event</a>',
      link: function(scope) {
        if (scope.event !== undefined) {
          scope.eventId = scope.event.doc._id;
        }
      }
    };
  }

  EventInfoDirective.$inject = [];

  angular.module('events.directives')
    .directive('kzEventInfo', EventInfoDirective);
})();
