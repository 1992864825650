(function() {
  'use strict';

  function LoggedOutController(Network, Utils) {
    var ctrl = this;
    ctrl.offline = Network.isOffline();
    Utils.setPageTitle('Logged out');
  }

  LoggedOutController.$inject = ['NetworkService', 'UtilsService'];

  angular.module('component.accounts')
    .controller('LoggedOutController', LoggedOutController);
})();
