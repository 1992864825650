(function() {
  'use strict';

  function filter($filter) {
    return function(items, search, selectedItems, itemValue) {
      if (items === undefined) {
        return null;
      }
      if (selectedItems === undefined) {
        return items;
      }

      itemValue = itemValue || '_id';

      var newItems = $filter('filter')(items, search) || [];

      angular.forEach(selectedItems, function(value) {
        if (value === undefined || value === null) {
          return;
        }
        if (value[itemValue] !== undefined) {
          return;
        }
        var found = _.find(newItems, function(newItem) {
          return newItem[itemValue] === value;
        });

        if (!found) {
          var oldFound = _.find(items, function(newItem) {
            return newItem[itemValue] === value;
          });
          if (oldFound !== undefined) {
            newItems.unshift(oldFound);
          }
        }
      }, this);

      return newItems.slice(0, 100);
    };
  }

  filter.$inject = ['$filter'];

  angular.module('blocks.utils')
    .filter('choicePreserveFilter', filter);
})();
