(function() {
  'use strict';

  function kzPouchDb() {
    var self = this;
    self.$get = [
      'pouchDB', '$log', '$q', '$rootScope', 'NetworkService', 'DB_ERRORS',
      function(pouchDB, $log, $q, $rootScope, Network, DB_ERRORS) {
        function intercept(fn, options) {
          options = options || {};
          return function() {
            if (options.force && Network.isNetworkOffline()) {
              return $q.reject(DB_ERRORS.notAvailableOffline);
            } else if (!options.force && Network.isOffline()) {
              return $q.reject(DB_ERRORS.notAvailableOffline);
            }

            var args = [].slice.call(arguments, 0);
            return fn.apply(this, args)
              .then(function(data) {
                $rootScope.needRelogin = false;
                return data;
              })
              .catch(function(error) {
                if (error && error.status === 401) {
                  $log.warn('User is logged out');
                  $rootScope.needRelogin = true;
                }

                if (error && error.status === 0) {
                  error.message = 'Cannot connect to server';
                }

                if (error && [0, 502, 503, 510].indexOf(error.status) !== -1) {
                  error.notreachable = true;
                  $rootScope.$broadcast('NetworkOffline');
                }

                return $q.reject(error);
              });
          };
        }

        function put(fn) {
          return function() {
            // $log.info('Put ' + this._db_name);
            return fn.apply(this, arguments);
          };
        }

        function get(fn) {
          return function() {
            if (arguments[0] === undefined) {
              $log.error(arguments);
            }

            return fn.apply(this, arguments);
          };
        }

        return function kzPouchDB(options) {
          var db = pouchDB(options);

          db.validatingPut = intercept(db.validatingPut);
          db.put = intercept(put(db.put));
          db.get = intercept(get(db.get));
          db.query = intercept(db.query);
          db.changes = intercept(db.changes, { force: true });

          return db;
        };
      }];
  }

  angular.module('blocks.db')
    .provider('kzPouchDB', kzPouchDb);
})();
