(function() {
  'use strict';

  function Controller($q, $state, $scope, $rootScope, Profile, EventUtils,
                      EventsStore, BlueprintUtils, Auth) {
    var ctrl = this;

    ctrl.username = Auth.currentUser();

    ctrl.prefs = Profile.getPreferences();
    ctrl.model = _.assignIn({
      selected: [],
      lastPath: {}
    }, ctrl.prefs.bulkTagging || {});
    ctrl.prefs.bulkTagging = ctrl.model;

    $scope.$watch(function() {
      return ctrl.model;
    }, function() {
      $rootScope.$broadcast('KZPreferencesChange');
    }, true);

    var taggableBlueprints;

    /* Get the tree nodes */
    /* Items is a list of Event objects */
    function aggregateBlueprints(items, options) {
      options = options || {};
      options.type = 'blueprintDefault';

      var blpPromise = BlueprintUtils.getTaggableBlueprints({ period: 'until now' });

      var aggPromise;
      if (options.aggregated) {
        aggPromise = $q.when(items);
      } else {
        aggPromise = EventUtils.aggregateEventsFromItems(items);
      }

      return $q.all([aggPromise, blpPromise])
        .then(function(results) {
          options.blueprints = results[1];
          return EventUtils.aggregateBlueprints(results[0], options);
        })
        .then(function(blueprints) {
          taggableBlueprints = blueprints;
        })
        .catch(function(error) {
          console.log(error);
        });
    }

    EventsStore.load()

      // Load blueprints
      .then(function(items) {
        return aggregateBlueprints(items);
      })

      // Setup tree
      .then(function() {
        var options = {
          selectable: true,
          searchable: true,
          icons: {
            arrowRight: 'icon-right-open-big',
            arrowDown: 'icon-down-open-big',
            empty: 'icon-minus'
          }
        };

        var tagsTree = taggableBlueprints.length > 1 ?
          taggableBlueprints : taggableBlueprints[0].categories;

        ctrl.bulkTags = {
          tree: tagsTree,
          selected: [],
          template: { id: '_id', nodes: 'categories', title: 'name' },
          options: options
        };
      });

    ctrl.actions = [
      {
        label: 'Select events to apply to',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.events.applytags'); })(),
        klass: 'btn-success'
      },
      {
        label: 'Exit bulk tagging',
        icon: 'icon-cancel',
        href: (function() { return $state.href('epf.events.index'); })(),
        klass: 'btn-warning'
      }
    ];
  }

  Controller.$inject = ['$q', '$state', '$scope', '$rootScope', 'ProfileService', 'EventUtils',
    'EventsStore', 'BlueprintUtils', 'AuthService'];

  angular.module('component.events')
    .controller('SelectTagsController', Controller);
})();
