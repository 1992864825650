(function() {
  'use strict';

  function UserEventsController(resolvedFilter, $scope, $stateParams, $state, $rootScope,
                                Profile, EventUtils, Utils, Security, UsersStub) {
    var ctrl = this;

    ctrl.username = $stateParams.user;
    if (_.isEmpty(ctrl.username)) {
      Utils.unauthorized(
        { message: 'You are not authorised to access this page. User is not defined.' }
      );
      Utils.setPageTitle('User events');
    } else {
      UsersStub.find(ctrl.username)
        .then(function(user) {
          var fullName = _.trim(
            (user.firstName || '') + ' ' + (user.lastName || '')
          );
          Utils.setPageTitle(fullName + '\'s events');
        })
        .catch(function() {
          Utils.setPageTitle('User events');
        });
    }

    ctrl.options = {
      // highlightField: 'text'
      highlightField: 'name',
      typesToUpdate: ['event', 'eventSection'],
      trackBy: 'id',
      borderClass: function(item) {
        return item.main.getBorderClass();
      }
    };
    ctrl.todoOptions = {
      trackBy: 'id',
      hideEndOfListText: true
    };
    ctrl.resolvedFilter = resolvedFilter;
    var listName = resolvedFilter ? 'userevents-' + resolvedFilter.id : 'userevents';

    function setupTodoList() {
      return EventUtils.getList('todosearch', ctrl.searchModel, {
        own: true,
        defaultFilter: {
          extendedState: ['draft'],
          eventOwner: ctrl.username
        },
        listOptions: {
          maxSize: 5
        }
      }).then(function(list) {
        ctrl.sectionList = list;
        return ctrl.sectionList.doLoadItems();
      });
    }

    setupTodoList()
      .then(EventUtils.preCache)
      .then(function() {
        var defaultFilter = _.assignIn(
          {},
          { user: ctrl.username }
        );

        ctrl.searchModel = Profile.getListPreferences(listName).current;
        ctrl.options.search = ctrl.searchModel;
        var searchCode = 'usersearch';
        return EventUtils.getList(
          searchCode,
          ctrl.searchModel,
          {
            own: false,
            defaultFilter: defaultFilter,
            resolvedFilter: ctrl.resolvedFilter
          }
        );
      })
      .then(function(list) {
        ctrl.list = list;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: ctrl.list.search });
        $rootScope.loading = false;
        ctrl.list.doLoadItems();
        ctrl.loaded = true;
      });

    $scope.$on('KZStoreInvalidated', function(_evt, args) {
      if (args.type === 'event' || args.type === 'eventSection') {
        setupTodoList();
      }
    });

    ctrl.actions = [
      {
        label: 'Create new',
        icon: 'icon-plus',
        href: (
          function() { return $state.href('epf.events.new', { user: ctrl.username }); }
        )(),
        showCondition: Security.hasPermissionFor.bind(this, 'events.create', ctrl.username),
        klass: 'btn-success'
      }
    ];
  }

  UserEventsController.$inject = [
    'resolvedFilter',
    '$scope',
    '$stateParams',
    '$state',
    '$rootScope',
    'ProfileService',
    'EventUtils',
    'UtilsService',
    'SecurityService',
    'UsersStubService'
  ];

  angular.module('component.users')
    .controller(
      'UserEventsController', UserEventsController);
})();
