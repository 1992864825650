(function() {
  'use strict';

  function AuditLogController(actionMapping) {
    this.actionMapping = actionMapping;
  }

  AuditLogController.$inject = [
    'ACTION_MAPPING'
  ];

  angular.module('blocks.widgets')
    .component('auditLogEntry', {
      templateUrl: 'app/blocks/widgets/auditlog/audit-log-entry.component.html',
      controller: AuditLogController,
      bindings: {
        log: '<'
      }
    });
})();
