import 'angular-elastic';

(function() {
  'use strict';

  angular.module('widgets.forms',
    [
      'monospaced.elastic'
    ]
  );
})();
