// jshint ignore:start
(function() {
  'use strict';

  /* eslint-disable */
  var helps = {
    "epf.events.index": "The Timeline is where all of your events are displayed.<br/><br/>Different categories of events can be accessed from the dropdown menu in the toolbar by clicking Timeline. You can then use the search box at the top to filter the list to find something specific.",
    "epf.users.events": "This is where all of the events for this user are displayed.<br/><br/>Different categories of events can be accessed from the dropdown menu in the blue toolbar by clicking Timeline. You can then use the search box at the top to filter the list to find something specific.",
    "epf.goals.index": "This page displays all of your goals grouped together into goal sets. Each set is titled with the name of the event which created it.",
    "epf.users.goals": "This page displays all of the shared goals for this user, grouped into goal sets. Clicking on the title of each will take you into the details of that goal where you can view the linked events.",
    "epf.goals.view": "This page shows all of the details for this goal.",
    "epf.goals.work": "This page shows all of the details for this goal.",
    "epf.users.goals-view": "This page shows all of the details for this goal.<br/><br/>Click the down arrow beneath each target to view the events which have been linked.",
    "epf.goals.link": "This is where you can select which events you would like to link to this target.<br/><br/>Once you have found the event(s) which you would like to link simply press the button Link this event.<br/><br/>To return to the previous screen click I'm Done Linking at the top of this page.",
    "epf.events.new": "Select a form from the list and you will be redirected to fill it in. Forms displayed here are grouped based on the created Timeline categories.",
    "epf.events.section-view": "You are viewing all of the details on the current event.<br/><br/>The status, description and tags are shown at the top followed by the completed section(s) beneath.<br/><br/>You can add a comment or tag at the bottom of this form.",
    "epf.events.view": "You are viewing all of the details on the current event.<br/><br/>The status, description and tags are shown at the top followed by the completed section(s) beneath.<br/><br/>You can add a comment or tag at the bottom of this form.",
    "epf.events.viewslash": "You are viewing all of the details on the current event.<br/><br/>The status, description and tags are shown at the top followed by the completed section(s) beneath.<br/><br/>You can add a comment or tag at the bottom of this form.",
    "epf.users.events-view": "You are viewing all of the details on the current event.<br/><br/>The status, description and tags are shown at the top followed by the completed section(s) beneath.<br/><br/>You can add a comment or tag at the bottom of this form.",
    "epf.event-types.index": "Event Types are the templates which you create that determine which events users can add to their timelines.<br/><br/>When an Event Type is published (green) it becomes available to be created by users.",
    "epf.event-types.edit": "This is where you can specify all of the basic information and configuration of this Event Type.<br/><br/>The field 'This event can be owned by' determines to which roles in the system these events can be added to the timelines of.<br/><br/>The sections define the workflow that this event moves through during creation. The first section determines which user of the system must start the process and then it moves on to the following section.",
    "epf.event-types.section": "This is where you can define which fields users will be asked to complete when filling in this event.<br/><br/>If you need users to be able to upload a file to this section, then a dedicated file blueprint or custom field must be included here.<br/><br/>When specifying who this section is filled in by you can choose one or many roles within the system. Please use Timeline Owner when you wish to define specifically that the owner of this event should be completing it. If you select any other role then any user in the system with that role can fill in this section.",
    "epf.users.index": "Shown here are all the users within the system which you have the permissions to view.<br/><br/>Please use the search box at the top of the page to find a specific person and then access their portfolio using the action buttons on the user card.",
    "epf.reportTemplates.index": "Shown here are all the reports for which you have the permissions to run.<br/><br/>New reports can be created by system administrators.",
    "epf.users.reports": "These are the reports which relate specifically to this user.To run your own reports please access the reports tab in the top toolbar.",
    "epf.reportTemplates.run": "If this report contains input fields that you can specify these will be shown at the top and you must then click Generate in order to see the results.",
    "epf.blueprints.index": "Blueprints are where you setup your hierarchy of tags that can be used throughout risr/advance. They are used when you wish to report on exactly the same data across multiple events and users.",
    "epf.relations.index": "Relations are how you connect various users of the system together. For example, you may wish to setup a hierarchy of locations so that only users in the same location can view each other's portfolio.<br/><br/>These relations are used when creating a hierarchy, if you wish to make one-to-one connections between users then please do this when defining a role.",
    "epf.roles.index": "Roles should be assigned to each user of the system. This is how you setup your definitions for which users should have which permissions.",
    "epf.roles.edit": "This is where you define which permissions this role should be assigned. This affects what they can access within risr/advance.<br/><br/>When a user is assigned this role they then take on these permissions. A user can be assigned multiple roles.",
    "epf.roles.new": "This is where you define which permissions this role should be assigned. This affects what they can access within risr/advance.<br/><br/>When a user is assigned this role they then take on these permissions. A user can be assigned multiple roles.",
    "epf.todos.index": "Shown here are all of the event sections which you have been invited to fill in for other users. Click Fill in on each to start the process.",
    "epf.allevents.index": "What you see here is a global timeline containing all events from all users that you have permissions to view.<br/><br/>The name of the event owner is displayed against each.",
    "epf.user-fields.index": "This is where you create and manage which fields should be shown on each user's profile. Each user can then be assigned permissions to edit their profile where they can complete these fields.",
    "epf.profile.view": "These are all the details on this user's profile card. If you have permissions to change them you will see a button to edit the profile.",
    "epf.profile.own": "These are all the details contained in your profile card. If you have permissions to change them you will see a button to edit your profile.",
    "epf.users.bulk": "Here is where you can perform an action in bulk against a group of users. They can be selected either by first filtering the users list or by pasting a list of usernames into the input box on this page.",
    "epf.events.manage-timelines": "Here you can create and manage timeline categories in order to group certain event types together. Individual event types can be assigned to each category which will then take effect for all users of the system.<br/><br/>All the categories in this list are then displayed within the Timeline dropdown from the top navigation bar.",
    "epf.announcements.index": "Announcements are a way to push out notices to users of the system and have them appear on their dashboard. They can be scheduled for publication on a certain date and limited to only certain roles and/or relations.<br/><br/>If you allow users to dismiss an announcement then they are able to move this to their archive. If not then it will remain on their dashboard until it expires.",
    "epf.announcements.new": "Announcements are a way to push out notices to users of the system and have them appear on their dashboard. They can be scheduled for publication on a certain date and limited to only certain roles and/or relations.<br/><br/>If you allow users to dismiss an announcement then they are able to move this to their archive. If not then it will remain on their dashboard until it expires.",
    "epf.announcements.edit": "Announcements are a way to push out notices to users of the system and have them appear on their dashboard. They can be scheduled for publication on a certain date and limited to only certain roles and/or relations.<br/><br/>If you allow users to dismiss an announcement then they are able to move this to their archive. If not then it will remain on their dashboard until it expires.",
    "epf.announcements.reorder": "Announcements can be reordered. The order you assign here will determine the order in which they appear in each user's individual Inbox on their Dashboard.",
    "epf.inbox.index": "The Inbox is where all your announcements are displayed. Viewing a message will mark it as read.",
    "epf.dashboard-templates.index": "Dashboard templates are used to feature different collections of widgets applicable to certain roles in risr/advance. You can set each template to be visible by default to one or many roles.<br/><br/>When a user logs into risr/advance, the dashboard that they see is whatever the admin has created for them to see by default based on the role that they have. Users with multiple roles can switch between these dashboards to see the widgets that are most relevant to them in the capacity they want to work in at that time.",
    "epf.dashboard-templates.new": "Dashboard templates are used to feature different collections of widgets applicable to certain roles in risr/advance. You can set each template to be visible by default to one or many roles.<br/><br/>When a user logs into risr/advance, the dashboard that they see is whatever the admin has created for them to see by default based on the role that they have. Users with multiple roles can switch between these dashboards to see the widgets that are most relevant to them in the capacity they want to work in at that time.",
    "epf.dashboard-templates.edit": "Dashboard templates are used to feature different collections of widgets applicable to certain roles in risr/advance. You can set each template to be visible by default to one or many roles.<br/><br/>When a user logs into risr/advance, the dashboard that they see is whatever the admin has created for them to see by default based on the role that they have. Users with multiple roles can switch between these dashboards to see the widgets that are most relevant to them in the capacity they want to work in at that time.",
    "epf.profile-templates.index": "Summary templates are used to feature different collections of widgets applicable to certain roles in risr/advance. You can set each template to be visible by default to one or many roles.<br/><br/>When a user views another user in risr/advance, the summary that they see is dependent on the role(s) of the the person being viewed.",
    "epf.profile-templates.new": "Summary templates are used to feature different collections of widgets applicable to certain roles in risr/advance. You can set each template to be visible by default to one or many roles.<br/><br/>When a user views another user in risr/advance, the summary that they see is dependent on the role(s) of the the person being viewed.",
    "epf.profile-templates.edit": "Summary templates are used to feature different collections of widgets applicable to certain roles in risr/advance. You can set each template to be visible by default to one or many roles.<br/><br/>When a user views another user in risr/advance, the summary that they see is dependent on the role(s) of the the person being viewed.",
    "epf.reportTemplates.new": "This page allows you to create a report template which other users will be able to run in one click from their reports area. Select the filters you want the system to search for, then define how the data should be output, and finally choose the ordering of the output table.",
    "epf.reportTemplates.edit": "This page allows you to create a report template which other users will be able to run in one click from their reports area. Select the filters you want the system to search for, then define how the data should be output, and finally choose the ordering of the output table.",
    "epf.users.new": "This page allows you to create a new user into risr/advance. Enter all the information pertaining to the user and select which roles this user will have. Roles determine what the user can do within risr/advance.",
    "epf.users.edit": "This page allows you to create a new user into risr/advance. Enter all the information pertaining to the user and select which roles this user will have. Roles determine what the user can do within risr/advance.",
    "epf.users.review": "This page allows you to create a new user into risr/advance. Enter all the information pertaining to the user and select which roles this user will have. Roles determine what the user can do within risr/advance.",
    "epf.user-fields.new": "A user field is a custom field that shows up on each user's profile. Depending on the permissions you set in the field properties, these fields can either be locked or editable by each user from their profile.",
    "epf.user-fields.edit": "A user field is a custom field that shows up on each user's profile. Depending on the permissions you set in the field properties, these fields can either be locked or editable by each user from their profile.",
    "epf.relations.new": "This page allows you to create relations that can then be assigned to users. Relations are a good way to connect various users in risr/advance in the form of a hierarchy.",
    "epf.relations.edit": "This page allows you to create relations that can then be assigned to users. Relations are a good way to connect various users in risr/advance in the form of a hierarchy.",
    "epf.organisations.index": "Organisations provide a clear separation of data in risr/advance. At the database level, each organisation has its own users, roles, relations, etc. While a user can be part of multiple organisations, they can only see and interact with one organisation (and its users) at a time.",
    "epf.organisations.new": "Organisations provide a clear separation of data in risr/advance. At the database level, each organisation has its own users, roles, relations, etc. While a user can be part of multiple organisations, they can only see and interact with one organisation (and its users) at a time.",
    "epf.organisations.settings": "Organisations provide a clear separation of data in risr/advance. At the database level, each organisation has its own users, roles, relations, etc. While a user can be part of multiple organisations, they can only see and interact with one organisation (and its users) at a time.",
    "epf.faqs.index": "Frequently Asked Questions aim to provide the users with simple answers to the most common questions that they may have, regarding risr/advance or your organisation.",
    "epf.faqs.new": "Each FAQ needs to contain the question and the answer. You can use text formatting when providing the answer.",
    "epf.faqs.edit": "Each FAQ needs to contain the question and the answer. You can use text formatting when providing the answer.",
    "epf.event-types.new": "An event type is the template for a form that your users will fill in. Each form has its own properties and is comprised of at least one section. You can create as many sections as you wish and you can even drag and drop to re-arrange them. The order of the sections is important as it defines the workflow for this form.",
    "epf.event-types.field": "An event type is the template for a form that your users will fill in. Each form has its own properties and is comprised of at least one section. You can create as many sections as you wish and you can even drag and drop to re-arrange them. The order of the sections is important as it defines the workflow for this form.",
    "epf.event-types.custom-field": "An event type is the template for a form that your users will fill in. Each form has its own properties and is comprised of at least one section. You can create as many sections as you wish and you can even drag and drop to re-arrange them. The order of the sections is important as it defines the workflow for this form.",
    "epf.blueprints.new": "Each blueprint must have a name and a type. The most common blueprint type is a hierarchical tree, which lets you setup your hierarchy of tags that can be used throughout risr/advance.",
    "epf.blueprints.edit": "Each blueprint must have a name and a type. The most common blueprint type is a hierarchical tree, which lets you setup your hierarchy of tags that can be used throughout risr/advance.",
    "epf.events.section-fillin": "You are now filling in a section of an event. All the fields you are being asked to complete are displayed on this page.<br/><br/>If you wish to return here and continue later then you can save as a draft and pick it up from your activity list. When you are finished click the submit button.<br/><br/>If you believe that you need to upload a file as part of this section and there is no file field available, please contact your course administrators.",
    "epf.events.section-new": "You are now filling in a section of an event. All the fields you are being asked to complete are displayed on this page.<br/><br/>If you wish to return here and continue later then you can save as a draft and pick it up from your activity list. When you are finished click the submit button.<br/><br/>If you believe that you need to upload a file as part of this section and there is no file field available, please contact your course administrators.",
    "epf.importer.index": "You can create bulk actions here, You can upload either Users, Roles" +
      " or  different Event types. Select <span class='font-bold'>Create new bulk action</span>" +
      " to begin the process. <br/><br/> We provide templates to help you create the data in the " +
      "right format and will also check the data for you before it gets uploaded to the " +
      "application.",
    "epf.files.index": "This is where files previously uploaded or attached to events are able to be searched and retrieved. If you had previously used folder structures when this area was called ‘Documents’, then you can easily search for the names of any of those folders to locate the files contained therein. Whilst the folders no longer exist, folder names have been incorporated into file names.",
    "epf.users.files": "This is where files previously uploaded or attached to events are able to be searched and retrieved. If you had previously used folder structures when this area was called ‘Documents’, then you can easily search for the names of any of those folders to locate the files contained therein. Whilst the folders no longer exist, folder names have been incorporated into file names."
  };
  /* eslint-enable */

  angular.module('epf.core')
  .constant('HELPTEXTS', helps);
})();
