(function() {
  'use strict';

  function ErrorController($location, Auth, Setup) {
    var ctrl = this;

    ctrl.show = true;
    ctrl.user = Auth.currentUser();

    var currUrl = $location.url();
    var search = { came_from: currUrl };

    ctrl.logout = function() {
      Setup.logout({ query: search });
    };
  }

  ErrorController.$inject = [
    '$location',
    'AuthService',
    'SetupService'
  ];

  angular.module('epf.core')
    .controller('ErrorDifferentUserController', ErrorController);
})();
