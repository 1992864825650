(function() {
  'use strict';

  var requestResetPasswordApi = 'accounts/pw_reset_request';
  angular.module('component.accounts')
    .constant('REQUEST_RESET_PASSWORD', requestResetPasswordApi);

  var resetPasswordApi = 'accounts/pw_reset';
  angular.module('component.accounts')
    .constant('RESET_PASSWORD', resetPasswordApi);
})();
