(function() {
  'use strict';

  function NetworkController($scope, StatusService) {
    var ctrl = this;

    ctrl.setModeOffline = function() {
      StatusService.setModeOffline();
    };

    ctrl.setModeOnline = function() {
      StatusService.setModeOnline();
    };

    ctrl.relogin = function() {
      StatusService.relogin();
    };

    ctrl.info = StatusService.getInfo();
    $scope.$on('KZStatusInfoUpdated', function(_evt, info) {
      _.assignIn(ctrl.info, info);
    });
  }

  NetworkController.$inject = [
    '$scope',
    'StatusService'
  ];

  angular.module('blocks.network')
    .controller('NetworkController', NetworkController);
})();
