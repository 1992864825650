(function() {
  'use strict';

  function Slot(Auth, Goal, GoalsStore, Profile, GOALS_STATES) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/goals/cards/goalset.card.html',
      link: function($scope) {
        var prefs = Profile.getPreferences();
        $scope.goalDoc = prefs.goalDoc;
        $scope.GOALS_STATES = GOALS_STATES;

        function loadGoalSet(goalDoc) {
          $scope.options = { current: goalDoc._id, hideTargetInfo: true, trackBy: 'doc._id' };
          if ($scope.eventId !== goalDoc.eventId) {
            $scope.list = undefined;
          }

          $scope.eventId = goalDoc.eventId;
          $scope.sectionId = goalDoc.eventSectionId;
          $scope.fieldId = goalDoc.eventFieldId;
          if (goalDoc.user !== Auth.currentUser()) {
            $scope.user = goalDoc.user;
          }

          // var dbOptions = { eventId: $scope.eventId, sectionId: $scope.sectionId };
          // if (Auth.currentUser() !== $scope.user) {
          //   dbOptions.username = $scope.user;
          // }
          // var listOpts = { findOptions: { limit: 5 } };
          // Goal.findAll(dbOptions)
            // .then(function(goals) {
            //   return Goal.getSetPaginatedList(goals, listOpts);
            // })
          GoalsStore.fetchLight(
            {
              eventId: $scope.eventId,
              eventSectionId: $scope.sectionId,
              user: $scope.user,
              sort_on: 'eventAndDueDate'
            },
            {
              own: !$scope.user
            }
          )
            .then(function(goals) {
              _.forEach(goals, function(goal) {
                goal.getViewLink().then(function(href) {
                  goal.href = href;
                });
              });
              return GoalsStore.getSimpleList(goals);
            })
            .then(function(result) {
              $scope.list = result.list;
              $scope.goToPage = result.goToPageFunc;
              $scope.actions = Goal.getActions(
                $scope.eventId,
                $scope.sectionId,
                $scope.fieldId,
                $scope.user
              );
            });
        }

        $scope.$watch(function() {
          return prefs.goalDoc;
        }, function(goalDoc) {
          if (!_.isUndefined(goalDoc)) {
            loadGoalSet(goalDoc);
          }
        });
      }
    };
  }

  Slot.$inject = ['AuthService', 'GoalFactory', 'GoalsStore', 'ProfileService', 'GOALS_STATES'];

  angular.module('component.goals')
    .directive('goalsetCard', Slot);
})();
