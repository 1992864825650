(function() {
  'use strict';

  function TargetFiltersDescriptionDirective(
    $q,
    $state,
    Blueprints,
    Security,
    REPORT_CONDITIONS,
    REPORT_FILTERS
  ) {
    return {
      scope: { targetFilters: '=' },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/target.filters.description.html',
      link: function(scope) {
        scope.humanReadableConditions = REPORT_CONDITIONS;

        function prepareFilters(filters, perms) {
          var result = [];
          _.forOwn(filters, function(flts, key) {
            var dt = REPORT_FILTERS.events[key];
            var label = dt === undefined ? key : dt.label;
            if (dt.id === 'blueprint') {
              var proms = _.map(flts, function(flts2) {
                var proms2 = _.map(flts2, function(flt) {
                  return Blueprints.groupCategories(flt.value);
                });
                return $q.all(proms2);
              });
              $q.all(proms)
                .then(function(res) {
                  _.map(res, function(res2) {
                    _.map(res2, function(res3) {
                      var subGroupOfItems = [];
                      _.forOwn(res3, function(value, key) {
                        var humanReadableKey = 'Blueprint ' + key;
                        subGroupOfItems.push({
                          key: humanReadableKey,
                          values: [_.map(value, function(item) {
                            var sref = 'epf.blueprints.edit',
                                srefOpts = { id: item.rootId };

                            return {
                              value: item.value,
                              condition: item.key === item.rootId ? '' : '=',
                              href: perms.canViewBlueprints ? $state.href(sref, srefOpts) : '',
                              showHref: false
                            };
                          })]
                        });
                      });
                      result.push(subGroupOfItems);
                    });
                  });
                });
            } else {
              result.push([{
                key: label,
                values: _.map(flts, function(flts2) {
                  return _.map(flts2, function(flt) {
                    flt = angular.copy(flt);
                    var dt = REPORT_FILTERS.events[flt.dataType];
                    if (dt === undefined) {
                      flt.kzType = flt.dataType;
                      flt.label = flt.condition;
                    } else if (dt.id === 'blueprint') {
                      flt.kzType = dt.formType || dt.type;
                      flt.label = dt.label;
                    } else {
                      flt.kzType = dt.formType || dt.type;
                      flt.label = dt.label;
                      if (
                        flt.kzType === 'eventType_versionGroupId' &&
                        perms.canViewEventTypes
                      ) {
                        flt.showHref = true;
                      }
                    }

                    if (flt.condition === 'relation') {
                      flt.kzType = 'relation';
                      flt.showHref = perms.canViewRelations;
                    }

                    if (_.indexOf(dt.extraConditions, flt.condition) > -1) {
                      flt.kzType = flt.condition;
                      flt.value = flt[flt.condition + 'Value'];
                    }

                    return flt;
                  });
                })
              }]);
            }
          });

          return result;
        }

        var proms = [];
        _.forEach(['blueprints.edit', 'eventTypes.edit', 'relations.edit'], function(perm) {
          proms.push(Security.hasPermission(perm).catch(function() { return false; }));
        });

        $q.all(proms)
          .then(function(result) {
            var permissions = {
              canViewBlueprints: result[0],
              canViewEventTypes: result[1],
              canViewRelations: result[2]
            };

            scope.filters = prepareFilters(scope.targetFilters, permissions);
          });
      }
    };
  }

  TargetFiltersDescriptionDirective.$inject = [
    '$q',
    '$state',
    'BlueprintsService',
    'SecurityService',
    'REPORT_CONDITIONS',
    'REPORT_FILTERS'
  ];

  angular.module('component.goals')
    .directive('targetFiltersDescription', TargetFiltersDescriptionDirective);
})();
