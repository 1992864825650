(function() {
  'use strict';

  function SQLReportFactory(
    $q,
    Api,
    ReportFields,
    Form,
    Auth,
    Utils,
    capitalizeFilter,
    REPORT_OUTPUT_FIELDS
  ) {
    var SQLReport = function(data, options) {
      this.type = 'sqlReport';
      this.reportId = data.reportId;
      this.elementId = data.elementId;
      this.conf = data.conf;
      this.sourceGlobalFilters = data.sourceGlobalFilters || {};
      this.options = options || {};
    };


    SQLReport.prototype.validate = function() {
      return $q.when([]);
    };

    SQLReport.prototype.getDisplay = function() {
      var display = this.conf.display;
      if (display === undefined) {
        display = _.map(this.conf.output, function(item) {
          return {
            type: 'table',
            output: item
          };
        });
      }
      return display;
    };

    /**
     * Run the report
     * @param  {object} data The model from the form above
     * @return {Promise}      An object containing the result
     */
    SQLReport.prototype.run = function(reportModel, user, options) {
      options = options || {};

      // Replace current user with user if possible
      reportModel = angular.copy(reportModel);
      // this._updateModelWithUser(reportModel, user);
      var data = {
        model: reportModel,
        currentUser: user,
        options: options
      };
      var needPreview = options.needPreview;

      if (!_.isUndefined(needPreview)) {
        data.preview = {
          conf: this.conf,
          sourceGlobalFilters: this.sourceGlobalFilters
        };
      } else {
        data.elementId = this.elementId;
      }

      // todo: preview should not always be sent!
      // if multiReport always send elementId
      return Api.post(
        'reports_run',
        data,
        { report_id: this.reportId }
      );
    };


    SQLReport.prototype.getSingleReportBasicFormFields = function(options) {
      var fields = [
        { id: 'description', type: 'text', label: 'Description', required: false },
        {
          id: 'hideIfEmpty',
          type: 'boolean',
          label: 'Hide report if no results are found',
          required: false
        }
      ];

      if (options.disableAll) {
        return Form.disableAll(fields);
      }
      return fields;
    };

    /** OLD CODE **/
    /**
     * Return the form object to fill before the report is run
     * @return {Promise} Formly object to show
     */
    SQLReport.prototype.getForm = function(options) {
      options = options || {};
      return this.getFields(options)
        .then(function(fields) {
          return new Form(fields);
        });
    };

    SQLReport.prototype.getFields = function() {
      function _getFields(fltrs) {
        return _.map(fltrs, function(fltr) {
          return ReportFields.buildField('events', fltr);
        });
      }

      var fields = _getFields(this.conf.filterForm);
      return $q.when(fields);
    };

    SQLReport.prototype.getDefaultModel = function() {
      return this.getFields()
        .then(function(fields) {
          var model = {};
          fields.forEach(function(fld) {
            model[fld.id] = fld.default;
          });
          return model;
        });
    };

    SQLReport.prototype.clearModel = function(model, options) {
      if (model === undefined) {
        return $q.when();
      }

      return this.getFields(options)
        .then(function(fields) {
          var _model = {};
          fields.forEach(function(fld) {
            _model[fld.id] = model[fld.id];
          });
        });
    };

    SQLReport.prototype.validateModel = function(model) {
      return this.getFields()
        .then(function(fields) {
          var valid = true;
          fields.forEach(function(item) {
            if (model[item.id] === undefined || Utils.isBlank(model[item.id])) {
              valid = false;
            }
          });

          if (!valid) {
            return $q.reject({ status: 500, message: 'Model invalid' });
          }
        });
    };

    SQLReport.prototype.modelWithUser = function(model, user) {
      model = angular.copy(model);
      _.forOwn(model, function(value, key) {
        if (Array.isArray(value)) {
          model[key] = _.map(value, function(item) {
            return item === '__current__' ? user : item;
          });
        } else {
          model[key] = value === '__current__' ? user : value;
        }
      });
      return model;
    };

    /**
     * Get field ID by field type
     * @param {[String]} field Field type - e.g. user, eventType etc
     */
    SQLReport.prototype.getFieldIds = function(field) {
      return _.chain(this.conf.filter)
        .filter(function(flt) {
          return flt.field === field;
        })
        .map(function(flt) {
          return flt.id;
        })
        .value();
    };

    SQLReport.prototype._updateModelWithUser = function(data, user) {
      user = user || Auth.currentUser();

      var applyUser = function(subdata) {
        if (subdata.field === 'user' || subdata.field === 'dependent') {
          var values;
          if (subdata.variable) {
            values = data[subdata.id] || subdata.value || [];
          } else {
            values = subdata.value || [];
          }

          if (values.forEach !== undefined) {
            values.forEach(function(item) {
              if (item === '__current__') {
                data[subdata.id] = [user];
              }
            });
          }
        }

        (subdata.filters || []).forEach(function(item) {
          applyUser(item);
        });
      };

      applyUser(this.conf.filter);
      applyUser(this.sourceGlobalFilters);
    };

    SQLReport.prototype.getOutputFields = function() {
      var fields = angular.copy(this.conf.outputFields);
      var _this = this;
      return _.map(fields, function(item) {
        item.def = REPORT_OUTPUT_FIELDS[_this.conf.source][item.field];
        return item;
      });
    };

    SQLReport.prototype.getAggOutputFields = function() {
      var _this = this;
      var fields = [];
      var groupByExists = false;
      _.forEach(this.conf.outputFields, function(fld) {
        var field = angular.copy(fld);
        if (_.indexOf(['groupby', 'agg'], field.agg) === -1) {
          return;
        }

        if (field.agg === 'groupby') {
          if (groupByExists) {
            return;
          }

          groupByExists = true;
        }
        field.def = REPORT_OUTPUT_FIELDS[_this.conf.source][field.field];

        fields.push(field);
      });

      return fields;
    };

    SQLReport.prototype.getSortFormFields = function(disableAll) {
      var fields = ReportFields.buildSortFields(this.conf.source, false);

      fields = fields.concat([
        {
          id: 'sortOrder',
          type: 'discrete',
          label: 'Select sort',
          required: true,
          options: [
            { _id: 'asc', key: 'asc', name: 'Ascending' },
            { _id: 'desc', key: 'desc', name: 'Descending' },
            { _id: 'size', key: 'size', name: 'Size' }
          ]
        }
      ]);

      if (disableAll) {
        return Form.disableAll(fields);
      }

      return fields;
    };

    SQLReport.prototype.getFilterFormFields = function(leadField, disableAll) {
      return ReportFields.getFilterFormFields(
        leadField,
        this.conf.source,
        {
          showVariableField: true,
          showLabelField: true,
          dataTypeToExclude: ['eventType_versionGroupId'],
          disableAll: disableAll
        }
      );
    };

    SQLReport.prototype.getOutputFormFields = function(leadField, disableAll, preCalData) {
      var _this = this;
      var fields = [
        { id: 'header', type: 'string', label: 'Column header', required: true }
      ];

      fields = fields.concat(ReportFields.buildSelectFields(_this.conf.source, true));

      var defFil = REPORT_OUTPUT_FIELDS[_this.conf.source][leadField];
      if (defFil && defFil.extraOutField) {
        fields.push(ReportFields.buildExtraOutField(_this.conf.source, defFil));
      }

      if (defFil && defFil.extraOutFields) {
        fields = fields.concat(ReportFields.buildExtraOutFields(_this.conf.source, defFil));
      }

      fields = fields.concat([
        {
          id: 'agg',
          type: 'discrete',
          label: 'Aggregation',
          required: true,
          options: [
            { _id: 'none', key: 'none', name: 'No aggregation' },
            { _id: 'groupby', key: 'groupby', name: 'Group by this field' },
            { _id: 'agg', key: 'agg', name: 'Metric' }
          ],
          expressionProperties: {
            hide: function(_$viewValue, _$modelValue, scope) {
              return _.indexOf(['preview', 'goalOrder', 'credentials'], scope.model.field) > -1;
            }
          }
        },
        {
          id: 'aggType',
          type: 'discrete_multiple',
          label: 'Aggregation type',
          required: false,
          options: [
            { _id: 'count', key: 'count', name: 'Count' },
            { _id: 'bool', key: 'bool', name: 'Yes/No' },
            { _id: 'max', key: 'max', name: 'Max' },
            { _id: 'min', key: 'min', name: 'Min' },
            { _id: 'sum', key: 'sum', name: 'Sum' },
            { _id: 'avg', key: 'avg', name: 'Avg' }
          ],
          hideExpression: function(_$viewValue, _$modelValue, scope) {
            return _.isUndefined(scope.model.agg) || scope.model.agg === 'none';
          }
        },
        {
          id: 'linkText',
          type: 'string',
          label: 'Link Text',
          required: true,
          expressionProperties: {
            hide: function(_$viewValue, _$modelValue, scope) {
              return ['preview', 'mark'].indexOf(scope.model.field) === -1;
            }
          },
          controller: ['$scope', function($scope) {
            if (_.isUndefined($scope.model.linkText)) {
              $scope.model.linkText = 'Preview';
            }
          }]
        },
        {
          id: 'attrType',
          type: 'discrete',
          label: 'How would you like to show the likert value?',
          required: true,
          options: [
            { _id: 'text', key: 'text', name: 'Text' },
            { _id: 'int', key: 'int', name: 'Numeric' }
          ],
          expressionProperties: {
            hide: function(_$viewValue, _$modelValue, scope) {
              return _.isUndefined(preCalData) ||
                ['blueprint', 'eventProperty'].indexOf(scope.model.field) === -1 ||
                (
                  _.isUndefined(scope.model.blueprint) &&
                  _.isUndefined(scope.model.eventProperty)
                ) ||
                (
                  _.intersection(preCalData.likertBlueprints, scope.model.blueprint).length === 0 &&
                  _.intersection(preCalData.likertFields, scope.model.eventProperty).length === 0
                );
            }
          }
        }
      ]);

      if (disableAll) {
        return Form.disableAll(fields);
      }
      return fields;
    };

    SQLReport.prototype.getAxis = function(disableAll) {
      var _this = this;
      var fields = [
        {
          id: 'label',
          type: 'string',
          label: 'Label',
          required: true
        },
        {
          id: 'outputField',
          type: 'discrete',
          label: 'Select output field',
          required: true,
          controller: ['$scope', function($scope) {
            $scope.report = _this.conf;
            $scope.$watch('report.outputFields', function() {
              var fields = _this.getAggOutputFields();
              $scope.options.templateOptions.options = _.map(fields, function(field) {
                return { _id: field.id, key: field.id, name: field.header };
              });
            }, true);
          }]
        }
      ];

      if (disableAll) {
        return Form.disableAll(fields);
      }
      return fields;
    };

    SQLReport.prototype.getChartFormFields = function(disableAll) {
      var fields = [
        {
          id: 'title',
          type: 'string',
          label: 'Title',
          required: true
        },
        {
          id: 'description',
          type: 'text',
          label: 'Description',
          required: false
        }
      ];

      if (disableAll) {
        return Form.disableAll(fields);
      }

      return fields;
    };

    SQLReport.prototype.getVerticalAxisFormFields = function(chart, disableAll) {
      var _this = this;
      var fields = this.getAxis();

      function updateAggOptions($scope, outputFields) {
        if (_.isUndefined($scope.model.outputField)) {
          return;
        }

        var outputField = _.find(outputFields, function(field) {
          return field.id === $scope.model.outputField;
        });

        if (_.isUndefined(outputField)) {
          return;
        }

        $scope.options.templateOptions.options = _.map(outputField.aggType, function(type) {
          return { _id: type, key: type, name: capitalizeFilter(type) };
        });
      }

      fields.push(
        {
          id: 'agg',
          type: 'discrete',
          label: 'Select Aggregation',
          required: true,
          controller: ['$scope', function($scope) {
            $scope.report = _this.conf;
            $scope.$watchCollection('model.outputField', function() {
              var outputFields = $scope.report.outputFields;
              updateAggOptions($scope, outputFields);
            });

            $scope.$watch('report.outputFields', function(outputFields) {
              updateAggOptions($scope, outputFields);
            }, true);
          }]
        }
      );

      function getTypesOptions(chart) {
        var types = ['bar', 'line'];
        if (!_this.canChartBeMulti(chart)) {
          types = types.concat(['radar', 'polar', 'pie', 'doughnut']);
        }
        return _.map(types, function(type) {
          return { _id: type, key: type, name: capitalizeFilter(type) };
        });
      }

      fields.push(
        {
          id: 'type',
          type: 'discrete',
          label: 'Select chart',
          required: true,
          options: getTypesOptions(chart)
        }
      );

      if (disableAll) {
        return Form.disableAll(fields);
      }

      return fields;
    };

    SQLReport.prototype.canChartBeMulti = function(chart) {
      if (_.isUndefined(chart.ys) || _.isEmpty(chart.ys)) {
        return true;
      }
      var bool = _.indexOf(['bar', 'line'], chart.ys[0].type) > -1;
      return bool;
    };

    /**
     * Download the report as csv
     * @param  {object} data The model from the form above
     * @return {Promise}      An object containing the result
     */
    SQLReport.prototype.download = function(data, _size, _start, user) {
      // Replace current user with user if possible
      // this._updateModelWithUser(data, user);
      return Api.download('reports_download', {}, {
        model: JSON.stringify(data),
        report_id: this.conf._id, // todo: conf should not have _id, get it from report
        currentUser: user
      });
    };

    /**
     * Generate the report as csv as Celerty Task
     * @param  {object} data The model from the form above
     * @return {Promise}      An object containing the result
     */
    SQLReport.prototype.generateAsTask = function(
      reportModel, user, resultValidityInDays, options
    ) {
      reportModel = angular.copy(reportModel);
      // this._updateModelWithUser(reportModel, user);
      var data = {
        model: reportModel,
        report_id: this.reportId,
        element_id: this.elementId,
        currentUser: user,
        resultValidityInDays: resultValidityInDays,
        options: options
      };

      return Api.post(
        'reports_generate_task',
        data,
        { report_id: this.reportId }
      );
    };

    SQLReport.prototype.canGenerateAsTask = function() {
      return this.conf.outputType !== 'none';
    };

    return SQLReport;
  }

  SQLReportFactory.$inject = [
    '$q',
    'ApiService',
    'ReportFieldService',
    'FormsService',
    'AuthService',
    'UtilsService',
    'capitalizeFilter',
    'REPORT_OUTPUT_FIELDS'
  ];

  angular.module('component.reports')
    .factory('SQLReportFactory', SQLReportFactory);
})();
