(function() {
  'use strict';

  function Cards(Sidebar) {
    var service = {};

    service.register = function() {
      var cards = [
        // {
        //   id: 'templates',
        //   title: 'Templates',
        //   directive: 'sidebar-templates',
        //   options: {
        //     routes: [
        //       'epf.email-templates.email-type'
        //     ]
        //   }
        // },
        {
          id: 'placeholders',
          title: 'Placeholders',
          directive: 'sidebar-placeholders',
          options: {
            routes: [
              'epf.email-templates.email-template'
            ]
          }
        }
      ];

      Sidebar.registerCards(cards);
    };

    return service;
  }

  Cards.$inject = ['SidebarService'];

  angular.module('component.emailTemplates')
    .service('EmailTemplatesCards', Cards);
})();
