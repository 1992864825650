export const BlueprintsTemplates = {
  'app/components/blueprints/partials/settings.other.html': require(
    './partials/settings.other.html'
  ).default,
  'app/components/blueprints/form.discrete.html': require('./form.discrete.html').default,
  'app/components/blueprints/form.likert.html': require('./form.likert.html').default
  // 'blueprint-subcategory-hierarchy.html': require(
  // './blueprint-subcategory-hierarchy.html'
  // ).default,
  // 'blueprint-hierarchy.html': require('./blueprint-hierarchy.html').default,
  // 'custom-fields-hierarchy.html': require(
  // './custom-fields-hierarchy.html'
  // ).default,
  // 'blueprint-subcategory-hierarchy.html': require(
  // './blueprint-subcategory-hierarchy.html'
  // ).default,
  // 'map-blueprint-hierarchy.html': require('./map-blueprint-hierarchy.html').default,
};
