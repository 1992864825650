(function() {
  'use strict';

  function filter() {
    return function(input, format) {
      if (!input) {
        return input;
      }

      format = format || 'all';
      var res;
      if (format === 'first') {
        // Capitalize the first letter of a sentence
        res = input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
      } else {
        var words = input.split(' ');
        var result = [];
        words.forEach(function(word) {
          result.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        });
        res = result.join(' ');
      }

      return res;
    };
  }

  angular.module('blocks.utils')
    .filter('capitalize', filter);
})();
