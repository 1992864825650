(function() {
  'use strict';

  function EventInviteesController(
    $scope,
    Utils,
    Network,
    ACTION_MAPPING,
    LocalizationService,
    EVENT_STATES,
    INVITEES_STATES
  ) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.actionMapping = ACTION_MAPPING;
    ctrl.inviteesState = INVITEES_STATES;
    ctrl.isOwner = $scope.event.isMine();

    function loadInvitees(event) {
      var currentSection = event.currentSection;
      return event.getSectionsMetaData(currentSection._id)
        .then(function(sectionsMetaData) {
          return _.map(sectionsMetaData, function(metaData) {
            if (!_.has(INVITEES_STATES, metaData.state)) {
              if (metaData.state === EVENT_STATES.MERGED.id) {
                if (metaData.source === 'import') {
                  metaData.state = INVITEES_STATES.importedManually.id;
                } else {
                  metaData.state = INVITEES_STATES.respondedElectronically.id;
                }
              }
            }

            return metaData;
          });
        })
        .then(function(invitees) {
          if (_.isUndefined(currentSection.section)) {
            return invitees;
          }

          var existingUsers = _.map(invitees, 'user'),
              rejectedOnes = _.chain(currentSection.section.rejectedInvites)
                .filter(function(invite) {
                  return invite.type === 'user' && _.indexOf(existingUsers, invite.user) === -1;
                })
                .map(function(rejectedInvite) {
                  return {
                    user: rejectedInvite,
                    section_type: 'user',
                    state: INVITEES_STATES.rejected.id
                  };
                })
                .uniq('user')
                .value();

          return invitees.concat(rejectedOnes);
        });
    }

    function loadTabs(invitees) {
      return [
        {
          id: 'responded',
          name: 'Responded',
          style: 'success',
          invitees: _.filter(invitees, function(invitee) {
            var states = [
              INVITEES_STATES.importedManually.id,
              INVITEES_STATES.respondedElectronically.id
            ];
            return _.indexOf(states, invitee.state) > -1;
          }),
          filledByText: 'Section filled in by'
        },
        {
          id: 'waitingForApproval',
          name: 'Waiting for approval',
          style: 'warning',
          invitees: _.filter(invitees, function(invitee) {
            return invitee.state === INVITEES_STATES.pending.id;
          }),
          filledByText: 'Section filled in by'
        },
        {
          id: 'waiting',
          name: 'Waiting',
          style: 'info',
          invitees: _.filter(invitees, function(invitee) {
            return invitee.state === INVITEES_STATES.draft.id;
          }),
          filledByText: 'Waiting on a response from'
        },
        {
          id: 'rejected',
          name: 'Rejected',
          style: 'info',
          invitees: _.filter(invitees, function(invitee) {
            return invitee.state === INVITEES_STATES.rejected.id;
          }),
          filledByText: 'Section rejected by'
        }
      ];
    }

    var currentSection = $scope.event.currentSection;
    if (
      currentSection.def &&
      currentSection.def.multiSource &&
      !$scope.event.isDraft()
    ) {
      if (Network.isOffline()) {
        // CCFs section is undefined
        if (!_.isUndefined(currentSection.section)) {
          ctrl.counts = {};

          ctrl.counts[INVITEES_STATES.importedManually.id] = _.filter(
            currentSection.section.items, function(item) {
              return item.source === 'import';
            }).length;

          ctrl.counts[INVITEES_STATES.respondedElectronically.id] = _.filter(
            currentSection.section.items, function(item) {
              return item.source !== 'import';
            }).length;

          var imported = ctrl.counts[INVITEES_STATES.importedManually.id],
              responded = ctrl.counts[INVITEES_STATES.respondedElectronically.id],
              allInvitations = imported + responded;

          if (!_.isUndefined(currentSection.section.actualResponsibles)) {
            allInvitations = currentSection.section.actualResponsibles.length;
          }

          ctrl.counts[INVITEES_STATES.draft.id] = allInvitations - imported - responded;
        }
      } else {
        ctrl.goTo = function(tabId) {
          var tab = _.find(ctrl.tabs, function(tab) {
            return tab.id === tabId;
          });
          ctrl.currentTab = tab;
        };

        ctrl.remind = function(invitee) {
          var data = {};
          if (invitee.user) {
            data.responsible = [invitee.user];
          } else {
            data.invites = [invitee.email];
          }

          $scope.event.invite(data);
        };

        ctrl.retract = function(eventSectionId) {
          $scope.event.retractResponse(eventSectionId);
        };

        ctrl.loading = true;
        loadInvitees($scope.event)
          .then(function(invitees) {
            ctrl.tabs = loadTabs(invitees);

            var firstAvailable = _.find(ctrl.tabs, function(tab) {
              return !_.isEmpty(tab.invitees);
            });
            if (firstAvailable) {
              ctrl.currentActive = firstAvailable.id;
              ctrl.goTo(ctrl.currentActive);
            }
          })
          .catch(Utils.showError)
          .finally(function() {
            ctrl.loading = false;
          });
      }
    }
  }

  EventInviteesController.$inject = [
    '$scope',
    'UtilsService',
    'NetworkService',
    'ACTION_MAPPING',
    'LocalizationService',
    'EVENT_STATES',
    'INVITEES_STATES'
  ];

  function EventInviteesDirective() {
    return {
      scope: {
        event: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-invitees.html',
      replace: true,
      controller: EventInviteesController,
      controllerAs: 'ctrl'
    };
  }


  angular.module('events.directives')
    .directive('eventInvitees', EventInviteesDirective)
    .controller('EventInviteesController', EventInviteesController);
})();
