(function() {
  'use strict';

  function APIListFactory($q, $timeout, $rootScope, Api, Notify, DB_SETTINGS) {
    var APIList = function(service, options) {
      options = options || {};
      this.service = service;
      options.endpoint = options.endpoint || service.es_endpoint;
      options.pagination = options.pagination || 'infinite';
      this.idField = options.idField || options.trackBy || 'doc._id';
      this.searchModel = options.model || {};
      this.appliedFilters = {};
      this.options = options;
      this.startkey = 0;
      this.defaultFilter = _.assign(
        {},
        _.isUndefined(options.search) ? {} : options.search.defaultFilter,
        options.defaultFilter || {}
      );

      // makeDocs is a simple wrapper for function that will be called lazily
      // on loaded item. This is so that we can tweak objects coming from ES
      this.makeDocs = options.makeDocs || function(item) {
        return item;
      };

      this.findOptions = options.findOptions || { limit: DB_SETTINGS.pagination.limit };
      this.search = options.search || {};
      if (_.isUndefined(this.search.filteredBy)) {
        this.search.filteredBy = {};
      }

      // For infinite scroll
      this.items = [];
      this.loadedIds = [];
      this.isLoading = false;
      this.finished = false;

      // Request counter so we can always use only the last one
      // This appears if the trigger to requests at the same time using filters
      this.requests = 0;
      this.ready = true;
    };

    APIList.prototype.setSearch = function(searchModel) {
      this.searchModel = searchModel || {};
      if (_.isUndefined(this.searchModel.filteredBy)) {
        this.searchModel.filteredBy = {};
      }
    };

    // Resets the start key
    APIList.prototype.resetFilters = function() {
      this._setStartKey();
      $rootScope.$broadcast('KZListResetted');
      return this;
    };

    /*
      External methods to be used in controllers
     */

    APIList.prototype.doPaginate = function(options) {
      var _this = this;

      return this._paginate(options)
        .then(function(data) {
          if (_this.requests > 1) {
            _this.requests -= 1;
            return [];
          }

          var hits = data.hits.map(_this.makeDocs);
          _this.total_count = data.total;
          _this.found_count = data.total;

          // if (!_.isUndefined(data.aggs)) {
          //   _this.aggs = data.aggs;
          // }
          //
          if (_this.loadedIds === undefined) {
            _this.loadedIds = [];
          }
          if (_this.options.pagination === 'infinite') {
            hits = _.filter(hits, function(hit) {
              var oid = _.get(hit, _this.idField);
              return _this.loadedIds.indexOf(oid) === -1;
            });
          }

          _this.items = _this.options.pagination === 'infinite' ? _.union(_this.items, hits) : hits;
          var ids = _.map(hits, _this.idField);
          _this.loadedIds = _.union(_this.loadedIds, ids);
          _this._setStartKey(_this.startkey + _this.findOptions.limit); // set the new startkey

          _this.isLoading = false;
          if (hits.length === 0 || (_this.items.length === data.total)) {
            _this.finished = true;
          }

          _this.requests -= 1;
          return _this.items;
        })
        .catch(function(error) {
          _this.requests -= 1;
          _this.isLoading = false;
          _this.finished = true;
          return $q.reject(error);
        });
    };

    APIList.prototype._prefilter = function(filteredBy) {
      var filters = this.search.filters;
      var promises = [];
      var result = {};
      _.forOwn(filteredBy, function(value, key) {
        var filter = _.find(filters, function(item) {
          return item.id === key;
        });

        if (filter === undefined || filter.preFilter === undefined) {
          result[key] = value;
          return;
        }

        promises.push($q.when(filter.preFilter(value)));
      });

      return $q.all(promises)
        .then(function(data) {
          _.forEach(data, function(obj) {
            _.assignIn(result, obj);
          });
          return result;
        });
    };

    APIList.prototype._paginate = function(options) {
      // Load next page according to startkey
      var data = {},
          _this = this;

      var findOptions = options || _this.findOptions;

      _this.isLoading = true;
      _this.finished = false;

      data.sort_on = _this.searchModel.orderBy || _this.search.defaultOrder;
      data.start = _this.startkey;
      data.size = findOptions.limit;
      data.includeAggs = false;

      _this.requests += 1;
      var filteredBy = _.assign(
        {},
        this.defaultFilter,
        findOptions.filteredBy || this.searchModel.filteredBy
      );

      return this._prefilter(filteredBy)
        .then(function(filteredBy) {
          _.assignIn(
            data,
            filteredBy
          );
          return Api.post(_this.options.endpoint, data)
            .catch(function(error) {
              // Notify.error('Could not load data');
              return $q.reject(error);
            });
        });
    };

    APIList.prototype.doLoadItems = function() {
      this.resetFilters();
      this.items = [];
      this.loadedIds = [];
      var _this = this;

      // Load first page
      return this.doPaginate()
        .finally(function() {
          _this.hideList = false;
        })
        .catch(function(error) {
          Notify.error(error.message, 'An error occured while searching.');
        });
    };

    APIList.prototype.getTotalCount = function() {
      var _this = this;

      // if (!_.isUndefined(this.total_count)) {
      //   return $q.when(this.total_count);
      // }

      return this._paginate({ limit: 0 })
        .then(function(data) {
          _this.total_count = data.total;
          _this.found_count = data.total;
          return data.total;
        });
    };

    APIList.prototype.getAggregates = function(options) {
      options = angular.copy(options) || {};
      options.size = 0;
      options.start = 0;
      options.includeAggs = true;

      var _this = this;

      return Api.post(_this.options.endpoint, options)
        .then(function(data) {
          _this.aggs = data.aggs;
          _this.aggs.total = data.total;
          return _this.aggs;
        });
    };

    APIList.prototype.doSearch = function() {
      var _this = this;
      var def = $q.defer();
      this.resetFilters();
      this.items = [];
      this.loadedIds = [];
      this.showFilters = true;
      this.isLoading = true;
      this.finished = false;
      this.hideList = true;
      $timeout(function() {
        return _this.doLoadItems()
          .then(function() {
            _this.hideList = false;
            def.resolve();
          })
          .catch(function(error) {
            _this.hideList = false;
            def.reject(error);
          });
      }, 0);
      return def.promise;
    };

    // set the startkey
    APIList.prototype._setStartKey = function(startkey) {
      this.startkey = startkey || 0;
    };

    APIList.prototype.orderedBy = function() {
      return this.searchModel.orderBy || this.search.defaultOrder;
    };

    /**
     * Given a page number, we set the new startkey to be that page number's
     * first item. This means simply multiplying the page number with the
     * limit per page.
     * @param  {number} page  The page number to go to.
     * @return {Promise}      The same promise returned by calling doPaginate().
     */
    APIList.prototype.goToPage = function(page) {
      page = page || 0;
      this._setStartKey(page * this.findOptions.limit);
      return this.doPaginate();
    };

    return APIList;
  }

  APIListFactory.$inject = [
    '$q',
    '$timeout',
    '$rootScope',
    'ApiService',
    'NotifyService',
    'DB_SETTINGS'
  ];

  angular.module('blocks.api')
    .factory('APIListFactory', APIListFactory);
})();
