(function() {
  'use strict';

  function RestApi(Api) {
    var service = function(endpoint) {
      this.rest_endpoint = endpoint;
    };

    service.prototype.findAll = function(data, urlOptions, options) {
      return Api.get(this.rest_endpoint, data, urlOptions, options);
    };

    service.prototype.save = function(data) {
      return Api.put(this.rest_endpoint, data);
    };

    service.prototype.create = function(data) {
      return Api.post(this.rest_endpoint, data);
    };

    service.prototype.find = function(id, urlParams, options) {
      return Api.get(this.rest_endpoint, { _id: id }, urlParams, options);
    };

    service.prototype.remove = function(id) {
      return Api.delete(this.rest_endpoint, { _id: id });
    };

    return service;
  }

  RestApi.$inject = ['ApiService'];

  angular.module('blocks.api')
    .factory('RestApi', RestApi);
})();
