(function() {
  'use strict';

  function filter(EVENT_STATES) {
    return function(input) {
      if (!input) {
        return input;
      }

      return _.find(EVENT_STATES, { id: input }).title;
    };
  }

  filter.$inject = ['EVENT_STATES'];

  angular.module('component.events')
    .filter('eventState', filter);
})();
