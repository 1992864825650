(function() {
  'use strict';

  function EventLightFactory(
    EventSecurity,
    EventFactory,
    Auth
  ) {
    var Event = function(transformed) {
      this.id = transformed.doc.id;
      this.doc = transformed.doc;
      this.eventType = transformed.eventType;
      this.sections = transformed.sections;
      this.loaded = true;
    };

    Event.prototype.getFull = function() {
      var username = this.isMine() ? undefined : this.doc.user;
      var event = new EventFactory();
      return event.load(this.id, username);
    };

    Event.prototype.isMine = function() {
      return Auth.currentUser() === this.doc.user;
    };

    Event.prototype.checkPermission = function(perm) {
      return EventSecurity.checkPermission(this, perm);
    };

    Event.prototype.getValueId = function(defField) {
      var valueId;
      if (defField.type === 'role') {
        valueId = defField.role;
      } else if (defField.type === 'relation') {
        valueId = defField.relation;
      } else if (defField.type === 'blueprint') {
        valueId = defField.blueprint;
      } else {
        valueId = defField._id;
      }

      return valueId;
    };

    return Event;
  }

  EventLightFactory.$inject = [
    'EventSecurity',
    'EventFactory',
    'AuthService'
  ];

  angular.module('component.events')
    .factory('EventLightFactory', EventLightFactory);
})();
