(function() {
  'use strict';

  function SectionCloseGoalController($scope, Auth, Form, GoalsStore, Utils) {
    var ctrl = this;

    ctrl.show = false;
    ctrl.form = new Form();
    ctrl.invites = {};

    $scope.source = $scope.event.getResponsibles.bind($scope.event);
    ctrl.options = {
      formData: {
        label: 'Specify next responsible person'
      }
    };

    ctrl.nextSection = undefined;
    if (!_.isUndefined($scope.event.nextSection) && !_.isEmpty($scope.event.nextSection)) {
      ctrl.nextSection = $scope.event.nextSection;
    }

    GoalsStore.fetchLight(
      {
        eventId: $scope.event.doc._id,
        eventSectionId: $scope.section.currentSection.def._id,
        sort_on: 'eventAndDueDate',
        user: $scope.event.doc.user
      },
      { own: Auth.currentUser() === $scope.event.doc.user }
    )
      .then(function(goals) {
        var goalSet = goals[0].goalSet;
        ctrl.goalSet = goalSet;
        return goalSet.canClose;
      })
      .then(function(canClose) {
        if (canClose) {
          ctrl.show = true;
        } else {
          return $scope.event.checkPermission('canCloseGoal')
            .then(function() {
              ctrl.show = true;
            }).catch(function(error) {
              if (error.status === 403) {
                ctrl.notEnoughPermission = true;
                return;
              }

              Utils.showError(error);
            });
        }
      });

    ctrl.closeGoal = function() {
      return $scope.event.closeGoal(ctrl.invites)
        .then(function() {
          $scope.$emit('EventReload', { id: $scope.event.doc._id });
          $scope.postAction();
        })
        .catch(Utils.showError);
    };
  }

  SectionCloseGoalController.$inject = [
    '$scope',
    'AuthService',
    'FormsService',
    'GoalsStore',
    'UtilsService'
  ];

  function SectionCloseGoalDirective() {
    return {
      scope: {
        section: '=',
        event: '=',
        def: '=',
        options: '=',
        postAction: '&'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/section-close-goal.html',
      replace: true,
      controller: SectionCloseGoalController,
      controllerAs: 'sectionCloseGoalCtrl'
    };
  }

  angular.module('events.directives')
    .directive('sectionCloseGoal', SectionCloseGoalDirective)
    .controller('SectionCloseGoalController', SectionCloseGoalController);
})();
