
(function() {
  'use strict';

  function ReportChartVerticalAxisDirective(Form) {
    return {
      scope: {
        report: '=',
        chart: '=',
        y: '=',
        editMode: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-chart-vertical-axis.html',
      replace: true,
      link: function(scope) {
        var disableAll = !scope.editMode,
            fields = scope.report.getVerticalAxisFormFields(scope.chart, disableAll);
        scope.verticalAxisForm = new Form(fields);

        scope.removeVerticalAxis = function() {
          _.remove(scope.chart.ys, scope.y);
        };
      }
    };
  }

  ReportChartVerticalAxisDirective.$inject = ['FormsService'];

  angular.module('component.reports')
    .directive('kzReportChartVerticalAxis', ReportChartVerticalAxisDirective);
})();
