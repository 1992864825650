(function() {
  'use strict';

  var sidebarCards = [
    'GeneralCards',
    'EventsCards',
    'GoalsCards',
    'ImporterCards',
    'UsersCards',
    'SettingsCards',
    'EmailTemplatesCards',
    'InboxCards'
  ];

  angular.module('layout.sidebar')
    .constant('SIDEBAR_CARDS', sidebarCards);
})();
