(function() {
  'use strict';

  function RootStylesDirective(Auth, Styles) {
    return {
      restrict: 'E',
      link: function($scope) {
        var setColors = Styles.setColors;

        $scope.$on('OrganisationSettingsChanged', function() {
          setColors(Auth.currentOrganisation());
        });

        $scope.$on('OrganisationProfilesLoaded', function() {
          setColors(Auth.currentOrganisation());
        });

        $scope.$watch(function() {
          return Auth.currentOrganisation();
        }, setColors);
      }
    };
  }

  RootStylesDirective.$inject = ['AuthService', 'StylesService'];

  angular.module('blocks.utils')
    .directive('kzRootStyles', RootStylesDirective);
})();
