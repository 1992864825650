(function() {
  'use strict';

  function TurnitinComponent($uibModal, $timeout, moment, turnitin, Notify) {
    var ctrl = this;

    ctrl.states = {
      undefined: 'N/A',
      created: 'pending...',
      file_pending: 'uploading...',
      file_error: 'error',
      report_not_requested: 'pending...',
      report_pending: 'pending...',
      complete: 'complete',
      report_error: 'error'
    };
    ctrl.usedAttempts = 0;

    ctrl.showState = function() {
      if (!ctrl.submission) {
        return true;
      }
      return ctrl.submission.state ? ctrl.submission.state.state !== 'complete' : true;
    };

    ctrl.showSubmit = function() {
      if (ctrl.readOnly) {
        return false;
      }

      if (ctrl.invalid) {
        return true;
      }

      if (!ctrl.submission) {
        return true;
      }
    };

    ctrl.showResult = function() {
      if (!ctrl.submission) {
        return false;
      }

      if (ctrl.invalid) {
        return false;
      }

      if (ctrl.submission.score !== null && ctrl.submission.score !== undefined) {
        return true;
      }
      return false;
    };

    ctrl.$onInit = function() {
      ctrl.setup();
    };


    var previous;
    ctrl.$doCheck = function() {
      if (ctrl.readOnly) {
        return;
      }

      if (!ctrl.submission) {
        return;
      }

      if (ctrl.submission && ctrl.submission.state.state === 'file_error') {
        ctrl.invalid = true;
        return;
      }

      var model = ctrl.data;
      if (model !== previous) {
        ctrl.invalid = ctrl.submission.uploaded_hash !== turnitin.getHash(ctrl.data);
        previous = model;
      }
    };

    ctrl.setup = function() {
      ctrl.submission = _.last(ctrl.result.submissions);
      if (ctrl.submission && ctrl.submission.uploaded_hash !== turnitin.getHash(ctrl.data)) {
        ctrl.invalid = true;
      }

      if (ctrl.submission && ctrl.submission.state.state === 'file_error') {
        ctrl.invalid = true;
      }
      ctrl.updateUsedAttempts();
      ctrl.startUpdator();
    };

    ctrl.submit = function() {
      if (ctrl.readOnly) {
        return false;
      }

      if (_.isString(ctrl.data) && ctrl.data.split(' ').length < 20) {
        Notify.error('The submission does not have enough text to generate a Similarity Report ' +
                     '(a submission must contain at least 20 words)');
        return;
      }

      if (ctrl.nextAllowedSubmission && ctrl.nextAllowedSubmission.isAfter()) {
        Notify.error('You have used all your submissions. Please wait for an extra attempt');
        return;
      }

      ctrl.invalid = true;
      var payload = {
        title: ctrl.title || 'Checked data',
        data: ctrl.data,
        metadata: ctrl.metadata
      };
      turnitin.submit(payload)
        .then(function(res) {
          ctrl.invalid = false;
          console.log(res);
          if (res.status === 'action_required') {
            return ctrl.acceptEula(res.eula)
              .then(function(accepted) {
                if (accepted) {
                  payload.eula = {
                    version: res.eula.version
                  };
                  return turnitin.submit(payload);
                }
              });
          }
          return res;
        })
        .then(function(res) {
          var submission = {
            id: res.id,
            state: res.state,
            score: res.report ? res.report.overall_match_percentage : null,
            uploaded_hash: res.uploaded_hash,
            error_message: res.error_message,
            error_code: res.error_code,
            created_date: res.created_date
          };

          ctrl.result.submissions.push(submission);
          ctrl.submission = submission;
          ctrl.update();
          ctrl.updateUsedAttempts();
          ctrl.startUpdator();
        })
        .catch(function(err) {
          console.error(err);
          ctrl.invalid = true;
          Notify.error(
            err.message || 'Turnitin service is currently unavailable. Please try again later.'
          );
        });
    };

    ctrl.acceptEula = function(eula) {
      var modal = $uibModal.open({
        animation: true,
        size: 'lg',
        component: 'turnitinEula',
        resolve: {
          eula: function() {
            return eula;
          }
        }
      }).result;

      return modal
      .then(function(data) {
        console.log(data);
        return data;
      })
      .catch(function(err) {
        console.log(err);
        return false;
      });
    };

    ctrl.forceUpdate = function() {
      if (ctrl.readOnly) {
        return false;
      }
      turnitin.forceUpdate(ctrl.submission.id)
        .then(function(data) {
          console.log(data);
          return ctrl.updateSubmission();
        })
        .then(function() {
          Notify.success('A refresh has been requested');
        })
        .catch(function(err) {
          console.error(err);
          Notify.error(err.message || 'Could not fetch information');
        });
    };

    ctrl.updateUsedAttempts = function() {
      if (!ctrl.submission) {
        ctrl.usedAttempts = 0;
      }

      ctrl.usedAttempts = _.filter(ctrl.result.submissions, function(sub) {
        if (sub.state === undefined) {
          return false;
        }
        return sub.state.state === 'complete';
      }).length;

      if (ctrl.maxAttempts && ctrl.submission && ctrl.submission.created_date) {
        if (ctrl.maxAttempts - ctrl.usedAttempts <= 0) {
          var dt = moment(ctrl.submission.created_date);
          dt.add(1, 'days');
          ctrl.nextAllowedSubmission = dt;
        } else {
          ctrl.nextAllowedSubmission = undefined;
        }
      } else {
        ctrl.nextAllowedSubmission = undefined;
      }
    };

    ctrl.updateSubmission = function() {
      var subId = ctrl.submission;
      return turnitin.get(ctrl.submission.id)
        .then(function(data) {
          console.log(data);
          subId.state = data.state;
          // subId.report = data.report;
          subId.score = data.report ? data.report.overall_match_percentage : null;
          subId.uploaded_hash = data.uploaded_hash;
          subId.error_message = data.error_message;
          subId.error_code = data.error_code;
          subId.created_date = data.created_date;
          ctrl.updateUsedAttempts();
          ctrl.update();
        });
    };

    ctrl.startUpdator = function() {
      if (ctrl.submission === undefined) {
        console.log('turnitIn: startUpdator: no submission');
        return;
      }
      var finishedStates = ['file_error', 'complete', 'report_error'];
      if (finishedStates.indexOf(ctrl.submission.state.state) !== -1) {
        console.log('turnitIn: startUpdator: finished state');
        return;
      }

      console.log('turnitIn: startUpdator: updating');
      ctrl.updateSubmission()
        .then(function() {
          var submission = ctrl.submission;
          if (submission === undefined) {
            console.log('turnitIn: startUpdator: no submission');
            return;
          }
          var finishedStates = ['file_error', 'complete', 'report_error'];
          if (finishedStates.indexOf(submission.state.state) !== -1) {
            console.log('turnitIn: startUpdator: finished state');
            return;
          }

          // We want to fetch it only once in a read only view
          if (ctrl.readOnly) {
            return;
          }

          ctrl.timer = $timeout(function() {
            ctrl.startUpdator();
          }, 5000);
        });
    };

    ctrl.openViewer = function() {
      turnitin.getViewerUrl(ctrl.submission.id)
        .then(function(res) {
          console.log(res);
          if (res.status === 'action_required') {
            return ctrl.acceptEula(res.eula)
              .then(function(accepted) {
                if (accepted) {
                  var eula = {
                    version: res.eula.version
                  };
                  return turnitin.getViewerUrl(ctrl.submission.id, eula);
                }
              });
          }
          return res;
        })
        .then(function(res) {
          console.log(res);
          if (res.response.viewer_url) {
            window.open(res.response.viewer_url, '_blank');
          } else {
            Notify.error('Report Viewer not currently available, please try again later');
          }
        })
        .catch(function(err) {
          console.log(err);
          Notify.error('Report Viewer not currently available, please try again later');
        });
    };

    ctrl.update = function() {
      ctrl.onUpdate({ result: ctrl.result, model: ctrl.data });
    };

    ctrl.$onDestroy = function() {
      if (ctrl.timer) {
        $timeout.cancel(ctrl.timer);
      }
    };
  }

  TurnitinComponent.$inject = [
    '$uibModal',
    '$timeout',
    'moment',
    'TurnitinService',
    'NotifyService'
  ];

  angular.module('blocks.utils')
    .component('turnitin', {
      templateUrl: 'app/blocks/utils/turnitin.component.html',
      controller: TurnitinComponent,
      bindings: {
        readOnly: '<',
        title: '<',
        plagiarismTitle: '<',
        data: '<',
        result: '<',
        metadata: '<',
        onUpdate: '&',
        maxAttempts: '<'
      }
    });
})();
