(function() {
  'use strict';

  function rolesGroup(Form) {
    return {
      scope: {
        roles: '=',
        model: '=',
        form: '=?',
        required: '@?'
      },
      templateUrl: 'app/components/roles/roles.group.html',
      link: function(scope) {
        var updateDeps = function() {
          scope.depRoles = _.intersection(scope.model.roles, scope.dependent);
        };
        scope.$watch('model.roles', function() {
          updateDeps();
        });

        scope.$watch(function() {
          return scope.roles;
        }, function(roles) {
          scope.dependent = _.chain(scope.roles)
            .filter(function(role) {
              return role.doc.isUserDependent || !_.isEmpty(role.doc.relations);
            })
            .map(function(role) {
              return role.doc._id;
            })
            .value();

          scope.form = new Form();

          if (roles) {
            updateDeps();
            var options = roles.map(function(role) {
                  return {
                    _id: role.doc._id,
                    key: role.doc._id,
                    name: role.doc.title
                  };
                }),
                rolesFields = [
                  {
                    id: 'basic-fields-legend',
                    type: 'text_heading',
                    label: 'Roles'
                  },
                  {
                    id: 'roles',
                    type: 'discrete_multiple',
                    label: 'Roles',
                    options: options,
                    required: !!scope.required
                  }
                ],
                rolesGroup = scope.form.addGroup();

            scope.form.extend(rolesFields, rolesGroup);
          }
        }
        );
      }
    };
  }

  rolesGroup.$inject = ['FormsService'];

  angular.module('component.roles')
    .directive('rolesGroup', rolesGroup);
})();
