(function() {
  'use strict';

  function QueueController($scope, ACTION_SCHEDULE_MAPPING) {
    var ctrl = this;
    ctrl.queue = {};

    $scope.mapping = {
      new: 'is scheduled',
      progress: 'is being processed',
      failure: 'failed'
    };

    $scope.scheduleMapping = ACTION_SCHEDULE_MAPPING;

    function loadQueue(force) {
      if (_.isUndefined($scope.obj)) {
        return;
      }

      var orig = ('state' in ctrl.queue) ? ctrl.queue.state : '';
      $scope.obj.loadQueue({ force: true })
        .then(function(queue) {
          if (queue === undefined) {
            return;
          }

          ctrl.queue = queue;
          if ((!_.isEmpty(orig) && ctrl.queue.state !== orig) || force) {
            $scope.updateFn();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }

    loadQueue(false);
    $scope.$on('DBPullChange', function(_evt, args) {
      if (args.doc.type === 'queue' && args.doc.related === $scope.obj.doc._id) {
        // Leave some time for pouchdb to process
        loadQueue(true);
      }
    });
  }

  function QueueDirective() {
    return {
      scope: {
        obj: '=',
        updateFn: '&onUpdate'
      },
      restrict: 'E',
      templateUrl: 'app/blocks/queue/queue.process.html',
      controller: QueueController,
      controllerAs: 'ctrl'
    };
  }

  function QueueDirectiveBox() {
    return {
      scope: {
        obj: '=',
        updateFn: '&onUpdate'
      },
      restrict: 'A',
      templateUrl: 'app/blocks/queue/queue.process.box.html',
      controller: QueueController,
      controllerAs: 'ctrl'
    };
  }

  QueueController.$inject = ['$scope', 'ACTION_SCHEDULE_MAPPING'];

  angular.module('blocks.queue')
    .directive('queueProcess', QueueDirective)
    .directive('queueProcessBox', QueueDirectiveBox)
    .controller('QueueController', QueueController);
})();
