(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.reportTemplates', {
        url: 'report_templates/',
        abstract: true,
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('reports.view');
          }],
          currentUser: ['AuthService', function(Auth) {
            return Auth.currentUser();
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.reportTemplates.index', {
        url: '',
        views: {
          'main@epf': {
            templateUrl: 'app/components/reports/templates_list.html',
            controller: 'ReportTemplatesController',
            controllerAs: 'reportsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.reportTemplates.run', {
        url: 'run/:id',
        views: {
          'main@epf': {
            templateUrl: 'app/components/reports/run.html',
            controller: 'ReportRunController',
            controllerAs: 'reportsCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('reports.view');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.reportTemplates.new', {
        url: 'new',
        views: {
          'main@epf': {
            templateUrl: 'app/components/reports/templates_form.html',
            controller: 'ReportTemplateController',
            controllerAs: 'reportCtrl'
          }
        },
        params: {
          doc: null
        },
        data: {
          action: 'new'
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            // @todo: Only organisation perm is handled, please review this once we'll let users
            // doing it.
            return Security.hasPermission('reports.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.reportTemplates.edit', {
        url: 'edit/:id',
        views: {
          'main@epf': {
            templateUrl: 'app/components/reports/templates_form.html',
            controller: 'ReportTemplateController',
            controllerAs: 'reportCtrl'
          }
        },
        data: {
          action: 'edit'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.reportTemplates.editMultiReportElement', {
        url: 'edit/multi-report/:reportId/element/:elementId/',
        views: {
          'main@epf': {
            templateUrl: 'app/components/reports/multiReport.edit.element.html',
            controller: 'MultiReportEditElementController',
            controllerAs: 'multiReportEditElementCtrl'
          }
        },
        data: {
          action: 'edit'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.reports', {
        url: 'reports/',
        abstract: true,
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('reports.view');
          }],
          currentUser: ['AuthService', function(Auth) {
            return Auth.currentUser();
          }]
        }
      })

      .state('epf.reports.view', {
        url: 'view/:location/:id/:blueprintId/:blueprintCatId/:user',
        params: {
          user: '',
          blueprintCatId: ''
        },
        data: {
          showActionButtons: false
        },
        resolve: {
          reportData: ['$stateParams',
            function($stateParams) {
              return {
                blueprint: $stateParams.blueprintId,
                reportType: 'coverage',
                restrictionType: 'none'
              };
            }],
          reportViewer: [
            'ReportViewerFactory', 'reportData', '$stateParams',
            function(ReportViewer, reportData, $stateParams) {
              return new ReportViewer({ reportData: reportData }, { forUser: $stateParams.user });
            }]
        },
        views: {
          'main@layout': {
            templateUrl: 'app/layout/templates/2-columns-half.html'
          },
          'left@epf.reports.view': {
            templateUrl: 'app/components/reports/layout.left.html',
            controller: 'ReportViewFiltersController',
            controllerAs: 'reportViewFiltersCtrl'
          },
          'main@epf.reports.view': {
            templateUrl: 'app/components/reports/view.html',
            controller: 'ReportViewEventsController',
            controllerAs: 'reportViewEventsCtrl'
          }
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.reports')
    .config(config);
})();
