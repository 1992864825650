(function() {
  'use strict';

  function SlotController(
    $scope,
    Auth,
    Event,
    Files,
    FileUpload,
    LocalizationService,
    Notify,
    FILES_ICONS_MAP
  ) {
    var ctrl = this;

    ctrl.fileIcon = FILES_ICONS_MAP[$scope.item.doc.extension];
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('datetime');
    ctrl.fileName = $scope.item.doc.fileinfo.filename;
    if ($scope.item.doc.fileinfo.legacyBreadcrumb) {
      let breadcrumbs = [
        ...$scope.item.doc.fileinfo.legacyBreadcrumb,
        $scope.item.doc.fileinfo.filename
      ];
      ctrl.fileName = breadcrumbs.join('/');
    }
    ctrl.eventTypeName = $scope.item.doc.eventTypeName;

    ctrl.actionButtons = [];
    if (Files.canPreview($scope.item.doc.fileinfo.contentType)) {
      ctrl.actionButtons.push({
        label: 'Preview',
        ariaLabel: 'Preview ' + ctrl.fileName,
        icon: 'icon-view',
        onClick: function() {
          Files.openPreviewModal($scope.item.doc.fileinfo, $scope.item.doc.origin.id);
        },
        klass: 'text-info'
      });
    }
    this.actionButtons.push(
      {
        label: 'Download',
        ariaLabel: 'Download ' + ctrl.fileName,
        icon: 'icon-download',
        onClick: function() {
          FileUpload.getDownloadUrl($scope.item.doc.fileinfo, { oid: $scope.item.doc.origin.id })
            .then(function(res) {
              window.open(res.url);
            })
            .catch(function(err) {
              console.log(err);
              Notify.error(err.message || 'The file cannot be downloaded');
            });
        },
        klass: 'text-info'
      }
    );

    ctrl.secondaryActionButtons = [
      {
        label: 'View linked event',
        ariaLabel: 'View linked event ' + ctrl.eventTypeName,
        icon: 'icon-link',
        href: (function() {
          var isLocal = $scope.item.doc.user === Auth.currentUser();
          $scope.item.doc.origin.user = $scope.item.doc.user;
          var event = new Event($scope.item.doc.origin, { isLocal: isLocal });
          event.updateViewLink();
          return event.href;
        })(),
        klass: 'text-info'
      }
    ];
  }

  function SlotDirective() {
    return {
      scope: {
        item: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/files/file.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  SlotController.$inject = [
    '$scope',
    'AuthService',
    'EventFactory',
    'FilesService',
    'FileUploadService',
    'LocalizationService',
    'NotifyService',
    'FILES_ICONS_MAP'
  ];

  angular.module('component.files')
    .directive('fileSlot', SlotDirective)
    .controller('FileSlotController', SlotController);
})();
