export const EventTypesTemplates = {
  'app/components/event-types/partials/blueprint-maps.html': require(
    './blueprint-maps.html'
  ).default,
  'app/components/event-types/partials/discrete.custom-blueprint.html': require(
    './discrete.custom-blueprint.html'
  ).default,
  'app/components/event-types/partials/discrete.custom-field.html': require(
    './discrete.custom-field.html'
  ).default,
  'app/components/event-types/partials/event-type-tag.html': require(
    './event-type-tag.html'
  ).default,
  'app/components/event-types/partials/field.blueprint.html': require(
    './field.blueprint.html'
  ).default,
  'app/components/event-types/partials/field.customField.html': require(
    './field.customField.html'
  ).default,
  'app/components/event-types/partials/field.goal.html': require('./field.goal.html').default,
  'app/components/event-types/partials/field.properties.html': require(
    './field.properties.html'
  ).default,
  'app/components/event-types/partials/field.relation.html': require(
    './field.relation.html'
  ).default,
  'app/components/event-types/partials/field.report.html': require('./field.report.html').default,
  'app/components/event-types/partials/field.restrictedBy.html': require(
    './field.restrictedBy.html'
  ).default,
  'app/components/event-types/partials/field.role.html': require('./field.role.html').default,
  'app/components/event-types/partials/field.signature.html': require(
    './field.signature.html'
  ).default,
  'app/components/event-types/partials/field.text.html': require('./field.text.html').default,
  'app/components/event-types/partials/field.tree.html': require('./field.tree.html').default,
  'app/components/event-types/partials/likert.custom-field.html': require(
    './likert.custom-field.html'
  ).default,
  'app/components/event-types/partials/file.custom-field.html': require(
    './file.custom-field.html'
  ).default,
  'app/components/event-types/partials/preview.section.html': require(
    './preview.section.html'
  ).default,
  'app/components/event-types/partials/tree.custom-field.html': require(
    './tree.custom-field.html'
  ).default
};
