(function() {
  'use strict';

  var typeInfo = [
    {
      id: 'autosave',
      type: 'userstore',
      title: 'Auto save (draft)'
    },
    {
      id: 'announcementExtra',
      type: 'userstore',
      title: 'Inbox custom'
    },
    {
      id: 'document',
      type: 'userstore',
      title: 'Document'
    },
    {
      id: 'event',
      type: 'userstore',
      title: 'Event'
    },
    {
      id: 'eventExtra',
      type: 'userstore',
      title: 'Comment'
    },
    {
      id: 'eventSection',
      type: 'userstore',
      title: 'Event section'
    },
    {
      id: 'goal',
      type: 'userstore',
      title: 'Goal'
    },
    {
      id: 'queue',
      type: 'userstore',
      title: 'Action request'
    },
    {
      id: 'user',
      type: 'userstore',
      title: 'User'
    },
    {
      id: 'userCache',
      type: 'userstore',
      title: 'User cached data'
    },

    /** Conf data */
    {
      id: 'announcement',
      type: 'confstore',
      title: 'Announcement'
    },
    {
      id: 'blueprint',
      type: 'confstore',
      title: 'Blueprint'
    },
    {
      id: 'dashboardTemplate',
      type: 'confstore',
      title: 'Dashboard'
    },
    {
      id: 'faq',
      type: 'confstore',
      title: 'FAQ'
    },
    {
      id: 'orgConf',
      type: 'confstore',
      title: 'Organisation settings'
    },
    {
      id: 'organisation',
      type: 'confstore',
      title: 'Organisation'
    },
    {
      id: 'profileTemplate',
      type: 'confstore',
      title: 'Profile page'
    },
    {
      id: 'relation',
      type: 'confstore',
      title: 'Relation'
    },
    {
      id: 'role',
      type: 'confstore',
      title: 'Role'
    },
    {
      id: 'userField',
      type: 'confstore',
      title: 'User field'
    },

    /** Versioned types */
    {
      id: 'eventType',
      type: 'versionstore',
      title: 'Event type'
    },
    {
      id: 'reportTemplate',
      type: 'versionstore',
      title: 'Report'
    }
  ];

  /** Rather ugly way to construct an object with type and list of ids */
  var types = _.chain(typeInfo)
    .groupBy('type')
    .map(function(item, key) {
      return [key, _.map(item, 'id')];
    })
    .reduce(function(collector, item) {
      collector[item[0]] = item[1];
      return collector;
    }, {})
    .value();

  var titles = _.chain(typeInfo)
    .groupBy('id')
    .map(function(item, key) {
      return [key, _.map(item, 'title')[0]];
    })
    .reduce(function(collector, item) {
      collector[item[0]] = item[1];
      return collector;
    }, {})
    .value();

  var formFields = {
    faq: [
      { id: 'question', type: 'string', label: 'Question', required: true },
      { id: 'answer', type: 'text', label: 'Answer', required: true }
    ],
    eventExtra: [
      { id: 'comment', type: 'text', label: 'Comment', required: true },
      { id: 'blueprints', type: 'tree', label: 'Tags', required: true },
      { id: 'documents', type: 'document_multiple', label: 'Selected documents', required: true }
    ],
    eventType: [
      { id: 'description', type: 'text', label: 'Description', required: true },
      { id: 'ownerRole', type: 'tree', label: 'Owned by', required: true },
      { id: 'state', type: 'text', label: 'State', required: true }
    ],
    role: [
      { id: 'description', type: 'text', label: 'Description', required: true },
      { id: 'permissions', type: 'discrete_multiple', label: 'Permissions', required: true },
      { id: 'appliesToRoles',
        type: 'discrete_multiple',
        label: 'Restrict permissions to roles',
        required: true },
      { id: 'invitable', type: 'boolean', label: 'Invitable', required: true },
      { id: 'relations',
        type: 'discrete_multiple',
        label: 'Only actionable against other users that match in these relations',
        required: true },
      { id: 'isUserDependent', type: 'boolean', label: 'Only user dependent', required: true }
    ]
  };

  angular.module('blocks.types')
    .constant('TYPES_FIELDS', formFields)
    .constant('DOC_TYPES', types)
    .constant('DOC_TYPE_TITLES', titles);
})();
