(function() {
  'use strict';

  function AnnouncementPreviewController($rootScope, $uibModalInstance, Announcements, Auth, Utils,
                                          Notify, LocalizationService, announcementDoc,
                                          allowDismiss, markAsRead) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.announcement = announcementDoc;

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * We set the correct properties on the extra object.
     * @type {Object}
     */
    ctrl.extra = {
      type: 'announcementExtra',
      announcement: announcementDoc._id,
      organisation: Auth.currentOrganisation(),
      user: Auth.currentUser()
    };

    // We only mark an announcement as read if the controller that opened the modal
    // allows us to do it. e.g., we do NOT mark it if an admin previews the
    // announcement in the announcements list.
    if (markAsRead) {
      var extra = _.assign({}, ctrl.extra, { _id: Utils.guid(), readDate: Utils.now() });
      Announcements.addUserExtra(extra)
        .then(function() {
          $rootScope.$broadcast('KZReadNotification', { _id: ctrl.announcement._id });
          ctrl.announcement.isRead = true;
        })
        .catch(Utils.showError);
    }

    /**
     * We dismiss an announcement, only if the controller that opened the modal
     * allows us to do it. e.g. we do NOT dismiss it if an admin previews the
     * announcement in the announcements list and presses Dismiss.
     * @return {[type]} [description]
     */
    ctrl.dimiss = function() {
      if (allowDismiss) {
        var extra = _.assign({}, ctrl.extra, { _id: Utils.guid(), dismissedDate: Utils.now() });
        Announcements.addUserExtra(extra)
          .then(function(data) {
            ctrl.announcement.isDismissed = true;
            $uibModalInstance.close(data);
            var msg = 'You\'ve successfully dismissed the announcement. It will no longer ' +
                      'show up in your current announcements, but you can still find it in ' +
                      'the archive.';
            Notify.success(msg, 'Success', 10000);
          })
          .catch(Utils.showError);
      } else {
        $uibModalInstance.close();
      }
    };

    ctrl.modal = $uibModalInstance;
  }

  AnnouncementPreviewController.$inject = [
    '$rootScope',
    '$uibModalInstance',
    'AnnouncementsService',
    'AuthService',
    'UtilsService',
    'NotifyService',
    'LocalizationService',
    'announcementDoc',
    'allowDismiss',
    'markAsRead'
  ];

  angular.module('component.announcements')
    .controller('AnnouncementPreviewController', AnnouncementPreviewController);
})();
