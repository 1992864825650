(function() {
  'use strict';

  function GoalSearch(Goals) {
    // function getEventTypesOffline(options) {
    //   options = options || {};
    //   var versionGroupIds = [];

    //   if (!_.isUndefined(options.defaultFilter)) {
    //     versionGroupIds = options.defaultFilter.eventType_versionGroupId || [];
    //   }

    //   return EventTypes.containsGoalFieldsGrouped()
    //     .then(function(result) {
    //       return _.chain(result)
    //         .filter(function(eventType) {
    //           if (_.isEmpty(versionGroupIds)) {
    //             return true;
    //           }
    //           return _.indexOf(versionGroupIds, eventType.doc.versionGroupId) !== -1;
    //         })
    //         .map(function(eventType) {
    //           return {
    //             id: eventType.doc.versionGroupId,
    //             title: eventType.doc.name
    //           };
    //         })
    //         .value();
    //     });
    // }

    function getEventTypes(options) {
      options = options || {};
      return Goals.findUniqueEventTypes(options.defaultFilter)
      .then(function(data) {
        return _.map(data.event_types, function(et) {
          return {
            id: et.versionGroupId,
            title: et.name
          };
        });
      });
    }

    var getSearch = function(defaultFilter, options) {
      options = options || {};
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              placeholder: 'Start typing to search'
            },
            {
              id: 'goalset_state',
              type: 'select',
              label: 'State',
              multiselect: true,
              options: [
                { id: 'open', title: 'Open' },
                { id: 'closed', title: 'Complete' }
              ],
              facetly: false,
              advanced: true
            },
            {
              id: 'eventType_versionGroupId',
              type: 'select',
              label: 'Event type',
              facetly: false,
              advanced: true,
              multiselect: true,
              options: options.simpleSearch ? [] : getEventTypes({ defaultFilter: defaultFilter })
            }
          ],
          options: {
            defaultFacet: 'title',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'title',
            matchFunc: function(obj, _key, value) {
              if (!value) { return true; }

              var val = value.toLowerCase(),
                  name = obj.doc.title ? obj.doc.title.toLowerCase() : '';
              return name.indexOf(val) > -1;
            }
          },
          {
            id: 'event_state',
            matchFunc: function(obj, _key, value) {
              if (_.isEmpty(obj.event)) {
                return false;
              }

              return value === 'completed' ? obj.isSetClosed() : !obj.isSetClosed();
            }
          },
          {
            id: 'eventType_versionGroupId',
            matchFunc: function(obj, _key, value) {
              if (_.isEmpty(value)) {
                return true;
              }

              if (_.isUndefined(obj.event) || _.isEmpty(obj.event)) {
                return false;
              }

              return value.indexOf(obj.eventType.versionGroupId) !== -1;
            }
          },
          {
            id: 'period',
            matchFunc: function(obj, _key, value) {
              if (_.isEmpty(value)) {
                return true;
              }

              if (_.isUndefined(obj.event)) {
                return false;
              }

              var show = true;
              _.forEach(value, function(concatedValue) {
                var concatedValueSplitted = concatedValue.split('|'),
                    eventId = concatedValueSplitted[0],
                    periodId = concatedValueSplitted[1];

                if (obj.doc.eventId === eventId) {
                  var goalPeriodIds = _.keys(obj.targetsByPeriods);
                  show = goalPeriodIds.indexOf(periodId) !== -1;
                }
              });

              return show;
            }
          }
        ],
        orders: {
          event_state: function(obj) {
            var order;
            if (_.isEmpty(obj.event)) {
              order = 3;
            }

            if (obj.isSetClosed()) {
              order = 2;
            } else {
              order = 1;
            }

            return order;
          },
          createdDate: function(obj) {
            if (!obj.event) {
              return '';
            }
            return (new Date(obj.event.doc.createdDate).getTime());
          },
          dueDate: function(obj) {
            return (new Date(obj.doc.dueDate).getTime());
          },
          eventId: function(obj) {
            return obj.doc.eventId;
          },
          goalTitle: function(obj) {
            return obj.doc.title;
          },
          eventTypeName: function(obj) {
            if (obj.eventType) {
              return obj.eventType.name;
            }
            return '';
          },
          eventSectionId: function(obj) {
            return obj.doc.eventSectionId;
          },
          suggested: function(obj) {
            return obj.suggested ? 0 : 1;
          }
        },
        orderGroups: {
          eventAndDueDate: {
            title: 'due date',
            orders: ['event_state', 'dueDate', 'eventTypeName', 'eventId', 'eventSectionId'],
            groupBy: {
              preTemplate: '<goal-wrapper goal="item" template-type="pre" options="options">' +
                '</goal-wrapper>',
              postTemplate: '<goal-wrapper goal="item" template-type="post"></goal-wrapper>'
            }
          },
          eventAndCreatedDate: {
            title: 'created date (most recent first)',
            orders: ['-createdDate', 'eventTypeName', 'eventId', 'eventSectionId'],
            groupBy: {
              preTemplate: '<goal-wrapper goal="item" template-type="pre" options="options">' +
                '</goal-wrapper>',
              postTemplate: '<goal-wrapper goal="item" template-type="post"></goal-wrapper>'
            },
            hideFromSelection: false
          },
          eventAndCreatedDateDesc: {
            title: 'created date (oldest first)',
            orders: ['createdDate', 'eventTypeName', 'eventId', 'eventSectionId'],
            groupBy: {
              preTemplate: '<goal-wrapper goal="item" template-type="pre" options="options">' +
                '</goal-wrapper>',
              postTemplate: '<goal-wrapper goal="item" template-type="post"></goal-wrapper>'
            },
            hideFromSelection: true
          },
          suggestedAndDueDate: {
            title: 'suggested and due date',
            orders: ['suggested', 'dueDate', 'eventId', 'eventSectionId', 'goalTitle'],
            groupBy: {
              preTemplate: '<goal-wrapper goal="item" template-type="pre" options="options">' +
                '</goal-wrapper>',
              postTemplate: '<goal-wrapper goal="item" template-type="post"></goal-wrapper>'
            },
            hideFromSelection: true
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'eventAndDueDate'
      };
      return search;
    };

    var getApiSearch = function(defaultFilter) {
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              label: 'Start typing to search'
            },
            {
              id: 'event_state',
              type: 'select',
              label: 'Event state',
              multiselect: false,
              options: [
                { id: 'open', title: 'Open' },
                { id: 'completed', title: 'Complete' }
              ]
            },
            {
              id: 'eventType_versionGroupId',
              type: 'select',
              label: 'Event type',
              multiselect: true,
              options: getEventTypes({ defaultFilter: defaultFilter })
            }
          ],
          options: {
            defaultFacet: 'text',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'event_state',
            preFilter: function(value) {
              var states = {
                open: ['inProgress'],
                completed: ['complete', 'submitted']
              };

              return {
                event_state: states[value]
              };
            }
          },
          {
            id: 'eventType_versionGroupId'
          }
        ],
        orders: {
          // used only for groupBy Template as API do it with goalsearch.py
          event_state: function(obj) {
            var order;
            if (_.isEmpty(obj.event_state)) {
              order = 3;
            }

            if (obj.event_state === 'inProgress') {
              order = 1;
            } else {
              order = 2;
            }

            return order;
          },
          dueDate: function(obj) {
            return (new Date(obj.dueDate).getTime());
          },
          createdDate: function(obj) {
            return (new Date(obj.createdDate.slice(0, 10)).getTime());
          },
          eventId: function(obj) {
            return obj.eventId;
          },
          eventTypeName: function(obj) {
            return obj.eventType_name;
          },
          title: function(obj) {
            return obj.title;
          },
          eventSectionId: function(obj) {
            return obj.eventSectionId;
          },
          suggested: function(obj) {
            return obj.suggested ? 0 : 1;
          }
        },
        orderGroups: {
          eventAndDueDate: {
            title: 'due date',
            orders: ['event_state', 'dueDate', 'eventTypeName', 'eventId', 'eventSectionId'],
            groupBy: {
              title: 'Goals created by ',
              notitle: 'Event',
              preTemplate: '<goal-wrapper goal="item" template-type="pre" options="options">' +
                '</goal-wrapper>',
              postTemplate: '<goal-wrapper goal="item" template-type="post"></goal-wrapper>'
            }
          },
          eventAndCreatedDate: {
            title: 'created date (most recent first)',
            orders: ['-createdDate', 'eventTypeName', 'eventId', 'eventSectionId'],
            groupBy: {
              preTemplate: '<goal-wrapper goal="item" template-type="pre" options="options">' +
                '</goal-wrapper>',
              postTemplate: '<goal-wrapper goal="item" template-type="post"></goal-wrapper>'
            },
            hideFromSelection: false
          },
          eventAndCreatedDateDesc: {
            title: 'created Date (oldest first)',
            orders: ['event_state', '-createdDate', 'eventId', 'eventSectionId', 'title'],
            groupBy: {
              preTemplate: '<goal-wrapper goal="item" template-type="pre" options="options">' +
                '</goal-wrapper>',
              postTemplate: '<goal-wrapper goal="item" template-type="post"></goal-wrapper>'
            },
            hideFromSelection: true
          },
          suggestedAndDueDate: {
            title: 'suggested and due Date',
            orders: ['suggested', 'dueDate', 'eventId', 'eventSectionId', 'title'],
            groupBy: {
              preTemplate: '<goal-wrapper goal="item" template-type="pre" options="options">' +
                '</goal-wrapper>',
              postTemplate: '<goal-wrapper goal="item" template-type="post"></goal-wrapper>'
            },
            hideFromSelection: true
          }
        },
        defaultOrder: 'eventAndDueDate'
      };
      return search;
    };

    var service = {
      dbsearch: getSearch,
      apiSearch: getApiSearch
    };

    return service;
  }

  GoalSearch.$inject = ['GoalsService'];

  angular.module('component.goals')
    .service('GoalSearch', GoalSearch);
})();
