(function() {
  'use strict';

  function DashboardTemplateSlotController($rootScope, $state, $scope, DashboardTemplates, Security,
                                          DashboardTemplatesUtils, Auth, Notify, Utils,
                                          LocalizationService) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.showLimit = 2;

    ctrl.editUrl = $state.href('epf.dashboard-templates.edit', { id: $scope.item._id });

    /**
     * We need this bound to the controller so that we can
     * either display the edit URL or just the announcement
     * title in the view.
     * @type {Boolean}
     */
    ctrl.canEditTemplate = false;

    Security.hasPermission('dashboardTemplates.edit')
      .then(function(res) {
        ctrl.canEditTemplate = res === undefined ? true : res;
      });

    ctrl.actionButtons = [{
      label: 'Edit',
      ariaLabel: 'Edit ' + $scope.item.title,
      icon: 'icon-edit',
      href: (function() {
        return $state.href('epf.dashboard-templates.edit', { id: $scope.item._id });
      })(),
      showCondition: Security.hasPermission.bind(this, 'dashboardTemplates.edit'),
      klass: 'text-primary'
    }];

    if (!$scope.item.isDefault) {
      ctrl.actionButtons.push({
        label: 'Delete',
        ariaLabel: 'Delete ' + $scope.item.title,
        icon: 'icon-trash',
        onClick: function() {
          Utils.swal({
            title: 'Are you sure you want to remove this dashboard template?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
          },
          function(isConfirm) {
            if (isConfirm) {
              var id = $scope.item._id;
              DashboardTemplates.remove(id)
                .then(function() {
                  $rootScope.$broadcast('KZItemRemoved', { item: $scope.item });
                  Notify.success('Dashboard template successfully removed!', 'Success');
                  $state.go('epf.dashboard-templates.index');
                });
            }
          });
        },
        showCondition: Security.hasPermission.bind(this, 'dashboardTemplates.edit'),
        klass: 'text-danger'
      });
    }

    ctrl.secondaryActionButtons = [{
      label: 'Duplicate',
      ariaLabel: 'Duplicate ' + $scope.item.title,
      icon: 'icon-duplicate',
      onClick: function() {
        var title = 'Copy of ' + $scope.item.title,
            newDashboard = DashboardTemplatesUtils.extendTemplate(
              {
                title: title,
                showTitle: $scope.item.showTitle,
                template: $scope.item.template
              },
              Auth.currentOrganisation()
            );

        // We need to make sure that the dashboard framework picks up the correct title as well
        newDashboard.template.title = title;

        Notify.success(
          'Your copy of the dashboard template is ready!',
          'Success'
        );

        $state.go('epf.dashboard-templates.new', { template: newDashboard });
      },
      showCondition: Security.hasPermission.bind(this, 'dashboardTemplates.edit'),
      klass: 'text-info'
    }];

    ctrl.actionButtons.push({
      label: 'Show audit log',
      ariaLabel: 'Show audit log for ' + $scope.item.title,
      icon: 'icon-history',
      onClick: function() {
        ctrl.shownDescription = !ctrl.shownDescription;
        this.label = ctrl.shownDescription ? 'Hide audit log' : 'Show audit log';
      },
      showCondition: function() {
        return !_.isUndefined($scope.item.dates);
      },
      klass: 'text-info'
    });
  }

  function DashboardTemplateSlotDirective() {
    return {
      scope: {
        item: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/dashboard-templates/directives/template.slot.html',
      controller: DashboardTemplateSlotController,
      controllerAs: 'ctrl'
    };
  }

  DashboardTemplateSlotController.$inject = [
    '$rootScope',
    '$state',
    '$scope',
    'DashboardTemplatesService',
    'SecurityService',
    'DashboardTemplatesUtilsService',
    'AuthService',
    'NotifyService',
    'UtilsService',
    'LocalizationService'
  ];

  angular.module('component.dashboardTemplates')
    .directive('dashboardTemplateSlot', DashboardTemplateSlotDirective);
})();
