(function() {
  'use strict';

  function UserRolesController(
    $q,
    $scope,
    Auth,
    Form,
    Notify,
    Roles,
    Security,
    Utils,
    User,
    Users
  ) {
    var ctrl = this;

    function buildForm(user) {
      if (_.indexOf(['create', 'edit'], ctrl.action) === -1) {
        return;
      }

      var permission = 'roles.assign',
          auth;

      if (ctrl.action === 'create') {
        auth = Security.hasPermission(permission);
      } else if (ctrl.action === 'edit') {
        if (user === Auth.currentUser()) {
          auth = Security.hasPermission(permission + '.own');
        } else {
          auth = Security.hasPermissionFor(permission, user);
        }
      }

      auth
        .then(function() {
          ctrl.form = new Form([]);
          return Security.hasRole('system:admin', { promise: true })
            .then(function() {
              // Add the Super admin checkbox
              ctrl.form.extend([
                { id: 'isOrgSuperUser', type: 'boolean', label: 'Organisation admin' }
              ], ctrl.basicGroup);
            })
            .catch(function() {
              // This does not matter
            });
        })
        .then(function() {
          return Roles.findAll({}, {}, { cached: true })
            .then(function(roles) {
              var options = function() {
                return _.chain(roles)
                  .map(function(role) {
                    return { id: role.doc._id, key: role.doc._id, name: role.doc.title };
                  })
                  .sortBy('name')
                  .value();
              };

              ctrl.form.extend([
                {
                  id: 'roles',
                  label: 'Roles',
                  type: 'checkboxes_grid',
                  options: options()
                }
              ]);
            });
        })
        .catch(function() {
          // switch to the view mode if the user has not the assign-role permission
          ctrl.action = 'view';
        });
    }
    ctrl.action = $scope.action;

    // profile = user
    // if no profile doc is passed then take the logged in or remote user instead
    ctrl.profile = $scope.profile;
    if (!$scope.profile) {
      ctrl.profile = '__current__';
    }

    var prom;
    if (ctrl.profile === '__current__') {
      prom = Users.find(Users.remoteUser || Auth.currentUser());
    } else {
      prom = $q.when(angular.copy(ctrl.profile));
    }

    prom
      .then(function(userDoc) {
        buildForm(userDoc.username);
        return Users.getRoles({ userDoc: userDoc, justVisible: true });
      })
      .then(function(userRoles) {
        ctrl.userRoles = userRoles;
      });

    if (ctrl.action === 'edit') {
      $scope.$watch('ctrl.userRolesForm', function(val) {
        $scope.$emit('KZUserFormChanged', { form: val });
      });
    }

    ctrl.saveProfile = function(isValid) {
      if (!isValid) {
        Utils.showError({ message: 'Form is not valid' });
        return;
      }

      return User.partiallySaveProfile('roles', ctrl.form.fields, ctrl.profile)
        .then(function(newProfile) {
          _.assignIn($scope.profile, newProfile);
          ctrl.profile = newProfile;
          Notify.success('The access control has been saved!');
          ctrl.userRolesForm.$setPristine();
        })
        .catch(function(e) {
          Notify.error(e.message, 'An error occured while saving');
        });
    };
  }

  UserRolesController.$inject = [
    '$q',
    '$scope',
    'AuthService',
    'FormsService',
    'NotifyService',
    'RolesService',
    'SecurityService',
    'UtilsService',
    'UserFactory',
    'UsersService'
  ];

  function UserRolesDirective() {
    return {
      scope: {
        action: '@',
        profile: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/directives/userRoles.html',
      replace: true,
      controller: UserRolesController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.users')
    .directive('userRoles', UserRolesDirective)
    .controller('UserRolesController', UserRolesController);
})();
