import { mimeMapping } from 'src/app/components/files/mimes';

(function() {
  'use strict';

  function FilesTableController(Files, FileUpload, Turnitin, Notify) {
    var ctrl = this;

    ctrl.files = [];
    ctrl.display = 'table';

    ctrl.$onInit = function() {
      let files = ctrl.model || [];
      if (!Array.isArray(files)) {
        files = [files];
      }
      ctrl.files = files.map(function(model) {
        let file = {
          filename: model.filename,
          type: mimeMapping[model.contentType] ?? model.type ?? model.filename.split('.').pop(),
          contentType: model.contentType,
          size: model.size,
          model: model,
          buttons: []
        };
        // The download button
        file.buttons.push({
          label: 'Download',
          icon: 'download',
          klass: 'text-primary',
          action: function(file) {
            FileUpload.getDownloadUrl(file.model, { oid: ctrl.docId })
              .then(function(res) {
                window.open(res.url, '_blank');
              })
              .catch(function(err) {
                console.log(err);
                Notify.error(err.message || 'The file cannot be downloaded');
              });
          }
        });
        if (model.turnitin) {
          var submission = _.last(model.turnitin.submissions);
          if (submission) {
            var validSubmission = typeof submission.error_code === 'undefined';
            file.plagiarism = {
              score: submission.score,
              status: validSubmission ? submission.state.state : 'error',
              details: validSubmission ? 'Plagiarism similarity score:' : submission.error_message
            };
            if (validSubmission) {
              file.buttons.push({
                label: 'View report',
                icon: 'chart-pie',
                klass: 'text-primary',
                action: function() {
                  Turnitin.openViewer(submission);
                }
              });
            }
          }
        }
        // The preview button
        if (Files.canPreview(model.contentType)) {
          file.buttons.push({
            label: 'Preview',
            icon: 'view',
            klass: 'text-primary',
            action: function(file) {
              Files.openPreviewModal(file.model, ctrl.docId);
            }
          });
        }
        return file;
      });

      if (['table', 'list'].includes(ctrl.displayAs)) {
        ctrl.display = ctrl.displayAs;
      }
    };
  }

  FilesTableController.$inject = [
    'FilesService',
    'FileUploadService',
    'TurnitinService',
    'NotifyService'
  ];

  angular.module('widgets.files')
    .component('kzFilesTable', {
      templateUrl: 'app/blocks/widgets/files/files-table.html',
      controller: FilesTableController,
      bindings: {
        displayAs: '@',
        model: '=',
        docId: '<'
      }
    });
})();
