(function() {
  'use strict';

  function filter(Utils) {
    return function(input, deep) {
      var empty = true;
      if (deep === 'deep') {
        _.forOwn(input, function(value) {
          if (Utils.isBlank(value)) {
            return;
          }

          empty = false;
        });

        return empty;
      }

      return Utils.isBlank(input);
    };
  }

  filter.$inject = ['UtilsService'];

  angular.module('blocks.utils')
    .filter('kzIsEmpty', filter);
})();
