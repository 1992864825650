(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.user-fields', {
        url: 'user-fields',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('userFields.view');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.user-fields.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/user-fields/list.html',
            controller: 'UserFieldsController',
            controllerAs: 'userFieldsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.user-fields.new', {
        url: '/new',
        views: {
          content: {
            templateUrl: 'app/components/user-fields/form.html',
            controller: 'UserFieldController',
            controllerAs: 'userFieldCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.user-fields.edit', {
        url: '/edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/user-fields/form.html',
            controller: 'UserFieldController',
            controllerAs: 'userFieldCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.user-fields.reorder', {
        url: '/reorder',
        views: {
          content: {
            templateUrl: 'app/components/user-fields/list-reorder.html',
            controller: 'UserFieldsReorderController',
            controllerAs: 'userFieldsReorderCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('userFields.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.userFields')
    .config(config);
})();
