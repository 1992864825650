(function() {
  'use strict';

  function TypePreviewController(item, $uibModalInstance, Form, TYPES_FIELDS) {
    var ctrl = this;

    ctrl.item = item;

    function buildForm() {
      var fields = TYPES_FIELDS[item.type] || [];
      ctrl.form = new Form(fields);
    }
    buildForm();

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    ctrl.modal = $uibModalInstance;
  }

  TypePreviewController.$inject = [
    'item',
    '$uibModalInstance',
    'FormsService',
    'TYPES_FIELDS'
  ];

  angular.module('blocks.types')
    .controller('TypePreviewController', TypePreviewController);
})();
