// https://github.com/jmdobry/angular-cache
// https://csswizardry.com/2019/03/cache-control-for-civilians/
(function() {
  'use strict';

  /*
    Takes an object like { 32: 'https://api.kaizenep.com/f2068f14/some-image-32.webp', 64: 'https://api.kaizenep.com/123zx875/some-image-64.webp' }
    and turns it into 'https://api.kaizenep.com/f2068f14/some-image-32.webp 32w, https://api.kaizenep.com/123zx875/some-image-64.webp 64w'
  */
  function imageSetToString(set) {
    return Object.entries(set).reduce(function(srcset, set) {
      var prefix = srcset.length > 0 ? ', ' : '';
      return srcset + prefix + set[1] + ' ' + set[0] + 'w';
    }, '');
  }

  /*
    Takes an object like { webp: { 32: 'https://api.kaizenep.com/f2068f14/some-image-32.webp', 64: 'https://api.kaizenep.com/123zx875/some-image-64.webp' } }
    and turns it into { webp: 'https://api.kaizenep.com/f2068f14/some-image-32.webp 32w, https://api.kaizenep.com/123zx875/some-image-64.webp 64w' }
  */
  function getImageSourceSets(imageData) {
    return Object.entries(imageData).reduce(function(sets, srcset) {
      var set = {};
      set[srcset[0]] = imageSetToString(srcset[1]);
      return _.assign({}, sets, set);
    }, {});
  }

  var formatsOrder = ['jxl', 'avif', 'webp', 'jpeg', 'png'];
  var cachesPrefix = 'kzImage-';

  function ImageService(
    $http,
    $q,
    CacheFactory,
    EasApi,
    Utils,
    IMAGE_PROPS,
    IMAGE_DEFAULT_PROPS,
    IMAGE_MISSING_SRC
  ) {
    var service = {};
    var cacheOptions = {
      storageMode: 'localStorage',
      maxAge: IMAGE_DEFAULT_PROPS.maxAge,
      deleteOnExpire: 'aggressive',
      storagePrefix: 'kz-images.caches.'
    };

    service._maxAgeForCache = function(cache) {
      return cache === 'immutable' ? Number.MAX_VALUE : IMAGE_DEFAULT_PROPS.maxAge;
    };

    service._useCache = function(cacheKey, maxAge) {
      var options = _.assign({}, cacheOptions, { maxAge: maxAge });
      return CacheFactory.get(cacheKey) || CacheFactory.createCache(cacheKey, options);
    };

    service._getFrom = function(path, signed) {
      return signed ? EasApi.get(path) : $http.get(path);
    };

    service.setCacheOptions = function(options) {
      return _.assign(
        {},
        { cache: 'no-store', maxAge: cacheOptions.maxAge },
        options
      );
    };

    service.validFormats = function(formats) {
      return formats.filter(function isValid(format) {
        return formatsOrder.includes(format);
      });
    };

    /* The order of the formats matter for the browser.
       We place modern sources before legacy formats,
       so that browsers that understand it will use them
       and those that don't will move onto more widely supported formats.
    */
    service.getFormatSortOrder = function(format) {
      return _.indexOf(formatsOrder, format);
    };

    service.setLayout = function(layout) {
      return layout && IMAGE_PROPS.layouts.includes(layout) ?
        layout :
        IMAGE_DEFAULT_PROPS.layout;
    };

    service.setCacheStrategy = function(cacheStrategy) {
      return cacheStrategy && IMAGE_PROPS.cache.includes(cacheStrategy)
        ? cacheStrategy
        : IMAGE_DEFAULT_PROPS.cache;
    };

    service.setPrefetch = function(prefetch) {
      return typeof prefetch === 'boolean'
        ? prefetch
        : IMAGE_DEFAULT_PROPS.prefetch;
    };

    service.prefetch = function(url, imageData, signed) {
      var deferred = $q.defer();
      service._getFrom(url, signed).catch(function prefetchError(err) {
        console.error(err);
        imageData.content = IMAGE_MISSING_SRC;
      }).finally(function prefetchComplete() {
        deferred.resolve(imageData);
      });

      return deferred.promise;
    };

    service.loadImage = function(imageOptions, cacheOptions) {
      // eslint-disable-next-line no-redeclare
      var cacheOptions = service.setCacheOptions(cacheOptions);
      // Should we try to read from cache or not?
      var useCache =
        ['immutable', 'stale-while-revalidate'].includes(cacheOptions.cache) &&
        !cacheOptions.forceUpdate;

      var imagesCache;
      var cacheKey = Utils.hash(JSON.stringify(imageOptions));
      var maxAge = service._maxAgeForCache(cacheOptions.cache);

      if (useCache) {
        imagesCache = service._useCache(cachesPrefix + cacheKey, maxAge);
      }

      var deferred = $q.defer();

      // If we previously called this loader and we're using the cache
      if (useCache && imagesCache && imagesCache.get('imageData')) {
        deferred.resolve(imagesCache.get('imageData'));
      } else {
        service._getFrom(imageOptions.url, imageOptions.signed)
          .then(function successWithLoader(apiResponse) {
            var sources = apiResponse.sources;
            var content = apiResponse.content;

            // Make all the transformations here and put it in the cache
            var imageData = {
              sources: sources ? getImageSourceSets(sources) : {},
              content: content
            };

            // If we use no-store, then we don't want to put this in the cache
            if (cacheOptions.cache !== 'no-store') {
              // If the storage is full, we need to catch this and allow the image to be displayed
              try {
                imagesCache.put('imageData', imageData);
              } catch (e) {
                // Storage may be full
                console.error(e);
              }
            }

            // Let the consumer know we already made a fetch request here
            // In case they want to revalidate it immediately, they need to know
            // it will have no effect
            var imageDataWithContext = _.assign({}, imageData, { servedFrom: 'api' });

            // Resolve the result here
            deferred.resolve(imageDataWithContext);
          }).catch(function errorWithLoader(errorResponse) {
            deferred.reject(errorResponse);
          });
      }

      return deferred.promise;
    };

    service.purgeCaches = function() {
      CacheFactory.keys()
        .filter(function startsWith(key) {
          return key.startsWith(cachesPrefix);
        })
        .forEach(CacheFactory.destroy);
    };

    return service;
  }

  ImageService.$inject = [
    '$http',
    '$q',
    'CacheFactory',
    'EasApiService',
    'UtilsService',
    'IMAGE_PROPS',
    'IMAGE_DEFAULT_PROPS',
    'IMAGE_MISSING_SRC'
  ];

  angular.module('blocks.image').service('ImageService', ImageService);
})();
