(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.profile-templates', {
        url: 'summary-templates/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('profileTemplates.view');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.profile-templates.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/dashboard-templates/list.html',
            controller: 'ProfileTemplatesController',
            controllerAs: 'templatesListCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.profile-templates.new', {
        url: 'new',
        params: { template: null },
        views: {
          content: {
            templateUrl: 'app/components/dashboard-templates/form.html',
            controller: 'ProfileTemplateController',
            controllerAs: 'templateFormCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('profileTemplates.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.profile-templates.edit', {
        url: 'edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/dashboard-templates/form.html',
            controller: 'ProfileTemplateController',
            controllerAs: 'templateFormCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('profileTemplates.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.dashboardTemplates')
    .config(config);
})();
