(function() {
  'use strict';

  function BlueprintMapEditController(
    $q, BlueprintMaps, Blueprints, Utils, Auth, Notify, Form, Security
  ) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.load();
    };

    ctrl.load = function() {
      ctrl.blueprintMap = {};
      ctrl.mappedBlueprints = [];
      ctrl.mappedTos = [];
      var promise;

      if (ctrl.id && ctrl.action === 'edit') {
        promise = BlueprintMaps.getForEdit(ctrl.id)
          .then(function(data) {
            ctrl.blueprintMap = data;
            Utils.setPageTitle('Edit blueprint map:' + ctrl.blueprintMap.name);
            // return ctrl.prepareData(data);
          })
          .then(function() {
            ctrl.mapForm = ctrl.getNewForm();
            ctrl.model = {
              name: ctrl.blueprintMap.name,
              description: ctrl.blueprintMap.description || '',
              usage: ctrl.blueprintMap.usage || []
            };
          });
      } else {
        promise = $q.when();
        Utils.setPageTitle('New blueprint map');
        ctrl.mapForm = ctrl.getNewForm();
        ctrl.model = {
          name: '',
          desription: '',
          usage: []
        };
      }

      promise
        .then(function() {
          if (ctrl.action === 'new') {
            ctrl.hasEditPermission = true;
            return;
          }

          return Security.hasRole('system:superadmin')
            .then(function(has) {
              ctrl.hasEditPermission = has;
            })
            .catch(function() {
              ctrl.hasEditPermission = false;
            });
        })
        .then(function() {
          return Blueprints.findDiscreteOnly();
        })
        .then(function(blps) {
          ctrl.blueprintOptions = _.map(blps, function(blp) {
            return { _id: blp._id, key: blp._id, name: blp.name };
          });
        })
        .then(function() {
          ctrl.blueprintForm = ctrl.getBlueprintForm();
          ctrl.mappedTo = ctrl.getMappedToForm();
          ctrl.mappedBlueprints = _.map(ctrl.blueprintMap.blueprints || [], function(blp) {
            return ctrl.deserializeBlueprint(blp);
          });
          ctrl.mappedTos = _.map(ctrl.blueprintMap.coordinate_values || [], function(blp) {
            return ctrl.deserializeMapTo(blp.id, blp.type);
          });
          ctrl.loaded = true;
        });
    };

    ctrl.loadAuditlog = function() {
      BlueprintMaps.getAuditlog(ctrl.id)
        .then(function(data) {
          ctrl.auditlog = data;
        });
    };

    ctrl.getNewForm = function() {
      return new Form([
        {
          id: 'name',
          type: 'string',
          label: 'Name',
          required: true
        },
        {
          id: 'description',
          type: 'string',
          label: 'Description',
          required: false
        },
        {
          id: 'usage',
          type: 'discrete_multiple',
          label: 'Usage',
          required: false,
          options: [
            { _id: 'events', key: 'events', name: 'Event auto tagging' }
            // { _id: 'reports', key: 'reports', name: 'Reports' }
          ]
        }
      ]);
    };

    ctrl.showMappedBlueprint = function() {
      ctrl.mappedModel = {};
      ctrl.showMappedForm = true;
    };

    ctrl.addMappedBlueprint = function() {
      var blp = ctrl.deserializeBlueprint(ctrl.mappedModel.blueprint);
      if (blp === undefined) {
        return;
      }
      ctrl.mappedBlueprints.push(blp);
      ctrl.mappedModel = {};
      ctrl.showMappedForm = false;
    };

    ctrl.removeBlueprint = function(blp) {
      ctrl.mappedBlueprints = _.without(ctrl.mappedBlueprints, blp);
    };

    ctrl.removeMapTo = function(blp) {
      ctrl.mappedTos = _.without(ctrl.mappedTos, blp);
    };


    ctrl.getBlueprintForm = function() {
      return new Form([
        {
          id: 'blueprint',
          type: 'discrete',
          label: 'Blueprint',
          required: true,
          options: ctrl.blueprintOptions
        }
      ]);
    };

    ctrl.showMappedTo = function() {
      ctrl.mappedToModel = {};
      ctrl.showMappedToForm = true;
    };

    ctrl.deserializeBlueprint = function(blpId) {
      var blp = _.find(ctrl.blueprintOptions, { _id: blpId });
      if (blp === undefined) {
        return;
      }
      return {
        id: blp._id,
        name: blp.name
      };
    };

    ctrl.deserializeMapTo = function(blpId, type) {
      if (type === 'blueprint') {
        var blp = _.find(ctrl.blueprintOptions, { _id: blpId });
        if (blp === undefined) {
          return;
        }
        return {
          id: blp._id,
          name: blp.name,
          type: 'blueprint'
        };
      }

      return {
        id: blpId,
        name: blpId,
        type: type
      };
    };

    ctrl.addMappedTo = function() {
      var blpId = ctrl.mappedToModel.type === 'blueprint' ?
        ctrl.mappedToModel.blueprint : ctrl.mappedToModel.name;
      var data = ctrl.deserializeMapTo(blpId, ctrl.mappedToModel.type);
      if (data === undefined) {
        return;
      }
      ctrl.mappedTos.push(data);
      ctrl.mappedToModel = {};
      ctrl.showMappedToForm = false;
    };

    ctrl.getMappedToForm = function() {
      return new Form([
        {
          id: 'type',
          type: 'discrete',
          label: 'Type',
          required: true,
          options: [
            { _id: 'blueprint', key: 'blueprint', name: 'Map to blueprint' },
            { _id: 'numeric', key: 'numeric', name: 'Map to number' }
          ]
        },
        {
          id: 'blueprint',
          type: 'discrete',
          label: 'Blueprint',
          required: true,
          options: ctrl.blueprintOptions,
          hideExpression: function(_view, _model, $scope) {
            return !$scope.model.type || $scope.model.type !== 'blueprint';
          }
        },
        {
          id: 'name',
          type: 'string',
          label: 'Name',
          required: true,
          hideExpression: function(_view, _model, $scope) {
            return !$scope.model.type || $scope.model.type !== 'numeric';
          }
        }
      ]);
    };

    ctrl.prepareData = function() {
      return '';
    };

    ctrl.save = function(form) {
      if (!form.$valid) {
        Notify.error('Please review all required fields');
        return;
      }

      if (ctrl.action === 'new') {
        ctrl.blueprintMap._id = Utils.guid();
        ctrl.blueprintMap.type = 'blueprintMap';
        ctrl.blueprintMap.organisation = Auth.currentOrganisation();
      }

      ctrl.blueprintMap.name = ctrl.model.name;
      ctrl.blueprintMap.description = ctrl.model.description;
      ctrl.blueprintMap.usage = ctrl.model.usage;

      if (ctrl.hasEditPermission) {
        ctrl.blueprintMap.blueprints = _.map(ctrl.mappedBlueprints, function(blp) {
          return blp.id;
        });
        ctrl.blueprintMap.coordinate_values = _.map(ctrl.mappedTos, function(mpl) {
          return { id: mpl.id, type: mpl.type };
        });
      }

      return BlueprintMaps.save(ctrl.blueprintMap)
        .then(function(data) {
          ctrl.blueprintMap._rev = data.rev;
          Notify.success('Your blueprint map was saved successfully.', 'Success!');
          // $state.go('epf.blueprintMaps.mapping', { id: ctrl.blueprintMap._id });
        })
        .catch(function(err) {
          console.log(err);
          Notify.error('Could not save blueprint map');
        });
    };
  }

  BlueprintMapEditController.$inject = [
    '$q',
    'BlueprintMapsService',
    'BlueprintsService',
    'UtilsService',
    'AuthService',
    'NotifyService',
    'FormsService',
    'SecurityService'
  ];

  angular.module('component.blueprints')
    .component('blueprintMapEdit', {
      templateUrl: 'app/components/blueprints/blueprintMap.edit.html',
      controller: BlueprintMapEditController,
      bindings: {
        id: '<',
        action: '<'
      }
    });
})();
