(function() {
  'use strict';

  function Search(Utils, DEFAULT_TEMPLATE_ID) {
    var getSearch = function() {
      var search = {
        facetly: {
          facets: []
        },
        filters: [
        ],
        orders: {
          name: function(item) {
            if (item.doc._id === DEFAULT_TEMPLATE_ID) {
              return -1;
            }
            return Utils.safeLowerStr(item.doc.name);
          }
        },
        orderGroups: {
          name: {
            title: 'name',
            orders: ['name']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'name'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = ['UtilsService', 'DEFAULT_TEMPLATE_ID'];

  angular.module('component.emailTemplates')
    .service('EmailTemplateSearch', Search);
})();
