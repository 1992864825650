(function() {
  'use strict';

  function NewEventController(
    $q,
    $state,
    Auth,
    Form,
    User,
    Users,
    UsersStub,
    Security
  ) {
    var ctrl = this;

    ctrl.remoteUser = Users.remoteUser;
    ctrl.model = {};

    function onChangeUser() {
      if (_.isEmpty(ctrl.model.user) || ctrl.model.user === Auth.currentUser()) {
        return;
      }

      UsersStub.find(ctrl.model.user)
        .then(function(profile) {
          ctrl.profile = profile;
        });
    }

    function buildForm() {
      var form = new Form();

      if (ctrl.canCreateForOthers && !ctrl.isItForMySelf && !Users.remoteUser) {
        form.addField({
          id: 'user',
          type: 'select',
          label: 'Who would you like to create this for?',
          required: false,
          onChange: onChangeUser,
          placeholder: 'Start typing to search',
          controller: ['$scope', function($scope) {
            $scope.reload = function(search) {
              if (search.length < 3) {
                $scope.options.templateOptions.options = [];
                return;
              }

              User.getAllOptions(Auth.currentUser(), search)
                .then(function(users) {
                  $scope.options.templateOptions.options = users;
                });
            };
          }]
        });
      } else {
        var user = Auth.currentUser();
        if (Users.remoteUser) {
          user = Users.remoteUser;
        }

        ctrl.model = { user: user };
        onChangeUser();
      }

      if (ctrl.canCreateOwn && ctrl.canCreateForOthers && !Users.remoteUser) {
        var label = 'Reset form';

        form.addField({
          type: 'link',
          label: label,
          onClick: function() {
            ctrl.reset();
          }
        });
      }

      return form;
    }

    ctrl.setIsItForMySelf = function(isItForMySelf) {
      ctrl.isItForMySelf = isItForMySelf;

      ctrl.loading = true;
      ctrl.form = buildForm();
      ctrl.loading = false;
    };

    ctrl.reset = function() {
      ctrl.profile = undefined;
      ctrl.model = { user: null };
      ctrl.form = null;
      ctrl.remoteUser = Users.remoteUser;

      if (ctrl.canCreateOwn && !ctrl.canCreateForOthers) {
        ctrl.setIsItForMySelf(true);
      } else if ((!ctrl.canCreateOwn && ctrl.canCreateForOthers)) {
        ctrl.setIsItForMySelf(false);
      } else if (Users.remoteUser) {
        ctrl.setIsItForMySelf('remote');
      } else {
        ctrl.isItForMySelf = undefined;
      }
    };

    // This is called only for other users, this means the model.user has to be set
    ctrl.save = function(form) {
      if (form.$invalid) {
        return;
      }

      ctrl.create(form);
    };

    ctrl.create = function(form) {
      var routeArgs = {};
      if (_.isEmpty(ctrl.model.user)) {
        form.user.$setValidity('required', false);
        return;
      }

      if (ctrl.model.user !== Auth.currentUser()) {
        routeArgs.user = ctrl.model.user;
      }

      $state.go('epf.events.new', routeArgs);
    };

    ctrl.loading = true;
    $q.all(
      _.map(['events.create.own', 'events.create'], function(perm) {
        return Security.hasPermission(perm)
          .catch(function() {
            return false;
          });
      })
    )
      .then(function(permissions) {
        ctrl.canCreateOwn = permissions[0];
        ctrl.canCreateForOthers = permissions[1];

        ctrl.reset();
        ctrl.authorised = _.some(permissions);
        ctrl.loading = false;
      });
  }

  NewEventController.$inject = [
    '$q',
    '$state',
    'AuthService',
    'FormsService',
    'UserFactory',
    'UsersService',
    'UsersStubService',
    'SecurityService'
  ];

  function config(dashboardProvider) {
    dashboardProvider.widget('newEvent', {
      title: 'Create a new event',
      description: 'Create a new event',
      controller: 'WidgetNewEventController',
      controllerAs: 'newEventCtrl',
      templateUrl: 'app/components/events/widgets/new-event/view.html',
      edit: {},
      category: 'Events'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.events')
    .config(config)
    .controller('WidgetNewEventController', NewEventController);
})();
