(function() {
  'use strict';

  function ShareService() {
    var service = {};

    // $rootScope.$on('KZCurrentFormChange', function(_evt, args) {
    //   service.currentForm = args.form;
    // });

    return service;
  }

  ShareService.$inject = [];

  angular.module('layout.sidebar')
    .service('ShareService', ShareService);
})();
