(function() {
  'use strict';

  function RelationsService(EasApiStore, EasApi) {
    var service = new EasApiStore('relations', { cacheKeys: [], usePersistentCache: true });
    var apiVersion = '';

    service.findAll = function(options) {
      var opts = {};
      if (options) {
        _.assign(opts, options);
      }

      if (opts.cached === undefined) {
        opts.cached = true;
      }

      return EasApiStore.prototype.findAll.call(this, opts)
        .then(function(data) {
          var docData = _.map(data, function(itm) {
            return { doc: itm };
          });
          if (opts.sortedByOrder) {
            return _.sortBy(docData, function(relation) {
              return relation.doc.order;
            });
          }

          return docData;
        });
    };

    service.getNewCopy = function(relationId) {
      return EasApi.get('relations/' + relationId + '/new-copy', {}, {}, { version: apiVersion });
    };

    // only display not already choosen relations
    service.availableRelationsInSection = function(currentSectionFields, currentFieldId) {
      return service.findAll()
        .then(function(relations) {
          var usedRelations = _.chain(currentSectionFields)
            .filter(function(field) {
              return field.type === 'relation' &&
                field.relation !== '' &&
                currentFieldId !== field._id;
            })
            .map(function(relation) {
              return relation.relation;
            }).value();

          var availableRelations = _.filter(relations, function(relation) {
            var relationId = relation.doc._id;
            return usedRelations.indexOf(relationId) === -1;
          });
          return { usedRelations: usedRelations, availableRelations: availableRelations };
        });
    };

    service.getEventTypeUsage = function(relationId) {
      var opts = { version: this.apiVersion };
      var url = this.specificUrl(relationId) + '/usage';
      return EasApi.get(url, undefined, {}, opts);
    };

    return service;
  }

  RelationsService.$inject = ['EasApiStore', 'EasApiService'];

  angular.module('component.relations')
    .factory('RelationsService', RelationsService);
})();
