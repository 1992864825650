(function() {
  'use strict';

  function emailLogPreviewLinkDirective($uibModal, EmailLogs, EmailLog) {
    return {
      restrict: 'A',
      scope: {
        eid: '='
      },
      link: function(scope, elem) {
        var show = function(eid) {
          if (!eid) {
            return;
          }
          return EmailLogs.find(eid)
            .then(function(data) {
              var emailLog = new EmailLog({ doc: data });
              $uibModal.open({
                animation: true,
                template: '<email-view data="ctrl.data" on-close="$close()"></email-view>',
                controller: ['data',
                  function(data) {
                    this.data = data;
                  }
                ],
                controllerAs: 'ctrl',
                resolve: {
                  data: function() {
                    return {
                      subject: emailLog.doc.subject,
                      html: emailLog.doc.html,
                      email_from: emailLog.doc.email_from,
                      recipients: emailLog.doc.recipients,
                      date: emailLog.doc.createdDate
                    };
                  }
                },
                size: 'lg'
              }).result.catch(function(err) {
                console.log('Dismissed', err);
              });
            });
        };

        elem[0].addEventListener('click', function() {
          show(scope.eid);
        });
      }
    };
  }

  emailLogPreviewLinkDirective.$inject = [
    '$uibModal',
    'EmailLogsService',
    'EmailLogFactory',
    'SecurityService',
    'NotifyService'
  ];

  angular.module('component.emailLogs')
    .directive('emailLogPreviewLink', emailLogPreviewLinkDirective);
})();
