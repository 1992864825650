(function() {
  'use strict';

  function filter() {
    return function(input, key) {
      if (key === undefined) {
        return _.isObject(input) ? '' : input;
      }

      var res = input;
      key.split('.').forEach(function(item) {
        if (res !== undefined) {
          res = res[item];
        }
      });

      return res;
    };
  }

  angular.module('blocks.utils')
    .filter('kzGetDotted', filter);
})();
