(function() {
  'use strict';

  angular
    .module('epf.layout',
    [
      'kzHeaderCard',
      'layout.sidebar'
    ]
    );
})();
