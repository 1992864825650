(function() {
  'use strict';

  function OfflineTestController($timeout, SettingsService, Utils) {
    var ctrl = this;

    Utils.setPageTitle('Offline test');

    ctrl.tests = SettingsService.getTests();

    ctrl.testAll = function() {
      if (ctrl.running) {
        console.log('Already running');
        return;
      }

      ctrl.overAll = undefined;
      ctrl.running = true;
      ctrl.tests = SettingsService.getTests();
      return SettingsService.testAll(ctrl.tests, { level: 2 })
        .then(function(result) {
          ctrl.overAll = result;
        })
        .finally(function() {
          ctrl.running = false;
        });
    };

    ctrl.getDbInfo = function() {
      return SettingsService.getDbInfo()
        .then(function(info) {
          ctrl.info = info;
        });
    };

    var updateInfo = function() {
      ctrl.getDbInfo()
        .then(function() {
          $timeout(updateInfo, 1000);
        });
    };

    updateInfo();
  }

  OfflineTestController.$inject = [
    '$timeout',
    'SettingsService',
    'UtilsService'
  ];

  angular.module('component.settings')
    .controller('OfflineTestController', OfflineTestController);
})();
