(function() {
  'use strict';

  function ReviewController($q, $filter, moment, LocalStore, Types, List, StatusService,
                            UserStore, ConfStore, VersionStore, Utils) {
    var ctrl = this;

    Utils.setPageTitle('Settings review');

    ctrl.stores = [UserStore, ConfStore, VersionStore];
    ctrl.options = {
      trackBy: 'item._id',
      borderClass: function(item) {
        if (item.error) {
          return 'progress-border-danger';
        }

        return 'progress-border-pending';
      }
    };

    ctrl.actions = [
      {
        label: 'Sync all data',
        icon: 'icon-push',
        onClick: function() {
          ctrl.pushAll();
        },
        showCondition: function() {
          return ctrl.items && ctrl.items.length > 0;
        },
        klass: 'btn-info'
      }
    ];
    var search = {
      facetly: {
        facets: [
          {
            id: 'type',
            type: 'select',
            label: 'Importance',
            multiselect: false,
            options: [
              { id: 'important', title: 'Important' }
            ],
            facetly: false
          }
        ]
      },
      filters: [
        {
          id: 'type',
          matchFunc: function(item, _key, value) {
            if (value === 'important') {
              if (item.error && item.error.warn) {
                return true;
              }

              return ['queue', 'autosave'].indexOf(item.item.doc.type) === -1;
            }
            return true;
          }
        }
      ],
      orders: {
        date: function(item) {
          return -(moment(item.item.modifiedDate).toDate().getTime());
        }
      },
      orderGroups: {
        date: {
          title: 'date',
          orders: ['date'],
          groupBy: {
            title: 'Items modified in',
            notitle: 'Items without modified date',
            getter: function(item) {
              return $filter('date')(item.item.modifiedDate, 'MMMM y');
            }
          }
        }
      },
      defaultFilter: {
      },
      defaultOrder: 'date'
    };

    ctrl.searchModel = {
      filteredBy: {
        type: 'important'
      }
    };
    ctrl.list = new List({ search: search, model: ctrl.searchModel, idField: 'item._id' });
    var loadItems = function() {
      return LocalStore.findToPush()
        .then(function(data) {
          return $q.all(_.map(data, function(item) {
            return LocalStore.getMeta(item.doc._id);
          }));
        })
        .then(function(data) {
          ctrl.items = data;
          return ctrl.list.doLoadItems(data);
        });
    };
    ctrl.loadItems = loadItems;

    function clearErrors() {
      ctrl.error = undefined;
    }

    function setError(error) {
      ctrl.error = error;
    }

    ctrl.pushAll = function() {
      clearErrors();
      return LocalStore.pushAll()
        .catch(function(err) {
          if (err && (err.status === 401 || Math.floor(err.status / 100) === 5)) {
            setError(err || { status: 0, message: 'Unknown error' });
          }
        })
        .then(function() {
          return loadItems();
        });
    };

    ctrl.pushAll()
      .finally(function() {
        ctrl.loaded = true;
      });

    ctrl.remove = function(item) {
      return LocalStore.safeRemove(item)
        .then(function() {
          return LocalStore.findAll();
        })
        .then(function(data) {
          var queue = _.find(data, function(itm) {
            return itm.doc.type === 'queue' && itm.doc.related === item._id;
          });

          if (queue !== undefined) {
            return LocalStore.safeRemove(queue);
          }
        })
        .then(function() {
          return loadItems();
        });
    };

    ctrl.openPreview = function(doc) {
      Types.openPreview(doc);
    };

    ctrl.relogin = function() {
      StatusService.relogin()
        .then(function() {
          ctrl.pushAll();
        });
    };
  }

  ReviewController.$inject = [
    '$q',
    '$filter',
    'moment',
    'LocalStoreService',
    'TypesService',
    'ListFactory',
    'StatusService',
    'BaseUserStoreService',
    'BaseConfStoreService',
    'BaseVersionStoreService',
    'UtilsService'
  ];

  angular.module('component.settings')
    .controller('ReviewController', ReviewController);
})();
