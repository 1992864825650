(function() {
  'use strict';

  function UserEmailsController($scope, $uibModal, Form, Notify, User, Utils) {
    var ctrl = this;
    if (ctrl.action === 'edit') {
      ctrl.profile = angular.copy($scope.profile);
    } else {
      ctrl.profile = $scope.profile;
    }
    ctrl.action = $scope.action;

    if (ctrl.action === 'create') {
      ctrl.profile.secondaryEmails = [];
      ctrl.primaryEmailForm = new Form([
        {
          id: 'email',
          type: 'email',
          label: 'Primary email',
          required: true,
          helpText: 'Notifications sent from risr/advance are always delivered to the ' +
          'primary email address of each user. Secondary email addresses can be added to ' +
          'enable invitations to be received under the same account.'
        }
      ]);
    }

    function saveProfile(successMessage) {
      if (ctrl.action !== 'edit') {
        return;
      }

      var formFields = [{ id: 'email' }, { id: 'secondaryEmails' }];
      return User.partiallySaveProfile('emails', formFields, ctrl.profile)
        .then(function(newProfile) {
          $scope.profile.email = ctrl.profile.email;
          $scope.profile.secondaryEmails = ctrl.profile.secondaryEmails;
          ctrl.profile = newProfile;
          Notify.success(successMessage);
        })
        .catch(function(e) {
          // Revert to old profile
          ctrl.profile = angular.copy($scope.profile);
          Notify.error(e.message, 'An error occured while saving');
        });
    }

    function replaceSecondaryEmail(secondaryEmails, oldValue, newValue) {
      var index = _.indexOf(secondaryEmails, oldValue);
      if (newValue) {
        secondaryEmails.splice(index, 1, newValue);
      } else {
        secondaryEmails.splice(index, 1);
      }
    }

    ctrl.editEmail = function(model, prop, value) {
      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/users/partials/edit-email.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'model',
          'prop',
          'value',
          function($scope, $uibModalInstance, model, prop, value) {
            $scope.form = new Form(
              [{ id: 'email', label: 'Email', type: 'email', required: true }]
            );
            $scope.formModel = { email: value };

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function(isValid) {
              if (!isValid) {
                return;
              }

              var newValue = $scope.formModel.email;
              var successMessage = '';
              if (prop === 'secondaryEmails') {
                if (!_.isArray(model[prop])) {
                  model[prop] = [];
                }

                if (_.indexOf(model[prop], newValue) > -1) {
                  Utils.showError({ message: newValue + ' already exists' });
                  return;
                }

                if (model.email === newValue) {
                  Utils.showError({ message: newValue + ' already exists' });
                  return;
                }

                if (_.isUndefined(value)) {
                  model[prop].push(newValue);
                  successMessage = 'The secondary email was added successfully';
                } else {
                  replaceSecondaryEmail(model[prop], value, newValue);
                  successMessage = 'The secondary email was edited successfully';
                }
              } else {
                if (_.indexOf(model.secondaryEmails, newValue) > -1) {
                  Utils.showError({ message: newValue + ' already exists' });
                  return;
                }

                model[prop] = newValue;
                successMessage = 'The primary email was edited successfully';
              }

              saveProfile(successMessage);
              $uibModalInstance.dismiss('cancel');
            };
          }
        ],
        size: 'md',
        resolve: {
          model: function() {
            return model;
          },
          prop: function() {
            return prop;
          },
          value: function() {
            return value;
          }
        }
      });
    };

    ctrl.makePrimary = function(newPrimaryEmail) {
      var currentPrimaryEmail = angular.copy(ctrl.profile.email);
      ctrl.profile.email = newPrimaryEmail;
      replaceSecondaryEmail(ctrl.profile.secondaryEmails, newPrimaryEmail, currentPrimaryEmail);
      saveProfile('The primary email has been changed successfully');
    };


    ctrl.removeSecondaryEmail = function(idx) {
      ctrl.profile.secondaryEmails.splice(idx, 1);
      saveProfile('The secondary email has been removed successfully');
    };
  }

  UserEmailsController.$inject = [
    '$scope',
    '$uibModal',
    'FormsService',
    'NotifyService',
    'UserFactory',
    'UtilsService'
  ];

  function UserEmailsDirective() {
    return {
      scope: {
        action: '@',
        profile: '=',
        saveFunc: '&'
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/directives/userEmails.html',
      replace: true,
      controller: UserEmailsController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.users')
    .directive('userEmails', UserEmailsDirective)
    .controller('UserEmailsController', UserEmailsController);
})();
