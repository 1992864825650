(function() {
  'use strict';

  var filesSlotStyles = {
    public: {
      id: 'public',
      name: 'Public',
      borderStyle: 'success'
    },
    private: {
      id: 'private',
      name: 'Private',
      borderStyle: 'danger'
    }
  };

  var msWordFilesExtensions = ['doc', 'docx', 'txt', 'rtf'];
  var msExcelFilesExtensions = ['xls', 'xlsx', 'xlr', 'csv'];
  var acrobatFilesExtensions = ['pdf'];
  var presentationFilesExtensions = ['key', 'pps', 'ppt', 'pptx'];
  var imagesFilesExtensions = [
    'bmp', 'gif', 'jpg', 'jpeg', 'png', 'psd', 'pspimage', 'thm', 'tif', 'yuv'
  ];
  var audioFilesExtensions = [
    'aif', 'iff', 'm3u', 'm4a', 'mid', 'mp3', 'mpa', 'ra', 'wav', 'wma'
  ];
  var compressedFilesExtensions = [
    '7z', 'gz', 'pkg', 'rar', 'rpm', 'sit', 'sitx', 'tar.gz', 'zip', 'zipx'
  ];

  var authorizedExtensions = msWordFilesExtensions.concat(
    msExcelFilesExtensions,
    acrobatFilesExtensions,
    presentationFilesExtensions,
    imagesFilesExtensions,
    audioFilesExtensions,
    compressedFilesExtensions
  );

  const availableTypes = [
    { id: 'all', title: 'All', descriptor: 'all file types' },
    { id: 'pdfs', title: 'PDFs', accept: '.pdf', descriptor: 'PDFs' },
    { id: 'words',
      title: 'Word documents',
      accept: '.doc, .docx, .txt, .odt, application/vnd.oasis.opendocument.*, application/msword',
      descriptor: 'Word documents'
    },
    { id: 'csvs', title: 'CSVs', accept: '.csv, application/csv', descriptor: 'CSV files' },
    { id: 'office',
      title: 'Office documents',
      // eslint-disable-next-line max-len
      accept: '.doc, .docx, text/plain, .csv, .txt, application/vnd.oasis.opendocument.*, application/msword, .xls, .xlsx, .ods',
      descriptor: 'Office documents'
    },
    { id: 'images', title: 'Any image file', accept: 'image/*', descriptor: 'images' },
    { id: 'zip',
      title: 'Any archive file',
      accept: '.gz, .zip, .rar, .jar, .tar, .tgz, .tar.gz, 7z',
      descriptor: 'archives'
    },
    { id: 'videos', title: 'Any video file', accept: 'video/*', descriptor: 'videos' },
    { id: 'audio', title: 'Any audio file', accept: 'audio/*', descriptor: 'audio files' }
  ];


  angular.module('component.files')
    .constant('AUTHORIZED_FILES_EXTENSIONS', authorizedExtensions)
    .constant('FILE_TYPES', availableTypes);

  var filesIconsMap = {};
  authorizedExtensions.forEach(function logArrayElements(element) {
    if (msWordFilesExtensions.indexOf(element) >= 0) {
      filesIconsMap[element] = 'file-word';
    } else if (msExcelFilesExtensions.indexOf(element) >= 0) {
      filesIconsMap[element] = 'file-excel';
    } else if (acrobatFilesExtensions.indexOf(element) >= 0) {
      filesIconsMap[element] = 'file-pdf';
    } else if (presentationFilesExtensions.indexOf(element) >= 0) {
      filesIconsMap[element] = 'file-powerpoint';
    } else if (imagesFilesExtensions.indexOf(element) >= 0) {
      filesIconsMap[element] = 'file-image';
    } else if (audioFilesExtensions.indexOf(element) >= 0) {
      filesIconsMap[element] = 'file-audio';
    } else if (compressedFilesExtensions.indexOf(element) >= 0) {
      filesIconsMap[element] = 'file-archive';
    }
  });

  angular.module('component.files')
    .constant('FILES_SLOT_STYLES', filesSlotStyles)
    .constant('FILES_ICONS_MAP', filesIconsMap);
})();
