(function() {
  'use strict';

  function UserFieldController($state, $stateParams, UserFields, Auth, BLUEPRINT_TYPES, Form, Utils,
                               Roles, Security, Notify, FILE_TYPES) {
    var ctrl = this;

    ctrl.blueprintTypes = BLUEPRINT_TYPES;
    ctrl.availableTypes = FILE_TYPES;

    Security.hasPermission('roles.edit')
      .then(function() {
        ctrl.showSave = true;
        ctrl.showDelete = !_.isUndefined($stateParams.id);
      });

    if ($stateParams.id) {
      UserFields.getForEdit($stateParams.id, { cached: false })
        .then(function(data) {
          ctrl.userField = data;
          Utils.setPageTitle('Edit user field: ' + ctrl.userField.name);
          ctrl.loaded = true;
        });
    } else {
      ctrl.userField = {};
      Utils.setPageTitle('New user field');
      ctrl.loaded = true;
    }

    Roles.findAll()
        .then(function(results) {
          var global = [{ _id: '__all__', name: 'All roles' }, { _id: '__owner__', name: 'Owner' }];
          var roles = _.map(results, function(role) {
            return { _id: role.doc._id, name: role.doc.title };
          });
          roles = global.concat(roles);

          ctrl.visibleByForm = new Form([
            {
              id: 'visibleBy',
              type: 'discrete_multiple',
              label: 'Who can see this user field?',
              required: true,
              defaultValue: ['__all__'],
              options: roles
            }
          ]);
        });


    var saveUserField = function() {
      return UserFields.save(ctrl.userField)
          .then(function() {
            Notify.success('Your user field was successfully saved.', 'Success!');
            $state.go('epf.user-fields.index');
          })
          .catch(Utils.showError);
    };

    ctrl.loadAuditlog = function() {
      UserFields.getAuditlog($stateParams.id)
        .then(function(data) {
          ctrl.auditlog = data;
        });
    };

    ctrl.save = function(isValid) {
      ctrl.formIsSubmitted = true;

      if (isValid) {
        // var actionName = 'modified';
        // if (ctrl.userField._rev === undefined) {
        //   actionName = 'created';
        // }
        // Utils.recordLog(ctrl.userField, actionName, Auth.currentUser());

        ctrl.userField.type = 'userField'; // Make sure this is userField and not something else
        ctrl.userField._id = ctrl.userField._id ? ctrl.userField._id : Utils.guid();
        ctrl.userField.organisation = Auth.currentOrganisation();

        return saveUserField();
      }
    };

    ctrl.remove = function() {
      Utils.swal({
        title: 'Are you sure you want to remove this user field?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          UserFields.remove($stateParams.id)
            .then(function() {
              Notify.success('Your user field was successfully removed.', 'Success!');
              $state.go('epf.user-fields.index');
            });
        }
      });
    };

    ctrl.addCategory = function() {
      ctrl.userField.categories = ctrl.userField.categories ? ctrl.userField.categories : [];
      ctrl.userField.categories.push({ _id: Utils.guid(), name: '', categories: [] });
    };

    ctrl.removeNode = function(scope) {
      scope.remove();
    };

    ctrl.toggleNode = function(scope) {
      scope.toggle();
    };

    ctrl.newSubNode = function(scope) {
      var nodeData = scope.$modelValue;
      nodeData.categories.push({
        _id: Utils.guid(),
        name: '',
        categories: []
      });
    };

    ctrl.fieldTypeChanged = function() {
      if (ctrl.userField.fieldType === 'boolean') {
        _.forEach(['isRequired', 'isUnique'], function(attr) {
          if (ctrl.userField[attr]) {
            delete ctrl.userField[attr];
          }
        });
      }
    };
  }

  UserFieldController.$inject = [
    '$state',
    '$stateParams',
    'UserFieldsService',
    'AuthService',
    'BLUEPRINT_TYPES',
    'FormsService',
    'UtilsService',
    'RolesService',
    'SecurityService',
    'NotifyService',
    'FILE_TYPES'
  ];

  angular.module('component.userFields')
    .controller('UserFieldController', UserFieldController);
})();
