(function() {
  'use strict';

  function UpgradeController($q, $timeout, Cache) {
    var ctrl = this;

    ctrl.show = true;

    ctrl.reload = function() {
      // unregister any service worker
      var promise = Cache.destroy({ strong: true });
      if ('serviceWorker' in navigator) {
        promise = promise.then(function() {
          return navigator.serviceWorker.getRegistrations();
        })
        .then(function(registrations) {
          return $q.all(registrations.map(function(registration) {
            return registration.unregister();
          }));
        });
      }

      return promise
        .catch(function() {
        })
        .then(function() {
          window.location.reload();
        });
    };

    console.log('Starting countdown');
    $timeout(ctrl.reload, 15000);
  }

  UpgradeController.$inject = ['$q', '$timeout', 'CacheService'];

  angular.module('epf.core')
    .controller('UpgradeController', UpgradeController);
})();
