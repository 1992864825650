(function() {
  'use strict';

  function GoalLightFactory(
    $state,
    $log,
    GoalFactory,
    TargetLight,
    GoalSecurity,
    Auth,
    EventLight,
    EventType,
    Utils,
    kzStorage,
    EVENT_STATES
  ) {
    /**
     * transformed is an object that needs to have:
     *
     *  - doc - the goal object
     *  - event - the transformed event
     *  - eventType - the transformed event
     *
     * @param {*} transformed
     */
    var Goal = function(transformed) {
      this.id = transformed.doc._id;
      this.doc = transformed.doc;
      if (transformed.event) {
        this.event = new EventLight(transformed.event);
        this.eventType = transformed.event.eventType;
        if (!_.isUndefined(transformed.event.goalSet)) {
          this.goalSet = transformed.event.goalSet;
          this.customsSettings = transformed.event.goalSet.customsSettings;
          this.canClose = transformed.event.goalSet.canClose;
          this.willAutoClose = transformed.event.goalSet.willAutoClose;
        }
      } else {
        $log.warn('A goal without event', this.id);
      }

      var _this = this;
      this.targets = _.map(transformed.targets, function(target) {
        return new TargetLight(target, _this.doc.periods);
      });

      // this is to know if we can show the "Others" period or not.
      this.hasOneTargetWithoutPeriod = _.some(this.targets, function(target) {
        return target.hasNoPeriod;
      });
    };

    Goal.prototype.getFull = function() {
      var username = this.isMine() ? undefined : this.doc.user;
      return GoalFactory.find(this.doc._id, username);
    };

    Goal.prototype.getState = function() {
      return this.doc.state || 'inProgress';
    };

    // this is wrong
    Goal.prototype.isSetClosed = function() {
      if (!this.event) {
        return true;
      }
      if (this.event.doc.state === EVENT_STATES.INPROGRESS.id) {
        return false;
        // var currentSection = this.event.currentSection;
        // if (currentSection) {
        //   return this.doc.eventSectionId !== currentSection._id;
        // }
      }

      return true;
    };

    Goal.prototype.isOverdue = function() {
      var dueDate = this.doc.dueDate;
      var today = new Date().toISOString().slice(0, 10);
      return dueDate < today;
    };

    Goal.prototype.getBorderClass = function() {
      // hack: this is actually use to show the border for the event oppening wrapper

      var style;
      if (this.isSetClosed()) {
        style = 'complete';
      } else {
        style = 'pending';
      }

      return 'progress-border-' + style;
    };

    Goal.prototype.isMine = function() {
      return Auth.currentUser() === this.doc.user;
    };

    Goal.prototype.isPredefined = function() {
      var sections = this.eventType.sections;
      var sectionId = this.doc.eventSectionId;
      var defField = EventType.getGoalField(sections, sectionId);
      var predefIds = _.map(defField.definition, function(def) {
        return def._id;
      });

      return _.indexOf(predefIds, this.doc.originalId) > -1;
    };

    Goal.prototype.getViewLink = function() {
      var _this = this;
      return this.checkPermission('canWork')
        .catch(function() {
          return false;
        })
        .then(function(canWork) {
          var sref,
              srefOpts = { id: _this.doc._id };

          if (_this.isMine()) {
            sref = 'epf.goals.view';

            if (canWork) {
              sref = 'epf.goals.work';
            }
          } else {
            sref = 'epf.users.goals-view';
            srefOpts.user = _this.doc.user;

            if (canWork) {
              sref = 'epf.users.goals-work';
            }
          }

          return $state.href(sref, srefOpts);
        });
    };

    Goal.prototype.getCurrentPeriodId = function() {
      if (_.isUndefined(this.doc.periods)) {
        return;
      }

      if (this.doc.periods.length === 0) {
        return;
      }

      var currentPeriods = kzStorage.getItem('selectedPeriod');
      var periodId = currentPeriods[this.doc._id];
      if (periodId === '__others__') {
        return periodId;
      }

      var period = _.find(this.doc.periods, { _id: periodId });
      if (period === undefined) {
        period = this.doc.periods[0];
      }

      return period._id;
    };

    Goal.prototype.getTargetsForPeriod = function(period) {
      if (period === undefined) {
        return this.targets;
      }
      var targets = _.filter(this.targets, function(target) {
        return target.hasPeriod(period);
      });

      return targets;
    };

    Goal.prototype.getProgress = function(period) {
      var targets = this.getTargetsForPeriod(period);
      var generalTargets = _.filter(targets, function(target) {
        var progress = target.calculateProgress(period);
        return progress.max === 0;
      });

      if (targets.length === 0) {
        return {
          type: 'none',
          value: null
        };
      }

      if (generalTargets.length === 0) {
        return {
          type: 'ratio',
          value: this.getAvgRate(period, targets)
        };
      }

      if (generalTargets.length === targets.length) {
        return {
          type: 'total',
          value: this.getTotalValue(period, targets)
        };
      }

      return {
        type: 'none',
        value: null
      };
    };

    Goal.prototype.getTotalValue = function(period, targets) {
      if (targets === undefined) {
        targets = this.getTargetsForPeriod(period);
      }

      var sum = _.chain(targets)
      .map(function(target) {
        var progress = target.calculateProgress(period);
        return progress.progress;
      })
      .reduce(function(pre, cur) {
        return pre + cur;
      })
      .value();

      return sum;
    };

    Goal.prototype.getAvgRate = function(period, targets) {
      if (targets === undefined) {
        targets = this.getTargetsForPeriod(period);
      }

      // get the rate from each target and get the avg
      var sum = _.chain(targets)
        .map(function(target) {
          var progress = target.calculateProgress(period);
          return progress.ratio;
        })
        .reduce(function(pre, cur) {
          return pre + cur;
        })
        .value();

      return Utils.getRate(sum, targets.length);
    };

    Goal.prototype.checkPermission = function(perm) {
      return GoalSecurity.checkPermission(this, perm);
    };

    Goal.prototype.getTotalLinkedEvents = function() {
      // console.error('Obsolete: need to use countableLinkedEvents now');

      var totalLinkedEvents = 0;
      _.forEach(this.targets, function(target) {
        var details = target.getDetails();
        var linkedEvents = details.countable || [];
        totalLinkedEvents += linkedEvents.length;
      });

      return totalLinkedEvents;
    };

    return Goal;
  }

  GoalLightFactory.$inject = [
    '$state',
    '$log',
    'GoalFactory',
    'TargetLightFactory',
    'GoalSecurity',
    'AuthService',
    'EventLightFactory',
    'EventTypeFactory',
    'UtilsService',
    'kzSessionStorage',
    'EVENT_STATES'
  ];

  angular.module('component.goals')
    .factory('GoalLightFactory', GoalLightFactory);
})();
