(function() {
  'use strict';

  var copyToClipboard = function(str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  function kzCopyToClipboardDirective(Notify) {
    return {
      scope: {
        text: '@'
      },
      link: function(scope, element) {
        element.click(function() {
          if (scope.text) {
            copyToClipboard(scope.text);
            Notify.success('Copied to clipboard!');
          } else {
            Notify.warning('Nothing to copy...');
          }
        });
      }
    };
  }

  kzCopyToClipboardDirective.$inject = ['NotifyService'];

  angular.module('blocks.utils')
    .directive('kzCopyToClipboard', kzCopyToClipboardDirective);
})();
