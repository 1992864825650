(function() {
  'use strict';

  var SUBMAPPING = {
    merged: 'This response has been submitted',
    draft: 'This section is awaiting a response',
    sectiondraft: 'This section is waiting to be filled in',

    complete: 'This section is completed',
    inprogress: 'Goals are being worked on',
    skipped: 'This section was intentionally skipped',
    awaiting: 'This section is awaiting responses from multiple users',

    missing: 'Future section in the workflow',
    other: 'Other section in the workflow',
    approval: 'This section is waiting for approval'

  };

  function EventProgress() {
    return {
      scope: {
        states: '='
      },
      restrict: 'EA',
      replace: true,
      templateUrl: 'app/components/events/directives/event-progress.html',
      link: function(scope) {
        /** We get only the state of each section since that's all we need in the HTML
         * The possible states are: complete, skipped, pending, incomplete
         */
        scope.SUBMAPPING = SUBMAPPING;
        // scope.states = scope.sections;
      }
    };
  }

  EventProgress.$inject = [];

  angular.module('events.directives')
    .directive('kzEventProgress', EventProgress);
})();
