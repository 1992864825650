(function() {
  'use strict';

  function SummaryController(ProfileTemplates, DashboardUtils, Utils) {
    var ctrl = this;

    ctrl.showNotifications = false;

    ctrl.name = 'main-profile';
    ctrl.templates = [];

    ProfileTemplates.findAllForUser()
      .then(function(templates) {
        // Templates come ordered, so the first one is always either the default
        // or the one that was last used (KZ-2293)
        ctrl.template = DashboardUtils.setTemplate('profiles', templates[0]);
        if (ctrl.template.title !== 'Default summary template') {
          Utils.setPageTitle(ctrl.template.title);
        } else {
          Utils.setPageTitle('Summary');
        }
        // We also set all the profile templates the user has access to
        ctrl.templates = templates;
      })
      .catch(Utils.showError);

    ctrl.onTemplateChange = function(id) {
      var template = _.find(ctrl.templates, { _id: id });
      if (!_.isUndefined(template)) {
        ctrl.template = DashboardUtils.setTemplate('profiles', template);
        Utils.setPageTitle(ctrl.template.title);
      }
    };
  }

  SummaryController.$inject = [
    'ProfileTemplatesService',
    'DashboardUtilsService',
    'UtilsService'
  ];

  angular.module('component.dashboard')
    .controller('SummaryController', SummaryController);
})();
