(function() {
  'use strict';

  function SyncDirective(Database) {
    return {
      scope: {
        doc: '='
      },
      restrict: 'A',
      replace: true,
      template: '<li class="label" ng-if="pending"><i class="icon-spin animate-spin"></i></li>',
      link: function($scope, elem) {
        function updateState() {
          Database.getSyncState($scope.doc._id + $scope.doc._rev)
            .then(function(state) {
              $scope.syncState = state;
              $scope.pending = state === 'pending';
              elem.addClass('sync-state-' + state);
            });
        }

        updateState();
        $scope.$on('DBPushChange', function(_evt, args) {
          if (args.id === $scope.doc._id) {
            updateState();
          }
        });
      }
    };
  }

  SyncDirective.$inject = ['DatabaseService'];

  function ActualModeDirective($log, Database) {
    var mapping = {
      offline: 'Ready to be used in areas without Internet connection',
      online: 'No data are stored locally',
      pendingOnline: 'Removing local data',
      pendingOffline: 'Syncing data for use in areas without Internet connection'
    };

    var mappingClass = {
      offline: 'text-success',
      online: 'text-warning',
      pendingOnline: 'text-danger',
      pendingOffline: 'text-danger'
    };

    return {
      restrict: 'A',
      template: 'Actual mode: <span class="{{ modeClass }}">{{ actualMode }}</span>',
      link: function($scope) {
        $scope.$watch(function() {
          return Database.actualMode;
        }, function(status) {
          $log.debug('ActualMode: ' + status);

          $scope.modeClass = mappingClass[status];
          $scope.actualMode = mapping[status];
        });
      }
    };
  }

  ActualModeDirective.$inject = ['$log', 'DatabaseService'];

  angular.module('blocks.db')
    .directive('syncState', SyncDirective)
    .directive('kzActualMode', ActualModeDirective);
})();
