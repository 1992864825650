(function() {
  'use strict';

  function FAQsService($q, EasApiStore) {
    var service = new EasApiStore('faq');
    service.preCache = function() { return $q.all([service.findAll()]); };

    return service;
  }

  FAQsService.$inject = ['$q', 'EasApiStore'];

  angular.module('component.faqs')
    .factory('FAQsService', FAQsService);
})();
