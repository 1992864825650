(function() {
  'use strict';

  function EventDefineFiltersController(globalScope, $uibModal, ReportFields, Utils) {
    var ctrl = this;

    ctrl.totalActiveFilters = (globalScope.model || []).length;
    globalScope.$watch('model', function() {
      ctrl.totalActiveFilters = (globalScope.model || []).length;
    }, true);

    ctrl.filters = Utils.filtersToSearchModel(globalScope.model);

    ctrl.openFiltersDefinitionModal = function() {
      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/events/partials/event-filters-definition.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'FormsService',
          'model',
          function($scope, $uibModalInstance, Form, model) {
            $scope.filters = angular.copy(model);

            function filterUsedDataType(dataTypeFromModel) {
              var dataTypeToFilter = [
                'eventType_versionGroupId',
                'blueprint',
                'modifiedDate',
                'addedDate',
                'completedDate',
                'startDate',
                'endDate',
                'eventState'
              ];
              var notAllowed = ['eventType_versionGroupId', 'eventState'];
              var dataTypeAlreadyUsed = _.chain($scope.filters)
                                         .map(function(filter) { return filter.dataType; })
                                         .uniq()
                                         .value();

              var dataTypesFiltered = _.filter(dataTypeToFilter, function(dt) {
                if (_.indexOf(notAllowed, dt) === -1 || dataTypeFromModel === dt) {
                  return true;
                }

                return _.indexOf(dataTypeAlreadyUsed, dt) === -1;
              });

              return dataTypesFiltered;
            }

            function loadFilterForms() {
              if (_.isUndefined(ctrl.filterForms)) {
                ctrl.filterForms = {};
              }

              $scope.filterForms = _.map($scope.filters, function(filter) {
                var formFields = ReportFields.getFilterFormFields(
                  filter.dataType,
                  'events',
                  {
                    dataTypeId: 'dataType',
                    dataTypeToFilter: filterUsedDataType(filter.dataType),
                    includeExtraConditions: true
                  }
                );
                return new Form(formFields);
              });
            }

            function loadModelWatch() {
              if ($scope.watchers) {
                _.forEach($scope.watchers, function(watcher) {
                  console.log('Removing watcher');
                  watcher();
                });
              }
              $scope.watchers = [];
              _.forEach($scope.filters, function(filter, i) {
                var filterId = 'filter_' + i;
                $scope[filterId] = filter;

                $scope.watchers.push(
                  $scope.$watchCollection(filterId + '.dataType', function(newVal, oldVal) {
                    console.log(newVal, oldVal);
                    // Clear value if it differes as we do not event to see
                    // blueprint selected with a event type id in value
                    if (newVal !== oldVal) {
                      delete $scope.filters[i].value;
                    }
                    loadFilterForms();
                  })
                );
              });
            }
            loadModelWatch();

            $scope.addFilter = function() {
              if (_.isUndefined($scope.filters)) {
                $scope.filters = [];
              }

              $scope.filters.push({});
              loadModelWatch();
            };

            $scope.removeFilter = function(index) {
              $scope.filters.splice(index, 1);
              loadModelWatch();
            };

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function(isValid) {
              if (!isValid) {
                return;
              }

              globalScope.model = angular.copy($scope.filters);
              $scope.dismiss();
            };
          }
        ],
        size: 'lg',
        resolve: {
          model: function() {
            return globalScope.model;
          }
        }
      });
    };
  }

  EventDefineFiltersController.$inject = [
    '$scope',
    '$uibModal',
    'ReportFieldService',
    'UtilsService'
  ];

  function EventDefineFiltersDirective() {
    return {
      scope: {
        model: '=',
        readonly: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event.define.filters.html',
      replace: true,
      controller: EventDefineFiltersController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventDefineFilters', EventDefineFiltersDirective)
    .controller('EventDefineFiltersController', EventDefineFiltersController);
})();
