(function() {
  'use strict';

  var loadingHtml = '<h3>Loading...</h3>';

  function EmailTypePreviewController($element, EmailTypes, Notify) {
    var ctrl = this;

    ctrl.showHeader = _.isUndefined(ctrl.showHeader) ? true : ctrl.showHeader;

    ctrl.$onChanges = function() {
      ctrl.preview();
    };

    ctrl.preview = function() {
      EmailTypes.preview(ctrl.emailTypeId, ctrl.preferredTemplateId, ctrl.partials)
        .then(function(result) {
          ctrl.showHtml(result.html);
          ctrl.subject = result.subject;
        })
        .catch(function(err) {
          var msg = 'Unknown error';
          if (err && err.message) {
            msg = err.message;
          }
          Notify.error(msg);
          if (ctrl.onClose) {
            ctrl.onClose({});
          }
        });
    };

    ctrl.showHtml = function(html) {
      var blob = new Blob([html], { type: 'text/html' });
      if (ctrl.href) {
        window.URL.revokeObjectURL(ctrl.href);
      }

      ctrl.href = window.URL.createObjectURL(blob);
      var iframe = $element.find('iframe')[0];
      try {
        iframe.contentWindow.location.replace(ctrl.href);
      } catch (err) {
        iframe.src = ctrl.href;
      }
    };

    ctrl.$onDestroy = function() {
      if (ctrl.href) {
        window.URL.revokeObjectURL(ctrl.href);
      }
    };
    ctrl.showHtml(loadingHtml);
  }

  EmailTypePreviewController.$inject = [
    '$element',
    'EmailTypesService',
    'NotifyService'
  ];

  angular.module('component.emailTemplates')
    .component('emailTypePreview', {
      templateUrl: 'app/components/email-templates/email-type-preview.html',
      controller: EmailTypePreviewController,
      bindings: {
        emailTypeId: '<',
        preferredTemplateId: '<?',
        partials: '<?',
        onClose: '&',
        showHeader: '<?'
      }
    });
})();
