(function() {
  'use strict';

  function SimpleGoalsList($scope, GoalSearch, List, LocalizationService, GOALS_STATES) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.goalsStates = GOALS_STATES;
    ctrl.model = $scope.model;
    ctrl.options = $scope.options || {};
    $scope.$on('GoalPeriodSelected', function(_ev, arg) {
      var splitted = arg.split('|');
      if (ctrl.list) {
        _.forEach(ctrl.list.items, function(item) {
          if (splitted[0] === item.doc.eventId) {
            item.setPeriod(splitted[1]);
          }
        });
      }
    });

    $scope.$watchCollection('goals', function(goals) {
      ctrl.options = $scope.options || {};
      ctrl.list = new List({
        search: GoalSearch.dbsearch(undefined, { simpleSearch: true }),
        idField: 'doc._id',
        defaultOrder: ctrl.options.defaultListOrder || 'eventAndDueDate',
        findOptions: { limit: 0 },
        defaultFilter: ctrl.options.filter || {}
      });

      ctrl.list.doLoadItems(goals);
    });

    ctrl.clearSearch = function() {
      delete ctrl.searchTerm;
      delete ctrl.searchTermToHighlight;
      ctrl.list.doLoadItems($scope.goals);
    };

    ctrl.search = function(searchTerm) {
      if (_.isUndefined(searchTerm) || _.isEmpty(searchTerm)) {
        ctrl.clearSearch();
        return;
      }

      ctrl.searchTerm = searchTerm;
      ctrl.searchTermToHighlight = searchTerm.toLowerCase();
      var goals = _.filter($scope.goals, function(goal) {
        var termLowered = searchTerm.toLowerCase();
        var filteredTargets = _.filter(goal.targets, function(target) {
          return !_.isUndefined(target.title) &&
                  _.includes(target.title.toLowerCase(), termLowered);
        });

        return _.includes(goal.event.eventType.name.toLowerCase(), termLowered) ||
          _.includes(goal.doc.title.toLowerCase(), termLowered) ||
          filteredTargets.length > 0;
      });
      ctrl.list.doLoadItems(goals);
    };
  }

  SimpleGoalsList.$inject = [
    '$scope',
    'GoalSearch',
    'ListFactory',
    'LocalizationService',
    'GOALS_STATES'
  ];

  function simpleGoalsList() {
    return {
      scope: {
        model: '=',
        goals: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/simple-goals-list.html',
      replace: false,
      controller: SimpleGoalsList,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('simpleGoalsList', simpleGoalsList)
    .controller('SimpleGoalsList', SimpleGoalsList);
})();
