(function() {
  'use strict';

  function GoalSecurity(
    $q,
    Security
  ) {
    var service = {};

    var PERMISSIONS = {
      canAdd: function(goal) {
        return $q.when(!_.isUndefined(goal.goalSet) && goal.goalSet.canAddCustomsInProgress);
      },
      canClose: function(goal) {
        return $q.when(!_.isUndefined(goal.goalSet) && goal.goalSet.canClose);
      },
      canReOpen: function(goal) {
        return $q.when(!_.isUndefined(goal.goalSet) && goal.goalSet.canReOpen);
      },
      canWork: function(goal) {
        if (goal.event === undefined) {
          return $q.reject({ status: 403 });
        }

        if (goal.isSetClosed()) {
          if (goal.isMine()) {
            return $q.reject({ status: 403 });
          }
          return Security.hasPermissionFor('goals.manage', goal.doc.user);
        }
        return Security.hasOwnPermissionFor('goals.work', goal.doc.user)
          .catch(function(error) {
            if (error.status === 403 && !goal.isMine()) {
              return Security.hasPermissionFor('goals.manage', goal.doc.user);
            }

            return $q.reject(error);
          });
      },
      canUpdateDueDate: function(goal) {
        return $q.when(!_.isUndefined(goal.goalSet) && goal.goalSet.canUpdateDueDate);
      },
      canUpdatePeriods: function(goal) {
        return $q.when(!_.isUndefined(goal.goalSet) && goal.goalSet.canUpdatePeriods);
      }
    };

    /**
     * Goal has to be an object with following interface
     *
     * doc.user
     * getDefField()
     * isSetClosed()
     */
    service.checkPermission = function(goal, permission) {
      var fn = PERMISSIONS[permission];
      if (fn === undefined) {
        throw new Error({ status: 500, message: 'Unknown permission: ' + permission });
      }
      return fn(goal);
    };

    return service;
  }

  GoalSecurity.$inject = [
    '$q',
    'SecurityService'
  ];

  angular.module('component.goals')
    .service('GoalSecurity', GoalSecurity);
})();
