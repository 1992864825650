(function() {
  'use strict';

  function ReportsController(
    $state, $rootScope, Report, Reports, ReportSearch, Profile, List, Security, Utils
  ) {
    var ctrl = this;

    Utils.setPageTitle('Reports');

    ctrl.options = {
    };

    ctrl.searchModel = Profile.getListPreferences('reports').current;

    var makeDocs = function(item) {
      return new Report(item);
    };

    ctrl.list = new List({
      search: ReportSearch.search,
      model: ctrl.searchModel,
      idField: 'id',
      makeDocs: makeDocs,
      searchFn: Reports.searchIds.bind(Reports),
      fetchFn: function(ids) {
        return Reports.fetchIds(ids, { transformType: 'light' });
      },
      defaultFilter: {
        purpose: 'latestDrafts'
      }
    });

    $rootScope.$broadcast('KZCurrentListChange', { list: 'reports', search: ctrl.list.search });
    ctrl.list.doLoadItems();

    ctrl.actions = [
      {
        label: 'Add new report',
        icon: 'icon-edit',
        href: (function() {
          return $state.href(
            'epf.reportTemplates.new',
            { type: 'organisation' });
        })(),
        showCondition: Security.hasPermission.bind(this, 'reports.edit'),
        klass: 'btn-success'
      }
    ];
  }

  ReportsController.$inject = [
    '$state',
    '$rootScope',
    'ReportFactory',
    'ReportTemplatesService',
    'ReportTemplateSearch',
    'ProfileService',
    'APIIDListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.reports')
    .controller('ReportTemplatesController', ReportsController);
})();
