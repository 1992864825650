
(function() {
  'use strict';

  function ReportSortDirective(Form) {
    return {
      scope: {
        report: '=',
        field: '=',
        editMode: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-sort.html',
      replace: true,
      link: function(scope) {
        var disableAll = !scope.editMode;
        scope.sortForm = new Form(scope.report.getSortFormFields(disableAll));

        scope.removeSort = function() {
          _.remove(scope.report.conf.sortBy, scope.field);
        };
      }
    };
  }

  ReportSortDirective.$inject = ['FormsService'];

  angular.module('component.reports')
    .directive('kzReportSort', ReportSortDirective);
})();
