(function() {
  'use strict';

  function config() {
  }

  angular
    .module('blocks.cache', [])
    .config(config);
})();
