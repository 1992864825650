(function() {
  'use strict';

  function SidebarService($log, $state, $injector, SIDEBAR_CARDS) {
    var service = {};
    service._cards = {};
    service._routecards = {};
    service._alreadyRegistered = false;

    service.registerAll = function() {
      if (service._alreadyRegistered === true) {
        return;
      }

      SIDEBAR_CARDS.forEach(function(item) {
        $injector.invoke([item, function(obj) {
          obj.register();
        }]);
      });

      service._alreadyRegistered = true;
    };

    service.registerCard = function(card) {
      $log.debug('Registering: ', card.id);
      if (_.has(service._cards, card.id)) {
        $log.error('This card \'' + card.id + '\' is already registered');
        return;
      }

      service._cards[card.id] = card;

      var options = card.options || {};
      if (_.isUndefined(options.routes)) {
        $log.error('This card \'' + card.id + '\' is not assigned to any routes');
        return;
      }

      options.routes.forEach(function(route) {
        service._routecards[route] = service._routecards[route] || [];
        service._routecards[route].push(card);
      });
    };

    service.registerCards = function(cards) {
      cards.forEach(function(item) {
        service.registerCard(item);
      });
    };

    service.getAllCards = function() {
      return _.values(service._cards).map(function(item) {
        return item.card;
      });
    };

    service.getCardsForRoute = function() {
      var name = $state.current.name;
      var cards = [];

      while (name !== '') {
        cards = cards.concat(service._routecards[name] || []);
        name = name.substring(0, name.lastIndexOf('.'));
      }

      return _.sortBy(_.uniq(cards), function(item) {
        return item.order !== undefined ? item.order : 1000;
      });
    };

    return service;
  }

  SidebarService.$inject = ['$log', '$state', '$injector', 'SIDEBAR_CARDS'];

  angular.module('layout.sidebar')
    .service('SidebarService', SidebarService);
})();
