(function() {
  'use strict';

  function SettingsController($scope, $q, md5, Profile, Users, Auth, Database, Form,
                              Cache, Notify, Utils, PACKAGE_VERSIONS) {
    var ctrl = this;

    Utils.setPageTitle('Settings');

    ctrl.versions = {
      // kaizen: '1.3.7',
      frontend: PACKAGE_VERSIONS.frontend
    };

    Profile.find({ cached: false })
      .then(function(doc) {
        var pinFields = [];
        if (doc.pin) {
          ctrl.pinHeader = 'Change PIN';
        } else {
          ctrl.pinHeader = 'Set PIN';
        }

        pinFields.push({
          id: 'pin',
          type: 'password',
          label: 'Enter new PIN',
          required: true
        }
        );
        pinFields.push({
          id: 'pinSame',
          type: 'password',
          label: 'Re-type new PIN',
          required: true
        }
        );

        ctrl.pin = {};
        ctrl.pinForm = new Form(pinFields);
      });

    Auth.getStorageMode()
      .then(function(mode) {
        ctrl.storage = {
          storageMode: mode
        };
        ctrl.storageModeForm = new Form([{
          id: 'storageMode',
          type: 'discrete',
          required: true,
          label: 'Storage mode',
          options: [
            { _id: 'none', key: 'none', name: 'No local store' },
            { _id: 'session', key: 'session', name: 'Use only for current session' },
            { _id: 'persistent', key: 'persistent', name: 'Keep persistent' }
          ]
        }]);
      });

    ctrl.updateStorageMode = function() {
      return Auth.setStorageMode(ctrl.storage.storageMode);
    };

    ctrl.settings = Auth.getSettings();

    ctrl.optimizeForm = new Form([
      { id: 'dataOptimized', type: 'boolean', label: 'Enable data optimisation' }
    ]);

    ctrl.updatePin = function(form) {
      Profile.find({ cached: false })
        .then(function(doc) {
          if (ctrl.pin.pin !== ctrl.pin.pinSame) {
            return $q.reject({ status: 403, message: 'PINs are not same. Please try again.' });
          }

          var pin = md5.createHash(ctrl.pin.pin);
          return Users.updatePin(doc, pin);
        })
        .then(function() {
          console.log('Saved');
          Notify.success('A new PIN code has been set');
          ctrl.pin = {};
          ctrl.error = '';
          form.$setPristine();
          form.$setUntouched();
          // Make sure we have loaded the modified profile
          return Profile.find({ cached: false });
        })
        .catch(function(error) {
          Notify.error(error.message || 'Unknown error');
          ctrl.error = error.message;
        });
    };

    ctrl.setOffline = function(offline) {
      ctrl.settings.preferredOffline = offline;
      Auth.setSettings(ctrl.settings);
      if (offline) {
        Profile.find()
          .then(function(doc) {
            Auth.updatePin(doc.pin);
          });
      } else {
        Auth.updatePin();
      }
    };

    ctrl.updateOptimize = function() {
      Auth.setSettings(ctrl.settings);
    };

    ctrl.clearCache = function() {
      var promise = Cache.destroy({ strong: true });

      // unregister any service worker
      if ('serviceWorker' in navigator) {
        promise = promise
          .then(function() {
            return navigator.serviceWorker.getRegistrations();
          })
          .then(function(registrations) {
            return $q.all(registrations.map(function(registration) {
              return registration.unregister();
            }));
          })
          .catch(function() {
            return;
          })
          .then(function() {
            window.location.reload();
          });
      }

      return promise;
    };

    ctrl.save = function() {
      Auth.getSettings();
    };

    $scope.$watch(function() {
      return Database.actualMode;
    }, function(value) {
      ctrl.actualMode = value;
    });

    // Figure out how much space the databases will take
    // TODO - this should be redone - it should be guarded in db setup rather then UI
    var toggleAllowLocalDataStorage = function() {
      var userAgent = window.navigator.userAgent,
          hasLimitedStorage = !userAgent.match(/Cordova/i) &&
            (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)),
          supportsLocalDb = Modernizr.websqldatabase || Modernizr.indexeddb || window.cordova,

          // MAX_SIZE = 52428800; //50MB http://www.conversion-metric.org/filesize/megabytes-to-bytes
          MAX_SIZE = 31457280; // 30MB

      if (!supportsLocalDb) {
        ctrl.allowLocalDataStorage = false;
        ctrl.noLocalStorageMessage = 'Your current browser does not support storing data locally.';
      } else if (hasLimitedStorage) {
        Database.checkSyncSize()
          .then(function(total) {
            if (total <= MAX_SIZE) {
              ctrl.allowLocalDataStorage = true;
            } else {
              ctrl.allowLocalDataStorage = false;
              ctrl.noLocalStorageMessage = 'Your current device cannot save all of your data for ' +
                'offline usage because it does not support its current size.';
            }
          });
      } else if (supportsLocalDb && !hasLimitedStorage) {
        ctrl.allowLocalDataStorage = true;
      }
    };

    toggleAllowLocalDataStorage();
  }

  SettingsController.$inject = [
    '$scope',
    '$q',
    'md5',
    'ProfileService',
    'UsersService',
    'AuthService',
    'DatabaseService',
    'FormsService',
    'CacheService',
    'NotifyService',
    'UtilsService',
    'PACKAGE_VERSIONS'
  ];

  angular.module('component.settings')
    .controller('SettingsController', SettingsController);
})();
