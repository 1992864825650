(function() {
  'use strict';

  function UnsyncedBar(StatusService) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'app/layout/header/unsynced-bar.html',
      link: function($scope) {
        $scope.info = StatusService.getInfo();
        $scope.$on('KZStatusInfoUpdated', function(_evt, info) {
          _.assignIn($scope.info, info);
        });
      }
    };
  }

  UnsyncedBar.$inject = ['StatusService'];

  angular.module('kzHeaderCard')
    .directive('kzUnsyncedBar', UnsyncedBar);
})();
