(function() {
  'use strict';

  function PlaceholdersCard($state, EmailTypes, Notify) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/email-templates/cards/placeholders.html',
      link: function(scope) {
        scope.placeholders = [];
        var emailTypeId = $state.params.emailTypeId;

        EmailTypes.find(emailTypeId)
          .then(function(emailType) {
            if (emailType.placeholders.length > 0) {
              scope.placeholders = emailType.placeholders;
            }
          })
          .catch(Notify.error);
      }
    };
  }

  PlaceholdersCard.$inject = ['$state', 'EmailTypesService', 'NotifyService'];

  angular.module('component.emailTemplates')
    .directive('sidebarPlaceholders', PlaceholdersCard);
})();
