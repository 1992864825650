(function() {
  'use strict';

  function TurnitinEula(
    $element
  ) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.eula = ctrl.resolve.eula;
      var blob = new Blob([ctrl.eula.html], { type: 'text/html' });
      if (ctrl.href) {
        window.URL.revokeObjectURL(ctrl.href);
      }

      ctrl.href = window.URL.createObjectURL(blob);
      var iframe = $element.find('iframe')[0];
      try {
        iframe.contentWindow.location.replace(ctrl.href);
      } catch (err) {
        iframe.src = ctrl.href;
      }

      ctrl.close = function() {
        ctrl.modalInstance.dismiss('cancel');
      };

      ctrl.acceptEula = function() {
        ctrl.modalInstance.close(true);
      };

      ctrl.$onDestroy = function() {
        if (ctrl.href) {
          window.URL.revokeObjectURL(ctrl.href);
        }
      };
    };
  }

  TurnitinEula.$inject = [
    '$element'
  ];

  angular.module('blocks.utils')
    .component('turnitinEula', {
      controller: TurnitinEula,
      templateUrl: 'app/blocks/utils/turnitin.eula.html',
      bindings: {
        resolve: '<',
        modalInstance: '<'
      }
    });
})();
