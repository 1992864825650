(function() {
  'use strict';

  function kzUserFieldsFieldsFilter(Form) {
    var controller = function($scope, UserFields) {
      var ctrl = this;

      ctrl.formData = {};

      ctrl.form = new Form();

      ctrl.idx = 1;

      var label = 'User field',
          id = 'field',
          addValueField = function(curr, userField, _itemLabel, _itemValue, group) {
            ctrl.form.removeField(id + '_value' + curr, group);
            ctrl.formData[id + '_value' + curr] = '';

            ctrl.form.addField({
              id: id + '_value' + curr,
              type: userField.fieldType,
              required: false,
              label: label + ' value ' + curr,
              options: userField.categories
            }, group);
          };

      ctrl.addPair = function() {
        var curr = ctrl.idx;
        var group = ctrl.form.addGroup();

        ctrl.form.addField({
          id: id + '_id' + curr,
          type: 'select',
          required: false,
          label: label + ' id ' + curr,
          itemLabel: 'name',
          itemValue: 'id',
          options: UserFields.findAll()
            .then(function(data) {
              var UserFields = [];
              UserFields.push({ id: '', name: '---' });
              _.forEach(data, function(relation) {
                UserFields.push({ id: relation.id, name: relation.doc.name });
              });
              return UserFields;
            }),
          onChange: function($viewValue) {
            if ($viewValue) {
              UserFields.find($viewValue)
                .then(function(doc) {
                  addValueField(curr, doc, 'name', 'id', group);
                });
            } else {
              ctrl.form.removeField(id + '_value' + curr, group);
            }
          }
        }, group);

        ctrl.idx++;
      };

      ctrl.usedFields = [];
      $scope.$watchCollection(
        function() {
          return (ctrl.formData);
        },
        function(newValue) {
          ctrl.usedFields.forEach(function(field) {
            if (ctrl.search.filteredBy[field]) {
              delete ctrl.search.filteredBy[field];
            }
          });

          if (newValue) {
            var keys = _.keys(ctrl.formData),
                ids = _.sortBy(_.filter(keys, function(key) {
                  return _.startsWith(key, id + '_id');
                })),
                values = _.sortBy(_.filter(keys, function(key) {
                  return _.startsWith(key, id + '_value');
                })),
                isEmpty = function(obj) {
                  return _.isUndefined(obj);
                };

            _.zipWith(ids, values, function(a, b) {
              if (!isEmpty(ctrl.formData[a]) && !isEmpty(ctrl.formData[b])) {
                if (ctrl.usedFields.indexOf(ctrl.formData[a]) === -1) {
                  ctrl.usedFields.push(ctrl.formData[a]);
                }

                ctrl.search.filteredBy[ctrl.formData[a]] = ctrl.formData[b];
              }
            });
          }
        }
      );

      ctrl.addPair();
    };

    controller.$inject = ['$scope', 'UserFieldsService'];

    return {
      restrict: 'AEC',
      scope: {
        search: '=',
        filter: '='
      },
      controller: controller,
      controllerAs: 'ctrl',
      bindToController: true,
      templateUrl: 'app/components/user-fields/filters/kz-userfields-fields-filter.html'
    };
  }

  kzUserFieldsFieldsFilter.$inject = ['FormsService'];

  angular.module('blocks.widgets')
    .directive('kzUserfieldsFieldsFilter', kzUserFieldsFieldsFilter);
})();
