(function() {
  'use strict';

  function AnnouncementController($state, $stateParams, $uibModal, Announcements,
                              AnnouncementsUtils, Utils, Auth, Notify) {
    var ctrl = this;

    ctrl.isNew = _.isUndefined($stateParams.id);
    ctrl.shouldNotSubmit = false;
    ctrl.canUnpublish = false;

    ctrl.announcement = {};

    if ($stateParams.id) {
      Announcements.find($stateParams.id)
        .then(function(data) {
          ctrl.originalAnnouncement = data;
          ctrl.announcement = data;

          Utils.setPageTitle('Edit announcement: ' + ctrl.announcement.title);

          // Can the announcement be unpublished?
          ctrl.canUnpublish = AnnouncementsUtils.isCurrent(data);
          ctrl.loaded = true;
        });
    } else {
      Utils.setPageTitle('New announcement');
      ctrl.loaded = true;
    }

    /**
     * Save the announcement and the audit log.
     * @param  {Object} doc The announcement object.
     * @param  {String} msg The message to show to the user on success.
     * @return {Promise}    A promise of success/failure.
     */
    var saveAnnouncement = function(doc, msg) {
      return Announcements
              .saveAuditLog(doc, ctrl.originalAnnouncement, ctrl.isNew, Auth.currentUser())
              .then(function(doc) {
                return Announcements.save(doc);
              })
              .then(function() {
                Notify.success(msg, 'Success');
                $state.go('epf.announcements.index');
              })
              .catch(Utils.showError);
    };

    /**
     * We need to validate the form before letting the user submit it for saving.
     * @param  {Object} form The form object as it comes from angular.
     * @return {Boolean}     True if there is no error, False otherwise.
     */
    var validateForm = function(form) {
      var errorTitle = '',
          hasError = false;

      if (form.$invalid) {
        hasError = true;
        errorTitle = 'Please ensure all mandatory fields are completed and all errors corrected.';
      }

      if (ctrl.announcement.publishDate > ctrl.announcement.expireDate) {
        hasError = true;
        errorTitle = 'The Publish date cannot be after the Expiry date!';
      }

      if (hasError) {
        Utils.swal({
          title: errorTitle,
          type: 'error'
        });

        return false;
      }

      return !hasError;
    };

    /**
     * Save the form when the user clicks Save.
     * @param  {Object} form The form object, as it comes from angular.
     * @return {void}
     */
    ctrl.save = function(form) {
      form.$setSubmitted();

      if (!validateForm(form)) {
        return;
      }

      // Make sure the announcement has the right fields.
      var announcement = _.assign(
        {},
        ctrl.announcement,
        {
          type: 'announcement',
          _id: ctrl.isNew ? Utils.guid() : ctrl.announcement._id,
          status: ctrl.isNew ? 'active' : ctrl.announcement.status,
          createdDate: Utils.now({ dateOnly: true }),
          organisation: Auth.currentOrganisation()
        }
      );

      if (ctrl.isNew) {
        // Find the order
        Announcements.findOrderIndexForLast()
          .then(function(idx) {
            announcement.order = Utils.getNewOrderIndex(idx);
            saveAnnouncement(announcement, 'Announcement successfully saved!');
          })
          .catch(function(err) {
            // Save anyway, but log the error
            console.log('Could not get the last index order!', err);
            saveAnnouncement(announcement, 'Announcement successfully saved!');
          });
      } else {
        saveAnnouncement(announcement, 'Announcement successfully saved!');
      }
    };

    /**
     * Remove an announcement.
     * @return {void}
     */
    ctrl.remove = function() {
      Utils.swal({
        title: 'Are you sure you want to remove this announcement?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      },
      function(isConfirm) {
        if (isConfirm) {
          Announcements.remove($stateParams.id)
            .then(function() {
              _.remove(ctrl.announcements, function(announcement) {
                return announcement._id === $stateParams.id;
              });
              Notify.success('Announcement successfully removed!', 'Success');
              $state.go('epf.announcements.index');
            })
            .catch(Utils.showError);
        }
      });
    };

    /**
     * Unpublish an announcement,
     * meaning we set the status to 'unpublish' instead of 'active'.
     * @return {void}
     */
    ctrl.unpublish = function() {
      Utils.swal({
        title: 'Are you sure you want to unpublish this announcement?',
        text: 'Unpublishing this announcement will make it invisible to users ' +
              'but will not remove it completely from your announcements list.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      },
      function(isConfirm) {
        if (isConfirm) {
          var announcement = _.assign(
            {},
            ctrl.announcement,
            {
              status: 'unpublish'
            }
          );

          saveAnnouncement(announcement, 'Announcement successfully unpublished!');
        }
      });
    };

    /**
     * Publish an announcement,
     * meaning we set the status to 'active' instead of 'unpublish'.
     * @return {void}
     */
    ctrl.publish = function() {
      Utils.swal({
        title: 'Are you sure you want to publish this announcement?',
        text: 'Publishing this announcement will make it visible to users.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      },
      function(isConfirm) {
        if (isConfirm) {
          var announcement = _.assign(
            {},
            ctrl.announcement,
            {
              status: 'active'
            }
          );

          saveAnnouncement(announcement, 'Announcement successfully published!');
        }
      });
    };

    /**
     * Open an announcement Preview modal.
     * @return {void}
     */
    ctrl.preview = function() {
      $uibModal.open({
        animation: true,
        size: 'md',
        templateUrl: 'app/components/announcements/preview.html',
        controller: 'AnnouncementPreviewController',
        controllerAs: 'announcementPreviewCtrl',
        resolve: {
          announcementDoc: function() {
            return ctrl.announcement;
          },
          allowDismiss: function() {
            return false;
          },
          markAsRead: function() {
            return false;
          }
        }
      });
    };
  }

  AnnouncementController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    'AnnouncementsService',
    'AnnouncementsUtils',
    'UtilsService',
    'AuthService',
    'NotifyService'
  ];

  angular.module('component.announcements')
    .controller('AnnouncementController', AnnouncementController);
})();
