(function() {
  'use strict';

  function EventLinkedViewController($scope, Event) {
    var ctrl = this;
    ctrl.event = new Event();
    ctrl.event.init($scope.event);
  }

  EventLinkedViewController.$inject = ['$scope', 'EventFactory'];

  function EventLinkedDirective() {
    return {
      scope: {
        event: '=',
        original: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-linked-view.html',
      replace: true,
      controller: EventLinkedViewController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventLinkedView', EventLinkedDirective)
    .controller('EventLinkedViewController', EventLinkedViewController);
})();
