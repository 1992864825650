(function() {
  'use strict';

  function RegistrationAndApprovalDirective(Form, Roles) {
    return {
      scope: {
        model: '=',
        isFirstSection: '=',
        readOnly: '='
      },
      restrict: 'EA',
      templateUrl: 'app/components/event-types/directives/registration-and-approval.html',
      link: function(scope) {
        function initForm(rolesMap) {
          // Commented out because the code using invitable is also commented out
          // var invitable = false;
          // if (!_.isUndefined(rolesMap[scope.model.roleId])) {
          //   invitable = rolesMap[scope.model.roleId].invitable;
          // }

          function getApprovalOptions() {
            var options = [
              { _id: 'always', key: 'always', name: 'Always' },
              { _id: 'never', key: 'never', name: 'Never' }
            ];

            // if (
            //   scope.model.roleId !== 'system:anonymous-external'
            //   && invitable
            //   && !scope.isFirstSection
            // ) {
            //   options.push({
            //     _id: 'userWithoutAccount',
            //     key: 'userWithoutAccount',
            //     name: 'If user does not have an account'
            //   });
            // }

            return options;
          }

          function getRolesOptions() {
            return _.map(rolesMap, function(role) {
              return role;
            });
          }

          scope.form = new Form([
            // {
            //   id: 'requireAccount',
            //   type: 'discrete',
            //   label: 'Require account?',
            //   required: true,
            //   disabled: scope.readOnly
            //   || scope.isFirstSection
            //   || scope.model.roleId === 'system:anonymous-external'
            //   || !invitable,
            //   options: _.map([true, false], function (opt) {
            //     var name = opt ? 'Yes' : 'No';
            //     return { _id: opt, key: opt, name: name }
            //   })
            // },
            {
              id: 'whenIsApprovalNeeded',
              type: 'discrete',
              label: 'When is approval needed?',
              required: true,
              disabled: scope.readOnly,
              options: getApprovalOptions()
            },
            {
              id: 'rolesThatCanApprove',
              type: 'discrete_multiple',
              label: 'Who can approve the responses?',
              required: true,
              options: getRolesOptions(),
              expressionProperties: {
                hide: function(_$viewValue, _$modelValue, scope) {
                  var value = scope.model.whenIsApprovalNeeded;
                  return value === 'never' || _.isUndefined(value);
                }
              }
            }
          ]);
        }

        Roles.findAll()
          .then(function(roles) {
            var rolesMap = {};
            _.forEach(roles, function(role) {
              rolesMap[role.doc._id] = {
                _id: role.doc._id,
                key: role.doc._id,
                name: role.doc.title,
                invitable: role.doc.invitable
              };
            });

            initForm(rolesMap);
          });
      }
    };
  }

  RegistrationAndApprovalDirective.$inject = ['FormsService', 'RolesService'];

  angular.module('component.eventTypes')
    .directive('registrationAndApproval', RegistrationAndApprovalDirective);
})();
