(function() {
  'use strict';

  function BaseStoreFactory($q, $log, $timeout, $rootScope, Cache, Database, Network,
                            LocalStore, Utils, DOC_TYPES) {
    var typesToIgnore = ['event', 'eventType', 'eventSection', 'eventExtra', 'goal'];
    var Service = function() {
      this.stores = {};
      this.dirty = {};
      this.loadings = {};
      this.lastUpdated = {};
      this.by_type_view = '';
      this.by_id_view = '';
      var _this = this;

      $log.debug('Store: Setting up listener');
      $rootScope.$on('DBPullChange', function(_evt, args) {
        _this.updateFromChanges(args);
      });

      var saveStores = function() {
        return _this.flushStores()
          .finally(function() {
            $timeout(saveStores, 5000);
          });
      };

      $timeout(saveStores, 5000);
    };

    Service.prototype.invalidate = function() {
      this.stores = {};
      this.loadings = {};
      this.dirst = {};
      this.lastUpdated = {};
      this.idbError = undefined;
      this._db = undefined;
    };

    Service.prototype.db = function() {
      if (this._db === undefined) {
        this._db = Database.get('master');
      }

      return this._db;
    };

    Service.prototype.ddoc = function() {
      throw { message: 'Implement ddoc function' };
    };

    // Called when store was updated
    Service.prototype.storeUpdated = function(_type) {};

    Service.prototype.get = function(type, id, kzOpts) {
      if (id === undefined) {
        return $q.reject({ status: 400, message: 'Trying to get ' + type + ' with undefined id' });
      }
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: type + '-' + id + '-get',
        maxAge: 0,
        cached: true
      }, kzOpts);
      var func = this._get_inner.bind(this, type, id, kzOpts);

      return Cache.cachedPromise(func, ext);
    };

    function getWinner(locDoc, remDoc) {
      if (remDoc === undefined) {
        return locDoc;
      }

      if (locDoc === undefined) {
        return remDoc;
      }

      var lcrev = Utils.getMainRev(locDoc.doc._rev);
      var rmrev = Utils.getMainRev(remDoc.doc._rev);

      if (lcrev >= rmrev) {
        return locDoc;
      }

      // We can actually safe remove local one now as it is pushed
      // and there is a newer rev online
      // Do not wait for it
      // LocalStore.safeRemove(locDoc.doc);
      return remDoc;
    }


    Service.prototype._get_inner = function(type, id, kzOpts) {
      var _this = this;
      kzOpts = kzOpts || {};
      var cache = kzOpts.cache || 'revalidate';

      function getNotModified() {
        var promise;
        if (cache === 'revalidate') {
          promise = _this._update(type, id);
        } else {
          promise = $q.when();
        }

        return promise
          .then(function() {
            return _this._getStoreFor(type);
          })
          .then(function(store) {
            var item = store[id];
            if (item === undefined) {
              // Retry if we were in cache
              if (cache === 'cached') {
                return _this._get_inner(type, id);
              }
              return $q.reject(
                { status: 404, message: 'Item could not be found: ' + type + ' - ' + id }
              );
            }
            return angular.copy(item.doc);
          })
          .catch(function(error) {
            $log.error('Could not retrieve item ' + id, error);
            return $q.reject(error);
          });
      }


      // First try to get from local store as local version always
      // takes precedence
      return LocalStore.get(id)
        .then(function(item) {
          if (item !== undefined) {
            if (!item.pushed) {
              return item.doc;
            }

            return getNotModified()
              .then(function(rem) {
                return getWinner(item, { doc: rem }).doc;
              });
          }

          return getNotModified();
        });
    };

    Service.prototype.injectKeys = function(options) {
      return options;
    };

    Service.prototype.query = function(view, options, _kzOpts) {
      // kzOpts = kzOpts || {};
      $log.warn('This is using query - it might not be available when offline!!!!', view, options);
      options = this.injectKeys(options);
      return this.db().query(this.ddoc(view), options);
    };

    Service.prototype.getOneOf = function(type, kzOpts) {
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: type + '-oneoff',
        maxAge: 5 * 1000,
        cached: true
      }, kzOpts);
      var func = this._getOneOf.bind(this, type, kzOpts);

      return Cache.cachedPromise(func, ext);
    };

    Service.prototype.findAll = function(type, kzOpts) {
      if (type === undefined) {
        throw { message: 'Cannot find all without a type' };
      }
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: type + '-findall',
        maxAge: 5 * 1000,
        cached: true
      }, kzOpts);
      var func = this._findAll.bind(this, type, kzOpts);

      return Cache.cachedPromise(func, ext);
    };

    Service.prototype.findKeys = function(type, keys, kzOpts) {
      return this.findAll(type, kzOpts)
        .then(function(data) {
          return _.filter(data, function(item) {
            return keys.indexOf(item.doc._id) !== -1;
          });
        });
    };

    Service.prototype._getOneOf = function(type, kzOpts) {
      kzOpts = kzOpts || {};
      return this._findAll(type)
        .then(function(res) {
          if (!res.length) {
            if (kzOpts.allowEmpty) {
              return {};
            }

            return $q.reject({ status: 404, message: 'Object not found' });
          }

          if (res.length !== 1) {
            $log.warn('Multiple objects found. Using the first one');
          }

          return res[0].doc;
        });
    };

    Service.prototype._findAll = function(type) {
      // $log.warn('Finding all', type);
      var _this = this;
      var promise = _this._updateAll(type)
        .then(function() {
          return $q.all([
            _this._getStoreFor(type),
            LocalStore.findAll(type)
          ]);
        })

        .then(function(result) {
          var store = _.clone(result[0]);
          var locstore = result[1];
          _.forEach(locstore, function(item) {
            var orig = store[item.doc._id];
            store[item.doc._id] = getWinner(item, orig);
          });
          return _.values(store);
        });

      return promise;
    };

    Service.prototype._update = function(type, id) {
      var _this = this;
      if (Network.isOffline()) {
        return $q.when();
      }

      var promise = _this._getStoreFor(type)
        .then(function(store) {
          var ldoc = store[id];
          if (ldoc === undefined) {
            return _this._get(type, id);
          }

          return _this._findStub(id)
            .then(function(item) {
              if (ldoc.doc._rev !== item.value) {
                return _this._get(type, id);
              }
            });
        })

        .then(function(doc) {
          if (doc !== undefined) {
            return _this._updateStore(type, { toAdd: [doc] });
          }
        })
        .catch(function(err) {
          // Ignore offline
          if (err && err.status === 509) {
            return;
          }

          // Ignore if we cannot reach the server
          if (err && (err.status === 0 || err.notreachable)) {
            $log.warn('Cannot connect to server', err);
            return;
          }

          return $q.reject(err);
        });

      return promise;
    };

    Service.prototype._updateAll = function(type) {
      var _this = this;
      if (Network.isNetworkOffline()) {
        return $q.when();
      }

      if (typesToIgnore.indexOf(type) !== -1) {
        $log.warn('Trying to load all', type);
        return $q.when();
      }

      // Get what is stored in memory
      var local;
      var promise = _this._getStoreFor(type)
        // Get list from server for revalidation
        .then(function(store) {
          local = store;
          if (_.isEmpty(local)) {
            // Notify.success('Re-fetching all ' + type);
            // $log.warn('Re-fetching all ' + type);
            return _this._findAllFull(type)
              .then(function(items) {
                return [[], items];
              });
          }

          return _this._findAllStub(type)
          // Find docs to update
            .then(function(items) {
              var toUpdate = [];
              _.forEach(items, function(item) {
                var lc = local[item.id];
                if (lc === undefined) {
                  toUpdate.push(item.id);
                } else if (lc.doc._rev !== item.value) {
                  toUpdate.push(item.id);
                }
              });

              // Remove no longer available from local
              var ids = _.map(items, 'id');
              var lids = _.keys(local);
              var toRemove = _.difference(lids, ids);
              // $log.debug('Found to remove', toRemove.length, 'for', type);
              // $log.debug('Found to update', toUpdate.length, 'for', type);

              if (toUpdate.length === 0 && toRemove.length === 0) {
                return [];
              }

              var updatePromise;
              if (toUpdate.length > 50) {
                updatePromise = _this._findAllFull(type);
              } else {
                updatePromise = _this._findKeys(toUpdate);
              }

              return $q.all([toRemove, updatePromise]);
            });
        })

        // Update store with changed docs
        .then(function(res) {
          if (res.length > 0) {
            return _this._updateStore(type, { toAdd: res[1], toRemove: res[0] });
          }
        })

        .then(function(res) {
          _this.lastUpdated[type] = new Date();
          return res;
        })

        .catch(function(err) {
          // Ignore offline
          if (err && err.status === 509) {
            return;
          }

          // Ignore if we cannot reach the server
          if (err && (err.status === 0 || err.notreachable || err.message === 'Failed to fetch')) {
            $log.warn('Cannot connect to server', err);
            return;
          }

          return $q.reject(err);
        });

      return promise;
    };

    Service.prototype._updateKeys = function(type, keys) {
      var _this = this;
      // Get what is stored in memory
      var local;
      var promise = _this._getStoreFor(type)
        // Get list from server for revalidation
        .then(function(store) {
          local = store;
          return _this._findKeysStub(type);
        })

        // Find docs to update
        .then(function(items) {
          var toUpdate = [];
          _.forEach(items, function(item) {
            var lc = local[item.id];
            if (lc === undefined) {
              toUpdate.push(item.id);
            } else if (lc.doc._rev !== item.value) {
              toUpdate.push(item.id);
            }
          });

          // Remove no longer available from local
          // We got specified keys so we are intersted which of the specified ones
          // are missing
          var ids = _.map(items, 'id');
          var toRemove = _.difference(keys, ids);
          $log.debug('Found to remove', toRemove.length, 'for', type);
          $log.debug('Found to update', toUpdate.length, 'for', type);

          if (toUpdate.length === 0 && toRemove.length === 0) {
            return [];
          }

          return $q.all([toRemove, _this._findKeys(toUpdate)]);
        })

        // Update store with changed docs
        .then(function(res) {
          if (res.length > 0) {
            return _this._updateStore(type, { toAdd: res[1], toRemove: res[0] });
          }
        });

      return promise;
    };

    Service.prototype._findAllStub = function(type) {
      var _this = this;
      var options = {
        key: type
      };
      options = _this.injectKeys(options);
      // $log.warn('Store: Fetching all stub', type);
      return this.db().query(_this.ddoc(this.by_type_view), options)
        .then(function(data) {
          return data.rows;
        });
    };

    Service.prototype._findAllFull = function(type) {
      var _this = this;
      var options = {
        key: type,
        include_docs: true
      };
      options = _this.injectKeys(options);
      // $log.warn('Store: Fetching all docs for', type);
      return this.db().query(_this.ddoc(this.by_type_view), options)
        .then(function(data) {
          return _.map(data.rows, function(item) {
            return {
              id: item.doc._id,
              doc: item.doc,
              source: 'couchdb'
            };
          });
        });
    };

    Service.prototype._findStub = function(id) {
      var options = {
        key: id
      };
      options = this.injectKeys(options);
      // $log.warn('Store: Fetching stub', id);
      return this.db().query(this.ddoc(this.by_id_view), options)
        .then(function(data) {
          if (!data.rows.length) {
            return $q.reject({ status: 404, message: 'Object not found' });
          }

          if (data.rows.length !== 1) {
            $log.warn('Multiple objects found. Using the first one');
          }

          var item = data.rows[0];
          return item;
        });
    };

    Service.prototype.storeItems = function(type, docs) {
      var objs = _.map(docs, function(doc) {
        if (doc.type !== type) {
          return;
        }
        return {
          id: doc._id,
          doc: doc,
          source: 'manual'
        };
      });
      var filtered = _.filter(objs, function(obj) {
        return obj !== undefined;
      });
      return this._updateStore(type, { toAdd: filtered });
    };

    Service.prototype._findKeys = function(keys) {
      $log.debug('Getting docs for', keys);
      var options = {
        keys: keys,
        include_docs: true
      };
      options = this.injectKeys(options);
      return this.db().query(this.ddoc(this.by_id_view), options)
        .then(function(data) {
          return _.map(data.rows, function(item) {
            return {
              id: item.doc.id,
              doc: item.doc,
              source: 'couchdb'
            };
          });
        });
    };

    Service.prototype._findKeysStub = function(keys) {
      $log.debug('Getting docs for', keys);
      var options = {
        keys: keys
      };
      options = this.injectKeys(options);
      return this.db().query(this.ddoc(this.by_id_view), options)
        .then(function(data) {
          return data.rows;
        });
    };

    Service.prototype._get = function(type, id) {
      $log.debug('Store: Getting one', type, id);
      return this._findKeys([id])
        .then(function(data) {
          if (!data.length) {
            return $q.reject({ status: 404, message: 'Object not found' });
          }

          if (data.length !== 1) {
            $log.warn('Multiple objects found. Using the first one');
          }

          var doc = data[0];

          if (doc.doc.type !== type) {
            $log.error('Invalid type. Found', doc.doc.type, 'instead of', type);
            return $q.reject({ status: 404, message: 'Object not found' });
          }

          return doc;
        });
    };

    Service.prototype._getStoreFor = function(type, options) {
      options = options || {};
      if (this.stores[type] !== undefined) {
        return $q.when(this.stores[type]);
      }

      var ext = _.assignIn({
        key: 'store-' + type,
        maxAge: 0,
        cached: true
      }, options);
      var func = this.__getStoreFor.bind(this, type);

      return Cache.cachedPromise(func, ext);
    };

    Service.prototype.__getRawStore = function(type) {
      return Cache.idbGet('store-' + type);
    };

    Service.prototype.__getStoreFor = function(type) {
      // Get what is stored in memory
      var defer = $q.defer();
      var _this = this;
      var func = function() {
        _this.loadings[type] = true;

        // $log.info('Loading store for', type);
        return _this.__getRawStore(type)
          .then(function(data) {
            $log.info('Loaded store for', type, Object.keys(data.store).length);
            // _this.stores[type] = JSON.parse(LZString.decompress(data.store));
            _this.stores[type] = data.store;
            if (_this.lastUpdated[type] === undefined) {
              _this.lastUpdated[type] = data.lastUpdated;
            }
            return _this.stores[type];
          })
          .catch(function(err) {
            $log.warn('Could not get idb cache for', type, err);
            _this.stores[type] = {};
            _this.dirty[type] = true;
            return _this.stores[type];
          });
      };

      var check = function() {
        $log.warn('Load: checking for', type);
        if (_this.loadings[type] || _this.stores[type] === undefined) {
          $timeout(check, 0.2);
        } else {
          if (_this.stores[type] === undefined) {
            $log.error('How come stores is undefined?', type);
          }

          defer.resolve(_this.stores[type]);
        }
      };

      if (_this.stores[type] !== undefined) {
        defer.resolve(_this.stores[type]);
      } else if (this.loadings[type] === undefined) {
        func().then(function(data) {
          _this.loadings[type] = undefined;
          defer.resolve(data);
        });
      } else {
        check();
      }

      return defer.promise;
    };

    Service.prototype._updateStore = function(type, data) {
      var _this = this;

      return this._getStoreFor(type)
        .then(function(store) {
          var changed = false;
          if (_.isArray(data.toAdd)) {
            _.forEach(data.toAdd, function(doc) {
              if (doc.source !== 'couchdb' && doc.source !== 'manual') {
                // The debugger here is for me to know whether there are
                // other cases we might be missing
                $log.warn('This source is not allowed', doc);
              }
              changed = true;
              var cur = store[doc.doc._id];
              // If we have a locally modified copy, refuse to merge with couchdb
              // until resolved
              if (cur !== undefined) {
                _.assignIn(cur, doc);
              } else {
                store[doc.doc._id] = doc;
              }
            });
          }

          if (_.isArray(data.toRemove)) {
            _.forEach(data.toRemove, function(lid) {
              changed = true;
              delete store[lid];
            });
          }

          if (!changed) {
            return;
          }

          _this.storeUpdated(type);
          _this.dirty[type] = true;
        });
    };

    Service.prototype._saveStore = function(type) {
      var store = this.stores[type];
      if (store === undefined) {
        return;
      }
      var _this = this;

      var doc = {
        _id: 'store-' + type,
        // store: LZString.compress(JSON.stringify(store))
        store: store,
        lastUpdated: _this.lastUpdated[type]
      };

      $log.debug('Saving store for', type);
      return Cache.idbPut('store-' + type, doc)
        .then(function() {
          $log.debug('Saved store for', type);
          _this.dirty[type] = false;
        })
        .catch(function(err) {
          $log.warn('Could not put to idb cache', type, err);
          _this.idbError = err;
        });
    };

    Service.prototype.save = function(type, doc) {
      var _this = this;
      if (doc.type !== type) {
        return $q.reject({
          status: 500, message: 'Expected ' + type + ' but received ' + doc.type
        });
      }

      // var store;
      return LocalStore.verify(doc)
        .then(function() {
          return _this._getStoreFor(type);
        })
        .then(function(_res) {
          // store = res;
          return _this.db().put(doc);
        })
        // We can try and do anti-conflict resolution
        // this works only if the item was previously saved locally (kzPrevHash exists)
        // if not it won't work anyway
        .catch(function(err) {
          if (doc.kzHash && err && err.status === 409) {
            return _this.db().get(doc._id)
              .then(function(rm) {
                // We need the kzHash to be still valid on remote - this means no other
                // edits outside the hash and the hash persisted
                if (rm.kzHash === Utils.hashDoc(rm) && rm.kzHash === doc.kzHash) {
                  doc._rev = rm._rev;
                  return _this.db().put(doc);
                }

                return $q.reject(err);
              });
          }
          return $q.reject(err);
        })
        .then(function(res) {
          // FIXME - remove from idb
          doc._rev = res.rev;

          // Now we saved, we can remove from idb
          return $q.all([
            LocalStore.safeRemove(doc),
            {
              id: doc._id,
              doc: doc,
              source: 'couchdb'
            }
          ]);
        })
        .then(function(result) {
          var item = result[1];
          var data = {};
          if (item.doc._deleted) {
            data = { toRemove: [item] };
          } else {
            data = { toAdd: [item] };
          }

          return _this._updateStore(type, data);
        })
        .catch(function(err) {
          // If offline, update store
          if (err && (err.status === 509 || err.notreachable)) {
            // FIXME - How do we check for conflicts
            return LocalStore.put(doc);
          }

          return $q.reject(err);
        })
        .then(function() {
          $rootScope.$broadcast('ResetChangesTimeout');
          $rootScope.$broadcast('StoreUpdated', { doc: doc });
          return doc;
        });
    };

    Service.prototype.remove = function(type, id) {
      var _this = this;
      return this.get(type, id)
        .then(function(doc) {
          doc._deleted = true;
          return _this.save(type, doc);
        })
        .catch(function(err) {
          $log.warn('Could not remove', type, id);
          return $q.reject(err);
        });
    };

    Service.prototype.updateFromChanges = function(args) {
      var doc = args.doc;
      var type = doc.type;

      // Get store directly - if it is not set up it is ok to skip
      var store = this.stores[type];
      if (store === undefined) {
        return;
      }

      var orig = store[doc._id];
      var updateObj = {};
      // Ignore if we already have the new req
      if (orig !== undefined && orig._rev === doc._rev) {
        return;
      }

      $log.debug('Storing', doc.type, doc._id, doc._rev);
      if (doc._deleted) {
        updateObj = { toRemove: [{ doc: doc }] };
      } else {
        updateObj = { toAdd: [{
          id: doc._id,
          doc: doc,
          source: 'couchdb'
        }] };
      }

      return this._updateStore(doc.type, updateObj)
        .then(function() {
          $rootScope.$broadcast('StoreUpdated', { doc: args.doc });
        });
    };

    function toMB(value) {
      return Math.round((value / (1024 * 1024)) * 100) / 100;
    }

    Service.prototype.getStoredSize = function(type) {
      return this.__getRawStore(type)
        .then(function(data) {
          return Object.keys(data.store).length;
        })
        .catch(function() {
          return 0;
        });
    };

    Service.prototype.getStored = function() {
      var _this = this;
      var item = {};
      item.storeId = this.id;
      item.idbError = this.idbError;
      item.stores = [];
      var types = DOC_TYPES[this.id];

      var promises = _.map(types, function(type) {
        return _this.getStoredSize(type);
      });
      return $q.all(promises)
        .then(function(result) {
          item.stores = _.map(result, function(item) {
            return { size: item };
          });
          return item;
        });
    };

    Service.prototype.inspectType = function(type) {
      var items = this.stores[type] || [];
      var json = JSON.stringify(items);

      return {
        id: type,
        size: Object.keys(items).length,
        source: _.countBy(items, 'source'),
        byteSize: toMB(json.length),
        // compressedSize: toMB(LZString.compress(json).length),
        dirty: !this.dirty[type],
        lastUpdated: this.lastUpdated[type]
      };
    };

    Service.prototype.inspect = function() {
      var _this = this;
      var item = {};
      item.storeId = this.id;
      item.idbError = this.idbError;
      item.stores = [];
      _.forEach(_.keys(this.stores), function(type) {
        item.stores.push(_this.inspectType(type));
      });
      item.stores = _.sortBy(item.stores, 'byteSize').reverse();
      return item;
    };

    Service.prototype.updateTypes = function(options) {
      var types = DOC_TYPES[this.id];
      if (types === undefined) {
        throw new Error({ status: 500, message: 'Store with unknown id' });
      }

      // Hack db
      var origDb = this._db;
      this._db = Database.get('master', undefined, { raw: true });

      var _this = this;
      var promises = _.map(types, function(item) {
        return _this._updateAll(item, options);
      });
      return $q.all(promises)
        .finally(function() {
          _this._db = origDb;
        });
    };

    Service.prototype.loadTypes = function(options) {
      var types = DOC_TYPES[this.id];
      if (types === undefined) {
        throw new Error({ status: 500, message: 'Store with unknown id' });
      }

      var _this = this;
      var promises = _.map(types, function(item) {
        return _this._getStoreFor(item, options);
      });
      return $q.all(promises);
    };

    Service.prototype.flushStores = function() {
      if (this.idbError) {
        console.log('Skipping saving store due to error');
        return $q.when();
      }

      var _this = this;
      return Database.allowLocalDb()
        .then(function() {
          var promises = [];
          _.forOwn(_this.dirty, function(value, type) {
            if (value) {
              promises.push(_this._saveStore(type));
            }
          });

          return $q.all(promises);
        });
    };


    Service.prototype.clearTypes = function() {
      var types = DOC_TYPES[this.id];
      if (types === undefined) {
        throw new Error({ status: 500, message: 'Store with unknown id' });
      }

      var _this = this;
      _.forOwn(types, function(type) {
        _this.stores[type] = {};
        _this.dirty[type] = true;
      });
    };

    return Service;
  }

  BaseStoreFactory.$inject = [
    '$q',
    '$log',
    '$timeout',
    '$rootScope',
    'CacheService',
    'DatabaseService',
    'NetworkService',
    'LocalStoreService',
    'UtilsService',
    'DOC_TYPES'
  ];

  angular.module('blocks.stores')
    .factory('BaseStoreFactory', BaseStoreFactory);
})();
