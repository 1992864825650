(function() {
  'use strict';

  function DatePickerDirective($timeout) {
    function link(scope, element) {
      var calendar;

      angular.element(element).bind('click touchend focusin', function(event) {
        event.preventDefault();
        calendar = rome(element[0], scope.options);
        calendar.on('data', function(value) {
          $timeout(function() {
            scope.ngModel = value;
          });
        });
      });
    }

    return {
      require: ['^form', '^ngModel'],
      restrict: 'EA',
      scope: {
        options: '=',
        ngModel: '='
      },
      link: link
    };
  }

  DatePickerDirective.$inject = ['$timeout'];

  angular.module('widgets.forms')
    .directive('datePicker', DatePickerDirective);
})();
