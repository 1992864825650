(function() {
  'use strict';

  /**
   *
   *  This is a cross regions service, mainly providing organisations info
   *
   */

  // var mockOrgs = [
  //   {
  //     id: 'org_fry',
  //     name: 'Fry',
  //     urls: {
  //       lapi: 'https://lapi.eas:8443',
  //       ldb: 'https://ldb.eas:8443',
  //       api: 'https://api.eas:8443'
  //     }
  //   },
  //   {
  //     id: 'org_rcpch',
  //     name: 'RCPCH',
  //     urls: {
  //       lapi: 'https://lapi2.eas:8443',
  //       ldb: 'https://ldb2.eas:8443',
  //       api: 'https://api2.eas:8443'
  //     }
  //   }
  // ];

  function ClusterService($q, $log, $http, $rootScope, OidcService, kzLocalStorage, SERVER_CONFIG) {
    var service = {};
    service._organisations = {};
    service._my_organisations = {};

    service.call = function(method, path, data) {
      var url = _.trimEnd(SERVER_CONFIG.urls.org, '/') + path;
      return $http({
        method: method,
        url: url,
        params: (method === 'GET') ? data : {},
        data: (method !== 'GET') ? data : {},
        withCredentials: true,
        cache: false,
        headers: {
          Authorization: OidcService.bearer,
          'EAS-Organisation': OidcService.organisation,
          'EAS-Username': OidcService.usernameHeader
        }
      }).then(function(resp) {
        return resp.data;
      }).catch(function(error) {
        if (error && error.status === 0) {
          $log.warn('Rest API: No response from server');
          return $q.reject(
            { status: 0, message: 'Cannot reach the server. Your connection is down.' }
          );
        }
        var status = error.status || 0;
        var errText;
        var errDetail;

        if (_.isEmpty(error.data)) {
          errText = 'Cannot reach the server';
        } else if (_.isString(error.data)) {
          errText = error.data;
          errDetail = error.data.details;
        } else {
          errText = error.data.message;
          errDetail = error.data.details;
        }

        return $q.reject({ status: status, message: errText, details: errDetail });
      });
    };

    service.getOrganisation = function(orgId) {
      if (service._organisations.length === 0) {
        throw new Error('Cluster: Profile organisations are not loaded');
      }

      var org = service._organisations[orgId];
      if (!org) {
        throw new Error('Cluster: Organisation not found');
      }
      return org;
    };

    service.getUrls = function(orgId) {
      var org = service.getOrganisation(orgId);
      return org.urls;
    };

    service.getCurrentOrganisation = function() {
      var org = OidcService.organisation;
      return service.getOrganisation(org);
    };

    service.getOrganisationsCount = function() {
      return _.size(service._organisations);
    };

    service.getOrganisations = function() {
      return angular.copy(service._organisations);
    };

    service.getCurrentUrls = function() {
      var org = service.getCurrentOrganisation();
      return org.urls;
    };

    service._getProfileOrgKey = function() {
      return OidcService.usernameHeader + '_profile_organisations';
    };

    /**
     * Load all organisations of current profile
     *
     * This should be the first call when user is loaded
     */
    service.loadProfileOrganisations = function(opts) {
      return service.getProfileOrganisations(opts)
        .then(function(orgs) {
          var key = service._getProfileOrgKey();
          if (orgs.ok) {
            kzLocalStorage.setItem(key, orgs);
          } else {
            // If it is not ok, lets try to use the cached one
            // if it exists, if not just stick to the uncomplete one
            var lsOrgs = kzLocalStorage.getItem(key);
            if (lsOrgs.orgs !== undefined) {
              orgs = lsOrgs;
            }
          }
          return orgs.orgs;
        })
        .catch(function(err) {
          console.log(
            'ClusterService: Could not load profile organisations. Looking for cache',
            err
          );
          var key = service._getProfileOrgKey();
          var orgs = kzLocalStorage.getItem(key).orgs;
          if (orgs === undefined) {
            console.log('ClusterService: Orgs not found in cache');
            orgs = [];
          }
          return orgs;
        })
        .then(function(orgs) {
          $log.info('ClusterService: Loaded organisations', orgs.length);
          service._organisations = {};
          _.forEach(orgs, function(org) {
            service._organisations[org.id] = org;
          });
          $rootScope.$broadcast('OrganisationProfilesLoaded');
        });
    };

    service.getProfileOrganisations = function(opts) {
      var options = opts || {};
      var _this = this;
      var key = service._getProfileOrgKey();
      if (!options.force && this._my_organisations[key]) {
        return $q.when(this._my_organisations[key]);
      }

      console.log('ClusterService: Fetching profile\'s organisations');
      return this.call('GET', '/my_organisations')
        .then(function(orgs) {
          _this._my_organisations[key] = orgs;
          return orgs;
        });
    };

    service.getAllOrganisations = function() {
      return this.call('GET', '/all_organisations')
        .then(function(data) {
          return data.orgs;
        })
        .catch(function(err) {
          console.log(err);
          return [];
        });
      /* mock */
      // var def = $q.defer();
      // setTimeout(function() {
      //   def.resolve(mockOrgs);
      // }, 100);
      // return def.promise;
    };

    service.ensureOrganisation = function() {
      try {
        service.getCurrentOrganisation();
      } catch (err) {
        $log.error(err);
        return $q.reject({ status: 0, message: 'No organisations loaded' });
      }
      return $q.when();
    };

    return service;
  }

  ClusterService.$inject = [
    '$q',
    '$log',
    '$http',
    '$rootScope',
    'OidcService',
    'kzLocalStorage',
    'SERVER_CONFIG'
  ];

  angular.module('component.organisations')
    .factory('ClusterService', ClusterService);
})();
