(function() {
  'use strict';

  function GoalPreviewController(
    item,
    $q,
    $scope,
    $uibModalInstance,
    $state,
    Goal,
    Profile,
    List,
    Utils,
    Network,
    GOALS_STATES,
    LocalizationService
  ) {
    var ctrl = this;

    ctrl.GOALS_STATES = GOALS_STATES;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    ctrl.prefs = Profile.getPreferences();
    ctrl.options = {};
    ctrl.action = 'work';

    function hasPerm(action) {
      var permission;

      if (action === 'view') {
        permission = 'canViewAny';
      }

      if (!permission) {
        return $q.reject({ status: 403 });
      }

      return ctrl.goal.checkPermission(permission);
    }

    function buildEventHref() {
      var route = 'epf.events.view';
      var args = { id: ctrl.goal.event.doc._id };
      if (!ctrl.goal.isMine()) {
        route = 'epf.users.events-view';
        args.user = ctrl.goal.event.doc.user;
      }

      ctrl.eventHref = $state.href(route, args);
    }

    var load;

    ctrl.list = new List({ idField: '_id' });
    ctrl.options = { trackBy: '_id' };

    load = function() {
      return Goal.init(item, { loadEvent: true, loadGoalSetSummary: true })
        .then(function(goal) {
          if (!goal) {
            return $q.reject();
          }

          ctrl.goal = goal;
          ctrl.prefs.goalDoc = ctrl.goal.doc;
        })
        .then(function() {
          return hasPerm('view');
        })
        .then(function() {
          var canMark = ctrl.goal.checkPermission('canMark')
            .catch(function() { return $q.when(false); });

          return $q.all([ctrl.goal.isMarkStepEnabled(), ctrl.goal.isSetClosed(), canMark])
            .then(function(result) {
              ctrl.isMarkStepEnabled = result[0];
              ctrl.isSetClosed = result[1];
              ctrl.canMark = result[2];
            });
        })
        .then(function() {
          if (!_.isEmpty(ctrl.goal.event)) {
            buildEventHref();
          } else {
            ctrl.noEvent = true;
          }

          return $q.when();
        })
        .catch(function(error) {
          Utils.showError(error);
        });
    };

    ctrl.markGoal = function() {
      Network.forceOnline({ showToastr: true })
        .then(function() {
          return ctrl.goal.openResolveDataModal();
        })
        .then(function() {
          ctrl.goal.reloadState();
        });
    };

    load();

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    ctrl.open = function() {
      ctrl.goal.getViewLink()
        .then(function(url) {
          window.open(url, '_blank');
        });
    };

    function selectPeriod(periodId) {
      ctrl.goal.setPeriod(periodId);
    }

    $scope.$on('GoalPeriodSelected', function(_evt, periodFilter) {
      var splitted = periodFilter.split('|'),
          periodId = splitted[1];
      selectPeriod(periodId);
    });
  }

  GoalPreviewController.$inject = [
    'item',
    '$q',
    '$scope',
    '$uibModalInstance',
    '$state',
    'GoalFactory',
    'ProfileService',
    'ListFactory',
    'UtilsService',
    'NetworkService',
    'GOALS_STATES',
    'LocalizationService'
  ];

  angular.module('component.goals')
    .controller('GoalPreviewController', GoalPreviewController);
})();
