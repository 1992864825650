(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.event-types', {
        url: 'event-types',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('eventTypes.view');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.event-types.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/event-types/list.html',
            controller: 'EventTypesController',
            controllerAs: 'eventTypesCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.event-types.new', {
        url: '/new',
        views: {
          content: {
            templateUrl: 'app/components/event-types/form.html',
            controller: 'EventTypeController',
            controllerAs: 'eventTypeCtrl'
          }
        },
        params: {
          doc: null
        },
        data: {
          action: 'new'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('eventTypes.edit');
          }]
        }
      })

      .state('epf.event-types.edit', {
        url: '/edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/event-types/form.html',
            controller: 'EventTypeController',
            controllerAs: 'eventTypeCtrl'
          }
        },
        data: {
          action: 'edit'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.event-types.preview', {
        url: '/preview/:id',
        views: {
          content: {
            templateUrl: 'app/components/event-types/form.preview.html',
            controller: 'EventTypePreviewController',
            controllerAs: 'eventTypePreviewCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.event-types.section', {
        url: '/:eventTypeId/section/:sectionId',
        views: {
          content: {
            templateUrl: 'app/components/event-types/event-type-section.html',
            controller: 'EventTypeSectionController',
            controllerAs: 'sectionCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.event-types.field', {
        url: '/:eventTypeId/section/:sectionId/field/:fieldId',
        views: {
          content: {
            templateUrl: 'app/components/event-types/event-type-section-field.html',
            controller: 'EventTypeFieldController',
            controllerAs: 'fieldCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.event-types.custom-field', {
        url: '/:eventTypeId/section/:sectionId/custom-fields',
        views: {
          content: {
            templateUrl: 'app/components/event-types/event-type-section-predefined-fields.html',
            controller: 'EventTypePredefinedFieldsController',
            controllerAs: 'predefinedFieldsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.eventTypes')
    .config(config);
})();
