(function() {
  'use strict';

  function Revisions(Drafts, EventSection, AutoSave, UAParser,
    AUTOSAVE_EVENTS, LocalizationService) {
    return {
      scope: {
        id: '=',
        info: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/drafts/revisions.html',
      replace: true,
      link: function($scope) {
        $scope.revisions = [];
        $scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
        var loadRevisions = function() {
          return Drafts.findAllForId($scope.id)
            .then(function(data) {
              $scope.revisions = _(data).sortBy('saveInfo.lastSaved').reverse().value();
              $scope.info.revisionCount = data.length;
            });
        };

        var loadCurrent = function() {
          return AutoSave.getObject($scope.info)
            .then(function(model) {
              $scope.current = model;
              $scope.active = _.isEmpty($scope.info.id);
              $scope.saveInfo = $scope.info.getSaveInfo(model) || {};
              $scope.identifier = UAParser.getName($scope.saveInfo.agent);
            });
        };

        loadRevisions();
        loadCurrent();

        $scope.$on(AUTOSAVE_EVENTS.newAutosave, function(_evt, args) {
          if (args.id === $scope.id) {
            loadRevisions();
            loadCurrent();
          }
        });

        $scope.$on('DBPullChange', function(_evt, args) {
          if (args.doc.type === 'autosave') {
            loadRevisions();
          }
        });

        $scope.openPreview = function() {
          if (_.isEmpty($scope.current)) {
            return;
          }

          var doc = $scope.current.doc;
          var ev = new EventSection(doc);
          var actions = [];

          if (!$scope.active) {
            actions.push({
              label: 'Switch to working on this',
              icon: 'icon-edit',
              onClick: $scope.switchToObject.bind(this),
              klass: 'btn-sm btn-success',
              close: true,
              showCondition: function() {
                return !$scope.active;
              }
            });
          }

          ev.init()
            .then(function() {
              ev.openEventPreview({ actions: actions, noFillin: true });
            });
        };

        $scope.switchToObject = function() {
          return AutoSave.switchToObject($scope.info);
        };
      }
    };
  }

  Revisions.$inject = ['DraftsService', 'EventSectionFactory',
    'AutoSaveService', 'UAParser', 'AUTOSAVE_EVENTS', 'LocalizationService'];

  angular.module('component.drafts')
    .directive('kzAutoSaveRevisions', Revisions);
})();
