
(function() {
  'use strict';

  function ReportChartDirective(Form) {
    return {
      scope: {
        report: '=',
        chart: '=',
        editMode: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-chart.html',
      replace: true,
      link: function(scope) {
        if (_.isEmpty(scope.chart.ys)) {
          scope.chart.ys = [
            scope.report.getInitialVerticalAxis()
          ];
        }

        if (scope.chart && scope.chart.showLegend === undefined) {
          scope.chart.showLegend = false;
        }

        var disableAll = !scope.editMode;
        scope.chartForm = new Form(scope.report.getChartFormFields(disableAll));
        scope.horizontalAxisForm = new Form(scope.report.getAxis(disableAll));
        scope.verticalAxisForm = new Form(
          scope.report.getVerticalAxisFormFields(scope.chart, disableAll)
        );

        scope.removeChart = function() {
          _.remove(scope.report.conf.charts, scope.field);
        };

        scope.addVerticalAxis = function() {
          if (_.isUndefined(scope.chart.ys)) {
            scope.chart.ys = [];
          }

          scope.chart.ys.push(scope.report.getInitialVerticalAxis());
        };
      }
    };
  }

  ReportChartDirective.$inject = ['FormsService'];

  angular.module('component.reports')
    .directive('kzReportChart', ReportChartDirective);
})();
