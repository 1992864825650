(function() {
  'use strict';

  function EventsApiService(RestEasApi, EasApi) {
    var service = new RestEasApi('events', { cacheKeys: [], usePersistentCache: true });
    var apiVersion = '';

    service.getEventFiles = function(eventId) {
      return EasApi.get('events/' + eventId + '/files', {}, {}, { version: apiVersion });
    };

    return service;
  }


  EventsApiService.$inject = [
    'RestEasApi',
    'EasApiService'
  ];

  angular.module('component.events')
    .factory('EventsApiService', EventsApiService);
})();
