(function() {
  'use strict';

  function EventTypeGroupFactory($q, Version, EventTypes, Auth, Security, Utils) {
    var EventType = function(group) {
      Version.call(this, group);
      this.type = 'versioned';
      this.service = EventTypes;
    };

    Utils.extends(EventType, Version);

    EventType.prototype.load = function(eid) {
      var _this = this;
      return EventTypes.getGroupByItemId(eid)
        .then(function(group) {
          _this.init(group);
          return _this;
        });
    };

    EventType.prototype.find = EventType.prototype.load;

    EventType.prototype.save = function() {
      return EventTypes.save(this.doc);
    };

    EventType.prototype.isNew = function() {
      return !this.doc._rev;
    };

    EventType.prototype.setInitial = function() {
      if (!this.isNew()) {
        return;
      }

      this.doc.type = 'eventType';
      this.doc.state = 'draft';
      this.doc.defaultVisibility = 'public';
      this.doc.organisation = Auth.currentOrganisation();
      this.doc._id = Utils.guid();

      Utils.recordLog(this.doc, 'created', Auth.currentUser());

      this.doc.versionGroupId = Utils.guid();
      this.linkedVersions = [this.doc];
    };

    /**
     * Validate give section and return a list of errors
     * @param  {String} fieldId
     * @return {Promise} list of errors
     */
    EventType.prototype.validateField = function(sectionId, fieldId) {
      var errors = [];
      var section = _.find(this.doc.sections || [], { _id: sectionId });
      if (section === undefined) {
        errors.push('Section not found');
        return $q.when(errors);
      }

      var field = _.find(section.fields || [], { _id: fieldId });
      if (field === undefined) {
        errors.push('Field not found');
        return $q.when(errors);
      }

      if (field.type === 'goal') {
        if (
          (_.isUndefined(field.customsSettings) || field.customsSettings.canAddCustoms === false)
          && (_.isUndefined(field.definition) || field.definition.length === 0)
        ) {
          var message = 'Goal Field: Please define at least a predefined goal or activate the ' +
            'ability for users to add custom ones';
          errors.push(message);
        }
      }

      return $q.when(errors);
    };

    /**
     * Validate give section and return a list of errors
     * @param  {String} sectionId
     * @return {Promise} list of errors
     */
    EventType.prototype.validateSection = function(sectionId) {
      var section = _.find(this.doc.sections || [], { _id: sectionId });
      if (section === undefined) {
        return $q.when(['Section not found']);
      }

      var fields = section.fields || [];
      var mapping = _.groupBy(fields, function(field) {
        return field.blueprint || field.relation || field._id;
      });

      // Validate restricted by
      var _this = this;
      var errorsFields = _.map(fields, function(field) {
        // Validate field
        return _this.validateField(sectionId, field._id)
          .then(function(fieldErrors) {
            // Validate restricted by exists
            if (field.restricted && field.restricted.by) {
              if (mapping[field.restricted.by] === undefined) {
                var name = field.name;
                if (field.type === 'text') {
                  name = field.content;
                }
                var errorMessage = 'The field "' + name + '" will be shown to a field that does ' +
                  'not exists. Please reset the field "Only show this field when the user fills ' +
                  'in"';
                fieldErrors.push(errorMessage);
              }
            }
            return fieldErrors;
          });
      });

      return $q.all(errorsFields)
        .then(function(errs) {
          return _.reduce(errs, function(pre, post) {
            return pre.concat(post);
          });
        });
    };

    EventType.prototype.validate = function() {
      var _this = this;
      var promise = $q.when();
      var errors = [];

      var sections = this.doc.sections || [];
      if (sections.length === 0) {
        errors.push('Event type does not have any sections');
      }

      _.forEach(sections, function(section, idx) {
        promise = promise.then(_this.validateSection.bind(_this, section._id))
          .then(function(errs) {
            _.forEach(errs, function(err) {
              errors.push('Section ' + (idx + 1) + ': ' + err);
            });
          });
      });

      return promise
        .then(function() {
          return errors;
        });
    };

    EventType.getGoalField = function(sections, sectionId) {
      var section = _.chain(sections)
        .filter(function(section) {
          return section._id === sectionId;
        })
        .first()
        .value();

      // only one goal per section
      return _.chain(section.fields)
        .filter(function(field) {
          return field.type === 'goal';
        })
        .first()
        .value();
    };

    // goal field settings
    EventType.hasGoalCustomsSettingsRoles = function(perm, sections, sectionId, user) {
      var field = this.getGoalField(sections, sectionId);
      return EventType.hasGoalCustomsSettingsPermission(perm, field.customsSettings, user);
    };

    EventType.hasGoalCustomsSettingsPermission = function(perm, customsSettings, user) {
      if (!_.isUndefined(customsSettings)) {
        if (customsSettings[perm] === true) {
          var filledBy = customsSettings.customsInProgressFilledBy || [];
          if (
            (_.indexOf(filledBy, '__all__') > -1) ||
            (Auth.currentUser() === user && _.indexOf(filledBy, 'owner') > -1)
          ) {
            return $q.when(true);
          }

          return Security.hasOwnRoleFor(filledBy, user);
        }
      }

      return $q.when(false);
    };

    EventType.hasMarkGoalAsRoles = function(sections, sectionId, isOwner) {
      var prom;
      var field = this.getGoalField(sections, sectionId);
      var markedBy = field.markedBy || [];
      if (_.indexOf(markedBy, '__all__') > -1) {
        prom = $q.when(true);
      } else if (isOwner) {
        prom = $q.when(_.indexOf(markedBy, 'owner') > -1);
      } else {
        prom = Security.hasRole(markedBy);
      }

      return prom;
    };

    return EventType;
  }

  EventTypeGroupFactory.$inject = [
    '$q',
    'VersionFactory',
    'EventTypesService',
    'AuthService',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.eventTypes')
    .factory('EventTypeGroupFactory', EventTypeGroupFactory);
})();
