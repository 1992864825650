(function() {
  'use strict';

  function CeleryTasksService($q, Api, RestApi) {
    var service = new RestApi('celerytask');

    service.searchIds = function(data) {
      return Api.post('es_celerytasks_ids', data);
    };

    service.fetchIds = function(ids) {
      if (ids.length === 0) {
        return $q.when([]);
      }

      return Api.post('celerytask_ids', { ids: ids });
    };

    return service;
  }

  CeleryTasksService.$inject = ['$q', 'ApiService', 'RestApi'];

  angular.module('blocks.asyncTasks')
    .factory('CeleryTasksService', CeleryTasksService);
})();
