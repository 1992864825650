(function() {
  'use strict';

  function EventActionButtonsController($scope, Event, Utils) {
    var ctrl = this;

    ctrl.displayInline = $scope.display === 'inline';

    ctrl.buttons = [];
    function loadButtons() {
      ctrl.buttons = [];
      Utils.asyncFilter(ctrl.event.actionButtons, function(button) {
        if (button.showCondition === undefined) {
          return true;
        }

        return button.showCondition()
          .then(function() {
            return true;
          })
          .catch(function() {
            return false;
          });
      })
      .then(function(data) {
        ctrl.buttons = data;

        return data.length;
      })
      .then(function(buttonsCount) {
        ctrl.forceButtons = buttonsCount < 3;
      });
    }

    if ($scope.doc !== undefined) {
      ctrl.event = new Event();
      ctrl.event.init($scope.doc);
    } else {
      ctrl.event = $scope.event;
      ctrl.event.init();
    }

    $scope.$watch(function() {
      return ctrl.event.actionButtons;
    }, function() {
      loadButtons();
    });

    $scope.$watch(
      function() {
        return (ctrl.event.doc._rev);
      },
      function() {
        loadButtons();
      }
    );

    ctrl.closeModal = function() {
      if ($scope.modal) {
        $scope.modal.dismiss('cancel');
      }
    };

    ctrl.doAction = function(action) {
      ctrl.closeModal();
      action.onClick();
    };
  }

  EventActionButtonsController.$inject = ['$scope', 'EventFactory', 'UtilsService'];

  function EventActionButtonsDirective() {
    return {
      scope: {
        klass: '@',
        event: '=',
        doc: '=',
        display: '@',
        modal: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-buttons.html',
      replace: true,
      controller: EventActionButtonsController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventActionButtons', EventActionButtonsDirective)
    .controller('EventActionButtonsController', EventActionButtonsController);
})();
