(function() {
  'use strict';

  function EventTypeFactory(
    $q, EventTypeGroupFactory, EventTypes, Auth, Security, Utils, Database
  ) {
    var EventType = function(doc) {
      this.type = 'full';
      this.init(doc);
    };

    EventType.fromFull = function(doc) {
      var eventType = new EventType(doc.doc);
      eventType.linkedVersions = doc.linkedVersions;
      eventType.sectionSummary = doc.sectionSummary;
      return eventType;
    };

    EventType.prototype.init = function(doc) {
      this.doc = doc;
      this.id = doc._id;
      this.linkedVersions = doc.linkedVersions;
    };

    EventType.prototype.getFull = function() {
      // Load all docs, store them and then return the group
      var _this = this;
      return EventTypes.updateVersionGroup(this.doc.versionGroupId)
        .then(function() {
          return EventTypes.getGroupByItemId(_this.id)
            .then(function(group) {
              return new EventTypeGroupFactory(group);
            });
        });
    };

    EventType.prototype.get = function() {
      return EventTypes.getGroupByItemId(this.id)
        .then(function(group) {
          return new EventTypeGroupFactory(group);
        });
    };

    EventType.prototype.save = function() {
      return EventTypes.save(this.doc);
    };

    EventType.prototype.isNew = function() {
      return !this.doc._rev;
    };

    EventType.prototype.hasState = function(state) {
      return this.doc.state === state;
    };

    EventType.prototype.isSystem = function() {
      return this.doc.systemType === 'system';
    };

    EventType.prototype.setInitial = function() {
      if (!this.isNew()) {
        return;
      }

      this.doc.type = 'eventType';
      this.doc.state = 'draft';
      this.doc.defaultVisibility = 'public';
      this.doc.organisation = Auth.currentOrganisation();
      this.doc._id = Utils.guid();

      Utils.recordLog(this.doc, 'created', Auth.currentUser());

      this.doc.versionGroupId = Utils.guid();
    };

    /**
     * Validate give section and return a list of errors
     * @param  {String} fieldId
     * @return {Promise} list of errors
     */
    EventType.prototype.validateField = function(sectionId, fieldId) {
      var errors = [];
      var section = _.find(this.doc.sections || [], { _id: sectionId });
      if (section === undefined) {
        errors.push('Section not found');
        return $q.when(errors);
      }

      var field = _.find(section.fields || [], { _id: fieldId });
      if (field === undefined) {
        errors.push('Field not found');
        return $q.when(errors);
      }

      if (field.type === 'goal') {
        if (
          (_.isUndefined(field.customsSettings) || field.customsSettings.canAddCustoms === false)
          && (_.isUndefined(field.definition) || field.definition.length === 0)
        ) {
          var message = 'Goal Field: Please define at least a predefined goal or activate the ' +
            'ability for users to add custom ones';
          errors.push(message);
        }
      }

      if (field.sizeLimit && field.sizeLimit > 1000) {
        const message = 'Field size limit cannot be more than 1000MB';
        errors.push(message);
      }

      return $q.when(errors);
    };

    /**
     * Validate give section and return a list of errors
     * @param  {String} sectionId
     * @return {Promise} list of errors
     */
    EventType.prototype.validateSection = function(sectionId) {
      var section = _.find(this.doc.sections || [], { _id: sectionId });
      if (section === undefined) {
        return $q.when(['Section not found']);
      }

      var fields = section.fields || [];
      var mapping = _.groupBy(fields, function(field) {
        return field.blueprint || field.relation || field._id;
      });

      // Validate restricted by
      var _this = this;
      var errorsFields = _.map(fields, function(field) {
        // Validate field
        return _this.validateField(sectionId, field._id)
          .then(function(fieldErrors) {
            // Validate restricted by exists
            if (field.restricted && field.restricted.by) {
              if (mapping[field.restricted.by] === undefined) {
                return EventType.getFieldName(field)
                  .then(function(name) {
                    var errorMessage = 'The field "' + name + '" will be shown to a ' +
                      'field that does ' +
                      'not exists. Please reset the field "Only show this field when ' +
                      'the user fills in"';
                    fieldErrors.push(errorMessage);
                    return fieldErrors;
                  });
              }
            }
            return fieldErrors;
          });
      });

      return $q.all(errorsFields)
        .then(function(errs) {
          return _.reduce(errs, function(pre, post) {
            return pre.concat(post);
          });
        })
        .then(function(errors) {
          errors = errors || [];
          if (section.multiSource &&
              section.filledBy &&
              section.filledBy.indexOf('system:timeline-owner') !== -1
          ) {
            errors.push('Timeline owner cannot fill in this section if it is multi source');
          }

          var sectionIdx = _.findIndex(_this.doc.sections || [], { _id: sectionId });
          if (sectionIdx > 1 &&
              section.fillOnSameDevice &&
              section.fillOnSameDevice.enabled &&
              section.fillOnSameDevice.defaultVisibility === 'expanded') {
            var prevSection = _this.doc.sections[sectionIdx - 1];
            if (prevSection.fillOnSameDevice && prevSection.fillOnSameDevice.enabled) {
              errors.push(
                'The default fill in on same device visibility is set as expanded. ' +
                'Please disable fill in on same device within section ' + sectionIdx + '.'
              );
            }
          }
          return errors;
        });
    };

    EventType.prototype.validate = function() {
      var _this = this;
      var promise = $q.when();
      var errors = [];

      var sections = this.doc.sections || [];
      if (sections.length === 0) {
        errors.push('Event type does not have any sections');
      }

      _.forEach(sections, function(section, idx) {
        promise = promise.then(_this.validateSection.bind(_this, section._id))
          .then(function(errs) {
            _.forEach(errs, function(err) {
              errors.push('Section ' + (idx + 1) + ': ' + err);
            });
          });
      });

      return promise
        .then(function() {
          return errors;
        });
    };

    EventType.getFieldName = function(field) {
      var name = field.name;
      if (field.type === 'text') {
        name = field.content;
      } else if (['blueprint', 'relation', 'role', 'report'].indexOf(field.type) !== -1) {
        var db = Database.get('master');
        var fid = field[field.type];
        if (fid) {
          name = db.get(fid)
            .then(function(obj) {
              return obj.title || obj.name;
            })
            .catch(function() {
              return field._id;
            });
        }
      }

      return $q.when(name);
    };

    EventType.getGoalField = function(sections, sectionId) {
      var section = _.chain(sections)
        .filter(function(section) {
          return section._id === sectionId;
        })
        .first()
        .value();

      // only one goal per section
      return _.chain(section.fields)
        .filter(function(field) {
          return field.type === 'goal';
        })
        .first()
        .value();
    };

    // goal field settings
    EventType.hasGoalCustomsSettingsRoles = function(perm, sections, sectionId, user) {
      var field = this.getGoalField(sections, sectionId);
      return EventType.hasGoalCustomsSettingsPermission(perm, field.customsSettings, user);
    };

    EventType.hasGoalCustomsSettingsPermission = function(perm, customSettings, user) {
      if (!_.isUndefined(customSettings)) {
        if (customSettings[perm] === true) {
          var filledBy = customSettings.customsInProgressFilledBy || [];
          if (
            (_.indexOf(filledBy, '__all__') > -1) ||
            (Auth.currentUser() === user && _.indexOf(filledBy, 'owner') > -1)
          ) {
            return $q.when(true);
          }

          return Security.hasOwnRoleFor(filledBy, user);
        }
      }

      return $q.when(false);
    };

    EventType.canMark = function(sections, sectionId, isOwner) {
      var prom;
      var field = this.getGoalField(sections, sectionId);

      var markStep = field.markStep || 'mandatory';
      if (markStep === 'never') {
        return $q.when(false);
      }

      var markedBy = field.markedBy || [];
      if (_.indexOf(markedBy, '__all__') > -1) {
        prom = $q.when(true);
      } else if (isOwner) {
        prom = $q.when(_.indexOf(markedBy, 'owner') > -1);
      } else {
        prom = Security.hasRole(markedBy);
      }

      return prom;
    };

    return EventType;
  }

  EventTypeFactory.$inject = [
    '$q',
    'EventTypeGroupFactory',
    'EventTypesService',
    'AuthService',
    'SecurityService',
    'UtilsService',
    'DatabaseService'
  ];

  angular.module('component.eventTypes')
    .factory('EventTypeFactory', EventTypeFactory);
})();
