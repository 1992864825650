(function() {
  'use strict';

  // Defaults
  var attrsMapping = {
    showAvatar: 'Show Avatar',
    showFullName: 'Show Full Name',
    showViewLink: 'Show View Profile Link',
    showRelations: 'Show relations and role connections',
    showRoles: 'Show Roles',
    showUserDetails: 'Show User Details',
    showAuditLog: 'Show Audit Log'
  };

  var model = [
    { _id: 'showAvatar', order: 0, visible: true },
    { _id: 'showFullName', order: 1, visible: true },
    { _id: 'showViewLink', order: 2, visible: true },
    { _id: 'showRelations',
      order: 3,
      visible: true,
      subOptions: {
        showPeriods: ['__all__'],
        relationsToShow: ['__all__'],
        roleConnectionsToShow: ['__all__']
      }
    },
    { _id: 'showRoles', order: 4, visible: true },
    { _id: 'showUserDetails', order: 5, visible: true },
    { _id: 'showAuditLog', order: 6, visible: true }
  ];

  function ProfileWidgetController(config, Auth, Users) {
    var ctrl = this;

    ctrl.config = config;
    ctrl.options = ctrl.config.options || {};

    // Handle no data in parts
    ctrl.parts = _.filter(ctrl.options.parts || model, function(item) {
      // Remove any that are not in default model so that we can
      // act upon removal. Persistent removal is done the same way in
      // edit
      if (_.find(model, { _id: item._id }) === undefined) {
        return false;
      }
      return item.visible;
    });

    ctrl.user = Users.remoteUser || Auth.currentUser();
    ctrl.remoteUser = Users.remoteUser;

    Users.find(ctrl.user)
      .then(function(profile) {
        ctrl.profile = profile;
      });
  }

  ProfileWidgetController.$inject = [
    'config',
    'AuthService',
    'UsersService'
  ];

  function ProfileWidgetEditController(config, $q, $scope, Form, Relations, Roles) {
    var ctrl = this;
    ctrl.config = config;
    ctrl.attrs = attrsMapping;

    if (config.options === undefined) {
      config.options = {};
    }
    ctrl.options = config.options;

    if (ctrl.options.parts === undefined) {
      ctrl.options.parts = angular.copy(model);
    } else {
      // Add missing if any
      _.forEach(model, function(item) {
        var md = _.find(ctrl.options.parts, { _id: item._id });
        if (md === undefined) {
          ctrl.options.parts.push(item);
        }
      });

      // Remove extra if any
      _.remove(ctrl.options.parts, function(item) {
        return _.find(model, { _id: item._id }) === undefined;
      });
    }

    // Set the visibility data for check boxes
    ctrl.visibleData = {};
    _.forEach(ctrl.options.parts, function(part) {
      ctrl.visibleData[part._id] = part.visible || false;
    });


    ctrl.subForms = {};

    $q.all([Relations.findAll(), Roles.findAllDependent()])
      .then(function(results) {
        var relations = results[0];
        var relationsToShowOptions = [{ _id: '__all__', name: 'All' }];
        _.forEach(relations, function(relation) {
          relationsToShowOptions.push({ _id: relation.doc._id, name: relation.doc.name });
        });

        var dependentRoles = results[1];
        var roleConnectionsToShowOptions = [{ _id: '__all__', name: 'All' }];
        _.forEach(dependentRoles, function(role) {
          roleConnectionsToShowOptions.push({ _id: role.doc._id, name: role.doc.title });
        });

        ctrl.subForms.showRelations = new Form([
          {
            id: 'showPeriods',
            type: 'discrete_multiple',
            label: 'Show periods',
            options: [
              { _id: '__all__', name: 'All Periods' },
              { _id: 'previous', name: 'Previous' },
              { _id: 'current', name: 'Current' },
              { _id: 'future', name: 'Future' }
            ],
            defaultValue: ['__all__']
          },
          {
            id: 'relationsToShow',
            type: 'discrete_multiple',
            label: 'Relations to show',
            options: relationsToShowOptions,
            defaultValue: ['__all__']
          },
          {
            id: 'roleConnectionsToShow',
            type: 'discrete_multiple',
            label: 'Role connections to show',
            options: roleConnectionsToShowOptions,
            defaultValue: ['__all__']
          }]
        );
      });

    // Update the model if visibility data changes
    $scope.$watch(function() {
      return ctrl.visibleData;
    }, function(data) {
      if (data === undefined) {
        return;
      }
      _.forEach(ctrl.options.parts, function(item) {
        item.visible = data[item._id] || false;
      });
    }, true);
  }

  ProfileWidgetEditController.$inject = [
    'config',
    '$q',
    '$scope',
    'FormsService',
    'RelationsService',
    'RolesService'
  ];

  function config(dashboardProvider) {
    dashboardProvider.widget('userProfile', {
      title: 'Profile',
      description: 'Show user profile details',
      controller: 'ProfileWidgetController',
      controllerAs: 'profileWidgetCtrl',
      titleTemplateUrl: 'app/components/dashboard/partials/widget-title.html',
      templateUrl: 'app/components/users/widgets/profile/view.html',
      edit: {
        templateUrl: 'app/components/users/widgets/profile/edit.html',
        controller: 'ProfileWidgetEditController',
        controllerAs: 'profileWidgetEditCtrl'
      },
      category: 'Users'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.users')
    .config(config)
    .controller('ProfileWidgetEditController', ProfileWidgetEditController)
    .controller('ProfileWidgetController', ProfileWidgetController);
})();
