(function() {
  'use strict';

  function ManageTimelinesController(
    $rootScope,
    $log,
    $timeout,
    Utils,
    Form,
    Roles,
    Organisations,
    Notify
  ) {
    var ctrl = this;
    ctrl.newsection = {};
    ctrl.warningModel = {};
    ctrl.forms = {};

    Utils.setPageTitle('Manage timeline categories');

    Organisations.getListOptionsFor('epf.events.index', { cached: false })
      .then(function(data) {
        ctrl.sections = data.sections || [{
          id: 'all',
          name: 'Timeline',
          filter: {}
        }];
      })
      .catch(function(error) {
        $log.warn('Could not load settings', error);
        Notify.error('Could not load settings. Please try again.',
                     'Manage timeline categories');
      });

    ctrl.roles = Roles.findAll()
      .then(function(data) {
        return _.chain(data)
          .map(function(item) {
            return {
              _id: item.doc._id,
              key: item.doc._id,
              name: item.doc.title
            };
          })
          .sortBy('title')
          .value();
      });
    // ctrl.eventTypes = EventTypes.findAllGrouped()
    //   .then(function(data) {
    //     return _.map(data, function(item) {
    //       return {
    //         _id: item.doc.versionGroupId,
    //         key: item.doc.versionGroupId,
    //         name: item.doc.name
    //       };
    //     });
    //   });

    // ctrl.addform = new Form([{
    //   id: 'eventType_versionGroupId',
    //   type: 'discrete_multiple',
    //   helpText: 'Leave blank to set all event types to display in this category',
    //   placeholder: 'Select event type to add to this category',
    //   required: false,
    //   options: ctrl.eventTypes
    // }]);

    // ctrl.addform = new Form([])

    ctrl.basicform = new Form([
      { id: 'name',
        type: 'string',
        label: 'Category title',
        required: true,
        helpText: 'This will be displayed to users in their navigation bar' },
      { id: 'id',
        type: 'string',
        label: 'Short ID (URL)',
        required: true,
        helpText: 'This will be added to the URL to form the unique identifier for this page.' },
      {
        id: 'filters',
        label: 'Select which events should be shown within this category',
        helpText: 'Leave empty to show all available events',
        type: 'filters'
      },
      {
        id: 'allowedRoles',
        type: 'discrete_multiple',
        label: 'To which roles this category should apply',
        helpText: 'Leave empty to apply to all roles',
        placeholder: 'Select roles...',
        required: false,
        options: ctrl.roles
      }
    ]);

    ctrl.add = function() {
      ctrl.sections.push({ uid: Utils.guid() });
    };

    ctrl.addSection = function(form) {
      if (form.$invalid) {
        $log.warn('Invalid form');
        return;
      }

      ctrl.newsection.id = Utils.slugify(ctrl.newsection.id);
      var oldsection = _.find(ctrl.sections, { id: ctrl.newsection.id });
      if (oldsection) {
        Notify.error('The short ID has to be unique, please choose a different one',
                     'Manage timeline categories');
        return;
      }

      ctrl.formChanged();

      var newsection = _.assignIn({}, ctrl.newsection);
      ctrl.sections.push(newsection);
      $timeout(function() {
        var sec = document.getElementById('section-' + newsection.id);
        if (sec !== undefined) {
          sec.focus();
        }
      });
      ctrl.newsection = {};
      form.$setPristine();
      form.$setUntouched();
    };

    ctrl.removeSection = function(section) {
      ctrl.sections = _.without(ctrl.sections, section);
      ctrl.formChanged();
    };

    ctrl.save = function() {
      // Look for duplicates
      var ids = _.map(ctrl.sections || [], function(sec) {
        return sec.id;
      });

      var dupls = _.uniq(_.filter(ids, function(id) {
        return _.filter(ids, function(_id) {
          return _id === id;
        }).length > 1;
      }));

      if (dupls.length > 0) {
        Notify.error('There are duplicate short ids: ' + dupls.join(', '));
        return;
      }

      if (ctrl.wrapperForm.$invalid) {
        ctrl.wrapperForm.$setSubmitted();
        ctrl.wrapperForm.$setDirty();
        _.forOwn(ctrl.forms, function(value) {
          value.$setSubmitted();
        });
        Notify.error('Setting could not be saved because the form is not valid',
                     'Manage timeline categories');
        return;
      }

      return Organisations.saveListOptionsFor('epf.events.index', { sections: ctrl.sections })
        .then(function() {
          Notify.success('Setting has been saved', 'Manage timeline categories');
          $rootScope.$broadcast('KZReloadHeader');
        })
        .catch(function(err) {
          $log.error(err);
          Notify.error('Setting could not be saved due to an error', 'Manage timeline categories');
        });
    };

    ctrl.formChanged = function() {
      ctrl.wrapperForm.$setDirty();
    };
  }

  ManageTimelinesController.$inject = [
    '$rootScope',
    '$log',
    '$timeout',
    'UtilsService',
    'FormsService',
    'RolesService',
    'OrganisationsService',
    'NotifyService'
  ];

  angular.module('component.events')
    .controller('ManageTimelinesController', ManageTimelinesController);
})();
