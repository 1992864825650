(function() {
  'use strict';

  var DB_ERRORS = {
    loggedInButNoAccount: { status: 467, message: 'Logged in but no accounts' },
    organisationsEmpty: { status: 479, message: 'Organisations list is empty' },
    organisationNotSet: { status: 477, message: 'Organisation not set' },
    organisationSetNotInUserInfo: { status: 478, message: 'Organisation not present in user info' },
    sharedDbDoNotExists: { status: 488, message: 'Shared database not present' },
    privateDbDoNotExists: { status: 489, message: 'Private database not present' },
    privateDbNotOnBackend: { status: 490, message: 'Account not found' },
    stateModeNotValid: { status: 500, message: 'State mode not valid' },
    offlineNotAllowed: { status: 501, message: 'Offline mode is not allowed' },
    notAvailableOffline: {
      status: 509,
      message: 'This is not available when working offline',
      data: {
        message: 'This is not available when working offline',
        reason: 'This is not available when working offline'
      }
    },
    UserInfoDoNotExists: {
      status: 500,
      message: 'No user info found',
      changeLocation: true,
      allowLogout: true
    },
    orgConfDbDoNotExists: {
      status: 488,
      message: 'Organisation conf database not present',
      changeLocation: true
    }
  };

  var DB_MODES = {
    online: 'online',
    pendingOnline: 'pendingOnline',
    pendingOffline: 'pendingOffline',
    offline: 'offline'
  };

  angular.module('blocks.db')
    .constant('DB_ERRORS', DB_ERRORS)
    .constant('DB_MODES', DB_MODES);
})();
