(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.blueprints', {
        url: 'blueprints/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('blueprints.view');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.blueprints.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/blueprints/list.html',
            controller: 'BlueprintsController',
            controllerAs: 'blueprintsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.blueprints.new', {
        url: 'new',
        views: {
          content: {
            templateUrl: 'app/components/blueprints/form.html',
            controller: 'BlueprintController',
            controllerAs: 'blueprintCtrl'
          }
        },
        resolve: {
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('blueprints.edit');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        },
        params: {
          defaults: null
        }
      })

      .state('epf.blueprints.edit', {
        url: 'edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/blueprints/form.html',
            controller: 'BlueprintController',
            controllerAs: 'blueprintCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })
      .state('epf.blueprintMaps', {
        url: 'blueprints_maps/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', 'FeaturesService',
            function(_setup, Security, Features) {
              return Security.hasPermission('blueprints.view')
                .then(function() {
                  return Features.isOn('blueprint-maps', { reject: true });
                });
            }
          ],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.blueprintMaps.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/blueprints/blueprintMaps.list.html',
            controller: 'BlueprintMapsController',
            controllerAs: 'blueprintMapsCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })
      .state('epf.blueprintMaps.new', {
        url: 'new',
        views: {
          content: {
            component: 'blueprintMapEdit'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          id: ['$transition$', function($transition$) {
            return $transition$.params().id;
          }],
          action: function() {
            return 'new';
          }
        }
      })
      .state('epf.blueprintMaps.edit', {
        url: 'edit/:id',
        views: {
          content: {
            component: 'blueprintMapEdit'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          id: ['$transition$', function($transition$) {
            return $transition$.params().id;
          }],
          action: function() {
            return 'edit';
          }
        }
      })
      .state('epf.blueprintMaps.view', {
        url: 'view/:id',
        views: {
          content: {
            component: 'blueprintMapView'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          id: ['$transition$', function($transition$) {
            return $transition$.params().id;
          }]
        }
      })
      .state('epf.blueprintMaps.mapping', {
        url: 'mapping/:id',
        views: {
          content: {
            component: 'blueprintMapMapping'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          id: ['$transition$', function($transition$) {
            return $transition$.params().id;
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.blueprints')
    .config(config);
})();
