(function() {
  'use strict';

  function EventTitleDirective($q, $stateParams, Event, Events, EventSection, EventSections) {
    return {
      scope: {
        eventId: '@?',
        event: '=?',
        forUser: '=?',
        highlight: '=?',
        showVersion: '='
      },
      restrict: 'A',
      template: '' +
      '<span data-ng-bind-html="title | kzHighlight: highlight"></span> ' +
      '<span data-ng-if="user">for <span epf-fullname user="user" highlight="highlight">' +
      '</span></span>',
      link: function(scope) {
        var getTitle = function(obj) {
          if (_.isUndefined(obj.doc)) {
            return '';
          }

          if (
            !_.isUndefined(obj.doc.eventOwner) &&
            obj.doc.eventOwner !== obj.doc.user &&
            obj.doc.eventOwner !== $stateParams.user
          ) {
            scope.user = obj.doc.eventOwner;
          }

          if (_.isEmpty(obj.doc.eventType)) {
            return obj.doc.title || 'Quick note';
          }

          if (obj.doc.title) {
            return obj.doc.title;
          }

          // if (scope.showVersion) {
          //   if (obj.doc.type === 'event') {
          //     scope.progress = 'Completed Sections ' +
          //       obj.completedSections.length + ' of ' +
          //       obj.allSections.length;
          //   } else if (obj.doc.type === 'eventSection') {
          //     scope.progress = 'Section ' + (obj.getCurrentIndex() + 1) + ' of ' +
          //       (obj.getNumberOfSections() + 1);
          //   }
          // }
          return obj.eventType.name;
        };

        if (scope.event) {
          if (scope.event.loaded) {
            scope.title = getTitle(scope.event) || 'n/a';
          } else {
            scope.$watch('event.loaded', function() {
              if (scope.event.loaded) {
                scope.title = getTitle(scope.event);
              }
            });
          }
        } else {
          Events.find(scope.eventId)
            .then(function(doc) {
              scope.event = new Event(doc);
              return scope.event.loadEventType();
            })
            .catch(function(err) {
              if (err && err.status === 404) {
                return EventSections.find(scope.eventId)
                  .then(function(doc) {
                    scope.event = new EventSection(doc);
                    return scope.event.loadEventType();
                  });
              }
              return $q.reject(err);
            })
            .then(function() {
              scope.title = getTitle(scope.event);
            })
            .catch(function(error) {
              console.log('Error getting title for event', error);
            });
        }
      }
    };
  }

  EventTitleDirective.$inject = ['$q',
    '$stateParams',
    'EventFactory', 'EventsService',
    'EventSectionFactory', 'EventSectionsService'
  ];

  angular.module('events.directives')
    .directive('kzEventTitle', EventTitleDirective);
})();
