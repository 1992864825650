(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.roles', {
        url: 'roles/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('roles.view');
          }],
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.roles.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/roles/list.html',
            controller: 'RolesController',
            controllerAs: 'rolesCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      })

      .state('epf.roles.new', {
        url: 'new',
        views: {
          content: {
            templateUrl: 'app/components/roles/form.html',
            controller: 'RoleController',
            controllerAs: 'roleCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        },
        params: {
          defaults: null
        }
      })

      .state('epf.roles.edit', {
        url: 'edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/roles/form.html',
            controller: 'RoleController',
            controllerAs: 'roleCtrl'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.roles')
    .config(config);
})();
