angular.module('component.importer')
  .component('importProgressInfo', {
    templateUrl: 'app/components/importer/components/progressInfo.html',
    controller: function() {
      var ctrl = this;

      function updateReadableState(state) {
        var _map = {
          processing: 'is processing',
          success: 'finished',
          failure: 'stopped by the system in the middle of the process',
          aborted: 'was stopped by a user in the middle of the process'
        };

        return _map[state];
      }

      function updateReadableSummary(summary, total) {
        var readableSummary = '';
        var outOf = ' out of ' + total;
        if (!_.isUndefined(summary)) {
          if (!_.isUndefined(summary.created) && summary.created !== 0) {
            readableSummary += '<span class="text-success">' + summary.created + outOf +
              ' were imported. </span>';
            outOf = '';
          }

          if (!_.isUndefined(summary.updated) && summary.updated !== 0) {
            readableSummary += '<span class="text-warning">' + summary.updated + '' +
              ' records updated' + outOf + '. </span>';
            outOf = '';
          }

          if (!_.isUndefined(summary.exists) && summary.exists !== 0) {
            readableSummary += '<span class="text-info">' + summary.exists + ' records skipped' +
              outOf + '. </span>';
            outOf = '';
          }

          if (!_.isUndefined(summary.errors) && summary.errors !== 0) {
            readableSummary += '<span class="text-danger">' + summary.errors + ' records failed' +
              outOf + '. </span>';
          }
        }

        return readableSummary;
      }

      function updateEstimatedTimeToFinish(processed, total, startedDate) {
        if (processed === total) {
          ctrl.estimatedTimeFinished = undefined;
          return;
        }

        var timeSpentSoFar = Math.floor((new Date() - new Date(startedDate)) / 1000);
        var timePerIteration = timeSpentSoFar / processed;
        var remainingIterations = total - processed;
        var duration = remainingIterations * timePerIteration;

        // Hours, minutes and seconds
        var hrs = Math.floor(duration / 3600),
            mins = Math.floor((duration % 3600) / 60),
            secs = Math.floor(duration % 60);

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = '';

        // if (days > 0) {
        //   ret += days + ' days';
        // }

        if (hrs > 0) {
          ret += ' ' + hrs + ' hours';
        }

        if (mins > 0 || hrs > 0) {
          ret += ' ' + mins + ' minutes';
        }

        ret += ' ' + secs + ' seconds';
        // ctrl.estimatedTimeFinished = ret + ' (' + timePerIteration + ' / it)';
        ctrl.estimatedTimeFinished = ret;
      }

      this.$onChanges = function(changes) {
        if (changes.state) {
          ctrl.readableState = updateReadableState(changes.state.currentValue);
        }

        if (changes.outputSummary) {
          ctrl.readableSummary = updateReadableSummary(
            changes.outputSummary.currentValue,
            ctrl.total
          );
        }

        if (changes.processed) {
          ctrl.finishedDateTime = updateEstimatedTimeToFinish(
            changes.processed.currentValue,
            ctrl.total,
            ctrl.startedTime
          );
        }
      };
    },
    bindings: {
      dryrun: '<',
      state: '<',
      processed: '<',
      total: '<',
      outputSummary: '<',
      startedTime: '<'
    }
  });
