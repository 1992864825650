(function() {
  'use strict';

  var mapping = {
    success: 'success',
    warning: 'warning',
    error: 'danger'
  };

  function filter() {
    return function(state, prefix) {
      if (prefix === undefined) {
        prefix = 'text';
      }

      if (state === undefined) {
        return '';
      }

      return prefix + '-' + (mapping[state] || state);
    };
  }

  angular.module('blocks.utils')
    .filter('kzStateClass', filter);
})();
