(function() {
  'use strict';

  function FilledBy($q, Security, Events, UsersStub, Auth) {
    return {
      scope: {
        eventDoc: '=event',
        username: '=user',
        displayOptions: '=?',
        type: '=?',
        email: '=?',
        isAnonymisedSection: '=?'
      },
      restrict: 'EA',
      template: '<i class="icon-spin animate-spin" ng-if="!user"></i>' +
                '<a ng-if="showLink" data-ui-sref="dashboard.profile.view({ user: user.user })">' +
                  '<span ng-if="!displayOptions.showAvatar">' +
                    '{{ fullname }}' +
                  '</span>' +
                '<kz-avatar class="avatar" user="user"' +
                  'ng-if="displayOptions.showAvatar && user"></kz-avatar>' +
                '</a>' +
                '<span ng-if="!displayOptions.showAvatar && !showLink">' +
                  '{{ fullname }}' +
                '</span>' +
                '<kz-avatar class="avatar" user="user"' +
                  'ng-if="displayOptions.showAvatar && user && !showLink">' +
                '</kz-avatar>',
      link: function(scope) {
        scope.type = scope.type || 'user';
        scope.showLink = false;
        var promise;

        if (
          scope.type === 'user' &&
          scope.username.slice &&
          scope.username.slice(0, 4) === 'enc:'
        ) {
          promise = Security.hasPermissionFor('events.viewAnon', scope.eventDoc.user)
            .then(function() {
              return Events.decrypt(scope.eventDoc._id, scope.username);
            })
            .then(function(data) {
              return { username: data.data };
            })
            .catch(function() {
              return $q.reject({ username: 'anonymised' });
            });
        } else if (scope.isAnonymisedSection) {
          promise = Security.hasPermissionFor('events.viewAnon', scope.eventDoc.user)
            .then(function() {
              return $q.when({ username: scope.username });
            })
            .catch(function() {
              return $q.reject({ username: 'anonymised' });
            });
        } else {
          promise = $q.when({ username: scope.username });
        }

        promise.then(function(data) {
          if (_.indexOf(['__anonymous__', '__notregistered__', '__system__'], data.username) > -1) {
            return $q.reject({
              status: 'This is anonymous, do not query api',
              username: data.username
            });
          }

          if (scope.type !== 'user') {
            return data.username;
          }
          return UsersStub.find(data.username);
        }).then(function(user) {
          scope.user = user;

          if (Auth.currentUser() !== user.user) {
            Security.hasPermissionFor('users.view', user.user)
              .then(function(result) {
                scope.showLink = result;
              });
          }
          if (scope.type === 'user') {
            scope.fullname = _.trim((user.firstName || '') + ' ' + (user.lastName || ''))
                          || 'Unnamed user';
          } else {
            scope.fullname = user;
          }
        })
          .catch(function(error) {
            console.log(error);
            scope.user = ' ';
            scope.showLink = false;

            if (error === undefined) {
              scope.fullname = 'Name could not be retrieved';
              return;
            }

            var map = {
              __anonymous__: 'Anonymous external user',
              __notregistered__: 'User without an account',
              anonymised: 'Hidden user',
              __system__: 'Kaizen'
            };

            scope.fullname = map[error.username];

            if (error.username === '__notregistered__' && scope.email) {
              scope.fullname = scope.email;
            }
          });
      }
    };
  }

  FilledBy.$inject = ['$q', 'SecurityService', 'EventsService', 'UsersStubService', 'AuthService'];

  angular.module('events.directives')
    .directive('eventFilledBy', FilledBy);
})();
