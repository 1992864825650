(function() {
  'use strict';

  function BlueprintsController(
    $state, $rootScope, Profile, Blueprints, BlueprintSearch, List, Security, Utils
  ) {
    var ctrl = this;
    ctrl.options = {};

    Utils.setPageTitle('Blueprints');

    ctrl.searchModel = Profile.getListPreferences('blueprints').current;

    ctrl.list = new List({
      search: BlueprintSearch.search,
      model: ctrl.searchModel,
      idField: 'doc._id'
    });
    $rootScope.$broadcast('KZCurrentListChange', { list: 'blueprints', search: ctrl.list.search });

    Blueprints.findAll({ cached: false })
      .then(function(items) {
        ctrl.list.doLoadItems(items);
      });

    ctrl.actions = [
      {
        label: 'New blueprint',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.blueprints.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'blueprints.edit'),
        klass: 'btn-success'
      }
    ];
  }

  BlueprintsController.$inject = [
    '$state',
    '$rootScope',
    'ProfileService',
    'BlueprintsService',
    'BlueprintSearch',
    'ListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.blueprints')
    .controller('BlueprintsController', BlueprintsController);
})();
