(function() {
  'use strict';

  function AnnouncementsReorderController($state, Announcements, AnnouncementsUtils, Notify,
                                          Utils) {
    var ctrl = this;

    Utils.setPageTitle('Reorder announcements');

    var getPrevious;
    var getNext;

    /**
     * Get the previous announcement in the list.
     * @param  {Number} c The current order number.
     * @param  {Array}  l The list of announcements.
     * @return {Number}   The previous announcement's order.
     */
    getPrevious = function(c, l) {
      return c === 0 ? c : l[c - 1].order;
    };

    /**
     * Get the next announcement in the list.
     * @param  {Number} c The current order number.
     * @param  {Array}  l The list of announcements.
     * @return {Number}   The next announcement's order.
     */
    getNext = function(c, l) {
      return c === l.length - 1 ? l[c].order + 1 : l[c + 1].order;
    };

    /**
     * Get the border class for the announcement.
     * This just calls another method on a service.
     * @param  {Object} item The announcement object.
     * @return {String}      The border class.
     */
    ctrl.getBorderClass = function(item) {
      return AnnouncementsUtils.getBorderClass(item);
    };

    /**
     * Get the border class for the announcement.
     * This just calls another method on a service.
     * @param  {Object} item The announcement object.
     * @return {String}      The border class.
     */
    ctrl.getBorderClass = AnnouncementsUtils.getBorderClass;

    /**
     * Reorder the announcements.
     * @param  {Object} evt The reorder event, as provided by Sortable.js.
     * @return {void}
     */
    ctrl.reorder = function(evt) {
      var current = _.findIndex(evt.models,
        function(m) { return m._id === evt.model._id; }),
          prev = getPrevious(current, evt.models),
          next = getNext(current, evt.models),
          order = Utils.getNewOrderIndex(prev, next);

      Announcements.saveOrder(evt.model._id, order)
        .then(function(data) {
          evt.model._rev = data._rev;
          evt.model.order = order;
          Notify.success('Order successfully updated!', 'Success');
        })
        .catch(Utils.showError);
    };

    ctrl.actions = [
      {
        label: 'Done',
        icon: 'icon-check',
        href: (function() { return $state.href('epf.announcements.index'); })(),
        klass: 'btn-success'
      }
    ];

    Announcements.findAll({ cached: false })
      .then(function(items) {
        ctrl.announcements = _.chain(items)
                              .filter(function(item) {
                                return AnnouncementsUtils.canBeReordered(item);
                              })
                              .sortBy(function(item) {
                                return item.order;
                              })
                              .value();

        ctrl.loaded = true;
      });
  }

  AnnouncementsReorderController.$inject = [
    '$state',
    'AnnouncementsService',
    'AnnouncementsUtils',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.announcements')
    .controller('AnnouncementsReorderController', AnnouncementsReorderController);
})();
