/* eslint-disable */
// import * as Analytics from 'frylytics-client-browser';

(function() {
  'use strict';

  function FrylyticsService($window, $location, Auth, PACKAGE_VERSIONS) {
    // var Analytics = $window.Frylytics;
    var backend = null;
    var analytics = null;

    var service = {};
    service.updateEnvironment = function(env) {
      // analytics.environment(env);
    };
    service.updateDetails = function(details) {
      details = details || {};
      var tags = _.assign({}, details.tags || {}, {
        host: $location.host(),
        path: $location.path()
      });
      return _.assign({}, {
        organisation: Auth.currentOrganisation(),
        user: {
          username: Auth.currentUser()
        },
        tags: tags
      }, details || {});
    };
    service.captureEvent = function(label, value, details) {
      // analytics.captureEvent(label, value, service.updateDetails(details));
    };
    service.captureStart = function(key) {
      // return analytics.captureStart(key);
    };
    service.captureEnd = function(key, measurement, details) {
      // analytics.captureEnd(key, measurement, service.updateDetails(details));
    };
    service.init = function() {
      // // backend = new Analytics.AWSBackend({
      // //   baseURL: 'https://vij3ywhmid.execute-api.eu-west-1.amazonaws.com/',
      // //   environment: 'dev' // This is environment of the API, leave dev now.
      // // });
      // var environment = {
      //   client: window.navigator.userAgent,
      //   organisation: 'undefined',
      //   project: 'Kaizen',
      //   release: PACKAGE_VERSIONS.frontend,
      //   source: 'kz-frontend',
      //   tags: {},
      //   user: {}
      // };
      // analytics = new Analytics.Analytics(backend, environment);
      // analytics.startDispatching();
    };
    return service;
  }

  FrylyticsService.$inject = ['$window', '$location', 'AuthService', 'PACKAGE_VERSIONS'];
  angular.module('epf.core')
         .service('FrylyticsService', FrylyticsService);
})();
/* eslint-enable */
