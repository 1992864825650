import * as toastr from 'toastr';

(function() {
  'use strict';

  function CheckService(
    $rootScope,
    $q,
    $http,
    $timeout,
    $log,
    $state,
    moment,
    Notify,
    Utils,
    ClusterService,
    NetworkService,
    AUTH_ERRORS
  ) {
    var service = {};

    service.delay = 1000;
    service.multiply = 1.5;

    service.getAPI_URL = function() {
      try {
        var urls = ClusterService.getCurrentUrls();
        return _.trimEnd(urls.lapi, '/');
      } catch (err) {
        $log.warn('CheckService: Cluster not set up yet');
        return;
      }
    };

    service.checkVersion = function() {
      if (service._checkingVersion !== undefined) {
        return;
      }

      service._checkingVersion = true;

      var _check = function() {
        var apiUrl = service.getAPI_URL();
        if (!apiUrl) {
          service._checkingVersion = $timeout(_check, 5 * 60 * 1000);
          return;
        }
        var url = apiUrl + '/accounts/check?' + new Date().getTime();
        return $http.get(url)
          .then(function(response) {
            if (response && response.data && response.data.minVersion &&
              !Utils.validVersion(response.data.minVersion)) {
              $state.go('errors.upgrade',
                { error: AUTH_ERRORS.upgradeRequired },
                { location: false });
            }

            if (response && response.data && response.data.nextDeploy) {
              var dt = new Date(response.data.nextDeploy);
              var now = new Date();
              var diff = (dt - now) / 1000 / 60;
              console.log(diff);
              if (diff < 60) {
                // Show the message
                // Notify.warning('Beware, there will be an upgrade in ' + diff + ' minutes');
                toastr.options = {
                  closeButton: false,
                  preventDuplicates: true,
                  positionClass: 'toast-bottom-right',
                  timeOut: 0,
                  extendedTimeOut: 0
                };
                var msg =
                  'We are getting ready to update risr/advance. Please make sure you save all ' +
                  'your work before ' +
                  moment(dt).format('h:mmA') +
                  ', to ensure your work will ' +
                  'not be lost.';
                toastr.warning(msg);
              }
            }
          })
          .finally(function() {
            service._checkingVersion = $timeout(_check, 5 * 60 * 1000);
          });
      };
      _check();
    };

    service.reconnect = function() {
      var _reconnect = function() {
        $log.debug('CheckService: tick');
        service.checkConnectivity({ force: true })
          .then(function(result) {
            if (result === NetworkService.STATUS.online) {
              service.reconnecting = false;
              return;
            }

            if (service._delay < 60000) {
              service._delay *= service.multiply;
            }
            $timeout(_reconnect, service._delay);
          })
          .catch(function() {
            $timeout(_reconnect, service._delay);
          });
      };

      if (service.reconnecting) {
        return;
      }

      service._delay = service.delay;
      service.reconnecting = true;
      $timeout(_reconnect, service._delay);
    };

    service.setOnline = function() {
      $log.debug('CheckService: Set online');
      NetworkService.setOnline();
    };

    service.setOffline = function() {
      $log.debug('CheckService: Set offline');
      NetworkService.setOffline();
      service.reconnect();
    };

    service.checkConnectivity = function(options) {
      $log.debug('CheckService: Checking connectivity');
      // service.checkVersion();
      options = options || {};
      if (!options.force && service.networkStatus !== undefined) {
        return $q.when(service.networkStatus);
      }

      return service._checkConnectivity();
    };

    service._checkConnectivity = function() {
      $log.debug('CheckService: Checking connectivity sub');
      // var url = COUCHDB_URLS.base + '/favicon.ico?nonce=' + new Date().getTime();
      var apiUrl = service.getAPI_URL();
      var url;
      if (apiUrl) {
        url = apiUrl + '/accounts/check?' + new Date().getTime();
      } else {
        url = window.location.protocol + '//' + window.location.host + '/favicon.ico?nonce='
              + new Date().getTime();
      }

      // if (service._checkingConnectivity) {
      //   return $q.when(service.networkStatus);
      // }

      service._checkingConnectivity = true;
      return $http.get(url)
        .then(function() {
          service.setOnline();
        })
        .catch(function() {
          service.setOffline();
        })
        .then(function() {
          return NetworkService.networkStatus;
        })
        .finally(function() {
          service._checkingConnectivity = false;
        });
    };

    service.detectConnectivity = function() {
      if (!navigator.onLine) {
        Notify.warning('You are currently offline!');
        service.setOffline();
      }

      if (window.addEventListener) {
        window.addEventListener('online', function() {
          service._checkConnectivity();
        }, true);
        window.addEventListener('offline', function() {
          service.setOffline();
        }, true);
      }

      $rootScope.$on('NetworkOnline', function() {
        service._checkConnectivity();
      });

      $rootScope.$on('NetworkOffline', function() {
        service.setOffline();
      });
    };

    return service;
  }

  CheckService.$inject = [
    '$rootScope', '$q', '$http', '$timeout', '$log', '$state', 'moment',
    'NotifyService', 'UtilsService', 'ClusterService', 'NetworkService',
    'AUTH_ERRORS'];

  angular.module('blocks.network')
    .service('CheckService', CheckService);
})();
