(function() {
  'use strict';

  function AnnouncementCondensedSlotController($scope, $uibModal) {
    var ctrl = this;

    ctrl.showLimit = 2;

    /**
     * Show the preview modal.
     * @return {void}
     */
    ctrl.showPreview = function() {
      $uibModal.open({
        animation: true,
        size: 'md',
        templateUrl: 'app/components/announcements/preview.html',
        controller: 'AnnouncementPreviewController',
        controllerAs: 'announcementPreviewCtrl',
        resolve: {
          announcementDoc: function() {
            return $scope.item;
          },
          allowDismiss: function() {
            // Because this is only used in the list by admins,
            // we should never mark this announcement as dimissed.
            return false;
          },
          markAsRead: function() {
            // Because this is only used in the list by admins,
            // we should never mark this announcement as read.
            return false;
          }
        }
      });
    };
  }

  function AnnouncementCondensedSlotDirective() {
    return {
      scope: {
        item: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/announcements/directives/announcement-condensed.slot.html',
      controller: AnnouncementCondensedSlotController,
      controllerAs: 'ctrl'
    };
  }

  AnnouncementCondensedSlotController.$inject = [
    '$scope',
    '$uibModal'
  ];

  angular.module('component.announcements')
    .directive('announcementCondensedSlot', AnnouncementCondensedSlotDirective);
})();
