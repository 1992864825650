(function() {
  'use strict';

  function GoalsetPeriodsDirective(LocalizationService) {
    return {
      scope: {
        goal: '=',
        selectedPeriodId: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/goalset.periods.select.html',
      replace: true,
      link: function(scope) {
        scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
        if (_.isUndefined(scope.selectedPeriodId)) {
          scope.selectedPeriodId = scope.goal.getCurrentPeriodId();
        }

        scope.selectPeriod = function(periodId) {
          scope.$emit('GoalPeriodSelected', scope.goal.doc.eventId + '|' + periodId);
          scope.selectedPeriodId = periodId;
        };
      }
    };
  }

  GoalsetPeriodsDirective.$inject = ['LocalizationService'];

  angular.module('component.goals')
    .directive('goalsetPeriodsSelect', GoalsetPeriodsDirective);
})();
