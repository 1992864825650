(function() {
  'use strict';

  function SQLTableComponent(Utils) {
    var ctrl = this;

    ctrl.pageSizes = [
      10,
      20,
      50,
      100,
      1000
    ];

    ctrl.$onInit = function() {
      ctrl.sizeModel = { size: ctrl.table.size };
      if (_.indexOf(ctrl.pageSizes, ctrl.table.size) === -1) {
        ctrl.pageSizes.push(ctrl.table.size);
        ctrl.pageSizes = _.sortBy(ctrl.pageSizes);
      }
    };

    ctrl.$onChanges = function() {
      ctrl.setup();
    };

    ctrl.setup = function() {
      ctrl.setupPagination();
      ctrl.setupTable();
    };

    ctrl.setupPagination = function() {
      ctrl.showPagination = ctrl.table.total > ctrl.table.size;
      ctrl.showFirst = ctrl.table.start > 0;
      ctrl.showLast = ctrl.table.start + ctrl.table.size <= ctrl.table.total;
    };

    ctrl.setupTable = function() {
      var hideColumns = (ctrl.options || {}).hideColumns || [];
      var colIds = _.map(ctrl.table.headers, 'id');
      var idxToHide = _.map(hideColumns, function(col) {
        return _.indexOf(colIds, col);
      });

      var newHeaders = _.filter(ctrl.table.headers, function(_head, idx) {
        return _.indexOf(idxToHide, idx) === -1;
      });
      var extraColumns = (ctrl.options || {}).extraColumns;

      var extraHeaders = [];

      _.forEach(extraColumns, function(col) {
        if (col.type === 'preview') {
          var colIdx = _.indexOf(colIds, col.field);
          var header = {
            id: col.label,
            type: 'string',
            valueGenerator: function(row) {
              var eid = row[colIdx];
              return {
                display: 'Preview',
                value: eid,
                type: 'preview'
              };
            }
          };

          if (col.replace) {
            newHeaders[colIdx] = header;
          } else {
            extraHeaders.push(header);
          }
        }
        console.log('Unknown column type');
      });

      newHeaders = newHeaders.concat(extraHeaders);
      var newData = _.map(ctrl.table.data, function(row) {
        var newRow = _.filter(row, function(_col, ridx) {
          return _.indexOf(idxToHide, ridx) === -1;
        });
        newRow = _.map(newRow, function(col, ridx) {
          var head = newHeaders[ridx];
          var dtype = head.type;
          var precision = (ctrl.table.options || {}).defaultPrecision || 2;
          if (_.isFunction(head.valueGenerator)) {
            return head.valueGenerator(row);
          }

          if (col !== null && dtype === 'numeric' && precision !== -1) {
            try {
              col = Utils.round(col, precision);
            } catch (err) {
              console.log(err);
            }
          }
          return {
            display: col,
            type: newHeaders[ridx].type
          };
        });
        _.forEach(extraHeaders, function(head) {
          newRow.push(head.valueGenerator(row));
        });
        return newRow;
      });
      ctrl.processedTable = {
        headers: newHeaders,
        data: newData,
        options: ctrl.table.options
      };
    };

    ctrl.first = function() {
      if (ctrl.onPage) {
        ctrl.onPage({
          $name: ctrl.table.name,
          $start: 0,
          $size: ctrl.table.size
        });
      }
    };

    ctrl.prev = function() {
      if (ctrl.onPage) {
        var start = Math.max(0, ctrl.table.start - ctrl.table.size);
        ctrl.onPage({
          $name: ctrl.table.name,
          $start: start,
          $size: ctrl.table.size
        });
      }
    };
    ctrl.next = function() {
      if (ctrl.onPage) {
        ctrl.onPage({
          $name: ctrl.table.name,
          $start: ctrl.table.start + ctrl.table.size,
          $size: ctrl.table.size
        });
      }
    };
    ctrl.last = function() {
      if (ctrl.onPage) {
        var start = ctrl.table.total - (ctrl.table.total % ctrl.table.size);
        ctrl.onPage({
          $name: ctrl.table.name,
          $start: start,
          $size: ctrl.table.size
        });
      }
    };
    ctrl.sizeChanged = function() {
      if (ctrl.onPage) {
        var size = ctrl.sizeModel.size;
        if (size === ctrl.table.size) {
          return;
        }

        ctrl.onPage({
          $name: ctrl.table.name,
          $start: 0,
          $size: size
        });
      }
    };

    ctrl.preview = function(col) {
      ctrl.onPreview({
        $col: col
      });
    };
  }

  SQLTableComponent.$inject = [
    'UtilsService'
  ];
  angular.module('component.reports')
    .component('kzSqlTable', {
      templateUrl: 'app/components/reports/directives/sql-table.html',
      controller: SQLTableComponent,
      bindings: {
        table: '<',
        onPage: '&',
        options: '<',
        onPreview: '&'
      }
    });
})();
