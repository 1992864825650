(function() {
  'use strict';

  function ProfileViewDirective(Accounts, Auth, UserFields) {
    return {
      scope: {
        user: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/users/profile.view.html',
      replace: true,
      link: function link(scope) {
        UserFields.findAll()
          .then(function(fields) {
            scope.userFields = fields;
          });

        Accounts.find(scope.user.username)
          .then(function(userAccounts) {
            userAccounts = _.filter(userAccounts, function(account) {
              return account.org === Auth.currentOrganisation();
            });
            scope.userAccounts = userAccounts;
          });
      }
    };
  }

  ProfileViewDirective.$inject = ['AccountsService', 'AuthService', 'UserFieldsService'];

  function Fullname(UsersStub) {
    return {
      scope: {
        username: '=user',
        user: '=?doc',
        highlight: '='
      },
      restrict: 'EA',
      template: '<span data-ng-bind-html="fullname | kzHighlight:highlight"></span>',
      link: function(scope) {
        function setFullName() {
          function getFullname(doc) {
            var fullname = _.trim((doc.firstName || '') + ' ' + (doc.lastName || ''));
            if (!fullname) {
              fullname = 'Unamed user';
            }

            return fullname;
          }

          if (!scope.username && !scope.user) {
            return;
          }

          if (scope.user) {
            scope.fullname = getFullname(scope.user);
            return;
          }

          UsersStub.find(scope.username)
          .then(function(user) {
            scope.user = user;
            scope.fullname = getFullname(user);
          })
          .catch(function(error) {
            console.log(error);
            scope.user = ' ';
          });
        }

        scope.$watch('user', function() {
          setFullName();
        });
      }
    };
  }

  Fullname.$inject = ['UsersStubService'];

  function Fullnames() {
    return {
      scope: {
        user: '='
      },
      restrict: 'EA',
      template: '<span data-ng-repeat="username in usernames">' +
      '<span epf-fullname user="username"></span><span ng-if="!$last">, </span>' +
      '</span>',
      link: function(scope) {
        scope.$watch(
          function() {
            return (scope.user);
          },
          function() {
            if (_.isUndefined(scope.user)) {
              return;
            }

            if (_.isArray(scope.user)) {
              scope.usernames = scope.user;
            } else {
              scope.usernames = [scope.user];
            }
          }
        );
      }
    };
  }

  function uniqueValidator($q, Users) {
    return {
      require: 'ngModel',
      link: function(scope, _elm, attrs, ctrl) {
        ctrl.$asyncValidators.unique = function(modelValue, viewValue) {
          var value = modelValue || viewValue;
          if (ctrl.$isEmpty(value)) {
            return $q.when();
          }

          var profile = scope.$eval(attrs.recordId);
          return Users.isUnique(attrs.name, value, profile);
        };
      }
    };
  }

  uniqueValidator.$inject = ['$q', 'UsersService'];

  angular.module('component.users')
    .directive('profileView', ProfileViewDirective)
    .directive('epfFullname', Fullname)
    .directive('epfFullnames', Fullnames)
    .directive('unique', uniqueValidator);
})();
