(function() {
  'use strict';

  function PinController($q, $state, $location, Auth, LocalUsers, Network, Utils) {
    var ctrl = this;

    Utils.setPageTitle('Offline pin');

    ctrl.credentials = {};
    ctrl.notifications = [];

    if (!Network.isOffline()) {
      $state.go('accounts.login');
      return;
    }

    LocalUsers.findAll()
      .then(function(data) {
        var promises = _.map(data, function(user) {
          return LocalUsers.getUserInfo(user)
            .catch(function() {
              return;
            });
        });
        return $q.all(promises);
      })
      .then(function(data) {
        var users = _.filter(data, function(item) {
          return !_.isUndefined(item);
        });
        return users;
      })
      // Optionally store unique usernames
      .then(function(users) {
        var uniqueUsers = _.groupBy(users, 'doc.username');
        ctrl.uniqueUsers = [];
        _.forOwn(uniqueUsers, function(profiles) {
          ctrl.uniqueUsers.push({
            doc: profiles[0].doc,
            profiles: profiles
          });
        });
        ctrl.users = ctrl.uniqueUsers;
      });

    ctrl.select = function(user) {
      ctrl.pinPresent = Auth.getPinsFor(user).length > 0;
      ctrl.selectedUser = user;
    };

    ctrl.deselect = function() {
      ctrl.selectedUser = undefined;
      ctrl.credentials = {};
      ctrl.notifications = [];
    };

    ctrl.login = function() {
      // After submitting the form we want to clear all notifications
      ctrl.notifications = [];

      Auth.loginOffline(ctrl.selectedUser, ctrl.credentials.pin)
        .then(function() {
          var cameFrom = $location.search().came_from || '/';
          $location.url(cameFrom);
        }, function(error) {
          if (error.status === 401) {
            ctrl.notifications.push({
              type: error.status,
              classes: 'text-danger',
              msg: error.message
            });
          } else {
            console.log('Unknown error:' + error.message);
          }
        });
    };
  }

  PinController.$inject = [
    '$q',
    '$state',
    '$location',
    'AuthService',
    'LocalUsersService',
    'NetworkService',
    'UtilsService'
  ];

  angular.module('component.accounts')
    .controller('PinController', PinController);
})();
