(function() {
  'use strict';

  function CacheService(
    $q,
    $log,
    _$timeout,
    $rootScope,
    CacheFactory,
    Auth,
    md5,
    Database
  ) {
    var maxAge = 10 * 60 * 1000;

    var _cache = CacheFactory.get('epf') || CacheFactory.createCache('epf', {
      maxAge: 5 * 60 * 1000,
      deleteOnExpire: 'aggressive'
    });

    var _locCache = CacheFactory.get('epfloc') || CacheFactory.createCache('epfloc', {
      maxAge: 1 * 60 * 60 * 1000,
      deleteOnExpire: 'aggressive',
      storageMode: 'localStorage'
    });

    var service = {};
    // var _delayedPut = {};
    service._cachedPromises = {};

    try {
      service._m = new Map();
    } catch (err) {
      service._m = null;
    }

    function getKey(id, keyOptions) {
      if (keyOptions !== undefined) {
        var hash = md5.createHash(JSON.stringify(keyOptions));
        return Auth.currentOrganisation() + '-' + id + '-' + hash;
      }

      return id;
    }

    service.get = function(id, keyOptions, cacheOptions) {
      cacheOptions = cacheOptions || {};
      var key = getKey(id, keyOptions);

      var res = _cache.get(key);

      if (res === undefined && cacheOptions.strong) {
        res = _locCache.get(key);
        _cache.put(key, res);
      }

      return res;
    };

    service.put = function(id, val, keyOptions, cacheOptions) {
      cacheOptions = cacheOptions || {};
      if (cacheOptions.strong) {
        try {
          _locCache.put(getKey(id, keyOptions), val);
        } catch (err) {
          console.log(err);
        }

        _cache.put(getKey(id, keyOptions), val);
      } else {
        _cache.put(getKey(id, keyOptions), val);
      }
    };

    service.remove = function(id, keyOptions) {
      var key = getKey(id, keyOptions);
      return _cache.remove(key);
    };

    service.destroy = function(options) {
      options = options || {};
      _cache.removeAll();
      try {
        this._m = new Map();
      } catch (err) {
        this._m = null;
      }
      service._cachedPromises = {};

      if (options.strong) {
        _locCache.removeAll();
        return this.removeLocalData();
      }

      return $q.when();
    };

    service.removeLocalData = function() {
      return Database.destroyLocalDb('idbCache');
    };

    /*
      THis is a simple cache wrapper. So far it caches only in memory
     */
    service.cached = function(key, func) {
      var _this = this;
      if (_this._m === null) {
        return func;
      }

      function wrapper() {
        var hashkey = getKey(key, arguments);

        // if (service.get(hashkey) !== undefined) {
        //   return $q.when(service.get(hashkey));
        // }

        if (!_this._m.has(hashkey)) {
          var def = func.apply(this, arguments)
            .then(function(data) {
              // service.put(hashkey, data);
              return data;
            });
          _this._m.set(hashkey, def);
        }

        return _this._m.get(hashkey);
      }

      return wrapper;
    };

    service.invalidateCachedPromise = function(key) {
      $log.info('Cache: Invalidating ' + key);
      delete service._cachedPromises[key];
    };

    service.cachedPromise = function(func, options, key) {
      // if promise on service return promise
      // if result on service, check its not old and if not return, otherwise discard
      // get revision from idb
      // get revision from couch
      // if the same get from idb
      // else get from couch and save to idb
      // save promise on service
      // when resolved, store result on a service
      // return promise

      options = options || {};
      key = key || getKey(options.key, options);

      // $log.debug('cache: cached', options.cached);
      if (!options.cached) {
        // $log.debug('cache: skipping', key);
        delete service._cachedPromises[key];
        return func();
      }

      var response = service._cachedPromises[key];

      // If no response we cannot use cache
      var useCache = response !== undefined;

      // If the promise is not done yet we always cache
      if (useCache && response.done) {
        useCache = new Date().getTime() - response.timestamp <= (options.maxAge || maxAge);
      }

      if (!useCache) {
        // $log.debug('cache: calling', key, options);
        service._cachedPromises[key] = {
          res: func()
            .then(function(res) {
              if (options.maxAge === 0) {
                // $log.debug('cache: removing cache', key, options);
                delete service._cachedPromises[key];
              } else if (service._cachedPromises[key] !== undefined) {
                service._cachedPromises[key].done = true;
              }
              return res;
            })
            .catch(function(err) {
              delete service._cachedPromises[key];
              return $q.reject(err);
            }),
          timestamp: new Date().getTime()
        };
      } else {
        // $log.debug('cache: cached', key, options);
      }

      return $q.when(service._cachedPromises[key].res);
    };

    function getAndCache(func, key) {
      var funcData;
      return func()
        .catch(function(error) {
          $log.error('Failed process func');
          return $q.reject(error);
        })
        .then(function(result) {
          var entry = {
            _id: key,
            type: 'cache',
            value: result,
            timestamp: new Date().getTime()
          };
          funcData = result;
          $log.debug('cache: Saving to idb', key);
          return service.idbPut(key, entry);
        })
        .then(function() {
          return funcData;
        })
        .catch(function() {
          return funcData;
        });
    }

    service.cachedIdb = function(func, options, key) {
      options = options || {};
      key = key || getKey(options.key, options);
      $log.debug('cache: Getting from idb', key);

      return service.idbGet(key)
        .catch(function() {
          // Getting from idb failed
          return;
        })
        .then(function(data) {
          if (data !== undefined && data.value) {
            $log.debug('cache: got from idb', key);
            if (new Date().getTime() - data.timestamp <= (options.maxAge || maxAge)) {
              return data.value;
            }

            $log.debug('cache: item too old, discarding', key);
          }

          return getAndCache(func, key);
        });
    };

    service.idbCache = function(kzOpts) {
      return Database.getLocalDb('idbCache', undefined, kzOpts)
        .then(function(idbCache) {
          if (_.isUndefined(idbCache)) {
            return $q.reject({ status: 500, message: 'No idb cache support' });
          }
          return idbCache;
        });
    };

    function safePut(id, value, db) {
      if (_.isUndefined(db)) {
        return $q.reject({ status: 500, message: 'No database specified' });
      }

      // value = angular.copy(value);
      return db.allDocs({ key: id })
        .then(function(doc) {
          if (doc.rows.length === 1) {
            value._rev = doc.rows[0].value.rev;
          } else {
            delete value._rev;
          }
        })
        .catch(function() {
          if (value._rev) {
            delete value._rev;
          }
        })
        .then(function() {
          return db.put(value);
        })
        .catch(function(err) {
          // Raven.captureMessage(err);
          $log.warn('Could not safe put to db', id, err, db);
          // Api.post('capture_log', { log: window.kzLog, type: 'error' });
          return $q.reject(err);
        });
    }

    // function delayedPut(id, entry, db) {
    //   if (_delayedPut[id] !== undefined) {
    //     $log.debug('Cancelling delayed put');
    //     $timeout.cancel(_delayedPut[id]);
    //     delete _delayedPut[id];
    //   }

    //   _delayedPut[id] = $timeout(function() {
    //     $log.debug('Saving delayed put');
    //     safePut(id, entry, db);
    //   }, 60000);
    // }

    service.idbGet = function(id) {
      return service.idbCache()
        .then(function(db) {
          return db.get(id);
        });
    };

    service.idbPut = function(id, value) {
      return service.idbCache()
        .then(function(db) {
          return safePut(id, value, db);
        });
    };

    service.persistentPut = function(id, value) {
      id = 'cache-' + id;
      var entry = {
        _id: id,
        type: 'cache',
        version: value.version,
        value: value
      };

      $log.debug('Saving cache', value.version);
      return service.idbCache()
        .then(function(db) {
          return safePut(id, entry, db);
        });
    };

    service.persistentGet = function(id, options) {
      options = options || {};
      id = 'cache-' + id;
      $log.debug('Cache require version', options.version);
      return service.idbCache()
        .then(function(db) {
          return db.get(id);
        })
        .then(function(doc) {
          $log.debug('Cache got from idb', doc.version);
          if (options.version !== doc.version) {
            $log.debug('Cache obsolete version');
            return $q.reject('Obsolete');
          }

          return doc;
        })
        .then(function(doc) {
          $log.debug('Cache final got', doc.version);
          return doc.value;
        })
        .catch(function() {
          $log.debug('Cache miss');
          return $q.reject();
        });
    };

    $rootScope.$on('KZClearCache', function(_evt, args) {
      service.destroy(args);
    });

    return service;
  }

  CacheService.$inject = [
    '$q',
    '$log',
    '$timeout',
    '$rootScope',
    'CacheFactory',
    'AuthService',
    'md5',
    'DatabaseService'
  ];

  angular.module('blocks.cache')
    .service('CacheService', CacheService);
})();
