(function() {
  'use strict';

  function title(Event, ACTION_SCHEDULE_MAPPING) {
    return {
      scope: {
        doc: '='
      },
      restrict: 'A',
      templateUrl: 'app/blocks/utils/title.html',
      link: function(scope) {
        if (scope.doc.type === 'event' || scope.doc.type === 'eventSection') {
          scope.type = 'event';
          scope.event = new Event(scope.doc);
          scope.event.init();
        } else if (scope.doc.type === 'eventExtra') {
          scope.event = new Event();
          scope.event.load(scope.doc.event);
          scope.title = 'Comment on';
          scope.type = 'both';
        } else if (scope.doc.type === 'queue') {
          scope.type = 'simple';
          scope.title = ACTION_SCHEDULE_MAPPING[scope.doc.action] || scope.doc.action;
          scope.related = scope.doc.related;
        } else if (scope.doc.type === 'autosave') {
          scope.type = 'simple';
          scope.title = 'Draft';
          scope.related = scope.doc.docId;
        } else {
          scope.title = scope.doc.name || scope.doc.title || scope.doc.question || 'unknown';
          scope.type = 'simple';
        }
      }
    };
  }

  title.$inject = ['EventFactory', 'ACTION_SCHEDULE_MAPPING'];

  angular.module('blocks.utils')
    .directive('kzTitle', title);
})();
