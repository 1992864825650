(function() {
  'use strict';

  function TurnitinFieldComponent() {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.setup();
    };

    ctrl.setup = function() {
      ctrl.turnitin_key = `__turnitin__${ctrl.key}`;
    };

    ctrl.getDataModel = function() {
      return ctrl.model[ctrl.key];
    };

    ctrl.getResultModel = function() {
      if (ctrl.model[ctrl.turnitin_key] === undefined) {
        ctrl.model[ctrl.turnitin_key] = {
          submissions: []
        };
      }

      return ctrl.model[ctrl.turnitin_key];
    };

    ctrl.onUpdate = function(result) {
      ctrl.model[ctrl.turnitin_key] = result;
    };
  }

  TurnitinFieldComponent.$inject = [];

  angular.module('blocks.utils')
    .component('turnitinField', {
      templateUrl: 'app/blocks/utils/turnitin.field.component.html',
      controller: TurnitinFieldComponent,
      bindings: {
        model: '<',
        key: '<',
        readOnly: '<',
        maxAttempts: '<',
        title: '<'
      }
    });
})();
