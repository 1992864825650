(function() {
  'use strict';

  function ReportCalculator($q, $log, BlueprintUtils, EventUtils) {
    var service = {};

    function loadFromES(list) {
      return EventUtils.preCache()

      // Load aggregates from elastic search
      .then(function() {
        $log.debug('Getting aggregates');
        return list.getAggregates(list.defaultFilter);
      })

      // Turn aggs into our format
      .then(function(result) {
        $log.debug('Getting aggregates from Api');
        var totalEvents = $q.when(result.total);
        return $q.all(
          [EventUtils.aggregateEventsFromApi(result.by_blueprint.buckets), totalEvents]
        );
      });
    }

    function loadFromCouch(list) {
      return EventUtils.preCache()

      // Load aggregates from elastic search
      .then(function() {
        $log.debug('Getting aggregates');
        return list.getAggregates(list.defaultFilter);
      })

      // Turn aggs into our format
      .then(function(result) {
        $log.debug(Date(), 'Getting aggregates from Api');
        var totalEvents = $q.when(result.total);
        return $q.all([result.by_blueprint, totalEvents]);
      });
    }

    function loadFromLocal(list) {
      // Apply filter to both as if cached we are using allItems
      return list.filter(list.defaultFilter)
        .then(function() {
          $log.debug('Getting aggregates from Items');
          var events = list.filtered;
          var totalEvents = $q.when(events.length);
          return $q.all([EventUtils.aggregateEventsFromItems(events), totalEvents]);
        });
    }

    service.calculateBlueprintCoverage = function(blueprintId, list, options) {
      options = options || {};
      var aggs;
      var blueprint;
      var totalEvents;
      var getAggregates;

      var user = options.user;

      if (list.listType === 'apilist') {
        getAggregates = loadFromES(list);
      } else if (list.listType === 'dblist') {
        getAggregates = loadFromLocal(list);
      } else if (list.listType === 'couchlist') {
        getAggregates = loadFromCouch(list);
      } else {
        console.log('Unknown list type');
      }

      // Load aggregates
      return getAggregates

      // Load blueprint
      .then(function(result) {
        $log.debug('Getting blueprints');
        aggs = result[0];
        totalEvents = result[1];

        var options = { user: user, period: 'all' };
        return BlueprintUtils.getTaggableBlueprints(options);
      })

      // Restrict blueprint based on the current user
      .then(function(blueprints) {
        $log.debug('Restricting blueprint');
        return _.find(blueprints, function(blueprint) {
          return blueprint._id === blueprintId;
        });
      })
      .then(function(blueprint) {
        $log.debug('Restricting blueprint category if needed');

        if (options.blueprintCatId) {
          return _.find(blueprint.categories, function(category) {
            return category._id === options.blueprintCatId;
          });
        }

        return blueprint;
      })
      // Aggregate and merge aggs with blueprint
      .then(function(blueprint) {
        $log.debug('Merging tags with blueprint');

        return EventUtils.aggregateBlueprints(
          aggs,
          { keepEmpty: true, blueprints: [blueprint], type: 'countTags' }
        );
      })

      // Consolidate
      .then(function(agg) {
        $log.debug('Outputting');
        blueprint = agg[0];

        // var blueprintCoverage = Math.floor(blueprint.tags.count / blueprint.tags.total * 100) +
        // '%';
        var tagsNotCovered = blueprint.tags.total - blueprint.tags.used;
        return {
          overview: [
            { label: 'Total events', data: totalEvents, style: 'primary' },

            // {label: 'Blueprint coverage', data: blueprintCoverage, style: 'primary'},
            { label: 'Tags not covered', data: tagsNotCovered, style: 'primary' }
          ],
          blueprintTags: agg[0].categories
        };
      });
    };

    return service;
  }

  ReportCalculator.$inject = ['$q', '$log', 'BlueprintUtils', 'EventUtils'];

  angular.module('component.reports')
    .factory('ReportCalculator', ReportCalculator);
})();
