
import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UpgradeModule } from '@angular/upgrade/static';

import epf from '../../app';
import initial from './starter';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private upgrade: UpgradeModule,
    private sw: SwUpdate
  ) {
    this.setupServiceWorker();
  }

  ngOnInit(): void {
    this.startKaizen();
  }

  setupServiceWorker(): void {
    console.log("SW", this.sw.isEnabled);
    window['offlineState'] = {
      engine: 'serviceWorker',
      state: this.sw.isEnabled ? 'ready' : 'none',
    }

    this.sw.versionUpdates.subscribe(event => {
      console.log("SW", event);
      console.log("SW", this.sw.isEnabled);
    });
    this.sw.unrecoverable.subscribe(event => {
      console.log("SW", event);
    });
  }

  startKaizen(): void {
    initial().then(() => {
      this.upgrade.bootstrap(document.body, [epf]);
    })
    .catch(err => {
      console.log(err);
    });
  }
}
