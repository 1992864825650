(function() {
  'use strict';

  function Percentage(Utils) {
    return {
      restrict: 'AE',
      scope: {
        numerator: '=',
        denominator: '=',
        rate: '='
      },
      template: '<span class="label" ng-class="label">{{value}}</span>',
      replace: true,
      link: function($scope) {
        function loadCompletionRate(value) {
          if (!_.isUndefined(value)) {
            $scope.completionRate = $scope.rate ?
              $scope.rate :
              Utils.getRate($scope.numerator, $scope.denominator);
            if ($scope.completionRate >= 100) {
              $scope.label = 'label-success';
            } else if ($scope.completionRate >= 66) {
              $scope.label = 'label-info';
            } else if ($scope.completionRate >= 33) {
              $scope.label = 'label-warning';
            } else if ($scope.completionRate >= 0) {
              $scope.label = 'label-danger';
            }

            $scope.value = $scope.completionRate + '%';
          } else {
            $scope.value = 'N/A';
          }
        }

        $scope.$watchCollection('numerator', function(value) {
          loadCompletionRate(value);
        });

        $scope.$watchCollection('rate', function(value) {
          loadCompletionRate(value);
        });
      }
    };
  }

  Percentage.$inject = ['UtilsService'];

  angular.module('blocks.widgets')
    .directive('kzPercentage', Percentage);
})();
