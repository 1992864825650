(function() {
  'use strict';

  function BlueprintTitleDirective(Blueprints) {
    return {
      scope: {
        category: '='
      },
      restrict: 'EA',
      template: '<span data-ng-show="!hasError">{{ categoryTitle }}</span>',
      link: function(scope) {
        if (!scope.category) {
          return;
        }

        var updateTitle = function(id) {
          Blueprints.findByCategoryId(id)
            .then(function(blueprint) {
              var title = 'N/A';
              if (!_.isUndefined(blueprint)) {
                title = blueprint.value;
              }
              scope.categoryTitle = title;
            })
            .catch(function(error) {
              scope.hasError = true;
              console.log(error);
            });
        };

        updateTitle(scope.category);

        scope.$watch('category',
          function(value, oldValue) {
            if (value !== oldValue) {
              updateTitle(value);
            }
          }
        );
      }
    };
  }

  function BlueprintPathDirective(Blueprints) {
    return {
      scope: {
        category: '='
      },
      restrict: 'EA',
      template: '<span data-ng-show="!hasError">{{ categoryTitle }}</span>',
      link: function(scope) {
        if (!scope.category) {
          return;
        }

        var updateTitle = function() {
          Blueprints.findPathFor({ key: scope.category }, { titles: true })
            .then(function(path) {
              // path.splice(0, 0, 'Home');
              scope.categoryTitle = path.filter(function(item) {
                return item !== undefined;
              }).join(' » ');
            })
            .catch(function(error) {
              scope.hasError = true;
              console.log(error);
            });
        };

        updateTitle(scope.category);

        scope.$watch('category',
          function(value, oldValue) {
            if (value !== oldValue) {
              updateTitle(value);
            }
          }
        );
      }
    };
  }

  function CategoryTitleDirective(
    $state,
    EventType,
    UserFields,
    Blueprints,
    Relations,
    Organisations
  ) {
    return {
      scope: {
        category: '=',
        klass: '@',
        kzType: '@',
        fieldId: '@',
        eventTypeId: '@',
        watch: '@',
        showHref: '=?'
      },
      restrict: 'EA',
      template: '<span class="{{klass}}" data-ng-show="show" ng-if="!showHref">' +
                  '<i class="icon-spin animate-spin" ng-if="!categoryTitle"></i>' +
                  '{{ categoryTitle }}' +
                '</span>' +
                '<a data-ng-show="show" target="_blank" ng-href="{{rootCategoryHref}}" href=""' +
        ' ng-if="showHref">' +
                '{{ categoryTitle }}' +
                '</a>',
      link: function(scope) {
        function show() {
          scope.show = true;
          if (!scope.category) {
            scope.show = false;
            return;
          }

          var hrefLocation;
          var promise;
          if (scope.kzType === 'customField' && scope.eventTypeId !== undefined) {
            promise = EventType.findByFieldCategoryId(
              scope.category,
              scope.fieldId,
              scope.eventTypeId
            );
          } else if (scope.kzType === 'userField') {
            promise = UserFields.findByCategoryId(scope.category);
          } else if (scope.kzType === 'blueprint') {
            hrefLocation = 'epf.blueprints.edit';
            promise = Blueprints.findByCategoryId(scope.category);
          } else if (scope.kzType === 'relation') {
            hrefLocation = 'epf.relations.edit';
            promise = Relations.findByCategoryId(scope.category);
          } else {
            promise = Organisations.findByCategoryId(scope.category);
          }

          promise
            .then(function(obj) {
              if (obj === undefined) {
                scope.categoryTitle = scope.category;
                return;
              }

              scope.categoryTitle = obj.value || obj.name || obj.value.name || obj.title;
              scope.rootCategoryHref = $state.href(hrefLocation, { id: obj.rootId });
            })
            .catch(function(error) {
              console.log(error);
              scope.categoryTitle = scope.category;
            });
        }

        if (scope.watch) {
          scope.$watch('category', function(_val) {
            show();
          });
        } else {
          show();
        }
      }
    };
  }

  function ObjectTitleDirective(Database) {
    return {
      scope: {
        id: '=',
        klass: '@',
        watch: '@'
      },
      restrict: 'EA',
      template: '<span class="{{klass}}" data-ng-show="show">' +
                  '<i class="icon-spin animate-spin" ng-if="!title"></i>' +
                  '{{ title }}' +
                '</span>',
      link: function(scope) {
        function show() {
          scope.show = true;
          if (!scope.id) {
            scope.show = false;
            return;
          }

          var db = Database.get('master');

          db.get(scope.id)
            .then(function(obj) {
              scope.title = obj.title || obj.name;
            })
            .catch(function(error) {
              console.log(error);
              scope.title = scope.id;
            });
        }

        if (scope.watch) {
          scope.$watch('id', function(_val) {
            show();
          });
        } else {
          show();
        }
      }
    };
  }

  BlueprintTitleDirective.$inject = ['BlueprintsService'];
  BlueprintPathDirective.$inject = ['BlueprintsService'];
  CategoryTitleDirective.$inject = [
    '$state',
    'EventTypesService',
    'UserFieldsService',
    'BlueprintsService',
    'RelationsService',
    'OrganisationsService'
  ];
  ObjectTitleDirective.$inject = ['DatabaseService'];

  angular.module('component.blueprints')
    .directive('kzBlueprintCategoryTitle', BlueprintTitleDirective)
    .directive('kzBlueprintCategoryPath', BlueprintPathDirective)
    .directive('kzCategoryTitle', CategoryTitleDirective)
    .directive('kzObjectTitle', ObjectTitleDirective);
})();
