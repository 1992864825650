(function() {
  'use strict';

  function EventTypePredefinedFieldsService($q, EasApiStore) {
    var service = new EasApiStore('predefined-field');
    service.preCache = function() { return $q.all([service.findAll()]); };

    return service;
  }

  EventTypePredefinedFieldsService.$inject = ['$q', 'EasApiStore'];

  angular.module('component.eventTypes')
    .factory('EventTypePredefinedFieldsService', EventTypePredefinedFieldsService);
})();
