(function() {
  'use strict';


  function EventSectionViewController($scope, LocalizationService) {
    var ctrl = this;
    ctrl.eventSection = $scope.eventSection;
    ctrl.allowActions = !$scope.noEdit;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    // Event has to be loaded
    if (!ctrl.eventSection.loaded) {
      throw { message: 'Event has to be loaded' };
    }

    ctrl.loadInvitations = function() {
      ctrl.showInvitation = false;
      var nextSectionId = ctrl.eventSection.getNextSection();
      if (nextSectionId) {
        var nextDefSection = ctrl.eventSection.getDefSection(nextSectionId);
        ctrl.showInvitation =
          _.indexOf(nextDefSection.filledBy, 'system:anonymous-external') === -1;
      }

      var nextInvitation = ctrl.eventSection.getNextInvitedUsers();
      ctrl.showNextInvitation = ctrl.showInvitation && nextInvitation.length;
    };
    ctrl.loadInvitations();
    $scope.$on('reloadInvitations', function() {
      ctrl.loadInvitations();
    });

    ctrl.fillIn = function(_evt) {
      if ($scope.fillIn) {
        $scope.fillIn();
      }
    };

    ctrl.isFilled = function() {
      if (ctrl.eventSection.doc.state !== 'draft') {
        return true;
      }

      if (ctrl.eventSection.doc.data.enc) {
        return true;
      }

      if (!_.isEmpty(ctrl.eventSection.doc.data.fields)) {
        return true;
      }

      return false;
    };
  }

  EventSectionViewController.$inject = ['$scope', 'LocalizationService'];

  function EventSectionViewDirective() {
    return {
      scope: {
        eventSection: '=',
        noEdit: '=?',
        fillIn: '&?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-view.html',
      replace: true,
      controller: EventSectionViewController,
      controllerAs: 'eventViewCtrl'
    };
  }


  angular.module('events.directives')
    .directive('eventSectionView', EventSectionViewDirective)
    .controller('eventSectionViewController', EventSectionViewController);
})();
