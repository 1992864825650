(function() {
  'use strict';

  function EventSectionNextUpdateController($scope, $uibModal, EventSections, Notify) {
    var ctrl = this;

    ctrl.style = 'link';
    // check that the next responsible person is still valid to be invited via API
    var currentNextSection;
    EventSections.isNextResponsiblePersonValid($scope.eventSection.doc._id)
      .then(function(isValid) {
        if (!isValid) {
          ctrl.show = true;
          var nextSection = $scope.eventSection.getNextSection();
          if (nextSection === currentNextSection) {
            return;
          }
          ctrl.nextDefSection = $scope.eventSection.getDefSection(nextSection);
        }
      });

    ctrl.showUpdateNextResponsiblePopup = function() {
      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/events/partials/event-section-next-responsible-update.html',
        controller: ['$scope', '$uibModalInstance', 'eventSection', 'nextDefSection',
          function($scope, $uibModalInstance, eventSection, nextDefSection) {
            $scope.eventSection = eventSection;
            $scope.nextDefSection = nextDefSection;
            $scope.newInvitation = angular.copy(eventSection.doc.invitation || {});

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function() {
              var newInvitation = $scope.newInvitation;
              EventSections.nextResponsiblePersonUpdate(eventSection.doc._id, newInvitation)
                .then(function() {
                  Notify.success('The next responsible person has been updated successfully');
                  $uibModalInstance.dismiss('cancel');
                })
                .catch(function(e) {
                  Notify.error(
                    'An error occurred while updating the next responsible person: ' + e.message
                  );
                });
            };
          }
        ],
        size: 'lg',
        resolve: {
          eventSection: function() {
            return $scope.eventSection;
          },
          nextDefSection: function() {
            return ctrl.nextDefSection;
          }
        }
      });
    };
  }

  EventSectionNextUpdateController.$inject = [
    '$scope',
    '$uibModal',
    'EventSectionsService',
    'NotifyService'
  ];

  function EventSectionNextUpdateDirective() {
    return {
      scope: { eventSection: '=' },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-next-update.html',
      replace: true,
      controller: EventSectionNextUpdateController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventSectionNextUpdate', EventSectionNextUpdateDirective)
    .controller('EventSectionNextController', EventSectionNextUpdateController);
})();
