(function() {
  'use strict';

  function filter(DOC_TYPE_TITLES) {
    return function(input) {
      return DOC_TYPE_TITLES[input];
    };
  }

  filter.$inject = ['DOC_TYPE_TITLES'];

  angular.module('blocks.types')
    .filter('kzDocTypeTitle', filter);
})();
