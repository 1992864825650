(function() {
  'use strict';

  function AvatarController($rootScope, $scope, UsersStub, Utils) {
    var ctrl = this;

    var getInitial,
        setAvatarData;

    getInitial = function(word) {
      return word.length ? word.charAt(0).toUpperCase() : '';
    };

    setAvatarData = function(data) {
      data = data || {};
      var firstName = data.firstName ? data.firstName : ' ',
          lastName = data.lastName ? data.lastName : ' ',
          initials;

      if (!data.firstName && !data.lastName) {
        initials = '?';
      } else {
        initials = getInitial(firstName) + getInitial(lastName);
      }

      var imageUrl = null;
      if ($scope.meta && $scope.meta.dryrun) {
        imageUrl = 'images?object_id=' + $scope.meta.importerId + '&file_info=' +
        '' + JSON.stringify(data.profilePicture) + '&size=128';
      } else if (data.profilePicture) {
        imageUrl = 'images?object_id=' + data._id + '&field_id=profilePicture&size=128&rev=' +
          '' + data._rev;
      }

      return {
        initials: initials,
        name: firstName + ' ' + lastName,
        imageUrl: imageUrl
      };
    };

    $rootScope.$on('KZProfileUpdated', function(_evt, args) {
      var updatedProfileId = args.profile._id;
      var currentUserId = $scope.user._id;

      if (updatedProfileId === currentUserId) {
        ctrl.avatar = _.assign({ initials: '?' }, setAvatarData(args.profile));
      }
    });

    $scope.$watch('user', function(value) {
      if (!_.isUndefined(value)) {
        ctrl.avatar = _.assign({ initials: '?' }, value, setAvatarData(value));
      }
    });

    $scope.$watch('username', function(value) {
      if (!_.isUndefined(value)) {
        UsersStub.find(value)
          .then(function(user) {
            ctrl.avatar = _.assign({ initials: '?' }, user, setAvatarData(user));
          })
          .catch(Utils.showError);
      }
    });

    $scope.$watch('src', function(value) {
      if (!_.isUndefined(value)) {
        ctrl.avatar = {
          initials: '?',
          src: value
        };
      }
    });
  }

  function AvatarDirective() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        user: '=?',
        username: '=?',
        size: '@',
        meta: '=?',
        src: '=?'
      },
      templateUrl: 'app/layout/header/kz-avatar.html',
      controller: AvatarController,
      controllerAs: 'avatarCtrl'
    };
  }

  AvatarController.$inject = [
    '$rootScope',
    '$scope',
    'UsersStubService',
    'UtilsService'
  ];

  angular.module('kzHeaderCard')
    .directive('kzAvatar', AvatarDirective);
})();
