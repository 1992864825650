(function() {
  'use strict';

  function FilesService($uibModal, RestEasApi, FileUpload, Notify) {
    var service = new RestEasApi('files', { version: '' });

    service.openPreviewModal = function(fileInfo, originId) {
      FileUpload.getDownloadUrl(
        fileInfo,
        { oid: originId },
        { content_disposition_type: 'inline' }
      ).then(function(res) {
        var isImage = service.isImage(fileInfo.contentType);
        $uibModal.open({
          animation: true,
          templateUrl: 'app/blocks/widgets/forms/file-preview.html',
          size: isImage ? 'large' : 'huge',
          controller: ['url', '$uibModalInstance',
            function(url, $uibModalInstance) {
              this.url = url;
              this.file = fileInfo;
              this.isImage = isImage;
              this.dismiss = function() {
                $uibModalInstance.dismiss('cancel');
              };

              this.success = function() {
                $uibModalInstance.close();
              };
            }
          ],
          controllerAs: 'ctrl',
          resolve: {
            url: () => {
              return res.url;
            },
            file: () => {
              return fileInfo;
            }
          }
        });
      })
        .catch(function(err) {
          console.log(err);
          Notify.error(err.message || 'The file cannot be downloaded');
        });
    };

    service.isImage = function(contentType) {
      return contentType && (
        contentType.startsWith('image') || ['png', 'jpg', 'jpeg'].includes(contentType)
      );
    };

    service.isPdf = function(contentType) {
      return contentType && ['application/pdf', 'pdf'].includes(contentType);
    };

    service.canPreview = function(contentType) {
      return service.isImage(contentType) || service.isPdf(contentType);
    };

    return service;
  }

  FilesService.$inject = ['$uibModal', 'RestEasApi', 'FileUploadService', 'NotifyService'];

  angular.module('component.files')
    .factory('FilesService', FilesService);
})();
