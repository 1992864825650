(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.email-templates', {
        url: 'emails',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('emailTemplates.view', { promise: true });
          }]
        }
      })

      .state('epf.email-templates.index', {
        url: '',
        views: {
          content: {
            component: 'emailTypesComponent'
          }
        }
      })

      .state('epf.email-templates.email-logs', {
        url: '/log',
        views: {
          content: {
            component: 'emailLogsComponent'
          }
        },
        resolve: {
          defaultFilter: function() {
            return {
              block: ['users', 'events', 'goals']
            };
          }
        }
      })

      .state('epf.email-templates.email-logs-booking', {
        url: '/log_booking',
        views: {
          content: {
            component: 'emailLogsComponent'
          }
        },
        resolve: {
          defaultFilter: function() {
            return {
              block: ['payments', 'bookings']
            };
          }
        }
      })

      .state('epf.email-templates.email-type', {
        url: '/:emailTypeId',
        views: {
          content: {
            component: 'emailTypeComponent'
          }
        },
        resolve: {
          emailTypeId: ['$transition$', function($transition$) {
            return $transition$.params().emailTypeId;
          }]
        }
      })

      .state('epf.email-templates.email-type-log', {
        url: '/:emailTypeId/log',
        views: {
          content: {
            component: 'emailLogsComponent'
          }
        },
        resolve: {
          defaultFilter: ['$transition$', function($transition$) {
            return {
              email_type: [$transition$.params().emailTypeId]
            };
          }]
        }
      })

      .state('epf.email-templates.email-template', {
        url: '/:emailTypeId/:emailTemplateId?duplicateFrom',
        views: {
          content: {
            component: 'emailTemplateComponent'
          }
        },
        resolve: {
          emailTypeId: ['$transition$', function($transition$) {
            return $transition$.params().emailTypeId;
          }],
          emailTemplateId: ['$transition$', function($transition$) {
            return $transition$.params().emailTemplateId;
          }],
          duplicateFrom: ['$transition$', function($transition$) {
            return $transition$.params().duplicateFrom;
          }]
        }
      })

      .state('epf.email-templates.email-template-log', {
        url: '/:emailTypeId/:emailTemplateId/log',
        views: {
          content: {
            component: 'emailLogsComponent'
          }
        },
        resolve: {
          emailTypeId: ['$transition$', function($transition$) {
            return $transition$.params().emailTypeId;
          }],
          emailTemplateId: ['$transition$', function($transition$) {
            return $transition$.params().emailTemplateId;
          }],
          defaultFilter: ['$transition$', function($transition$) {
            return {
              email_type: [$transition$.params().emailTypeId],
              email_template: [$transition$.params().emailTemplateId]
            };
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.emailTemplates')
    .config(config);
})();
