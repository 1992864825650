(function() {
  'use strict';

  function SectionPreviewController($q, $uibModalInstance, $rootScope, $scope, resolvedDoc,
                                    eventType, Form, EventUtils, Roles,
                                    Events, EventSections, Notify, LocalizationService) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.resolvedDoc = resolvedDoc;
    ctrl.editMode = false;
    ctrl.eventType = eventType;
    ctrl.messages = [];
    ctrl.allowedActions = [];

    ctrl.def = _.find(eventType.sections, { _id: ctrl.resolvedDoc.doc.section });

    var buildForm = function() {
      ctrl.form = new Form();
      ctrl.def.fields.forEach(function(field) {
        var fld = EventUtils.createFormField(field, ctrl.options, ctrl.kzOpts);
        ctrl.form.addField(fld);
      });
    };

    var setActions = function() {
      ctrl.actions = [
        {
          label: 'Approve',
          icon: 'icon-check',
          klass: 'btn-sm btn-success',
          onClick: function() {
            return resolvedDoc.publish({ queue: false })
              .then(function() {
                Notify.success('Section has been approved', 'Pending setions');
                $rootScope.$broadcast('KZItemRemoved', { item: resolvedDoc });
                ctrl.close();
              })
              .catch(function(err) {
                ctrl.messages.unshift({
                  message: err.message,
                  type: 'warning'
                });
              });
          },
          showCondition: function() {
            return !ctrl.editMode && ctrl.allowedActions.indexOf('approve') !== -1;
          }
        },
        {
          label: 'Reject',
          icon: 'icon-cancel',
          klass: 'btn-sm btn-warning',
          onClick: function() {
            return resolvedDoc.reject({
              queue: false,
              confirmTxt: 'Are you sure you want to reject this event? This will return the ' +
                          'event back to the user who sent the invitation'
            })
              .then(function() {
                Notify.success('Section has been rejected', 'Pending setions');
                $rootScope.$broadcast('KZItemRemoved', { item: resolvedDoc });
                ctrl.close();
              })
              .catch(function(err) {
                ctrl.messages.unshift({
                  message: err.message,
                  type: 'warning'
                });
              });
          },
          showCondition: function() {
            return !ctrl.editMode && ctrl.allowedActions.indexOf('reject') !== -1;
          }
        },
        {
          label: 'Reopen',
          icon: 'icon-undo',
          klass: 'btn-sm btn-warning',
          onClick: function() {
            return Events.sectionReopen(ctrl.resolvedDoc.doc._id)
              .then(function() {
                Notify.success('Section has been returned to pending', 'Pending setions');
                $rootScope.$broadcast('KZItemRemoved', { item: resolvedDoc });
                ctrl.close();
              })
              .catch(function(err) {
                ctrl.messages.unshift({
                  message: err.message,
                  type: 'warning'
                });
              });
          },
          showCondition: function() {
            return !ctrl.editMode && ctrl.allowedActions.indexOf('reopen') !== -1;
          }
        }
      ];
    };

    var setRolesToAssign = function() {
      if ((resolvedDoc.doc.section_type || 'user') === 'user') {
        Roles.findByIds(ctrl.def.filledBy)
          .then(function(roles) {
            var invitable = _.filter(roles, function(role) {
              return role.doc.invitable;
            });
            ctrl.allRoles = roles;
            ctrl.invitableRoles = invitable;
          });
      }
    };

    var loadSection = function() {
      // Make sure we have up-to-date version
      return EventSections.findWithActions(resolvedDoc.doc._id, resolvedDoc.doc.user)
        .then(function(doc) {
          if (doc.section.state !== 'pending') {
            return $q.reject({ status: 403, message: 'Whatever' });
          }
          resolvedDoc.doc = doc.section;
          ctrl.allowedActions = doc.actions;
          ctrl.userInfo = doc.userInfo;
          ctrl.messages = doc.messages;
          return resolvedDoc.loadOriginalEvent();
        })
        .then(function() {
          buildForm();
          setActions();
          setRolesToAssign();
          ctrl.loaded = true;
        })
        .catch(function(err) {
          if (err && err.status === 403) {
            ctrl.error = { message: 'This response is no longer available to approve' };
          } else {
            ctrl.error = err;
          }
          ctrl.loaded = true;
        });
    };

    $scope.$on('kzLeaveEditMode', function() {
      ctrl.editMode = false;
      setActions();
      $scope.$broadcast('reloadInvitations');
    });

    $scope.$on('kzEnterEditMode', function() {
      ctrl.editMode = true;
      setActions();
    });

    loadSection();

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    ctrl.modal = $uibModalInstance;
  }

  SectionPreviewController.$inject = [
    '$q',
    '$uibModalInstance',
    '$rootScope',
    '$scope',
    'resolvedDoc',
    'resolvedEventType',
    'FormsService',
    'EventUtils',
    'RolesService',
    'EventsService',
    'EventSectionsService',
    'NotifyService',
    'LocalizationService'
  ];

  angular.module('component.admin')
    .controller('SectionPreviewController', SectionPreviewController);
})();
