(function() {
  'use strict';

  /**
   * Transclude that always takes current scope rather than from parent
   * see https://github.com/angular/angular.js/issues/7874#issuecomment-47647528
   *
   * @return {[type]} [description]
   */
  function inject($log) {
    return {
      link: function($scope, $element, _$attrs, _controller, $transclude) {
        if (!$transclude) {
          $log.error('Invalid use of kz-inject directive. The parent is not trancluded');
          throw { error: 'Invalid use of kz-inject directive. The parent is not trancluded' };
        }

        var innerScope = $scope.$new();
        $transclude(innerScope, function(clone) {
          $element.empty();
          $element.append(clone);
          $element.on('$destroy', function() {
            innerScope.$destroy();
          });
        });
      }
    };
  }

  inject.$inject = ['$log'];

  angular.module('blocks.utils')
    .directive('kzInject', inject);
})();
