(function() {
  'use strict';

  function DatabaseUtils() {
    var service = {};

    service.paginate = function(data) {
      var response = {};

      // if there are rows
      if (_.isArray(data.rows) && data.rows.length > 0) {
        response.startkey = data.rows[data.rows.length - 1].key; // set the new startkey
        response.totalItems = data.total_rows; // set the total number of rows
        response.items = data.rows; // set the rows

        // set a skip so we can skip the first row of the next query, since startkey is inclusive
        response.skip = 1;
      } else { // there are no more items
        response.items = [];
      }

      return response;
    };

    return service;
  }

  angular.module('blocks.db')
    .service('DatabaseUtils', DatabaseUtils);
})();
