(function() {
  'use strict';

  function InboxNotificationsController($scope, Announcements, Auth, Utils) {
    var ctrl = this;

    ctrl.alwaysShow = typeof $scope.alwaysShow !== 'undefined' ? $scope.alwaysShow : false;

    $scope.$on('KZReadNotification', function(_evt, args) {
      var read = _.find(ctrl.unread, { _id: args._id });
      ctrl.unread = _.without(ctrl.unread, read);
      ctrl.all = read.length;
    });

    Announcements.findAllForUser(Auth.currentUser(), { include_docs: true })
      .then(function(announcements) {
        ctrl.unread = _.chain(announcements)
          .filter(function(announcement) {
            return !announcement.isRead;
          })
          .value();
        ctrl.all = announcements.length;
      })
      .catch(Utils.showError);
  }

  function InboxNotificationsDirective() {
    return {
      restrict: 'E',
      scope: {
        alwaysShow: '='
      },
      templateUrl: 'app/components/announcements/directives/kz-inbox-notifications.html',
      controller: InboxNotificationsController,
      controllerAs: 'ctrl'
    };
  }

  InboxNotificationsController.$inject = [
    '$scope',
    'AnnouncementsService',
    'AuthService',
    'UtilsService'
  ];

  angular.module('component.announcements')
    .directive('kzInboxNotifications', InboxNotificationsDirective);
})();
