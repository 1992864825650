(function() {
  'use strict';

  function Slot($rootScope, $log, Events, Profile) {
    return {
      restrict: 'A',
      templateUrl: 'app/layout/sidebar/cards/advanced-search.card.html',
      link: function(scope) {
        if (_.isUndefined(scope.card.diroptions)) {
          $log.error('This card requires diroptions');
          return;
        }

        if (_.isUndefined(scope.card.diroptions.source)) {
          $log.error('This card has to specify \'source\' in diroptions');
          return;
        }

        if (_.isUndefined(scope.card.diroptions.getSearch)) {
          $log.error('This card has to specify \'getFacets\' in diroptions');
          return;
        }

        // var search = $injector.invoke(scope.card.diroptions.getSearch);
        // $q.when(search).then(function(result) {
        //   scope.search = result;
        // });

        var setupModel = function(listName) {
          scope.search = undefined;
          scope.searchModel = undefined;
          scope.listName = undefined;
          window.setTimeout(function() {
            scope.listName = listName;
            scope.search = Events.currentSearch;
            scope.searchModel = listName ? Profile.getListPreferences(listName).current : undefined;
          }, 0);
        };

        setupModel(Events.currentList);

        // scope.$on('KZCurrentListChange', function(_evt, args) {
        //   debugger
        //   scope.search = args.search;
        //   setupModel(args.list);
        // });
        scope.$watch(function() {
          return Events.currentList;
        }, function(listName) {
          setupModel(listName);
        });

        scope.apply = function() {
          $rootScope.$broadcast('KZApplySearch');
        };

        // /* Ordering */
        // scope.orderFields = [
        //   {
        //     key: 'orderBy',
        //     type: 'discrete',
        //     templateOptions: {
        //       label: 'Order by',
        //       itemLabel: 'title',
        //       itemValue: 'id',
        //       options: (function() {
        //         var res = [];
        //         _.forOwn(scope.search.orderGroups, function(item, key) {
        //           res.push({
        //             id: key,
        //             title: item.title
        //           });
        //         });
        //         return res;
        //       })()
        //     }
        //   }
        // ];
      }
    };
  }

  Slot.$inject = ['$rootScope', '$log', 'EventsService', 'ProfileService'];

  angular.module('layout.sidebar')
    .directive('sidebarAdvancedSearch', Slot);
})();
