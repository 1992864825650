(function() {
  'use strict';

  function StatusController($scope, $rootScope, $q, $timeout, UserStore,
    ConfStore, VersionStore, LocalizationService, Utils) {
    var ctrl = this;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('datetime');
    ctrl.stores = [UserStore, ConfStore, VersionStore];
    var timer;

    Utils.setPageTitle('Status');

    var loadStores = function(options) {
      options = options || {};
      var promises = _.map(ctrl.stores, function(store) {
        if (options.clear) {
          store.stores = {};
        }
        var promise;
        if (options.update) {
          promise = store.updateTypes({ cached: false });
        } else {
          promise = store.loadTypes({ cached: false });
        }

        return promise;
      });

      return $q.all(promises);
    };

    var clearStores = function() {
      var promises = _.map(ctrl.stores, function(store) {
        var promise = store.clearTypes();
        return promise;
      });

      return $q.all(promises);
    };

    var load = function(options) {
      options = options || {};
      console.log('Loading');
      ctrl.data = [];
      _.forEach(ctrl.stores, function(store) {
        ctrl.data.push(store.inspect());
      });

      if (options.keepLoading) {
        timer = $timeout(function() {
          load({ keepLoading: true });
        }, 5000);
      }
    };

    load({ keepLoading: true });
    ctrl.load = load;
    ctrl.loadAll = function() {
      return loadStores().then(load);
    };

    ctrl.reloadAll = function() {
      return loadStores({ clear: true }).then(load);
    };

    ctrl.updateAll = function() {
      return loadStores({ clear: true, update: true }).then(load);
    };

    ctrl.clearType = function(storeId, type) {
      var store = _.find(ctrl.stores, { id: storeId });

      store.stores[type] = {};
      store.dirty[type] = true;

      $rootScope.$broadcast('KZStoreInvalidated', { type: type });
      return load();
    };

    ctrl.clearAll = function() {
      return clearStores();
    };

    $scope.$on('$destroy', function() {
      $timeout.cancel(timer);
    });
  }

  StatusController.$inject = [
    '$scope',
    '$rootScope',
    '$q',
    '$timeout',
    'BaseUserStoreService',
    'BaseConfStoreService',
    'BaseVersionStoreService',
    'LocalizationService',
    'UtilsService'
  ];

  angular.module('component.settings')
    .controller('StatusController', StatusController);
})();
