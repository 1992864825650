(function() {
  'use strict';

  function TargetFactory(
    $q,
    $log,
    Event,
    Events,
    EventSection,
    EventSections,
    Goals,
    Network,
    Utils,
    TARGETS_STATES
  ) {
    var Target = function() {
      this._id = '';
      this.doc = {};
      this.minEventsToBeLinked = 0;
      this.progress = 0;
      this.state = '';
      this.goal = {};
      this.periodId = undefined;
    };

    Target.setDefaults = function() {
      return {
        _id: Utils.guid(),
        title: '',
        conditions: [],
        linkedEvents: []
      };
    };

    // a target can have different conditions (only ORs)
    Target.setDefaultsCondition = function() {
      return {
        _id: Utils.guid(),
        count: 0,
        filters: [],
        autolinked: 'autoLink'
      };
    };

    Target.setDefaultsFilter = function() {
      return {
        _id: Utils.guid(),
        condition: '=',
        value: undefined
      };
    };

    Target.prototype.getDefaultCache = function() {
      return {
        linkedEvents: [],
        countable: []
      };
    };

    Target.init = function(targetDoc, goal, opts) {
      $log.debug('Initiating target', targetDoc._id);
      var options = opts || {};

      var target = new Target();
      target._id = targetDoc._id;
      target.doc = targetDoc;
      target.goal = goal;

      return target.getCachedPeriods(options)
        .then(function(cache) {
          target.cachedPeriods = cache;
          target.setPeriod(options.periodId);
          return target;
        })
        .then(function() {
          return target.loadLinkedEvents(targetDoc, options);
        })
        .then(function(linkedEventsDetails) {
          target.linkedEvents = linkedEventsDetails;
          if (!_.isUndefined(linkedEventsDetails.privatesCount)) {
            target.linkedEvents = linkedEventsDetails.events;
          }
          return target;
        })
        .then(function() {
          $log.debug('Initiating target', targetDoc._id, 'DONE');
          return target;
        });
    };

    Target.prototype.setPeriod = function(periodId) {
      if (periodId) {
        if (_.isUndefined(this.doc.conditions) || _.isUndefined(this.doc.conditions[0]) ||
          _.isUndefined(this.doc.conditions[0].periodMeasurements)
        ) {
          if (periodId !== '__others__') {
            return false;
          }
          periodId = '__global__';
        }

        if (periodId && periodId !== '__global__') {
          var periodMeasurement = _.find(this.doc.conditions[0].periodMeasurements,
            function(measurement) {
              return measurement.period === periodId;
            }
          );
          if (periodMeasurement === undefined) {
            return false;
          }
        }
      }

      // if (!_.isUndefined(periodMeasurement.filterMeasurements)) {
      //   this.conditions[0].filterMeasurements = periodMeasurement.filterMeasurements;
      // } else {
      //   this.conditions[0].count = periodMeasurement.count;
      // }
      // delete this.conditions[0].periodMeasurements;

      periodId = periodId || '__global__';
      this.periodId = periodId;
      this.progress = this.calculateProgress(periodId, this.cachedPeriods);
      this.state = this.getState();
      return true;
    };

    Target.prototype.getState = function() {
      var state;
      if (this.progress.ratio >= 1) {
        state = TARGETS_STATES.completed.id;
      } else if (this.progress.progress > 0) {
        state = TARGETS_STATES.inProgress.id;
      } else {
        state = TARGETS_STATES.notStarted.id;
      }

      return state;
    };

    function getFilterMeasurementsToBeLinked(filterMeasurements) {
      return _.sumBy(filterMeasurements, 'count') / filterMeasurements.length;
    }

    Target.prototype.getMinEventsToBeLinked = function() {
      console.error('getMinEventsToBeLinked is obsolete');
      if (!this.conditions.length) {
        return 0;
      } else if (this.conditions.length > 1) {
        console.error('Why? There should be only one condition');
        return 0;
      }

      var condition = this.conditions[0];
      if (!_.isUndefined(condition.filterMeasurements)) {
        return getFilterMeasurementsToBeLinked(condition.filterMeasurements);
      }

      return condition.count;
    };

    Target.prototype.loadLinkedEvents = function(targetDoc, options) {
      if (_.isUndefined(options) || !options.loadLinkedEvents) {
        return $q.when(targetDoc.linkedEvents);
      }

      var proms;
      var linkedEvents = targetDoc.linkedEvents;
      if (options.onlyPeriod && this.periodId) {
        var periodDetails = this.getPeriodDetails(this.periodId);
        linkedEvents = periodDetails.details.linkedEvents;
      }

      var isLocal = this.goal.isMine();

      if (this.goal.isMine() && Network.isOffline()) {
        proms = [
          Events.findAllByIds(linkedEvents),
          EventSections.findAllByEvent(linkedEvents)
        ];
      } else {
        proms = [
          Events.findAllByIdsFor(
            this.goal.doc.user,
            this.goal.doc._id,
            this.doc._id,
            this.periodId
          ),
          $q.when([]) // we don't want to retrieve any eventSections on someone else view
        ];
      }

      return $q.all(proms)
        .then(function(result) {
          var events = [];
          var deletedCount = result[0].deleted_count || 0;
          var privatesCount = 0;

          // the not local one return a privates_count in more than the local one.
          if (!_.isUndefined(result[0].privates_count)) {
            events = result[0].events;
            privatesCount = result[0].privates_count;
          } else {
            events = result[0];
          }

          var eventsIds = _.map(events, function(event) {
            return event.doc._id;
          });
          var eventSections = _.filter(result[1], function(eventSection) {
            return _.indexOf(eventsIds, eventSection.doc.event) === -1;
          });

          var objs = events.concat(eventSections);

          var eventProms = _.map(objs, function(obj) {
            var ev;
            if (obj.doc.type === 'event') {
              ev = new Event(obj.doc, { isLocal: isLocal });
            } else {
              ev = new EventSection(obj.doc, { isLocal: isLocal });
            }

            var evLoader;
            if (options.init) {
              evLoader = ev.init();
            } else {
              evLoader = ev.loadEventType()
                .then(function(eventType) {
                  ev.updateViewLink();
                  ev.eventType = eventType;
                  return ev.loadSections();
                });
            }

            return evLoader
              .then(function() {
                var eventType = ev.eventType;
                ev.loaded = true;

                var id,
                    name,
                    startDate;

                if (ev.doc.eventType === undefined) {
                  name = 'Quick Note';
                  startDate = ev.meta.startDate;
                } else if (ev.doc.type === 'event') {
                  id = ev.doc._id;
                  name = eventType.name;
                  startDate = ev.doc.startDate;
                } else {
                  id = ev.doc.event;
                  name = eventType.name;
                  startDate = ev.meta.startDate;
                }

                var result = {
                  id: id,
                  name: name,
                  startDate: startDate
                };
                if (options.includeObj) {
                  result.obj = ev;
                }

                return result;
              });
          });

          return $q.all(eventProms)
            .then(function(events) {
              return [_.sortBy(events, 'startDate'), deletedCount, privatesCount];
            });
        })
        .then(function(result) {
          return { events: result[0], deletedCount: result[1], privatesCount: result[2] };
        });
    };

    Target.prototype.getProgress = function() {
      console.error('getProgress is obsolete');
      var countableLinkedEvents = this.doc.countableLinkedEvents || [];
      if (this.periodId) {
        var _this = this;
        countableLinkedEvents = _.filter(this.doc.countableLinkedEvents, function(linkedEvent) {
          return linkedEvent.periodId === _this.periodId;
        });
      }

      if (
        !_.isUndefined(this.conditions) &&
        !_.isEmpty(this.conditions) && this.conditions[0].filterMeasurements
      ) {
        var filterMeasurements = this.conditions[0].filterMeasurements;
        if (!_.isUndefined(filterMeasurements)) {
          var countPerBlId = {}; // { blueprintId: { aggr: '', values: [] } }
          _.forEach(filterMeasurements, function(measurement) {
            countPerBlId[measurement.blueprintId] = { aggr: measurement.aggr, values: [] };
          });

          _.forEach(countableLinkedEvents, function(countableLinkedEvent) {
            _.forEach(countableLinkedEvent.blueprintsInFields, function(value, blId) {
              if (_.indexOf(_.keys(countPerBlId), blId) === -1) {
                return;
              }

              countPerBlId[blId].values.push(value);
            });
          });

          var values = [];
          _.forEach(countPerBlId, function(item) {
            var count = item.values.length,
                sum = _.sum(item.values);

            var value = 0;
            if (item.aggr === 'count') {
              value = count;
            } else if (item.aggr === 'sum') {
              value = sum;
            } else if (item.aggr === 'avg' && count) {
              value = sum / count;
            }

            values.push(value);
          });

          // do the average
          return _.sum(values) / values.length;
        }
      }

      return countableLinkedEvents.length; // only count for now
    };

    Target.prototype.getPeriodById = function(periodId) {
      if (!this.doc.conditions) {
        return;
      }

      return _.find(this.doc.conditions[0].periodMeasurements || [], function(count) {
        return count.period === periodId;
      });
    };

    function filtersToSearchModel(filters) {
      return Utils.filtersToSearchModel(filters);
    }

    Target.prototype.getPeriodFilters = function(periodId) {
      periodId = periodId || this.periodId;
      if (_.isUndefined(periodId)) {
        return {};
      }

      var baseDueDate = this.goal.getPeriodById(periodId),
          baseDDSearchModel = baseDueDate ? filtersToSearchModel(baseDueDate.filters) : {},
          targetDueDate = this.getPeriodById(periodId),
          targetDDSearchModel = targetDueDate ? filtersToSearchModel(targetDueDate.filters) : {};
      return Object.assign(baseDDSearchModel, targetDDSearchModel);
    };

    Target.prototype.getLinkingFilters = function() {
      if (_.isUndefined(this.doc.conditions) || _.isEmpty(this.doc.conditions)) {
        return [];
      }

      return this.doc.conditions[0].filters;
    };

    Target.prototype.getLinkingSearchModel = function() {
      var _linkingFilters = this.getLinkingFilters();
      return filtersToSearchModel(_linkingFilters);
    };

    Target.prototype.getProgressFilters = function() {
      if (_.isUndefined(this.doc.conditions) || _.isEmpty(this.doc.conditions)) {
        return [];
      }

      var condition = this.doc.conditions[0];
      if (condition.differentAsLinkingFilters === false) {
        return condition.filters;
      }

      return condition.progressFilters;
    };

    Target.prototype.getProgressSearchModel = function() {
      var periodFilters = this.getPeriodFilters(),
          progressFilters = filtersToSearchModel(this.getProgressFilters());

      var progressSearchModel = periodFilters;
      _.forEach(progressFilters, function(values, key) {
        if (!progressSearchModel[key]) {
          progressSearchModel[key] = [];
        }

        _.forEach(values, function(value) {
          progressSearchModel[key].push(value);
        });
      });

      return progressSearchModel;
    };

    Target.prototype.isCompleted = function() {
      if (this.progress === undefined) {
        throw new Error('Cannot get completed without progress caluclated');
      }
      return this.progress.ratio >= 1;
    };

    Target.prototype.getBorderClass = function() {
      var style = 'progress-border-';
      if (this.isCompleted()) {
        style += 'complete';
      } else {
        style += 'pending';
      }

      return style;
    };

    /**
     * Return relevant event types that can satisfy this target
     *
     * @return {arrau} Array of event types ids
     */
    Target.prototype.getRelevantEventTypes = function() {
      var filters = _.filter(this.getLinkingFilters(), { dataType: 'eventType_versionGroupId' });

      if (!_.isArray(filters)) {
        return [];
      }

      return _.chain(filters)
        .map(function(et) {
          return et.value;
        })
        .reduce(function(sum, item) {
          return sum.concat(item);
        }, [])
        .value();
    };

    Target.prototype.isAutoLinked = function() {
      return !_.chain(this.doc.conditions)
        .filter(function(condition) {
          return condition.autolinked === 'autoLink';
        })
        .isEmpty()
        .value();
    };

    // update
    Target.prototype.updateLinkedEvents = function(updateFunc, eventId) {
      var linkedEvents = angular.copy(this.doc.linkedEvents);
      this.doc.linkedEvents = updateFunc(linkedEvents, eventId);
    };

    Target.prototype.linkEventToTarget = function(eventToLink) {
      var _this = this;
      return Goals.targetLinkEvent({
        goal: this.goal.doc._id,
        target: this.doc._id,
        data: {
          add: [eventToLink]
        }
      }).then(function(res) {
        _this.goal.doc._rev = res.rev;
        return res;
      });
    };

    Target.prototype.unLinkEventFromTarget = function(eventIdToRemove) {
      var _this = this;
      return Goals.targetLinkEvent({
        goal: this.goal.doc._id,
        target: this.doc._id,
        data: {
          remove: [eventIdToRemove]
        }
      }).then(function(res) {
        _this.goal.doc._rev = res.rev;
        return res;
      });
    };

    Target.prototype.unLinkAllEventsFromTarget = function() {
      var _this = this;
      return Goals.targetLinkEvent({
        goal: this.goal.doc._id,
        target: this.doc._id,
        data: {
          set: []
        }
      }).then(function(res) {
        _this.goal.doc._rev = res.rev;
        return res;
      });
    };

    Target.prototype.getDetails = function(periodId) {
      return this.getPeriodDetails(periodId).details;
    };

    Target.prototype.getPeriodDetails = function(periodId) {
      periodId = periodId || this.periodId || '__global__';
      var cache = this.cachedPeriods;
      var details;
      var period;
      if (periodId === '__global__') {
        var condition = this.doc.conditions && this.doc.conditions[0];
        details = cache.global || {};
        if (condition !== undefined) {
          period = {
            filterMeasurements: condition.filterMeasurements,
            count: condition.count
          };
        } else {
          period = {};
        }
      } else {
        details = _.find(cache.periods, { periodId: periodId });
        period = this.getPeriodById(periodId);
      }
      return {
        details: details || this.getDefaultCache(),
        period: period
      };
    };

    Target.prototype.calculateProgress = function(periodId, cache) {
      var periodDetails = this.getPeriodDetails(periodId, cache);
      return this.calculateProgressForPeriod(periodDetails.details, periodDetails.period);
    };

    Target.prototype.calculateProgressForPeriod = function(details, period) {
      if (details === undefined || period === undefined) {
        return {
          progress: 0,
          max: 0,
          ratio: 0
        };
      }

      var filterMeasurements = period.filterMeasurements;
      var progress;
      var max;
      if (!filterMeasurements) {
        progress = details.countable.length;
        max = period.count || 0;
      } else {
        progress = this.calculateProgressForMeasurements(filterMeasurements, details.countable);
        max = this.calculateMaxForMeasurements(filterMeasurements);
      }

      return {
        progress: progress,
        max: max,
        ratio: Math.min(max !== 0 ? progress / max : 0, 1),
        other: details.linkedEvents.length - details.countable.length
      };
    };

    Target.prototype.calculateProgressForMeasurements = function(
      filterMeasurements, countableLinkedEvents
    ) {
      var measurements = [];
      _.forEach(filterMeasurements, function(measurement) {
        var values = [];
        // Backward compatibility
        if (measurement.blueprintId) {
          measurement = angular.copy(measurement);
          measurement.blueprints = [measurement.blueprintId];
        }

        _.forEach(countableLinkedEvents, function(countableLinkedEvent) {
          var totalPerEvent = _.sumBy(_.map(measurement.blueprints || [], function(blId) {
            return (countableLinkedEvent.blueprintsInFields || {})[blId] || 0;
          }));
          values.push(totalPerEvent);
        });

        measurements.push({ aggr: measurement.aggr, values: values });
      });

      var values = [];
      _.forEach(measurements, function(item) {
        var count = item.values.length,
            value = 0;
        if (item.aggr === 'count') {
          value = count;
        } else if (item.aggr === 'sum') {
          value = _.sum(item.values);
        } else if (item.aggr === 'avg' && count) {
          value = _.sum(item.values) / count;
        } else if (item.aggr === 'max') {
          value = item.values.length > 0 ? _.max(item.values) : 0;
        } else if (item.aggr === 'min') {
          value = item.values.length > 0 ? _.min(item.values) : 0;
        }

        // show max 3 decimal places but remove trailing zeros
        values.push(_.round(value, 3));
      });

      // do the average
      if (values.length === 0) {
        return 0;
      }
      return _.sum(values);
    };

    Target.prototype.calculateMaxForMeasurements = function(filterMeasurements) {
      if (filterMeasurements.length === 0) {
        return 0;
      }

      return _.sumBy(filterMeasurements, 'count') / filterMeasurements.length;
    };

    // same as Target.get_progression_cache
    Target.prototype.getCachedPeriods = function(opts) {
      var options = opts || {};
      var _this = this;
      var cacheId = this.doc.progressionCacheId || this.goal.doc._id + '_' + this.doc._id;
      if (options.usingLight) {
        if (!_.isEmpty(_this.doc.cachedPeriods)) {
          return $q.when(_this.doc.cachedPeriods);
        }
        return $q.when({
          generatedBy: 'frontend',
          generatedOn: Utils.now(),
          periods: [],
          global: {
            linkedEvents: _this.doc.linkedEvents,
            countable: []
          }
        });
      }

      return Goals.findTargetProgressionCache(this.goal.doc.user, cacheId)
        .catch(function(err) {
          console.log('Could not load target cache. Returing an empty one', err);
          if (!_.isUndefined(_this.doc.cachedPeriods)) {
            return _this.doc.cachedPeriods;
          }
          return {
            generatedBy: 'frontend',
            generatedOn: Utils.now(),
            periods: [],
            global: {
              linkedEvents: _this.doc.linkedEvents,
              countable: []
            }
          };
        });
    };

    Target.prototype.updateGoal = function() {
      var docCopied = angular.copy(this.doc);
      return this.goal.updateTarget(docCopied);
    };


    Target.prototype.save = function() {
      var docCopied = angular.copy(this.doc);
      return this.goal.saveTarget(docCopied);
    };

    return Target;
  }

  TargetFactory.$inject = [
    '$q',
    '$log',
    'EventFactory',
    'EventsService',
    'EventSectionFactory',
    'EventSectionsService',
    'GoalsService',
    'NetworkService',
    'UtilsService',
    'TARGETS_STATES'
  ];

  angular.module('component.goals')
    .factory('TargetFactory', TargetFactory);
})();
