(function() {
  'use strict';

  function reload($timeout) {
    return {
      restrict: 'A',
      scope: {
        obj: '='
      },
      transclude: true,
      template: '<ng-transclude></ng-transclude>',
      link: function(scope, element) {
        // var done = function() {
        //   $timeout(function() {
        //     angular.element(element).removeClass('slot-reloaded');
        //   }, 1000);
        // };

        scope.$watch('obj.recentlyUpdated', function(value) {
          $timeout(function() {
            if (value) {
              angular.element(element).addClass('slot-reloaded');
            } else {
              angular.element(element).removeClass('slot-reloaded');
            }
          });
        });
        // scope.$on('StoreUpdated', function(_evt, args) {
        //   if (scope.obj.doc._id === args.doc._id) {
        //     angular.element(element).addClass('slot-reloaded');
        //     if (_.isFunction(scope.obj.reload)) {
        //       scope.obj.reload(args.doc)
        //         .then(done);
        //     } else {
        //       scope.obj.doc = args.doc;
        //       done();
        //     }
        //   }
        // });
      }
    };
  }

  reload.$inject = ['$timeout'];

  angular.module('blocks.utils')
    .directive('kzReload', reload);
})();
