(function() {
  'use strict';

  function Slot($q, $rootScope, Utils, LocalizationService) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/goals/cards/target.linking.card.html',
      link: function(scope) {
        scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

        var postAction = function(itemToRemove, hasBeenAutoClosed) {
          _.remove(scope.target.linkedEvents, function(val) {
            if (itemToRemove === '__all__') {
              return true;
            }

            return val.id === itemToRemove;
          });

          $rootScope.$broadcast('kzReloadActions');
          $rootScope.$broadcast('HasBeenAutoClosed', { hasBeenAutoClosed: hasBeenAutoClosed });
        };

        var removeAlert = function(message) {
          var def = $q.defer();

          Utils.swal({
            title: message,
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Remove'
          },
            function(isConfirm) {
              if (isConfirm) {
                return def.resolve();
              }

              return def.reject();
            });

          return def.promise;
        };

        scope.remove = function(itemId) {
          var message = 'You are about to remove an event link for this target. ' +
            'Would you like to proceed?';
          removeAlert(message)
            .then(function() {
              return scope.target.unLinkEventFromTarget(itemId);
            })
            .then(function(result) {
              postAction(itemId, result.hasBeenAutoClosed);
            });
        };

        scope.removeAll = function() {
          var message = 'You are about to remove all event links for this ' +
            'target. Would you like to proceed?';

          removeAlert(message)
            .then(function() {
              return scope.target.unLinkAllEventsFromTarget();
            })
            .then(function(result) {
              postAction('__all__', result.hasBeenAutoClosed);
            });
        };

        $rootScope.$on('UpdateLinkingStatus', function(_event, args) {
          scope.target = args.target;
        });
      }
    };
  }

  Slot.$inject = ['$q', '$rootScope', 'UtilsService', 'LocalizationService'];

  angular.module('component.goals')
    .directive('targetLinkingCard', Slot);
})();
