(function() {
  'use strict';

  function filter() {
    return function(input, format) {
      var value;

      if (_.isString(input)) {
        value = ['yes', 'true', '1'].indexOf(input.toLowerCase()) !== -1;
      } else {
        value = input;
      }

      if (format === 'yesnoblank' && _.isUndefined(value)) {
        return '';
      }

      if (format.indexOf('yesno') > -1) {
        return value ? 'Yes' : 'No';
      }

      return value;
    };
  }

  angular.module('blocks.utils')
    .filter('smartboolean', filter);
})();
