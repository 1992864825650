(function() {
  'use strict';

  function AuditLogsController($scope) {
    var ctrl = this;

    ctrl.dates = $scope.profileDates;

    ctrl.expanded = typeof $scope.expanded !== 'undefined' ? $scope.expanded : false;
    ctrl.showHeader = typeof $scope.showHeader !== 'undefined' ? $scope.showHeader : true;
  }

  AuditLogsController.$inject = ['$scope'];

  function audtiLogsDirective() {
    return {
      scope: {
        profileDates: '=',
        expanded: '=?',
        limit: '=?',
        showHeader: '=?',
        perPage: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/directives/auditLogs.html',
      replace: true,
      controller: AuditLogsController,
      controllerAs: 'ctrl',
      link: function(scope) {
        scope.options = {};
        scope.perPage = scope.perPage || 0;
      }
    };
  }

  angular.module('component.users')
    .directive('auditLogs', audtiLogsDirective)
    .controller('AuditLogsController', AuditLogsController);
})();
