(function() {
  'use strict';


  function EventViewController($scope, Api) {
    var ctrl = this;
    ctrl.event = $scope.event;
    ctrl.parent = $scope.parent;

    ctrl.allowActions = false;
    if (ctrl.event.doc.state === 'complete') {
      ctrl.allowActions = true;
    } else {
      ctrl.event.hasPerm('deleteMultiSourceResponse')
        .then(function() {
          ctrl.allowActions = true;
        });
    }

    // Event has to be loaded
    if (!ctrl.event.loaded) {
      throw { message: 'Event has to be loaded' };
    }

    if (_.isEmpty(ctrl.event.allSections)) {
      ctrl.event.loadSections();
    }

    if (ctrl.event.currentSection.def && ctrl.event.currentSection.def.multiSource) {
      // Not returning on purpose as this does not need to slow down loading
      ctrl.event.getSectionTokens(ctrl.event.currentSection._id)
        .then(function(data) {
          ctrl.tokens = _.map(data, function(token) {
            return {
              url: Api.absHostUrl('token_url', { token: token.short_token }, { baseUrl: true })
            };
          });
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  }

  EventViewController.$inject = [
    '$scope',
    'ApiService'
  ];

  function EventViewDirective() {
    return {
      scope: {
        event: '=',
        noEdit: '=?',
        parent: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-view.html',
      replace: true,
      controller: EventViewController,
      controllerAs: 'eventViewCtrl'
    };
  }


  angular.module('events.directives')
    .directive('eventView', EventViewDirective)
    .controller('eventViewController', EventViewController);
})();
