(function() {
  'use strict';

  function StatusDirective() {
    return {
      restrict: 'AE',
      controller: 'NetworkController',
      controllerAs: 'ctrl',
      templateUrl: 'app/blocks/network/network.status.html'
    };
  }

  angular.module('blocks.network')
    .directive('kzNetStatus', StatusDirective);
})();
