(function() {
  'use strict';

  function OrganisationsController($state, Profile, Organisations, OrganisationSearch,
                                    List, Security, Utils) {
    var ctrl = this;
    ctrl.options = {
      stateField: 'state',
      trackBy: 'id'
    };

    Utils.setPageTitle('Organisations');

    ctrl.searchModel = Profile.getListPreferences('organisations').current;

    ctrl.list = new List({
      search: OrganisationSearch.search,
      model: ctrl.searchModel,
      idField: 'id'
    });

    Organisations.findAllWithCluster()
      .then(function(data) {
        if (data.length > 0) {
          ctrl.list.doLoadItems(data);
        }
      }).catch(function(err) {
        console.log(err);
      });

    ctrl.actions = [
      {
        label: 'Control panel',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.organisations.control-panel'); })(),
        showCondition: Security.hasPermission.bind(this, 'organisations.edit'),
        klass: 'btn-info'
      },
      {
        label: 'New organisation',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.organisations.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'organisations.edit'),
        klass: 'btn-success'
      }
    ];

    // Defined here so that I don't have to create a special slot that does nothing
    ctrl.options.actionButtons = function(item) {
      return [
        {
          label: 'Edit settings',
          icon: 'icon-edit',
          href: (function() {
            return $state.href('epf.organisations.settings', { orgId: item.id });
          })(),
          showCondition: function() {
            if (!item.local) {
              return false;
            }
            return Security.hasPermission('organisations.edit');
          },
          klass: 'text-info'
        }
      ];
    };
  }

  OrganisationsController.$inject = [
    '$state',
    'ProfileService',
    'OrganisationsService',
    'OrganisationSearch',
    'ListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.organisations')
    .controller('OrganisationsController', OrganisationsController);
})();
