(function() {
  'use strict';

  function TemplatesCard(
    $state,
    $rootScope,
    $uibModal,
    EmailTypes,
    EmailType,
    EmailTemplate,
    EmailTemplates,
    DEFAULT_TEMPLATE_ID,
    Security,
    Notify
  ) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/email-templates/cards/templates.html',
      link: function(scope) {
        var emailType;

        scope.emailTypeId = $state.params.emailTypeId;
        scope.canEditTemplate = false;
        scope.defaultTemplateId = DEFAULT_TEMPLATE_ID;

        var makeDocs = function(item, emailType) {
          return new EmailTemplate(item, emailType);
        };


        Security.hasPermission('emailTemplates.edit')
          .then(function(perm) {
            scope.canEditTemplate = perm;
          });

        function load() {
          EmailTypes.find(scope.emailTypeId)
          .then(function(emailTypeDoc) {
            emailType = new EmailType(emailTypeDoc);
            return EmailTypes.getByType(scope.emailTypeId);
          })
          .then(function(data) {
            var defaultTemplate = {
              _id: DEFAULT_TEMPLATE_ID,
              name: 'Default template',
              email_type: emailType.doc.id,
              partials: emailType.doc.defaults
            };

            var items = [makeDocs(defaultTemplate, emailType)];

            if (data.length > 0) {
              items = items.concat(data.map(function(item) {
                return makeDocs(item, emailType);
              }));
            }
            scope.templates = items;
          }).catch(Notify.error);
        }

        load();
        scope.reload = function() {
          // Not sure about the implementation but it works for now
          $rootScope.$broadcast('EmailTypeChanged');
        };

        scope.$on('EmailTypeChanged', function() {
          load();
        });

        scope.isCurrent = function(id) {
          return EmailTypes.isCurrent(id, emailType);
        };

        scope.canSetAsDefault = function(id) {
          return (
            !scope.isCurrent(id) &&
            Security.hasPermission.bind(this, 'emailTemplates.edit')
          );
        };

        var preview = function(item) {
          $uibModal.open({
            animation: true,
            template: '<email-type-preview email-type-id="ctrl.emailTypeId" ' +
                    'preferred-template-id="ctrl.preferredTemplateId" ' +
                    'on-close="$close()"/></email-type-preview>',
            controller: ['emailTypeId', 'preferredTemplateId',
              function(emailTypeId, preferredTemplateId) {
                this.emailTypeId = emailTypeId;
                this.preferredTemplateId = preferredTemplateId;
              }
            ],
            controllerAs: 'ctrl',
            resolve: {
              emailTypeId: function() {
                return scope.emailTypeId;
              },
              preferredTemplateId: function() {
                return item.doc._id;
              }
            },
            size: 'lg'
          });
        };

        var canDeleteTemplate = function(templateId) {
          return templateId !== DEFAULT_TEMPLATE_ID && !scope.isCurrent(templateId);
        };

        scope.getActions = function(item) {
          return [
            {
              label: 'Preview',
              icon: 'icon-edit',
              onClick: function() {
                preview(item);
              },
              showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
              klass: 'text-info'
            },
            {
              label: 'Use this template',
              icon: 'icon-edit',
              onClick: function() {
                EmailTypes.setCurrent(item.doc.email_type, item.doc._id)
                  .then(function() {
                    Notify.success('We\'ll use this template from now on.');
                    scope.reload();
                  })
                  .catch(Notify.error);
              },
              showCondition: function() {
                return scope.canSetAsDefault(item.doc._id);
              },
              klass: 'text-info'
            },
            {
              label: 'Send test email',
              icon: 'icon-edit',
              onClick: function() {
                EmailTypes.sendTestEmail(emailType.doc.id, item.doc._id)
                  .then(function() {
                    Notify.success('A test email has been sent to your email address');
                  })
                  .catch(function(err) {
                    var msg = 'Unknown error';
                    if (err && err.message) {
                      msg = err.message;
                    }
                    Notify.error(msg);
                  });
              },
              showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
              klass: 'text-info'
            },
            {
              label: 'Delete',
              icon: 'icon-trash',
              onClick: function() {
                EmailTemplates.remove(item.doc._id)
                  .then(function() {
                    Notify.success('Template has been removed');
                  })
                  .catch(function(err) {
                    var msg = 'Unknown error';
                    if (err && err.message) {
                      msg = err.message;
                    }
                    Notify.error(msg);
                  });
              },
              showCondition: function() {
                return (
                  canDeleteTemplate(item.doc._id) &&
                  Security.hasPermission('emailTemplates.edit')
                );
              },
              klass: 'text-danger'
            }
          ];
        };
      }
    };
  }

  TemplatesCard.$inject = [
    '$state',
    '$rootScope',
    '$uibModal',
    'EmailTypesService',
    'EmailTypeFactory',
    'EmailTemplateFactory',
    'EmailTemplatesService',
    'DEFAULT_TEMPLATE_ID',
    'SecurityService',
    'NotifyService'
  ];

  angular.module('component.emailTemplates')
    .directive('sidebarTemplates', TemplatesCard);
})();
