(function() {
  'use strict';

  function Search(BLUEPRINT_TYPES, moment) {
    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'name',
              type: 'text',
              label: 'Start typing to search'
            },
            {
              id: 'fieldType',
              type: 'select',
              label: 'Field type',
              multiselect: true,
              options: BLUEPRINT_TYPES.map(function(item) {
                return { id: item.name, title: item.label };
              }),
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'name',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'name',
            matchFunc: function(doc, _key, value) {
              if (!value) { return true; }

              var val = value.toLowerCase(),
                  name = doc.doc.name.toLowerCase();
              return name.indexOf(val) > -1;
            }
          },
          {
            id: 'fieldType'
          }
        ],
        orders: {
          name: function(item) {
            return item.doc.name.toLowerCase();
          },
          importance: function(item) {
            return item.doc.order;
          },
          added: function(item) {
            var dt = item.doc.createdDate;
            if (dt === undefined && item.doc.dates && item.doc.dates.length) {
              dt = item.doc.dates[0].date;
            }
            if (dt === undefined) {
              return Number.MAX_SAFE_INTEGER;
            }
            return -(moment(dt).toDate().getTime());
          },
          modified: function(item) {
            var dt = item.doc.modifiedDate;
            if (dt === undefined && item.doc.dates && item.doc.dates.length) {
              dt = _.last(item.doc.dates).date;
            }
            if (dt === undefined) {
              return Number.MAX_SAFE_INTEGER;
            }
            return -(moment(dt).toDate().getTime());
          }
        },
        orderGroups: {
          name: {
            title: 'name',
            orders: ['name']
          },
          importance: {
            title: 'Importance',
            orders: ['importance']
          },
          added: {
            title: 'added',
            orders: ['added', 'name']
          },
          modified: {
            title: 'most recent',
            orders: ['modified', 'name']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'importance'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = ['BLUEPRINT_TYPES', 'moment'];

  angular.module('component.userFields')
    .service('UserFieldSearch', Search);
})();
