(function() {
  'use strict';

  function BlueprintsService($q, $log, EasApiStore, EasApi, BLUEPRINT_TYPES) {
    var service = new EasApiStore(
      'blueprints',
      { cacheKeys: [], usePersistentCache: true, schemaVersion: 'v2' }
    );
    var apiVersion = '';

    service.findAll = function(options) {
      var opts = {};
      if (options) {
        _.assign(opts, options);
      }

      if (opts.cached === undefined) {
        opts.cached = true;
      }

      return EasApiStore.prototype.findAll.call(this, opts)
        .then(function(data) {
          var docData = _.map(data, function(itm) {
            return { doc: itm };
          });
          return docData;
        });
    };

    service.getNewCopy = function(blueprintId) {
      return EasApi.get('blueprints/' + blueprintId + '/new-copy', {}, {}, { version: apiVersion });
    };

    service.blueprintTypes = function() {
      return $q.when(BLUEPRINT_TYPES);
    };

    service.findDiscreteOnly = function() {
      return service.findAll()
        .then(function(result) {
          return _.chain(result)
            .map(function(item) {
              return item.doc;
            })
            .filter(function(item) {
              return [
                'discrete', 'discrete_multiple', 'tree', 'likert'
              ].indexOf(item.blueprintType) > -1;
            })
            .sortBy('name')
            .value();
        });
    };

    service.findPathFor = function(options) {
      var _this = this;
      options = options || {};

      if (options.keys) {
        return $q.all(_.map(options.keys, function(item) {
          return $q.all([item, _this.findCategoryPath(item)])
            .then(function(result) {
              return {
                key: result[0],
                path: result[1]
              };
            })
            .catch(function(error) {
              $log.warn('Could not find path', error);
            });
        }))
        .then(function(data) {
          return _.filter(data, function(item) {
            return !_.isUndefined(item);
          });
        });
      }

      return $q.reject({ status: 500, message: 'Not implemented' });
    };

    service.findNumericBlueprints = function() {
      return this.findAll()
        .then(function(blueprints) {
          return _.filter(blueprints, function(blueprint) {
            return _.indexOf(['numeric', 'likert'], blueprint.doc.blueprintType) > -1;
          });
        });
    };

    service.getEventTypeUsage = function(blueprintId) {
      var opts = { version: this.apiVersion };
      var url = this.specificUrl(blueprintId) + '/usage';
      return EasApi.get(url, undefined, {}, opts);
    };

    service.groupCategories = function(ids) {
      var proms = _.map(ids, function(id) {
        return service.findByCategoryId(id);
      });

      return $q.all(proms)
        .then(function(data) {
          return _.groupBy(data, function(val) {
            return val.key === val.rootId ? '' : val.root;
          });
        });
    };

    return service;
  }


  BlueprintsService.$inject = [
    '$q',
    '$log',
    'EasApiStore',
    'EasApiService',
    'BLUEPRINT_TYPES'
  ];

  angular.module('component.blueprints')
    .factory('BlueprintsService', BlueprintsService);
})();
