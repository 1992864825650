(function() {
  'use strict';

  function kzAdvancedSearch($q, LocalizationService) {
    return {
      restrict: 'AE',
      scope: {
        model: '=',
        search: '=',
        facets: '=?'
      },
      templateUrl: 'app/blocks/widgets/search/kz-advanced-search.html',
      link: function(scope) {
        scope.fields = [];

        var buildForm = function(facets) {
          if (!facets) {
            return;
          }

          facets.forEach(function(facet) {
            var to = {
              label: facet.label,
              required: false,
              placeholder: facet.placeholder
            };

            var field = {
              id: facet.id,
              key: facet.id,
              templateOptions: to
            };

            switch (facet.type) {
              case 'text':
                _.assign(field,
                  {
                    type: 'string'
                  });
                break;
              case 'select':
                _.assign(field,
                  {
                    type: facet.multiselect ? 'discrete_multiple' : 'discrete'
                  }
                );

                _.assign(to,
                  {
                    options: [],
                    itemLabel: 'title',
                    itemValue: 'id',
                    loading: true
                  }
                );

                var options;
                if (_.isFunction(facet.options)) {
                  console.log('Getting options');
                  options = facet.options();
                } else {
                  options = facet.options;
                }

                $q.when(options, function(data) {
                  to.options = data;
                  to.loading = false;
                });
                break;
              case 'date':
                _.assign(field,
                  {
                    type: 'date'
                  }
                );

                _.assign(to,
                  {
                    options: {
                      showDropdowns: true,
                      singleDatePicker: true,
                      autoApply: true,
                      locale: {
                        format: LocalizationService.getDateTimeFormat('dateonly'),
                        firstDay: 1
                      }
                    }
                  }
                );
                break;

              default:
                console.log('Unknown field type');
            }

            if (!_.isEmpty(field)) {
              scope.fields.push(field);
            }
          });
        };

        scope.$watch(function() {
          return scope.search ? scope.search.ready : false;
        }, function(value) {
          if (value !== false) {
            var facets;
            if (scope.facets) {
              facets = scope.facets;
            } else {
              facets = _.filter(scope.search.facetly.facets, function(item) {
                return item.advanced;
              });
            }
            buildForm(facets);
          }
        });
      }
    };
  }

  kzAdvancedSearch.$inject = ['$q', 'LocalizationService'];

  angular.module('blocks.widgets')
    .directive('kzAdvancedSearch', kzAdvancedSearch);
})();
