(function() {
  'use strict';

  function StylesService(ClusterService) {
    var service = {};

    service.getColorProperties = function(colors) {
      var styles = '';
      if (colors && typeof colors === 'object') {
        styles = Object.keys(colors).reduce(function(colorNames, color) {
          return colorNames + color + ': ' + colors[color] + '; ';
        }, '');
      } else {
        console.warn('The organisation\'s colors are not valid JSON!');
      }

      return styles;
    };

    service.setColorProperties = function(colors) {
      if (colors && typeof colors === 'object') {
        Object.keys(colors).forEach(function(color) {
          document.documentElement.style
            .setProperty(color, colors[color]);
        });
      } else {
        console.warn('The organisation\'s colors are not valid JSON!');
      }
    };

    service.getColors = function(organisationId) {
      try {
        var organisation = ClusterService.getOrganisation(organisationId);
        return (organisation.themeSetup && organisation.themeSetup.colors) || {};
      } catch (err) {
        console.log('Styles: Not yet loaded');
        return {};
      }
      // return Clust.findAllPublic()
      //   .then(function(availableLogins) {
      //     var organisation = _.find(availableLogins.logins, { id: organisationId });
      //     return organisation ? (organisation.themeSetup || {}).colors : {};
      //   });
    };

    service.setColors = function(organisationId) {
      var colors = service.getColors(organisationId);
      service.setColorProperties(colors);
      console.log('Styles: Setting new colors');
    };

    return service;
  }

  StylesService.$inject = ['ClusterService'];

  angular.module('blocks.utils')
    .service('StylesService', StylesService);
})();
