(function() {
  'use strict';

  /**
   * The custom partials paths.
   * @type {Object}
   */
  var paths = {
    titleTemplateUrl: 'app/components/dashboard/partials/dashboard-title.html',
    editTemplateUrl: 'app/components/dashboard/partials/dashboard-edit.html',
    addTemplateUrl: 'app/components/dashboard/partials/dashboard-addwidget.html',
    widgetTitleUrl: 'app/components/dashboard/partials/widget-title.html'
  };

  /**
   * These are private permissions.
   * canRemoveDefault: Whether the user can remove the default dashboard template
   * @type {Array}
   */
  var permissions = [{
    canRemoveDefault: 'dashboardTemplates.setTemplateAsDefault'
  }];

  /**
   * This is the default dashboard template layout.
   * @type {Object}
   */
  var layout = {
    structure: '3-5-4',
    rows: [
      {
        columns: [
          {
            styleClass: 'col-sm-3',
            widgets: []
          },
          {
            styleClass: 'col-sm-5',
            widgets: []
          },
          {
            styleClass: 'col-sm-4',
            widgets: []
          }
        ]
      }
    ]
  };

  /**
   * This is the default dashboard template. It relies on the layout and extends it.
   * @type {Object}
   */
  var template = _.cloneDeep(layout);
  template.title = 'Dashboard';
  template.rows[0].columns[0].widgets.push({
    title: 'Profile',
    type: 'userProfile',
    titleTemplateUrl: paths.widgetTitleUrl,
    config: {
      options: {
        hideTitle: true
      }
    }
  });
  template.rows[0].columns[1].widgets.push({
    title: 'Inbox',
    type: 'inbox',
    titleTemplateUrl: 'app/components/dashboard/partials/widget-title.html'
  });

  angular.module('component.dashboardTemplates')
    .constant('DASHBOARD_PARTIALS_PATHS', paths)
    .constant('DASHBOARD_PERMISSIONS', permissions)
    .constant('DEFAULT_DASHBOARD_TEMPLATE', template)
    .constant('DEFAULT_DASHBOARD_TEMPLATE_LAYOUT', layout);
})();
