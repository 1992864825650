(function() {
  'use strict';

  var AUTH_ERRORS = {
    notLoggedIn: { status: 401, message: 'Not logged in' },
    differentUser: { status: 411, message: 'Different user' },
    userDisabled: { status: 401, message: 'user disabled' },
    upgradeRequired: { status: 426, message: 'Upgrade required' },
    serverNotResponding: { status: 500, message: 'No response from server' }
  };

  var preferredMode = {
    online: 'online',
    offline: 'offline'
  };

  angular.module('blocks.auth')
    .constant('AUTH_ERRORS', AUTH_ERRORS)
    .constant('AUTH_MODES', preferredMode);
})();
