import * as Sentry from '@sentry/browser';

(function() {
  'use strict';

  function HeaderCardController($q, $rootScope, $scope, $state, $injector, $log, Auth,
                                BlueprintUtils, Features, Utils, Security, Organisations, Profile,
                                Roles, HEADER_MENU) {
    var ctrl = this;

    ctrl.goToState = function(stateName) {
      var reload = $state.current.name === stateName;

      if ($(window).width() <= 768 && !reload) {
        angular.element('#collapseMenu').trigger('click');
      }

      // detect if they are trying to refresh the page by going from stateA to the same stateA
      var opts = { reload: reload };
      $state.transitionTo(stateName, {}, opts);
    };

    var checkPermission = function(link) {
      var promise;
      if (link.requireFeature !== undefined) {
        promise = Features.isOn(link.requireFeature)
          .then(function(res) {
            if (!res) {
              return $q.reject({
                status: 403,
                message: 'Feature is not allowed'
              });
            }
            return true;
          });
      } else {
        promise = $q.when(true);
      }

      if (_.isArray(link.links) && link.links.length > 0) {
        return promise
          .then(function() {
            return Utils.asyncFilter(link.links, checkPermission);
          })
          .then(function(menu) {
            link.links = menu;
            return menu.length ? link : null;
          });
      }

      if (_.isArray(link.permission)) {
        return promise.then(function() {
          return $injector.invoke(link.permission);
        });
      }

      if (link.permission !== undefined) {
        promise = promise.then(function() {
          return Security.hasPermission(link.permission, { noCatch: true });
        });
      }

      return promise
        .catch(function(err) {
          $log.error('Could not check permission', link.permission, err);
          return false;
        });
    };

    var extendMenu = function(menu) {
      return $q.all([
        Organisations.getListOptions({ cached: false }),
        Profile.getRoles()
      ])
        .then(function(result) {
          var listOptions = result[0];
          var roles = result[1];
          // extend menu with organisation list options
          return _.map(menu, function(item) {
            item = angular.copy(item);

            if (_.isUndefined(listOptions[item.extendBy])) {
              item.href = $state.href(item.extendBy);
              return item;
            }

            var extendBy = item.extendBy;
            var state;
            var perm;

            // Copy data from placeholder
            var idx = _.findIndex(item.links, { placeholder: true });
            if (idx !== -1) {
              state = item.links[idx].state;
              perm = item.links[idx].permission;
            }

            var links = _.map(listOptions[extendBy].sections || [], function(filter) {
              if (filter.allowedRoles && filter.allowedRoles.length > 0) {
                if (_.intersection(roles, filter.allowedRoles).length === 0) {
                  return;
                }
              }
              return {
                title: filter.name,
                permission: perm,
                href: $state.href(state, {
                  filterId: filter.id
                }),
                state: state,
                stateOptions: {
                  filterId: filter.id
                }
              };
            });

            links = _.filter(links, function(item) {
              return item !== undefined;
            });

            item.links.splice.apply(item.links, [idx, 1].concat(links));
            // item.links = _(links).concat(item.links || []).value();

            return item;
          });
        });
    };

    var extendCurrentCoveragesReports = function(menu) {
      var user = Auth.currentUser();
      return BlueprintUtils.getHistoricalCoverages(user, { cached: false })
        .then(function(historicalCoverages) {
          // extend menu with blueprints coverages
          var reportMenu = _.find(menu, function(item) {
            return item.title === 'Reports';
          });

          if (_.isUndefined(reportMenu)) {
            return menu;
          }

          _.forEach(historicalCoverages.current, function(item) {
            _.forEach(item.blueprint, function(_values, blueprintId) {
              var link = {
                state: 'epf.reports.view',
                stateOptions: {
                  location: 'organisation',
                  id: 'coverage'
                },
                permission: 'reports.view'
              };

              if (_.find(reportMenu.links, { blueprintId: blueprintId })) {
                return;
              }

              // Disabled the below because we always want to show the blueprint name
              link.blueprintId = blueprintId;
              link.stateOptions.blueprintId = blueprintId;
              link.stateOptions.blueprintCatId = ''; // it overrides previous value when reload
              reportMenu.links.push(link);

              // if (values.length) {
              //   _.forEach(values, function(value) {
              //     link = angular.copy(link);
              //     link.blueprintId = value;
              //     link.stateOptions.blueprintId = blueprintId;
              //     link.stateOptions.blueprintCatId = value;

              //     reportMenu.links.push(link);
              //   });
              // } else {
              //   link.blueprintId = blueprintId;
              //   link.stateOptions.blueprintId = blueprintId;
              //   link.stateOptions.blueprintCatId = ''; // it overrides previous value when reload
              //   reportMenu.links.push(link);
              // }
            });
          });

          $rootScope.$broadcast('KZMenuUpdated', menu);
          return menu;
        });
    };

    function setup() {
      ctrl.menu = {};
      ctrl.loading = false;

      Roles.loadWithPermissions({ cached: true })
        .then(Profile.find({ cached: true }))
        .then(function() {
          return extendMenu(angular.copy(HEADER_MENU.main));
        })
        .then(function(menu) {
          return Utils.asyncFilter(menu, checkPermission);
        })
        .then(function(menu) {
          ctrl.menu.main = menu;
        })
        .then(function() {
          ctrl.loadingSubItems = true;
          return extendCurrentCoveragesReports(ctrl.menu.main);
        })
        .then(function(menu) {
          ctrl.menu.main = menu;
          ctrl.loadingSubItems = false;
        })
        .then(function() {
          return Security.hasPermission('events.create.own')
            .then(function() { ctrl.canCreateEvents = true; })
            .catch(function() { ctrl.canCreateEvents = false; });
        })
        .catch(function(err) {
          var error = new Error('Failed to load menu');
          try {
            $log.error(JSON.stringify(err));
          } catch (e) {
            $log.error(err);
          }
          Sentry.captureException(error);
        })
        .finally(function() {
          ctrl.loading = true;
        });

      $q.all([Profile.find({ cached: true }), Profile.userCache({ cached: true })])
        .then(function(results) {
          var data = results[0];
          var cache = results[1];

          ctrl.userProfile = data;
          ctrl.userProfile.relations = cache.profile ? cache.profile.relations : [];
          ctrl.userProfile.dependent = cache.profile ? cache.profile.user_dependent : [];
        });
    }

    setup();

    $scope.$on('KZReloadHeader', function() {
      setup();
    });
  }

  HeaderCardController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    '$state',
    '$injector',
    '$log',
    'AuthService',
    'BlueprintUtils',
    'FeaturesService',
    'UtilsService',
    'SecurityService',
    'OrganisationsService',
    'ProfileService',
    'RolesService',
    'HEADER_MENU'
  ];

  function HeaderCardDirective() {
    return {
      restrict: 'EA',
      templateUrl: 'app/layout/header/header.html',
      controller: HeaderCardController,
      controllerAs: 'headerCardCtrl'
    };
  }

  angular.module('kzHeaderCard')
    .directive('kzHeaderCard', HeaderCardDirective);
})();
