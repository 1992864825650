(function() {
  'use strict';

  function RelationTitleDirective(Relations) {
    return {
      scope: {
        relation: '@'
      },
      restrict: 'EA',
      template: '<i class="icon-spin animate-spin" ng-if="!title"></i>{{ title }}',
      link: function(scope) {
        Relations.findByCategoryId(scope.relation, { include_docs: true })
          .then(function(data) {
            if (data === undefined) {
              scope.title = 'n/a';
              return;
            }
            scope.title = data.value;
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    };
  }

  RelationTitleDirective.$inject = ['RelationsService'];

  function RelationCategoriesDirective() {
    return {
      scope: {
        categories: '='
      },
      restrict: 'EA',
      template: '<span data-ng-repeat="category in categories">' +
      '<span epf-relation-title relation="{{category}}"></span><span ng-if="!$last">, </span>' +
      '</span>'
    };
  }

  angular.module('component.relations')
    .directive('epfRelationTitle', RelationTitleDirective)
    .directive('epfRelationCategories', RelationCategoriesDirective);
})();
