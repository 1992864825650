(function() {
  'use strict';

  function kzButtonLoading($timeout) {
    return {
      scope: {
        indicator: '='
      },
      link: function(scope, element) {
        scope.$watchCollection('indicator', function() {
          if (scope.indicator === true) {
            $timeout(function() {
              // element[0].disabled = 'true';
              element.append('<i class="icon-spin animate-spin"></i>');
            });
          } else if (!_.isUndefined(scope.indicator)) {
            $timeout(function() {
              // element[0].disabled = 'false';
              element.find('.icon-spin').remove();
            });
          }
        });
      }
    };
  }

  kzButtonLoading.$inject = ['$timeout'];

  angular.module('blocks.utils')
    .directive('kzButtonLoading', kzButtonLoading);
})();
