(function() {
  'use strict';

  function AdminService($log, EventTypes) {
    var service = {};

    service.getReviewableSections = function() {
      return EventTypes.getOwnReviewableSections()
        .catch(function(error) {
          $log.warn('Could not load permissions for pending sections', error);
          return [];
        });
    };

    return service;
  }

  AdminService.$inject = ['$log', 'EventTypesService'];

  angular.module('component.admin')
    .factory('AdminService', AdminService);
})();
