(function() {
  'use strict';

  function Search(BLUEPRINT_TYPES, moment) {
    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'name',
              type: 'text',
              label: 'Start typing to search'
            },
            {
              id: 'blueprintType',
              type: 'select',
              label: 'Type',
              options: BLUEPRINT_TYPES.map(function(item) {
                return { id: item.name, title: item.label };
              }),
              facetly: false,
              advanced: true
            },
            {
              id: 'usage',
              type: 'select',
              label: 'Purpose',
              multiselect: true,
              options: [
                { id: 'events', title: 'Event tagging' },
                { id: 'goal_periods', title: 'Goal period tagging' }
              ],
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'name',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'name',
            matchFunc: function(doc, _key, value) {
              if (!value) { return true; }

              var val = value.toLowerCase(),
                  name = doc.doc.name.toLowerCase();
              return name.indexOf(val) > -1;
            }
          },
          {
            id: 'blueprintType'
          },
          {
            id: 'usage',
            matchFunc: function(doc, _key, value) {
              if (!value) { return true; }

              var usage = doc.doc.usage || [];
              return _.intersection(usage, value).length > 0;
            }
          }
        ],
        orders: {
          name: function(item) {
            return item.doc.name.toLowerCase();
          },
          added: function(item) {
            var dt = item.doc.createdDate;
            if (
              dt === undefined && item.doc.dates && item.doc.dates.length &&
              item.doc.dates.length
            ) {
              dt = item.doc.dates[0].date;
            }
            if (dt === undefined) {
              return Number.MAX_SAFE_INTEGER;
            }
            return -(moment(dt).toDate().getTime());
          },
          modified: function(item) {
            var dt = item.doc.modifiedDate;
            if (dt === undefined && item.doc.dates && item.doc.dates.length) {
              dt = _.last(item.doc.dates).date;
            }
            if (dt === undefined) {
              return Number.MAX_SAFE_INTEGER;
            }
            return -(moment(dt).toDate().getTime());
          }
        },
        orderGroups: {
          name: {
            title: 'name',
            orders: ['name']
          },
          added: {
            title: 'added',
            orders: ['added', 'name']
          },
          modified: {
            title: 'most recent',
            orders: ['modified', 'name']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'name'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = ['BLUEPRINT_TYPES', 'moment'];

  angular.module('component.blueprints')
    .service('BlueprintSearch', Search);
})();
