(function() {
  'use strict';

  function Highlight($sce, $injector, $log) {
    // Courtesy of https://github.com/angular-ui/bootstrap/blob/master/src/typeahead/typeahead.js
    var isSanitizePresent;
    isSanitizePresent = $injector.has('$sanitize');

    function escapeRegexp(query) {
      // Regex: capture the whole query string and replace it with the string that will be used to
      // match
      // the results, for example if the capture is "a" the result will be \a
      return query.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    function containsHtml(value) {
      return /<.*>/g.test(value);
    }

    var filter = function(value, queries) {
      if (queries === undefined) {
        if (!isSanitizePresent) {
          value = $sce.trustAsHtml(value); // If $sanitize is not present we pack the string in a
          // $sce object for the ng-bind-html directive
        }

        return value;
      }

      if (!isSanitizePresent && containsHtml(value)) {
        // Warn the user about the danger
        $log.warn('Unsafe use of typeahead please use ngSanitize');
      }

      if (!_.isArray(queries)) {
        queries = queries.split(' ');
      }

      var regexQueries = queries
        .filter(query => query.length > 1)
        .map(query => escapeRegexp(query))
        .join('|');

      value = ('' + value).replace(
        new RegExp('(' + regexQueries + ')', 'gi'),
        '<span class="highlighted">$&</span>'
      );

      if (!isSanitizePresent) {
        // If $sanitize is not present we pack the string in a $sce object for the ng-bind-html
        // directive
        value = $sce.trustAsHtml(value);
      }

      return value;
    };

    return filter;
  }

  Highlight.$inject = ['$sce', '$injector', '$log'];

  angular.module('blocks.utils')
    .filter('kzHighlight', Highlight);
})();
