(function() {
  'use strict';

  function EventsController(
    resolvedFilter,
    $log,
    $state,
    $rootScope,
    Profile,
    EventUtils,
    Auth,
    Security,
    Utils
  ) {
    var ctrl = this;

    ctrl.options = {
      highlightField: 'name',
      typesToUpdate: ['event', 'eventSection'],
      trackBy: 'id',
      borderClass: function(item) {
        return item.main.getBorderClass();
      }
    };
    ctrl.username = Auth.currentUser();

    ctrl.resolvedFilter = resolvedFilter;
    var listName = resolvedFilter ? 'events-' + resolvedFilter.id : 'events';

    ctrl.searchModel = Profile.getListPreferences(listName).current;
    ctrl.options.search = ctrl.searchModel;

    var options = {
      defaultFilter: { user: ctrl.username },
      resolvedFilter: ctrl.resolvedFilter,
      own: true
    };
    EventUtils.getList('dbsearch', ctrl.searchModel, options)
      .then(function(list) {
        ctrl.list = list;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: list.search });
        $rootScope.loading = false;
        ctrl.list.doLoadItems();
        Utils.setPageTitle(resolvedFilter ? resolvedFilter.name : 'Timeline');
        ctrl.loaded = true;
      })
      .catch(function(error) {
        $rootScope.loading = false;
        ctrl.loaded = true;
        $log.warn(error);
      });

    ctrl.actions = [
      {
        label: 'Create new',
        position: 0,
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.events.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'events.create.own'),
        klass: 'btn-success'
      },
      {
        label: 'Bulk tagging',
        color: 'warning',
        type: 'link',
        position: 1,
        onClick: function() {
          var prefs = _.assignIn(
            {},
            Profile.getPreferences(),
            {
              bulkTagging: {
                lastPath: {},
                selected: []
              }
            }
          );
          Profile.savePreferences(prefs);
          $state.go('epf.events.selecttags');
        }
      }
    ];
  }

  EventsController.$inject = [
    'resolvedFilter',
    '$log',
    '$state',
    '$rootScope',
    'ProfileService',
    'EventUtils',
    'AuthService',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.events')
    .controller('EventsController', EventsController);
})();
