(function() {
  'use strict';

  function EventSectionFieldsDirective(Events, EventSections) {
    return {
      scope: {
        event: '=',
        model: '=',
        localModel: '=edited',
        fields: '=',
        form: '=',
        def: '=',
        parent: '=?',
        originalEvent: '=',
        readOnly: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-fields.html',
      replace: true,
      link: function($scope) {
        $scope.ready = false;
        function load() {
          $scope.cannotEdit = false;
          // WTF? :)
          // eslint-disable-next-line no-self-assign
          $scope.localModel = $scope.localModel;

          if (!$scope.model.fields && $scope.model.enc) {
            // User cannot edit encrypted responses - at least not in this version
            $scope.cannotEdit = true;

            var options;
            var promise;
            if ($scope.parent && $scope.parent.doc.type === 'eventSection') {
              options = {
                originalEvent: $scope.event.doc._id,
                filledBy: $scope.model.filledBy,
                sectionId: $scope.def._id
              };
              promise = EventSections.decrypt($scope.parent.doc._id, $scope.model.enc, options);
            } else {
              options = { filledBy: $scope.model.filledBy };
              promise = Events.decrypt($scope.event.doc._id, $scope.model.enc, options);
            }

            promise
              .then(function(data) {
                // There is no other way but to break the link to original model
                $scope.localModel.fields = JSON.parse(data.data);
                delete $scope.localModel.enc;
                $scope.ready = true;
              })
              .catch(function(error) {
                $scope.ready = true;
                if (error.status) {
                  if (error.status === 403) {
                    $scope.info = 'This section has been intentionally hidden from view until ' +
                      'the entire event has been completed.';
                    return;
                  } else if (error.status === 509) {
                    $scope.info = error.data.message;
                    return;
                  }
                }

                $scope.error = 'There has been an error accessing this section: '
                  + error.data || error.message;
              });
          } else if ($scope.originalEvent) {
            $scope.ready = true;
            // If there is original event with fields, don't display it
          } else {
            // Lets keep the link
            $scope.ready = true;
            $scope.localModel.fields = $scope.model.fields;
          }
        }


        $scope.$watchCollection('localModel', function(data) {
          if (_.isEmpty(data.fields)) {
            load();
          }
        });
      }
    };
  }

  EventSectionFieldsDirective.$inject = ['EventsService', 'EventSectionsService'];

  angular.module('events.directives')
    .directive('eventSectionFields', EventSectionFieldsDirective);
})();
