
(function() {
  'use strict';

  function MultiReportBuilderController(
    $scope,
    $uibModal,
    Form,
    MultiReport,
    SingleReport,
    Utils
  ) {
    var ctrl = this;

    ctrl.editMode = $scope.editMode;

    ctrl.availableSources = [['events', 'Events'], ['users', 'Users'], ['goals', 'Goals'],
      ['sections', 'Logs']];
    ctrl.ELEMENT_TYPES = [{ id: 'report', name: 'Report' }, { id: 'text', name: 'Text' }];

    ctrl.multiReport = $scope.multiReport;

    var disableAll = !ctrl.editMode;
    ctrl.baseForm = new Form(MultiReport.getBaseFormFields(disableAll));

    // global filters
    ctrl.openFiltersDefinitionModal = function(source) {
      if (_.isUndefined(ctrl.multiReport.globalFilters[source])) {
        ctrl.multiReport.globalFilters[source] = SingleReport.getInitialFilter();
      }

      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/reports/partials/filters-definition.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'source',
          'filter',
          'editMode',
          function($scope, $uibModalInstance, source, filter, editMode) {
            $scope.editMode = editMode;

            var data = {
              conf: { presource: source, source: source, filter: filter }
            };
            $scope.singleReport = new SingleReport(data);

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };
          }
        ],
        size: 'lg',
        resolve: {
          filter: function() {
            return ctrl.multiReport.globalFilters[source];
          },
          source: function() {
            return source;
          },
          editMode: function() {
            return $scope.editMode;
          }
        }
      });
    };

    // elements
    function saveAndRedirectToEditElement(elementId) {
      $scope.$emit(
        'SaveAndEditMultiReportElement',
        { reportId: $scope.reportId, elementId: elementId }
      );
    }

    ctrl.addElement = function(elementType) {
      var element = MultiReport.getDefaultMultiReportElement(elementType);
      ctrl.multiReport.elements.push(element);
      saveAndRedirectToEditElement(element._id);
    };

    ctrl.editElement = function(elementId) {
      saveAndRedirectToEditElement(elementId);
    };

    ctrl.removeElement = function(elementId) {
      Utils.removeItemFromListById(ctrl.multiReport.elements, elementId);
      delete ctrl.forms[elementId];
    };
  }

  MultiReportBuilderController.$inject = [
    '$scope',
    '$uibModal',
    'FormsService',
    'MultiReportFactory',
    'SingleReportFactory',
    'UtilsService'
  ];

  function MultiReportBuilderDirective() {
    return {
      scope: {
        multiReport: '=',
        reportId: '=',
        editMode: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/multi-report-builder.html',
      replace: true,
      controller: MultiReportBuilderController,
      controllerAs: 'multiReportBuilderCtrl'
    };
  }

  MultiReportBuilderDirective.$inject = [];

  angular.module('component.reports')
    .directive('multiReportBuilder', MultiReportBuilderDirective);
})();
