(function() {
  'use strict';

  function Search(Roles, Utils, VERSION_STATES) {
    function getRoles() {
      return Roles.findAll({ includeSystemRoles: true })
        .then(function(data) {
          return _.sortBy(data.map(function(obj) {
            return { id: obj.doc._id, key: obj.doc._id, title: obj.doc.title };
          }), 'name');
        });
    }

    var roles = getRoles();

    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              label: 'Start typing to search'
            },
            {
              id: 'groupState',
              type: 'select',
              label: 'State',
              multiselect: true,
              options: [
                { id: VERSION_STATES.draft, title: 'Draft' },
                { id: VERSION_STATES.published, title: 'Published' },
                { id: VERSION_STATES.archived, title: 'Archived' }
              ],
              facetly: false,
              advanced: true
            },
            {
              id: 'ownerRole',
              type: 'select',
              label: 'Can be owned by',
              multiselect: true,
              options: roles,
              facetly: false,
              advanced: true
            },
            {
              id: 'initiatorRole',
              type: 'select',
              label: 'Can be initiated by',
              multiselect: true,
              options: roles,
              facetly: false,
              advanced: true
            },
            {
              id: 'fulltext_extended',
              type: 'text',
              label: 'Search by fields',
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'text',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'text',
            matchFunc: function(item, _key, value) {
              if (!value) { return true; }

              var val = value.toLowerCase(),
                  name = Utils.safeLowerStr(item.doc.name) +
                          Utils.safeLowerStr(item.doc.description);
              return name.indexOf(val) > -1;
            }
          },
          {
            id: 'state'
          },
          {
            id: 'ownerRole'
          }
        ],
        orders: {
          name: function(item) {
            return item.doc && Utils.safeLowerStr(item.doc.name);
          },
          state: function(item) {
            return item.doc && item.doc.state;
          }
        },
        orderGroups: {
          state: {
            title: 'state',
            orders: ['state', 'name']
          },
          name: {
            title: 'name',
            orders: ['name']
          },
          added: {
            title: 'added',
            orders: ['added', 'name']
          },
          modified: {
            title: 'most recent',
            orders: ['modified', 'name']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'state'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = ['RolesService', 'UtilsService', 'VERSION_STATES'];

  angular.module('component.eventTypes')
    .service('EventTypeSearch', Search);
})();
