(function() {
  'use strict';

  function EventHeaderDirective($q, $stateParams, Auth, Form, Utils, Network, EventSections,
                                EVENT_STATES, LocalizationService) {
    return {
      scope: {
        event: '=',
        allowActions: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-header.html',
      link: function(scope) {
        scope.conf = {
          auditLogShown: false,
          showEditForm: false
        };

        scope.sectionsToFillIn = [];

        scope.EVENT_STATES = EVENT_STATES;
        scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
        scope.username = $stateParams.user || Auth.currentUser();
        scope.sectionStates = scope.event.getProgress();

        var getDraftSections = function() {
          if (scope.event.doc.state === 'complete') {
            return $q.when([]);
          }

          return EventSections.getTodoFor(scope.event.doc._id)
            .then(function(res) {
              if (res.total === 1 && res.hits.length === 1) {
                return res.hits;
              }
            })
            .catch(function(err) {
              console.log(err);
              return [];
            });
        };
        getDraftSections()
          .then(function(sections) {
            scope.sectionsToFillIn = sections;
          });

        var getMeta = function() {
          return {
            startDate: scope.event.doc.startDate,
            endDate: scope.event.doc.endDate,
            description: scope.event.doc.description
          };
        };

        var setMeta = function(meta) {
          scope.event.doc.startDate = meta.startDate;
          scope.event.doc.endDate = meta.endDate;
          scope.event.doc.description = meta.description;
        };

        var edit = function() {
          if (!scope.allowActions) {
            return;
          }

          scope.model = getMeta();
          scope.conf.showEditForm = true;
          scope.basicForm = new Form();

          scope.basicForm.addField({
            id: 'startDate',
            type: 'date',
            label: 'Date occurred on',
            required: true,
            helpText: 'Please indicate the date on which this event occurred.',
            className: 'col-sm-3'
          });

          scope.basicForm.addField({
            id: 'endDate',
            type: 'date',
            label: 'End date',
            required: true,
            helpText: 'If this event spans multiple days, please indicate ' +
            ' the date on which this finishes.',
            className: 'col-sm-3'
          });

          scope.basicForm.addField({
            id: 'description',
            type: 'text',
            label: 'Description (optional)',
            required: false
          });

          scope.$broadcast('kzReloadActions');
        };

        var save = function() {
          setMeta(scope.model);
          return scope.event.saveHeader(scope.model)
            .then(function() {
              scope.conf.showEditForm = false;
              scope.$broadcast('kzReloadActions');
            });
        };

        var cancel = function() {
          scope.conf.showEditForm = false;
          scope.$broadcast('kzReloadActions');
        };

        var loadActionButtons = function() {
          var buttons = [
            {
              label: 'Edit',
              icon: 'icon-edit',
              onClick: edit,
              klass: 'btn btn-sm btn-primary',
              showCondition: function() {
                if (Network.isOffline()) {
                  return false;
                }

                if (scope.conf.showEditForm) {
                  return false;
                }

                return scope.event.canEditBasicForm();
              }
            },
            {
              label: 'Save',
              icon: 'icon-save',
              onClick: save,
              klass: 'btn btn-sm btn-success',
              showCondition: function() {
                if (Network.isOffline()) {
                  return false;
                }

                return scope.conf.showEditForm;
              }
            },
            {
              label: 'Cancel',
              icon: 'icon-cancel',
              onClick: cancel,
              klass: 'btn btn-sm btn-danger',
              showCondition: function() {
                return scope.conf.showEditForm;
              }
            }
          ];

          // We need to hide the buttons box when there are no visible buttons
          Utils.filterButtons(buttons)
            .then(function(visible) {
              scope.showActionButtons = visible.length && !Network.isOffline();
            });

          // Set the buttons
          scope.actionButtons = buttons;
        };


        loadActionButtons();

        scope.reloadEvent = function() {
          scope.$emit('EventReload', { id: scope.event.doc._id });
        };
      }
    };
  }

  EventHeaderDirective.$inject = [
    '$q',
    '$stateParams',
    'AuthService',
    'FormsService',
    'UtilsService',
    'NetworkService',
    'EventSectionsService',
    'EVENT_STATES',
    'LocalizationService'
  ];

  angular.module('events.directives')
    .directive('eventHeader', EventHeaderDirective);
})();
