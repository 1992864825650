(function() {
  'use strict';

  function MappingBlueprintMapController(
    $uibModal,
    BlueprintMaps,
    Form,
    Utils,
    Notify
  ) {
    var ctrl = this;
    // ctrl.unsavedValue = {val: 'no'};
    ctrl.$onInit = function() {
      ctrl.load();
    };

    ctrl.load = function() {
      ctrl.loaded = false;
      ctrl.blueprintMap = {};

      if (ctrl.id) {
        BlueprintMaps.getForEdit(ctrl.id)
          .then(function(data) {
            ctrl.blueprintMap = data;
            Utils.setPageTitle('Blueprint map mapping for ' + ctrl.blueprintMap.name);
            if (_.isEmpty(ctrl.blueprintMap.blueprints)) {
              ctrl.error = 'Invalid blueprint map: No blueprints to map';
            }
            if (_.isEmpty(ctrl.blueprintMap.coordinate_values)) {
              ctrl.error = 'Invalid blueprint map: No values to map to';
            }
            return ctrl.prepareData(ctrl.blueprintMap);
          })
          .then(function() {
            ctrl.loaded = true;
          });
      } else {
        Utils.setPageTitle('Blueprint map mapping');
        ctrl.loaded = true;
      }
    };

    ctrl.loadAuditlog = function() {
      BlueprintMaps.getAuditlog(ctrl.id)
        .then(function(data) {
          ctrl.auditlog = data;
        });
    };

    ctrl.edit = function(row) {
      var popup = $uibModal.open({
        animation: true,
        size: 'dialog',
        component: 'blueprintMapItemEdit',
        resolve: {
          row: function() { return row; },
          blueprints: function() { return ctrl.blueprints; },
          coords: function() { return ctrl.blueprintMap.coordinate_values; },
          model: function() { return ctrl.model[row.id]; }
        }
      });

      popup.result.then(function(model) {
        console.log('Success', model);
        ctrl.model[row.id] = model;
        // if (ctrl.unsavedForm) {
        //   window.setTimeout(function() {
        //     ctrl.unsavedForm.$setDirty();
        //     ctrl.unsavedValue.val = "true";
        //   }, 1000);
        // }
      }).catch(function(err) {
        console.log('Err', err);
      });
    };

    ctrl.save = function() {
      var data = ctrl.serializeModelByKey(
        ctrl.keyData, ctrl.blueprintMap.coordinate_values, ctrl.model
      );
      ctrl.blueprintMap.data = data;
      BlueprintMaps.save(ctrl.blueprintMap)
        .then(function() {
          Notify.success('Map successfully updated!', 'Success');
          return ctrl.load();
        })
        .catch(function(err) {
          Notify.error(err, 'Failed');
        });
    };

    ctrl.prepareData = function(blueprintMap) {
      return BlueprintMaps.loadBMapBlueprints(blueprintMap)
        .then(function(blps) {
          ctrl.blueprints = blps;
          var product = ctrl.product(blueprintMap.blueprints);
          var model = ctrl.deserializeModelByKey(blueprintMap);

          ctrl.keyData = product;
          ctrl.model = model;
          // ctrl.prepareWholeForm(blueprintMap, product);
        });
    };

    ctrl.deserializeModelByKey = function(blueprintMap) {
      var data = blueprintMap.data;
      var coords = blueprintMap.coordinate_values;
      var model = {};
      _.forEach(data, function(item) {
        var val = {};
        _.forEach(coords, function(coord, idx) {
          val[coord.id] = item.value[idx];
        });
        model[item.id.join(':')] = val;
      });
      return model;
    };

    ctrl.serializeModelByKey = function(product, coords, model) {
      var data = [];
      _.forEach(product, function(row) {
        var val = model[row.id];
        if (val === undefined) {
          return;
        }
        var mappedValue = _.map(coords, function(coord) {
          return val[coord.id];
        });

        if (_.every(mappedValue, Utils.isBlank)) {
          return;
        }

        data.push({
          id: _.map(row.blueprints, function(blp) {
            return blp.key;
          }),
          value: mappedValue
        });
      });
      return data;
    };

    ctrl.deserializeModel = function(blueprintMap) {
      var data = blueprintMap.data;
      var coords = blueprintMap.coordinate_values;
      var model = {};
      _.forEach(data, function(item) {
        _.forEach(coords, function(coord, idx) {
          var key = item.id.concat([coord.id]);
          model[key.join(':')] = item.value[idx];
        });
      });
      return model;
    };

    ctrl.prepareWholeForm = function(blueprintMap, product) {
      var coords = blueprintMap.coordinate_values;
      var fields = [];

      _.forEach(product, function(item) {
        _.forEach(coords, function(coord) {
          var key = _.map(item, function(item) {
            return item.key;
          });
          var label = _.map(item, function(item) {
            return item.value;
          });
          key = key.concat(coord.id);
          key = key.join(':');

          label = label.concat(coord.id);
          label = label.join(' ');

          var fld = {
            id: key,
            type: 'numeric',
            label: label
          };
          fields.push(fld);
        });
      });
      var mappingForm = new Form(fields);
      return mappingForm;
    };

    ctrl.product = function(blps) {
      if (blps.length === 0) {
        return [];
      }

      var extendData = function(blps, data) {
        if (blps.length === 0) {
          return data;
        }

        var newData = [];
        var blp = blps[0];
        var rest = blps.slice(1, blps.length);

        var categories = ctrl.blueprints[blp].categories;

        _.forEach(data, function(item) {
          _.forEach(categories, function(categ) {
            newData.push(item.concat([categ]));
          });
        });

        return extendData(rest, newData);
      };

      var blp = blps[0];
      var rest = blps.slice(1, blps.length);
      var categories = ctrl.blueprints[blp].categories;
      var first = _.map(categories, function(categ) {
        return [categ];
      });
      var product = extendData(rest, first);
      var result = _.map(product, function(item) {
        return {
          id: _.map(item, 'key').join(':'),
          blueprints: item
        };
      });
      return result;
    };
  }

  MappingBlueprintMapController.$inject = [
    '$uibModal',
    'BlueprintMapsService',
    'FormsService',
    'UtilsService',
    'NotifyService'
  ];

  angular.module('component.blueprints')
    .component('blueprintMapMapping', {
      templateUrl: 'app/components/blueprints/blueprintMap.mapping.html',
      controller: MappingBlueprintMapController,
      bindings: {
        id: '<'
      }
    });
})();
