(function() {
  'use strict';

  function filter() {
    return function(content) {
      if (!content) {
        return '';
      }
      var regexp = /<\s*a\s*\shref="(.*?)"[^>]*\s*>(.*?)<\s*\/\s*a>/g;
      var matchexp = /<\s*a\s*\shref="(.*?)"[^>]*\s*>(.*?)<\s*\/\s*a>/;
      if (content.search(regexp) !== -1) {
        content = content.replace(regexp, function(link) {
          var match = link.match(matchexp);
          return '<a href="' + match[match.length - 2] +
                 '" target="_blank">' + match[match.length - 1] + '</a>';
        });
      }
      return content;
    };
  }

  filter.$inject = ['$sce', '$log'];

  angular.module('blocks.utils')
    .filter('a2nt', filter);
})();
