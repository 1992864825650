(function() {
  'use strict';

  function BlueprintMapsController(
    $state, $rootScope, Profile, BlueprintMaps, BlueprintMapSearch, List, Security, Utils
  ) {
    var ctrl = this;
    Utils.setPageTitle('Blueprint maps');
    ctrl.options = {};
    ctrl.options.actionButtons = function(item) {
      return [
        {
          label: 'Edit',
          icon: 'icon-edit',
          href: (function() {
            return $state.href('epf.blueprintMaps.edit', { id: item.doc._id });
          })(),
          showCondition: Security.hasPermission.bind(this, 'blueprints.edit'),
          klass: 'text-info'
        },
        {
          label: 'Mapping',
          icon: 'icon-edit',
          href: (function() {
            return $state.href('epf.blueprintMaps.mapping', { id: item.doc._id });
          })(),
          showCondition: function() { return true; },
          klass: 'text-info'
        }
      ];
    };

    ctrl.searchModel = Profile.getListPreferences('blueprintMaps').current;

    ctrl.actions = [
      {
        label: 'New blueprint map',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.blueprintMaps.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'blueprints.edit'),
        klass: 'btn-success'
      }
    ];

    ctrl.list = new List({
      search: BlueprintMapSearch.search,
      model: ctrl.searchModel,
      idField: 'doc._id'
    });
    $rootScope.$broadcast('KZCurrentListChange',
      { list: 'blueprintMaps', search: ctrl.list.search }
    );

    BlueprintMaps.findAll({ cached: false })
      .then(function(items) {
        ctrl.list.doLoadItems(items);
      });
  }

  BlueprintMapsController.$inject = [
    '$state',
    '$rootScope',
    'ProfileService',
    'BlueprintMapsService',
    'BlueprintMapSearch',
    'ListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.blueprints')
    .controller('BlueprintMapsController', BlueprintMapsController);
})();
