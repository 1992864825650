(function() {
  'use strict';

  function SlotController($scope, $state, Blueprints, Security, BLUEPRINT_TYPES) {
    var ctrl = this;

    ctrl.BLUEPRINT_TYPES = _.keyBy(_.map(BLUEPRINT_TYPES, function(item) {
      return { id: item.name, name: item.label };
    }), 'id');

    // this may be also used for the details view, move it if necessary
    ctrl.actionButtons = [
      {
        label: 'Edit',
        ariaLabel: 'Edit ' + $scope.item.doc.name,
        icon: 'icon-edit',
        href: (function() {
          return $state.href('epf.blueprints.edit', { id: $scope.item.doc._id });
        })(),
        showCondition: Security.hasPermission.bind(this, 'blueprints.edit'),
        klass: 'text-info'
      },
      {
        label: 'View',
        ariaLabel: 'View ' + $scope.item.doc.name,
        icon: 'icon-edit',
        href: (function() {
          return $state.href('epf.blueprints.edit', { id: $scope.item.doc._id });
        })(),
        showCondition: function() {
          return Security.hasPermission('blueprints.edit')
            .then(function(has) {
              return !has;
            })
            .catch(function() {
              return true;
            });
        },
        klass: 'text-info'
      }
    ];

    ctrl.secondaryActionButtons = [
      {
        label: 'Duplicate',
        icon: 'icon-duplicate',
        onClick: function() {
          Blueprints.getNewCopy($scope.item.doc._id)
            .then(function(newCopy) {
              $state.go('epf.blueprints.new', { defaults: newCopy });
            });
        },
        showCondition: Security.hasPermission.bind(this, 'blueprints.edit'),
        klass: 'text-info'
      }
    ];
  }

  function SlotDirective() {
    return {
      scope: {
        item: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/blueprints/blueprint.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  SlotController.$inject = ['$scope', '$state', 'BlueprintsService', 'SecurityService',
    'BLUEPRINT_TYPES'];

  angular.module('component.blueprints')
    .directive('blueprintSlot', SlotDirective)
    .controller('BlueprintSlotController', SlotController);
})();
