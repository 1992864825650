(function() {
  'use strict';

  function Controller($rootScope, $transitions, SidebarService) {
    var ctrl = this;

    // ctrl.cards = SidebarService.getCardsForRoute();
    ctrl.cards = [];
    console.log(ctrl.cards.length);
    // $rootScope.$on('$stateChangeSuccess', function() {
    $transitions.onSuccess({}, function() {
      ctrl.cards = [];
    });

    $rootScope.$on('KzPageLoaded', function() {
      ctrl.cards = SidebarService.getCardsForRoute();
    });
  }

  Controller.$inject = ['$rootScope', '$transitions', 'SidebarService'];

  angular.module('layout.sidebar')
    .controller('SidebarController', Controller);
})();
