
(function() {
  'use strict';

  function ReportTaskButtonDirective(
    $log, AsyncTasks, Notify, moment, LocalizationService, $interval
  ) {
    return {
      scope: {
        report: '=report',
        model: '=?',
        extra: '=?',
        resultValidityInDays: '=?',
        options: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-task-button.html',
      replace: true,
      link: function($scope) {
        $scope.loaded = false;
        $scope.taskId = '';
        $scope.task = {};
        $scope.progress = 0;
        $scope.generated = false;
        $scope.dateFormat = LocalizationService.getDateTimeFormat('longdatetime');
        $scope.finished = false;
        $scope.failure = false;
        $scope.failureMessage = '';
        $scope.resultValidityInDays = ($scope.resultValidityInDays || 10);
        var refreshTaskInterval;

        var stopTaskRefresh = function() {
          if (angular.isDefined(refreshTaskInterval)) {
            $interval.cancel(refreshTaskInterval);
            refreshTaskInterval = undefined;
          }
        };

        $scope.cancelTask = function() {
          $scope.generated = false;
          $scope.failure = false;
          stopTaskRefresh();
        };

        var updateTask = function() {
          if ($scope.taskId && $scope.task.doc.state !== 'failure') {
            if ($scope.task.doc.meta) {
              $scope.progress = $scope.task.doc.meta.rate_complete;
              if ($scope.task.doc.meta.inner_state === 'finished') {
                $scope.finished = true;
                $scope.failure = false;
                stopTaskRefresh();
              } else {
                $scope.finished = false;
                $scope.failure = false;
              }
            }
          } else {
            $scope.finished = false;
            $scope.generated = false;
            $scope.failure = true;
            $scope.failureMessage = $scope.task.doc.error;
            stopTaskRefresh();
          }

          if ($scope.task) {
            $scope.dateGenerated = moment($scope.task.doc.createdDate).toDate();
          }
        };

        var startTaskRefresh = function() {
          if ($scope.generated && $scope.taskId) {
            refreshTaskInterval = $interval(function() {
              AsyncTasks.find($scope.taskId, { noCache: true })
                .then(function(doc) {
                  $scope.task.doc = doc;
                  updateTask();
                })
                .catch(function(err) {
                  $log.warn('Could not find task', $scope.taskId, err);
                });
            }, 1500);
          }
        };

        var findTask = function() {
          var params = {
            action: 'GenerateReport',
            'metadata:reportId': $scope.report.reportId,
            size: 1,
            sort_on: 'addedDate'
          };
          AsyncTasks.search(params)
            .then(function(tasks) {
              var task;
              if (tasks.hits.length > 0) {
                task = { doc: tasks.hits[0] };

                $scope.dateGenerated = moment(task.doc.createdDate).toDate();
                var resultExpired = moment(task.doc.resultExpirationDate);
                if (resultExpired.diff(moment()) > 0) {
                  $scope.taskId = task.doc._id;
                  $scope.task.id = task.doc._id;
                  $scope.task.doc = task.doc;
                  $scope.generated = true;
                  startTaskRefresh();
                  updateTask();
                }
              }
              $scope.loaded = true;
            })
            .catch(function(err) {
              // We do not need to inform the user about this, but it's good to log it
              $log.warn(err);
              $scope.loaded = true;
            });
        };

        $scope.generateTaskInBackground = function(regenerate) {
          $scope.generated = false;
          if (regenerate) {
            $scope.dateGenerated = moment().toDate();
          }
          $scope.report.generateAsTask(
            $scope.model, ($scope.extra || {}).user, $scope.resultValidityInDays, $scope.options
          )
            .then(function(result) {
              if (result.ok) {
                $scope.taskId = result.celeryTaskId;
                $scope.generated = true;
                $scope.finished = false;
                $scope.progress = 0;
                startTaskRefresh();
              }
            })
            .then(function() {
              AsyncTasks.find($scope.taskId)
                .then(function(doc) {
                  $scope.task.id = $scope.taskId;
                  $scope.task.doc = doc;
                  $scope.dateGenerated = moment(doc.createdDate);
                  updateTask();
                });
            })
            .catch(function(err) {
              console.log(err);
              var msg = 'Could not generate the report';
              if (err && err.message) {
                msg += ': ' + err.message;
              }
              Notify.error(msg);
            });
        };

        $scope.downloadGeneratedCSV = function() {
          AsyncTasks.downloadOutput($scope.taskId)
            .then(function(result) {
              window.location.href = result.url;
            })
            .catch(function(err) {
              $log.error(err);
              let msg;
              if (err && err.status === 403 && err.message) {
                msg = err.message;
              } else {
                msg = 'The configuration of this report has changed. Please regenerate the CSV ' +
                      'before attempting to download.';
              }
              Notify.error(msg);
            });
        };

        findTask();
        startTaskRefresh();

        $scope.$on('$destroy', function() {
          stopTaskRefresh();
        });
      }
    };
  }

  ReportTaskButtonDirective.$inject = [
    '$log',
    'AsyncTasksService',
    'NotifyService',
    'moment',
    'LocalizationService',
    '$interval'
  ];

  angular.module('component.reports')
    .directive('kzReportTaskButton', ReportTaskButtonDirective);
})();
