(function() {
  'use strict';

  function EmailTypeSlotController($state, $uibModal, EmailTypes, Security, Notify) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.setup();
    };

    ctrl.setup = function() {
      ctrl.actionButtons = [
        {
          label: 'View',
          ariaLabel: 'View ' + ctrl.emailType.doc.name,
          icon: 'icon-view',
          href: (function() {
            return $state.href('epf.email-templates.email-type',
              { emailTypeId: ctrl.emailType.doc.id });
          })(),
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          klass: 'text-info'
        },
        {
          label: 'View sent emails',
          ariaLabel: 'View ' + ctrl.emailType.doc.name + ' sent emails',
          icon: 'icon-view',
          href: (function() {
            return $state.href('epf.email-templates.email-type-log',
              { emailTypeId: ctrl.emailType.doc.id });
          })(),
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          klass: 'text-info'
        }
      ];
      ctrl.secondaryActionButtons = [
        {
          label: 'Send test email',
          ariaLabel: 'Send ' + ctrl.emailType.doc.name + ' test email',
          icon: 'icon-edit',
          onClick: function() {
            EmailTypes.sendTestEmail(ctrl.emailType.doc.id)
              .then(function() {
                Notify.success('A test email has been sent to your email address');
              })
              .catch(function(err) {
                var msg = 'Unknown error';
                if (err && err.message) {
                  msg = err.message;
                }
                Notify.error(msg);
              });
          },
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          klass: 'text-info'
        },
        {
          label: 'Preview test email',
          ariaLabel: 'Preview ' + ctrl.emailType.doc.name + ' test email',
          icon: 'icon-edit',
          onClick: function() {
            ctrl.preview();
          },
          showCondition: Security.hasPermission.bind(this, 'emailTemplates.view'),
          klass: 'text-info'
        }
      ];

      ctrl.preview = function() {
        $uibModal.open({
          animation: true,
          template: '<email-type-preview email-type-id="ctrl.emailTypeId" ' +
                    'on-close="$close()"></email-type-preview>',
          controller: ['emailTypeId',
            function(emailTypeId) {
              this.emailTypeId = emailTypeId;
            }
          ],
          controllerAs: 'ctrl',
          resolve: {
            emailTypeId: function() {
              return ctrl.emailType.doc.id;
            }
          },
          size: 'lg'
        }).result.catch(function(err) {
          console.log('Dismissed', err);
        });
      };
    };
  }

  EmailTypeSlotController.$inject = [
    '$state',
    '$uibModal',
    'EmailTypesService',
    'SecurityService',
    'NotifyService'
  ];

  angular.module('component.emailTemplates')
    .component('emailTypeSlot', {
      templateUrl: 'app/components/email-templates/email-type-slot.html',
      controller: EmailTypeSlotController,
      bindings: {
        emailType: '<',
        options: '<'
      }
    });
})();
