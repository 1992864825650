(function() {
  'use strict';

  function EventRejectMessageController($scope) {
    var ctrl = this;
    ctrl.latestAuditLog = _.last($scope.dates || []);
  }

  EventRejectMessageController.$inject = ['$scope'];

  function EventRejectMessageDirective() {
    return {
      scope: { dates: '=' },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-reject-message.html',
      replace: true,
      controller: EventRejectMessageController,
      controllerAs: 'ctrl'
    };
  }


  angular.module('events.directives')
    .directive('kzRejectMessage', EventRejectMessageDirective)
    .controller('EventRejectMessageController', EventRejectMessageController);
})();

