(function() {
  'use strict';

  function TargetsService() {
    var service = {};

    // used by the wizard that link events to target
    service._target = undefined;

    service.getTarget = function() {
      return service._target;
    };

    service.setTarget = function(target) {
      service._target = target;
    };

    return service;
  }

  TargetsService.$inject = [];

  angular.module('component.goals')
    .factory('TargetsService', TargetsService);
})();
