(function() {
  'use strict';

  function InboxController(Auth, Announcements, List, LocalizationService, Utils) {
    var ctrl = this;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    Utils.setPageTitle('Inbox');

    ctrl.list = new List({
      idField: '_id'
    });

    ctrl.options = {
      trackBy: '_id',
      borderClass: function(message) {
        var className = 'progress-border-';
        return message.isRead ? className + 'success' : className + 'pending';
      }
    };

    Announcements.findAllForUser(Auth.currentUser(), {
      include_docs: true
    }).then(function(items) {
      ctrl.list.doLoadItems(items);
    });
  }

  InboxController.$inject = [
    'AuthService',
    'AnnouncementsService',
    'ListFactory',
    'LocalizationService',
    'UtilsService'
  ];

  angular.module('component.announcements')
    .controller('InboxController', InboxController);
})();
