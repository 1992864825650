(function() {
  'use strict';

  function WysiwygController(config) {
    this.config = config;
  }

  WysiwygController.$inject = ['config'];

  function config(dashboardProvider) {
    dashboardProvider.widget('wysiwyg', {
      title: 'Text',
      description: 'Enter free text and embed videos',
      controller: 'WysiwygController',
      controllerAs: 'wysiwygCtrl',
      templateUrl: 'app/core/widgets/wysiwyg/view.html',
      edit: {
        templateUrl: 'app/core/widgets/wysiwyg/edit.html',
        controller: 'WysiwygController',
        controllerAs: 'wysiwygEditCtrl'
      },
      category: 'General'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('epf.core')
    .config(config)
    .controller('WysiwygController', WysiwygController);
})();
