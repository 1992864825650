(function() {
  'use strict';

  function AutoRegisterController($location, $log, loadingBar, Api, Cookies,
                                  kzLocalStorage, Setup, Utils) {
    var ctrl = this;
    ctrl.registering = false;

    Utils.setPageTitle('Automatically register');

    ctrl.loaded = false;
    Api.get('autoregister')
      .then(function(result) {
        ctrl.data = result.data;
        Cookies.put('currentLoginType', result.login_type);
        kzLocalStorage.setItem('kz-login', result.login_setup);
        ctrl.canAutoRegister = true;
        ctrl.loaded = true;
      })
      .catch(function(error) {
        ctrl.canAutoRegister = false;
        if (error && error.status === 403) {
          var cameFrom = $location.search().came_from || '/';
          $location.url(cameFrom);
        } else if (error && error.status === 400) {
          ctrl.canAutoRegister = false;
        } else {
          ctrl.error = error.message;
        }
        ctrl.loaded = true;
      });

    ctrl.register = function() {
      ctrl.registering = true;
      loadingBar.start();
      Api.post('autoregister')
        .then(function() {
          ctrl.registering = false;
          loadingBar.complete();
          var cameFrom = $location.search().came_from || '/';
          $location.url(cameFrom);
        })
        .catch(function(error) {
          ctrl.registering = false;
          $log.error(error);
          ctrl.error = error.message;
          loadingBar.complete();
        });
    };

    ctrl.logout = function() {
      Setup.logout();
    };
  }

  AutoRegisterController.$inject = [
    '$location',
    '$log',
    'cfpLoadingBar',
    'ApiService',
    'CookiesService',
    'kzLocalStorage',
    'SetupService',
    'UtilsService'
  ];

  angular.module('component.accounts')
    .controller('AutoRegisterController', AutoRegisterController);
})();
