(function() {
  'use strict';

  // function liveChatDirective($log, env, Profile, SERVER_CONFIG) {
  function liveChatDirective(env) {
    return {
      template: ' ',
      link: function($scope) {
        $scope.env = env;

        // var setup = function(org) {
        //   return Profile.find()
        //     .then(function(profile) {
        //       window.__lc = window.__lc || {};
        //       window.__lc.license = 11881647;
        //       var license = SERVER_CONFIG.liveChat.licenses[org];
        //       if (license) {
        //         window.__lc.license = license;
        //         window.__lc.visitor = {
        //           name: profile.firstName + ' ' + profile.lastName,
        //           email: profile.email
        //         };
        //         var startChat = function() {
        //           /* eslint-disable */
        //           var lc = document.createElement('script'); lc.type = 'text/javascript';
        //           lc.async = true;
        //           lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') +
        //           'cdn.livechatinc.com/tracking.js';
        //           var s = document.getElementsByTagName('script')[0];
        //           s.parentNode.insertBefore(lc, s);
        //           /* eslint-enable */
        //         };
        //         startChat();
        //       } else {
        //         $log.warn('LiveChat: No license for', org);
        //       }
        //     })
        //     .catch(function(err) {
        //       console.log(err);
        //     });
        // };
        // $scope.$watch(function() {
        //   return Auth.currentOrganisation();
        // }, function(val) {
        //   if (val) {
        //     setup(val);
        //   } else {
        //     $log.warn('LiveChat: How to destroy?');
        //   }
        // });
      }

    };
  }

  liveChatDirective.$inject = [
    // '$log', 'KZ_ENVIRONMENT', 'ProfileService', 'SERVER_CONFIG'
    'KZ_ENVIRONMENT'
  ];

  angular.module('blocks.utils')
    .directive('kzLiveChat', liveChatDirective);
})();
