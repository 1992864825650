(function() {
  'use strict';

  function ReportTemplatesService(
    $q,
    $rootScope,
    EasApiVersionStore,
    EasApi,
    Auth,
    Utils,
    kzLocalStorage
  ) {
    var cacheKeys = [];
    var service = new EasApiVersionStore('reports', { cacheKeys: cacheKeys, envelope: 'doc' });
    var reportData = {};

    service.tempReportData = {};
    service.selectedTags = [];

    /**
     * Return the initial structure of the event type
     * @return {object}
     */
    service.getDefaultDoc = function() {
      return {
        _id: Utils.guid(),
        type: 'reportTemplate',
        source: 'events',
        organisation: Auth.currentOrganisation(),
        location: 'organisation',
        filter: {
          id: Utils.guid(),
          filterType: 'compFilter',
          operator: 'and',
          filters: []
        },
        sort: 'asc',
        versionGroupId: Utils.guid(),
        state: 'draft'
      };
    };

    service.searchIds = function(data) {
      var opts = { version: this.apiVersion };
      return EasApi.post('reports/search', data, undefined, opts);
    };

    service.fetchIds = function(ids, options) {
      var opts = { version: this.apiVersion };
      return EasApi.post('reports/fetch', { ids: ids, options: options || {} }, undefined, opts)
        .then(function(data) {
          return data.hits;
        });
    };

    service.fetch = function(id, transformType) {
      return this.fetchIds([id], { transformType: transformType })
        .then(function(data) {
          if (data.length === 0) {
            return $q.reject({ status: 404, message: 'Not found' });
          }

          return data[0];
        });
    };

    service.putReportData = function(data) {
      reportData = data;
      kzLocalStorage.setItem('reportData', { reportData: data });
    };

    service.getReportData = function(rid, location) {
      if (rid && location) {
        return service.find(rid, location === 'organisation');
      }

      if (_.isEmpty(reportData)) {
        reportData = kzLocalStorage.getItem('reportData').reportData || {};
      }

      return reportData;
    };

    service.setSelectedTags = function(tags) {
      service.selectedTags = tags;
      $rootScope.$broadcast('reports.selectedTagsChanged');
    };

    // service.getEventTypeUsage = function(reportId, options) {
    //   options = options || {};
    //   options.startkey = ['report', reportId];
    //   options.endkey = ['report', reportId, {}];
    //   return this.store.query('event_types_special_fields', options)
    //     .catch(function(err) {
    //       if (err && err.status === 509) {
    //         // This is rejecting on purpose - it's not worth implementing
    //         return $q.reject(err);
    //       }

    //       return $q.reject(err);
    //     });
    // };

    service.getEventTypeUsage = function(reportId) {
      var opts = { version: this.apiVersion };
      var url = this.specificUrl(reportId) + '/usage';
      return EasApi.get(url, undefined, {}, opts);
    };

    return service;
  }

  ReportTemplatesService.$inject = [
    '$q',
    '$rootScope',
    'EasApiVersionStore',
    'EasApiService',
    'AuthService',
    'UtilsService',
    'kzLocalStorage'
  ];

  angular.module('component.reports')
    .factory('ReportTemplatesService', ReportTemplatesService);
})();
