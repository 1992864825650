(function() {
  'use strict';

  function Slot($q, $state, $stateParams, Auth, EventTypes, Organisations) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/goals/cards/list.card.html',
      link: function($scope) {
        $q.all([
          Organisations.getFilter('epf.goals.index', $stateParams.filterId),
          EventTypes.findAvailableFor(Auth.currentUser())
        ])
          .then(function(results) {
            var eventTypes = results[1];
            var versionGroupIds = [];

            if (!_.isUndefined(results[0]) && !_.isUndefined(results[0].filter)) {
              versionGroupIds = results[0].filter.eventType_versionGroupId || [];
            }

            return _.filter(eventTypes, function(eventType) {
              if (
                versionGroupIds.length &&
                versionGroupIds.indexOf(eventType.versionGroupId) === -1
              ) {
                return false;
              }

              if (eventType.sectionSummary && eventType.sectionSummary.length > 0) {
                return eventType.sectionSummary[0].goalSection;
              }
              // var goalField = _.find(eventType.doc.sections[0].fields, function(field) {
              //   return field.type === 'goal';
              // });


              return true;
            });
          })
          .then(function(eventTypesWithGoals) {
            // $scope.goalsEventTypeAvailable = eventTypesWithGoals;
            $scope.goalsEventTypeAvailable = _.map(eventTypesWithGoals, function(eventType) {
              return {
                name: eventType.name,
                _id: eventType._id
              };
            });
          });

        $scope.createTmpEvent = function(eventTypeId) {
          return $state.go('epf.events.section-new', {
            eventTypeId: eventTypeId,
            previousRoute: 'epf.goals.index'
          });
        };
      }
    };
  }

  Slot.$inject = [
    '$q',
    '$state',
    '$stateParams',
    'AuthService',
    'EventTypesService',
    'OrganisationsService'
  ];

  angular.module('component.goals')
    .directive('goalsListCard', Slot);
})();
