(function() {
  'use strict';

  angular.module('epf.blocks',
    [
      'blocks.api',
      'blocks.auth',
      'blocks.asyncTasks',
      'blocks.cache',
      'blocks.cookies',
      'blocks.db',
      'blocks.easapi',
      'blocks.setup',
      'blocks.list',
      'blocks.lunr',
      'blocks.queue',
      'blocks.network',
      'blocks.notify',
      'blocks.security',
      'blocks.services',
      'blocks.stores',
      'blocks.utils',
      'blocks.versions',
      'blocks.widgets',
      'blocks.types',
      'blocks.pdf',
      'blocks.image'
    ]);
})();
