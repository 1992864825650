(function() {
  'use strict';

  function QueueService(UserService, Api, Utils, Profile, Auth) {
    var service = new UserService('queue');

    function prepareMessage(action, msg, relatedId) {
      return {
        _id: Utils.guid(),
        type: 'queue',
        state: 'new',
        action: action,
        related: relatedId,
        createdDate: Utils.now(),
        user: Auth.currentUser(),
        login: Auth.currentLogin(),
        organisation: Profile.organisation(),
        data: msg
      };
    }

    service.postRequest = function(action, msg, relatedId) {
      var message = prepareMessage(action, msg, relatedId);
      return this.save(message);
    };

    service.submitRequest = function(action, msg, relatedId) {
      var message = prepareMessage(action, msg, relatedId);
      return Api.post('msg_submit', message)
        .then(function(data) {
          return data.result;
        });
    };

    service.findByRelated = function(oid) {
      return this.findAll()
        .then(function(data) {
          var group = _.chain(data)
            .filter(function(item) {
              return item.doc.related === oid;
            })
            .sortBy('createdDate')
            .reverse()
            .value();

          return group;
        });
    };

    service.ack = function(doc) {
      doc.ack = true;
      return this.save(doc);
    };

    return service;
  }

  QueueService.$inject = ['UserService', 'ApiService', 'UtilsService', 'ProfileService',
    'AuthService'];

  angular.module('blocks.queue')
    .service('QueueService', QueueService);
})();
