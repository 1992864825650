(function() {
  'use strict';

  function BaseVersionStoreFactory($q, $log, BaseConfStore, Utils, Cache) {
    var Service = function() {
      BaseConfStore.call(this);
      this.id = 'versionstore';
      this.groupedStores = {};
    };

    Utils.extends(Service, BaseConfStore);

    Service.prototype.invalidate = function() {
      this.stores = {};
      this.loadings = {};
      this.groupedStores = {};
      this.dirst = {};
      this.lastUpdated = {};
      this.idbError = undefined;
      this._db = undefined;
    };

    Service.prototype.storeUpdated = function(type) {
      // $log.debug('Group store invalidated for', type);
      delete this.groupedStores[type];
    };

    /**
     * Return version groups with linked versions
     *
     * @param  {string} type   Document type
     * @param  {object} kzOpts Reserved for additional options
     * @return {array}         An array of groups
     */
    Service.prototype.findAllGrouped = function(type, kzOpts) {
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: type + '-findall-grouped',
        maxAge: 60 * 1000,
        cached: true
      }, kzOpts);
      var func = this._findAllGrouped.bind(this, type, kzOpts);

      return Cache.cachedPromise(func, ext);
    };

    /**
     * Return list of published items
     *
     * This is all the active that can be used by user
     *
     * @param  {string} type Document type
     * @return {array}       An array of items
     */
    Service.prototype.findLatestPublishedGrouped = function(type) {
      return this.findAllGrouped(type)
        .then(function(data) {
          return _.filter(data, function(item) {
            return item.doc.state === 'published';
          });
        });
    };

    Service.prototype.findLatestAvailableGrouped = function(type) {
      return this.findAllGrouped(type)
        .then(function(data) {
          return _.filter(data, function(item) {
            return item.doc.state === 'published' ||
                   (item.doc.state === 'archived' && !item.doc.nextVersion);
          });
        });
    };


    /**
     * Return all available items
     *
     * This means items that were at least once published
     *
     * @param  {string} type Document type
     * @return {array}       An array of items
     */
    Service.prototype.findLatestAvailable = function(type) {
      return this.findAll(type)
        .then(function(data) {
          return _.filter(data, function(item) {
            return item.doc.state === 'published' ||
                   (item.doc.state === 'archived' && !item.doc.nextVersion);
          });
        });
    };

    /**
     * Return list of published items
     *
     * This is all the active that can be used by user
     *
     * @param  {string} type Document type
     * @return {array}       An array of items
     */
    Service.prototype.findLatestPublished = function(type) {
      return this.findAll(type)
        .then(function(data) {
          return _.filter(data, function(item) {
            return item.doc.state === 'published';
          });
        });
    };

    /**
     * Return latest items including drafts
     *
     * @param  {string} type Document type
     * @return {array}       An array of items
     */
    Service.prototype.findLatest = function(type) {
      return this.findAll(type)
        .then(function(data) {
          return _.filter(data, function(item) {
            return !item.doc.nextVersion;
          });
        });
    };

    /**
     * Return group by individual item id
     *
     * @param  {string} type   Document type
     * @param  {string} itemId Item ID
     * @return {object}        Group object
     */
    Service.prototype.getGroupByItemId = function(type, itemId) {
      var _this = this;
      return this.get(type, itemId)
        .then(function(doc) {
          return _this.getGroupById(type, doc.versionGroupId);
        })
        .then(function(group) {
          // Set the doc to be the itemId
          group.doc = _.find(group.linkedVersions, { _id: itemId });
          return group;
        });
    };

    /**
     * Return group by version group id
     *
     * @param  {string} type    Document type
     * @param  {string} groupId Version group id
     * @return {object}         Group object
     */
    Service.prototype.getGroupById = function(type, groupId) {
      var _this = this;
      var group = (this.groupedStores[type] || {})[groupId];

      // If it exists, return it otherwise try to obtain it
      if (group !== undefined) {
        return $q.when(angular.copy(group));
      }

      $log.debug('Did not find group in cache. Is it expected?');
      return this.findAll(type, { maxAge: 1 })
        .then(function(data) {
          var items = _.filter(data, function(item) {
            return item.doc.versionGroupId === groupId;
          });
          if (items.length === 0) {
            return $q.reject({ status: 404, message: 'Object not found' });
          }

          return angular.copy(_this._initGroup(items, groupId));
        });
    };

    /**
     * Return groups by list of version group ids
     *
     * @param  {string} type      Document type
     * @param  {string} groupIds  Version group ids
     * @return {list}             List of group objects
     */
    Service.prototype.getGroupsByIds = function(type, groupIds) {
      var _this = this;
      var promises = _.map(groupIds, function(groupId) {
        return _this.getGroupById(type, groupId)
          .catch(function(err) {
            return err.status !== 404 ? $q.reject(err) : $q.when();
          });
      });
      return $q.all(promises)
        .then(function(results) {
          return _.filter(results, function(item) {
            return !_.isUndefined(item);
          });
        });
    };

    /**
     * Return groups by list of item ids
     *
     * @param  {string} type    Document type
     * @param  {string} itemIds Item ids
     * @return {list}           List of group objects
     */
    Service.prototype.getGroupsByItemIds = function(type, itemIds) {
      var _this = this;
      return this.findKeys(type, itemIds)
        .then(function(data) {
          return _.map(data, function(item) {
            return item.doc.versionGroupId;
          });
        })
        .then(function(groupIds) {
          return _this.getGroupsByIds(groupIds);
        });
    };

    /**
     * Return groups by list of item ids
     *
     * @param  {string} type    Document type
     * @param  {string} itemIds Item ids
     * @return {list}           List of group objects
     */
    Service.prototype.clearGroupById = function(type, groupId) {
      var _this = this;
      delete (this.groupedStores[type] || {})[groupId];
      return this.findAll(type, { maxAge: 1 })
        .then(function(data) {
          var items = _.filter(data, function(item) {
            return item.doc.versionGroupId === groupId;
          });

          var toRemove = { toRemove: _.map(items, function(item) {
            return item.doc._id;
          }) };

          return _this._updateStore(type, toRemove);
        });
    };

    Service.prototype._findAllGrouped = function(type, kzOpts) {
      kzOpts = kzOpts || {};
      var _this = this;
      return this.findAll(type, kzOpts)
        .then(function(data) {
          if (kzOpts.cached && _this.groupedStores[type] !== undefined) {
            return _this.groupedStores[type];
          }
          var grouped = _.groupBy(data, 'doc.versionGroupId');
          var initted = {};
          _.forOwn(grouped, function(items, gid) {
            initted[gid] = _this._initGroup(items, gid);
          });
          _this.groupedStores[type] = initted;
          return _.values(initted);
        });
    };

    /**
     * Return a group object with items sorted by version
     * @param  {list} items list of items in the group
     * @param  {string} groupId versionGroupId
     * @return {object}       Group object
     */
    Service.prototype._initGroup = function(items, groupId) {
      var group = {
        _id: groupId,
        linkedVersions: []
      };

      // Will use this object to look up rather than iterating
      // through (_.find) again and again
      // Is it better to use memory or to iterate over and over?

      var byNext = _.groupBy(items, 'doc.nextVersion');
      var last = _.find(items, function(item) {
        return _.isEmpty(item.doc.nextVersion);
      });

      if (_.isUndefined(last)) {
        $log.warn('There is no last item. Mismatched event type', items[0], groupId);
        // Last item does not exist, what to do?
        group.doc = items[0].doc;
        group.linkedVersions.unshift(group.doc);
        return group;
      }

      group.linkedVersions.push(last.doc);

      var curr = last;
      var prev;

      while (curr) {
        // We know that if it exists it has at least one item in the array
        prev = byNext[curr.doc._id];

        // How to detect cycle? Otherwise it should always stop
        if (prev) {
          group.linkedVersions.unshift(prev[0].doc);
          curr = prev[0];
        } else {
          curr = undefined;
        }
      }

      var published = _.findLast(group.linkedVersions, function(item) {
        return item.state !== 'draft';
      });

      // In case there is no published one, just use the one we have
      if (published === undefined) {
        published = _.last(group.linkedVersions);
      }

      group.doc = published;

      return group;
    };

    return Service;
  }

  function BaseVersionStoreService($rootScope, BaseVersionStoreFactory) {
    var service = new BaseVersionStoreFactory();
    $rootScope.$on('KZClearCache', function() {
      service.invalidate();
    });
    return service;
  }

  BaseVersionStoreFactory.$inject = ['$q', '$log', 'BaseConfStoreFactory', 'UtilsService',
    'CacheService'];
  BaseVersionStoreService.$inject = ['$rootScope', 'BaseVersionStoreFactory'];

  angular.module('blocks.stores')
    .factory('BaseVersionStoreFactory', BaseVersionStoreFactory)
    .service('BaseVersionStoreService', BaseVersionStoreService);
})();
