(function() {
  'use strict';

  var networkStatus = {
    online: 'online',
    offline: 'offline'
  };

  angular.module('blocks.network')
    .constant('NETWORK', networkStatus);
})();
