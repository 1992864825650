(function() {
  'use strict';

  function filter() {
    return function(input) {
      return _.isArray(input);
    };
  }

  angular.module('blocks.utils')
    .filter('kzIsArray', filter);
})();
