(function() {
  'use strict';

  function BlueprintMapController(BlueprintMaps, Utils) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.load();
    };

    ctrl.load = function() {
      ctrl.blueprintMap = {};

      if (ctrl.id) {
        BlueprintMaps.find(ctrl.id)
          .then(function(data) {
            ctrl.blueprintMap = data;
            Utils.setPageTitle('Blueprint map: ' + ctrl.blueprintMap.name);
            return ctrl.prepareData(data);
          })
          .then(function() {
            ctrl.loaded = true;
          });
      } else {
        Utils.setPageTitle('Blueprint map');
        ctrl.loaded = true;
      }
    };

    ctrl.prepareData = function(blueprintMap) {
      return BlueprintMaps.loadBMapBlueprints(blueprintMap)
        .then(function(blps) {
          ctrl.blueprints = blps;
        });
    };
  }

  BlueprintMapController.$inject = ['BlueprintMapsService', 'UtilsService'];

  angular.module('component.blueprints')
    .component('blueprintMapView', {
      templateUrl: 'app/components/blueprints/blueprintMap.view.html',
      controller: BlueprintMapController,
      bindings: {
        id: '<'
      }
    });
})();
