(function() {
  'use strict';

  function LocalUsersService(
    $q,
    $rootScope,
    Database,
    Notify
  ) {
    var service = {};

    var getDb = function(kzOpts) {
      kzOpts = kzOpts || { alwaysAllow: true };
      return Database.getDb('stored_users', undefined, kzOpts);
    };

    service.getDb = function(kzOpts) {
      return $q.when(getDb(kzOpts));
    };

    service.storeUser = function(profile) {
      var db;
      var doc = {
        _id: profile._id,
        rev: profile._rev,
        username: profile.username,
        firstName: profile.firstName,
        lastName: profile.lastName,
        organisation: profile.organisation
      };

      return getDb({ alwaysAllow: false })
        .then(function(res) {
          db = res;
        })
        .then(function() {
          return db.get(doc._id);
        })
        .then(function(user) {
          // If the rev is the same, do not save
          if (user.rev === doc.rev) {
            return;
          }

          doc._rev = user._rev;
          return db.put(doc);
        })
        .catch(function(err) {
          if (err && err.status === 404) {
            return db.put(doc);
          }

          if (err && err.status === 501) {
            return;
          }

          return $q.reject(err);
        });
    };

    service.findAll = function() {
      return getDb()
        .then(function(db) {
          return db.allDocs({ include_docs: true });
        })
        .then(function(data) {
          return _.map(data.rows, 'doc');
        });
    };

    service.getUserDb = function(user, kzOpts) {
      return Database.getLocalDbName('idbCache', user.username, user.organisation)
        .then(function(dbName) {
          return Database.getDb(dbName, undefined, kzOpts);
        });
    };

    service.getRealProfile = function(user) {
      return service.getUserDb(user)
        .then(function(db) {
          return db.get('store-user');
        })
        .then(function(data) {
          var profile = data.store[user._id];
          if (profile === undefined) {
            return $q.reject({ status: 404, message: 'Profile not found' });
          }
          return profile;
        });
    };

    service.getRealOrganisation = function(user) {
      return service.getUserDb(user)
        .then(function(db) {
          return db.get('store-organisation');
        })
        .then(function(data) {
          var profile = data.store[user.organisation];
          if (profile === undefined) {
            return $q.reject({ status: 404, message: 'Organisation not found' });
          }
          profile.doc.id = profile.doc._id;
          return profile.doc;
        });
    };

    service.getUserInfo = function(user) {
      return $q.all([
        service.getRealProfile(user),
        service.getRealOrganisation(user)
      ])
      .then(function(result) {
        return {
          doc: result[0].doc,
          organisation: result[1]
        };
      });
    };

    service.getProfilesFor = function(username) {
      return service.findAll()
        .then(function(data) {
          var users = _.filter(data, { username: username });
          return $q.all(_.map(users, function(user) {
            return service.getRealProfile(user)
              .catch(function() {
                return;
              });
          }));
        })
        .then(function(users) {
          return _.filter(users, function(user) {
            return user !== undefined;
          });
        })
        .catch(function(err) {
          console.log(err);
          return [];
        });
    };

    service.getOrganisationsFor = function(username) {
      return service.getProfilesFor(username)
        .then(function(users) {
          return $q.all(_.map(users, function(item) {
            return service.getRealOrganisation(item.doc)
              .catch(function(err) {
                console.log(err);
              });
          }));
        })
        .then(function(orgs) {
          return _.filter(orgs, Boolean);
        });
    };

    $rootScope.$on('KZRemoveLocalData', function(_evt, args) {
      return service.removeDataFor(args.username);
    });

    service.removeDataFor = function(username) {
      return service.getProfilesFor(username)
        .then(function(users) {
          var promises = _.map(users, function(user) {
            return service.getUserDb(user.doc, { alwaysAllow: true })
              .then(function(db) {
                return db.destroy();
              })
              .catch(function(err) {
                console.log('Could not remove database for', user, err);
              });
          });

          return $q.all(promises);
        })
        .then(function() {
          Notify.success('Local data have been removed');
        });
    };

    service.removeDb = function() {
      return Database.destroyLocalDb('stored_users');
    };

    return service;
  }

  LocalUsersService.$inject = [
    '$q',
    '$rootScope',
    'DatabaseService',
    'NotifyService'
  ];

  angular.module('blocks.services')
    .service('LocalUsersService', LocalUsersService);
})();
