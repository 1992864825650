(function() {
  'use strict';

  function Interceptor($q, $rootScope) {
    return {
      // On request failure
      responseError: function(rejection) {
        if (rejection && 'status' in rejection &&
            [0, 502, 503, 510].indexOf(rejection.status) !== -1) {
          $rootScope.$broadcast('NetworkOffline');
        }

        // Return the promise rejection.
        return $q.reject(rejection);
      }
    };
  }

  function config($provide, $httpProvider) {
    $provide.factory('ConnectivityInterceptor', Interceptor);
    $httpProvider.interceptors.push('ConnectivityInterceptor');
  }

  config.$inject = ['$provide', '$httpProvider'];

  Interceptor.$inject = ['$q', '$rootScope'];

  angular.module('blocks.network')
    .config(config);
})();
