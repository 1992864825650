
(function() {
  'use strict';

  function GoalsDefinePeriodMeasurementsController($scope, Form) {
    var ctrl = this;

    function getOptionsFor(meas) {
      var usedPeriods = _.map($scope.measurements, 'period');
      return _.chain($scope.goalSetPeriods)
        .filter(function(period) {
          if (!_.isUndefined(meas) && meas.period === period._id) {
            return true;
          }

          return usedPeriods.indexOf(period._id) === -1;
        })
        .map(function(period) {
          return { _id: period._id, key: period._id, name: period.name };
        })
        .value();
    }

    function getNewForm(meas) {
      return new Form([
        {
          id: 'period',
          type: 'discrete',
          label: 'Period',
          required: true,
          options: getOptionsFor(meas),
          getTitle: function(val) {
            var item = _.find($scope.goalSetPeriods, { _id: val });
            if (item) {
              return item.name;
            }

            return val;
          }
        },
        {
          id: 'count',
          type: 'numeric',
          label: 'How many do you want to achieve? (0 for unlimited)',
          required: true,
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            return !_.isUndefined($scope.model.filterMeasurements);
          }
        },
        {
          id: 'filters',
          type: 'filters',
          label: 'Override the progress measurement for this period',
          required: false
        }
      ]);
    }

    ctrl.periodMeasurementForms = {};
    function loadMeasurementForms() {
      ctrl.periodMeasurementForms = _.map($scope.measurements, function(meas) {
        return getNewForm(meas);
      });
    }

    $scope.$watch('measurements', function(measurements) {
      _.forEach(ctrl.periodMeasurementForms, function(form, idx) {
        var fld = _.find(form.fields, { id: 'period' });
        if (fld === undefined) {
          return;
        }
        fld.templateOptions.options = getOptionsFor(measurements[idx]);
      });
    }, true);

    loadMeasurementForms();

    ctrl.addPeriodMeasurement = function() {
      $scope.measurements.push({});
      ctrl.periodMeasurementForms.push(getNewForm());
    };

    ctrl.removePeriodMeasurement = function(index) {
      $scope.measurements.splice(index, 1);
      ctrl.periodMeasurementForms.splice(index, 1);
    };
  }

  GoalsDefinePeriodMeasurementsController.$inject = [
    '$scope',
    'FormsService'
  ];

  function GoalsDefinePeriodMeasurementsDirective() {
    return {
      scope: {
        measurements: '=',
        goalSetPeriods: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/goals.define.period.measurements.html',
      replace: true,
      controller: GoalsDefinePeriodMeasurementsController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('goalsDefinePeriodMeasurements', GoalsDefinePeriodMeasurementsDirective)
    .controller('GoalsDefinePeriodMeasurementsController', GoalsDefinePeriodMeasurementsController);
})();
