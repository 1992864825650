(function() {
  'use strict';

  function AvatarRemoteCardController(
    $scope,
    $timeout,
    Organisations,
    Setup,
    ClusterService,
    AsyncTasks
  ) {
    var ctrl = this;

    ctrl.error = false;
    ctrl.isRemoteProfile = $scope.isRemoteProfile || false;
    ctrl.user = $scope.user;

    var setAvatarData,
        setError,
        getFullName;

    getFullName = function(first, last) {
      return _.trim(first + ' ' + last);
    };

    setAvatarData = function(data) {
      data = data || {};
      var firstName = data.firstName ? data.firstName : '',
          lastName = data.lastName ? data.lastName : '';
      return {
        fullName: getFullName(firstName, lastName),
        imageUrl: data.imageUrl ? data.imageUrl : null
      };
    };

    setError = function() {
      if (_.isUndefined(ctrl.avatar)) {
        ctrl.error = true;
        ctrl.message = 'Could not load profile!';
      } else {
        ctrl.error = false;
      }
    };

    $scope.$on('OrganisationProfilesLoaded', function() {
      ctrl.showOrg = ClusterService.getOrganisationsCount() > 1;
    });
    ctrl.showOrg = ClusterService.getOrganisationsCount() > 1;

    $scope.$watch('user', function(value) {
      if (!_.isUndefined(value)) {
        ctrl.user = value;
        Organisations.find()
          .then(function(org) {
            ctrl.avatar = _.assign(
              {},
              value,
              setAvatarData(value),
              {
                roles: _.filter(value.roles || [], function(role) {
                  return !_.startsWith(role, 'system:');
                }),
                organisation: org.name
              }
            );
            setError();
          })
          .catch(function() {
            setError();
          });
      }
    });

    ctrl.logout = function() {
      Setup.logout();
    };

    ctrl.preparePendingItems = function(celeryStatus) {
      var totalCount = 0;
      var pending = celeryStatus;
      if (pending && pending.totalPending) {
        totalCount += pending.totalPending;
      }

      ctrl.pendingItems = {
        total: totalCount,
        perAction: celeryStatus.perAction
      };
    };

    var updator = function() {
      ctrl.timeoutHandler = undefined;
      AsyncTasks.pendingTaskCount(ctrl.user.user)
        .then(function(celeryStatus) {
          ctrl.preparePendingItems(celeryStatus);
        })
        .catch(function(err) {
          console.log('Failed getting pendingTask count', err);
        })
        .finally(function() {
          if (ctrl.pendingItems && ctrl.pendingItems.total > 0) {
            console.log('Starting remote user in 6s');
            ctrl.timeoutHandler = $timeout(updator, 6000);
          } else {
            console.log('Starting remote user in 10m');
            ctrl.timeoutHandler = $timeout(updator, 600000);
          }
        });
    };
    updator();

    $scope.$watch(function() {
      return AsyncTasks.triggerUpdate;
    }, function() {
      console.log('Change detected');
      if (ctrl.timeoutHandler) {
        console.log('Cancelling remote pending user timeout');
        $timeout.cancel(ctrl.timeoutHandler);
      }
      updator();
    });

    // A attempt to blink if we are in online mode but the browser is online
    var toggler;
    function setMode() {
      if (!ctrl.info || !ctrl.info.mode || !ctrl.info.network) {
        toggler = $timeout(setMode, 1000);
        return;
      }
      if (ctrl.info.mode.status === ctrl.info.network.status) {
        ctrl.mode = ctrl.info.mode.status;
      } else {
        ctrl.mode = ctrl.mode === 'online' ? 'offline' : 'online';
      }
      toggler = $timeout(setMode, 1000);
    }

    if (!ctrl.isRemoteProfile) {
      // $scope.$on('KZStatusInfoUpdated', function(_evt, info) {
      //   ctrl.info = info;
      //   ctrl.preparePendingItems(info, AsyncTasks.status);
      // });

      // $scope.$watch(function() {
      //   return AsyncTasks.status;
      // }, function(status) {
      //   ctrl.preparePendingItems(ctrl.info, status);
      // });

      setMode();
    }

    $scope.$on('$destroy', function() {
      if (toggler) {
        $timeout.cancel(toggler);
      }

      if (ctrl.timeoutHandler) {
        console.log('Cancelling remote pending user timeout');
        $timeout.cancel(ctrl.timeoutHandler);
      }
    });
  }

  function AvatarRemoteCardDirective() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        user: '=',
        isRemoteProfile: '=?'
      },
      templateUrl: 'app/layout/header/kz-avatar-remote-card.html',
      controller: AvatarRemoteCardController,
      controllerAs: 'avatarCardCtrl'
    };
  }

  AvatarRemoteCardController.$inject = [
    '$scope',
    '$timeout',
    'OrganisationsService',
    'SetupService',
    'ClusterService',
    'AsyncTasksService'
  ];

  angular.module('kzHeaderCard')
    .directive('kzAvatarRemoteCard', AvatarRemoteCardDirective);
})();
