(function() {
  'use strict';

  function GoalsetPeriodsController($scope, Form, Utils, Blueprints, TREE_OPTIONS) {
    var ctrl = this;

    if ($scope.readonly) {
      $scope.options = {
        formState: { readOnly: true }
      };
    }

    function getBlueprintField(blueprints) {
      return {
        id: 'categories',
        controller: ['$scope', function($scope) {
          $scope.tree = blueprints;
          $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
          $scope.treeOptions = _.assign(angular.copy(TREE_OPTIONS), { inline: false, limit: 1 });
        }],
        type: 'tree',
        label: 'Add a tag',
        required: false,
        ngModelAttrs: {
          sequoiaTree: {
            bound: 'sequoia-tree',
            attribute: 'sequoia-tree'
          },
          nodeTemplate: {
            bound: 'node-template',
            attribute: 'node-template'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'nodeTemplate',
            value: 'nodeTemplate'
          },
          {
            name: 'options',
            value: 'treeOptions'
          },
          {
            name: 'sequoiaTree',
            value: 'tree'
          }
        ]
      };
    }

    function loadPeriodForms() {
      return Blueprints.findAll()
        .then(function(data) {
          var blueprints = _.map(data, 'doc');
          blueprints = _.filter(blueprints, function(bl) {
            if (!_.isArray(bl.usage)) {
              return false;
            }
            return bl.usage.indexOf('goal_periods') !== -1;
          });
          ctrl.periodForms = _.map($scope.model.periods, function() {
            return new Form([
              {
                id: 'name',
                type: 'string',
                label: 'Name',
                required: true
              },
              getBlueprintField(blueprints),
              {
                id: 'filters',
                type: 'filters',
                label: 'Choose how to measure progress against this period by setting filters ' +
                  'below',
                required: false
              }
            ]);
          });
        });
    }

    loadPeriodForms();

    ctrl.addPeriod = function() {
      if (_.isUndefined($scope.model.periods)) {
        $scope.model.periods = [];
      }

      $scope.model.periods.push({ _id: Utils.guid() });

      loadPeriodForms();
    };

    function periodsAssignedOnTargets(periodId, goals) {
      var assignedTo = [];
      _.forEach(goals, function(goal) {
        _.forEach(goal.targets, function(target) {
          _.forEach(target.conditions, function(condition) {
            _.forEach(condition.periodMeasurements, function(periodMeasurement) {
              if (periodId === periodMeasurement.period) {
                assignedTo.push('Goal: ' + goal.title + ' / Target: ' + target.title);
              }
            });
          });
        });
      });

      return assignedTo;
    }

    ctrl.removePeriod = function($index) {
      var periodId = $scope.model.periods[$index]._id,
          goals = $scope.model.definition,
          assignedOnTargets = periodsAssignedOnTargets(periodId, goals);
      if (!_.isEmpty(assignedOnTargets)) {
        var html = '';
        var andFewMoreCount = 0;
        _.forEach(assignedOnTargets, function(targetName, i) {
          if (i < 3) {
            html += '- ' + targetName + '\n';
          } else {
            andFewMoreCount += 1;
          }
        });
        if (andFewMoreCount !== 0) {
          html += '+' + andFewMoreCount + ' more.';
        }

        Utils.swal({
          title: 'You cannot remove this period until it is unassigned from the following targets:',
          text: html,
          type: 'error'
        });
        return;
      }

      $scope.model.periods.splice($index, 1);
      if (_.isEmpty($scope.model.periods)) {
        delete $scope.model.periods;
      }

      loadPeriodForms();
    };

    $scope.$watch(function() {
      return $scope.model.periods;
    }, function(value, oldval) {
      if (!value) {
        return;
      }
      if (value === oldval) {
        return;
      }

      _.forEach(value, function(period, idx) {
        var oldPeriod = (oldval && oldval[idx]) || {};
        if (period.categories && period.categories !== oldPeriod.categories) {
          Blueprints.findByCategoryId(period.categories)
            .then(function(val) {
              if (!period.name) {
                period.name = val.value;
              }
              period.blueprint = [{ blueprint: val.rootId, category: val.key }];
            });
        }
      });
    }, true);
  }

  GoalsetPeriodsController.$inject = [
    '$scope', 'FormsService', 'UtilsService', 'BlueprintsService', 'TREE_OPTIONS'
  ];

  function GoalsetPeriodsDirective() {
    return {
      scope: {
        model: '=',
        readonly: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/goalset.periods.html',
      replace: true,
      controller: GoalsetPeriodsController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('goalsetPeriods', GoalsetPeriodsDirective)
    .controller('GoalsetPeriodsController', GoalsetPeriodsController);
})();
