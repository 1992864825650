(function() {
  'use strict';

  function filter() {
    return function(input, words) {
      if (isNaN(words)) {
        return input;
      }

      if (words <= 0) {
        return '';
      }

      if (input) {
        var inputWords = input.split(/\s+/);
        if (inputWords.length > words) {
          input = inputWords.slice(0, words).join(' ') + '…';
        }
      }

      return input;
    };
  }

  angular.module('blocks.utils')
    .filter('truncate', filter);
})();
