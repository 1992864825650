(function() {
  'use strict';

  function eventAutoSaveDirective($filter, moment, LocalizationService) {
    return {
      scope: {
        isSaving: '=',
        model: '=',
        delete: '&',
        showDeleteButton: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-autosave.html',
      replace: true,
      link: function($scope) {
        var calculateLastModified = function(date) {
          var md;
          var dateFormat = LocalizationService.getDateTimeFormat('datetime');
          var timeFormat = LocalizationService.getDateTimeFormat('timeonly');
          if (moment().diff(moment(date), 'hours') < 48) {
            md = moment(date).calendar(moment(), timeFormat);
          } else {
            md = $filter('date')(date, dateFormat);
          }

          return md;
        };

        $scope.$watchCollection(
          function() {
            return $scope.model;
          },
          function(newVal, oldVal) {
            if (newVal !== oldVal && $scope.model.date) {
              $scope.lastModified = calculateLastModified($scope.model.date.modified);
            }
          }
        );
      }
    };
  }

  eventAutoSaveDirective.$inject = ['$filter', 'moment', 'LocalizationService'];

  angular.module('events.directives')
    .directive('eventAutoSaveDirective', eventAutoSaveDirective);
})();
