(function() {
  'use strict';

  var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];

  function filter() {
    return function(bytes, precision) {
      precision = typeof precision === 'undefined' ? 1 : precision;

      if (!bytes) {
        return '';
      }

      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
        return '-';
      }

      var number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
    };
  }

  angular.module('blocks.utils')
    .filter('bytes', filter);
})();
