(function() {
  'use strict';

  function MultiReportEditElementController(
    $q,
    $state,
    $stateParams,
    Form,
    Report,
    Reports,
    Notify,
    MultiReport,
    Security,
    Utils
  ) {
    var ctrl = this;

    ctrl.reportId = $stateParams.reportId;

    function loadReport(reportId) {
      if (_.isUndefined(reportId)) {
        return $q.reject('Report ' + reportId + 'not found.');
      }

      return Reports.getGroupByItemId(reportId)
        .then(function(data) {
          var report = new Report(data);
          var editPerm = report.location === 'personal' ? 'reports.edit.own' : 'reports.edit';
          return Security.hasPermission(editPerm)
            .then(function(hasEditPerm) {
              ctrl.hasEditPerm = hasEditPerm;
              return report;
            })
            .catch(function(error) {
              if (error.status === 403) {
                $state.go('epf.reportTemplates.index');
              }

              return $q.reject(error);
            });
        })
        .then(function(version) {
          ctrl.baseReport = version;
          ctrl.version = version;
          ctrl.editMode = ctrl.baseReport.doc.state === 'draft' && ctrl.hasEditPerm;
        })
        .catch(Utils.showError);
    }

    ctrl.show = false;
    loadReport($stateParams.reportId)
      .then(function() {
        var report = ctrl.baseReport.getSingleOrMultiReportByDoc();
        if (report.type !== 'multiReport') {
          return $q.reject('The report is not a multi report');
        }

        ctrl.multiReport = report;
        ctrl.elementToEdit = ctrl.multiReport.getElement($stateParams.elementId);
        Utils.setPageTitle('Edit ' + report.reportDoc.title + ' multireport element');
        if (ctrl.elementToEdit.type === 'text' && ctrl.editMode) {
          ctrl.form = new Form(MultiReport.getTextElementFields());
        }
      })
      .catch(Utils.showError);

    function goToBaseReportRoute() {
      $state.go('epf.reportTemplates.edit', { id: $stateParams.reportId });
    }

    ctrl.save = function() {
      if (ctrl.baseReport.doc.state !== 'draft' && ctrl.hasEditPerm) {
        return Utils.showError({ message: 'You cannot perform that action' });
      }

      return Reports.save(ctrl.baseReport.doc, true)
        .then(function(data) {
          ctrl.baseReport.doc._rev = data.rev;
          Notify.success('This report element has been saved', 'Success');
        })
        .then(function() {
          goToBaseReportRoute();
        })
        .catch(Utils.showError);
    };

    ctrl.cancel = function() { goToBaseReportRoute(); };
  }

  MultiReportEditElementController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    'FormsService',
    'ReportFactory',
    'ReportTemplatesService',
    'NotifyService',
    'MultiReportFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.reports')
    .controller('MultiReportEditElementController', MultiReportEditElementController);
})();
