(function() {
  'use strict';

  function ReportWidgetViewController(
    $q,
    $stateParams,
    config,
    Security,
    Report,
    Reports,
    Users,
    VERSION_STATES
  ) {
    var ctrl = this;

    ctrl.config = config;
    ctrl.charts = ctrl.config.charts || [];
    ctrl.username = $stateParams.user;

    var unauthorisedMessage = 'You are not able to view this report. This could be because it ' +
      'has been retired or that you have not been given permission to view it.';

    var hasPerm = Security.hasPermission('reports.view');

    hasPerm
      .then(function() {
        if (_.isUndefined(config.report)) {
          return $q.reject({ message: 'Please select a report' });
        }

        return Reports.getGroupById(config.report);
      })
      .then(function(data) {
        return new Report(data);
      })
      .then(function(report) {
        if (_.isEmpty(report)) {
          return $q.reject({ message: 'The selected report does not exists.' });
        }

        if (report.hasState(VERSION_STATES.archived)) {
          return $q.reject({ message: 'The selected report is no longer available.' });
        }

        var hasRoles;
        if (report.doc.roles && report.doc.roles.indexOf('__all__') !== -1) {
          hasRoles = true;
        } else if (Users.remoteUser) {
          hasRoles = Security.hasRoleFor(Users.remoteUser, report.doc.roles);
        } else {
          hasRoles = Security.hasRole(report.doc.roles);
        }

        return $q.all([report, hasRoles]);
      })
      .then(function(data) {
        var hasRoles = data[1];

        if (!hasRoles) {
          return $q.reject({ message: unauthorisedMessage });
        }

        ctrl.report = data[0];
        return ctrl.config.reportData || ctrl.report.getDefaultModel();
      })
      .then(function(model) {
        ctrl.defaultModel = model;
        ctrl.generated = false;
        ctrl.showResult = true;
      })
      .catch(function(error) {
        if (error.status === 403) {
          ctrl.error = unauthorisedMessage;
          return;
        }

        ctrl.error = error.message;
      });

    ctrl.remoteUser = Users.remoteUser;
  }

  ReportWidgetViewController.$inject = [
    '$q',
    '$stateParams',
    'config',
    'SecurityService',
    'ReportFactory',
    'ReportTemplatesService',
    'UsersService',
    'VERSION_STATES'
  ];

  function ReportWidgetEditController($scope, config, Report, Reports, VERSION_STATES) {
    var ctrl = this;

    ctrl.config = config;
    $scope.config = config;
    $scope.$watchCollection('config.report', function(reportId) {
      if (_.isUndefined(reportId)) {
        return;
      }

      return Reports.getGroupById(reportId)
        .then(function(reportDoc) {
          if (_.isEmpty(reportDoc)) {
            return;
          }

          var report = new Report(reportDoc);

          if (report.hasState(VERSION_STATES.archived)) {
            return;
          }

          ctrl.report = report;
        });
    });
  }

  ReportWidgetEditController.$inject = [
    '$scope',
    'config',
    'ReportFactory',
    'ReportTemplatesService',
    'VERSION_STATES'
  ];

  function config(dashboardProvider) {
    dashboardProvider.widget('ReportWidget', {
      title: 'Report',
      description: 'Displays a report',
      controller: 'ReportWidgetViewController',
      controllerAs: 'viewCtrl',
      templateUrl: 'app/components/reports/widgets/report/view.html',
      reload: true,
      edit: {
        templateUrl: 'app/components/reports/widgets/report/edit.html',
        controller: 'ReportWidgetEditController',
        controllerAs: 'editCtrl'
      },
      category: 'Reports'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.reports')
    .config(config)
    .controller('ReportWidgetViewController', ReportWidgetViewController)
    .controller('ReportWidgetEditController', ReportWidgetEditController);
})();
