(function() {
  'use strict';

  /**
    * How often do we save?
    * The default is 10 seconds.
    * @type {Number}
  */
  var interval = 10000;

  var events = {
    saved: 'KZAutoSave:Save',
    removed: 'KZAutoSave:Delete',
    newAutosave: 'KZAutoSave:NewAutosave',
    unsavedChanges: 'KZAutoSave:UnsavedChanges',
    userSaved: 'KZAutoSave:UserSave',
    saveInfoUpdate: 'KZAutoSave:SaveInfoUpdate'
  };

  angular.module('component.drafts')
    .constant('AUTOSAVE_INTERVAL', interval)
    .constant('AUTOSAVE_EVENTS', events);
})();
