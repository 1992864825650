(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('dashboard', {
        url: '/',
        abstract: true,
        parent: 'layout',
        views: {
          main: {
            templateUrl: 'app/layout/templates/1-column-full.html'
          }
        },
        resolve: {
          appInit: ['SetupService', function(Setup) {
            return Setup.initApp();
          }],
          setup: ['appInit', 'SetupService', function(_appInit, Setup) {
            return Setup.setupApp();
          }]
        }
      })

      .state('dashboard.index', {
        url: 'dashboard',
        views: {
          main: {
            templateUrl: 'app/components/dashboard/view.html',
            controller: 'DashboardController',
            controllerAs: 'templateCtrl'
          }
        }
      })

      .state('dashboard.activity', {
        url: 'activities',
        views: {
          main: {
            templateUrl: 'app/components/todos/activity.html',
            controller: 'ActivityController',
            controllerAs: 'activityCtrl'
          }
        }
      })

      .state('dashboard.summary', {
        url: 'summary/:user',
        views: {
          main: {
            templateUrl: 'app/components/dashboard/view.html',
            controller: 'SummaryController',
            controllerAs: 'templateCtrl'
          }
        },
        data: {
          action: 'view'
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('users.view');
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.dashboard')
    .config(config);
})();
