import $ from 'jquery';

(function() {
  'use strict';

  function jiraDirective($q, Auth, Profile, Organisations) {
    return {
      link: function() {
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        // We only add if it's not an iOS device
        if (!iOS) {
          $q.all([Profile.find(), Organisations.find()])
            .then(function(result) {
              var profile = result[0],
                  organisation = result[1];

              var url = organisation.supportDeskLink;
              if (!url) {
                console.log('No Support desk link');
                return;
              }

              window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {
                triggerFunction: function(showCollectorDialog) {
                  $('#feedback-button').on('click', function(e) {
                    e.preventDefault();
                    showCollectorDialog();
                  });
                },
                // Pre-populate the form fields
                fieldValues: {
                  // Visible form fields -------------------
                  fullname: profile.firstName + ' ' + profile.lastName,
                  email: profile.email,

                  // Hidden form fields ----------------
                  customfield_11600: window.location.href, // Current page URL
                  customfield_13576: Auth.currentOrganisation(), // Instance organisation
                  customfield_13577: Auth.currentUser() // User ID
                }
              });

              $.ajax({ url: url, type: 'get', cache: true, dataType: 'script' });
            });
        }
      }
    };
  }

  jiraDirective.$inject = ['$q', 'AuthService', 'ProfileService', 'OrganisationsService'];

  angular.module('blocks.utils')
    .directive('kzJiraCollector', jiraDirective);
})();
