(function() {
  'use strict';

  function EventCommentDirective(
    $anchorScroll,
    $timeout,
    $location,
    EventTypes,
    BlueprintUtils,
    Form,
    Utils,
    Notify
  ) {
    return {
      scope: {
        event: '=',
        autoExpand: '@?',
        onSave: '&'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-comment.html',
      replace: true,
      link: function($scope) {
        EventTypes.isUploadedEventType($scope.event.doc.eventType)
          .then(function(isUploadedEventType) {
            $scope.isUploadedEventType = isUploadedEventType;
          });

        var initForm = function() {
          $scope.form = new Form();
          $scope.form.addField({ id: 'comment', type: 'text', label: 'Comment', required: false });
          $scope.extra = { comment: '', tags: [] };
          $scope.tagTreeIsVisible = false;

          if ($scope.autoExpand === 'tags') {
            $scope.showTagsTree();
            $timeout(function() {
              var hashId = $location.hash(); // store the current hash
              $location.hash('new-comment-form'); // attach the ID of the form to scroll to
              $anchorScroll.yOffset = 75; // let the user see the Add new comment heading
              $anchorScroll();
              $location.hash(hashId); // restore the old hash
            }, 1000); // It doesn't work without a timeout duration
          }
        };

        $scope.showTagsTree = function() {
          var blueprints = BlueprintUtils.getTaggableBlueprints({
            period: 'until now',
            user: $scope.event.doc.user
          })
            .then(function(blueprints) {
              return {
                id: 'blueprints',
                controller: ['$scope', function($scope) {
                  if (blueprints.length === 0) {
                    $scope.tree = [];
                  } else if (blueprints.length === 1) {
                    $scope.tree = blueprints[0].categories;
                  } else {
                    $scope.tree = blueprints;
                  }
                  $scope.selected = [];
                  $scope.kzTree = '';
                  $scope.treeOptions = {
                    selectable: true,
                    searchable: true,
                    icons: {
                      arrowRight: 'icon-right-open-big',
                      arrowDown: 'icon-down-open-big',
                      empty: 'icon-minus'
                    }
                  };
                }],
                type: 'tree',
                label: 'Tags',
                required: false,
                ngModelAttrs: {
                  kzTree: {
                    bound: 'kzTree',
                    attribute: 'kz-tree'
                  },
                  selected: {
                    bound: 'selected',
                    attribute: 'selected'
                  },
                  nodes: {
                    bound: 'nodes',
                    attribute: 'nodes'
                  },
                  options: {
                    bound: 'treeOptions',
                    attribute: 'options'
                  }
                },
                ngModelAttrsValues: [
                  {
                    name: 'kzTree',
                    value: 'kzTree'
                  },
                  {
                    name: 'selected',
                    value: 'selected'
                  },
                  {
                    name: 'nodes',
                    value: 'tree'
                  },
                  {
                    name: 'options',
                    value: 'treeOptions'
                  }
                ]
              };
            });

          $scope.form.addField(blueprints);
          $scope.tagTreeIsVisible = true;
        };

        initForm();

        var submit = function(form) {
          var privateExtra = angular.copy($scope.extra);
          var newExtra = angular.copy(privateExtra);
          delete newExtra._rev; // remove rev from auto-save

          return $scope.event.addExtra(newExtra)
            .then(function(data) {
              form.$setPristine();
              form.$setUntouched();

              $scope.onSave({ newExtra: data });

              initForm();
            });
        };

        // var intervalSave = 10000; // 10s
        // savePeriodically = $interval(function() {
        //   if (holdAutoSave) {
        //     console.log('Auto-save: Waiting for user action');
        //     return;
        //   }

        //   if (angular.equals($scope.oldExtra, $scope.extra)) {
        //     console.log('Auto-save: form not changed');
        //     return;
        //   }

        //   $scope.isSaving = true;
        //   saveDraft(true);
        // }, intervalSave);

        $scope.save = function(form) {
          return submit(form)
            .then(function() {
              // clean the doc before publishing it
              var msg = 'Successfully updated!';
              Notify.success('Adding a comment', msg);
            })
            .catch(Utils.showError);
        };

        $scope.delete = function(extra, dbType, showDialog) {
          function _delete() {
            return $scope.event.deleteExtra(extra, dbType)
              .then(function() {
                $scope.extra = {};
              })
              .catch(function(error) {
                if (error.reason) {
                  error.message = error.reason;
                }

                Utils.showError(error);
              });
          }

          if (showDialog) {
            Utils.swal(
              {
                title: 'Are you sure you want to remove this comment?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'OK'
              },
              function(isConfirm) {
                if (isConfirm) {
                  return _delete();
                }
              });
          } else {
            return _delete();
          }
        };
      }
    };
  }

  EventCommentDirective.$inject = [
    '$anchorScroll',
    '$timeout',
    '$location',
    'EventTypesService',
    'BlueprintUtils',
    'FormsService',
    'UtilsService',
    'NotifyService'
  ];

  angular.module('events.directives')
    .directive('eventComment', EventCommentDirective);
})();
