(function() {
  'use strict';

  function EmailViewController($element) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.view();
    };

    ctrl.view = function() {
      ctrl.showHtml(ctrl.data.html);
      ctrl.subject = ctrl.data.subject;
      ctrl.email_from = ctrl.data.email_from;
      ctrl.recipients = ctrl.data.recipients;
      ctrl.messageId = ctrl.data.messageId;
      ctrl.state_log = ctrl.data.state_log;
      ctrl.date = ctrl.data.date;
    };

    ctrl.showHtml = function(html) {
      var blob = new Blob([html], { type: 'text/html' });
      if (ctrl.href) {
        window.URL.revokeObjectURL(ctrl.href);
      }

      ctrl.href = window.URL.createObjectURL(blob);
      var iframe = $element.find('iframe')[0];
      iframe.src = ctrl.href;
    };

    ctrl.$onDestroy = function() {
      if (ctrl.href) {
        window.URL.revokeObjectURL(ctrl.href);
      }
    };
  }

  EmailViewController.$inject = [
    '$element'
  ];

  angular.module('component.emailTemplates')
    .component('emailView', {
      templateUrl: 'app/components/email-templates/email-view.html',
      controller: EmailViewController,
      bindings: {
        data: '<',
        onClose: '&'
      }
    });
})();
