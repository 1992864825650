(function() {
  'use strict';

  function Slot($log, $rootScope, Utils, Form, Events, Profile) {
    return {
      restrict: 'A',
      templateUrl: 'app/layout/sidebar/cards/bookmark.card.html',
      link: function(scope) {
        var setupPrefs = function(listName) {
          if (_.isEmpty(listName)) {
            scope.hidden = true;
            $log.debug('Setup bookmarks', 'hidig');
            return;
          }

          scope.hidden = false;
          var prefs = Profile.getListPreferences(listName);
          scope.searchModel = prefs.current;
          scope.bookmarks = prefs.bookmarks = prefs.bookmarks || [];
          $log.debug('Setup bookmarks', listName);
        };

        setupPrefs(Events.currentList);

        scope.$watch(function() {
          return Events.currentList;
        }, function(listName) {
          setupPrefs(listName);
        });

        scope.data = {};

        scope.addform = new Form([
           { id: 'title', type: 'string', placeholder: 'Bookmark current search', required: true }
        ]);

        scope.add = function(form) {
          if (_.isEmpty(scope.data.title)) {
            return;
          }

          var data = {
            id: Utils.guid(),
            title: scope.data.title,
            filter: angular.copy(scope.searchModel.filteredBy)
          };

          scope.bookmarks.push(data);
          scope.data = {};

          form.$setPristine();
          form.$setUntouched();

          $rootScope.$broadcast('KZPreferencesChange');
        };

        scope.remove = function(item) {
          _.remove(scope.bookmarks, item);
          $rootScope.$broadcast('KZPreferencesChange');
        };

        scope.use = function(item) {
          scope.searchModel.filteredBy = angular.copy(item.filter);
          $rootScope.$broadcast('KZApplySearch');
        };
      }
    };
  }

  Slot.$inject = ['$log', '$rootScope', 'UtilsService', 'FormsService', 'EventsService',
    'ProfileService'];

  angular.module('layout.sidebar')
    .directive('sidebarBookmark', Slot);
})();
