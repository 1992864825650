(function() {
  'use strict';

  function EventFormController($scope, Auth, Form) {
    var ctrl = this;

    function buildBasicForm() {
      var form = ctrl.form;
      form.clearFields();
      if ($scope.model.quickDraft) {
        form.addField({ id: 'description', type: 'text', label: 'Notes', required: true });
        return form;
      }

      // form.addField({id: 'title', type: 'string', label: 'Event title', required: true});

      form.addField({
        id: 'startDate',
        type: 'date',
        label: 'Date occurred on',
        required: true,
        helpText: 'Please indicate the date on which this event occurred.',
        className: 'col-sm-3'
      });

      form.addField({
        id: 'endDate',
        type: 'date',
        label: 'End date',
        required: true,
        helpText: 'If this event spans multiple days, please indicate ' +
        ' the date on which this finishes.',
        className: 'col-sm-3'
      });

      if (!$scope.readOnly || !_.isEmpty($scope.model.description)) {
        form.addField({
          id: 'event-description',
          key: 'description',
          type: 'text',
          label: 'Description (optional)',
          required: false,
          readOnly: $scope.readOnly
        });
      }

      return form;
    }

    ctrl.isMine = $scope.user === Auth.currentUser();
    ctrl.form = new Form();
    ctrl.options = { formState: { readOnly: $scope.readOnly } };
    ctrl.eventType = $scope.eventType;

    // if (!$scope.readOnly) {
    //   if (ctrl.event.completedSections.length > 0) {
    //     $scope.event.canEditBasicForm()
    //       .then(function() {
    //         ctrl.options.formState.readOnly = false;
    //       })
    //       .catch(function() {
    //         ctrl.options.formState.readOnly = true;
    //       });
    //   } else {
    //     ctrl.options.formState.readOnly = false;
    //   }
    // }

    $scope.$watch('model.quickDraft', function() {
      buildBasicForm();
    });

    ctrl.canChangeVisibility = function() {
      return !ctrl.eventType
        ? false
        : ctrl.isMine && ctrl.eventType.changableVisibility;
    };

    ctrl.updateVisibility = function() {
      var cur = $scope.model.visibility || $scope.event.eventType.defaultVisibility || 'public';
      $scope.model.visibility = cur === 'public' ? 'private' : 'public';
    };
  }

  EventFormController.$inject = [
    '$scope',
    'AuthService',
    'FormsService'
  ];

  function EventBasicFormDirective() {
    return {
      scope: {
        model: '=',
        user: '=',
        sectionData: '=',
        eventType: '=',
        disallowFull: '@',
        readOnly: '=',
        form: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-basic-form.html',
      replace: true,
      controller: EventFormController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventBasicForm', EventBasicFormDirective)
    .controller('EventFormController', EventFormController);
})();
