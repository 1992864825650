(function() {
  'use strict';

  function RolesService($q, $log, Cache, EasApiStore,
    DEFAULT_PERMISSIONS, SYSTEM_ROLES) {
    var cacheKeys = [
      'role-load-permissions'
    ];
    var service = new EasApiStore('roles', { cacheKeys: cacheKeys, usePersistentCache: true });

    service.preCache = function() {
      return $q.all([
        service.findAll(),
        service.loadWithPermissions({ cached: true })
      ]);
    };

    service.findAll = function(data, _urlOptions, options) {
      var opts = {};
      if (options) {
        _.assign(opts, options);
      }

      if (opts.cached === undefined) {
        opts.cached = true;
      }

      var includeSystemRoles = data !== undefined ? data.includeSystemRoles : false;

      return EasApiStore.prototype.findAll.call(this, opts)
        .then(function(data) {
          var docData = _.map(data, function(itm) {
            return { doc: itm };
          });
          return docData;
        })
        .then(function(data) {
          if (!includeSystemRoles) {
            return data;
          }

          return _(data).concat(service.systemRoles()).value();
        });
    };

    service.systemRoles = function() {
      return SYSTEM_ROLES;
    };

    service.findAllDependent = function() {
      return this.findAll()
        .then(function(data) {
          return _.filter(data, function(item) {
            return item.doc.isUserDependent;
          });
        });
    };

    service.findWithPermission = function(permission) {
      return this.loadWithPermissions()
        .then(function(data) {
          return data[permission] || [];
        })
        .then(function(roles) {
          if (DEFAULT_PERMISSIONS[permission] !== undefined) {
            roles = roles.concat(DEFAULT_PERMISSIONS[permission]);
          }

          return roles;
        })
        .catch(function(error) {
          if (error && 'status' in error && error.status === 0) {
            $log.warn('Roles Service: No response from server');
            return $q.reject(
              { status: 0, message: 'Cannot reach the server. Your connection is down.' }
            );
          }

          return $q.reject(error);
        });
    };

    service.loadWithPermissions = function() {
      var _this = this;
      var func = function() {
        return _this.findAll()
          .then(function(roles) {
            $log.debug('Loading permissions');
            var permissions = {};
            _.forEach(roles, function(role) {
              _.forEach(role.doc.permissions || [], function(perm) {
                if (!_.has(permissions, perm)) {
                  permissions[perm] = [];
                }

                permissions[perm].push(role.doc._id);
              });
            });
            return permissions;
          });
      };

      // There is no need to cache really hard - this function is actually quite fast
      // only called too often
      var ext = {
        key: 'role-load-permissions',
        maxAge: 5 * 60 * 1000,
        cached: true
      };

      return Cache.cachedPromise(func, ext);
    };

    service.find = function(id, kzOpts) {
      var systemRoles = this.systemRoles().filter(function(role) {
        return role.id === id;
      });

      if (systemRoles.length > 0) {
        return $q.when(systemRoles[0].doc);
      }

      var opts = {};
      if (kzOpts) {
        _.assign(opts, kzOpts);
      }

      if (opts.cached === undefined) {
        opts.cached = true;
      }

      return EasApiStore.prototype.find.call(this, id, opts);
    };

    service.titleFor = function(id) {
      return this.find(id)
        .then(function(doc) {
          return doc.title || '';
        });
    };

    return service;
  }

  RolesService.$inject = [
    '$q',
    '$log',
    'CacheService',
    'EasApiStore',
    'DEFAULT_PERMISSIONS',
    'SYSTEM_ROLES'
  ];

  angular.module('component.roles')
    .factory('RolesService', RolesService);
})();
