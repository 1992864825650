(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.organisations', {
        url: 'organisations',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          forceOnline: ['setup', 'NetworkService', function(_setup, NetworkService) {
            return NetworkService.forceOnline();
          }],
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasRole('system:superadmin', { promise: true });
          }]
        }
      })

      .state('epf.organisations.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/organisations/list.html',
            controller: 'OrganisationsController',
            controllerAs: 'orgsCtrl'
          }
        }
      })

      .state('epf.organisations.new', {
        url: '/new',
        views: {
          content: {
            templateUrl: 'app/components/organisations/addform.html',
            controller: 'OrganisationController',
            controllerAs: 'orgCtrl'
          }
        }
      })

      .state('epf.organisations.settings', {
        url: '/settings/:orgId',
        views: {
          content: {
            templateUrl: 'app/components/organisations/settings.html',
            controller: 'OrganisationSettingsController',
            controllerAs: 'organisationSettingsCtrl'
          }
        }
      })

      .state('epf.organisations.control-panel', {
        url: '/control',
        views: {
          content: {
            component: 'organisationControlPanel'
          }
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.organisations')
    .config(config);
})();
