(function() {
  'use strict';

  function BasicFormController(Form) {
    var ctrl = this;

    var buildForm = function() {
      var form = new Form();

      form.addField({
        id: 'title',
        type: 'string',
        label: 'Announcement title',
        required: true
      });

      form.addField({
        id: 'publishDate',
        type: 'date',
        label: 'Publish date',
        className: 'col-sm-4',
        required: true
      });

      form.addField({
        id: 'expireDate',
        type: 'date',
        label: 'Expiration date',
        className: 'col-sm-4'
      });

      form.addField({
        id: 'isDismissable',
        type: 'boolean',
        label: 'Allow the user to dismiss the announcement manually',
        className: 'col-sm-4'
      });

      form.addField({
        id: 'content',
        type: 'wysiwyg',
        label: 'Announcement content'
      });

      return form;
    };

    ctrl.form = buildForm();
  }

  function BasicFormDirective() {
    return {
      scope: {
        announcement: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/announcements/directives/basic-form.html',
      controller: BasicFormController,
      controllerAs: 'ctrl'
    };
  }

  BasicFormController.$inject = [
    'FormsService'
  ];

  angular.module('component.announcements')
    .directive('announcementBasicForm', BasicFormDirective);
})();
