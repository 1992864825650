(function() {
  'use strict';

  function EventPreviewController(
    $q,
    $scope,
    $stateParams,
    $uibModalInstance,
    Auth,
    EventSectionEdit,
    EventSection,
    EventSections,
    Utils,
    Notify,
    event,
    options,
    LocalizationService
  ) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    ctrl.modal = $uibModalInstance;
    ctrl.eventOwner = $stateParams.user || Auth.currentUser();

    options = options || {};
    ctrl.parent = options.parent;
    ctrl.dryrun = options.dryrun;

    function setupActions(extra) {
      ctrl.actions = options.actions || [];
      ctrl.secondaryActions = _([]).concat(options.secondaryActions || [], extra || []).value();
      _.forEach([ctrl.actions, ctrl.secondaryActions], function(actions) {
        _.forEach(actions, function(action) {
          if (_.isFunction(action.onClick)) {
            var old = action.onClick;
            action.onClick = function() {
              return $q.when(old())
                .then(function(res) {
                  if (action.dismiss) {
                    $uibModalInstance.dismiss(res);
                  } else {
                    $uibModalInstance.close(res);
                  }
                });
            };
          }

          if (action.href) {
            action.mouseUp = function() {
              ctrl.modal.close();
            };
          }
        });
      });
    }

    ctrl.username = $stateParams.user || Auth.currentUser();
    ctrl.event = event;

    ctrl.displayView = 'default';
    if (ctrl.event.isComplete()) {
      ctrl.displayView = ctrl.event.eventType.defaultView || 'default';
      if (ctrl.displayView !== 'default') {
        ctrl.availableViews = [
          { id: ctrl.displayView, label: 'Report view' }
        ];

        ctrl.event.checkPermission('canEditAny')
          .then(function() {
            ctrl.availableViews.push({ id: 'default', label: 'Full event view' });
          });
      } else {
        ctrl.availableViews = [
          { id: 'default', label: 'Full event view' }
        ];
      }
    }

    /*
      Try to find a draft so that we can suggest relevant options
     */
    var loadSections = function() {
      if (ctrl.event.doc.state === 'complete' || ctrl.event.isMine()) {
        return $q.when([]);
      }

      return EventSections.findAllByEvent(ctrl.event.doc._id, 'draft')
        .then(function(sections) {
          if (sections.length > 0) {
            var eventSection = new EventSection(sections[0].doc);
            return eventSection.checkPermission('canFillIn')
              .then(function() {
                return eventSection;
              });
          }

          return $q.reject({ status: 404, message: 'not found' });
        })
        .then(function(eventSection) {
          return EventSectionEdit.init('edit', {
            eventSection: eventSection
          }, { noUpdateLocation: false });
        })
        .then(function(options) {
          ctrl.eventSection = EventSectionEdit.eventSection;
          ctrl.eventExtras = EventSectionEdit.eventExtras;
          ctrl.options = options;
          ctrl.canFillIn = true;
          var extraActions = _.filter(ctrl.eventSection.actionButtons, { position: 1 });
          setupActions(extraActions);
          ctrl.setupNextSectionWatch();
        })
        .catch(function(err) {
          setupActions();
          console.log('Could not load event section', err);
        });
    };

    loadSections();

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    ctrl.open = function() {
      var url = event.href;
      window.open(url, '_blank');
    };

    // duplicates
    ctrl.mode = 'read';
    ctrl.switchMode = function(mode) {
      ctrl.mode = mode;
    };

    function confirmationDialogToStartGoals() {
      return Utils.confirm({
        title: 'This will create and start the goals which you have just defined. ' +
          'Would you like to proceed?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Proceed'
      });
    }

    ctrl.save = function(form, action /* either publish or save */) {
      ctrl.loading = true;

      // if the action is draft, set a default title
      var eventSection = ctrl.eventSection;
      var promise = $q.when();

      // We require the form to be valid if it is not a draft
      if (action === 'publish') {
        ctrl.submitted = true;
        form.$setSubmitted();
        ctrl.formIsSubmitted = true;
        if (form.$invalid) {
          Utils.swal({
            title: 'Please ensure all mandatory fields are completed and all errors corrected.',
            type: 'error'
          }, function() {
            ctrl.submitted = false;
          });
          return;
        }
        if (eventSection.containsGoal) {
          promise = promise.then(function() {
            return confirmationDialogToStartGoals();
          });
        }
      }

      promise
        .then(function() {
          return EventSectionEdit.save(action);
        })
        .then(function(statusMessage) {
          Notify.success(statusMessage, 'Success');
          ctrl.loading = false;
          ctrl.justSaved = true;
          ctrl.close();
        })
        .catch(function(error) {
          ctrl.submitted = false;
          if (error && error.status === 510) {
            ctrl.submitted = false;
            return;
          }

          Utils.swal({
            title: (error && error.message) || 'Unknown error',
            type: 'error'
          });
        });
    };

    ctrl.justSaved = false;
    $scope.$on('modal.closing', function() {
      if (ctrl.mode === 'fillin' && !ctrl.justSaved) {
        ctrl.save(ctrl.form, 'save');
      }
    });

    ctrl.setupNextSectionWatch = function() {
      var oldSection;
      $scope.$watch(function() {
        return ctrl.eventSection.doc;
      }, function() {
        var nextSection = ctrl.eventSection.getNextSection();
        if (nextSection !== oldSection) {
          oldSection = nextSection;
          if (nextSection) {
            ctrl.eventSection.nextDefSection = ctrl.eventSection.getDefSection(nextSection);
          } else {
            delete ctrl.eventSection.nextDefSection;
          }
          ctrl.showNextSection = EventSectionEdit.showNextSection();
          console.log('Next section is', nextSection);
          $scope.$broadcast('KZNextSectionChanged');
        }
      }, true);
    };
  }

  EventPreviewController.$inject = [
    '$q',
    '$scope',
    '$stateParams',
    '$uibModalInstance',
    'AuthService',
    'EventSectionEditService',
    'EventSectionFactory',
    'EventSectionsService',
    'UtilsService',
    'NotifyService',
    'event',
    'options',
    'LocalizationService'
  ];

  angular.module('component.events')
    .controller('EventPreviewController', EventPreviewController);
})();
