(function() {
  'use strict';

  function AsyncTasksSearch(
    $q,
    $filter,
    moment,
    Cache
  ) {
    var PLACEHOLDER = 'Start typing to search...';

    var getAsyncTasksSearch = function(options) {
      // merge all defaultFilters
      var defaultFilter = {};
      options = _.isObject(options) ? options : {};
      defaultFilter = _.assignIn({}, defaultFilter || {}, (options.defaultFilter || {}).filter);

      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              label: 'Start typing to search',
              facetly: true,
              advanced: false
            },
            {
              id: 'action',
              type: 'select',
              label: 'Action',
              multiselect: false,
              options: [
                { id: 'import_file', title: 'CSV import' }
              ],
              facetly: false,
              advanced: false
            },
            {
              id: 'state',
              type: 'select',
              label: 'State',
              multiselect: true,
              options: [
                { id: 'new', title: 'New' },
                { id: 'processing', title: 'Processing' },
                { id: 'success', title: 'Success' },
                { id: 'failure', title: 'Failure' },
                { id: 'aborted', title: 'Aborted' }
              ],
              facetly: false,
              advanced: true
            },
            {
              id: 'createdDate_start',
              type: 'date',
              label: 'Created date from',
              facetly: false,
              advanced: true
            },
            {
              id: 'createdDate_end',
              type: 'date',
              label: 'Created date to',
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'name',
            placeholder: PLACEHOLDER,
            listMaxItems: 200
          }
        },
        filters: [
          {
            id: 'action',
            matchFunc: function(item, _key, value) {
              var celeryTask = item.main || item;
              return value.indexOf(celeryTask.doc.action) === -1;
            }
          },
          {
            id: 'state',
            matchFunc: function(item, _key, value) {
              var celeryTask = item.main || item;
              return value.indexOf(celeryTask.doc.state) === -1;
            }
          }
        ],
        orders: {
          addedDate: function(item) {
            var celeryTask = item.main || item;
            var date = celeryTask.doc.createdDate;
            if (date) {
              return -(moment(date).toDate().getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          }
        },
        orderGroups: {
          addedDate: {
            title: 'date added',
            orders: ['addedDate'],
            groupBy: {
              title: 'Task created in',
              notitle: 'Task without a date',
              getter: function(item) {
                var celeryTask = item.main || item;
                var date = celeryTask.createdDate;
                if (date) {
                  return $filter('date')(date, 'MMMM y');
                }

                return;
              }
            }
          }
        },
        defaultFilter: defaultFilter,
        defaultOrder: 'addedDate'
      };

      search.ready = false;
      return $q.when(search);
    };

    var searches = { search: getAsyncTasksSearch };

    var getSearch = function(searchType) {
      var func = searches[searchType];
      if (func === undefined) {
        return $q.reject({ status: 500, message: 'An unknown search type: ' + searchType });
      }

      var ext = _.assignIn({
        key: 'search-async-' + searchType,
        maxAge: 60 * 60 * 1000,
        cached: true
      });
      return Cache.cachedPromise(func, ext);
    };

    var service = { getSearch: getSearch };

    return service;
  }

  AsyncTasksSearch.$inject = [
    '$q',
    '$filter',
    'moment',
    'CacheService'
  ];

  angular.module('blocks.asyncTasks')
    .service('AsyncTasksSearch', AsyncTasksSearch);
})();
