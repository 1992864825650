(function() {
  'use strict';

  function DashboardTemplatesUtils(
    Profile,
    Utils,
    TemplatesUtils,
    DASHBOARD_PERMISSIONS,
    DEFAULT_DASHBOARD_TEMPLATE_LAYOUT,
    DASHBOARD_PARTIALS_PATHS
  ) {
    var service = {};

    /**
     * The search object required by facetly.
     * @type {Object}
     */
    service.search = TemplatesUtils.getSearch('Dashboard template');

    /**
     * The search model required by facetly.
     * @type {Object}
     */
    service.searchModel = Profile.getListPreferences('dashboardTemplates').current;

    /**
     * Based on the remove permission, figure out if the default dashboard
     * can be removed.   This will only return true for system:admin.
     * @return {Boolean} True if the default dashboard can be removed, false otherwise.
     */
    service.canRemoveDefaultDashboardTemplate = function() {
      return TemplatesUtils.canRemoveDefaultTemplate(DASHBOARD_PERMISSIONS.canRemoveDefault);
    };

    /**
     * Build the default document structure for a dashboard template.
     * @param  {String} organisation The organisation for which to build the template.
     * @return {Object}              The default structure, as required by CouchDB.
     */
    service.getDocDefault = function(organisation) {
      return {
        type: 'dashboardTemplate',
        _id: Utils.guid(),
        createdDate: Utils.now({ dateOnly: true }),
        organisation: organisation,
        showTitle: true,
        template: angular.copy(DEFAULT_DASHBOARD_TEMPLATE_LAYOUT)
      };
    };

    /**
     * Modify the template to contain the audit log dates.
     * This is just a symlink to the TemplatesUtils method with the same name.
     * @return {String}          The border class.
     */
    service.saveAuditLog = TemplatesUtils.saveAuditLog;

    /**
     * Get the border class for a given dashboard template.
     * This is just a symlink to the TemplatesUtils method with the same name.
     * @return {String}          The border class.
     */
    service.getBorderClass = TemplatesUtils.getBorderClass;

    /**
     * See if there already is a default summary template.
     * This is just a symlink to the TemplatesUtils method with the same name.
     * @return {String}          The border class.
     */
    service.noDefaultTemplate = TemplatesUtils.noDefaultTemplate;

    /**
     * Extend the dashboard template with the titleTemplatetUrl.
     * @param  {Object} data         The template to extend.
     * @param  {String} organisation The organisation to which the template will belong to.
     * @return {Object}              The new template object.
     */
    service.extendTemplate = function(data, organisation) {
      var doc = _.assign(
        {},
        service.getDocDefault(organisation),
        data || {}
      );

      doc.template.titleTemplateUrl = DASHBOARD_PARTIALS_PATHS.titleTemplateUrl;
      doc.template.editTemplateUrl = DASHBOARD_PARTIALS_PATHS.editTemplateUrl;
      doc.template.addTemplateUrl = DASHBOARD_PARTIALS_PATHS.addTemplateUrl;
      doc.template.options = {
        hideTitle: !_.isUndefined(doc.showTitle) ? !doc.showTitle : false,
        disableEditModeToggle: true
      };

      // This needs to be a copy otherwise it gets easily overwritten
      // See KZ-2395
      return angular.copy(doc);
    };

    return service;
  }

  DashboardTemplatesUtils.$inject = [
    'ProfileService',
    'UtilsService',
    'TemplatesUtilsService',
    'DASHBOARD_PERMISSIONS',
    'DEFAULT_DASHBOARD_TEMPLATE_LAYOUT',
    'DASHBOARD_PARTIALS_PATHS'
  ];

  angular.module('component.dashboardTemplates')
    .service('DashboardTemplatesUtilsService', DashboardTemplatesUtils);
})();
