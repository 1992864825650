(function() {
  'use strict';

  function ProfileService(
    $q,
    $log,
    BaseUserStore,
    Cache,
    Auth,
    Relations,
    RestApi,
    LocalUsers,
    kzLocalStorage,
    Organisations
  ) {
    var service = {};

    service.preCache = function() {
      return service.find({ cached: true })
        .then(function() {
          return service.getRelationsPaths({ cached: true });
        });
    };

    service.find = function(kzOpts) {
      var opts = _.assignIn({}, { maxAge: 60000 }, kzOpts || {});
      if (opts.noCache) {
        opts.cached = false;
      }
      var api = new RestApi('users');
      // return BaseUserStore.getOneOf('user', opts)
      return api.find(Auth.currentUser(), undefined, opts)
        .then(function(doc) {
          // Save for offline later
          service.storeUser(doc);
          BaseUserStore.storeItems('user', [doc])
            .then(function() {
              console.log('Stored current user');
            })
            .catch(function() {
              console.log('Could not store current user');
            });
          return doc;
        })
        .catch(function(err) {
          if (err && err.status === 509) {
            return BaseUserStore.getOneOf('user', opts);
          }
          $log.warn('Could not load user profile', err);
          return $q.reject(err);
        })
        .then(function(res) {
          return res;
        });
    };

    service.findOrganisation = function(kzOpts) {
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: 'profile-current-organisation',
        maxAge: 0,
        cached: true
      }, kzOpts);

      var func = function() {
        return Organisations.find()
          .then(function(org) {
            if (!org.allowedProducts) {
              return $q.reject({ status: 403, message: 'Organisation not allowed' });
            }
            if (org.allowedProducts.indexOf('kaizen') === -1) {
              return $q.reject({ status: 403, message: 'Organisation not allowed' });
            }
            return org;
          });
      };
      return Cache.cachedPromise(func, ext);
    };

    service.storeUser = function(doc) {
      var func = function() {
        return Auth.getStorageMode()
          .then(function(mode) {
            $log.info('Persistent mode', mode);
            if (mode === 'persistent') {
              return LocalUsers.storeUser(doc);
            }
          })
          .catch(function(err) {
            $log.warn('Could not store local user', err);
          });
      };

      // Just store the user max once a minute
      return Cache.cachedPromise(func, {
        key: 'storing-user-' + doc._id,
        maxAge: 60000,
        cached: true
      });
    };

    service.getRoles = function(kzOpts) {
      return service.find(kzOpts)
        .then(function(doc) {
          if (doc.state === 'disabled') {
            return [];
          }

          var roles = ['system:timeline-owner', 'system:anyone'].concat(doc.roles || []);
          if (doc.isSuperUser) {
            roles.push('system:superadmin');
            roles.push('system:admin');
          }

          if (doc.isOrgSuperUser) {
            roles.push('system:admin');
          }

          if (doc.state === 'waiting_for_approval') {
            roles.push('system:pending-user');
          }

          return roles;
        });
    };

    service.userCache = function() {
      return BaseUserStore.getOneOf('userCache', { maxAge: 60000 })
        .catch(function(err) {
          $log.warn('Could not load user cache', err);
        });
    };

    service.getRelations = function(options) {
      var opts = options || {};
      return service.userCache(opts)
        .then(function(cache) {
          if (cache === undefined) {
            // Raven.captureMessage('User cache is undefined');
          }
          cache = cache || {};
          var profile = cache.profile || {};
          return profile.relations || {};
        });
    };

    service.getParentRelations = function(options) {
      var opts = options || {};
      return service.getRelations(opts)
        .then(function(relations) {
          relations = _.flattenDeep(_.values(relations));
          return $q.all(_.map(relations, function(categoryId) {
            return Relations.findCategoryPath(categoryId)
              .catch(function(error) {
                // We do not want to fail here
                $log.warn(error);
              });
          }));
        })
        .then(function(res) {
          res = _.filter(res, function(item) {
            return item !== undefined;
          });
          var list = _.flattenDeep(res);
          return _.map(list, 'key');
        });
    };

    service.getRelationsPaths = function(options) {
      options = options || {};
      var cacheKey = 'profile-service-get-relations-paths';
      if (options.cached) {
        var relationsPaths = Cache.get(cacheKey, options);
        if (relationsPaths) {
          return $q.when(relationsPaths);
        }
      }

      return service.getRelations(options)
        .then(function(relations) {
          var promises = [];
          _.forOwn(relations, function(value) {
            value.forEach(function(item) {
              promises.push(Relations.findChildCategories(item)
                .catch(function(error) {
                  console.log(item, error);
                }));
            });
          });
          return $q.all(promises);
        })
        .then(function(data) {
          var res = {};
          data.forEach(function(item) {
            res[item.id] = item.value;
          });
          Cache.put(cacheKey, res, options);
          return res;
        })
        .catch(function(error) {
          console.log(error);
        });
    };

    service.username = function() {
      return Auth.currentUser();
    };

    service.organisation = function() {
      return Auth.currentOrganisation();
    };

    service.getTCNotification = function(user) {
      var cacheId = 'tc_' + user.username + '_' + user.organisation;

      if (_.isEmpty(kzLocalStorage.getItem(cacheId))) {
        return Organisations.find(service.organisation())
          .then(function(data) {
            return data.terms;
          });
      }

      return $q.when(false);
    };

    service.agreeToTC = function(user) {
      kzLocalStorage.setItem('tc_' + user.username + '_' + user.organisation, { agree: true });
    };

    /* Preferences have this structure
     * {
     *   lists: {
     *     blueprints: {
     *       current: {
     *         orderBy: '',
     *         filteredBy: {}
     *       },
     *       latestFiltersBy: [
     *         {filterBy: {}}, {filterBy: {}}
     *       ],
     *       bookmarks: [
     *         {
     *           id: 'xxx',
     *           'title': 'xxx',
     *           'filterBy': {}
     *          }
     *       ]
     *     }
     *   },
     *   bulkTagging: {
     *     selected: [],
     *     lastPath: [],
     *   },
     *   cards: {
     *     org_fry_cardId: {
     *       hidden: true/false,
     *       collapsed: true/false,
     *       sticky: true/false
     *     }
     *   },
     *   dashboards: {
     *     lastUsed: '<id>'
     *   },
     *   profiles: {
     *     lastUsed: '<id>'
     *   }
     * }
     */

    service.getPreferences = function() {
      if (service.preferences === undefined || service.forOrg !== service.organisation()) {
        var cacheId = 'kz_pref_' + service.username() + '_' + service.organisation();
        service.preferences = kzLocalStorage.getItem(cacheId) || {};
        service.forOrg = service.organisation();
      }

      return service.preferences;
    };

    service.savePreferences = function(pref) {
      if (pref !== undefined) {
        service.preferences = pref;
      }

      service.preferences = service.preferences || {};

      var cacheId = 'kz_pref_' + service.username() + '_' + service.organisation();
      kzLocalStorage.setItem(cacheId, service.preferences);
    };

    service.getListPreferences = function(listId) {
      service.preferences = service.getPreferences();
      service.preferences.lists = service.preferences.lists || {};
      service.preferences.lists[listId] = service.preferences.lists[listId] || {
        current: { filteredBy: {} }
      };
      return service.preferences.lists[listId];
    };

    return service;
  }

  ProfileService.$inject = [
    '$q',
    '$log',
    'BaseUserStoreService',
    'CacheService',
    'AuthService',
    'RelationsService',
    'RestApi',
    'LocalUsersService',
    'kzLocalStorage',
    'OrganisationsService'
  ];

  angular.module('component.users')
    .factory('ProfileService', ProfileService);
})();
