(function() {
  'use strict';

  // This is a filter used in ng-repeat to exclude or empty fields
  // This works with Strings as all others can be done with normal filter
  // If anybody finds a way how to do this using built-in filter, lets remove this
  function filter() {
    return function(array) {
      return _.filter(array, function(item) {
        return !_.isEmpty(item);
      });
    };
  }

  angular.module('blocks.utils')
    .filter('kzFilterEmpty', filter);
})();
