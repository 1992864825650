(function() {
  'use strict';

  function ColumnsMappingController($scope) {
    var ctrl = this;

    ctrl.possibleMatch = [];

    function autoMapField(kzFieldName) {
      var matchingKzField = _.find(ctrl.kzFields, function(kzField) {
        return kzField.name === kzFieldName;
      });

      if (!_.isUndefined(matchingKzField)) {
        $scope.mapping[matchingKzField._id] = kzFieldName;
        if (_.indexOf(ctrl.possibleMatch, kzFieldName) === -1) {
          ctrl.possibleMatch.push(kzFieldName);
        }
      }
    }

    function countMappedFields() {
      ctrl.autoMapNumber = _.keys($scope.mapping).length;
      ctrl.totalColumnsInFile = ctrl.fileColumns.length;
    }

    ctrl.autoMapField = function(kzFieldName) {
      autoMapField(kzFieldName);
    };

    ctrl.autoMapAll = function() {
      _.forEach(ctrl.fileColumns, function(kzFieldName) {
        autoMapField(kzFieldName);
      });

      countMappedFields();
    };

    ctrl.unMap = function(kzFieldId) {
      delete $scope.mapping[kzFieldId];
    };

    ctrl.unMapAll = function() {
      $scope.mapping = {};
    };

    function getSelectedEntryUniqueness(selectedFields) {
      return _.filter(
        _.keys(selectedFields),
        function(fieldId) {
          return selectedFields[fieldId] === true;
        });
    }

    ctrl.disableEntryUniquenessSelection = function(kzFieldId) {
      if (ctrl.entryUniquenessKzFields.fields.indexOf(kzFieldId) === -1) {
        return true;
      }

      if (!$scope.mapping[kzFieldId]) {
        return true;
      }

      if (ctrl.entryUniquenessKzFields.select === 'single') {
        var selectedCheckboxes = getSelectedEntryUniqueness($scope.entryUniquenessSelectedFields);
        return selectedCheckboxes.length >= 1 && selectedCheckboxes[0] !== kzFieldId;
      }
    };

    this.$onInit = function() {
      $scope.mapping = {}; // { KzFieldId: CSVTemplateColumnName }
      if (!_.isUndefined(ctrl.mapping) && !_.isEmpty(ctrl.mapping)) {
        _.forEach(ctrl.mapping, function(m) {
          $scope.mapping[m.fieldId] = m.column;
        });
      } else {
        ctrl.autoMapAll();
      }

      $scope.entryUniquenessSelectedFields = {};
      if (!_.isUndefined(ctrl.entryUniqueness) && !_.isEmpty(ctrl.entryUniqueness)) {
        _.forEach(ctrl.entryUniqueness, function(entryUniquenessSelectedField) {
          $scope.entryUniquenessSelectedFields[entryUniquenessSelectedField] = true;
        });
      } else {
        _.forEach(ctrl.entryUniquenessKzFields.defaults, function(defaultValue) {
          $scope.entryUniquenessSelectedFields[defaultValue] = true;
        });
      }

      ctrl.availableMappingOptions = [{ id: undefined, name: '- None -' }];
      _.forEach(ctrl.fileColumns, function(fileColumn) {
        ctrl.availableMappingOptions.push({ id: fileColumn, name: fileColumn });
      });
    };

    this.$onChanges = function(changes) {
      if (changes.kzFields === undefined) {
        return;
      }
      var currentKzFields = _.map(changes.kzFields.currentValue, function(field) {
        return field._id;
      });
      var previousKzFields = _.map(changes.kzFields.previousValue, function(field) {
        return field._id;
      });
      if (!_.isEqual(currentKzFields, previousKzFields)) {
        $scope.mapping = {};
      }

      var newKzFields = changes.kzFields.currentValue;
      if (newKzFields) {
        if (ctrl.docType === 'event') {
          var kzFields = [
            {
              title: 'User identification',
              fields: _.filter(newKzFields, function(f) { return f.user_field; })
            },
            {
              title: 'Data',
              fields: _.filter(newKzFields, function(f) { return !f.user_field; })
            }
          ];
          ctrl.kzFields = [];
          _.forEach(kzFields, function(s) {
            ctrl.kzFields.push({ title: s.title });
            _.forEach(s.fields, function(f) {
              ctrl.kzFields.push(f);
            });
          });
          ctrl.autoMapAll();
        }
      }
    };

    $scope.$watchCollection('mapping', function(newMapping) {
      var isUserFieldChecked = false;
      _.forEach(ctrl.kzFields, function(field) {
        if (!field.user_field) {
          return;
        }

        var value = false;
        if (!isUserFieldChecked && $scope.mapping[field._id]) {
          value = true;
          isUserFieldChecked = true;
        }

        $scope.entryUniquenessSelectedFields[field._id] = value;
      });

      // clean mapping for output
      ctrl.mapping = _.chain(newMapping)
                      .map(function(fileColumnName, kzFieldId) {
                        return {
                          fieldId: kzFieldId,
                          type: 'column',
                          column: fileColumnName
                        };
                      })
                      .filter(function(columnMap) {
                        return !_.isUndefined(columnMap.column) && !_.isEmpty(columnMap.column);
                      })
                      .value();
    });

    $scope.$watchCollection('entryUniquenessSelectedFields', function(newEntryUniqueness) {
      ctrl.entryUniqueness = getSelectedEntryUniqueness(newEntryUniqueness);
    });
  }

  ColumnsMappingController.$inject = ['$scope'];

  angular.module('component.importer')
    .component('columnsMapping', {
      templateUrl: 'app/components/importer/components/columnsMapping.html',
      controller: ColumnsMappingController,
      bindings: {
        fileColumns: '<',
        kzFields: '<',
        entryUniquenessKzFields: '<',
        mapping: '=',
        entryUniqueness: '=',
        docType: '=',
        action: '='
      }
    });
})();
