(function() {
  'use strict';

  function SlotController($scope) {
    var ctrl = this;

    // this may be also used for the details view, move it if necessary
    if (($scope.options || {}).actionButtons) {
      ctrl.actionButtons = $scope.options.actionButtons($scope.item);
    }

    if (($scope.options || {}).secondaryActionButtons) {
      ctrl.secondaryActionButtons = $scope.options.secondaryActionButtons($scope.item);
    }
  }

  function SlotDirective() {
    return {
      scope: {
        item: '=',
        options: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/blocks/list/list.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  SlotController.$inject = ['$scope'];

  angular.module('blocks.list')
    .directive('listSlot', SlotDirective)
    .controller('listController', SlotController);
})();
