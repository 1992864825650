(function() {
  'use strict';

  function InitialReplicationController($q, $location, Auth, Database) {
    Database.replicateUserPrivateDatabase()
      .then(function() {
        return Database.setupUserDatabases(Auth.currentUser(), Auth.currentOrganisation());
      })
      .catch(function(err) {
        if (err.status === 401) {
          return $q.reject({ status: 500, message: 'You have no access to the database.' });
        }

        return $q.reject(err);
      })
      .finally(function() {
        var cameFrom = $location.search().came_from || '/';
        $location.url(cameFrom);
      });
  }

  InitialReplicationController.$inject = ['$q', '$location', 'AuthService', 'DatabaseService'];

  angular.module('component.accounts')
    .controller('InitialReplicationController', InitialReplicationController);
})();
