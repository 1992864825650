(function() {
  'use strict';

  function Slot() {
    return {
      restrict: 'A',
      controller: 'NetworkController',
      controllerAs: 'ctrl',
      templateUrl: 'app/components/settings/status.card.html'
    };
  }

  Slot.$inject = [];

  angular.module('component.settings')
    .directive('offlineStatus', Slot);
})();
