(function() {
  'use strict';

  function OfflineResultList() {
    return {
      scope: {
        tests: '=',
        level: '=?'
      },
      restrict: 'EA',
      templateUrl: 'app/components/settings/offlineresultlist.html'
    };
  }

  function OfflineResultItem($compile) {
    return {
      scope: {
        test: '=',
        level: '=?'
      },
      restrict: 'EA',
      templateUrl: 'app/components/settings/offlineresult.html',
      link: function(scope, element) {
        scope.newLevel = scope.level || 0;
        scope.newLevel += 1;

        if (_.isArray(scope.test.tests)) {
          $compile(
            '<div kz-offline-result level="newLevel" tests="test.tests"></div>'
          )(scope, function(cloned) {
            element.find('span.sublevel').append(cloned);
          });
        }
      }
    };
  }

  OfflineResultItem.$inject = ['$compile'];

  angular.module('component.settings')
    .directive('kzOfflineResult', OfflineResultList)
    .directive('kzOfflineResultItem', OfflineResultItem);
})();
