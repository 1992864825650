(function() {
  'use strict';

  function DashboardTemplatesController($state, DashboardTemplates, DashboardTemplatesUtils, List,
                                        Auth, Security, Utils, Notify, DEFAULT_DASHBOARD_TEMPLATE) {
    var ctrl = this;

    Utils.setPageTitle('Dashboard templates');

    ctrl.pageTitle = 'Dashboard templates';
    ctrl.templateType = 'dashboard';

    ctrl.list = new List({
      search: DashboardTemplatesUtils.search,
      model: DashboardTemplatesUtils.searchModel,
      idField: '_id'
    });

    ctrl.options = {
      trackBy: '_id',
      borderClass: function(item) {
        return DashboardTemplatesUtils.getBorderClass(item);
      }
    };

    ctrl.actions = [{
      label: 'New',
      icon: 'icon-edit',
      href: (function() { return $state.href('epf.dashboard-templates.new'); })(),
      showCondition: Security.hasPermission.bind(this, 'dashboardTemplates.edit'),
      klass: 'btn-success'
    }];

    var createDefaultDashboard;
    var loadItems;

    /**
     * Create the default dashboard template and save it.
     * This will only happen if there is no default dashboard template.
     * @param  {Boolean} shouldCreate True if there is no default template, false otherwise.
     * @return {Promise}              A promise with whether the default template has been saved.
     */
    createDefaultDashboard = function() {
      // We get the default document structure
      // and then we extend it with some sensible defaults
      var defaultDashboard = _.assign(
        {},
        DashboardTemplatesUtils.getDocDefault(Auth.currentOrganisation()),
        {
          title: 'Default dashboard',
          template: _.assign({}, DEFAULT_DASHBOARD_TEMPLATE, { title: 'Default dashboard' }),
          isDefault: true
        }
      );

      // We save the template and display a toastr notice to the user
      // if the save has been successful
      // We also reload the list of templates
      return DashboardTemplates.save(defaultDashboard)
              .then(function() {
                Notify.success(
                    'It looks like you didn\'t create a default dashboard template, ' +
                    'so we\'ve created one for you!',
                    'Default dashboard template created'
                );
              })
              .catch(Utils.showError);
    };

    /**
     * Load the list of dashboard templates.
     * @param {Boolean} skipDefaultDashboardCheck Should we check for the default dashboard or not?
     * @return {void}
     */
    loadItems = function() {
      DashboardTemplates.findAll({ cached: false })
        .then(function(items) {
          if (DashboardTemplatesUtils.noDefaultTemplate(items)) {
            // Create and reload
            return createDefaultDashboard()
              .then(function() {
                return DashboardTemplates.findAll({ cached: false });
              });
          }
          return items;
        })
        .then(function(items) {
          return ctrl.list.doLoadItems(items);
        })
        .catch(Utils.showError);
    };

    // Let's jumpstart everything
    loadItems();
  }

  DashboardTemplatesController.$inject = [
    '$state',
    'DashboardTemplatesService',
    'DashboardTemplatesUtilsService',
    'ListFactory',
    'AuthService',
    'SecurityService',
    'UtilsService',
    'NotifyService',
    'DEFAULT_DASHBOARD_TEMPLATE'
  ];

  angular.module('component.dashboardTemplates')
    .controller('DashboardTemplatesController', DashboardTemplatesController);
})();
