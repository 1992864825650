(function() {
  'use strict';

  function UserController(
    $q,
    $state,
    $stateParams,
    $rootScope,
    Accounts,
    Users,
    UserFields,
    Roles,
    Auth,
    Form,
    Utils,
    Security,
    Organisations
  ) {
    var ctrl = this;

    // Check permissions
    ctrl.action = $state.current.data.action;

    var username = $stateParams.user;
    if (ctrl.action === 'edit') {
      username = username || Auth.currentUser();
    }

    function hasPerm(action) {
      var auth;
      var permission;
      if (action === 'new') {
        permission = 'users.create';
      } else if (action === 'edit') {
        permission = 'users.edit';
      } else if (action === 'assign-roles') {
        permission = 'roles.assign';
      } else if (action === 'review') {
        permission = 'users.review';
      } else if (action === 'delete') {
        permission = 'users.delete';
      }

      if (!permission) {
        return $q.reject();
      }

      if (!username) {
        auth = Security.hasPermission(permission);
      } else if (username === Auth.currentUser()) {
        auth = Security.hasPermission(permission + '.own');
      } else {
        auth = Security.hasPermissionFor(permission, username);
      }

      return auth;
    }

    ctrl.accounts = [];
    function init() {
      // Get user fields
      return Security.hasPermission('users.edit.locked')
        .then(function() {
          return false;
        })
        .catch(function() {
          return true;
        })
        .then(function(isLocked) {
          ctrl.isLocked = isLocked;
        })
        .then(function() {
          // set basic fields
          var basicFields = [
            { id: 'basic-fields-legend', type: 'text_heading', label: 'Basic fields' },
            {
              id: 'firstName',
              type: 'string',
              label: 'First name',
              required: true
            },
            {
              id: 'lastName',
              type: 'string',
              label: 'Last name',
              required: true
            },
            { id: 'email', type: 'email', label: 'Email', required: true, unique: true }

          ];

          if (ctrl.action !== 'new') {
            basicFields.push(
              {
                id: 'secondaryEmails',
                type: 'deletableItems',
                label: 'Secondary Emails',
                required: true
              }
            );
          }

          ctrl.user = {};

          // User basic form
          ctrl.form = new Form();
          ctrl.basicGroup = ctrl.form.addGroup();

          ctrl.form.extend(basicFields, ctrl.basicGroup);
        })
        .then(function() {
          return UserFields.findAll()
            .then(function(userFields) {
              if (ctrl.isLocked) {
                // disable all locked fields if the user doesn't have the permission
                _.forEach(userFields, function(field) {
                  if (field.doc.isLocked) {
                    field.doc.disabled = true;
                  }
                });
              }
              ctrl.form.extendFromDb(userFields, ctrl.basicGroup);
            });
        })
        .then(function() {
          return hasPerm('assign-roles')
            .then(function() {
              return Roles.findAll({}, {}, { cached: true });
            })
            .then(function(data) {
              ctrl.roles = data;
            })
            .catch(function() {
              // No permission but it does not matter
            });
        })
        .then(function() {
          return Security.hasRole('system:admin', { promise: true })
            .then(function() {
              // Add the Super admin checkbox
              ctrl.form.extend([
                { id: 'isOrgSuperUser', type: 'boolean', label: 'Organisation admin' }
              ], ctrl.basicGroup);
            })
            .catch(function() {
              // This does not matter
            });
        })
        .then(function() {
          return Security.hasRole('system:superadmin', { promise: true })
            .then(function() {
              // Add the Super admin checkbox
              ctrl.form.extend([
                { id: 'isSuperUser', type: 'boolean', label: 'risr/advance system admin' }
              ], ctrl.basicGroup);
            })
            .catch(function() {
              // This does not matter
            });
        })
        .then(function() {
          ctrl.form.extend([
            { id: 'sendWelcomeEmail', type: 'boolean', label: 'Send Welcome Email' }
          ], ctrl.basicGroup);
        })
        .then(function() {
          var orgId = Auth.currentOrganisation();
          Organisations.find(orgId)
            .then(function(org) {
              if (ctrl.action === 'new') {
                if (_.isEmpty(org.allowedAccountTypes)) {
                  Utils.unauthorized({});
                } else {
                  ctrl.authorized = true;
                }
              } else {
                Accounts.find(username)
                  .then(function(accounts) {
                    ctrl.accounts = _.map(accounts, function(usernames, type) {
                      var username = usernames[0];
                      var t = type === 'SSO' ? 'proxy' : 'local';
                      return { type: t, username: username };
                    });
                    return ctrl.accounts;
                  })
                  .catch(function(err) {
                    // Catch it here so that it does nto ruin the whole widget
                    console.log('Could not load account:', err);
                  });
              }
            });
        })
        .then(function() {
          ctrl.nextPage = 'dashboard.summary';
          ctrl.nextParams = { user: username };
          ctrl.isCurrentUser = false;
          if (username !== undefined) {
            return Users.find(username, { noCache: true })
              .then(function(data) {
                ctrl.user = data;
                if (username === Auth.currentUser()) {
                  ctrl.nextPage = 'dashboard.index';
                  // ctrl.nextPage = 'dashboard.profile.view';
                  // ctrl.nextParams = { user: username };
                  ctrl.isCurrentUser = true;
                }
              });
          }
        })
        .then(function() {
          ctrl.authorized = true;
        })
        .catch(function(error) {
          Utils.showError(error, function() {
            $state.go('dashboard.summary', { user: username });
          });
        });
    }

    hasPerm(ctrl.action)
      .then(function() {
        init();
      })
      .catch(Utils.unauthorized);

    ctrl.cancel = function() {
      Utils.go(ctrl.nextPage, ctrl.nextParams, { tryBack: true });
    };

    ctrl.addAccount = function() {
      ctrl.accounts.push({});
    };

    ctrl.removeAccount = function(index) {
      ctrl.accounts.splice(index, 1);
    };

    ctrl.formIsSubmitted = false;
    ctrl.save = function(isValid) {
      ctrl.formIsSubmitted = true;

      if (!isValid) {
        Utils.showError({ message: 'Form is not valid' });
        return;
      }

      // The buttons will be disabled but the digest will not get
      // double click
      if (ctrl.saving) {
        return;
      }

      ctrl.saving = true;
      ctrl.user.type = 'user'; // Make sure this is user and not something else
      ctrl.user._id = ctrl.user._id ? ctrl.user._id : Utils.guid(); // Generate an ID
      ctrl.user.accounts = ctrl.accounts;

      return Users.save(ctrl.user)
        .then(function() {
          Utils.go(ctrl.nextPage, ctrl.nextParams, { tryBack: true });
        })
        .catch(Utils.showError)
        .finally(function() {
          ctrl.saving = false;
        });
    };

    ctrl.approve = function(isValid) {
      ctrl.formIsSubmitted = true;

      if (!isValid) {
        Utils.showError({ message: 'Form is not valid' });
        return;
      }

      // The buttons will be disabled but the digest will not get
      // double click
      if (ctrl.saving) {
        return;
      }

      ctrl.saving = true;
      ctrl.user.type = 'user'; // Make sure this is user and not something else
      ctrl.user._id = ctrl.user._id ? ctrl.user._id : Utils.guid(); // Generate an ID
      return Users.save(ctrl.user)
        .then(function() {
          return Users.updateState(ctrl.user, 'user_approve');
        })
        .then(function() {
          return Users.find(ctrl.user.username, { noCache: true });
        })
        .then(function() {
          $rootScope.$broadcast('KZRemoteUserChanged');
          Utils.go(ctrl.nextPage, ctrl.nextParams, { tryBack: true });
        })
        .catch(Utils.showError)
        .finally(function() {
          ctrl.saving = false;
        });
    };

    ctrl.create = function(isValid) {
      ctrl.formIsSubmitted = true;

      if (isValid) {
        var ids = [];
        var usernames = {};
        var errors = false;
        _.forEach(ctrl.accounts, function(account) {
          if (_.indexOf(ids, account._id) > -1) {
            Utils.showError({ message: 'Duplicates of names: ' + account.id });
            errors = true;
            return;
          }

          ids.push(account._id);

          if (_.isUndefined(usernames[account.type])) {
            usernames[account.type] = [];
          } else if (_.indexOf(usernames[account.type], account.username) > -1) {
            Utils.showError({ message: 'Duplicates of usernames: ' + account.username });
            errors = true;
            return;
          }

          usernames[account.type].push(account.username);
        });

        if (errors) {
          return;
        }

        ctrl.user.accounts = ctrl.accounts;
        Users.create(ctrl.user)
          .then(function(newUser) {
            ctrl.formIsSubmitted = false;
            $state.go('dashboard.summary', { user: newUser.user });
          }).catch(function(error) {
            ctrl.formIsSubmitted = false;
            console.log(error);
            Utils.swal({
              title: error.message,
              type: 'error'
            });
          });
      }
    };

    ctrl.remove = function() {
      hasPerm('delete')
        .then(function() {
          Utils.swal({
            title: 'Are you sure you want to remove this user?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'OK'
          },
          function(isConfirm) {
            if (isConfirm) {
              Users.remove(username)
                .then(function() {
                  $state.go('epf.users.index');
                });
            }
          });
        })
        .catch(function() {
          Utils.unauthorized({ message: 'You are not authorised to delete profiles' },
            function() {});
        });
    };

    // ctrl.requestResetPassword = function() {
    //   Utils.swal({
    //     title: 'Are you sure you want to request a new password for this user?',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'OK'
    //   },
    //   function(isConfirm) {
    //     if (isConfirm) {
    //       var email = ctrl.user.email;
    //       var orgId = Auth.currentOrganisation();
    //
    //       Accounts.requestResetPassword(email, orgId)
    //       .then(function() {
    //         console.log('success:');
    //       }).catch(function(error) {
    //         console.log('error:', error);
    //       });
    //     }
    //   });
    // };
  }

  UserController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    '$rootScope',
    'AccountsService',
    'UsersService',
    'UserFieldsService',
    'RolesService',
    'AuthService',
    'FormsService',
    'UtilsService',
    'SecurityService',
    'OrganisationsService'
  ];

  angular.module('component.users')
    .controller('UserController', UserController);
})();
