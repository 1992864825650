(function() {
  'use strict';

  function Slot(ShareService) {
    return {
      restrict: 'A',
      templateUrl: 'app/layout/sidebar/cards/formerrors.card.html',
      link: function(scope) {
        scope.$watch(function() {
          return ShareService.currentForm;
        }, function(value) {
          scope.form = ShareService.currentForm;
          scope.hidden = value === undefined || scope.form.$submitted;
        });

        function updateErrors() {
          if (scope.form === undefined
              || scope.form.$error === undefined
              || !scope.form.$submitted) {
            scope.errors = 0;
            scope.required = 0;
            scope.hidden = true;
            return;
          }

          var errors = scope.form.$error;
          var _errors = [];
          _.forOwn(errors, function(value, key) {
            if (key === 'required' || key === 'required2') {
              return;
            }

            _errors = _errors.concat(value.filter(function(item) {
              return item.$name !== '';
            }));
          });

          var _required = _.assignIn(errors.required || [], errors.required2 || []);

          var required = _required.filter(function(item) {
            return item.$name !== '';
          });

          scope.errors = _errors.length;
          scope.required = required.length;
          scope.hidden = scope.errors === 0 && scope.required === 0;
        }

        scope.$watch('form.$error', function() {
          updateErrors();
        }, true);

        scope.$watch('form.$submitted', function() {
          updateErrors();
        });


        scope.showMe = function() {
          angular.element(
            '.has-error input, .has-error textarea, .has-error select, input.ng-invalid, ' +
            'select.ng-invalid, textarea.ng-invalid'
          ).first().focus();
        };
      }
    };
  }

  Slot.$inject = ['ShareService'];

  angular.module('layout.sidebar')
    .directive('sidebarFormErrors', Slot);
})();
