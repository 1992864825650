(function() {
  'use strict';

  var title = '';
  var loading = false;

  function filter(Roles) {
    function innerFilter(input) {
      if (!input || loading || title) {
        return title || input;
      }

      loading = true;
      Roles.titleFor(input)
        .then(function(data) {
          title = data;
          loading = false;
        });

      return !_.isEmpty(title) ? title : input;
    }

    innerFilter.$stateful = true;
    return innerFilter;
  }

  filter.$inject = ['RolesService'];

  angular.module('component.roles')
    .filter('roleTitle', filter);
})();
