(function() {
  'use strict';

  function ConnectionsWidgetController(
    $state,
    config,
    Security,
    APIList,
    Users,
    User
  ) {
    var ctrl = this;

    /**
     * Create the User objects.
     * @param  {Object} item The ES raw user object.
     *
     * @return {Object}      The formatted user.
     */
    var makeUsers = function(item) {
      return new User(item);
    };

    /**
     * Set up the message shown to the users telling them they have
     * no permissions to view the list of their users.
     * @param  {string} content The content of the message.
     * @param  {string} type    The type of message. Should be either info or error.
     *
     * @return {void}
     */
    var setUserMessage = function(content, type) {
      ctrl.message = {
        type: type || 'error',
        content: content || 'You are not authorized to view the list of users.'
      };
    };

    /**
     * Set up the list of users.
     *
     * @return {void}
     */
    var setupUsersList = function() {
      // Reset the message
      ctrl.message = {};

      ctrl.list = new APIList(Users, {
        search: {
          orderGroups: {
            firstname: {
              title: 'first name',
              orders: ['firstName', 'lastName']
            }
          },
          defaultOrder: 'firstname'
        },
        defaultFilter: {
          limitToRoles: config.roles,
          state: 'active'
        },
        findOptions: {
          limit: 10
        },
        model: {},
        idField: 'doc._id',
        makeDocs: makeUsers,
        pagination: 'links'
      });

      ctrl.list.doSearch()
        .then(function() {
          if (!ctrl.list.items.length) {
            setUserMessage('You have no connections that you can see!', 'info');
          }
        })
        .catch(function(error) {
          var msg = (error && error.message) || 'Could not load connection list';
          setUserMessage(msg, 'error');
        });
    };

    /**
     * Construct the href link for a user based on their
     * state and username.
     * @param  {string} state    The user's state - either active/waiting_for_approval/disabled.
     * @param  {string} username The user's username. Used in constructing the url.
     * @return {string}          The URL.
     */
    ctrl.getUserMainLink = function(state, username) {
      var href = '';
      switch (state) {
        case 'active':
          href = $state.href('dashboard.summary', { user: username });
          break;
        case 'waiting_for_approval':
          href = $state.href('epf.users.review', { user: username });
          break;
        case 'disabled':
          href = $state.href('dashboard.summary', { user: username });
          break;
        default:
          console.log('Unknown state');
      }

      return href;
    };

    /**
     * On click, we load the provided page.
     * @param  {number} page The page number to navigate to.
     * @return {void}
     */
    ctrl.goToPage = function(page) {
      ctrl.list.goToPage(+page);
    };

    // First figure out if the user has the permission to view users
    Security.hasPermission('users.view')
      .then(function(hasPermission) {
        if (hasPermission) {
          setupUsersList();
        } else {
          setUserMessage();
        }
      })
      .catch(function(error) {
        var msg = (error && error.message) || 'Could not load connection list';
        setUserMessage(msg);
      });
  }

  function ConnectionsWidgetEditController(config, Form, Roles) {
    var ctrl = this;

    ctrl.config = config;
    ctrl.form = new Form();

    // We find the roles in the system and add the form field.
    Roles.findAll()
      .then(function(roles) {
        ctrl.form.addField({
          id: 'roles',
          type: 'discrete_multiple',
          label: 'Select the roles for which to display the user\'s connections:',
          options: roles.map(function(role) {
            return {
              _id: role.doc._id,
              key: role.doc._id,
              name: role.doc.title
            };
          })
        });
      });
  }

  ConnectionsWidgetController.$inject = [
    '$state',
    'config',
    'SecurityService',
    'APIListFactory',
    'UsersService',
    'UserFactory'
  ];
  ConnectionsWidgetEditController.$inject = [
    'config',
    'FormsService',
    'RolesService'
  ];

  function config(dashboardProvider) {
    dashboardProvider.widget('connections', {
      title: 'My Connections',
      description: 'Show the user\'s connections',
      controller: 'ConnectionsWidgetController',
      controllerAs: 'connectionsWidgetCtrl',
      templateUrl: 'app/components/users/widgets/connections/view.html',
      edit: {
        templateUrl: 'app/components/users/widgets/connections/edit.html',
        controller: 'ConnectionsWidgetEditController',
        controllerAs: 'connectionsWidgetEditCtrl'
      },
      category: 'Users'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.users')
    .config(config)
    .controller('ConnectionsWidgetController', ConnectionsWidgetController)
    .controller('ConnectionsWidgetEditController', ConnectionsWidgetEditController);
})();
