(function() {
  'use strict';

  function EventTypeLightFactory(EventTypeGroupFactory, EventTypes) {
    var EventType = function(transformed) {
      this.type = 'light';
      if (transformed.doc._id === undefined) {
        throw new Error('EventTypeLight: Malformed document - missing _id');
      }
      if (transformed.linkedVersions === undefined) {
        throw new Error('EventTypeLight: Malformed document - missing linkedVersions');
      }
      this.id = transformed.doc._id;
      this.doc = transformed.doc;
      this.linkedVersions = transformed.linkedVersions;
      this.groupState = transformed.groupState;
    };

    EventType.prototype.hasState = function(state) {
      return this.doc.state === state;
    };

    EventType.prototype.isSystem = function() {
      return this.doc.systemType === 'system';
    };

    EventType.prototype.getFull = function() {
      // Load all docs, store them and then return the group
      var _this = this;
      return EventTypes.updateVersionGroup(this.doc.versionGroupId)
        .then(function() {
          return EventTypes.fetch(_this.id)
            .then(function(group) {
              return new EventTypeGroupFactory(group);
            });
        });
    };

    EventType.prototype.get = function() {
      return EventTypes.getGroupByItemId(this.id)
        .then(function(group) {
          return new EventTypeGroupFactory(group);
        });
    };

    EventType.prototype.getBorderClass = function() {
      var map = {
        draft: 'pending',
        published: 'complete',
        archived: 'danger'
      };

      return 'progress-border-' + map[this.groupState];
    };

    return EventType;
  }

  EventTypeLightFactory.$inject = [
    'EventTypeGroupFactory',
    'EventTypesService'
  ];

  angular.module('component.eventTypes')
    .factory('EventTypeLightFactory', EventTypeLightFactory);
})();
