(function() {
  'use strict';

  function ContextualMenu($window) {
    return {
      restrict: 'A',
      scope: {
        toggle: '@'
      },
      link: function(scope, elem) {
        var $elem = angular.element(elem),
            w = angular.element($window),
            closeMenu = function() {
              angular.element('body')
                .css('overflow', 'auto');
              angular.element('#contextual-menu-background')
                .remove();
              angular.element('#contextual-menu-background-close')
                .remove();
              angular.element(scope.toggle)
                .css('display', 'none');
              angular.element('.action-button')
                .unbind('click', closeMenu);
            };

        $elem.on('click', function(e) {
          e.preventDefault();

          // Add the contextual menu background div
          angular.element('body')
            .css('overflow', 'hidden')
            .prepend('<div id="contextual-menu-background"></div>');
          angular.element('#contextual-menu-background')
            .css('display', 'block')
            .bind('click', closeMenu);

          // Show the contextual menu
          angular.element(scope.toggle)
            .css('display', 'block');

          // Add the cancel button
          angular.element('body')
            .append('' +
              '<a id="contextual-menu-background-close" ' +
              'class="btn btn-info bg-white btn-icon-circle" aria-label="Close">' +
              '<i class="icon-cancel"></i></a>' +
              '');
          angular.element('#contextual-menu-background-close')
            .bind('click', closeMenu);

          // Bind the click on the action buttons as well
          angular.element('.action-button')
            .bind('click', closeMenu);
        });

        var shouldShowButtons = function(width) {
          return width > 768 && angular.element(elem).css('display') === 'none';
        };
        // If the window resizes, check whether to make the buttons visible anyway
        w.bind('resize', function() {
          if (shouldShowButtons(w.width())) {
            angular.element(scope.toggle)
              .css('display', 'block');
          } else {
            angular.element(scope.toggle)
              .css('display', 'none');
          }
        });

        // Destroy
        scope.$on('$destory', function() {
          w.off('resize');
        });
      }
    };
  }

  ContextualMenu.$inject = ['$window'];

  angular.module('blocks.widgets')
    .directive('kzContextualMenu', ContextualMenu);
})();
