(function() {
  'use strict';

  function ReportRunController($scope, $stateParams, Reports, Report, Utils, Auth) {
    var ctrl = this;

    var reportId = $stateParams.id;
    ctrl.reportData = {};
    ctrl.showForm = false;

    ctrl.extra = {
      user: Auth.currentUser()
    };

    Reports.fetch(reportId, 'full')
      .then(function(report) {
        ctrl.baseReport = new Report(report);
        return ctrl.baseReport.getSingleOrMultiReportByDoc();
      })
      .then(function(report) {
        ctrl.report = report;
        Utils.setPageTitle('Run report ' + report.getTitle());
        ctrl.showCsvDownload = report.canGenerateAsTask();
        return ctrl.report.getFields()
          .then(function(fields) {
            // Construct default model from the report
            fields.forEach(function(fld) {
              ctrl.reportData[fld.id] = fld.default;
            });
          });
      })
      .then(function() {
        ctrl.showForm = true;
        ctrl.generate();
      })
      .catch(Utils.showError);

    ctrl.generate = function() {
      ctrl.model = angular.copy(ctrl.reportData);
      ctrl.generated = false;
      ctrl.showResult = true;
      $scope.$broadcast('RegenerateReport');
    };

    ctrl.download = function() {
      ctrl.model = angular.copy(ctrl.reportData);
      ctrl.report.download(ctrl.model);
    };
  }

  ReportRunController.$inject = [
    '$scope',
    '$stateParams',
    'ReportTemplatesService',
    'ReportFactory',
    'UtilsService',
    'AuthService'
  ];

  angular.module('component.reports')
    .controller('ReportRunController', ReportRunController);
})();
