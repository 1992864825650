(function() {
  'use strict';

  function AuditLogPreviewController(dates, showDetails, $uibModalInstance) {
    var ctrl = this;

    ctrl.dates = dates;
    ctrl.showDetails = showDetails;

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    ctrl.modal = $uibModalInstance;
  }

  AuditLogPreviewController.$inject = [
    'dates',
    'showDetails',
    '$uibModalInstance'
  ];

  angular.module('blocks.widgets')
    .controller('AuditLogPreviewController', AuditLogPreviewController);
})();
