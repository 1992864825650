/**
 * This is a rest API service with an offline support
 *
 * Key points:
 *  - This uses Kaizen API v2
 *  - It fallbacks to IndexedDB when offline
 *  - It needs to handle languages
 */
(function() {
  'use strict';

  function EasApiStore($q, $log, Auth, EasApi, Cache, moment, Network) {
    var service = function(endpoint, options) {
      var opts = options || {};
      this.rest_endpoint = endpoint;
      this.cacheKeys = opts.cacheKeys;
      this.store = {};
      this.apiVersion = '';
      this.usePersistentCache = opts.usePersistentCache;
      this.schemaVersion = opts.schemaVersion || 'v1';
      this.envelope = opts.envelope;
    };

    service.prototype.envelopAll = function() {
      if (this.store.all === undefined) {
        return [];
      }

      var _this = this;
      var res = this.store.all;
      if (this.envelope) {
        res = _.map(res, function(item) {
          return { [_this.envelope]: item };
        });
      }

      return _.values(res);
    };

    service.prototype.getCacheKeys = function() {
      var keys = [
        this.rest_endpoint + '-findall'
      ];
      if (this.cacheKeys !== undefined) {
        keys = keys.concat(this.cacheKeys);
      }
      return keys;
    };

    service.prototype.invalidate = function() {
      var keys = this.getCacheKeys();
      _.forEach(keys, function(key) {
        Cache.invalidateCachedPromise(key);
      });
      $log.debug('EasApiStore: Invalidating store for' + this.rest_endpoint);
      this.store = {};
      this.updatedTime = null;
      this._cached_flatten = null;
    };

    service.prototype.invalidateIfInvalid = function() {
      if (Auth.currentOrganisation() !== this.store.organisation) {
        this.invalidate();
        return;
      }
      if (Network.isOffline()) {
        $log.debug('EasApiStore: Not invalidating while offline');
        return;
      }

      if (this.store && this.store.all) {
        if (moment() > this.invalidateTime) {
          this.invalidate();
        }
      }
    };

    service.prototype.listUrl = function() {
      return this.rest_endpoint + '/';
    };

    service.prototype.specificUrl = function(oid) {
      return this.rest_endpoint + '/' + oid;
    };

    service.prototype.findAll = function(options) {
      var opts = _.assignIn({}, options || {});
      var _this = this;
      var ext = {
        key: this.rest_endpoint + '-findall',
        maxAge: 0
      };
      var isOffline = Network.isOffline();
      // isOffline = true;

      // Always use a cache if offline
      if (isOffline) {
        opts.cached = true;
      }

      var func;
      // Try memory cache
      if (opts.cached) {
        ext.cached = true;
        this.invalidateIfInvalid();
        if (_this.store.all) {
          $log.debug('EasApiStore: Returning from store cache for ' + _this.rest_endpoint);
          return $q.when(_this.envelopAll());
        } else if (isOffline) {
          func = function() {
            return _this.loadStore()
              .then(function() {
                $log.debug('EasApiStore: Reloaded from idb' + _this.rest_endpoint);
                return _this.envelopAll();
              });
          };
        }
        ext.cached = true;
      }

      var funcUpdatable = function() {
        $log.debug('EasApiStore: Going to load store for ' + _this.rest_endpoint);
        return _this.loadStore()
          .then(function() {
            return _this.updateStore();
          })
          .then(function() {
            $log.debug('EasApiStore: Reloaded from idb ' + _this.rest_endpoint);
            return _this.envelopAll();
          });
      };

      /*

      return this.loadStore()
        .then(update)

      return this.loadStore()
        .then(function() {
          $log.debug('EasApiStore: Reloaded from idb' + _this.rest_endpoint);
          return _this.store.all || [];
        });
      */

      var funcFull = function() {
        $log.debug('EasApiStore: Going to fetch all data for ' + _this.rest_endpoint);
        return _this._findAll(options)
          .then(function(data) {
            // Store for offline
            var obj = {};
            _.forEach(data, function(itm) {
              obj[itm._id] = itm;
            });
            _this.store.all = obj;
            _this.store.organisation = Auth.currentOrganisation();
            _this.store.lastUpdated = data.generated;
            _this.invalidateTime = (moment()).add(5, 'minutes');
            _this.saveStore();
            $log.debug('EasApiStore: Returning fresh data for ' + _this.rest_endpoint);
            return _this.envelopAll();
          });
      };

      if (func === undefined) {
        if (this.usePersistentCache) {
          func = funcUpdatable;
        } else {
          func = funcFull;
        }
      }

      if (func === undefined) {
        throw new Error('Func is not defined');
      }
      return Cache.cachedPromise(func, ext);
    };

    service.prototype._findAll = function(options) {
      var url = this.listUrl();
      var opts = _.assignIn({}, options || {}, { version: this.apiVersion });
      return EasApi.get(url, {}, {}, opts);
    };

    service.prototype.save = function(data) {
      this.invalidate();
      var opts = { version: this.apiVersion };
      var url = this.specificUrl(data._id) + '/edit';
      return EasApi.put(url, data, undefined, opts);
    };

    service.prototype.create = function(data) {
      this.invalidate();
      var opts = { version: this.apiVersion };
      var url = this.listUrl();
      return EasApi.post(url, data, undefined, opts);
    };

    service.prototype.find = function(oid, options) {
      var _this = this;
      var opts = _.assignIn({}, options || {}, { version: this.apiVersion });
      if (opts.cache === 'cached') {
        $log.warn('EasApiStore: Using obsolete cache option');
        opts.cached = true;
      }
      var ext = {
        key: this.rest_endpoint + '-find-' + oid,
        maxAge: 0
      };

      var func = function() {
        $log.debug('EasApiStore: Going to fetch single data for ' + _this.rest_endpoint);
        var url = _this.specificUrl(oid);
        return EasApi.get(url, undefined, undefined, opts)
          .then(function(itm) {
            $log.debug('EasApiStore: Going to fetch single data for ' + _this.rest_endpoint);
            return itm;
          });
      };

      if (opts.cached) {
        ext.cached = true;
        return this.ensureStore()
          .then(function() {
            if (_this.store.all && _this.store.all[oid]) {
              $log.debug('EasApiStore: Returning single from store cache for ' +
                         _this.rest_endpoint);
              return _this.store.all[oid];
            }
            return Cache.cachedPromise(func, ext);
          });
      }

      return Cache.cachedPromise(func, ext);
    };

    service.prototype.getForEdit = function(oid, urlParams, options) {
      var _this = this;
      var opts = _.assignIn({}, options || {}, { version: this.apiVersion });
      var url = this.specificUrl(oid) + '/edit';
      return EasApi.get(url, undefined, urlParams, opts)
        .then(function(itm) {
          $log.debug('EasApiStore: Going to fetch single data for ' + _this.rest_endpoint);
          return itm;
        });
    };

    service.prototype.getAuditlog = function(oid, urlParams, options) {
      var _this = this;
      var opts = _.assignIn({}, options || {}, { version: this.apiVersion });
      var url = this.specificUrl(oid) + '/auditlog';
      return EasApi.get(url, undefined, urlParams, opts)
        .then(function(itm) {
          $log.debug('EasApiStore: Going to fetch single auditlog for ' + _this.rest_endpoint);
          return itm;
        });
    };

    service.prototype.findByIds = function(ids) {
      return this.findAll()
        .then(function(data) {
          return _.filter(data, function(item) {
            return ids.indexOf(item.doc._id) !== -1;
          });
        });
    };

    service.prototype.remove = function(oid) {
      this.invalidate();
      var opts = { version: this.apiVersion };
      var url = this.specificUrl(oid);
      return EasApi.delete(url, undefined, undefined, opts);
    };

    service.prototype.searchIds = function(data, options) {
      options = options || {};
      options.version = this.apiVersion;
      var url = this.listUrl() + 'search';
      return EasApi.post(url, data, undefined, options)
        .then(function(data) {
          // Lets make it compatible
          return {
            total: data.total,
            size: data.size,
            start: data.start,
            hits: _.map(data.ids, function(itm) {
              return { id: itm };
            })
          };
        });
    };

    service.prototype.fetchIds = function(ids, options) {
      options = options || {};
      options.version = this.apiVersion;
      var url = this.listUrl() + 'fetch';
      return EasApi.post(url, { ids: ids }, undefined, options)
        .then(function(data) {
          return _.map(data.docs, function(itm) {
            return {
              doc: itm
            };
          });
        });
    };

    service.prototype.saveStore = function() {
      if (this.store.all === undefined) {
        $log.info('EasApiStore: Items are not loaded, skipping save for ' + this.rest_endpoint);
        return $q.when();
      }

      var sid = 'easstore-all' + this.schemaVersion + this.rest_endpoint;
      var doc = {
        _id: sid,
        store: this.store.all,
        lastUpdated: this.store.lastUpdated
      };

      return Cache.idbPut(sid, doc)
        .then(function() {
          $log.debug('EasApiStore: Saved store for ', sid);
        })
        .catch(function(err) {
          $log.warn('EasApiStore: Could not put to idb cache ', sid, err);
        });
    };

    service.prototype.loadStore = function() {
      var sid = 'easstore-all' + this.schemaVersion + this.rest_endpoint;
      var _this = this;
      var func = function() {
        return Cache.idbGet(sid)
          .then(function(data) {
            $log.debug('EasApiStore: Loaded store for ', sid);
            _this.store.all = data.store;
            _this.store.organisation = Auth.currentOrganisation();
            _this.store.lastUpdated = data.lastUpdated;
            return data.store;
          })
          .catch(function(err) {
            $log.debug('EasApiStore: Failed loading store for ', sid, err);
          });
      };
      var ext = {
        key: 'easstore-allload-' + sid,
        maxAge: 0,
        cached: true
      };
      return Cache.cachedPromise(func, ext);
    };

    service.prototype.ensureStore = function() {
      if (this.store.all !== undefined) {
        return $q.when();
      }

      return this.loadStore();
    };

    service.prototype.ensureUpdatedStore = function() {
      if (Network.isOffline()) {
        return $q.when();
      }
      if (!this.usePersistentCache) {
        return $q.when();
      }

      var _this = this;
      this.invalidateIfInvalid();
      return this.ensureStore()
        .then(function() {
          return _this.updateStore();
        });
    };

    service.prototype.updateStore = function() {
      var sid = 'easstore-all' + this.schemaVersion + this.rest_endpoint;
      var _this = this;
      var url = this.listUrl() + 'changes';
      var opts = _.assignIn({}, { version: this.apiVersion });
      var data = {
        changedAfter: this.store.lastUpdated
      };
      return EasApi.post(url, data, {}, opts)
        .then(function(data) {
          _this.store.lastUpdated = data.generated;

          // if (data.modified.length === 0) {
          //   $log.info('EasApiStore: No changes for ', sid);
          //   return;
          // }

          $log.info(`EasApiStore: Updating store for ${sid} with ${data.modified.length}`);

          var items;
          if (data.status === 'complete') {
            items = {};
          } else {
            items = (_this.store && _this.store.all) || {};
          }
          data.modified.forEach(function(item) {
            items[item._id] = item;
          });
          data.removed.forEach(function(itemId) {
            delete items[itemId];
          });

          if (_.keys(items).length !== data.total_count) {
            $log.error('EasApiStore: The number fetched does not correspond to count');
            _this.store.lastUpdated = undefined;
            if (data.status !== 'complete') {
              $log.error('EasApiStore: Re-runing load');
              return _this.updateStore();
            }
          } else {
            _this.store.lastUpdated = data.generated;
          }

          _this.store.all = items;
          return _this.saveStore();
        });
    };

    service.prototype.updateOrder = function(oid, order) {
      var url = this.specificUrl(oid) + '/update-order';
      return EasApi.post(url, { order: order }, {}, { version: this.apiVersion });
    };

    // copied from conf.service
    service.prototype.findByCategoryId = function(category) {
      return this.flatten()
        .then(function(flat) {
          return flat[category];
        });
    };

    service.prototype.findSubCategory = function(categoryId, options) {
      var _this = this;
      return this.findCategoryPath(categoryId)
        .then(function(path) {
          if (path.length === 0) {
            // Should we rather fail?
            return {};
          }

          return $q.all([$q.when(path), _this.find(path[0].rootId, options)]);
        })
        .then(function(result) {
          var row = result[0];
          var category = result[1];

          if (row.length === 1) {
            category.meta = row[0];
            return category;
          }

          var breadcrumb;
          for (var i = 1; i < row.length; i++) {
            breadcrumb = row[i];
            if (category === undefined) {
              return $q.reject({
                status: 404,
                message: 'Category ' + categoryId + 'not found in ' + result[1]._id
              });
            }
            category = _.find(category.categories, { _id: row[i].key });
          }

          if (breadcrumb) {
            category.meta = breadcrumb;
          }

          return category;
        });
    };

    service.prototype.findChildCategories = function(categoryIds) {
      var _this = this;
      var ids = _.isArray(categoryIds) ? categoryIds : [categoryIds];
      return $q.all(_.map(ids, function(id) {
        return _this.findChildCategory(id);
      }))
      .then(function(result) {
        return _.flatten(result);
      });
    };

    /**
     * Returns all categories below the given one
     * @param  {uuid} categoryId  CategoryId
     * @return {Array}            Array of children
     */
    service.prototype.findChildCategory = function(categoryId) {
      return this.findSubCategory(categoryId)
        .then(function(subCategory) {
          function emitCategories(category) {
            var categories = [category._id];
            if (category.categories) {
              // Get all categories from sub categories
              category.categories.forEach(function(item) {
                categories = categories.concat(emitCategories(item));
              });
            }

            return categories;
          }

          return emitCategories(subCategory);
        });
    };


    service.prototype.findByCategory = function(category) {
      var _this = this;
      return this.findByCategoryId(category)
        .then(function(data) {
          return _this.get(data.rootId);
        });
    };


    service.prototype.findCategoryPath = function(categoryId) {
      return this.flatten()
        .then(function(flat) {
          var category = flat[categoryId];
          if (category === undefined) {
            return $q.reject(
              { status: 404, message: 'Category ' + categoryId + ' not found' }
            );
          }

          var path = [];
          var parent = category;
          path.push(category);

          while (parent !== undefined) {
            parent = flat[parent.parent];
            if (parent !== undefined) {
              path.unshift(parent);
            }
          }

          return path;
        });
    };

    service.prototype.flatten = function(kzOpts) {
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: this.rest_endpoint + '-flatten',
        maxAge: 0,
        cached: true
      }, kzOpts);
      var func = this._flatten.bind(this, kzOpts);

      return Cache.cachedPromise(func, ext);
    };

    service.prototype._flatten = function() {
      if (this._cached_flatten) {
        return $q.when(this._cached_flatten);
      }

      $log.debug('ConfService: Calling flatten on', this.rest_endpoint);
      var _this = this;

      return _this.findAll()
        .then(function(data) {
          var flatten = {};

          var flat = function(categories, root, parent) {
            if (categories === undefined) {
              return;
            }

            categories.forEach(function(category, idx) {
              flatten[category._id] = {
                root: root.name,
                value: category.name,
                key: category._id,
                rootId: root._id,
                parent: parent._id,
                order: idx
              };

              flat(category.categories, root, category);
            });
          };

          data.forEach(function(item, idx) {
            flatten[item.doc._id] = {
              root: item.doc.name,
              value: item.doc.name,
              key: item.doc._id,
              rootId: item.doc._id,
              order: idx
            };

            flat(item.doc.categories, item.doc, item.doc);
          });

          _this._cached_flatten = flatten;
          return flatten;
        });
    };

    return service;
  }

  EasApiStore.$inject = [
    '$q', '$log', 'AuthService', 'EasApiService', 'CacheService', 'moment', 'NetworkService'
  ];

  angular.module('blocks.easapi')
    .factory('EasApiStore', EasApiStore);
})();
