
(function() {
  'use strict';

  function ReportFilterDirective() {
    return {
      scope: {
        report: '=report',
        filter: '=',
        parent: '=',
        editMode: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-filter.html',
      replace: true
    };
  }

  ReportFilterDirective.$inject = [];

  function ReportSingleDirective($log, $compile, Form, SingleReport) {
    return {
      scope: {
        report: '=report',
        filter: '=',
        parent: '=',
        editMode: '=',
        test: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-single-filter.html',
      replace: true,
      link: function(scope, element) {
        scope.options = {
          // uniqueFormId: scope.filter.id
        };

        var originalField = scope.filter.field;
        var valueCache = {};

        scope.getNegOperator = function() {
          scope.negoperator = scope.filter.operator === 'and' ? 'or' : 'and';
          return scope.negoperator;
        };

        if (scope.filter.filterType === 'fieldFilter') {
          scope.$watch('filter.field', function(value) {
            $log.debug('Initializing form', value);
            if (originalField !== value) {
              valueCache[originalField] = scope.filter.value;
              scope.filter.value = valueCache[value];
              originalField = value;
            }

            var disableAll = !scope.editMode;
            scope.singleForm = new Form(
              scope.report.getFilterFormFields(scope.filter.field, disableAll)
            );
          });
        }

        if (scope.filter.filterType === 'compFilter') {
          scope.operatorForm = new Form([{
            id: 'operator',
            type: 'discrete',
            label: 'Select operator for rules below',
            options: [
              { _id: 'and', key: 'and', name: 'AND' },
              { _id: 'or', key: 'or', name: 'OR' }
            ]
          }]);
        }

        var collectionSt = '<kz-report-filter class="filter-list" filter="filter" ' +
          'report="report" parent="parent" edit-mode="::editMode"></kz-report-filter>';
        if (angular.isArray(scope.filter.filters)) {
          $compile(collectionSt)(scope, function(cloned) {
            element.find('.placeholder').append(cloned);
          });
        }

        /**
         * Adds a normal one line filter to current comp filter
         */
        scope.addFilter = function() {
          if (scope.filter.filterType !== 'compFilter') {
            $log.error('Cannot add a new filter');
            return;
          }

          scope.filter.filters.push(scope.report.getInitialFieldFilter());
        };

        /**
         * OBSOLETE - this function adds a new comp filter
         *
         * This should be replaced by splitFilter and insertFilter
         */
        scope.addCompFilter = function() {
          if (scope.filter.filterType !== 'compFilter') {
            $log.error('Cannot add a new filter');
            return;
          }

          var flt = SingleReport.getInitialFilter();
          flt.operator = (scope.filter.operator === 'and') ? 'or' : 'and';
          scope.filter.filters.push(flt);
          return flt;
        };

        /**
         * Turns one line filter into a comp filter of opposite operator
         * where this becomes the first one.
         */
        scope.splitFilter = function() {
          if (scope.filter.filterType === 'compFilter') {
            $log.error('Cannot split a comp filter');
            return;
          }

          var flt = SingleReport.getInitialFilter();
          flt.operator = (scope.parent.operator === 'and') ? 'or' : 'and';

          flt.filters.push(angular.copy(scope.filter));
          flt.filters.push(scope.report.getInitialFieldFilter());

          var index = _.indexOf(scope.parent.filters, _.find(scope.parent.filters, scope.filter));
          scope.parent.filters.splice(index, 1, flt);
        };

        /**
         * Turn composed filter with one one-line filter into a one-line
         * filter only. Effectively this is the opposite of splitFilter
         */
        scope.unsplitFilter = function() {
          if (scope.filter && scope.filter.filterType !== 'compFilter') {
            $log.error('Cannot unsplit if parent is not comp');
            return;
          }

          if (scope.filter.filters.length !== 1) {
            $log.error('Cannot unsplit if there are more then one filter');
            return;
          }

          if (scope.parent === undefined) {
            $log.error('Cannot unsplit top filter');
            return;
          }

          var index = _.indexOf(scope.parent.filters, _.find(scope.parent.filters, scope.filter));
          scope.parent.filters.splice(index, 1, scope.filter.filters[0]);
        };

        /**
         * Insert comp filter above the top one. Effectivaly it means that user
         * can apply an opossite operator to all existing filters
         */
        scope.insertFilter = function() {
          if (scope.parent !== undefined) {
            $log.error('Cannot insert to filter with a parent');
            return;
          }

          var flt = SingleReport.getInitialFilter();
          flt.operator = (scope.filter.operator === 'and') ? 'or' : 'and';

          flt.filters.push(angular.copy(scope.filter));
          flt.filters.push(scope.report.getInitialFieldFilter());

          scope.filter = flt;
        };

        /**
         * Remove this filter from parent comp filter
         */
        scope.removeFilter = function() {
          _.remove(scope.parent.filters, scope.filter);
        };
      }
    };
  }

  ReportSingleDirective.$inject = ['$log', '$compile', 'FormsService', 'SingleReportFactory'];

  angular.module('component.reports')
    .directive('kzReportFilter', ReportFilterDirective)
    .directive('kzReportSingleFilter', ReportSingleDirective);
})();
