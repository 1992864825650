(function() {
  'use strict';

  function EventSecurity(
    $q,
    Security,
    EVENT_STATES
  ) {
    var service = {};

    var PERMISSIONS = {
      canCloseGoal: function(event) {
        if (event.doc.state !== EVENT_STATES.INPROGRESS.id) {
          return $q.reject({ status: 403 });
        }

        if (!event.isMine()) {
          return Security.hasPermissionFor('events.forceCloseGoal', event.doc.user);
        }

        return $q.when(true);
      },
      canReOpen: function(event) {
        if (event.doc.state !== 'complete') {
          return $q.reject({ status: 403 });
        }

        var lastSec = _.last(_.filter(event.sections, { state: 'complete' }));
        if (lastSec === undefined) {
          return $q.reject({ status: 403 });
        }

        var perm;
        if (lastSec.multiSource) {
          if (event.isMine()) {
            perm = Security.hasPermission('events.reopen');
          } else {
            perm = Security.hasPermissionFor('events.forceClose');
          }
        } else if (lastSec.goalSection) {
          if (event.isMine()) {
            perm = Security.hasPermission('events.reopengoal');
          } else {
            perm = Security.hasPermissionFor('events.forceCloseGoal');
          }
        } else {
          perm = $q.reject({ status: 403 });
        }
        return perm;
      }
    };

    /**
     * Event has to be an object with following interface
     *
     * doc.user
     * isMine()
     * getDefField()
     * isSetClosed()
     */
    service.checkPermission = function(event, permission) {
      var fn = PERMISSIONS[permission];
      if (fn === undefined) {
        throw new Error({ status: 500, message: 'Unknown permission: ' + permission });
      }
      return fn(event);
    };

    return service;
  }

  EventSecurity.$inject = [
    '$q',
    'SecurityService',
    'EVENT_STATES'
  ];

  angular.module('component.events')
    .factory('EventSecurity', EventSecurity);
})();
