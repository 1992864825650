(function() {
  'use strict';

  function SecurityService($q, Auth, Profile, Users, Roles, Api) {
    var service = {};

    service.preCache = function() {
      return $q.all([
        Roles.preCache(),
        Profile.preCache()
      ]);
    };

    service.hasPermission = function(permission, options) {
      options = options || {};
      var check = $q.all([
        Roles.findWithPermission(permission),
        Profile.getRoles({ cached: true })
      ]).then(function(result) {
        var roles = result[0];
        var profile = result[1];

        if (profile.indexOf('system:admin') > -1) {
          return true;
        }

        return _.intersection(roles, profile).length > 0;
      });

      if (options.noCatch) {
        return check;
      }

      return check.then(function(has) {
        return has || $q.reject({ status: 403, message: 'You are not authorised.' });
      });
    };

    service.hasPermissionFor = function(permission, user) {
      return Api.get('has_permission_for', { permission: permission, username: user })
        .catch(function(error) {
          if (error && 'status' in error &&
              [0, 502, 503, 510].indexOf(error.status) !== -1) {
            // Return true if service unavailable as we don't want to spoil it
            // and it will get caught by the server anyway
            return true;
          }

          return $q.reject({ status: 403, message: 'You are not authorised.' });
        })
        .then(function(data) {
          return data.has_permission ||
                 $q.reject({ status: 403, message: 'You are not authorised.' });
        });
    };

    service.hasOwnPermissionFor = function(permission, user) {
      if (user === Auth.currentUser()) {
        return service.hasPermission(permission + '.own');
      }

      return service.hasPermissionFor(permission, user);
    };

    service.hasOwnRoleFor = function(role, user) {
      if (user === Auth.currentUser()) {
        return service.hasRole(role);
      }

      return service.hasRoleFor(user, role);
    };

    // Return true is current user has given role
    service.hasRole = function(role, options) {
      options = options || {};
      var requiredRoles = Array.isArray(role) ? role : [role];
      var promise = Profile.getRoles({ cache: 'cached' })
        .then(function(roles) {
          var inter = _.intersection(roles, requiredRoles);
          return inter.length > 0;
        });

      if (!options.promise) {
        return promise;
      }

      return promise
        .then(function(has) {
          return has || $q.reject({ status: 403, message: 'You are not authorised.' });
        });
    };

    // Return true if user has given role
    service.userHasRole = function(user, role) {
      // This is online only which is not ver good however
      // this is used only when dealing with other users which is
      // online only anyway
      var requiredRoles = Array.isArray(role) ? role : [role];

      return Users.getRoles({ username: user })
        .then(function(roles) {
          return _.intersection(roles, requiredRoles).length > 0;
        });
    };

    service.hasRoleFor = function(username, roleId) {
      var requiredRoles = Array.isArray(roleId) ? roleId : [roleId];
      return service.hasRole(requiredRoles)
        .then(function(has) {
          if (!has) {
            return false;
          }

          return Api.post('has_role_for', { role: requiredRoles, username: username })
            .then(function(data) {
              return data.has_role;
            })
            .catch(function(error) {
              if (error && 'status' in error && error.status === 0) {
                // Return true if service unavailable as we don't want to spoil it
                // and it will get caught by the server anyway
                return true;
              }

              return false;
            });
        });
    };

    return service;
  }

  SecurityService.$inject = ['$q', 'AuthService', 'ProfileService', 'UsersService', 'RolesService',
    'ApiService'];

  angular.module('blocks.security')
    .service('SecurityService', SecurityService);
})();
