(function() {
  'use strict';

  function LoginController(
    $state,
    $location,
    $window,
    $log,
    Cache,
    kzLocalStorage,
    Api,
    Auth,
    Form,
    Network,
    Notify,
    Organisations,
    OidcService,
    Utils,
    SERVER_CONFIG
  ) {
    var ctrl = this;
    ctrl.useOidc = SERVER_CONFIG.authType === 'oidc';
    // Do not use login controller at all
    if (ctrl.useOidc) {
      $state.go('dashboard.index');
      return;
    }

    Utils.setPageTitle('Login');

    ctrl.credentials = {};
    ctrl.notifications = [];

    /**
     * Destroy the caches.
     */
    Cache.destroy();
    Api.purgeCache();

    /**
     * Redirect to the pin form when the user is offline.
     */
    if (Network.isOffline()) {
      $state.go('accounts.pin');
      return;
    }

    var setupLogin;
    Network.checkConnectivity()
      .then(Auth.isLoggedIn)
      .then(function() {
        Notify.success('You are already logged in');
        var cameFrom = $location.search().came_from || '/';
        $location.url(cameFrom);
      })
      .catch(function() {
        // Setup up local
        if (ctrl.useOidc) {
          var cameFrom = $location.search().came_from || '/';
          OidcService.login({ organisation: ctrl.organisationId, lastUrl: cameFrom });
        } else {
          setupLogin();
        }
      });

    var createForm;

    /**
     * The current organisation, if there is one.
     * This is useful for users that have logged in on the
     * current device before, and we can show them the logins
     * for their current organisation.
     * @type {String}
     */
    ctrl.organisationId = Auth.currentOrganisation();

    /**
     * A list of all the organisations in the system.
     * @type {Array}
     */
    ctrl.organisations = [];

    /**
     * Get a list of all the organisations in the system and attach
     * them to the controller so that we can display them for the user.
     */
    setupLogin = function() {
      Organisations.findAllPublic()
        .then(function(data) {
          ctrl.organisations = data.logins;
          ctrl.selectOrganisation(ctrl.organisationId);
          ctrl.loaded = true;
        }).catch(function(err) {
          // The error is already logged but we need to show at least normal login form
          $log.warn('Could not load logins', err);
          ctrl.loaded = true;
        });
    };

    /**
     * Button click handler to handle external login via SSO options.
     * @param  {Object} login The login object with the correct options,
     *                        including the loginPath.
     * @return {void}
     */
    ctrl.loginExternal = function(login) {
      var cameFrom = $location.search().came_from || '/';
      kzLocalStorage.setItem('kz-cameFrom', { url: cameFrom });
      kzLocalStorage.setItem('kz-login', login);
      $window.location.href = login.loginPath;
    };

    /**
     * Create the form with the correct fields for the login form.
     * @return {Object}     The created form.
     */
    createForm = function() {
      var fields = [
        {
          id: 'username',
          type: 'string',
          label: 'Username',
          required: true,
          ngModelAttrs: {
            autocorrect: {
              bound: 'autocorrect',
              attribute: 'autocorrect'
            },
            autocapitalize: {
              bound: 'autocapitalize',
              attribute: 'autocapitalize'
            }
          },
          ngModelAttrsValues: [
            {
              name: 'autocorrect',
              value: 'off'
            },
            {
              name: 'autocapitalize',
              value: 'off'
            }
          ]
        },
        {
          id: 'password',
          type: 'password',
          label: 'Password',
          required: true
        }
      ];

      return new Form(fields);
    };

    /**
     * Set the current organisation.
     * This changes the login page components to show only the login
     * options for the current organisation.
     * @param  {String} org The organisation ID that the user selected.
     * @return {void}
     */
    ctrl.selectOrganisation = function(org) {
      ctrl.organisationId = org;
      // Set the current organisation cookie for next time
      // they land on the page, even if they don't log in.
      Auth.setOrganisation(org);
      ctrl.organisation = _.find(ctrl.organisations, { id: ctrl.organisationId });

      // Create the local form if there is such a login option for the selected organisation
      if (ctrl.organisation && _.find(ctrl.organisation.loginOptions, { type: 'local' })) {
        ctrl.form = createForm();
      }
    };

    /**
     * Login the user as well as set any errors and warnings.
     * @return {void}
     */
    ctrl.login = function() {
      // After submitting the form we want to clear all notifications
      ctrl.notifications = [];

      // Make sure we remove kz-login if using the normal one
      kzLocalStorage.popItem('kz-login');

      var credentials = ctrl.credentials;
      Auth.login(ctrl.organisationId, credentials.username, credentials.password)
        .then(function() {
          var cameFrom = $location.search().came_from || '/';
          $location.url(cameFrom);
        }, function(error) {
          if (error.status === 401) {
            ctrl.notifications.push({
              type: error.status,
              classes: 'text-danger',
              msg: error.message
            });
          } else if (error.status === 403) {
            ctrl.notifications.push({
              type: error.status,
              classes: 'text-danger',
              msg: 'Access was denied. Please make sure cookies are enabled.'
            });
          } else if (error.status === 509) {
            Notify.error('Redirecting to offline login');
            $state.go('accounts.pin');
          } else {
            ctrl.notifications.push({
              type: error.status,
              classes: 'text-danger',
              msg: error.message || 'Unknown error'
            });
            $log.error('Unknown error:' + error.message);
          }
        });
    };

    ctrl.loggedIn = function() {
      var cameFrom = $location.search().came_from || '/';
      $location.url(cameFrom);
    };

    ctrl.notLoggedIn = function(error) {
      if (error && error.status === 509) {
        Notify.error('Redirecting to offline login');
        $state.go('accounts.pin');
      }
    };
  }

  LoginController.$inject = [
    '$state',
    '$location',
    '$window',
    '$log',
    'CacheService',
    'kzLocalStorage',
    'ApiService',
    'AuthService',
    'FormsService',
    'NetworkService',
    'NotifyService',
    'OrganisationsService',
    'OidcService',
    'UtilsService',
    'SERVER_CONFIG'
  ];

  angular.module('component.accounts')
    .controller('LoginController', LoginController);
})();
