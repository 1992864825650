(function() {
  'use strict';

  function Cards(Sidebar) {
    var service = {};

    service.register = function() {
      var cards = [
        {
          id: 'events-bookmark',
          title: 'Bookmarked searches',
          directive: 'sidebar-bookmark',
          options: {
            routes: [
              'epf'
            ]
          },
          order: 1
        },
        {
          id: 'help',
          title: 'Information',
          directive: 'sidebar-help',
          options: {
            routes: ['epf']
          },
          order: 2000
        },
        {
          id: 'form-errors',
          title: 'Form errors',
          directive: 'sidebar-form-errors',
          type: 'danger',
          options: {
            routes: ['epf']
          }
        },
        {
          id: 'relogin',
          title: 'Logged out',
          directive: 'sidebar-relogin',
          type: 'warning',
          options: {
            routes: ['epf']
          }
        }
      ];

      Sidebar.registerCards(cards);
    };

    return service;
  }

  Cards.$inject = ['SidebarService'];

  angular.module('layout.sidebar')
    .service('GeneralCards', Cards);
})();
