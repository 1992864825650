(function() {
  'use strict';

  function EventGroupSlotDirective() {
    return {
      scope: {
        item: '=',
        options: '=',
        showActionButtons: '=',
        bulkTaggingSelectedTags: '=',
        useCompactView: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/events/directives/event-group-slot.html'
    };
  }

  angular.module('events.directives')
    .directive('eventGroupSlot', EventGroupSlotDirective);
})();
