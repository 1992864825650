(function() {
  'use strict';

  function VisibilityFormController($q, $scope, Form, Announcements, UsersStub, Utils) {
    var ctrl = this;

    var formatRelations = function(relations) {
      return _.map(relations, function(relation) {
        return relation.doc;
      });
    };

    var buildRolesRelationsForm = function(roles, relations) {
      var form = new Form();
      form.addField({
        id: 'visibleForRoles',
        type: 'discrete_multiple',
        label: 'Please select all roles for whom this announcement will be visible:',
        options: roles.map(function(role) {
          return {
            _id: role.doc._id,
            key: role.doc._id,
            name: role.doc.title
          };
        })
      });

      form.addField({
        type: 'link',
        label: 'Select all roles',
        onClick: function() {
          $scope.announcement.visibleForRoles = _.map(roles, function(role) {
            return role.doc._id;
          });
        },
        hideExpression: function() {
          return (
            $scope.announcement.visibleForRoles &&
            $scope.announcement.visibleForRoles.length === roles.length
          );
        }
      });

      form.addField({
        type: 'link',
        label: 'Deselect all roles',
        onClick: function() {
          $scope.announcement.visibleForRoles = [];
        },
        hideExpression: function() {
          return (
            $scope.announcement.visibleForRoles &&
            $scope.announcement.visibleForRoles.length === 0
          );
        }
      });

      form.addField({
        id: 'visibleForRelations',
        controller: ['$scope', function($scope) {
          var formattedRelations = formatRelations(relations);
          $scope.tree = formattedRelations.length > 1 ?
            formattedRelations :
            formattedRelations[0].categories;
          $scope.selected = [];
          $scope.kzTree = '';
          $scope.treeOptions = {
            selectable: true,
            searchable: true,
            icons: {
              arrowRight: 'icon-right-open-big',
              arrowDown: 'icon-down-open-big',
              empty: 'icon-minus'
            }
          };
        }],
        type: 'tree',
        label: 'Please select what relations a user should have in ' +
                'order to view this announcement:',
        required: false,
        ngModelAttrs: {
          kzTree: {
            bound: 'kzTree',
            attribute: 'kz-tree'
          },
          selected: {
            bound: 'selected',
            attribute: 'selected'
          },
          nodes: {
            bound: 'nodes',
            attribute: 'nodes'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'kzTree',
            value: 'kzTree'
          },
          {
            name: 'selected',
            value: 'selected'
          },
          {
            name: 'nodes',
            value: 'tree'
          },
          {
            name: 'options',
            value: 'treeOptions'
          }
        ]
      });

      return form;
    };

    function onChangeUser() {
      if (_.isEmpty($scope.announcement.visibleForUsers)) {
        return;
      }

      UsersStub.find($scope.announcement.visibleForUsers)
        .then(function(profile) {
          ctrl.profile = profile;
        });
    }

    var buildUsersListForm = function() {
      var form = new Form();

      form.addField({
        id: 'visibleForUsers',
        type: 'discrete_multiple',
        label: 'Please select the user or users who will be able to view this announcement:',
        onChange: onChangeUser,
        placeholder: 'Start typing to search',
        controller: ['$scope', function($scope) {
          $scope.reload = function(search) {
            if (search.length < 3) {
              $scope.options.templateOptions.options = [];
              return;
            }

            UsersStub.getAllOptions({ fullname: search })
              .then(function(users) {
                $scope.options.templateOptions.options = users;
              });
          };
        }],
        ngModelAttrs: {
          limit: {
            bound: 'ng-limit',
            attribute: 'limit'
          },
          closeOnSelect: {
            bound: 'ng-close-on-select',
            attribute: 'close-on-select'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'closeOnSelect',
            value: true
          }
        ],
        options: $q.all(_.map($scope.announcement.visibleForUsers || [], function(item) {
          return UsersStub.find(item);
        }))
        .then(function(result) {
          return _.map(result, function(user) {
            return {
              _id: user.username,
              key: user.username,
              name: _.trim((user.firstName || '') + ' ' + (user.lastName || '') + ' - ' +
                (user.email || '')) || user.username
            };
          });
        })
      });

      return form;
    };

    Announcements.getVisibilityFormData()
      .then(function(data) {
        ctrl.rolesRelationsForm = buildRolesRelationsForm(data[0], data[1]);
        ctrl.usersListForm = buildUsersListForm();
      })
      .catch(Utils.showError);
  }

  function VisibilityFormDirective() {
    return {
      scope: {
        announcement: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/announcements/directives/visibility-form.html',
      controller: VisibilityFormController,
      controllerAs: 'ctrl'
    };
  }

  VisibilityFormController.$inject = [
    '$q',
    '$scope',
    'FormsService',
    'AnnouncementsService',
    'UsersStubService',
    'UtilsService'
  ];

  angular.module('component.announcements')
    .directive('announcementVisibilityForm', VisibilityFormDirective);
})();
