(function() {
  'use strict';

  function backButton($window) {
    return {
      restrict: 'A',
      link: function(_scope, element) {
        element.on('click', function() {
          $window.history.back();
        });
      }
    };
  }

  backButton.$inject = ['$window'];

  angular.module('blocks.utils')
    .directive('epfBack', backButton);
})();
