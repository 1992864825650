(function() {
  'use strict';

  function FileUploadService($http, EasApi) {
    var service = {};

    service.uploadToS3 = function(upload, metadata, progressCallback) {
      return service.getSignedUrl(upload, metadata)
        .then(function(res) {
          return service.uploadToPresignedS3(upload, res, progressCallback);
        })
        .then(function(urls) {
          return {
            // location: this.location ? this.location.href : undefined,
            size: upload.size,
            key: urls.key,
            contentType: upload.type,
            type: upload.type.split('/')[1],
            filename: upload.name,
            vendor: 's3'
          };
        });
    };

    service.getDownloadUrl = function(upload, metadata, options) {
      var opts = options || {};
      const params = {
        file_info: upload,
        metadata: metadata,
        options: opts
      };
      return EasApi.post('files/get_download_token', params, undefined, { version: '' });
    };

    service.getSignedUrl = function(upload, metadata) {
      const params = {
        file_extension: upload.name.split('.').pop(),
        metadata: metadata
      };
      return EasApi.post('files/get_upload_token', params, undefined, { version: '' });
    };

    service.uploadToPresignedS3 = function(upload, urls, progressCallback) {
      var formData = new FormData();
      _.forOwn(urls.fields, function(obj, key) {
        formData.append(key, obj);
      });
      formData.append('file', upload);

      // This is a hack to fix the s3 url issue
      // const url = urls.url.replace('.s3.amazonaws.com', '.s3.eu-west-2.amazonaws.com');
      const url = urls.url;

      return $http({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': undefined,
          mimeType: 'multipart/form-data',
          'ngsw-bypass': 'true'
        },
        data: formData,
        uploadEventHandlers: {
          progress: function(e) {
            if (e.lengthComputable) {
              const progress = Math.round((e.loaded / e.total) * 100);
              if (progressCallback) {
                progressCallback(progress);
              }
            }
          }
        }
      }).then(function() {
        return urls;
      });
    };

    return service;
  }

  FileUploadService.$inject = [
    '$http',
    'EasApiService'
  ];

  angular.module('blocks.utils')
    .service('FileUploadService', FileUploadService);
})();
