(function() {
  'use strict';

  function EventExtrasForm($scope, Form, BlueprintUtils) {
    var ctrl = this;

    ctrl.displayOptions = angular.copy(
      $scope.displayOptions || { collapsible: false, isExpanded: true }
    );

    ctrl.form = new Form();

    var buildForm = function() {
      // Add blueprints
      var blueprints = BlueprintUtils.getTaggableBlueprints({
        period: 'until now',
        user: $scope.forUser
      })
          .then(function(blueprints) {
            return {
              id: 'blueprints',
              controller: ['$scope', function($scope) {
                if (blueprints.length === 0) {
                  $scope.tree = [];
                } else if (blueprints.length === 1) {
                  $scope.tree = blueprints[0].categories;
                } else {
                  $scope.tree = blueprints;
                }
                $scope.selected = [];
                $scope.kzTree = '';
                $scope.treeOptions = {
                  selectable: true,
                  searchable: true,
                  icons: {
                    arrowRight: 'icon-right-open-big',
                    arrowDown: 'icon-down-open-big',
                    empty: 'icon-minus'
                  }
                };
              }],
              type: 'tree',
              label: 'Tags',
              required: false,
              ngModelAttrs: {
                kzTree: {
                  bound: 'kzTree',
                  attribute: 'kz-tree'
                },
                selected: {
                  bound: 'selected',
                  attribute: 'selected'
                },
                nodes: {
                  bound: 'nodes',
                  attribute: 'nodes'
                },
                options: {
                  bound: 'treeOptions',
                  attribute: 'options'
                }
              },
              ngModelAttrsValues: [
                {
                  name: 'kzTree',
                  value: 'kzTree'
                },
                {
                  name: 'selected',
                  value: 'selected'
                },
                {
                  name: 'nodes',
                  value: 'tree'
                },
                {
                  name: 'options',
                  value: 'treeOptions'
                }
              ]
            };
          });

      ctrl.form.addField(blueprints);
    };

    buildForm();
  }

  EventExtrasForm.$inject = [
    '$scope',
    'FormsService',
    'BlueprintUtils'
  ];

  function EventExtrasFormDirective() {
    return {
      scope: {
        forUser: '=',
        extras: '=',
        displayOptions: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-extras-form.html',
      replace: true,
      controller: EventExtrasForm,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventExtrasForm', EventExtrasFormDirective);
})();
