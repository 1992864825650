(function() {
  'use strict';

  function Slot($compile, $rootScope, Profile) {
    return {
      restrict: 'AE',
      scope: {
        card: '='
      },
      templateUrl: 'app/layout/sidebar/sidebar-slot.html',
      link: function(scope, elem) {
        function addDirective() {
          var directiveName = (scope.card.directive || 'sidebar-default');
          var elString = '<div ' + directiveName + ' card="card"></div>';
          var el = $compile(elString)(scope);
          elem.find('.inner-card').html('');
          elem.find('.inner-card').append(el);
        }

        scope.$watch('hidden', function(value) {
          if (!value) {
            addDirective();
          }
        });

        var prefs = Profile.getPreferences();

        if (prefs.cards === undefined) {
          prefs.cards = {};
        }

        if (prefs.cards[scope.card.id] === undefined) {
          prefs.cards[scope.card.id] = {
            hidden: false,
            collapsed: false,
            sticky: false
          };
        }

        scope.cardPrefs = prefs.cards[scope.card.id];

        scope.$watch('cardPrefs', function() {
          $rootScope.$broadcast('KZPreferencesChange');
        }, true);

        scope.toggleSticky = function() {
          scope.cardPrefs.sticky = !scope.cardPrefs.sticky;
          $rootScope.$broadcast('sidebarCard:toggleSticky', scope.cardPrefs.sticky, scope.card.id);
        };
      }
    };
  }

  Slot.$inject = ['$compile', '$rootScope', 'ProfileService'];

  angular.module('layout.sidebar')
    .directive('sidebarSlot', Slot);
})();
