(function() {
  'use strict';

  var EVENT_STATES = {
    DRAFT: { id: 'draft', title: 'Draft', labelStyle: 'warning', borderStyle: 'pending' },
    FAILED: { id: 'failed', title: 'Action failed', labelStyle: 'danger', borderStyle: 'danger' },
    ACCEPTED: { id: 'accepted', title: 'Accepted', labelStyle: 'warning', borderStyle: 'pending' },
    REJECTED: { id: 'rejected', title: 'Rejected', labelStyle: 'danger', borderStyle: 'danger' },
    COMPLETE: { id: 'complete', title: 'Complete', labelStyle: 'success', borderStyle: 'complete' },
    OBSOLETE: { id: 'obsolete', title: 'Obsolete', labelStyle: 'danger', borderStyle: 'danger' },
    MERGED: { id: 'merged', title: 'Merged', labelStyle: 'success', borderStyle: 'success' },
    PENDING: { id: 'pending', title: 'Pending', labelStyle: 'warning', borderStyle: 'pending' },
    PROCESSING: {
      id: 'processing',
      title: 'Processing',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    PENDING_APPROVAL: {
      id: 'pending_approval',
      title: 'Waiting for approval',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    SUBMITTED: {
      id: 'submitted',
      title: 'Submitted',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    PARTIAL: {
      id: 'partial',
      title: 'Awaiting responses',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    RETRACTED: {
      id: 'retracted',
      title: 'Retracted',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    INPROGRESS: {
      id: 'inProgress',
      title: 'In progress',
      labelStyle: 'warning',
      borderStyle: 'pending'
    } // used for goals

  };

  var EVENT_ALLOWED_STATES = {
    rePublish: [],
    reRequest: [EVENT_STATES.SUBMITTED.id, EVENT_STATES.PARTIAL.id],
    accept: [EVENT_STATES.SUBMITTED.id, EVENT_STATES.FAILED.id],
    close: [EVENT_STATES.PARTIAL.id],
    invite: [EVENT_STATES.SUBMITTED.id, EVENT_STATES.PARTIAL.id],
    reject: [EVENT_STATES.PARTIAL.id, EVENT_STATES.SUBMITTED.id, EVENT_STATES.DRAFT.id],
    retract: [EVENT_STATES.SUBMITTED.id],
    delete: [EVENT_STATES.DRAFT.id, EVENT_STATES.RETRACTED.id],
    edit: [
      EVENT_STATES.PARTIAL.id,
      EVENT_STATES.DRAFT.id,
      EVENT_STATES.INPROGRESS.id,
      EVENT_STATES.FAILED.id,
      EVENT_STATES.REJECTED.id,
      EVENT_STATES.ACCEPTED.id,
      EVENT_STATES.SUBMITTED.id
    ],
    changeVisibility: [
      EVENT_STATES.FAILED.id,
      EVENT_STATES.SUBMITTED.id,
      EVENT_STATES.PARTIAL.id,
      EVENT_STATES.REJECTED.id,
      EVENT_STATES.RETRACTED.id,
      EVENT_STATES.COMPLETE.id,
      EVENT_STATES.INPROGRESS.id
    ]
  };

  var EVENT_STATES_ORDERED = ['draft', 'submitted', 'partial', 'complete', 'rejected'];

  var INVITEES_STATES = {
    draft: { id: 'draft', name: 'Waiting', style: 'warning' },
    pending: {
      id: 'pending',
      name: 'Waiting For Approval',
      style: 'warning'
    },
    importedManually: { id: 'importedManually', name: 'Manually Imported', style: 'success' },
    respondedElectronically: {
      id: 'respondedElectronically',
      name: 'Responded',
      style: 'success'
    },
    rejected: { id: 'rejected', name: 'Rejected', style: 'danger' }
  };

  angular.module('component.events')
    .constant('EVENT_STATES', EVENT_STATES)
    .constant('EVENT_STATES_ORDERED', EVENT_STATES_ORDERED)
    .constant('EVENT_ALLOWED_STATES', EVENT_ALLOWED_STATES)
    .constant('INVITEES_STATES', INVITEES_STATES);
})();
