(function() {
  'use strict';

  function kzSearch($rootScope) {
    return {
      restrict: 'AE',
      scope: {
        list: '=',
        doSearch: '&?'
      },
      templateUrl: 'app/blocks/widgets/search/kz-simplesearch.html',
      link: function(scope) {
        scope.$watch('list', function() {
          if (_.isUndefined(scope.list)) {
            return;
          }

          scope.facets = _.filter(scope.list.search.facetly.facets, function(item) {
            return item.facetly === undefined || item.facetly;
          });

          scope.useFacetly = scope.facets.length > 1;
          if (!scope.useFacetly && scope.facets[0].label) {
            scope.facets[0].placeholder = scope.facets[0].label;
            scope.facets[0].label = undefined;
          }
        });

        scope.search = function() {
          $rootScope.$broadcast('KZApplySearch');
        };

        scope.removeAll = function() {
          scope.list.searchModel.filteredBy = {};
        };
      }
    };
  }

  kzSearch.$inject = ['$rootScope'];

  angular.module('blocks.widgets')
    .directive('kzSimpleSearch', kzSearch);
})();
