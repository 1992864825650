(function() {
  'use strict';

  function filter() {
    return function(collection) {
      var unique = [];
      var dups = [];

      collection.forEach(function(event) {
        var _id = event.doc._id;
        if (unique.indexOf(_id) > -1) {
          dups.push(_id);
        } else {
          unique.push(_id);
        }
      });

      return collection.filter(function(event) {
        var _id = event.doc._id;
        if (dups.indexOf(_id) > -1 && event.isShared) {
          return false;
        }

        return true;
      });
    };
  }

  angular.module('blocks.utils')
    .filter('uniqueEvents', filter);
})();
