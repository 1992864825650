(function() {
  'use strict';

  function BaseConfStoreFactory(BaseStore, Utils, Auth) {
    var Service = function() {
      BaseStore.call(this);
      this.id = 'confstore';
      this.by_type_view = 'by_org_and_type';
      this.by_id_view = 'by_org_and_id';
    };

    Utils.extends(Service, BaseStore);

    Service.prototype.ddoc = function(view) {
      return 'public_conf/' + view;
    };

    Service.prototype.injectKeys = function(options) {
      options = angular.copy(options || {});
      var org = Auth.currentOrganisation();

      if (options.key) {
        if (_.isArray(options.key)) {
          options.key = _([org]).concat(options.key).value();
        } else {
          options.key = [org, options.key];
        }
      } else if (options.keys) {
        options.keys = _.map(options.keys, function(key) {
          return [org, key];
        });
      } else {
        options.startkey = [org].concat(options.startkey);
        options.endkey = [org].concat(options.endkey);
        // $log.error('Not yet implemented');
      }

      return options;
    };


    return Service;
  }

  function BaseConfStoreService($rootScope, BaseConfStoreFactory) {
    var service = new BaseConfStoreFactory();

    $rootScope.$on('KZClearCache', function() {
      service.invalidate();
    });

    return service;
  }

  BaseConfStoreFactory.$inject = ['BaseStoreFactory', 'UtilsService', 'AuthService'];
  BaseConfStoreService.$inject = ['$rootScope', 'BaseConfStoreFactory'];

  angular.module('blocks.stores')
    .factory('BaseConfStoreFactory', BaseConfStoreFactory)
    .service('BaseConfStoreService', BaseConfStoreService);
})();
