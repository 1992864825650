(function() {
  'use strict';

  var requestResetPasswordURL = 'accounts/pw_reset_request';

  var states = {
    active: {
      id: 'active',
      name: 'Active',
      labelStyle: 'success',
      borderStyle: 'complete'
    },
    disabled: {
      id: 'disabled',
      name: 'Disabled',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    waiting_for_approval: {
      id: 'waiting_for_approval',
      name: 'External response',
      labelStyle: 'warning',
      borderStyle: 'pending'
    }
  };

  angular.module('component.users')
      .constant('REQUEST_RESET_PASSWORD_URL', requestResetPasswordURL)
      .constant('USER_STATES', states);
})();
