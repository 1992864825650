(function() {
  'use strict';

  function AllEventsController($rootScope, Profile, EventUtils, Utils) {
    var ctrl = this;

    ctrl.options = {
      highlightField: 'name',
      typesToUpdate: ['event', 'eventSection'],
      trackBy: 'id',
      borderClass: function(item) {
        return item.main.getBorderClass();
      }
    };

    Utils.setPageTitle('All events for other users');

    ctrl.searchModel = Profile.getListPreferences('allevents').current;
    var listName = 'allevents';

    ctrl.searchModel = Profile.getListPreferences(listName).current;
    ctrl.options.search = ctrl.searchModel;
    var searchCode = 'allsearch';
    EventUtils.getList(searchCode, ctrl.searchModel, { own: false })
      .then(function(list) {
        ctrl.list = list;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: ctrl.list.search });
        $rootScope.loading = false;
        ctrl.list.doLoadItems();
        ctrl.loaded = true;
      });
  }

  AllEventsController.$inject = ['$rootScope', 'ProfileService', 'EventUtils', 'UtilsService'];

  angular.module('component.events')
    .controller('AllEventsController', AllEventsController);
})();
